import { useState, useCallback, } from 'react';
import { useModalManager, useTimer } from '../../shared';
import { writeToClipboard } from '../../../utils';
import { ModalIDs } from '../../../const';
export const useUserMenu = ({ clients, allClients, actions, thunks, dispatch, labels, zendesk, }) => {
    const [userMenuInfo, setUserMenuInfo] = useState(null);
    const [clientIdToDisassociate, setClientIdToDisassociate] = useState(null);
    const [isClientLoading, setClientsIsLoading] = useState(false);
    const [isTimerRunning, startTimer] = useTimer();
    const handleCloseUserMenu = useCallback(() => setUserMenuInfo(null), [setUserMenuInfo]);
    const { openModal } = useModalManager();
    const handleEditUser = useCallback(async () => {
        const clientToEdit = clients === null || clients === void 0 ? void 0 : clients.find((client) => client.ovcid === (userMenuInfo === null || userMenuInfo === void 0 ? void 0 : userMenuInfo.clientId));
        setUserMenuInfo(null);
        if (!clientToEdit) {
            return;
        }
        await openModal(ModalIDs.addEditClient, {
            actions,
            thunks,
            dispatch,
            originalClientData: clientToEdit,
            clients: allClients,
            labels,
            zendesk,
        });
    }, [dispatch, clients, userMenuInfo === null || userMenuInfo === void 0 ? void 0 : userMenuInfo.clientId]);
    const handleCopyID = useCallback(async () => {
        if (!(userMenuInfo === null || userMenuInfo === void 0 ? void 0 : userMenuInfo.clientId)) {
            return;
        }
        await writeToClipboard(userMenuInfo.clientId, 'text/plain');
        startTimer();
    }, [userMenuInfo === null || userMenuInfo === void 0 ? void 0 : userMenuInfo.clientId, startTimer]);
    const handleDisassociateUser = useCallback(() => {
        if (!userMenuInfo) {
            return;
        }
        setClientIdToDisassociate(userMenuInfo.clientId);
        setUserMenuInfo(null);
    }, [dispatch, clients, userMenuInfo === null || userMenuInfo === void 0 ? void 0 : userMenuInfo.clientId]);
    const closeDisassociateDialog = useCallback(() => {
        setClientIdToDisassociate(null);
    }, [setClientIdToDisassociate]);
    const userMenuHandler = useCallback((event, client) => {
        setUserMenuInfo({
            anchor: event.target,
            clientId: client.ovcid,
        });
    }, [setUserMenuInfo]);
    return {
        clientIdToDisassociate,
        isClientLoading,
        isTimerRunning,
        handleCloseUserMenu,
        handleCopyID,
        handleDisassociateUser,
        handleEditUser,
        userMenuHandler,
        closeDisassociateDialog,
        userMenuInfo,
        setClientsIsLoading,
    };
};
