import { createAsyncThunk } from '@reduxjs/toolkit';

import { API } from '../../api';

export const getPartnerDomains = createAsyncThunk(
  'partnerDomains/get',
  async () => {
    const { data: fetchData } = await API.getPartnerDomains();
    return fetchData.data;
  },
);
export type GetPartnerDomainsOutput = ReturnType<
  typeof getPartnerDomains.fulfilled
>;
