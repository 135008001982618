var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TextField, MenuItem } from '@mui/material';
import { TIME_SLOTS } from './hours-list';
export const DzTimeSelect = (_a) => {
    var { formik, name, label, className, minTime, maxTime, options = TIME_SLOTS } = _a, rest = __rest(_a, ["formik", "name", "label", "className", "minTime", "maxTime", "options"]);
    return (_jsx(TextField, Object.assign({ label: label, error: Boolean(formik.touched[name] && formik.errors[name]), helperText: formik.touched[name] && formik.errors[name], select: true, className: className, value: formik.values[name], onChange: (e) => {
            formik.setFieldValue(name, e.target.value);
        } }, rest, { children: getMenuItems(options, minTime, maxTime) })));
};
function getMenuItems(options, minTime, maxTime) {
    if (!options)
        return [];
    return options
        .filter((_, index) => {
        if (minTime) {
            const indexOf = TIME_SLOTS.findIndex((el) => el.value === minTime);
            return index > indexOf;
        }
        if (maxTime) {
            const indexOf = TIME_SLOTS.findIndex((el) => el.value === maxTime);
            return index < indexOf;
        }
        return true;
    })
        .map((el) => (_jsx(MenuItem, Object.assign({ value: el.value }, { children: el.label }), el.value + el.label)));
}
