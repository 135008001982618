import { v4 as uuid } from 'uuid';
import { MapLike } from 'typescript';

import { format } from '@one-vision/date-utils';
import { CellValueUnion, Sheet } from '@flatfile/api/api';
import { FlatfileClient } from '@flatfile/api/Client';

export const formatData = (clients: MapLike<CellValueUnion | null>[]) => {
  const ovcidsMap = new Map();
  const ovaidsMap = new Map();
  const ovprjidMap = new Map();

  return clients.map((data) => {
    const clientKey = key(data, 'email');
    const addressKey = key(data, 'address1', 'address2', 'address.name');

    if (!ovcidsMap.has(clientKey)) {
      ovcidsMap.set(clientKey, `ovcid-${uuid()}`);
    }

    if (!ovaidsMap.has(addressKey)) {
      ovaidsMap.set(addressKey, `ovaid-${uuid()}`);
      ovprjidMap.set(addressKey, `ovprjid-${uuid()}`);
    }

    if (!data.ovcid) data.ovcid = ovcidsMap.get(clientKey);
    if (!data.ovaid) data.ovaid = ovaidsMap.get(addressKey);
    if (!data.ovprjid) data.ovprjid = ovprjidMap.get(addressKey);
    formatExpirationDate(data, 'warrantyExpirationDate');
    formatExpirationDate(data, 'legacyPlanExpiration');

    return data;
  });
};

function formatExpirationDate(
  data: MapLike<CellValueUnion | null>,
  dateName: 'warrantyExpirationDate' | 'legacyPlanExpiration',
) {
  if (data[dateName]) {
    try {
      data[dateName] = format(
        new Date(data[dateName]?.toString() || ''),
        'MM-dd-yyyy',
      );
    } catch {
      delete data[dateName];
    }
  }
}

async function extractSheets(
  workbookSheets: Sheet[],
  api: FlatfileClient,
) {
  const sheets = [];
  for (const element of workbookSheets) {
    const { data: records } = await api.records.get(element.id);

    sheets.push({
      ...element,
      ...records,
    });
  }

  return sheets;
}

export const extractDataFromFlatfileSheet = async (
  workbookSheets: Sheet[],
  api: FlatfileClient,
) => {
  const sheets = await extractSheets(workbookSheets, api);
  const finalList: MapLike<CellValueUnion | null>[] = [];

  sheets[0].records.forEach((record) => {
    const tempObj: MapLike<CellValueUnion | null> = {};

    Object.keys(record.values).forEach((key) => {
      tempObj[key] = record.values[key].value || null;
    });

    finalList.push(tempObj);
  });

  return formatData(finalList);
};

export function key(
  data: MapLike<CellValueUnion | null>,
  ...fields: string[]
) {
  return fields.map((field) => data[field]).join('_');
}

export function splitArray(
  arr: MapLike<CellValueUnion | null>[] = [],
  chunkSize = 1,
) {
  const results = [];
  while (arr.length) {
    results.push(arr.splice(0, chunkSize));
  }
  return results;
}
