import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { csn } from '@one-vision/utils';
import { useStyles } from './dz-projects.styles';
export const DzProjectsDataRow = ({ title, shouldDisplay, children, justifyTitle = 'center', zendesk, }) => {
    const classes = useStyles();
    if (!shouldDisplay)
        return null;
    return (_jsxs("div", Object.assign({ className: classes.projectDataLine }, { children: [_jsx("div", Object.assign({ className: csn(classes.projectDataRowTitle, [classes.projectDataRowTop, justifyTitle === 'top'], [classes.projectDataRowTitleZen, zendesk]) }, { children: title })), _jsx("div", Object.assign({ className: csn(classes.projectDataContent, [
                    classes.projectDataContentZen,
                    zendesk,
                ]) }, { children: children }))] })));
};
