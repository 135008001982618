import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => ({
    createOption: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: `${theme.px2rem(11)}`,
        color: theme.black.black100,
        width: '100%',
        height: '100%',
    },
    link: {
        fontSize: theme.px2rem(12),
        textDecoration: 'underline',
        color: theme.palette.primary.main,
        fontWeight: 500,
    },
}));
