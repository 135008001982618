import React from 'react';
/**
 * Used to inline external images as base64 data for template exporting.
 * Base64 conversion allows to embed them in canvas without tainting.
 * Regular images from external sources are not allowed by CORS policy.
 * Local images do not require inlining.
 * Base64 data is inlined as background image to preserve image proportions using `background-size: cover`,
 * because `object-fit: cover` is not supported by canvas.
 */

export interface DzBase64ImageProps {
  /**
   * External image source.
   */
  src: string;
  /**
   * Class name to be applied to the image.
   */
  className?: string;
  /**
   * Callback to be called when the image is loaded and inlined.
   */
  onImageLoad: () => void;
}
export const DzBase64Image = ({
  src,
  className,
  onImageLoad,
}: DzBase64ImageProps) => {
  const inlineExternalImage = async (
    e: React.SyntheticEvent<HTMLImageElement>,
  ) => {
    const data = await fetch(src);
    const blob = await data.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const img = e.target as HTMLImageElement;
      const base64data = reader.result;
      img.style.backgroundImage = `url(${base64data})`;
      onImageLoad?.();
    };
  };

  return (
    <img onLoad={inlineExternalImage} className={className} src={src} />
  );
};
