import React, { useMemo } from 'react';
import { Typography, LinearProgress } from '@mui/material';

import { useStyles } from './dz-data-import-progress.styles';

export interface Props {
  success?: number;
  error?: number;
  total?: number;
  done?: number;
}

export const DzDataImportProgress: React.FC<Props> = ({
  success = 0,
  total = 0,
  error = 0,
  done = 0,
}) => {
  const classes = useStyles();

  const percentage = useMemo(() => (done * 100) / total, [done, total]);

  return (
    <div>
      <div className={classes.progressBox}>
        <LinearProgress
          className={classes.progressBar}
          variant="determinate"
          value={percentage}
        />
        <Typography color="textSecondary" variant="subtitle2">
          {`${done}/${total}`} Records
        </Typography>
      </div>

      <div className={classes.separator}></div>

      <Typography
        style={{ fontWeight: 500 }}
        color="textPrimary"
        variant="subtitle1"
      >
        Data:
      </Typography>

      <Typography variant="body2">
        {`${success}`} Records Successfully Uploaded
      </Typography>
      <Typography variant="body2">{`${error}`} Skipped Records</Typography>
    </div>
  );
};
