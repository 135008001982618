import React from 'react';
import { DzAddClientToAddressDialog } from '../dz-users-page/add-client-to-address-dialog';
import { DzGlobalSearchDialog } from 'components/dz-global-search';
import {
  ModalIDs,
  DzProposalWonDialog,
  DzProposalLostDialog,
  DzSubmitDayOneDialog,
  DzCreateProjectDialog,
  DzConfirmationDialog,
  DzCreateOrganizationDialog,
  DzUpdateTechDialog,
  DzAddClientDialog,
  DzAddEditClientDialog,
  DzEditLinkDialog,
  DzSelectImageDialog,
  DzPreSoldDialog,
  DzCppExpDialog,
  DzContactSupportDialog,
  DzUpdatePlansDialog,
} from 'shared-ui';
import { DzHolidaysDialog } from '../dz-settings-page/dz-holidays-manager/dz-holidays-dialog';
import { DzBulkUsersImporterDialog } from 'components/dz-settings-page/dz-users/dz-bulk-users-importer';
import { useDzSelector } from 'core/redux';

const DzModals: React.FC = () => {
  const products = useDzSelector(
    (state) => state.newProjectDialog.products,
  );
  return (
    <>
      <DzCreateProjectDialog id={ModalIDs.createProject} />
      <DzCreateOrganizationDialog id={ModalIDs.createOrganization} />
      <DzConfirmationDialog id={ModalIDs.confirmation} />
      <DzProposalWonDialog id={ModalIDs.proposalWon} />
      <DzProposalLostDialog id={ModalIDs.proposalLost} />
      <DzSubmitDayOneDialog
        id={ModalIDs.submitDayOne}
        products={products}
      />
      <DzAddClientDialog id={ModalIDs.addClient} />
      <DzUpdateTechDialog id={ModalIDs.updateTech} />
      <DzAddEditClientDialog id={ModalIDs.addEditClient} />
      <DzAddClientToAddressDialog id={ModalIDs.addClientToAddress} />
      <DzGlobalSearchDialog id={ModalIDs.globalSearch} />
      <DzEditLinkDialog id={ModalIDs.editLink} />
      <DzSelectImageDialog id={ModalIDs.selectImage} />
      <DzHolidaysDialog id={ModalIDs.addOrEditHoliday} />
      <DzBulkUsersImporterDialog id={ModalIDs.BulkUsersImporter} />
      <DzPreSoldDialog id={ModalIDs.preSoldMembership} />
      <DzCppExpDialog id={ModalIDs.cppExpDate} />
      <DzContactSupportDialog id={ModalIDs.contactSupport} />
      <DzUpdatePlansDialog id={ModalIDs.updatePlans} />
    </>
  );
};

export default DzModals;
