import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        columnGap: theme.spacing(1.5),
        padding: `${theme.spacing(1.5)} 0`,
    },
    toggleLabel: {
        fontWeight: 500,
    },
    switchFormLabel: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: 0,
        width: '94%',
        marginBottom: theme.spacing(1),
    },
    zendeskSwitchFormLabel: {
        width: '97%',
    },
}));
