import { TextField } from '@mui/material';
import {
  DzSearchOrCreateList,
  useGlobalStyles,
  ClientFormNames,
  DzPhoneInput,
  DzClient,
  DzClientToAddress,
  ClientData,
  ClientErrors,
  DzClientFormData,
  FullClientData,
  ItemForList,
} from 'shared-ui';
import { logRender } from '@one-vision/utils';
import React, { useCallback, useMemo } from 'react';

interface Props {
  formName: string;
  isValidateForm: boolean;
  isSubmitting: boolean;
  clientData: DzClientFormData;
  updateClient: (client: DzClientFormData, canSubmit: boolean) => void;
  setClientData?: (client: DzClientFormData) => void;
  readonly?: boolean;
  onSelectExisting?: (selectedAddress: Partial<DzClient>) => void;
  clients?: (Pick<DzClientToAddress, 'email' | 'phone' | 'ovcid'> &
    Pick<DzClient, 'firstName' | 'lastName'>)[];
  clientItems?: ItemForList[];
  clientsAll?: (DzClient & DzClientToAddress)[];
  errors?: ClientErrors;
}

const VALID_EMAIL_FORMAT = 'email@mail.com';
const DEFAULT_VALID_ERROR = `Please enter a valid email address. Valid format: ${VALID_EMAIL_FORMAT}.`;
const NOT_UNIQUE_EMAIL_ERROR = ' is already being used by another user.';

export const DzClientFormView: React.FC<Props> = ({
  formName,
  readonly,
  clientData,
  clients = [],
  clientItems,
  onSelectExisting,
  isValidateForm,
  updateClient,
  setClientData,
  errors = {},
  clientsAll,
}) => {
  logRender(DzClientFormView);

  const classes = useGlobalStyles();
  const _clientItems = useMemo(() => {
    return clients
      .filter((client) => client?.email?.trim())
      .map((client) => ({
        id: client.ovcid,
        text: `${client.firstName} ${client.lastName} (${client.email})`,
        props: client,
      }));
  }, [clients]);

  const getErrors = useCallback(
    (newErrors: ClientErrors = {}) => {
      let errors = { ...clientData.errors, ...newErrors };
      const errorsNew = Object.keys(errors).filter(
        (key) => errors[key as keyof ClientErrors],
      );
      if (!errorsNew.length) {
        errors = {};
      }

      return errors;
    },
    [clientData],
  );

  const getUpdatedClientData = useCallback(
    (data: ClientData, newErrors?: ClientErrors) => {
      const additionalChanges: ClientData = {};
      const isEditForm = formName === ClientFormNames.EDIT;

      if (!isEditForm && (data.firstName || data.lastName)) {
        additionalChanges.ovcid = '';
      }
      if (!isEditForm && (data.email || data.phone)) {
        additionalChanges.ovaid = '';
      }

      const errors = getErrors(newErrors);
      const updatedClientData = {
        ...clientData,
        ...data,
        ...additionalChanges,
        errors,
      };

      if (!Object.keys(errors).length) {
        const { errors: _, ...clientDataWithOutErrors } =
          updatedClientData;

        return clientDataWithOutErrors;
      }

      return updatedClientData;
    },
    [clientData, formName, getErrors],
  );

  const handleUpdateClientData = useCallback(
    (data: ClientData, newErrors?: ClientErrors) => {
      const updatedClientData = getUpdatedClientData(data, newErrors);

      const canSubmit = Boolean(
        updatedClientData.email &&
          updatedClientData.firstName &&
          updatedClientData.lastName,
      );

      updateClient(updatedClientData as FullClientData, canSubmit);
    },
    [getUpdatedClientData, updateClient],
  );

  const getDisabledStatus = useCallback(() => {
    const isAddFormStatus =
      formName !== ClientFormNames.EDIT && Boolean(clientData.ovcid);

    return Boolean(readonly || isAddFormStatus);
  }, [formName, clientData, readonly]);

  const onChangeTextField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.currentTarget;

      handleUpdateClientData(
        { [name]: value },
        { [name]: value ? '' : 'Field is required.' },
      );
    },
    [handleUpdateClientData],
  );

  const onChangePhoneField = useCallback(
    (phone, errorText) =>
      handleUpdateClientData({ phone }, { phone: errorText }),
    [handleUpdateClientData],
  );

  const onChangeEmailField = useCallback(
    (email) => {
      // This is a temporary solution. The 'Client Form' component will be deleted after
      // all components will use the formik approach.
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isValid = re.test(email);
      let errorText;

      if (!email) {
        errorText = 'Email field is required';
      } else if (!isValid) {
        errorText = DEFAULT_VALID_ERROR;
      } else if (
        clientsAll?.some((item) => item.email?.includes(email.trim()))
      ) {
        errorText = `${email} ${NOT_UNIQUE_EMAIL_ERROR}`;
      }

      handleUpdateClientData({ email }, { email: errorText });
    },
    [clientsAll, handleUpdateClientData],
  );

  const getErrorInfo = (
    fieldName: keyof ClientErrors,
  ): { status: boolean; text: string } => {
    if (!isValidateForm) {
      return { status: false, text: '' };
    }

    const text =
      clientData.errors?.[fieldName] || errors?.[fieldName] || '';
    const status = Boolean(text);

    return { status, text };
  };

  return (
    <div data-testid="client-form-component">
      <DzSearchOrCreateList
        createSectionText="Create new User"
        selectSectionText="Associate with existing User"
        label="Email"
        error={getErrorInfo('email').status}
        errorText={getErrorInfo('email').text}
        disabled={readonly}
        items={clientItems || _clientItems}
        selectedId={clientData.ovcid ? clientData.ovcid : ''}
        inputText={clientData.email || ''}
        onValueChange={onChangeEmailField}
        onCreate={() => {
          if (setClientData) {
            setClientData({
              email: clientData.email || '',
              ovaid: '',
              ovcid: '',
            });
          }
        }}
        onSelect={(id) => {
          const selectedClient = clients.find(
            (client) => client.ovcid === id,
          );

          if (selectedClient) {
            if (setClientData) {
              setClientData({ ...selectedClient });
            }

            if (onSelectExisting) {
              onSelectExisting(selectedClient);
            }
          }
        }}
      />

      <TextField
        fullWidth
        className={classes.gcFieldMargin}
        label="First Name"
        name="firstName"
        disabled={getDisabledStatus()}
        value={clientData.firstName || ''}
        error={getErrorInfo('firstName').status}
        helperText={getErrorInfo('firstName').text}
        onChange={onChangeTextField}
      />

      <TextField
        fullWidth
        className={classes.gcFieldMargin}
        name="lastName"
        label="Last Name"
        disabled={getDisabledStatus()}
        value={clientData.lastName || ''}
        error={getErrorInfo('lastName').status}
        helperText={getErrorInfo('lastName').text}
        onChange={onChangeTextField}
      />

      <DzPhoneInput
        value={clientData.phone || ''}
        showError={getErrorInfo('phone').status}
        errorText={getErrorInfo('phone').text}
        disabled={getDisabledStatus()}
        onChange={onChangePhoneField}
      />
    </div>
  );
};
