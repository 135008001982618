import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DzAddress } from 'shared-ui';

export interface UpdateServiceLevelDialogSliceState {
  isOpen: boolean;
  address: DzAddress;
  originalAddress: DzAddress;
}

export const updateServiceLevelDialogSlice = createSlice({
  name: 'update-service-level-dialog',
  initialState: {
    isOpen: false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    address: {} as any,
  } as UpdateServiceLevelDialogSliceState,
  reducers: {
    openUpdateServiceLevelDialog: (
      _,
      action: PayloadAction<DzAddress>,
    ) => {
      return {
        isOpen: true,
        address: action.payload,
        originalAddress: action.payload,
      };
    },

    updateUpdateServiceLevelDialogState: (
      state,
      action: PayloadAction<
        Partial<
          Omit<UpdateServiceLevelDialogSliceState, 'originalAddress'>
        >
      >,
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
});
