import { Skeleton } from '@mui/material';
import { csn } from '@one-vision/utils';
import React from 'react';
import { useStyles } from './dz-users-sidebar-organizations-placeholder.styles';

export const DzUsersSidebarOrganizationsPlaceholder: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={csn(classes.tabWrapper, classes.tabWithCardWrapper)}>
      <div className={classes.content}>
        {new Array(3).fill(0).map((_, i) => (
          <div className={classes.card} key={i}>
            <div className={csn(classes.cardHeader)}>
              <Skeleton
                animation="wave"
                variant="circular"
                width={16}
                height={16}
                component="div"
              />
              <Skeleton
                animation="wave"
                variant="text"
                width={100}
                height={26}
              />
              <Skeleton
                animation="wave"
                variant="circular"
                width={16}
                height={16}
                component="div"
                sx={{
                  marginLeft: 'auto',
                }}
              />
              <Skeleton
                animation="wave"
                variant="circular"
                width={16}
                height={16}
                component="div"
              />
            </div>
            <div className={csn(classes.cardContent)}>
              <div className={classes.cardContentRow}>
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={16}
                  height={16}
                  component="div"
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={170}
                  height={26}
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={75}
                  height={26}
                />
              </div>
              <div className={classes.cardContentRow}>
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={16}
                  height={16}
                  component="div"
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={170}
                  height={26}
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={75}
                  height={26}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
