import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../shared';
import { useEffect } from 'react';
export const FETCH_API_PROMPTS_QUERY_KEY = 'FETCH_API_PROMPTS_QUERY_KEY';
export const useAiPrompts = ({ addressId, onSuccess, }) => {
    var _a, _b;
    const apiClient = useApiClient();
    const { data: response, isLoading, isSuccess, refetch, } = useQuery({
        queryKey: [FETCH_API_PROMPTS_QUERY_KEY, addressId],
        queryFn: () => apiClient.getAiPrompts({ filter: { active: true } }),
        staleTime: 0,
        enabled: !!addressId,
    });
    const data = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data;
    useEffect(() => {
        onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(data !== null && data !== void 0 ? data : []);
    }, [isSuccess, onSuccess]);
    return {
        data: (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data,
        isLoading,
        refetch,
    };
};
