import React from 'react';
import { GlobalStyles } from '@mui/material';
import { isRenderingInIframe } from 'core/runtime';

export const globalStyles = (
  <GlobalStyles
    styles={{
      body: {
        paddingLeft: isRenderingInIframe ? 0 : '68px',
        paddingTop: isRenderingInIframe ? 0 : '72px',
        minWidth: '1000px',
        // The "overlay" value is not recognized by GlobalStyles.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        overflowX: 'overlay' as any,
      },
    }}
  />
);
