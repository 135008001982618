import React from 'react';
import ReactDOM from 'react-dom/client';
import ensureRoot from 'shared/utils/ensure-root';
import DzApp from './components/dz-app';
import DzErrorBoundary, {
  DzAppLevelFallback,
} from './components/dz-error-boundary';
import { applyTheme } from './core/theme';

import { auth } from '@one-vision/login';
import { authProvider } from '@one-vision/login';
import { config } from 'core/config';
import { isRenderingInIframe } from 'core/runtime';
import { LocalStorageManager } from 'shared';

if (isRenderingInIframe) {
  window.onmessage = async function (event: MessageEvent<string>) {
    if (event.origin.includes(process.env.REACT_APP_IFRAME_ORIGIN)) {
      // Initialize localStorage from iframe query with auth data.
      const iframeData = JSON.parse(event.data) as {
        storage: Record<string, string>;
        partnerId: string;
      };

      Object.entries(iframeData.storage).forEach(
        ([key, value]: [string, string]) => {
          window.localStorage.setItem(key, value);
        },
      );
      auth.setPartnerId(Number(iframeData.partnerId));
      LocalStorageManager.removeReduxState();
      initApp();
    }
  };
} else {
  initApp();
}

function initApp() {
  authProvider.setup({
    cognitoClientId: config.cognitoClientId,
    cognitoUserPoolId: config.cognitoUserPoolId,
    cognitoUserPoolRegion: config.cognitoUserPoolRegion,
    env: config.stage,
  });

  const ThemedApp = applyTheme(DzApp);
  const container = ensureRoot('app');
  const root = ReactDOM.createRoot(container);

  root.render(<ThemedApp />);
}

const ThemedApp = applyTheme(() => {
  return (
    <DzErrorBoundary fallback={<DzAppLevelFallback />}>
      <ThemedApp />
    </DzErrorBoundary>
  );
});
