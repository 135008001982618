import React from 'react';
import { csn } from '@one-vision/utils';

import { useStyles } from './dz-view-tabs.styles';
import { Tab } from '@mui/material';

interface Props {
  isActive: boolean;
  classes: ReturnType<typeof useStyles>;
  value: string;
  label: string;
  handleTabClick: (value: string) => void;
}

export const DzViewTab: React.FC<Props> = ({
  isActive,
  classes,
  value,
  label,
  handleTabClick,
}) => {
  const handleClick = (event: React.SyntheticEvent) => {
    if (event.type === 'mouseover' || event.type === 'mouseleave') {
      return;
    }

    handleTabClick(value);
  };

  return (
    <Tab
      classes={{
        root: csn(classes.tab, classes.tabWrapper, [
          classes.selectedTabWrapper,
          isActive,
        ]),
      }}
      icon={<div className={classes.tabIndicator} />}
      value={value}
      label={label}
      disableRipple={true}
      disableFocusRipple={true}
      onClick={handleClick}
    />
  );
};
