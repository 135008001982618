import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect } from 'react';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Image from '@tiptap/extension-image';
import Placeholder from '@tiptap/extension-placeholder';
import DzEditorContent from './dz-editor-content.view';
import DzEditorMenu from './dz-editor-menu.view';
import { useStyles } from './dz-editor.styles';
import { logError } from '@one-vision/utils';
import { ALLOWED_S3_CONTENT_TYPE } from '../../..';
const DzRichTextEditor = ({ content = '', onUpdate = () => undefined, placeholder = '', withMenu = true, editable = true, editorHookDependency, s3Client, customClasses, }) => {
    const classes = Object.assign(Object.assign({}, useStyles({ placeholder })), customClasses);
    const uploadImage = useCallback(async (file) => {
        if (!ALLOWED_S3_CONTENT_TYPE[file.type]) {
            throw new Error('File type is not supported');
        }
        if (file.size / 1024 / 1024 > 5) {
            throw new Error('Image is too big');
        }
        const imageUrl = await s3Client.uploadFile(file);
        return imageUrl;
    }, [s3Client]);
    const handleDrop = useCallback((view, event, _, moved) => {
        if (!moved &&
            event.dataTransfer &&
            event.dataTransfer.files &&
            event.dataTransfer.files[0]) {
            uploadImage(event.dataTransfer.files[0])
                .then((src) => {
                const { schema } = view.state;
                const coordinates = view.posAtCoords({
                    left: event.clientX,
                    top: event.clientY,
                });
                if (!coordinates) {
                    throw new Error('Position is outside the editor');
                }
                const node = schema.nodes.image.create({ src });
                const transaction = view.state.tr.insert(coordinates.pos, node);
                return view.dispatch(transaction);
            })
                .catch((error) => {
                logError(error);
            });
            return true;
        }
        return false;
    }, [uploadImage]);
    const editor = useEditor({
        editable,
        extensions: [
            StarterKit.configure({
                codeBlock: {
                    HTMLAttributes: {
                        class: classes.codeBlock,
                    },
                },
                blockquote: {
                    HTMLAttributes: {
                        class: classes.blockQuote,
                    },
                },
            }),
            Underline,
            TaskList.configure({
                HTMLAttributes: {
                    class: classes.richTaskList,
                },
            }),
            TaskItem.configure({
                nested: true,
                HTMLAttributes: {
                    class: classes.richTaskItem,
                },
            }),
            Link.configure({
                protocols: ['ftp', 'mailto'],
                autolink: true,
                HTMLAttributes: {
                    class: classes.richLink,
                },
            }),
            Image.configure({
                HTMLAttributes: {
                    class: classes.richImage,
                },
            }),
            Placeholder.configure({
                placeholder: 'My Custom Placeholder',
                emptyEditorClass: classes.emptyEditor,
            }),
        ],
        onUpdate,
        content,
        editorProps: {
            attributes: {
                class: classes.proseMirror,
            },
            handleDrop,
        },
    }, [editorHookDependency, editable, handleDrop]);
    useEffect(() => {
        if (!editor) {
            return;
        }
        editor.setOptions({
            editorProps: {
                handlePaste: function (_, event) {
                    if (event.clipboardData && event.clipboardData.files[0]) {
                        uploadImage(event.clipboardData.files[0]).then((src) => {
                            editor.commands.setImage({ src });
                        });
                        return true;
                    }
                    return false;
                },
                handleDrop,
            },
        });
    }, [editor, uploadImage]);
    return (_jsxs("div", Object.assign({ className: classes.editorWrapper }, { children: [withMenu && (_jsx(DzEditorMenu, { editor: editor, uploadImage: uploadImage })), _jsx(DzEditorContent, { editor: editor })] })));
};
export default DzRichTextEditor;
