import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { logRender } from '@one-vision/utils';
import { DzUsersView } from './dz-users.users-view';
import { useStyles } from './dz-users.layout.styles';
import { DzUsersPartnerDomains } from './dz-users.partner-domains.view';

export function DzUsersLayoutView() {
  logRender(DzUsersLayoutView);
  const classes = useStyles();

  return (
    <Stack direction="column" spacing={3} className={classes.stack}>
      <Box>
        <Typography variant="subtitle1" marginBottom={1}>
          Manage who has access to ProVision
        </Typography>
        <Divider orientation="horizontal" />
      </Box>
      <Box>
        <Typography variant="subtitle2">Allowed Domains</Typography>
        <Typography variant="small2">
          Anyone with an email address at these domains is allowed to sign
          up for this workspace.
        </Typography>
        <DzUsersPartnerDomains />
      </Box>
      <Stack flex={1}>
        <Typography variant="subtitle2" className={classes.subtitle}>
          Manage Users
        </Typography>
        <DzUsersView />
      </Stack>
    </Stack>
  );
}
