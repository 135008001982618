import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        flexDirection: 'row',
        columnGap: theme.spacing(1),

        maxHeight: theme.isIframe
          ? '100vh'
          : `calc(100vh - ${theme.px2rem(theme.dz.topBar.height)})`,
        minHeight: theme.isIframe
          ? '100vh'
          : `calc(100vh - ${theme.px2rem(theme.dz.topBar.height)})`,
        width: theme.isIframe
          ? '100vw'
          : `calc(100vw - ${theme.px2rem(theme.dz.appBar.width)})`,
        position: 'fixed',

        overflow: 'hidden',
        backgroundColor: theme.palette.custom.OV.sys.surfaceBright,
        padding: theme.isIframe ? 0 : theme.spacing(1),
      },
      wrapper: {
        position: 'relative',
        width: '100%',
        overflowY: 'auto',
        border: theme.isIframe
          ? 'none'
          : `1px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
        borderRadius: theme.isIframe ? 0 : '4px',
        backgroundColor:
          theme.palette.custom.OV.sys.surfaceContainerLowest,
      },
      tab: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor:
          theme.palette.custom.OV.sys.surfaceContainerLowest,
        borderBottom: `1px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
      },
    };
  },
  { name: 'dz-settings-page' },
);
