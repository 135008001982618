import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { useStyles } from './dz-delete-confirmation-dialog.styles';

interface Props {
  type: 'user' | 'partner-domain';
  open: boolean;
  onCancel: () => void;
  onDelete: () => void;
}

export const DzDeleteConfirmationDialogView: React.FC<Props> = (props) => {
  const classes = useStyles();
  const typeText = props.type === 'user' ? 'user' : 'partner domain';

  return (
    <Dialog
      open={props.open}
      classes={{
        paper: classes.deleteConfirmation,
      }}
      onClose={props.onCancel}
      disableEnforceFocus={true}
    >
      <DialogTitle>
        <Box display="flex" flexDirection="column" alignItems="center">
          <DeleteForeverIcon className={classes.deleteIcon} />
          Are you sure?
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText align="center">
          Do you really want to delete this {typeText}?
          <br />
          This process cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="primary" autoFocus>
          Cancel
        </Button>
        <Button
          onClick={props.onDelete}
          className={classes.deleteButton}
          variant="contained"
          color="primary"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
