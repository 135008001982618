import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { MapLike } from 'typescript';
import { Typography, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import DomainIcon from '@mui/icons-material/Domain';
import {
  DzSidePanelHeader,
  DzAddressContent,
  isResidential,
  getAddressTitle,
  DzAddress,
  DzBrand,
  DzOwner,
  DzPartner,
  DzAsyncDispatch,
  DzClientLabel,
  DzClientLabelsList,
  JsonApiEntity,
  getFieldsFromAttributes,
} from 'shared-ui';

import { useStyles } from './dz-users-sidebar-organizations.styles';
import { actions, thunks, useSelectUserSidebar } from 'core/redux';
import { DzNoOrganizationIcon } from 'components/shared/dz-icons';
import { DzUsersSidebarOrganizationsPlaceholder } from './dz-users-sidebar-organizations-placeholder';
import { useDispatch } from 'react-redux';
import { API } from 'core/api';
import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { JsonApiError } from 'types';
import { logError } from '@one-vision/utils';
import { usePartnerDetails } from 'shared/hooks';

interface Props {
  addresses: DzAddress[];
  isLoading: boolean;
  setCurrentAddress: Dispatch<SetStateAction<DzAddress | null>>;
  brands: DzBrand[];
  owners: DzOwner[];
  menuList: Array<{
    name: string;
    icon: JSX.Element;
    action: () => void;
  }>;
  isModifyOrgMenuOpen: boolean;
  currentAddress: DzAddress | null;
  toggleModifyOrgMenu: () => void;
  partner: DzPartner | undefined;
  clientLabels: DzClientLabel[];
  onOrgCardNameClick?: (id: string) => void;
}

export const DzUsersSidebarOrganizations: React.FC<Props> = ({
  addresses,
  isLoading,
  setCurrentAddress,
  brands,
  owners,
  menuList,
  isModifyOrgMenuOpen,
  currentAddress,
  toggleModifyOrgMenu,
  partner,
  clientLabels,
  onOrgCardNameClick,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch<DzAsyncDispatch>();
  const { client } = useSelectUserSidebar();
  const memoizedClients = useMemo(
    () => (client ? [client] : []),
    [client],
  );
  const [originalClientLabels, setOriginalClientLabels] = useState<
    MapLike<JsonApiEntity<DzClientLabel>[]>
  >({});

  const { data } = usePartnerDetails();

  const getLabelsForClient = useCallback(async () => {
    if (client) {
      try {
        const { data } = await API.getClientLabels({
          ovcid: client.ovcid,
        });
        const labelsForAddresses = data.data;

        if (Object.keys(labelsForAddresses)) {
          setOriginalClientLabels(labelsForAddresses);
        }
      } catch (error) {
        const { errors } =
          (error as AxiosError<{ errors: JsonApiError[] }>)?.response
            ?.data || {};

        logError(errors?.[0] || new Error('Client labels error'));
      }
    }
  }, [client]);

  useEffect(() => {
    if (client) {
      getLabelsForClient();
    }
  }, [client, getLabelsForClient]);

  if (isLoading) {
    return <DzUsersSidebarOrganizationsPlaceholder />;
  }
  return (
    <>
      {!addresses.length ? (
        <div className={classes.NoOrgAssociated}>
          <DzNoOrganizationIcon
            viewBox="0 0 72 72"
            classes={{ root: classes.NoOrgAssociatedIcon }}
          />
          <Typography className={classes.NoOrgAssociatedText}>
            No Organizations Associated.
          </Typography>
        </div>
      ) : null}
      <div className={classes.contentWrapper}>
        {!!addresses.length &&
          addresses.map((address, index) => (
            <div
              key={`${getAddressTitle(address)}${index}`}
              className={classes.orgCard}
            >
              <div
                className={classes.orgCardHeader}
                onClick={() => setCurrentAddress(address)}
              >
                <DzSidePanelHeader
                  clients={memoizedClients}
                  apiClient={API}
                  icons={
                    isResidential(address, brands) ? (
                      <HomeIcon />
                    ) : (
                      <DomainIcon />
                    )
                  }
                  title={getAddressTitle(address)}
                  isLoading={!addresses}
                  menuItems={menuList}
                  menuIsOpen={
                    isModifyOrgMenuOpen &&
                    currentAddress?.ovaid === address.ovaid
                  }
                  switchMenu={toggleModifyOrgMenu}
                  customClasses={{ headerActions: classes.headerActions }}
                  address={address}
                  onCardNameClick={onOrgCardNameClick}
                />
              </div>

              <DzAddressContent
                address={address}
                customClasses={{ orgData: classes.orgData }}
                owners={owners}
                actions={actions}
                thunks={thunks}
                dispatch={dispatch}
                partner={partner}
                partnerDetails={data!}
                renderLabels={
                  <Box display="flex" flexDirection="row" flexWrap="wrap">
                    <DzClientLabelsList
                      key={`user-sidebar-client-labels-${address.ovaid}-${index}`}
                      defaultClientLabels={getFieldsFromAttributes(
                        originalClientLabels[address.ovaid] || [],
                      )}
                      clientLabels={clientLabels}
                      ovaid={address.ovaid}
                      ovcid={client?.ovcid || ''}
                      apiClient={API}
                      thunks={thunks}
                      dispatch={dispatch}
                    />
                  </Box>
                }
              />
            </div>
          ))}
      </div>
    </>
  );
};
