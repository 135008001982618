import { makeStyles } from '@mui/styles';

import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        width: '100%',
      },
      deleteContainer: {
        textAlign: 'right',
      },
      textField: {
        '& .MuiInputBase-root': {
          '&::before': {
            borderBottomColor: 'transparent',
          },
        },
      },
      tableContainer: {
        boxShadow: 'none',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.black.black10,
        borderRadius: '0px',
      },
    };
  },
  { name: 'dz-grid-editor' },
);
