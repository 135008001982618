import React from 'react';
import { GridName } from './dz-grid.types';
import { useStyles } from './dz-grid.styles';

interface Props {
  gridName: GridName;
}

const Overlay = ({ gridName }: Props) => {
  const classes = useStyles();

  return (
    <span
      className={classes.noDataMessage}
    >{`No ${gridName} to show`}</span>
  );
};

export default Overlay;
