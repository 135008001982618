export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const first = a[orderBy] || 0;
  const second = b[orderBy] || 0;

  if (second < first) {
    return -1;
  }
  if (second > first) {
    return 1;
  }
  return 0;
}

export type Order = 'asc' | 'desc';

export function getComparator<T>(
  order: Order,
  orderBy: keyof T,
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
