import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    // @TODO: redundant !important required fix
    return {
        header: {
            backgroundColor: theme.palette.secondary.main,
            color: '#fff',
            padding: `14px ${theme.spacing(3)}`,
        },
        paper: {
            margin: 0,
            borderRadius: '4px 0px 0px 4px',
            height: '100vh',
            maxHeight: '100vh',
            width: '550px',
            backgroundColor: theme.palette.custom.OV.sys.surfaceContainerLowest,
            backgroundImage: 'none',
        },
        zendesk: {
            width: theme.zendeskWidth,
            borderRadius: 0,
        },
        container: {
            justifyContent: 'flex-end',
        },
        noBackdrop: {
            backgroundColor: 'unset',
        },
        content: {
            //need to investigate: default style is higher than custom
            padding: `${theme.spacing(3.7)} ${theme.spacing(5)} !important`,
        },
        contentZen: {
            //need to investigate: default style is higher than custom
            padding: `${theme.spacing(2.5)} ${theme.px2rem(3)} !important`,
        },
        contentSubmitting: {
            opacity: '0.35',
        },
        submissionCircularProgress: {
            position: 'absolute',
            width: '40px',
            height: '40px',
            left: 'calc(50% - 20px)',
            top: 'calc(50% - 20px)',
            zIndex: 10,
        },
        dialogActions: {
            padding: theme.spacing(3),
            backgroundColor: theme.palette.custom.OV.sys.surfaceContainer,
        },
        okButton: {
            color: theme.palette.common.white,
            height: '38px',
            minWidth: '162px',
        },
    };
}, { name: 'dz-dialog', index: 0 });
