import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        title: {
            fontSize: theme.px2rem(18),
            marginBottom: theme.spacing(3),
            fontWeight: 'normal',
        },
        titleZen: {
            fontSize: theme.px2rem(14),
            marginBottom: theme.spacing(2),
        },
        fieldWithInfo: {
            width: '94%',
            marginBottom: theme.spacing(2),
        },
        infoIconWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            marginLeft: 'auto',
            width: '5%',
            '& > svg': {
                position: 'absolute',
                top: theme.px2rem(18),
            },
        },
        toggleLabel: {
            fontWeight: 500,
        },
        switchFormLabel: {
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: 0,
            width: '94%',
            marginBottom: theme.spacing(1),
        },
        zendeskSwitchFormLabel: {
            width: '97%',
        },
    };
}, { name: 'dz-update-tech-dialog', index: 0 });
