import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        productCollapsible: {
            marginBottom: theme.spacing(2),
        },
        switchRow: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        priceFieldContainer: {
            marginTop: theme.spacing(2),
        },
        collapsibleInputs: {
            paddingLeft: theme.spacing(2),
            borderLeft: `2px solid ${theme.palette.grey[300]}`,
        },
    };
}, { name: 'dz-update-plans-dialog' });
