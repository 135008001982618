import { makeStyles } from '@mui/styles';

import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      stack: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        height: '100%',
        maxWidth: theme.px2rem(900),
        minWidth: theme.px2rem(800),
      },
      subtitle: {
        marginBottom: theme.spacing(2),
      },
    };
  },
  { name: 'dz-users-layout' },
);
