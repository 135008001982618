import { logError } from '@one-vision/utils';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'core/api';

export const fetchPrimaryClientIds = createAsyncThunk<string[]>(
  'primaryClientIds/get',
  async () => {
    const params = {
      primaryClientsOnly: 'true',
    };

    try {
      const response = await API.getPrimaryClientIds(params);

      return response.data;
    } catch (error) {
      logError(error);
      return [];
    }
  },
);

export const primaryClientIdsSlice = createSlice<
  string[],
  Record<string, never>,
  'primaryClientIds'
>({
  name: 'primaryClientIds',
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrimaryClientIds.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});
