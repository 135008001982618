import { format } from '@one-vision/date-utils';

export const escapeCsvCharacters = (originalString: string) => {
  const escapedValue = originalString.replace(/"/g, '""');
  return escapedValue.match(/("|,|\n)/g)
    ? `"${escapedValue}"`
    : escapedValue;
};

export const exportToCsv = (
  fileNameSeed: string,
  rows: string[][],
  options: {
    withTimestamp: boolean;
  } = { withTimestamp: false },
): void => {
  const csvRows = rows.map((row) => {
    return row
      .map((value) => {
        return escapeCsvCharacters(
          value == undefined ? '' : value.toString(),
        );
      })
      .join(',');
  });

  const csvContent = csvRows.join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  const fileName =
    fileNameSeed +
    (options.withTimestamp
      ? '_' +
        format(new Date(), 'yyyy-MM-dd hh:mm:ss')
          .replace(/ /g, '_')
          .replace(/:/g, '')
      : '') +
    '.csv';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((navigator as any).msSaveBlob) {
    // IE 10+
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (navigator as any).msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
