import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      button: {
        minWidth: theme.px2rem(170),
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        display: 'grid',
        gap: theme.px2rem(12),
        gridTemplateColumns: `${theme.px2rem(15)} 1fr`,
        justifyItems: 'center',
        justifyContent: 'space-between',
        lineHeight: 2,
        fontWeight: 600,
        filter:
          'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24))',
      },
      circularBtn: {
        minWidth: theme.px2rem(30),

        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        filter:
          'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24))',
      },
      iconButton: {
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
      },
    };
  },
  { name: 'dz-default-header' },
);
