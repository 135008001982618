/**
 * User endpoints v2 returns a different shape than the v1 users endpoint.
 * This function converts the v2 user to the v1 owner to isolate the reconciling of both versions.
 */
export function userToOwner(user) {
    var _a, _b;
    return {
        ownerId: user.id,
        partnerId: Number(user.relationships.partner.data[0].id),
        name: user.attributes.firstName + ' ' + user.attributes.lastName,
        email: user.attributes.email,
        cognitoUsername: user.attributes.cognitoUsername,
        userGroups: ((_b = (_a = user.relationships.userGroup) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : []).map((userGroup) => userGroup.id),
    };
}
