import React, { useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { csn } from '@one-vision/utils';
import { useStyles } from './dz-stripe.styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const LOGIN_STRINGS = {
  title: 'Connected Stripe Account',
  infoFirstLine: 'Your stripe account is setup.',
  infoSecondLine:
    'Stripe is used to collect payment methods and manage subscriptions for your clients.',
  buttonText: 'Sign in to Stripe',
};

const STRIPE_LOGIN_URL = 'https://dashboard.stripe.com/login';

export const DzLoginBanner = () => {
  const classes = useStyles();

  const handleClick = useCallback(() => {
    window.open(STRIPE_LOGIN_URL, '_blank');
  }, []);

  return (
    <Box className={csn(classes.root, classes.animatedAppearance)}>
      <Typography className={classes.headerName}>
        {LOGIN_STRINGS.title}
      </Typography>
      <Box className={csn(classes.box, classes.greenBackground)}>
        <Box className={classes.boxTextContainer}>
          <CheckCircleOutlineIcon className={classes.loginIcon} />
          <Box className={classes.boxText}>
            <Typography className={classes.boxMainLine}>
              {LOGIN_STRINGS.infoFirstLine}
            </Typography>
            <Typography>{LOGIN_STRINGS.infoSecondLine}</Typography>
          </Box>
        </Box>
        <Button
          className={classes.loginButton}
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          {LOGIN_STRINGS.buttonText}
        </Button>
      </Box>
    </Box>
  );
};
