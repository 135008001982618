import React, { useCallback, useEffect } from 'react';
import Mousetrap from 'mousetrap';
import 'mousetrap-global-bind';
import { csn } from '@one-vision/utils';
import { Button } from '@mui/material';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import { useStyles } from './dz-global-search-button.styles';
import { ModalIDs, useModalManager } from 'shared-ui';
import { DzGlobalSearchDialogProps } from './dz-global-search-dialog';

export const enableGlobalSearchHotkeys = (
  openGlobalSearch: () => void,
  isMac: boolean,
) => {
  Mousetrap.bindGlobal([isMac ? 'command+k' : 'alt+k'], openGlobalSearch);
};

export const disableGlobalSearchHotkeys = (isMac: boolean) => {
  Mousetrap.unbindGlobal([isMac ? 'command+K' : 'alt+K']);
};

const MAC_NAVIGATOR_STR = 'macintel';

export const DzGlobalSearchButton = () => {
  const isMac = window.navigator.platform
    .toLowerCase()
    .includes(MAC_NAVIGATOR_STR);

  const { openModal } = useModalManager();

  const blurActiveElement = () => {
    document.activeElement?.blur();
  };

  const openGlobalSearchModal = useCallback(() => {
    blurActiveElement();

    openModal<DzGlobalSearchDialogProps, undefined>(
      ModalIDs.globalSearch,
      {},
    );
  }, [openModal]);

  const classes = useStyles();

  useEffect(() => {
    enableGlobalSearchHotkeys(openGlobalSearchModal, isMac);

    return () => {
      disableGlobalSearchHotkeys(isMac);
    };
  }, [isMac, openGlobalSearchModal]);

  return (
    <Button
      variant="outlined"
      className={classes.button}
      onClick={openGlobalSearchModal}
    >
      <div className={classes.text}>Search ProVision...</div>
      <div className={csn(classes.keyCombo, classes.text)}>
        {isMac ? <KeyboardCommandKeyIcon /> : <span>Alt</span>} + K
      </div>
    </Button>
  );
};
