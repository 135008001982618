import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { API } from 'core/api';
import {
  DzAddress,
  DzDialog,
  withModalManager,
  DzAsyncDispatch,
  DzOrgAutocomplete,
  ApiClient,
  DzAddressPrefixed,
  ModalManagerProps,
} from 'shared-ui';
import { actions, thunks } from 'core/redux';
import { ClientToAddress } from 'types';
import { Typography } from '@mui/material';
import { useStyles } from './dz-add-client-to-address-dialog.styles';
import { logError } from '@one-vision/utils';

export interface DzAddClientToAddressDialogProps {
  clientData: ClientToAddress;
  addressesToFilter?: DzAddress[] | DzAddressPrefixed[];
}

export const DzAddClientToAddressDialog: React.FC<
  DzAddClientToAddressDialogProps & ModalManagerProps<ClientToAddress>
> = ({ isOpen, close, clear, clientData, addressesToFilter }) => {
  const classes = useStyles();

  const [addressSelected, setSelectedAddress] = useState<DzAddress | null>(
    null,
  );

  const dispatch = useDispatch<DzAsyncDispatch>();

  const handleClose = useCallback(() => {
    close();
    clear();
  }, [close, clear]);

  const handleSubmit = useCallback(async () => {
    if (addressSelected) {
      await dispatch(
        thunks.addAddressToClient({
          data: { ...clientData, ovaid: addressSelected.ovaid },
        }),
      )
        .unwrap()
        .then((clientInfo) => {
          if (!clientInfo) {
            dispatch(
              actions.updateSnackbar({
                type: 'error',
                text: 'Oops! Something went wrong...',
              }),
            );
            return;
          }
          dispatch(
            actions.updateSnackbar({
              type: 'success',
              text: 'Organization associated',
            }),
          );
          close({ ...clientData, ovaid: addressSelected.ovaid });
        })
        .catch((err) => {
          logError(err);
          dispatch(
            actions.updateSnackbar({
              type: 'error',
              text: 'Oops! Something went wrong...',
            }),
          );
        })
        .finally(() => {
          setSelectedAddress(null);
        });
    }
  }, [clientData, addressSelected, dispatch, setSelectedAddress, close]);

  const content = useMemo(
    () => (
      <>
        <Typography component="label" className={classes.label}>
          Select Organization
        </Typography>
        <DzOrgAutocomplete
          apiClient={API as unknown as ApiClient}
          actions={actions}
          dispatch={dispatch}
          selectedAddress={addressSelected}
          onAddressSelect={setSelectedAddress}
          addressToFilter={addressesToFilter}
        />
      </>
    ),
    [addressSelected, addressesToFilter, classes, dispatch],
  );

  return (
    <>
      <DzDialog
        isOpen={isOpen}
        caption="Associate user with an Organization"
        okButtonText="Save"
        okButtonDisabled={!addressSelected}
        content={content}
        onClose={handleClose}
        cancelButtonText="Cancel"
        onOk={handleSubmit}
        disablePortal={true}
      />
    </>
  );
};

export default withModalManager<DzAddClientToAddressDialogProps>()(
  DzAddClientToAddressDialog,
);
