export function formatAddress(address1: string, address2: string) {
  if (address1 && address2) {
    return `${address1} ${address2}`;
  }

  if (address1) {
    return address1;
  }

  return '';
}
