import { useCallback, useState } from 'react';
import { thunks } from 'core/redux';
import { useDispatch } from 'react-redux';

import { DzAsyncDispatch } from 'shared-ui';
import { PartnerDomain } from 'core/redux/partner-domains';

export function useDeletePartnerDomain(partnerDomain: PartnerDomain) {
  const dispatch = useDispatch<DzAsyncDispatch>();
  const [loading, setLoading] = useState(false);

  const execute = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(
        thunks.deletePartnerDomain(partnerDomain.partnerDomainId),
      ).unwrap();
    } finally {
      setLoading(false);
    }
  }, [dispatch, partnerDomain.partnerDomainId]);

  return { deletePartnerDomain: execute, loading };
}
