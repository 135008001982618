import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { csn } from '@one-vision/utils';
import { getAbbr, pickColor } from '../../../utils';
import { useStyles } from './dz-owner-icon.styles';
export const DzOwnerIcon = ({ name, customClasses, }) => {
    const classes = useStyles();
    return (_jsx(_Fragment, { children: name ? (_jsx("div", Object.assign({ style: {
                backgroundColor: pickColor(name),
            }, className: csn(classes.ownerIcon, customClasses === null || customClasses === void 0 ? void 0 : customClasses.ownerIcon) }, { children: getAbbr(name) }))) : (_jsx(Box, Object.assign({ display: "flex", alignItems: "center", className: csn(customClasses === null || customClasses === void 0 ? void 0 : customClasses.unassignedAvatarList) }, { children: _jsx(AccountCircleIcon, { className: csn(classes.ownerUnassignedCircle, customClasses === null || customClasses === void 0 ? void 0 : customClasses.ownerUnassignedCircle) }) }))) }));
};
