import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import { useStyles } from './dz-empty.styles';
export const DzEmptyView = ({ title, description, icon: Icon, }) => {
    const classes = useStyles();
    const getIcon = () => {
        if (Icon) {
            return _jsx(Icon, { className: classes.icon });
        }
        else {
            return _jsx(InboxIcon, { className: classes.icon });
        }
    };
    return (_jsxs("div", Object.assign({ className: classes.wrapper }, { children: [getIcon(), _jsx(Typography, Object.assign({ className: classes.text }, { children: title || 'No Data' })), description && (_jsx(Typography, Object.assign({ className: classes.text }, { children: description })))] })));
};
