import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Typography, } from '@mui/material';
import { add, addZeroTime, format, isAfter, isValid, matchFormat, } from '@one-vision/date-utils';
import { logError } from '@one-vision/utils';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { day1PredefinedCppLengths, } from '../../..';
import { DzPreSoldSwitch } from '../../../components/dialogs';
import { ISO_DATE_FORMAT, ISO_DATE_MASK } from '../../../const';
import { DzProjectStage, } from '../../../types';
import { DzCircularProgress, DzDateTimePicker, useDrawerStyles, useFormStyles, } from '../../shared';
import { withModalManager } from '../../shared/dz-modal-manager';
import { usePartnerConfig } from '../../shared/dz-partner-config-provider';
import DzDocumentationAlert from './dz-documentation-alert.view';
import DzCppForm from './dz-cpp-form.view';
import { DzCppSwitch } from './dz-cpp-switch.view';
import DzPreSoldForm from './dz-pre-sold-form.view';
import { useOfferedProducts } from '../../shared/hooks/useOfferedProducts';
import { TODAY_DATE_STRING } from '../dz-cpp-exp-dialog/dz-cpp-exp-dialog.view';
import { DzMembershipSalesNote } from './dz-membership-sales-notes.view';
import { useStyles } from './dz-submit-day-one-dialog.styles';
import dayjs from 'dayjs';
import { usePreSoldMembership } from '../../shared/hooks/usePreSoldMembership';
const dateDiffInDays = (a, b) => {
    return dayjs(a).diff(b, 'day');
};
function convertCentsToDollars(price) {
    return price / 100;
}
const DzSubmitDayOneDialog = ({ estimatedDay1Date = TODAY_DATE_STRING, isOpen, close, clear, addressId, projectId, apiClient, actions, thunks, dispatch, zendesk, partner, }) => {
    var _a, _b, _c, _d;
    const drawerClasses = useDrawerStyles();
    const formClasses = useFormStyles();
    const ownClasses = useStyles();
    const partnerConfig = usePartnerConfig();
    const [address, setAddress] = useState(null);
    const { products: offeredProducts, loading: loadingOfferedProducts } = useOfferedProducts({
        brandId: (address === null || address === void 0 ? void 0 : address.ovbid) || '',
    });
    const { preSoldMembership, loading: loadingPreSoldMembership } = usePreSoldMembership({ addressId: addressId || '' });
    const handleSubmit = useCallback(async (values) => {
        if (!address || !projectId || !addressId) {
            return;
        }
        const projectPayload = {
            day1StartDate: values.project.day1StartDate,
            isDay1DateFinalized: true,
            projectStageId: 3,
            estimatedDay1Date: values.project.day1StartDate,
        };
        const preSoldMembershipPayload = {
            intervalCount: values.preSoldMembership.lengthInMonths < 12
                ? values.preSoldMembership.lengthInMonths
                : Math.floor(values.preSoldMembership.lengthInMonths / 12),
            intervalUnit: values.preSoldMembership.lengthInMonths < 12
                ? 'month'
                : 'year',
            amount: values.preSoldMembership.price * 100,
            membershipLevel: values.preSoldMembership.membershipLevel,
        };
        const addressPayload = Object.assign({ membershipSalesNotes: values.address.membershipSalesNotes, cppLength: 0 }, (values.showPreSoldForm
            ? { preSoldMembership: preSoldMembershipPayload }
            : {}));
        const isCppLengthSet = values.address.cppLength && values.address.cppLength > 0;
        const cppExpDate = values.address.customCppExpDate
            ? new Date(addZeroTime(values.address.customCppExpDate))
            : add({
                date: values.project.day1StartDate,
                unit: 'day',
                value: values.address.cppLength || 0,
            });
        const todayEnd = new Date().setHours(23, 59, 59);
        if (values.showCppForm) {
            addressPayload.cppExpDate = format(cppExpDate, ISO_DATE_FORMAT);
            addressPayload.cppLength = isCppLengthSet
                ? values.address.cppLength
                : dateDiffInDays(new Date(values.project.day1StartDate), cppExpDate);
            if (isAfter(cppExpDate, todayEnd)) {
                addressPayload.clientActivatedMembership = values.address
                    .clientActivatedMembership;
            }
        }
        try {
            const [projectResponse, addressResponse = { data: [] }] = await Promise.all([
                apiClient.updateProject(projectId, projectPayload),
                apiClient.updateAddress(addressId, addressPayload),
            ]);
            const [updatedProject] = projectResponse.data;
            const [updatedAddress] = addressResponse.data;
            await apiClient.postToTray({
                OVAID: address.ovaid,
                OVPRJID: projectId,
                day1_date_finalized: true,
                Action: 'submitted_day1',
            }, 'day1DateFinalized');
            close({ updatedProject, updatedAddress });
        }
        catch (error) {
            logError(error);
        }
    }, [apiClient, address, projectId, close]);
    const formik = useFormik({
        initialValues: {
            showCppForm: true,
            showPreSoldForm: false,
            address: {
                membershipSalesNotes: '',
                clientActivatedMembership: '',
                customCppExpDate: format(add({
                    date: estimatedDay1Date,
                    unit: 'day',
                    value: day1PredefinedCppLengths[0].value,
                }), ISO_DATE_FORMAT),
                cppLength: day1PredefinedCppLengths[0].value,
            },
            preSoldMembership: {
                price: 0,
                lengthInMonths: 6,
                membershipLevel: '',
            },
            project: {
                day1StartDate: estimatedDay1Date,
                isDay1DateFinalized: true,
                projectStageId: DzProjectStage.ServiceClient,
            },
        },
        validateOnBlur: true,
        validationSchema: yup.lazy((values) => {
            return yup.object({
                project: yup.object({
                    day1StartDate: yup
                        .string()
                        .required('Field is required.')
                        .test({
                        name: 'inProperFormat',
                        test: (dateString) => {
                            return dateString
                                ? matchFormat(dateString, ISO_DATE_FORMAT)
                                : false;
                        },
                        message: 'Please, use yyyy-mm-dd date format.',
                    }),
                }),
                address: values.showCppForm
                    ? yup.object({
                        cppLength: yup.number().nullable(),
                        customCppExpDate: yup
                            .string()
                            .nullable()
                            .when('cppLength', {
                            is: (cppLength) => cppLength === null,
                            then: yup.string().nullable().required('Required'),
                        })
                            .test({
                            name: 'inProperFormat',
                            test: (dateString) => {
                                return dateString
                                    ? matchFormat(dateString, ISO_DATE_FORMAT)
                                    : false;
                            },
                            message: 'Please, use yyyy-mm-dd date format.',
                        }),
                        clientActivatedMembership: yup.string().required('Required'),
                    })
                    : yup.object().nullable(),
                preSoldMembership: values.showPreSoldForm
                    ? yup.object({
                        price: yup.number().required('Required'),
                        membershipLevel: yup.string().required('Required'),
                    })
                    : yup.object().nullable(),
            });
        }),
        onSubmit: handleSubmit,
    });
    useEffect(() => {
        (async () => {
            if (!addressId) {
                return;
            }
            const response = await apiClient.getAddresses({
                ovaid: addressId.trim(),
            });
            const [addressData] = response.data;
            setAddress(addressData);
            const cppLength = (addressData === null || addressData === void 0 ? void 0 : addressData.primaryProjectId) === projectId
                ? Number(addressData.cppLength)
                : 0;
            const addressUpdates = Object.assign(Object.assign({}, formik.values.address), { membershipSalesNotes: addressData.membershipSalesNotes || '', clientActivatedMembership: '', customCppExpDate: format(add({
                    date: estimatedDay1Date,
                    unit: 'day',
                    value: cppLength || day1PredefinedCppLengths[0].value || 0,
                }), ISO_DATE_FORMAT), cppLength });
            formik.setFieldValue('address', addressUpdates, false);
            formik.setFieldError('address.clientActivatedMembership', undefined);
        })();
    }, [addressId, offeredProducts]);
    useEffect(() => {
        var _a, _b, _c;
        if (loadingPreSoldMembership || loadingOfferedProducts) {
            return;
        }
        if (!preSoldMembership || (preSoldMembership === null || preSoldMembership === void 0 ? void 0 : preSoldMembership.isScheduled)) {
            formik.setValues(Object.assign(Object.assign({}, formik.values), { preSoldMembership: {
                    membershipLevel: offeredProducts.length
                        ? offeredProducts[0].productName
                        : '',
                    price: ((_a = formik.values.preSoldMembership) === null || _a === void 0 ? void 0 : _a.price) || 0,
                    lengthInMonths: ((_b = formik.values.preSoldMembership) === null || _b === void 0 ? void 0 : _b.lengthInMonths) || 0,
                } }));
            return;
        }
        const membershipLevel = ((_c = offeredProducts.find((offeredProd) => offeredProd.productId === preSoldMembership.productNameId)) === null || _c === void 0 ? void 0 : _c.productName) || '';
        const lengthInMonths = preSoldMembership.intervalCount *
            (preSoldMembership.intervalUnit === 'year' ? 12 : 1);
        formik.setValues(Object.assign(Object.assign({}, formik.values), { preSoldMembership: {
                membershipLevel,
                lengthInMonths,
                price: convertCentsToDollars(preSoldMembership.amount),
            }, showPreSoldForm: true }));
    }, [
        loadingPreSoldMembership,
        preSoldMembership,
        loadingOfferedProducts,
        offeredProducts,
    ]);
    const shouldShowPreSolds = partner &&
        !zendesk &&
        partnerConfig.accessToPreSoldMemberships &&
        !(preSoldMembership === null || preSoldMembership === void 0 ? void 0 : preSoldMembership.isScheduled);
    const handleDay1DateChange = useCallback((date, inputValue) => {
        var _a;
        const isPartiallyEntered = inputValue && inputValue.length !== ISO_DATE_FORMAT.length;
        if (isPartiallyEntered || !isValid(date) || !date) {
            formik.setFieldValue('project.day1StartDate', date !== null && date !== void 0 ? date : '');
            return;
        }
        const formattedDate = format(date, ISO_DATE_FORMAT);
        formik.setValues(Object.assign(Object.assign({}, formik.values), { project: Object.assign(Object.assign({}, formik.values.project), { day1StartDate: formattedDate }), address: Object.assign(Object.assign({}, formik.values.address), { customCppExpDate: format(add({
                    date: formattedDate,
                    value: ((_a = formik.values.address) === null || _a === void 0 ? void 0 : _a.cppLength) || 0,
                    unit: 'day',
                }), ISO_DATE_FORMAT) }) }));
    }, [formik]);
    const handleCppChipChange = useCallback((value) => {
        if (value) {
            formik.setFieldValue('address.cppLength', value);
            formik.setFieldTouched('address.customCppExpDate', false);
            formik.setFieldValue('address.customCppExpDate', format(add({
                date: formik.values.project.day1StartDate,
                value: value,
                unit: 'day',
            }), ISO_DATE_FORMAT));
        }
        else {
            formik.setFieldValue('address.cppLength', null);
        }
    }, [formik]);
    const isLoading = loadingPreSoldMembership || loadingOfferedProducts;
    return (_jsxs(Dialog, Object.assign({ open: isOpen, onClose: () => close(), closeAfterTransition: true, transitionDuration: 400, TransitionComponent: Slide, TransitionProps: {
            direction: 'left',
            onExited: clear,
            mountOnEnter: true,
            unmountOnExit: true,
        }, classes: {
            paper: drawerClasses.paper,
            container: drawerClasses.container,
        }, disableRestoreFocus: true }, { children: [_jsxs(DialogTitle, Object.assign({ className: drawerClasses.header }, { children: [_jsx(Typography, { children: "Submit Day1" }), _jsx(IconButton, Object.assign({ onClick: () => close(), color: "inherit" }, { children: _jsx(CloseIcon, { color: "inherit" }) }))] })), _jsx(DialogContent, { children: isLoading ? (_jsx(DzCircularProgress, { className: ownClasses.spinner })) : (_jsxs(_Fragment, { children: [_jsxs("form", Object.assign({ id: "day-one-form", className: formClasses.formContainer, onSubmit: formik.handleSubmit }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: "What day will the client start using their technology?" })), _jsx(DzDateTimePicker, { name: "project.day1StartDate", onBlur: formik.handleBlur, label: "Day1", error: ((_a = formik.touched.project) === null || _a === void 0 ? void 0 : _a.day1StartDate)
                                        ? (_b = formik.errors.project) === null || _b === void 0 ? void 0 : _b.day1StartDate
                                        : '', inputFormat: ISO_DATE_FORMAT, mask: ISO_DATE_MASK, value: (_d = (_c = formik.values.project) === null || _c === void 0 ? void 0 : _c.day1StartDate) !== null && _d !== void 0 ? _d : null, onChange: handleDay1DateChange }), _jsx(DzCppSwitch, { checked: formik.values.showCppForm, onChange: () => {
                                        formik.setFieldValue('showCppForm', !formik.values.showCppForm);
                                    } }), formik.values.showCppForm && (_jsx(DzCppForm, { offeredMemberships: offeredProducts, errors: formik.errors.address, touched: formik.touched.address, values: formik.values.address, handleBlur: formik.handleBlur, handleMembershipChange: (value) => {
                                        formik.setFieldValue('address.clientActivatedMembership', value);
                                    }, handleCustomExpDateChange: (value) => {
                                        formik.setFieldValue('address.customCppExpDate', value);
                                    }, handleChipChange: handleCppChipChange })), shouldShowPreSolds && (_jsx(DzPreSoldSwitch, { checked: formik.values.showPreSoldForm, labelOnly: preSoldMembership && !preSoldMembership.isScheduled, onChange: () => {
                                        formik.setFieldValue('showPreSoldForm', !formik.values.showPreSoldForm);
                                    } })), formik.values.showPreSoldForm && (_jsx(DzPreSoldForm, { offeredMemberships: offeredProducts, errors: formik.errors.preSoldMembership, values: formik.values.preSoldMembership, handleBlur: formik.handleBlur, handlePriceChange: (value) => {
                                        formik.setFieldValue('preSoldMembership.price', value);
                                    }, handleChipChange: (value) => {
                                        formik.setFieldValue('preSoldMembership.lengthInMonths', value);
                                    }, handleMembershipLevelChange: (value) => {
                                        formik.setFieldValue('preSoldMembership.membershipLevel', value);
                                    } }))] })), _jsx(DzMembershipSalesNote, { value: formik.values.address.membershipSalesNotes, handleChange: (value) => {
                                formik.setFieldValue('address.membershipSalesNotes', value);
                            }, address: address, apiClient: apiClient }), _jsx(DzDocumentationAlert, { addressId: address === null || address === void 0 ? void 0 : address.ovaid, actions: actions, dispatch: dispatch, thunks: thunks, zendesk: zendesk })] })) }), _jsx(DialogActions, Object.assign({ className: drawerClasses.actions }, { children: _jsx(Button, Object.assign({ size: "large", variant: "contained", color: "primary", type: "submit", form: "day-one-form", disabled: !formik.isValid || formik.isSubmitting }, { children: formik.isSubmitting ? 'Submitting Day1...' : 'Submit Day1' })) }))] })));
};
export default withModalManager()(DzSubmitDayOneDialog);
