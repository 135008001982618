import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Zoom, } from '@mui/material';
import DeleteForever from '@mui/icons-material/DeleteForever';
import { useCallback } from 'react';
import { withModalManager } from '../../shared/dz-modal-manager';
import { useStyles } from './dz-confirmation-dialog.styles';
const DzConfirmationDialog = ({ isOpen, close, clear }) => {
    const classes = useStyles();
    const cancelHandler = useCallback(() => {
        close(false);
    }, [close]);
    const confirmHandler = useCallback(() => {
        close(true);
    }, [close]);
    return (_jsxs(Dialog, Object.assign({ open: isOpen, onClose: cancelHandler, closeAfterTransition: true, transitionDuration: 400, TransitionComponent: Zoom, TransitionProps: {
            onExited: clear,
            mountOnEnter: true,
            unmountOnExit: true,
        }, classes: {
            paper: classes.paper,
        }, disableRestoreFocus: true }, { children: [_jsx(DialogTitle, { children: _jsxs(Box, Object.assign({ display: "flex", flexDirection: "column", alignItems: "center" }, { children: [_jsx(DeleteForever, { className: classes.icon }), "Confirmation"] })) }), _jsx(DialogContent, { children: _jsxs(DialogContentText, Object.assign({ align: "center" }, { children: ["You are removing the project.", _jsx("br", {}), "This action cannot be undone."] })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ onClick: cancelHandler, color: "primary", autoFocus: true }, { children: "Cancel" })), _jsx(Button, Object.assign({ onClick: confirmHandler, className: classes.button, variant: "contained", color: "primary" }, { children: "Delete" }))] })] })));
};
export default withModalManager()(DzConfirmationDialog);
