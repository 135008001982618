import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const usePhStyles = makeStyles(
  (_theme: ExtTheme) => {
    return {
      placholderRow: {
        display: 'flex',
        justifyContent: 'flex-start',
      },
      withPaddingLeft: {
        paddingLeft: 16,
      },
      arrow: {
        backgroundColor: 'initial',
        transform: 'none',
      },
      container: {
        paddingBottom: 10,
        minHeight: 360,
        overflow: 'hidden',
      },
    };
  },
  { name: 'dz-global-search-dialog-placeholder' },
);
