import React, { useCallback, useState, lazy, Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';
import { logRender } from '@one-vision/utils';
import { Stack, Tab, Tabs } from '@mui/material';

import { useStyles } from './dz-settings-page.styles';
import { DzCompanyInfo } from './dz-company-info';
import { DzUsers } from './dz-users';
import { DzDataImport } from './dz-data-import';
import { DzHolidaysManager } from './dz-holidays-manager';
import { auth } from '@one-vision/login';
import { DzBilling } from './dz-billing';
import {
  usePartnerConfig,
  DzLazyImportFallback,
  useUserConfig,
} from 'shared-ui';
import { URL_PARAM_NAMES } from 'shared/constants';
import { DzIframeHiddenContent } from 'components/shared';
import { DzStripe } from './dz-stripe';

const DzMemberships = lazy(() => import('./dz-memberships'));
const DzOncallManagement = lazy(() => import('./dz-oncall-management'));

enum SettingsTabs {
  CompanyInfo = 'company-info',
  Users = 'users',
  DataImport = 'data-import',
  Holidays = 'holiday-schedule',
  Billing = 'billing',
  Memberships = 'memberships',
  OncallManagement = 'signal',
  Stripe = 'stripe',
}

const getTab = (tab: string) => {
  switch (tab) {
    case SettingsTabs.CompanyInfo:
      return <DzCompanyInfo />;
    case SettingsTabs.Users:
      return <DzUsers />;
    case SettingsTabs.DataImport:
      return <DzDataImport />;
    case SettingsTabs.Holidays:
      return <DzHolidaysManager />;
    case SettingsTabs.Billing:
      return <DzBilling />;
    case SettingsTabs.Memberships:
      return (
        <Suspense fallback={<DzLazyImportFallback />}>
          <DzMemberships />
        </Suspense>
      );
    case SettingsTabs.OncallManagement:
      return (
        <Suspense fallback={<DzLazyImportFallback />}>
          <DzOncallManagement />
        </Suspense>
      );
    case SettingsTabs.Stripe:
      return <DzStripe />;
  }
};

export const DzSettingsPageView: React.FC = () => {
  logRender(DzSettingsPageView);

  const [searchParams, setSearchParams] = useSearchParams();

  const urlView = searchParams.get(URL_PARAM_NAMES.CURRENT_VIEW);

  const classes = useStyles();
  const [tab, setTab] = useState(urlView || SettingsTabs.CompanyInfo);
  const onChange = useCallback(
    (_: React.SyntheticEvent, newValue: string) => {
      searchParams.set(URL_PARAM_NAMES.CURRENT_VIEW, newValue);
      setSearchParams([...searchParams]);
      setTab(newValue as SettingsTabs);
    },
    [searchParams, setSearchParams],
  );
  const partnerConfig = usePartnerConfig();
  const userConfig = useUserConfig();

  // Material-UI doesn't allow Tab components to be wrapped so WithAdminRights HOC can't be used
  const tabsList = auth.getIsAdmin()
    ? [
        <Tab
          key={SettingsTabs.CompanyInfo}
          value={SettingsTabs.CompanyInfo}
          label="Company Info"
        />,
        <Tab
          key={SettingsTabs.DataImport}
          value={SettingsTabs.DataImport}
          label="Data Import"
        />,
        <Tab
          key={SettingsTabs.Holidays}
          value={SettingsTabs.Holidays}
          label="Holiday Schedule"
        />,
        <Tab
          key={SettingsTabs.Memberships}
          value={SettingsTabs.Memberships}
          label="Memberships"
        />,
      ]
    : [
        <Tab
          key={SettingsTabs.CompanyInfo}
          value={SettingsTabs.CompanyInfo}
          label="Company Info"
        />,
        <Tab
          key={SettingsTabs.Memberships}
          value={SettingsTabs.Memberships}
          label="Memberships"
        />,
      ];

  const isInAdminUserGroup =
    !!userConfig.userDetails.relationships?.userGroup?.find(
      (el) => el.id === 'admin',
    )?.id;

  if (auth.getIsAdmin() || isInAdminUserGroup) {
    tabsList.splice(
      1,
      0,
      <Tab
        key={SettingsTabs.Users}
        value={SettingsTabs.Users}
        label="Users"
      />,
    );
  }

  if (partnerConfig.accessToCustomSla) {
    tabsList.push(
      <Tab
        key={SettingsTabs.Billing}
        value={SettingsTabs.Billing}
        label="Billing"
      />,
    );
  }

  if (partnerConfig.accessToOncallManagement) {
    tabsList.push(
      <Tab
        key={SettingsTabs.OncallManagement}
        value={SettingsTabs.OncallManagement}
        label="Signal"
      />,
    );
  }

  const isInBasePlatformGroup =
    !!userConfig.userDetails.relationships?.userGroup?.find(
      (el) => el.id === 'base_platform',
    )?.id;

  const showStripe =
    !isInBasePlatformGroup &&
    (auth.getIsAdmin() ||
      isInAdminUserGroup ||
      !!userConfig.user['access_to_billing']);

  if (showStripe) {
    tabsList.push(
      <Tab
        key={SettingsTabs.Stripe}
        value={SettingsTabs.Stripe}
        label="Stripe"
      />,
    );
  }

  return (
    <div className={classes.root}>
      <Stack className={classes.wrapper}>
        <DzIframeHiddenContent>
          <Tabs
            className={classes.tab}
            textColor="primary"
            indicatorColor="primary"
            value={tab}
            onChange={onChange}
          >
            {tabsList}
          </Tabs>
        </DzIframeHiddenContent>

        {getTab(tab)}
      </Stack>
    </div>
  );
};
