import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo, useReducer } from 'react';
import DzModalContext from './dz-modal-context';
import reducer, { clearProps, setProps } from './reducer';
const initialState = {};
const DzModalProvider = ({ children, }) => {
    const [propsDictionary, dispatch] = useReducer(reducer, initialState);
    const openModal = useCallback(function (modalID, passedProps) {
        return new Promise((resolve) => {
            const modalProps = Object.assign(Object.assign({}, passedProps), { isOpen: true, resolve, close: (value) => {
                    resolve(value);
                    dispatch(setProps(modalID, { isOpen: false }));
                }, clear: () => {
                    dispatch(clearProps(modalID));
                }, kill: (value) => {
                    resolve(value);
                    dispatch(clearProps(modalID));
                } });
            dispatch(setProps(modalID, modalProps));
        });
    }, [dispatch]);
    const closeModal = useCallback(function (modalID) {
        const modalProps = propsDictionary[modalID];
        if (modalProps) {
            const { resolve } = modalProps;
            if (resolve) {
                resolve();
            }
            dispatch(setProps(modalID, Object.assign(Object.assign({}, modalProps), { isOpen: false })));
        }
    }, [propsDictionary, dispatch]);
    const clearModal = useCallback(function (modalID) {
        const modalProps = propsDictionary[modalID];
        if (modalProps) {
            const { resolve } = modalProps;
            if (resolve) {
                resolve();
            }
            dispatch(clearProps(modalID));
        }
    }, [propsDictionary, dispatch]);
    const context = useMemo(() => ({
        propsDictionary,
        openModal,
        closeModal,
        clearModal,
    }), [openModal, closeModal, propsDictionary]);
    return (_jsx(DzModalContext.Provider, Object.assign({ value: context }, { children: children })));
};
export default DzModalProvider;
