import { SvgIcon } from '@mui/material';
import React from 'react';
import { useStyles } from './dz-icons.styles';

const DzAscendingSort: React.FC = () => {
  const classes = useStyles();
  return (
    <SvgIcon
      width="14"
      height="14"
      viewBox="0 0 32 32"
      className={classes.svg}
    >
      <path
        id="asc"
        d="M5.333,16L7.213,17.88L14.666,10.44L14.666,26.667L17.333,26.667L17.333,10.44L24.773,17.893L26.666,16L15.999,5.333L5.332,16L5.333,16Z"
        className={classes.path}
      ></path>
    </SvgIcon>
  );
};

export default DzAscendingSort;
