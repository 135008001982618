import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../core/theme';

export const useStyles = makeStyles((_: ExtTheme) => {
  return {
    dzFallback: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50vh',
    },
    dzFallbackIcon: {
      height: '25vh',
      width: '25vh',
    },
    dzFallbackMessage: {
      fontWeight: 500,
      color: '#828282',
    },
  };
});
