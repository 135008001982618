import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        root: {
            opacity: 0,
            transition: theme.transitions.create('opacity', { duration: 1000 }),
        },
        visible: {
            opacity: 1,
        },
    };
});
