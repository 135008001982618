/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { logRender, csn } from '@one-vision/utils';
import { DzUploadIcon } from 'components/shared/dz-icons';
import { Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useStyles } from './dz-data-import.styles';
import { thunks, useDzSelector } from 'core/redux';
import { DzFlatfileSpace } from './dz-flatfile-space.view';

export const DzDataImportView: React.FC = () => {
  logRender(DzDataImportView);
  const classes = useStyles();

  const brands = useDzSelector((state) => state.brands);
  useFetchBrandsIfEmpty();

  const [showSpace, setShowSpace] = useState(false);

  if (showSpace) {
    return (
      <DzFlatfileSpace
        brands={brands}
        onClose={() => setShowSpace(false)}
      />
    );
  }

  return (
    <div className={csn(classes.root, classes.animatedAppearance)}>
      <div className={classes.box}>
        <DzUploadIcon className={classes.uploadIcon} />

        <div className={classes.message}>
          <Typography className={classes.title} variant="h5">
            Import
          </Typography>
          <Typography variant="body1">
            Import organizations, users, and projects into Provision.
          </Typography>
        </div>

        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => setShowSpace(true)}
          disabled={!brands.length}
        >
          START IMPORT
        </Button>
      </div>
    </div>
  );
};

function useFetchBrandsIfEmpty() {
  const dispatch = useDispatch();
  const brands = useDzSelector((state) => state.brands);

  useEffect(() => {
    if (!brands.length) {
      dispatch(thunks.fetchBrands());
    }
  }, [brands, dispatch]);
}
