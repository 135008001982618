import { useEffect, useState } from 'react';

export function usePasswordValidations(
  password1: string,
  password2: string,
): PasswordsValidation {
  const password1Validation = useTextValidation(password1);
  const password2Validation = useTextValidation(password2);
  const passwordsMatch = password1 === password2;
  const hasError =
    isInvalidTextValidation(password1Validation) ||
    isInvalidTextValidation(password2Validation) ||
    !passwordsMatch;

  return {
    password1: password1Validation,
    password2: password2Validation,
    hasError,
    passwordsMatch,
  };
}

function useTextValidation(password: string) {
  const [textValidation, setTextValidation] = useState<TextValidation>(
    initialTextValidation(),
  );

  useEffect(() => {
    setTextValidation({
      atLeastTenCharacters: /^(?=.{10,})/.test(password),
      atLeastOneNumber: /(?=.*\d)/.test(password),
      atLeastOneLowerCaseLetter: /(?=.*[a-z])/.test(password),
      atLeastOneUpperCaseLetter: /(?=.*[A-Z])/.test(password),
      atLeastOneSpecialCharacter:
        // Checks the presence of the following characters:
        // ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ ` + =
        // eslint-disable-next-line no-useless-escape
        /[\^$*.\[\]{}()?"!@#%&\/\\,><'':;|_~`+=-]/.test(password),
    });
  }, [password]);

  return textValidation;
}

function initialTextValidation(): TextValidation {
  return {
    atLeastTenCharacters: false,
    atLeastOneNumber: false,
    atLeastOneLowerCaseLetter: false,
    atLeastOneUpperCaseLetter: false,
    atLeastOneSpecialCharacter: false,
  };
}

function isInvalidTextValidation(textValidation: TextValidation) {
  return Object.values(textValidation).some((value) => !value);
}

export interface PasswordsValidation {
  password1: TextValidation;
  password2: TextValidation;
  hasError: boolean;
  passwordsMatch: boolean;
}

export interface TextValidation {
  atLeastTenCharacters: boolean;
  atLeastOneNumber: boolean;
  atLeastOneLowerCaseLetter: boolean;
  atLeastOneUpperCaseLetter: boolean;
  atLeastOneSpecialCharacter: boolean;
}
