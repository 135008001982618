import { createAction } from '@reduxjs/toolkit';
import { DzOwner } from 'shared-ui';

interface UpdateProjectPayload {
  id: string;
  ownerId: DzOwner['ownerId'] | null;
  nameOwner: DzOwner['name'] | undefined;
}

export const updateProject =
  createAction<UpdateProjectPayload>('project/update');
