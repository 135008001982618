import {
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { csn, logRender } from '@one-vision/utils';
import React, { useCallback, useRef } from 'react';
import { useStyles } from './dz-drawer.styles';

export const DzDrawerView: React.FC<{
  caption?: string | JSX.Element;
  isOpen: boolean;
  content: JSX.Element;
  contentClass?: string;
  onClose: () => void;
  onOpen: () => void;
  showButton: boolean;
}> = ({
  caption,
  isOpen,
  content,
  contentClass,
  onClose,
  onOpen,
  showButton,
}) => {
  logRender(DzDrawerView);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const classes = useStyles();

  const menuIconHandler = useCallback(() => {
    if (buttonRef.current) {
      buttonRef.current.blur();
    }

    isOpen ? onClose() : onOpen();
  }, [isOpen, onClose, onOpen]);

  return (
    <div
      role="dialog"
      className={csn(classes.root, [classes.showOnOpened, isOpen])}
    >
      <div className={classes.drawerPaper}>
        {showButton && (
          <IconButton
            ref={buttonRef}
            classes={{ root: classes.closeButton }}
            onClick={menuIconHandler}
          >
            <Tooltip title={'Close Side Panel'}>
              <CloseIcon className={csn(classes.closeIcon)} />
            </Tooltip>
          </IconButton>
        )}
        <DialogTitle
          className={classes.header}
          classes={{
            root: classes.header,
          }}
        >
          {caption}
        </DialogTitle>
        <DialogContent className={csn(classes.content, contentClass)}>
          {content}
        </DialogContent>
      </div>
    </div>
  );
};
