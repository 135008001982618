import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  searchValue: string | null;
}

export const arbitrarySettingsSlice = createSlice({
  name: 'arbitrary',
  initialState: {
    searchValue: '',
  } as State,
  reducers: {
    setSearchValue: (
      state: State,
      action: PayloadAction<string>,
    ): State => {
      return {
        ...state,
        searchValue: action.payload,
      };
    },
  },
});
