/*
  Content of this file can be requested via HTTP request
  It can be used to share meta information about the API
*/

export const OvMetaInformation = {
  project: {
    type: 'Project',
    relationships: {
      'address>primaryClient': 'address>primaryClient',
    },
  },
  address: {
    type: 'Address',
  },
  client: {
    type: 'Client',
  },
  projectLostReason: {
    type: 'ProjectLostReason',
  },
};

export enum PARTNER_GROUP_RULE {
  ACCESS_TO_PROVISION_PROJECTS = 'access_to_provision_projects',
  ACCESS_TO_PRE_SOLD_MEMBERSHIPS = 'access_to_pre_sold_memberships',
  // This rule is not used anywhere yet,
  // but it is a prerequisite for separation of SLA and Memberships
  // Once UI and endpoints are implemented
  // This rule will control access to the feature
  ACCESS_TO_CUSTOM_SLA = 'access_to_custom_sla',
  ACCESS_TO_SLA_STRIPE_SYNC = 'access_to_sla_stripe_sync',
  ACCESS_TO_SUBSCRIPTION_MANAGEMENT = 'access_to_subscription_management',
  ACCESS_TO_PROVISION_SIGNAL = 'provision_signal',
  PREVENT_CHARGE_CLIENTS_PRELITS = 'prevent_charge_clients_prelits',
}
