import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      isSidebarOpen: { maxWidth: theme.px2rem(400) },
      tabsRoot: {
        height: theme.px2rem(theme.dz.tabs.top.height + 12),
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
          display: 'none',
        },
        flex: 1,
      },
      tabsFlexContainer: {
        height: '100%',
      },
      tab: {
        flexBasis: theme.px2rem(200),
        minHeight: 'unset',
        opacity: 1,
      },
      tabIndicator: {
        height: theme.px2rem(12),
        width: theme.px2rem(12),
        borderRadius: '50%',
        alignSelf: 'center',
        margin: `${theme.px2rem(1)}px ${theme.px2rem(
          15,
        )}px 0 0 !important`,
        transform: 'scale(0)',
        transition: theme.transitions.create('transform'),
      },
      tabWrapper: {
        flexDirection: 'row',
        textTransform: 'none',
        fontWeight: 600,
        color: theme.palette.custom.OV.sys.onSurface,
        paddingRight: theme.px2rem(20),
      },
      selectedTabWrapper: {
        color: theme.palette.primary.main,
      },
    };
  },
  { name: 'dz-view-tabs', index: 0 },
);
