import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { logRender, csn } from '@one-vision/utils';
import { useStyles } from './dz-search-input.styles';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { TextField, IconButton, Autocomplete, Paper, Typography, } from '@mui/material';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { usePersistent } from '../hooks/custom-hooks';
const CustomPaper = (props) => {
    const classes = useStyles();
    return (_jsxs(Paper, Object.assign({}, props, { children: [_jsx(Typography, Object.assign({ className: classes.listLabel }, { children: "Previous search" })), _jsx("div", { children: props.children })] })));
};
const DzSearchInputView = ({ searchValue = '', onTextChange, autoFocus, zendesk, placeholder = 'Filter results', muiClassesOverrides, selectList, onBlur, muiInputClass, withSearchIcon = true, withClearIcon = true, }) => {
    logRender(DzSearchInputView);
    const classes = useStyles();
    const [searchText, setSearchText] = useState('');
    const inputSubject$ = usePersistent(() => new Subject());
    useEffect(() => {
        if (!searchValue) {
            return;
        }
        setSearchText(searchValue);
        onTextChange(searchValue);
    }, [searchValue, setSearchText, onTextChange]);
    useEffect(() => {
        const inputSub = inputSubject$
            .pipe(debounceTime(250))
            .subscribe((value) => onTextChange(value));
        return () => {
            inputSub.unsubscribe();
        };
    }, [onTextChange, inputSubject$]);
    const handleSearchTextChange = useCallback((_, value) => {
        setSearchText(value || '');
        inputSubject$.next(value || '');
    }, [setSearchText, inputSubject$]);
    const blurHandler = useCallback(() => {
        if (onBlur)
            onBlur();
    }, [onBlur]);
    const clearInput = useCallback(() => {
        if (onBlur)
            onBlur();
        handleSearchTextChange({}, '');
    }, [handleSearchTextChange, blurHandler]);
    const inputClassName = csn(classes.inputInput, muiInputClass || '');
    return (_jsxs("div", Object.assign({ className: classes.root }, { children: [withSearchIcon && (_jsx("div", Object.assign({ className: csn(classes.searchIcon, [
                    classes.searchIconZen,
                    zendesk,
                ]) }, { children: _jsx(SearchIcon, {}) }))), _jsx(Autocomplete, { renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { variant: "standard", color: "secondary", placeholder: placeholder, classes: Object.assign({ root: classes.inputRoot }, muiClassesOverrides), InputProps: Object.assign(Object.assign({}, params.InputProps), { className: inputClassName, classes: {
                            underline: classes.inputUnderline,
                        }, endAdornment: withClearIcon ? (_jsx(IconButton, Object.assign({ onClick: clearInput, className: csn(classes.clearIcon, [
                                classes.clearIconHidden,
                                !searchText,
                            ]) }, { children: _jsx(ClearIcon, {}) }))) : null }), autoFocus: autoFocus, onBlur: blurHandler }))), options: selectList || [], value: searchText, inputValue: searchText, onInputChange: handleSearchTextChange, onChange: handleSearchTextChange, classes: {
                    popper: csn([classes.hideList, !selectList]),
                    listbox: classes.listBox,
                }, PaperComponent: CustomPaper })] })));
};
const memoDzSearchInputView = React.memo(DzSearchInputView);
export { memoDzSearchInputView as DzSearchInputView };
