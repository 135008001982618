import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { useStyles } from './dz-stage.styles';
import { DzProjectStage } from '../../../types';
import { csn } from '@one-vision/utils';
import { DzLeadIcon, DzLostIcon, DzServiceClientIcon, DzActiveProjectIcon, } from '../../icons';
export const DzStage = ({ projectStageId }) => {
    const classes = useStyles();
    const getStage = (projectStageId) => {
        switch (projectStageId) {
            case DzProjectStage.Lead: {
                return {
                    iconStage: _jsx(DzLeadIcon, { fontSize: "small" }),
                    stage: 'Proposal',
                };
            }
            case DzProjectStage.ActiveProject: {
                return {
                    iconStage: _jsx(DzActiveProjectIcon, { fontSize: "small" }),
                    stage: 'Active Project',
                };
            }
            case DzProjectStage.ServiceClient: {
                return {
                    iconStage: _jsx(DzServiceClientIcon, { fontSize: "small" }),
                    stage: 'Service Client',
                };
            }
            case DzProjectStage.LostProposal: {
                return {
                    iconStage: _jsx(DzLostIcon, { fontSize: "small" }),
                    stage: 'Lost Proposal',
                };
            }
            default: {
                return {
                    iconStage: _jsx(DzLostIcon, { fontSize: "small" }),
                    stage: 'No projects',
                };
            }
        }
    };
    const { iconStage, stage } = getStage(projectStageId);
    return (_jsxs(Typography, Object.assign({ className: csn(classes.orgData), component: 'span' }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: "Stage" })), _jsx("div", Object.assign({ className: classes.stage }, { children: iconStage })), _jsx("div", Object.assign({ className: classes.orgDataValue }, { children: stage }))] })));
};
