import { makeStyles } from '@mui/styles';
import { makeStyles as makeStylesV5 } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        projectActions: {
            display: 'flex',
            justifyContent: 'space-between',
            columnGap: theme.spacing(3),
        },
        fullWidthButton: {
            width: '100%',
        },
        organizationRoot: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.px2rem(28),
            paddingBottom: theme.px2rem(35),
            position: 'relative',
            rowGap: theme.px2rem(20),
        },
        organizationRootZen: {
            padding: `${theme.px2rem(28)} ${theme.px2rem(12)}`,
            rowGap: theme.px2rem(10),
        },
        iconContainer: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            width: theme.px2rem(26),
        },
        orgData: {
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: `1fr ${theme.px2rem(26)} 1fr`,
            gridGap: theme.px2rem(20),
        },
        ownerInfo: {
            minHeight: theme.px2rem(28),
        },
        orgDataValue: {
            fontSize: theme.px2rem(12),
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        circularProgress: {
            margin: 'auto',
        },
        emergenceAnimation: {
            opacity: 0,
            animation: '$emergence-anim .5s ease-in-out normal forwards',
        },
        '@keyframes emergence-anim': {
            '100%': {
                opacity: 1,
            },
        },
        ownerItem: {
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: `${theme.px2rem(20)} auto`,
            gridGap: theme.px2rem(10),
            justifyItems: 'center',
        },
        infoIcon: {
            height: theme.px2rem(15),
            width: theme.px2rem(15),
            color: theme.black.black50,
            marginLeft: theme.px2rem(5),
        },
        subscrDetailsTooltip: {
            maxWidth: theme.px2rem(150),
        },
    };
}, { name: 'dz-org-address-content', index: 0 });
export const useMuiStyles = makeStylesV5((theme) => ({
    inputRoot: {
        padding: `${theme.px2rem(8)} ${theme.px2rem(16)}`,
    },
}));
