import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import { useCallTranscripts } from './useCallTranscripts';
import { useAiPrompts } from './useAiPrompts';
import { Autocomplete, Box, Button, CircularProgress, IconButton, TextField, Typography, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReactMarkdown from 'markdown-to-jsx';
import { useAiHelpTrigger } from './useAiHelpTrigger';
import { useStyles } from './dz-ai-assistant.styles';
import { CopyTooltip } from '../../shared';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { format } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
const TRANSCRIPT_DATE_UI_FORMAT = 'PPpp';
export const DzAiAssistantView = (props) => {
    var _a, _b, _c;
    const { addressId, dispatch, actions } = props;
    const classes = useStyles();
    const aiHelpResultContainerRef = useRef(null);
    const [isAdditionalContextOpen, setIsAdditionalContextOpen] = useState(false);
    const formik = useFormik({
        initialValues: {
            additionalContext: '',
            callTranscript: null,
            prompt: null,
        },
        onSubmit: (values) => {
            var _a, _b;
            if (!values.callTranscript || !values.prompt) {
                return;
            }
            generateAiHelpMutation.mutate({
                promptId: (_a = values.prompt) === null || _a === void 0 ? void 0 : _a.id,
                transcriptionId: (_b = values.callTranscript) === null || _b === void 0 ? void 0 : _b.id,
                additionalContext: values.additionalContext,
            });
        },
    });
    const handleSuccessTranscripts = useCallback((list) => {
        if (!formik.values.callTranscript && (list === null || list === void 0 ? void 0 : list.length)) {
            formik.setFieldValue('callTranscript', list[0]);
        }
    }, [formik]);
    const { data: callTranscriptList = [], isLoading: isCallTranscriptsLoading, } = useCallTranscripts({
        addressId,
        onSuccess: handleSuccessTranscripts,
    });
    const handleSuccessPrompts = useCallback((list) => {
        if (!formik.values.prompt && (list === null || list === void 0 ? void 0 : list.length)) {
            formik.setFieldValue('prompt', list[0]);
        }
    }, [formik]);
    const { data: aiPrompts } = useAiPrompts({
        addressId,
        onSuccess: handleSuccessPrompts,
    });
    const handleAiHelpSuccess = useCallback((data) => {
        var _a, _b;
        if (data === null || data === void 0 ? void 0 : data.aiHelp) {
            (_b = (_a = aiHelpResultContainerRef === null || aiHelpResultContainerRef === void 0 ? void 0 : aiHelpResultContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView) === null || _b === void 0 ? void 0 : _b.call(_a, {
                behavior: 'smooth',
            });
        }
    }, [aiHelpResultContainerRef]);
    const handleAiHelpError = useCallback(() => {
        dispatch(actions.updateSnackbar({
            type: 'error',
            text: 'Oops! Something went wrong, please try again.',
        }));
    }, [dispatch, actions, aiHelpResultContainerRef]);
    const generateAiHelpMutation = useAiHelpTrigger({
        onSuccess: handleAiHelpSuccess,
        onError: handleAiHelpError,
    });
    const toggleAdditionalContextHandler = useCallback(() => {
        setIsAdditionalContextOpen((isOpen) => {
            if (isOpen) {
                formik.setFieldValue('additionalContext', '');
            }
            return !isOpen;
        });
    }, [formik]);
    const getTranscriptOptionLabel = (transcript) => {
        var _a;
        if (!((_a = transcript === null || transcript === void 0 ? void 0 : transcript.attributes) === null || _a === void 0 ? void 0 : _a.createdAt)) {
            return 'Unknown date';
        }
        let formattedDate = transcript.attributes.createdAt;
        try {
            formattedDate = format(new Date(transcript.attributes.createdAt), TRANSCRIPT_DATE_UI_FORMAT);
        }
        catch (error) {
            console.error(error);
        }
        return `Created ${formattedDate}`;
    };
    if (isCallTranscriptsLoading) {
        return (_jsx(Box, Object.assign({ padding: "50px", display: "flex", alignItems: "center", justifyContent: "center" }, { children: _jsx(CircularProgress, { size: 30 }) })));
    }
    if (!isCallTranscriptsLoading && !(callTranscriptList === null || callTranscriptList === void 0 ? void 0 : callTranscriptList.length)) {
        return (_jsx(Typography, Object.assign({ className: classes.emptyNoticeText, sx: { padding: '20px 0' } }, { children: "There have not been any transcripts yet." })));
    }
    return (_jsxs(Box, Object.assign({ className: classes.contentRoot }, { children: [_jsx(Box, Object.assign({ mb: "20px", mt: "10px" }, { children: !!(callTranscriptList === null || callTranscriptList === void 0 ? void 0 : callTranscriptList.length) && (_jsx(Autocomplete, { value: formik.values.callTranscript, autoHighlight: true, openOnFocus: true, getOptionLabel: getTranscriptOptionLabel, isOptionEqualToValue: (option, value) => {
                        var _a, _b;
                        return ((_a = option === null || option === void 0 ? void 0 : option.attributes) === null || _a === void 0 ? void 0 : _a.transcriptionId) ===
                            ((_b = value === null || value === void 0 ? void 0 : value.attributes) === null || _b === void 0 ? void 0 : _b.transcriptionId);
                    }, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Call Transcript", margin: "none" }))), onChange: (_, value) => formik.setFieldValue('callTranscript', value !== null && value !== void 0 ? value : null), options: callTranscriptList })) })), _jsx(Box, Object.assign({ mb: "10px" }, { children: !!(aiPrompts === null || aiPrompts === void 0 ? void 0 : aiPrompts.length) && (_jsx(Autocomplete, { value: formik.values.prompt, autoHighlight: true, openOnFocus: true, getOptionLabel: (option) => option.attributes.friendlyName, isOptionEqualToValue: (option, value) => option.id === value.id, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Prompt", margin: "none" }))), options: aiPrompts, onChange: (_, value) => formik.setFieldValue('prompt', value !== null && value !== void 0 ? value : null) })) })), _jsxs(Box, Object.assign({ display: "flex", alignItems: "end", flexDirection: "column" }, { children: [isAdditionalContextOpen ? (_jsxs(Box, Object.assign({ className: classes.additionalContextFieldContainer }, { children: [_jsx(TextField, { className: classes.additionalContextField, label: "Additional context", variant: "outlined", InputLabelProps: { shrink: true }, fullWidth: true, multiline: true, rows: 4, placeholder: `Examples:
  • Previous info from ticket`, name: "additionalContext", onChange: formik.handleChange }), _jsx(IconButton, Object.assign({ className: classes.topIconRoot, onClick: toggleAdditionalContextHandler, color: "inherit" }, { children: _jsx(CloseIcon, { htmlColor: "#6D6F71", fontSize: "small" }) }))] }))) : (_jsx(Button, Object.assign({ variant: "text", className: classes.additionalContextButton, sx: { marginBottom: '10px' }, onClick: toggleAdditionalContextHandler }, { children: "+ Additional context" }))), _jsx(LoadingButton, Object.assign({ size: "large", variant: "contained", color: "secondary", onClick: formik.submitForm, loading: generateAiHelpMutation.isPending, disabled: !formik.values.callTranscript ||
                            !formik.values.prompt ||
                            generateAiHelpMutation.isPending }, { children: "GENERATE" }))] })), !!((_a = generateAiHelpMutation.data) === null || _a === void 0 ? void 0 : _a.aiHelp) && (_jsxs(Box, Object.assign({ className: classes.aiHelpRoot, ref: aiHelpResultContainerRef }, { children: [_jsx(Box, Object.assign({ className: classes.topIconRoot, m: "5px" }, { children: _jsx(CopyTooltip, Object.assign({ textToCopy: (_b = generateAiHelpMutation.data) === null || _b === void 0 ? void 0 : _b.aiHelp }, { children: _jsx(ContentCopyRoundedIcon, { fontSize: "small", htmlColor: "#6D6F71" }) })) })), _jsx(ReactMarkdown, { children: (_c = generateAiHelpMutation.data) === null || _c === void 0 ? void 0 : _c.aiHelp })] })))] })));
};
