import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormControl, InputLabel, MenuItem, Select, Typography, } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { DzDialog, DzNotificationSnackbar } from '../../../shared';
export var PaymentPortalTypes;
(function (PaymentPortalTypes) {
    PaymentPortalTypes["Internal Use Only"] = "Internal Use Only";
    PaymentPortalTypes["Client Facing"] = "Client Facing";
})(PaymentPortalTypes || (PaymentPortalTypes = {}));
const paymentPortalTypes = Object.keys(PaymentPortalTypes);
export const DzAccessPaymentPortalView = ({ isOpen, onClose, address, thunks, dispatch, }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [notificationText, setNotificationText] = useState('');
    const [paymentPortalType, setPaymentPortalType] = useState('');
    const handleSubmit = () => {
        setIsSubmitting(true);
        const payload = {
            subscriptionID: (address === null || address === void 0 ? void 0 : address.chargifySubId) || '',
            internal: false,
        };
        if (paymentPortalType === PaymentPortalTypes['Internal Use Only']) {
            payload.internal = true;
            dispatch(thunks.generateCustomerPortal({
                payload,
            }))
                .unwrap()
                .then((response) => {
                if (response) {
                    window.open(response.url);
                    setPaymentPortalType('');
                    setIsSubmitting(false);
                    onClose();
                }
            });
        }
        else {
            dispatch(thunks.generateCustomerPortal({
                payload,
            }))
                .unwrap()
                .then((response) => {
                if (response) {
                    navigator.clipboard.writeText(response.url);
                    setNotificationText('Client facing link copied to clipboard');
                    setPaymentPortalType('');
                    setIsSubmitting(false);
                    onClose();
                }
            });
        }
    };
    const handleOnChange = useCallback((event) => setPaymentPortalType(event.target.value), [setPaymentPortalType]);
    const handleCloseNotification = useCallback(() => setNotificationText(''), [setNotificationText]);
    const content = useMemo(() => {
        return (_jsxs(_Fragment, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", align: "center", paragraph: true }, { children: "Generate a portal link to update the client\u2019s credit card or download past invoices." })), _jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(InputLabel, Object.assign({ id: "select-payment-portal-type" }, { children: "Payment Portal Type" })), _jsx(Select, Object.assign({ labelId: "select-payment-portal-type", inputProps: {
                                'aria-label': 'paymentPortal',
                            }, label: "Payment Portal Type", value: paymentPortalType, onChange: handleOnChange }, { children: paymentPortalTypes.map((type) => (_jsx(MenuItem, Object.assign({ value: type }, { children: type }), type))) }))] }))] }));
    }, [isSubmitting, paymentPortalType]);
    return (_jsxs(_Fragment, { children: [_jsx(DzDialog, { isOpen: isOpen, caption: "Access Payment Portal", okButtonText: "Generate Link", okButtonDisabled: isSubmitting || !paymentPortalType, isSubmittingLoader: isSubmitting, content: content, onClose: onClose, onOk: handleSubmit }), _jsx(DzNotificationSnackbar, { onClose: handleCloseNotification, text: notificationText })] }));
};
