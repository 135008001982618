const SUBDOMAIN_PART = 'dev-pv-api.';

let api = process.env.REACT_APP_API_URL || '';
if (process.env.REACT_APP_API_URL_SUFFIX && api.includes(SUBDOMAIN_PART)) {
  // we need this part for custom environments (ada|eve|mia|zoe etc)
  api = api
    .split(SUBDOMAIN_PART)
    .join(
      SUBDOMAIN_PART.replace(
        '-',
        `${process.env.REACT_APP_API_URL_SUFFIX}-`,
      ),
    );
}

export const config = {
  stage: process.env.REACT_APP_STAGE,
  api,
  reportingApi: process.env.REACT_APP_ERRORS_API_URL,
  apiKeys: {
    reportingApiKey: '', // will be set after auth
    partnerApiKey: '', // will be set after auth
  },
  cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  cognitoDomain: process.env.REACT_APP_COGNITO_DOMAIN,
  cognitoUserPoolRegion: process.env.REACT_APP_COGNITO_USER_POOL_REGION,
  cognitoUserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  realTimeEngineApi: process.env.REACT_APP_REAL_TIME_ENGINE_API_URL,
  plainAppKey: process.env.REACT_APP_PLAIN_APP_KEY,
  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
};
