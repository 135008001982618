const createBatchActionGuard = (type) => {
    function isBatchAction(action) {
        return action.type === type;
    }
    return isBatchAction;
};
export const batchCreate = (type) => Object.assign((actions) => actions && actions.length === 1 ? actions[0] : { type, actions }, { type });
const INIT = {
    type: '@@OV-FRNT-BATCH::INIT::' + Math.random().toString().slice(2),
};
export const batchingReducer = (type, reducer) => (state, action) => {
    if (!createBatchActionGuard(type)(action)) {
        return reducer(state, action);
    }
    if (action.actions.length > 0) {
        return action.actions.reduce(reducer, state);
    }
    return reducer(state, INIT);
};
