export const mergePayload = (state, action) => !state
    ? Object.assign({}, action.payload) : Object.assign(Object.assign({}, state), action.payload);
const defaultIdSelector = (action) => action.payload.id;
const updateIfNeeded = (state, id, entity) => (state[id] !== entity ? Object.assign(Object.assign({}, state), { [id]: entity }) : state);
const updateById = (state, action, entityReducer) => (id) => id != null
    ? updateIfNeeded(state, id, entityReducer(state[id], action))
    : state;
export const lookupReducer = (entityReducer, idSelector) => (state = {}, action) => {
    const id = idSelector ? idSelector(action) : defaultIdSelector(action);
    return updateById(state, action, entityReducer)(id);
};
