import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: theme.px2rem(250),
      minHeight: theme.px2rem(250),
    },
    buttonsContainer: {
      textAlign: 'center',
    },
    browseFiles: {
      backgroundColor: theme.palette.secondary.dark,
    },
    manualEntry: {
      textDecoration: 'underline',
      color: theme.palette.secondary.dark,
    },
  }),
  { name: 'dz-csv-import-editor' },
);
