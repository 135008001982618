var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { UNASSIGNED_TEXT } from '../../../const';
import { DzOwnerIcon } from '../dz-owner-icon';
import { useFormStyles } from '../styles/form.styles';
const DzOwnerListOption = (_a) => {
    var { name } = _a, props = __rest(_a, ["name"]);
    const formClasses = useFormStyles();
    return (_jsxs("li", Object.assign({}, props, { children: [_jsx(DzOwnerIcon, { name: name, customClasses: {
                    ownerIcon: formClasses.inputAvatar,
                    unassignedAvatarList: formClasses.inputAvatar,
                } }), name || UNASSIGNED_TEXT] })));
};
export default DzOwnerListOption;
