import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { DzAsyncDispatch } from 'shared-ui';
import { thunks } from 'core/redux';
import { NewUser } from 'core/redux/owners.redux';
import { User } from './types';

export function useStartImport({
  addFailedUser,
  addSucceededUser,
  users,
}: UseStartImportInput) {
  const postUser = usePostUser();
  const reloadUsers = useReloadUsers();

  const startImport = useCallback(async () => {
    const processes: Promise<void>[] = [];

    for (const user of users) {
      const process = postUser(user).then((success) => {
        if (success) {
          addSucceededUser(user);
        } else {
          addFailedUser(user);
        }
      });

      processes.push(process);
    }

    await Promise.all(processes);
    reloadUsers();
  }, [addFailedUser, addSucceededUser, postUser, users, reloadUsers]);

  return startImport;
}

function usePostUser() {
  const dispatch = useDispatch<DzAsyncDispatch>();

  return useCallback(
    (user: NewUser) => {
      return dispatch(
        thunks.postOwner({
          owner: user,
          // Users are reloaded after importing is completed
          reload: false,
        }),
      ).unwrap();
    },
    [dispatch],
  );
}

function useReloadUsers() {
  const dispatch = useDispatch<DzAsyncDispatch>();

  return useCallback(() => {
    dispatch(thunks.reloadUsers());
  }, [dispatch]);
}

interface UseStartImportInput {
  users: User[];
  addSucceededUser: (user: User) => void;
  addFailedUser: (user: User) => void;
}
