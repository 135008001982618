import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import { useStyles } from './dz-reports-page.styles';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import React from 'react';
import { useUserConfig } from 'shared-ui';
import { auth } from '@one-vision/login';

interface DzTableFiltersProps {
  statusFilter: string;
  setStatusFilter: React.Dispatch<React.SetStateAction<string>>;
  handleExportClick: () => void;
}

export const stripeStatuses = [
  { value: 'all', name: 'All' },
  { value: 'incomplete', name: 'Incomplete' },
  { value: 'incomplete_expired', name: 'Incomplete Expired' },
  { value: 'active', name: 'Active' },
  { value: 'past_due', name: 'Past Due' },
  { value: 'canceled', name: 'Canceled' },
  { value: 'trialing', name: 'Trialing' },
  { value: 'unpaid', name: 'Unpaid' },
];

export const DzTableFilters: React.FC<DzTableFiltersProps> = ({
  statusFilter,
  setStatusFilter,
  handleExportClick,
}: DzTableFiltersProps) => {
  const classes = useStyles();

  const { user } = useUserConfig();

  const selectChangeHandler = (e: SelectChangeEvent<string>) =>
    setStatusFilter(e.target.value);

  return (
    <Box className={classes.filtersContainer}>
      <FormControl className={classes.filterFormControl}>
        <InputLabel>View</InputLabel>
        <Select
          value={statusFilter}
          label="View"
          size={'small'}
          onChange={selectChangeHandler}
        >
          {stripeStatuses.map((stripeStatus) => (
            <MenuItem key={stripeStatus.value} value={stripeStatus.value}>
              {stripeStatus.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {(auth.getIsAdmin() || user['export_data']) && (
        <Tooltip title="Export results">
          <Button
            className={classes.exportButton}
            variant="text"
            color="inherit"
            onClick={handleExportClick}
          >
            <FileDownloadOutlinedIcon fontSize="medium" />
          </Button>
        </Tooltip>
      )}
    </Box>
  );
};
