import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      viewFilter: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
      },
      filterText: {
        display: 'block',
        width: 165,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      activeStar: {
        color: '#F6A609',
      },
      button: {
        minWidth: theme.px2rem(170),
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        filter:
          'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24))',
      },
      buttonLabel: {
        display: 'grid',
        gap: theme.px2rem(12),
        gridTemplateColumns: `${theme.px2rem(15)} 1fr`,
        justifyItems: 'center',
        justifyContent: 'space-between',
        lineHeight: 2,
        fontWeight: 600,
      },
      smallButton: {
        width: 40, // px, because rem is shrinking at lower sizes
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        flexShrink: '0',
      },
      smallButtonLabel: {
        display: 'flex',
        justifyContent: 'center',
      },
      headerSelect: {
        border: '1px solid #8F9091',
        minWidth: theme.px2rem(180),
      },
      isSidebarOpenHeaderSelect: {
        border: '1px solid #8F9091',
        maxWidth: theme.px2rem(130),
        minWidth: 'unset',

        [theme.breakpoints.down(theme.breakpoints.values.lgBigger)]: {
          maxWidth: theme.px2rem(180),
          minWidth: 'unset',
        },
      },
      menuItem: {
        justifyContent: 'space-between',
      },
      selectRoot: {
        padding: `0 ${theme.px2rem(12)}`,
        textAlign: 'center',
        backgroundColor: 'transparent !important',
      },
      selectIcon: {
        top: 'calc(50% - 10px)',
      },
      svgIconRoot: {
        paddingBottom: 2,
        paddingRight: 2,
      },
      formControl: {
        flexDirection: 'row',
        maxWidth: theme.px2rem(180),
      },
      controlsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: theme.spacing(2),
        '&:not:last-child': {
          paddingRight: theme.px2rem(33),
        },
        [theme.breakpoints.down(theme.breakpoints.values.lgBigger)]: {
          marginLeft: 'auto',
        },
      },
      openSidebarControlsWrapper: {
        display: 'flex',
        flex: `0 0 450px`,
        '&:not:last-child': {
          paddingRight: theme.px2rem(33),
        },
      },
      openSidebarFormControlRoot: {
        flexDirection: 'row',
        maxWidth: theme.px2rem(130),
        [theme.breakpoints.down(theme.breakpoints.values.lgBigger)]: {
          maxWidth: theme.px2rem(180),
          padding: '0 33px',
        },
      },
      openSidebarButtonRoot: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        filter:
          'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24))',
        maxWidth: theme.px2rem(130),
        minWidth: 'unset',
        padding: `6px 6px`,
      },
      openSidebarInputBaseRoot: {
        display: 'flex',
        maxWidth: theme.px2rem(130),
        minWidth: 'unset',
      },
      openSidebarSmallFormControlRoot: {
        maxWidth: theme.px2rem(180),
        padding: 0,
      },
      openSidebarSmallButtonRoot: {
        minWidth: theme.px2rem(170),
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        filter:
          'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24))',
        maxWidth: theme.px2rem(180),
      },
      openSidebarSmallInputRoot: {
        display: 'flex',
        maxWidth: theme.px2rem(180),
      },
      exportButton: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    };
  },
  { name: 'dz-page-header' },
);
