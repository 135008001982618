import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => ({
    drawerContent: {
      '& > *': {
        marginTop: theme.spacing(3),
      },
    },
    headerTitleGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: theme.px2rem(270),
      alignItems: 'center',
    },
    headerIcon: {
      marginRight: theme.px2rem(11),
      height: theme.px2rem(25),
      width: theme.px2rem(25),
      fill: 'hsl(240deg 1% 70%)',
    },
    headerTitle: {
      fontWeight: 500,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: theme.px2rem(250),
    },
    headerActions: {
      position: 'static',
    },
    sidebarDataContainer: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      rowGap: theme.px2rem(20),
    },
    paddingSm: {
      padding: `0 ${theme.spacing(1.5)}`,
    },
    paddingMd: {
      padding: `0 ${theme.spacing(3.5)}`,
    },
    sidebarSubheader: {
      color: theme.black.black65,
    },
    sidebarActions: {
      display: 'flex',
      justifyContent: 'space-between',
      columnGap: `${theme.spacing(3)}`,
      '& button': {
        width: '100%',
      },
    },
    dataRow: {
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: `1fr ${theme.px2rem(26)} 1fr`,
      gridGap: theme.px2rem(20),
    },
    clickableDate: {
      transition: theme.transitions.create('color'),
      '&:hover': {
        color: theme.dz.colors.listHover,
        cursor: 'pointer',
      },
    },
    disabledDate: {
      color: `${theme.black.black35} !important`,
      cursor: 'not-allowed',
    },
    cardHeader: {
      borderBottom: '2px solid #E5E5E5',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.px2rem(20),
      padding: theme.px2rem(24),
      paddingBottom: theme.px2rem(32),
    },
    cardHeaderIcon: {
      fill: 'hsl(240deg 1% 70%)',
      height: theme.px2rem(25),
      width: theme.px2rem(25),
    },
    cardHeaderContent: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    cardHeaderTitle: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontWeight: 500,
    },
    tooltipHeader: {
      fontSize: theme.px2rem(12),
    },
    tooltipSubheader: {
      fontSize: theme.px2rem(10),
    },
    rotateUrlIcon: {
      transform: 'rotate(135deg)',
    },
    ellipses: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: theme.px2rem(268),
    },
    dropdownSpacing: {
      paddingLeft: theme.px2rem(14),
    },
    dropdownSecondaryText: {
      color: theme.black.black50,
    },
    listItemIcon: {
      minWidth: theme.px2rem(32),
    },
    zendeskIcon: {
      width: theme.px2rem(13),
      height: theme.px2rem(13),
      fill: 'black',
    },
  }),
  { index: 0 },
);
