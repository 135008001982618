import { format, coerceToDate } from '@one-vision/date-utils';
import {
  DzColDef,
  GridColumnTypes,
  getDzCheckBoxRawHtml,
  getDzOwnerCellRawHtml,
} from 'components/shared';
import {
  OrganizationsColumns,
  OrganizationsPreparedTableRow,
} from './types';
import { notAvailableText } from 'shared/constants';

interface OrganizationsColumnsProps {
  setOwnerPopupInfo: (
    {
      anchor,
      row,
    }: {
      anchor: Element;
      row: OrganizationsPreparedTableRow;
    },
    data: OrganizationsPreparedTableRow,
  ) => void;
}

export type OrganizationsColumnsType = OrganizationsColumns<
  DzColDef<OrganizationsPreparedTableRow>
>;

export const getOrganizationsColumns: (
  props: OrganizationsColumnsProps,
) => OrganizationsColumnsType = ({ setOwnerPopupInfo }) => ({
  address: {
    headerName: 'Address',
    flex: 1.1,
    cellRenderer: ({ data: { address1, address2, businessName } }) =>
      getAddress({ address1, address2, businessName }),
    valueGetter: ({ data }) => {
      if (data) {
        const { address1, address2, businessName } = data;
        return getAddress({ address1, address2, businessName });
      }
    },
  },
  primaryUser: {
    headerName: 'Primary user',
    flex: 0.7,
    cellRenderer: ({ data }) => `${data.firstName} ${data.lastName}`,
    valueGetter: ({ data }) => `${data?.firstName} ${data?.lastName}`,
  },
  primaryProject: {
    headerName: 'Primary project',
    flex: 0.8,
    cellRenderer: ({ data }) => {
      if (!data.projectName) {
        if (data.projectId) {
          return `Project for ${data.address1}`;
        }

        return 'No primary project';
      }

      return data.projectName;
    },
    valueGetter: ({ data }) => {
      if (!data?.projectName) {
        if (data?.projectId) {
          return `Project for ${data?.address1}`;
        }

        return 'No primary project';
      }

      return data.projectName;
    },
  },
  serviceLevel: {
    headerName: 'Service level',
    flex: 0.7,
    cellRenderer: ({ data }) => data.clientActivatedMembership,
    valueGetter: ({ data }) => data?.clientActivatedMembership,
  },
  desired: {
    headerName: 'Desired',
    flex: 0.5,
    cellRenderer: ({ data }) => data.clientSelectedMembership,
    valueGetter: ({ data }) => data?.clientSelectedMembership,
  },
  primaryDay1: {
    headerName: 'Primary day1',
    flex: 0.5,
    cellRenderer: ({ data }) => {
      return typeof data.projectDay1StartDate === 'string'
        ? format(coerceToDate(data.projectDay1StartDate), 'MMM dd, yyyy')
        : notAvailableText;
    },
    valueGetter: ({ data }) => {
      return typeof data?.projectDay1StartDate === 'string'
        ? data.projectDay1StartDate
        : '';
    },
  },
  cppExpDate: {
    headerName: 'CPP Exp',
    flex: 0.5,
    cellRenderer: ({ data }) => {
      return typeof data.cppExpDate === 'string'
        ? format(coerceToDate(data.cppExpDate), 'MMM dd, yyyy')
        : notAvailableText;
    },
    valueGetter: ({ data }) => {
      return typeof data?.cppExpDate === 'string' ? data.cppExpDate : '';
    },
  },
  tos: {
    headerName: 'TOS',
    toStringRepresentation: ({ data }) => data.tosAccepted,
    flex: 0.1,
    cellRenderer: ({ data }) => {
      return getDzCheckBoxRawHtml(
        data.tosAccepted.toLowerCase() === 'yes',
      );
    },
    type: [GridColumnTypes.CENTERED],
    valueGetter: ({ data }) =>
      data?.tosAccepted?.toLowerCase() === 'yes' ? 1 : 0,
  },
  creditCard: {
    headerName: 'Membership Registered',
    toStringRepresentation: ({ data }) => data.creditCardProvided,
    flex: 0.1,
    cellRenderer: ({ data }) => {
      return getDzCheckBoxRawHtml(
        data.creditCardProvided.toLowerCase() === 'yes',
      );
    },
    type: [GridColumnTypes.CENTERED],
    valueGetter: ({ data }) =>
      data?.creditCardProvided?.toLowerCase() === 'yes' ? 1 : 0,
  },
  accountManager: {
    headerName: 'Account manager',
    toStringRepresentation: ({ data }) => data.ownerName,
    flex: 0.8,
    cellRenderer: ({ data }) => {
      return getDzOwnerCellRawHtml(data.ownerName);
    },
    type: [GridColumnTypes.CENTERED],
    getQuickFilterText: ({ data }) => {
      return data.ownerName;
    },
    valueGetter: ({ data }) => {
      return data?.ownerName;
    },
    onCellClicked: (e) => {
      setOwnerPopupInfo(
        {
          anchor: e.event?.target as Element,
          row: e.data as OrganizationsPreparedTableRow,
        },
        e.data as OrganizationsPreparedTableRow,
      );
    },
  },
  createdAt: {
    flex: 0.5,
    headerName: 'Created at',
    cellRenderer: ({ data }) => {
      return data?.createdAt;
    },
    valueGetter: ({ data }) => {
      return data?.createdAt || '';
    },
  },
  updatedAt: {
    flex: 0.5,
    headerName: 'Updated at',
    cellRenderer: ({ data }) => {
      return data?.updatedAt;
    },
    valueGetter: ({ data }) => {
      return data?.updatedAt || '';
    },
  },
});

const getAddress = ({
  address1,
  address2,
  businessName,
}: {
  address1: string;
  address2: string | null;
  businessName: string | null;
}) => {
  return (
    address1 +
    (address2 ? `, ${address2}` : '') +
    (businessName && businessName.trim() ? ` (${businessName})` : '')
  );
};
