import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, Skeleton, IconButton, } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import { csn } from '@one-vision/utils';
import { CopyTooltip } from '../dz-copy-tooltip';
import { useStyles } from './dz-af-url-copy-modal.styles';
export const DzAfURLCopyModal = ({ apiClient, client, address, zendesk, isOpen, onClose, }) => {
    const classes = useStyles();
    const [url, setUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [hideIcon, setHideIcon] = useState(false);
    const generateUrl = async () => {
        if (client) {
            setIsLoading(true);
            try {
                const { url } = await apiClient.activationFlowURL({
                    addressId: address.ovaid,
                    clientId: client.ovcid,
                });
                setUrl(url);
                navigator.permissions
                    .query({
                    name: 'clipboard-write',
                })
                    .then(async (result) => {
                    if (result.state === 'granted' || result.state === 'prompt') {
                        await navigator.clipboard.writeText(url);
                    }
                })
                    .catch(() => setHideIcon(true));
            }
            catch (e) {
                console.log('error: ', e);
            }
            finally {
                setIsLoading(false);
            }
        }
    };
    useEffect(() => {
        if (client) {
            generateUrl();
        }
    }, [client]);
    const copyHandler = useCallback(async () => {
        if (url) {
            await navigator.clipboard.writeText(url);
        }
    }, [url, navigator]);
    const openNewTabHandler = useCallback(() => {
        var _a;
        (_a = window.open(url, '_blank')) === null || _a === void 0 ? void 0 : _a.focus();
    }, [url]);
    return (_jsx(Modal, Object.assign({ open: isOpen, onClose: onClose }, { children: _jsxs(Box, Object.assign({ className: csn(classes.root, [classes.rootZen, zendesk]) }, { children: [_jsxs(Box, Object.assign({ className: classes.top }, { children: [_jsxs(Box, { children: ["A Client Activation Flow URL has been generated for", ' ', _jsx(Typography, Object.assign({ className: classes.topText }, { children: `${client === null || client === void 0 ? void 0 : client.firstName} ${client === null || client === void 0 ? void 0 : client.lastName} at ${address.city} - ${address.address1}${address.address2 ? ` ${address.address2}` : ''}${address.businessName ? ` (${address.businessName})` : ''}` }))] }), _jsx(IconButton, Object.assign({ className: classes.closeIcon, onClick: onClose }, { children: _jsx(CloseIcon, {}) }))] })), _jsxs(Box, Object.assign({ className: classes.body }, { children: [_jsx(Box, Object.assign({ className: csn(classes.inputWrapper, [
                                classes.loadingInput,
                                isLoading,
                            ]) }, { children: !isLoading ? (_jsxs(_Fragment, { children: [_jsx(Typography, Object.assign({ className: classes.input }, { children: url })), !hideIcon && (_jsx(CopyTooltip, Object.assign({ textToCopy: url }, { children: _jsx(IconButton, Object.assign({ onClick: copyHandler }, { children: _jsx(ContentCopyIcon, {}) })) })))] })) : (_jsxs(_Fragment, { children: [_jsx(Skeleton, { className: csn(classes.input, classes.inputSkeleton) }), _jsx(Skeleton, { className: classes.copyIcon, variant: "circular" })] })) })), !isLoading ? (_jsx(Button, Object.assign({ className: classes.newTabButton, onClick: openNewTabHandler }, { children: "Open in a new tab" }))) : (_jsx(Skeleton, { className: classes.skeletonNewTab }))] })), _jsx(Box, Object.assign({ className: classes.footer }, { children: _jsx(Button, Object.assign({ className: classes.closeButton, disabled: isLoading, onClick: onClose }, { children: "Close" })) }))] })) })));
};
