export var UserCommunicationChannel;
(function (UserCommunicationChannel) {
    UserCommunicationChannel["SMS"] = "sms";
    UserCommunicationChannel["Email"] = "email";
    UserCommunicationChannel["PhoneCall"] = "phone-call";
})(UserCommunicationChannel || (UserCommunicationChannel = {}));
export var UserRelations;
(function (UserRelations) {
    UserRelations["partner"] = "partner";
    UserRelations["userGroup"] = "userGroup";
})(UserRelations || (UserRelations = {}));
export var UserGroupRule;
(function (UserGroupRule) {
    UserGroupRule["provisionSignal"] = "provision_signal";
    UserGroupRule["accessToUserManagement"] = "access_to_user_management";
})(UserGroupRule || (UserGroupRule = {}));
