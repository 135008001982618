import { useCallback } from 'react';
import * as yup from 'yup';

import { useDzSelector } from 'core/redux';

const DOMAIN_REGEX = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const SCHEMA = yup.object({
  domain: yup
    .string()
    .required()
    .matches(DOMAIN_REGEX, { message: 'Invalid domain format' }),
});

export function useFormValidation() {
  const partnerDomains = useDzSelector((state) => state.partnerDomains);

  return useCallback(
    (values: { domain: string }) => {
      try {
        SCHEMA.validateSync(values);
      } catch (error) {
        return { domain: (error as yup.ValidationError).message };
      }

      const itExists = partnerDomains.find(
        (partnerDomain) =>
          partnerDomain.domain === values.domain.trim().toLowerCase(),
      );

      if (itExists) {
        return { domain: 'Domain already added' };
      }

      return {};
    },
    [partnerDomains],
  );
}
