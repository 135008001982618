import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (_: ExtTheme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    fillNone: {
      fill: 'none',
    },
    path: {
      fillRule: 'nonzero',
    },
    svg: {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinejoin: 'round',
      strokeMiterlimit: 2,
      marginTop: 2,
      width: '14px',
      height: '14px',
    },
  }),
  { name: 'dz-icons' },
);
