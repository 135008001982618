var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Divider, Tab, Tabs, Tooltip } from '@mui/material';
import { csn } from '@one-vision/utils';
import { useStyles } from './dz-tabs.styles';
import DzTabsPlaceholder from './dz-tabs-placeholder.view';
import DzTabContentPlaceholder from './dz-tab-content-placeholder.view';
const TabPanel = (props) => {
    const { children, tab, activeTab, classes } = props, other = __rest(props, ["children", "tab", "activeTab", "classes"]);
    return (_jsx("div", Object.assign({ className: classes.emergenceAnimation, role: "tabpanel", hidden: tab !== activeTab, id: `tabpanel-${activeTab}`, "aria-labelledby": `tab-${activeTab}` }, other, { children: tab === activeTab && _jsx(_Fragment, { children: children }) })));
};
export const DzTabsView = ({ tabs, customClasses, onChange, activeTab, isLoading, zendesk, }) => {
    const classes = Object.assign(Object.assign({}, useStyles()), customClasses);
    const handleChangeTab = useCallback((_event, value) => {
        onChange(value);
    }, [onChange]);
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: csn(classes.tabsContainer, classes.customTabsContainer) }, { children: isLoading ? (_jsx(DzTabsPlaceholder, {})) : (_jsx(Tabs, Object.assign({ value: activeTab, onChange: handleChangeTab, indicatorColor: "primary", textColor: "primary" }, { children: tabs.map(({ id, name, icon }) => (_jsx(Tooltip, Object.assign({ title: name.toUpperCase(), classes: {
                            tooltip: classes.tooltip,
                        } }, { children: _jsx(Tab, { className: classes.tab, icon: icon, "aria-controls": `tabpanel-${id}` }) }), id))) }))) })), _jsx(Divider, {}), isLoading ? (_jsx(DzTabContentPlaceholder, { zendesk: zendesk })) : (tabs.map(({ id, content }) => (_jsx(TabPanel, Object.assign({ activeTab: activeTab, tab: id, classes: classes }, { children: content }), id))))] }));
};
