import { API } from 'core/api';
import React from 'react';
import { DzRichTextEditor, S3Client } from 'shared-ui';

const BlueprintPage: React.FC = () => {
  console.log('re-render');
  return (
    <div>
      <DzRichTextEditor s3Client={new S3Client({ apiClient: API })} />
    </div>
  );
};

export default BlueprintPage;
