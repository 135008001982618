import { FlatfileClient } from '@flatfile/api/Client';
import { CellValueUnion, RecordData, Sheet } from '@flatfile/api/api';
import { API } from 'core/api';
import { MapLike } from 'typescript';

interface ApiResponseRow {
  data: MapLike<string>;
  errors: MapLike<string>;
}

export async function uploadData(
  dataInChunks: MapLike<CellValueUnion | null>[][],
  jobId: string,
  api: FlatfileClient,
) {
  let uploadProgress = 0;
  const failedRows: ApiResponseRow[] = [];
  const successfulRows: ApiResponseRow[] = [];

  if (!dataInChunks.length) {
    return { failedRows, successfulRows };
  }

  for (let i = 0; i < dataInChunks.length; i++) {
    const response = await uploadChunk(dataInChunks[i]);

    const apiErrors = response.filter((el: ApiResponseRow) => el.errors);
    const apiSuccess = response.filter((el: ApiResponseRow) => !el.errors);

    if (apiErrors?.length) {
      failedRows.push(...apiErrors);
    }
    if (apiSuccess?.length) {
      successfulRows.push(...apiSuccess);
    }

    uploadProgress += (100 / dataInChunks.length) * (i + 1);

    await api.jobs.ack(jobId, {
      info: 'Getting started.',
      progress: uploadProgress,
    });
  }

  return { failedRows, successfulRows };
}

export async function uploadChunk(data: MapLike<CellValueUnion | null>[]) {
  try {
    const importPartnerUrl = await API.getImportPartnerUrl(data);
    const importResult = await fetch(importPartnerUrl.data.url, {
      method: 'POST',
      headers: importPartnerUrl.data.headers,
      body: JSON.stringify(data),
    }).then((response) => response.json());

    return importResult;
  } catch (error) {
    return errorResponseByUnknownError(data, error as Error);
  }
}

function errorResponseByUnknownError(data: Array<unknown>, error: Error) {
  return data.map((record) => ({
    data: record,
    errors: {
      // As this is an unknown error there is no specific field affected.
      // In order to display it, the required field "address1" is used.
      address1: 'External error: ' + error,
    },
  }));
}

export async function handleFailedRecords(
  workbookSheet: Sheet,
  failedRows: ApiResponseRow[],
  api: FlatfileClient,
) {
  // Get all records for the worksheet
  const { data: records } = await api.records.get(workbookSheet.id);

  // Delete all of its existing records
  await api.records.delete(workbookSheet.id, {
    ids: records.records.map((record) => record.id),
  });

  // Then, create new records with proper validation and add them back to the sheet
  const validatedRecords = createValidatedRecords(failedRows);

  await api.records.insert(workbookSheet.id, validatedRecords);
}

// Converts the output of import-partner endpoint to Flatfile records
export function createValidatedRecords(
  failedRows: ApiResponseRow[],
): RecordData[] {
  return failedRows.map((failedRow) => {
    const recordDataObj: RecordData = {};

    Object.keys(failedRow.data).forEach((failedRowKey: string) => {
      recordDataObj[failedRowKey] = {
        value: failedRow.data[failedRowKey],
        valid: Boolean(failedRow.errors[failedRowKey]),
        messages: failedRow.errors[failedRowKey]
          ? [
              {
                type: 'error',
                source: 'custom-logic',
                message: failedRow.errors[failedRowKey],
              },
            ]
          : [],
      };
    });
    return recordDataObj;
  });
}
