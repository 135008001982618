import { Theme, StyledEngineProvider, createTheme } from '@mui/material';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import * as React from 'react';
import { dzColors, dzSharedStyles } from 'shared-ui';
import { ThemeProvider as PortalTheme } from '@one-vision/design-theme';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { isRenderingInIframe } from 'core/runtime';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    lgBigger: true;
    lgBiggest: true;
    xxl: true;
  }
  interface TypographyVariants {
    small2: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    small2?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    small2: true;
  }
}

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  seed: 'mui-jss',
});

export const themeCustomParams = {
  /*
    @base should be taken from `htmlFontSize` if treated as root rem font-size
  */
  px2rem: (px: number, htmlFontSize = 14) => {
    const rem = (px / htmlFontSize).toFixed(2);
    return `${rem}rem`;
  },
  dz: {
    appBar: {
      width: 68,
    },
    topBar: {
      height: 72,
    },
    subBar: {
      height: 75,
    },
    tabs: {
      top: {
        height: 63,
      },
    },
    drawer: {
      width: 400,
    },
    colors: dzColors,
    styles: { ...dzSharedStyles },
  },
  isIframe: isRenderingInIframe,
  black: {
    black5: '#F4F4F4',
    black10: '#E9E9E9',
    black20: '#D2D3D3',
    black35: '#B1B1B2',
    black50: '#8F9091',
    black65: '#6D6F71',
    black80: '#4C4D50',
    black100: '#1F2124',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      lgBigger: 1430,
      lgBiggest: 1550,
      xl: 1536,
      xxl: 1650,
    },
  },
};

export type ExtTheme = Theme & typeof themeCustomParams;
export type CustomTheme = Theme & {
  breakpoints: {
    values: {
      lgBigger: number;
      xxl: number;
      lgBiggest: number;
      xs: number;
      sm: number;
      md: number;
      lg: number;
      xl: number;
    };
  };
};

const themeOptions = {
  palette: {
    primary: {
      light: '#54d0d3',
      main: '#2AC5C9',
      dark: '#1d898c',
    },
    secondary: {
      light: '#b47aff',
      main: '#A259FF',
      dark: '#713eb2',
    },
    background: {},
    success: {
      light: '#5EFC82',
      main: '#00C853',
      dark: '#009624',
    },
    error: {
      main: '#E53935',
    },
  },
  typography: {
    useNextVariants: true,
    h1: {
      fontSize: '3.71rem',
    },
    h2: {
      fontSize: '3.14rem',
    },
    h3: {
      fontSize: '2.28rem',
    },
    h4: {
      fontSize: '1.85rem',
    },
    h5: {
      fontSize: '1.42rem',
    },
    h6: {
      fontSize: '1.28rem',
    },
    body1: {
      fontSize: '1.14rem',
    },
    body2: {
      fontSize: '1rem',
    },
    subtitle1: {
      fontSize: '1.14rem',
    },
    subtitle2: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    small2: {
      fontSize: '0.86rem',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: dzColors.oneVisionBlack80,
        fontSize: 14,
        padding: `6px 10px`,
      },
    },
    MuiTypography: {
      h1: {
        fontSize: '3.71rem',
      },
      h2: {
        fontSize: '3.14rem',
      },
      h3: {
        fontSize: '2.28rem',
      },
      h4: {
        fontSize: '1.85rem',
      },
      h5: {
        fontSize: '1.42rem',
      },
      h6: {
        fontSize: '1.28rem',
      },
      body1: {
        fontSize: '1.14rem',
      },
      body2: {
        fontSize: '1rem',
      },
      subtitle1: {
        fontSize: '1.14rem',
      },
      subtitle2: {
        fontSize: '1rem',
        fontWeight: 500,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      lgBigger: 1430,
      lgBiggest: 1550,
      xl: 1536,
      xxl: 1650,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: '#fff',
        },
        containedSuccess: {
          color: '#fff',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`&.${outlinedInputClasses.error}`]: {
            color: theme.palette.custom.OV.sys.error,
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`&.${outlinedInputClasses.error}`]: {
            color: theme.palette.custom.OV.sys.error,
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.custom.OV.sys.outline,
          },

          [`&.${outlinedInputClasses.error}`]: {
            '.MuiOutlinedInput-notchedOutline': {
              color: theme.palette.custom.OV.sys.error,
              borderColor: theme.palette.custom.OV.sys.error,
            },
          },
        }),
      },
    },
  },
};

export const themeObject = {
  ...themeOptions,
  ...themeCustomParams,
};
export const theme = createTheme(themeObject) as ExtTheme;

export function applyTheme<T>(
  Component: React.ComponentType<T>,
): (props: T) => JSX.Element {
  const withAppliedTheme = (props: T): JSX.Element => {
    return (
      <StyledEngineProvider injectFirst>
        <StylesProvider generateClassName={generateClassName}>
          <PortalTheme theme={themeObject}>
            <Component {...props} />
          </PortalTheme>
        </StylesProvider>
      </StyledEngineProvider>
    );
  };
  return withAppliedTheme;
}
