import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      list: {
        listStyle: 'none',
        '& li': {
          marginBottom: theme.spacing(1),
        },
        paddingLeft: 0,
      },
      valid: {
        color: '#19BF00',
      },
      invalid: {
        color: '#DF3312',
      },
      icon: {
        verticalAlign: 'middle',
      },
    };
  },
  { name: 'dz-password-criteria' },
);
