import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { logRender } from '@one-vision/utils';
import { DzDialog, useApiClient } from '../../shared';
import { withModalManager } from '../../shared/dz-modal-manager';
import * as yup from 'yup';
import DzPreSoldForm from '../dz-submit-day-one-dialog/dz-pre-sold-form.view';
import { useOfferedProducts } from '../../shared/hooks/useOfferedProducts';
import { useFormik } from 'formik';
import { usePreSoldMembership } from '../../shared/hooks/usePreSoldMembership';
function convertPriceToCents(price) {
    return price * 100;
}
function findProductIdFromMembershipLevel(offeredProducts, membershipLevel) {
    var _a;
    return (((_a = offeredProducts.find((product) => product.productName === membershipLevel)) === null || _a === void 0 ? void 0 : _a.productId) || 0);
}
function getInterval(lengthInMonths) {
    return {
        intervalCount: lengthInMonths < 12
            ? lengthInMonths
            : Math.floor(lengthInMonths / 12),
        intervalUnit: lengthInMonths < 12 ? 'month' : 'year',
    };
}
export const DzPreSoldDialog = ({ isOpen, close, clear, address, zendesk = false, dispatch, actions, scheduleImmediately, }) => {
    logRender(DzPreSoldDialog);
    const api = useApiClient();
    const [isLoading, setIsLoading] = useState(false);
    const { products: offeredProducts, loading: isLoadingOfferedProducts } = useOfferedProducts({
        brandId: (address === null || address === void 0 ? void 0 : address.ovbid) || '',
        addressId: (address === null || address === void 0 ? void 0 : address.ovaid) || '',
    });
    const { preSoldMembership, loading: isLoadingPreSoldMembership } = usePreSoldMembership({ addressId: address.ovaid || '' });
    useEffect(() => {
        if (preSoldMembership === null || preSoldMembership === void 0 ? void 0 : preSoldMembership.isScheduled) {
            close();
        }
    }, [preSoldMembership]);
    const formik = useFormik({
        initialValues: {
            price: 0,
            lengthInMonths: 6,
            membershipLevel: '',
        },
        validateOnBlur: true,
        validationSchema: yup.object({
            price: yup.number().required('Required'),
            membershipLevel: yup.string().required('Required'),
            lengthInMonths: yup.number().required('Required'),
        }),
        onSubmit: async (values) => {
            var _a, _b, _c, _d;
            setIsLoading(true);
            const { intervalUnit, intervalCount } = getInterval(values.lengthInMonths);
            const amount = convertPriceToCents(values.price);
            const props = {
                addressId: address.ovaid,
                partnerId: Number(address.ovpid),
                intervalCount,
                intervalUnit,
                productNameId: findProductIdFromMembershipLevel(offeredProducts, values.membershipLevel),
                amount,
                isScheduled: false,
            };
            try {
                // When scheduleImmediately is true, we need hit address PATCH to trigger subscription
                // scheduler immediately, otherwise, we create the PSM directly from it's POST endpoint
                if (scheduleImmediately) {
                    await api.updateAddress(address.ovaid, {
                        // @ts-ignore - preSoldMembership is not part of address but accepted by the endpoint
                        preSoldMembership: {
                            intervalUnit,
                            intervalCount,
                            amount,
                            membershipLevel: values.membershipLevel,
                        },
                    });
                    const { data } = await api.getPreSoldMembership({
                        filter: { addressId: address.ovaid },
                        include: ['address'],
                    });
                    close((_d = (_c = (_b = (_a = data.data) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.relationships) === null || _c === void 0 ? void 0 : _c.address.data) === null || _d === void 0 ? void 0 : _d[0].id);
                }
                else {
                    const { data } = await api.postPreSoldMembership(props);
                    close(data.data.id);
                }
            }
            catch (e) {
                dispatch(actions.updateSnackbar({
                    type: 'error',
                    text: 'Failed to save pre-sold membership. Please try again.',
                }));
            }
            finally {
                setIsLoading(false);
            }
        },
    });
    const content = useMemo(() => (_jsx(DzPreSoldForm, { leftBorder: false, offeredMemberships: offeredProducts, errors: formik.errors, values: formik.values, handleBlur: formik.handleBlur, handlePriceChange: (value) => {
            formik.setFieldValue('price', value);
        }, handleChipChange: (value) => {
            formik.setFieldValue('lengthInMonths', value);
        }, handleMembershipLevelChange: (value) => {
            formik.setFieldValue('membershipLevel', value);
        } })), [offeredProducts, formik.values, formik.errors, formik.handleBlur]);
    const caption = preSoldMembership
        ? 'Edit Pre-sold Membership'
        : 'Add Pre-sold Membership';
    return (_jsx(DzDialog, { isOpen: isOpen, clear: clear, caption: caption, okButtonText: "Save", okButtonDisabled: isLoading ||
            isLoadingOfferedProducts ||
            isLoadingPreSoldMembership ||
            !formik.isValid, content: content, onClose: close, onOk: formik.handleSubmit, zendesk: zendesk }));
};
export default withModalManager()(DzPreSoldDialog);
