import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        chip: {
            height: theme.px2rem(18),
            color: '#fff',
            marginRight: theme.spacing(0.5),
            marginBottom: theme.px2rem(0.5),
        },
        addChipButton: {
            padding: 0,
            fontSize: theme.px2rem(12),
            color: theme.palette.custom.OV.sys.onSurface,
            textTransform: 'lowercase',
        },
    };
}, { name: 'dz-client-info-content', index: 0 });
