import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useCallback, useEffect } from 'react';
import { Box, Chip, Button, Checkbox, } from '@mui/material';
import { DzAutocompletePopper } from '../dz-autocomplete-popper';
import { useStyles } from './dz-client-labels-list.styles';
const getLabelsChanges = (arr1, arr2) => arr1.filter((x) => !arr2.includes(x));
const getIds = (labels) => labels.map((el) => el.clientLabelId);
const sortLabelsByIds = (labels) => [...labels].sort((first, second) => first.clientLabelId < second.clientLabelId ? -1 : 1);
const sortLabelsAlphabetically = (labels) => [...labels].sort((first, second) => first.name.localeCompare(second.name));
const getSelectedLabels = (allLabels, selectedLabels) => allLabels.filter((el) => selectedLabels
    .map((item) => item.clientLabelId)
    .includes(el.clientLabelId));
const Option = (props, option, { selected }) => (_jsxs("li", Object.assign({}, props, { children: [_jsx(Checkbox, { checked: selected }), option.name] })));
export const DzClientLabelsList = ({ defaultClientLabels = [], clientLabels, apiClient, ovaid, ovcid, thunks, dispatch, }) => {
    const classes = useStyles();
    const [anchorElement, setAnchor] = useState(null);
    const autocompleteRef = useRef(null);
    const [clientLabelsAutocomplete, setClientLabelsAutocomplete] = useState([]);
    const [clientLabelsList, setClientLabelsList] = useState([]);
    useEffect(() => {
        setClientLabelsList(sortLabelsByIds(defaultClientLabels));
        setClientLabelsAutocomplete(getSelectedLabels(clientLabels, defaultClientLabels));
    }, [defaultClientLabels]);
    const openAutocomplete = useCallback((event) => {
        setAnchor(event.currentTarget);
    }, [setAnchor]);
    const closeAutocomplete = async () => {
        if (anchorElement) {
            anchorElement.focus();
        }
        const listToAdd = getLabelsChanges(getIds(clientLabelsAutocomplete), getIds(clientLabelsList));
        const listToDelete = getLabelsChanges(getIds(clientLabelsList), getIds(clientLabelsAutocomplete));
        setClientLabelsList(sortLabelsByIds(clientLabelsAutocomplete));
        if (apiClient && ovaid && ovcid) {
            setAnchor(null);
            if (listToAdd.length) {
                try {
                    await apiClient.addLabelToClient({
                        ovaid,
                        ovcid: ovcid,
                        clientLabelIds: listToAdd,
                    });
                }
                catch (e) {
                    console.log('addLabelToClient: ', e);
                }
            }
            if (listToDelete.length) {
                try {
                    await apiClient.deleteClientLabel({
                        'client-label-ids': JSON.stringify(listToDelete),
                        ovaid,
                        ovcid: ovcid,
                    });
                }
                catch (e) {
                    console.log('deleteClientLabel: ', e);
                }
            }
            if (listToAdd.length || listToDelete.length) {
                dispatch(thunks.fetchClientsForAddress({
                    ovaid,
                }));
            }
        }
    };
    const onAutocompleteChange = useCallback((option) => {
        setClientLabelsAutocomplete(option);
    }, [setClientLabelsAutocomplete]);
    return (_jsxs(Box, { children: [clientLabelsList.map((el, index) => (_jsx(Chip, { sx: { backgroundColor: el.color }, label: el.name, className: classes.chip, onClick: openAutocomplete }, `${ovcid}-${ovaid}-${index}`))), _jsx(Button, Object.assign({ className: classes.addChipButton, onClick: openAutocomplete, ref: autocompleteRef }, { children: "+ add label" })), _jsx(DzAutocompletePopper, { isOpen: Boolean(anchorElement), anchorEl: autocompleteRef, closeAutocomplete: closeAutocomplete, label: "Select Labels", placeholder: "Add labels\u2026", options: sortLabelsAlphabetically(clientLabels), getOptionLabel: (option) => option.name, multiple: true, removeTags: true, value: clientLabelsAutocomplete, renderOption: Option, onChange: onAutocompleteChange })] }));
};
