import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        section: {
            marginBottom: theme.px2rem(15),
        },
        sectionHeader: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: theme.spacing(2),
        },
        sectionIcon: {
            color: '#A259FF',
        },
        sectionLabel: {
            marginLeft: theme.px2rem(5),
            color: '#6D6F71',
            fontSize: theme.px2rem(14),
        },
        primaryUserIcon: {
            cursor: 'pointer',
            marginRight: theme.px2rem(5),
        },
        primaryUserIconInactive: {
            color: '#F6A609',
        },
        primaryUserIconActive: {
            color: '#828282',
        },
        addContactButton: {
            display: 'flex',
            flexDirection: 'row',
            borderRadius: '5px',
            marginTop: theme.px2rem(10),
        },
        addContactIcon: {
            color: '#2AC5C9',
            transform: 'rotate(45deg)',
        },
        addContactText: {
            color: '#2AC5C9',
            textTransform: 'uppercase',
            fontSize: theme.px2rem(14),
            marginLeft: theme.px2rem(10),
        },
    };
}, { name: 'dz-edit-client-dialog' });
