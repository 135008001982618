import { createAction } from '@reduxjs/toolkit';
import {
  DzClient,
  DzClientToAddress,
  DzAddress,
  ClientWithMultiContacts,
} from 'shared-ui';

export interface ClientToAddress {
  ovaid: DzAddress['ovaid'];
  ovcid: DzClient['ovcid'];
  email?: DzClientToAddress['email'];
}

export const clientUpdated = createAction<DzClient & DzClientToAddress>(
  'client/updated',
);
export const clientWithMultiContactsUpdated =
  createAction<ClientWithMultiContacts>('client-with-contacts/updated');
export const clientIsPosting =
  createAction<Partial<DzClient & DzClientToAddress>>('client/posting');
export const clientPosted = createAction<DzClient & DzClientToAddress>(
  'client/posted',
);
export const clientDisassociated = createAction<{
  clientId: DzClientToAddress['ovcid'];
  addressId: DzClientToAddress['ovaid'];
}>('client/disassociated');
export const clientIsDisassociating = createAction<{
  clientId: DzClientToAddress['ovcid'];
  addressId: DzClientToAddress['ovaid'];
}>('client/disassociating');
export const addedClientToAddress = createAction<ClientToAddress>(
  'client/address-added',
);
