import { API } from '../../../core/api';

export function useResendInvitation(showResult: (result: string) => void) {
  return async (input: UseResendInvitationInput) => {
    try {
      await API.resendLoginInvitation(input.userId, input.password);
      showResult('Password reset sent');
    } catch {
      showResult('Failed to send invitation. Please try again.');
    }
  };
}

export interface UseResendInvitationInput {
  userId: string;
  password?: string;
}
