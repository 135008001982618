import { API } from 'core/api';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DzAsyncDispatch } from 'shared-ui';
import { actions } from 'core/redux';

const ERROR_MESSAGE = "Couldn't fetch the account's onboarding status";
export const useStripeAccountOnboardStatus = () => {
  const [onboardCompleted, setOnboardCompleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const dispatch = useDispatch<DzAsyncDispatch>();

  const fetchOnboardStatus = useCallback(async () => {
    try {
      const {
        data: {
          data: { detailsSubmitted },
        },
      } = await API.getStripeAccountOnboardStatus();

      setOnboardCompleted(detailsSubmitted);
      setLoading(false);
    } catch (error) {
      dispatch(
        actions.updateSnackbar({
          type: 'error',
          text: error,
        }),
      );
      setError(ERROR_MESSAGE);
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchOnboardStatus();
  }, [fetchOnboardStatus]);

  return {
    onboardCompleted,
    loading,
    error,
    refreshOnboardStatus: fetchOnboardStatus,
  };
};
