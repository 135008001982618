import React, { useCallback } from 'react';
import { logRender } from '@one-vision/utils';
import { useStyles } from './dz-company-info.styles';
import {
  canadaProvinces,
  usStates,
  DzPhoneInput,
  DzPartnerDetails,
} from 'shared-ui';
import {
  Typography,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  TextField,
  MenuItem,
  Button,
} from '@mui/material';
import { Formik } from 'formik';
import { actions, thunks } from '../../../core/redux';
import { useDispatch } from 'react-redux';
import { withAdminRights } from '../../../shared/HOCs/withFallback';
import { csn } from '@one-vision/utils';
import { auth } from '@one-vision/login';

const WithAdminRights = withAdminRights();

const states = [...usStates, ...canadaProvinces].map((state) => ({
  value: state.value,
  text: `${state.text} (${state.value})`,
}));

interface FormikModel {
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  mainOfficePhone?: string;
  billingEmail?: string;
  billingEmail2?: string;
  websiteUrl?: string;
}

interface Props {
  partnerDetails: DzPartnerDetails;
}

export const DzCompanyFormBox: React.FC<Props> = ({ partnerDetails }) => {
  logRender(DzCompanyFormBox);
  const isAdmin = auth.getIsAdmin();

  const classes = useStyles();
  const dispatch = useDispatch();

  const formValidation = (values: FormikModel) => {
    const fieldIsRequiredMessage = 'Field is required';
    const InvalidBillingEmailAddress = 'Invalid billing email address';
    const errors: FormikModel = {};
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!values.address) {
      errors.address = fieldIsRequiredMessage;
    }

    if (!values.city) {
      errors.city = fieldIsRequiredMessage;
    }

    if (!values.state) {
      errors.state = fieldIsRequiredMessage;
    }

    if (!values.zip) {
      errors.zip = fieldIsRequiredMessage;
    }

    if (!values.mainOfficePhone) {
      errors.mainOfficePhone = fieldIsRequiredMessage;
    }

    if (!values.billingEmail) {
      errors.billingEmail = fieldIsRequiredMessage;
    } else if (!emailRegex.test(values.billingEmail)) {
      errors.billingEmail = InvalidBillingEmailAddress;
    }

    if (!values.websiteUrl) {
      errors.websiteUrl = fieldIsRequiredMessage;
    }
    return errors;
  };

  const formSubmitHandle = useCallback(
    async (
      values: FormikModel,
      {
        setSubmitting,
      }: { setSubmitting: (isSubmitting: boolean) => void },
    ) => {
      setSubmitting(true);
      const updateObj = {
        ovpid: String(partnerDetails.ovpid),
        changes: {
          ...partnerDetails,
          ...values,
        },
      };
      await dispatch(thunks.updatePartnerDetails(updateObj));
      setSubmitting(false);
      dispatch(
        actions.updateSnackbar({
          type: 'success',
          text: 'Company Information Saved',
        }),
      );
    },
    [dispatch, partnerDetails],
  );

  return (
    <div className={csn(classes.formBox, classes.animatedAppearance)}>
      <div className={classes.formBoxHeader}>
        <Typography className={classes.title} variant="h6">
          Company Information
        </Typography>
      </div>
      <div className={classes.formBoxBody}>
        <Formik
          initialValues={{
            address: partnerDetails?.address || '',
            city: partnerDetails?.city || '',
            state: partnerDetails?.state || '',
            zip: partnerDetails?.zip || '',
            mainOfficePhone: partnerDetails?.mainOfficePhone || '',
            billingEmail: partnerDetails?.billingEmail || '',
            billingEmail2: partnerDetails?.billingEmail2 || '',
            websiteUrl: partnerDetails?.websiteUrl || '',
          }}
          validate={formValidation}
          onSubmit={formSubmitHandle}
        >
          {({
            setFieldValue,
            values,
            handleChange,
            handleSubmit,
            touched,
            errors,
            isValid,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                className={classes.firstField}
                name="address"
                label="Office Address"
                error={touched.address && Boolean(errors.address)}
                helperText={touched.address && errors.address}
                disabled={!isAdmin}
                value={values.address || ''}
                onChange={handleChange}
              />
              <TextField
                className={classes.field}
                name="city"
                label="City"
                error={touched.city && Boolean(errors.city)}
                helperText={touched.city && errors.city}
                disabled={!isAdmin}
                value={values.city || ''}
                onChange={handleChange}
              />
              <div className={classes.inputGroup}>
                <FormControl
                  error={touched.state && Boolean(errors.state)}
                  disabled={!isAdmin}
                  className={classes.field}
                >
                  <InputLabel id="label-state-select">
                    State/Prov
                  </InputLabel>
                  <Select
                    labelId="label-state-select"
                    name="state"
                    label={'State/Prov'}
                    value={values.state}
                    onChange={(event) =>
                      setFieldValue(
                        'state',
                        event.target.value as string,
                        true,
                      )
                    }
                  >
                    {states.map((state) => (
                      <MenuItem key={state.value} value={state.value}>
                        {state.text}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.state && Boolean(errors.state) ? (
                    <FormHelperText>
                      {touched.state && errors.state}
                    </FormHelperText>
                  ) : null}
                </FormControl>
                <TextField
                  className={classes.field}
                  name="zip"
                  label="Zip/Postal Code"
                  error={touched.zip && Boolean(errors.zip)}
                  helperText={touched.zip && errors.zip}
                  disabled={!isAdmin}
                  value={values.zip || ''}
                  onChange={handleChange}
                />
              </div>
              <DzPhoneInput
                className={classes.field}
                label="Office Phone"
                value={values.mainOfficePhone || ''}
                showError={
                  touched.mainOfficePhone &&
                  Boolean(errors.mainOfficePhone)
                }
                errorText={
                  touched.mainOfficePhone && errors.mainOfficePhone
                }
                disabled={!isAdmin}
                onChange={(event) =>
                  setFieldValue('mainOfficePhone', event)
                }
              />
              <TextField
                className={classes.field}
                name="billingEmail"
                label="Billing Contact Email 1"
                error={
                  touched.billingEmail && Boolean(errors.billingEmail)
                }
                helperText={touched.billingEmail && errors.billingEmail}
                disabled={!isAdmin}
                value={values.billingEmail || ''}
                onChange={handleChange}
              />
              <TextField
                className={classes.field}
                name="billingEmail2"
                label="Billing Contact Email 2"
                error={
                  touched.billingEmail2 && Boolean(errors.billingEmail2)
                }
                helperText={touched.billingEmail2 && errors.billingEmail2}
                disabled={!isAdmin}
                value={values.billingEmail2 || ''}
                onChange={handleChange}
              />
              <TextField
                className={classes.field}
                name="websiteUrl"
                label="Company Website URL"
                error={touched.websiteUrl && Boolean(errors.websiteUrl)}
                helperText={touched.websiteUrl && errors.websiteUrl}
                disabled={!isAdmin}
                value={values.websiteUrl || ''}
                onChange={handleChange}
              />
              <WithAdminRights>
                <Button
                  className={classes.updateButton}
                  disabled={isSubmitting || !isValid}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Update
                </Button>
              </WithAdminRights>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
