var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
import { useStyles } from './icons.styles';
export const DzLeadIcon = (_a) => {
    var props = __rest(_a, []);
    const classes = useStyles();
    return (_jsxs(SvgIcon, Object.assign({ viewBox: "0 0 12 12", htmlColor: "#FFB402", className: classes.root }, props, { children: [_jsx("path", { d: "M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM1.5 6C1.5 8.48528 3.51472 10.5 6 10.5C8.48528 10.5 10.5 8.48528 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5C3.51472 1.5 1.5 3.51472 1.5 6Z" }), _jsx("path", { d: "M9.45906 8.0087C9.81174 7.40139 9.9983 6.71192 9.99999 6.00964C10.0017 5.30735 9.81844 4.617 9.4687 4.00799C9.11896 3.39898 8.61504 2.89279 8.00762 2.54031C7.40019 2.18782 6.71067 2.00148 6.00838 2.00001L6 6L9.45906 8.0087Z" })] })));
};
