import { useRef, useState, useEffect, useCallback } from 'react';
export const usePersistent = (initial) => {
    const ref = useRef(null);
    if (!ref.current) {
        ref.current =
            typeof initial === 'function' ? initial() : initial;
    }
    return ref.current;
};
export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value]);
    return debouncedValue;
}
export const useTimer = (ms = 1000) => {
    const [isTimerRunning, setTimerState] = useState(false);
    const startTimer = useCallback(() => {
        setTimerState(true);
    }, [setTimerState]);
    useEffect(() => {
        if (!isTimerRunning) {
            return;
        }
        const timer = setTimeout(() => {
            setTimerState(false);
        }, ms);
        return () => clearTimeout(timer);
    }, [isTimerRunning, setTimerState]);
    return [isTimerRunning, startTimer];
};
