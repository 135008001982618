import { DzAddress } from 'shared-ui';

export const getAddressLabel = ({
  address1,
  address2,
  businessName,
}: DzAddress) => {
  return (
    address1 +
    (address2 ? `, ${address2}` : '') +
    (businessName && businessName.trim() ? ` (${businessName})` : '')
  );
};
