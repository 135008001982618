import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        margin: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
      headerName: {
        fontWeight: 700,
        fontSize: theme.px2rem(32),
        color: theme.palette.custom.OV.sys.onSurface,
        marginBottom: 10,
      },
      box: {
        width: '100%',
        height: theme.px2rem(90),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        padding: theme.px2rem(20),
        color: theme.palette.custom.OV.sys.onBackground,
      },
      blueBackground: {
        background: theme.palette.custom.OV.sys.tertiaryContainer,
      },
      greenBackground: {
        background: theme.palette.custom.OV.sys.successContainer,
      },
      boxTextContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '70%',
      },
      boxText: {
        marginLeft: theme.px2rem(20),
      },
      boxMainLine: {
        fontWeight: 'bold',
        width: '100%',
      },
      setupButton: {
        height: theme.px2rem(45),
        backgroundColor: theme.palette.custom.OV.sys.tertiary,
        color: theme.palette.custom.OV.sys.onTertiary,
        minWidth: theme.px2rem(195),
        '&:hover': {
          backgroundColor: theme.palette.custom.OV.sys.onTertiaryContainer,
        },
      },
      setupIcon: {
        color: theme.palette.custom.OV.sys.tertiary,
      },
      loginButton: {
        height: theme.px2rem(45),
        backgroundColor: theme.dz.colors.success,
        color: '#fff',
        minWidth: theme.px2rem(195),
        '&:hover': {
          backgroundColor: theme.dz.colors.successDarker,
        },
      },
      loginIcon: {
        color: theme.palette.custom.OV.sys.success,
      },
      animatedAppearance: {
        opacity: 0,
        animation: '$row-emergence-anim .6s ease-in-out normal forwards',
        transition: theme.transitions.create('background-color'),
      },
      '@keyframes row-emergence-anim': {
        '100%': {
          opacity: 1,
          transform: 'unset',
        },
      },
      spinner: {
        position: 'absolute',
        left: 'calc(50% - 20px)',
        top: 'calc(50% - 60px)',
      },
    };
  },
  { name: 'dz-stripe' },
);
