import { createAsyncThunk } from '@reduxjs/toolkit';

import { API } from '../../api';

export const deletePartnerDomain = createAsyncThunk(
  'partnerDomains/delete',
  async (id: string) => {
    await API.deletePartnerDomain(id);
    return id;
  },
);
export type DeletePartnerDomainOutput = ReturnType<
  typeof deletePartnerDomain.fulfilled
>;
