import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        horizontalRowItem: {
            marginRight: `${theme.spacing(1)}`,
            flex: 1,
            '&:last-child': {
                marginRight: 0,
            },
        },
    };
}, { name: 'dz-update-address-dialog' });
