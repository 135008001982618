import React from 'react';
import { visuallyHidden } from '@mui/utils';
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import {
  ColumnDefinition,
  ColumnDefinitions,
  DzSimpleGridAction,
} from './dz-simple-grid.types';
import { Order } from './dz-simple-grid.helpers';
import { useStyles } from './dz-simple-grid.styles';

interface DzSimpleGridHeadProps<T> {
  rowCount: number;
  columnDefs: ColumnDefinitions<T>;
  onRequestSort?: ({
    order,
    orderBy,
  }: {
    order: Order;
    orderBy: keyof T;
  }) => void;
  order?: Order;
  orderBy?: keyof T;
  actions?: DzSimpleGridAction<T>[];
}

export const DzSimpleGridHead = <T,>({
  onRequestSort,
  order,
  orderBy,
  columnDefs,
  actions,
}: DzSimpleGridHeadProps<T>) => {
  const classes = useStyles();

  const createSortHandler =
    (property: keyof T) => (_event: React.MouseEvent<unknown>) => {
      const isAsc = orderBy === property && order === 'asc';

      onRequestSort?.({
        order: isAsc ? 'desc' : 'asc',
        orderBy: property,
      });
    };

  const isSortable = !!onRequestSort;

  return (
    <TableHead>
      <TableRow className={classes.tableRow}>
        {columnDefs.map((column: ColumnDefinition<T>) => (
          <TableCell
            key={column.field}
            align={column.align ?? 'left'}
            sx={{
              pointerEvents: isSortable ? 'auto' : 'none',
            }}
            sortDirection={orderBy === column.field ? order : false}
          >
            <TableSortLabel
              hideSortIcon={!isSortable}
              active={orderBy === column.field}
              direction={orderBy === column.field ? order : 'asc'}
              onClick={createSortHandler(column.field)}
            >
              {column.headerName}
              {orderBy === column.field ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc'
                    ? 'sorted descending'
                    : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {actions && (
          <TableCell
            className={classes.sticky}
            key={'actions_cell'}
            align={'left'}
            padding={'normal'}
            sortDirection={false}
          >
            {'Actions'}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
