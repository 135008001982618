var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { useStyles } from './dz-creation-option.styles';
const DzCreationOption = (_a) => {
    var { description = '' } = _a, _b = _a.listItemProps, { className } = _b, listItemProps = __rest(_b, ["className"]);
    const classes = useStyles();
    return (_jsx("li", Object.assign({}, listItemProps, { className: `create ${className}` }, { children: _jsxs(Box, Object.assign({ className: classes.createOption }, { children: [description, _jsx("span", Object.assign({ className: classes.link }, { children: "CREATE NEW" }))] })) })));
};
export default DzCreationOption;
