import { Button, Menu, MenuItem } from '@mui/material';
import React, { useRef, useState } from 'react';

import { DzSearchInput } from 'shared-ui';
import { useStyles } from './dz-users-header.styles';

interface Props {
  onNewUserClick: () => void;
  onSearchTextChange: (text: string) => void;
  onBulkImportClick: () => void;
}

export const DzUsersHeader: React.FC<Props> = (props) => {
  const classes = useStyles();
  const triggerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const closeMenu = () => setIsOpen(false);
  const handleNewUserClick = () => {
    props.onNewUserClick();
    closeMenu();
  };
  const handleBullImportClick = () => {
    props.onBulkImportClick();
    closeMenu();
  };

  return (
    <div className={classes.headerRoot}>
      <div className={classes.headerLeftGroup}>
        <DzSearchInput onTextChange={props.onSearchTextChange} />
      </div>
      <Button ref={triggerRef} onClick={() => setIsOpen(!isOpen)}>
        Menu
      </Button>
      <Menu
        anchorEl={triggerRef.current}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <MenuItem onClick={handleNewUserClick}>Create New User</MenuItem>
        <MenuItem onClick={handleBullImportClick}>Bulk Import</MenuItem>
      </Menu>
    </div>
  );
};
