import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        underlinedTextOnHover: {
            '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
            },
        },
        copyTooltipPopper: {
            top: '-10px !important',
        },
        tooltipFirstLine: {
            fontSize: theme.px2rem(12),
        },
        tooltipSecondLine: {
            fontSize: theme.px2rem(10),
        },
    };
}, { name: 'dz-copy-tooltip' });
