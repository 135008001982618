import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        tabWrapper: {
            margin: `0 ${theme.px2rem(28)}`,
            '&:before': {
                backgroundColor: '#FF000000',
            },
        },
        tabWrapperZen: {
            margin: `0 ${theme.px2rem(12)}`,
        },
        tabWithCardWrapper: {
            margin: `0 ${theme.px2rem(13)}`,
        },
        customTabsContainer: {
            marginBottom: `-${theme.px2rem(1)}`,
            minWidth: theme.px2rem(320),
        },
        noAccessSubscription: {
            margin: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            '& > svg': {
                color: theme.black.black50,
                width: theme.px2rem(80),
                height: theme.px2rem(80),
            },
        },
    };
}, { name: 'dz-org-tabs', index: 0 });
