import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => ({
    browseFiles: {
      backgroundColor: theme.palette.secondary.dark,
    },
  }),
  { name: 'dz-csv-file-picker' },
);
