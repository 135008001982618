import { withStyles, WithStyles } from '@mui/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { csn } from '@one-vision/utils';
import * as React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

type DzNumberInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'value' | 'defaultValue'
> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: React.Ref<any> | React.RefObject<any>;
  decimalPartLength?: number;
  value: string;
  defaultValue?: string;
  onValueChange: (values: NumberFormatValues) => void;
};

const styles = {
  noButtons: {
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '-moz-appearance': 'textfield',
  },
};

class DzNumberInputInternal extends React.Component<
  DzNumberInputProps & WithStyles<keyof typeof styles>,
  unknown
> {
  public render() {
    const {
      inputRef,
      decimalPartLength,
      className,
      classes,
      type: _,
      ...other
    } = this.props;

    return (
      <NumberFormat
        {...other}
        type="text"
        className={csn(className, classes.noButtons)}
        getInputRef={inputRef}
        thousandSeparator={true}
        decimalScale={decimalPartLength}
        fixedDecimalScale={true}
      />
    );
  }
}

export const DzNumberInput = withStyles(styles)(DzNumberInputInternal);

export function DzNumberTextField({
  InputProps,
  onValueChange,
  ...props
}: TextFieldProps & {
  onValueChange: DzNumberInputProps['onValueChange'];
}) {
  const newInputProps = {
    ...InputProps,
    inputProps: {
      ...InputProps?.inputProps,
      onValueChange,
    },
  };

  return (
    <TextField
      {...props}
      InputProps={
        {
          ...newInputProps,
          inputComponent: DzNumberInput,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any
      }
    />
  );
}
