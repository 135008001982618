import {
  makeUseFavoriteViews,
  UsersViews,
} from 'shared/hooks/makeUseFavoriteViews';

import { makeUseViews } from 'shared/hooks';

export const views = [
  {
    id: UsersViews.allUsers,
    text: 'All Users',
  },
  {
    id: UsersViews.allPrimary,
    text: `All Primary Users`,
  },
  {
    id: UsersViews.notAssociatedWithOrg,
    text: 'Users not associated with an organization',
  },
];

export const useUsersFavoriteViews = makeUseFavoriteViews('user');

export const useUsersViews = makeUseViews<typeof useUsersFavoriteViews>(
  'user',
  views,
  useUsersFavoriteViews,
);
