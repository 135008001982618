import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      contentWrapper: {
        margin: `0 ${theme.px2rem(13)}`,
      },
      orgCard: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow:
          '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
        borderRadius: '4px',
        marginBottom: theme.px2rem(20),
        border: `1px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
      },
      orgCardHeader: {
        padding: `${theme.px2rem(15)} ${theme.px2rem(5)}`,
        borderBottom: `2px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
      },
      orgData: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr auto 1fr',
        gridGap: theme.px2rem(20),
      },
      NoOrgAssociated: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: theme.px2rem(32),
        padding: `0 ${theme.px2rem(14)}`,
      },
      NoOrgAssociatedText: {
        fontSize: theme.px2rem(14),
        fontWeight: 500,
        color: '#8F9091',
        marginTop: theme.px2rem(12),
      },
      NoOrgAssociatedIcon: {
        width: theme.px2rem(72),
        height: theme.px2rem(72),
      },
      headerActions: {
        display: 'flex',
        marginRight: 0,
      },
    };
  },
  { name: 'dz-users-sidebar-organizations', index: 0 },
);
