import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddOrEditHolidayDialogProps } from './dz-holidays-dialog';
import { isHolidayWholeDay, TO_UTC } from './utils';
import {
  DzSearchInput,
  useModalManager,
  ModalIDs,
  getNumberSuffix,
} from 'shared-ui';
import { DzDeleteHolidayModal } from './dz-delete-holiday-modal';
import { DzPartnerHoliday } from 'types';
import { usePartnerDetails } from 'shared/hooks';
import { API } from 'core/api';
import { useStyles } from './dz-holidays-manager.styles';

const MONTH_DATE_INDEX = 1;
const MAX_SEARCH_DATE = '2099-01-01';

const OPTIONS = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
} as const;

const getFormattedDate = (date: Date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

//return example: 'Saturday, May 6th 2023'
const getHolidayStart = (date: string, timeZone: string) => {
  const toLocaleDate = new Date(date + TO_UTC).toLocaleString('en-US', {
    ...OPTIONS,
    timeZone,
  });
  const split = toLocaleDate.split(', ')[1].split(' ');
  const num = Number(split[1]);
  const newDate = toLocaleDate
    .split(', ')
    .map((el, index) =>
      index === MONTH_DATE_INDEX ? el + getNumberSuffix(num) : el,
    );
  newDate[0] = newDate[0] + ', ';
  return newDate.join(' ');
};

//examples: '8:00 AM - 5:00 PM' or 'Friday, May 12 at 11:00 PM' or
//'through May 31th' depends on how long holiday
const getHolidayEnding = (
  start: string,
  end: string,
  timeZone: string,
) => {
  if (isHolidayWholeDay(start, end)) {
    let date = new Date(end + TO_UTC).toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      timeZone,
    });
    const split = date.split(' ');
    const num = Number(split[1]);

    date = date + getNumberSuffix(num);
    return `through ${date}`;
  }

  if (start.split(' ')[0] === end.split(' ')[0]) {
    const startDate = new Date(start + TO_UTC);
    const endDate = new Date(end + TO_UTC);

    const convertOptions: {
      hour: 'numeric';
      minute: 'numeric';
      timeZone: string;
    } = {
      hour: 'numeric',
      minute: 'numeric',
      timeZone,
    };

    return `${startDate.toLocaleString(
      'en-US',
      convertOptions,
    )} - ${endDate.toLocaleString('en-US', convertOptions)}`;
  }

  const endDate = new Date(end + TO_UTC).toLocaleString('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
  });

  return endDate;
};

export interface Holiday extends DzPartnerHoliday {
  partnerHolidayId: string;
}

export const DzHolidaysManager: React.FC = () => {
  const classes = useStyles();

  const menuRef = useRef<HTMLButtonElement | null>(null);
  const [isMenuOpen, setMenuState] = useState(false);
  const [currentHoliday, setCurrentHoliday] = useState<Holiday | null>(
    null,
  );
  const [deleteId, setDeleteId] = useState('');
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const [renderHolidays, setRenderHolidays] = useState<Holiday[]>([]);
  const [date, setDate] = useState<Date | null>(null);

  const { data: details } = usePartnerDetails();
  const timeZone = details?.timeZone || '';

  const openMenu = useCallback(
    (holiday: Holiday, element: HTMLButtonElement) => {
      setCurrentHoliday(holiday);
      setMenuState(true);
      menuRef.current = element;
    },
    [setMenuState, setCurrentHoliday],
  );
  const closeMenu = useCallback(() => {
    setCurrentHoliday(null);
    setMenuState(false);
    menuRef.current = null;
  }, [setMenuState]);

  useEffect(() => {
    (async () => {
      const { data } = await API.getPartnerHoliday();
      const restructuredList = data.data.map((holiday) => ({
        partnerHolidayId: holiday.id as string,
        ...holiday.attributes,
      }));
      setHolidays(restructuredList);
      setRenderHolidays(restructuredList);
    })();
  }, []);

  const searchHandler = (text: string) => {
    const includes = (field: string) =>
      field.toLowerCase().includes(text.toLowerCase());

    const searchResult = holidays.filter((holiday) =>
      Object.values(holiday).find((value) =>
        typeof value === 'string' ? includes(value) : false,
      ),
    );
    setRenderHolidays(searchResult);
  };

  const onDateClick = useCallback(
    async (event: Date | null) => {
      setDate(event);
      if (event) {
        const date = getFormattedDate(event);

        const { data } = await API.getPartnerHoliday({
          filter: {
            holidayStart: {
              value: `'${date}','${MAX_SEARCH_DATE}'`,
              type: 'inRange',
            },
          },
        });
        const restructuredList = data.data.map((holiday) => ({
          partnerHolidayId: holiday.id as string,
          ...holiday.attributes,
        }));
        setHolidays(restructuredList);
        setRenderHolidays(restructuredList);
      }
    },
    [setDate, setHolidays, setRenderHolidays],
  );

  const { openModal } = useModalManager();

  const handleHolidayDialog = useCallback(async () => {
    const newHoliday = await openModal<
      AddOrEditHolidayDialogProps,
      Holiday
    >(
      ModalIDs.addOrEditHoliday,
      currentHoliday
        ? { holiday: currentHoliday, timeZone }
        : { timeZone },
    );

    if (newHoliday) {
      if (
        holidays.find(
          (el) => newHoliday.partnerHolidayId === el.partnerHolidayId,
        )
      ) {
        setHolidays((prev) =>
          prev.map((el) =>
            newHoliday.partnerHolidayId === el.partnerHolidayId
              ? { ...newHoliday }
              : el,
          ),
        );
        setRenderHolidays((prev) =>
          prev.map((el) =>
            newHoliday.partnerHolidayId === el.partnerHolidayId
              ? { ...newHoliday }
              : el,
          ),
        );
        return;
      }

      setHolidays((prev) => [
        ...prev,
        {
          ...newHoliday,
        },
      ]);
      setRenderHolidays((prev) => [
        ...prev,
        {
          ...newHoliday,
        },
      ]);
    }
  }, [openModal, currentHoliday, holidays, timeZone]);

  const handleDeleteHoliday = useCallback(() => {
    if (currentHoliday) {
      setDeleteId(currentHoliday.partnerHolidayId);
    }
  }, [currentHoliday]);

  const onCloseDeleteModal = () => {
    setDeleteId('');
  };

  const onSubmitDelete = async () => {
    if (deleteId) {
      try {
        await API.deletePartnerHoliday({ partnerHolidayId: deleteId });
        setHolidays((prev) =>
          prev.filter((holiday) => holiday.partnerHolidayId !== deleteId),
        );
        setRenderHolidays((prev) =>
          prev.filter((holiday) => holiday.partnerHolidayId !== deleteId),
        );
        onCloseDeleteModal();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const sortHolidays = (first: Holiday, second: Holiday) => {
    const firstDate = new Date(first.holidayStart).getTime();
    const secondDate = new Date(second.holidayStart).getTime();

    return firstDate - secondDate;
  };

  return (
    <Box className={classes.root}>
      {timeZone && (
        <>
          <Box className={classes.leftSection}>
            <Box className={classes.header}>
              <Typography className={classes.headerName}>
                Holiday Schedule
              </Typography>
              <Typography className={classes.headerTimeZone}>
                {timeZone.replace('_', ' ')}
              </Typography>
            </Box>
            <DzSearchInput onTextChange={searchHandler} />
            <Box className={classes.listWrapper}>
              {renderHolidays.length ? (
                renderHolidays.sort(sortHolidays).map((el, index) => (
                  <Box
                    className={classes.eventBlock}
                    key={`${index}-${el.holidayStart}`}
                  >
                    <Box>
                      <Typography className={classes.eventBlockTitle}>
                        {getHolidayStart(el.holidayStart, timeZone)}
                      </Typography>
                      <Typography
                        className={classes.eventBlockUnderTitle}
                      >{`${el.holidayName} • ${getHolidayEnding(
                        el.holidayStart,
                        el.holidayEnd,
                        timeZone,
                      )}`}</Typography>
                    </Box>
                    <Button
                      ref={menuRef}
                      onClick={(e) =>
                        openMenu(el, e.target as HTMLButtonElement)
                      }
                    >
                      <MoreVertIcon className={classes.threeDots} />
                    </Button>
                  </Box>
                ))
              ) : (
                <Typography className={classes.noHolidays}>
                  No holidays currently scheduled
                </Typography>
              )}
            </Box>
          </Box>
          <Box className={classes.rightSection}>
            <Button
              variant="contained"
              className={classes.addNewButton}
              onClick={handleHolidayDialog}
            >
              + NEW HOLIDAY
            </Button>
            <StaticDateTimePicker
              openTo="day"
              displayStaticWrapperAs="desktop"
              className={classes.calendar}
              value={date}
              views={['year', 'month', 'day']}
              onChange={onDateClick}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          <Menu
            onClose={closeMenu}
            onClick={closeMenu}
            open={isMenuOpen}
            anchorEl={menuRef.current}
          >
            <MenuItem onClick={handleHolidayDialog}>
              <EditIcon fontSize="small" className={classes.editIcon} />
              <Typography>Update</Typography>
            </MenuItem>
            <MenuItem onClick={handleDeleteHoliday}>
              <DeleteIcon
                fontSize="small"
                className={classes.deleteIcon}
              />
              <Typography>Delete</Typography>
            </MenuItem>
          </Menu>
          <DzDeleteHolidayModal
            id={deleteId}
            onClose={onCloseDeleteModal}
            onSubmit={onSubmitDelete}
          />
        </>
      )}
    </Box>
  );
};
