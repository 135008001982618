import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { Box, FormControl, TextField } from '@mui/material';
import { csn, getChanges, logRender } from '@one-vision/utils';
import { DzDialog, DzGooglePlacesAutocomplete, DzOwnerListOption, useGlobalStyles, useApiClient, } from '../../shared';
import { useStyles } from './dz-update-address-dialog.styles';
import { statesList } from '../../..';
import { Autocomplete } from '@mui/material';
import { UNASSIGNED_TEXT } from '../../../const';
import Addresses from '../../../redux/addresses';
const unassigned = {
    name: null,
    ownerId: null,
};
export const DzUpdateAddressDialogView = ({ actions, dispatch, isOpen, originalAddress, thunks, address, owners, brands, zendesk, }) => {
    logRender(DzUpdateAddressDialogView);
    const classes = Object.assign(Object.assign({}, useGlobalStyles()), useStyles());
    const apiClient = useApiClient();
    const ownersLookup = useMemo(() => owners.reduce((acc, owner) => (Object.assign(Object.assign({}, acc), { [owner.ownerId]: owner })), {}), [owners]);
    const options = useMemo(() => [unassigned, ...owners], [owners]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const updateAddress = (changes) => {
        if (!address) {
            return;
        }
        dispatch(actions.updateUpdateAddressDialogState({
            address: Object.assign(Object.assign({}, address), changes),
        }));
    };
    const handleClose = useCallback(() => dispatch(actions.updateUpdateAddressDialogState({
        isOpen: false,
    })), [dispatch]);
    const changes = useMemo(() => getChanges(originalAddress, address), [address, originalAddress]);
    const handleSubmit = useCallback(() => {
        setIsSubmitting(true);
        dispatch(thunks.updateAddress({
            ovaid: originalAddress.ovaid,
            changes,
        }))
            .unwrap()
            .then(async () => {
            const { data: [updatedAddress], } = await apiClient.getAddresses({ ovaid: originalAddress.ovaid });
            if (updatedAddress) {
                dispatch(Addresses.actions.update(address));
                dispatch(actions.addressUpdated(address));
            }
            dispatch(actions.updateSnackbar({
                text: 'Organization was updated',
                type: 'success',
            }));
            handleClose();
        })
            .catch((error) => {
            dispatch(actions.updateSnackbar({
                type: 'error',
                text: error.message,
            }));
        })
            .finally(() => {
            setIsSubmitting(false);
        });
    }, [changes, originalAddress, dispatch, setIsSubmitting]);
    const content = useMemo(() => {
        return (_jsxs(Box, Object.assign({ display: "flex", flexDirection: "column" }, { children: [_jsx(Box, Object.assign({ className: classes.gcFieldMargin }, { children: _jsx(DzGooglePlacesAutocomplete, { id: "address1", label: "Address 1", initialValue: address.address1, onInputValueChanged: (value) => updateAddress({ address1: value }), onOptionSelected: ({ city, state, zip, address1 }) => {
                            console.log({ city, state, zip, address1 });
                            updateAddress({ city, state, zip, address1 });
                        } }) })), _jsx(TextField, { className: classes.gcFieldMargin, label: "Address 2", value: (address === null || address === void 0 ? void 0 : address.address2) || '', onChange: (event) => updateAddress({ address2: event.currentTarget.value }) }), _jsxs(Box, Object.assign({ display: "flex" }, { children: [_jsx(TextField, { className: csn(classes.gcFieldMargin, classes.horizontalRowItem), label: "City", value: (address === null || address === void 0 ? void 0 : address.city) || '', onChange: (event) => updateAddress({ city: event.currentTarget.value }) }), _jsx(FormControl, Object.assign({ className: csn(classes.gcFieldMargin, classes.horizontalRowItem) }, { children: _jsx(Autocomplete, { value: statesList.find((state) => {
                                    var _a, _b;
                                    return (state.value.toLowerCase() ===
                                        ((_a = address === null || address === void 0 ? void 0 : address.state) === null || _a === void 0 ? void 0 : _a.toLowerCase()) ||
                                        state.text.toLowerCase() ===
                                            `${(_b = address === null || address === void 0 ? void 0 : address.state) === null || _b === void 0 ? void 0 : _b.toLowerCase()} (${state.value.toLowerCase()})`);
                                }), renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "State/Province", margin: "none" }))), options: statesList, getOptionLabel: (option) => option.text, isOptionEqualToValue: (option) => option.value === address.state, onChange: (_, newValue) => {
                                    updateAddress({
                                        state: newValue === null || newValue === void 0 ? void 0 : newValue.value,
                                    });
                                } }) })), _jsx(TextField, { className: csn(classes.gcFieldMargin, classes.horizontalRowItem), label: "Zip/Postal Code", value: (address === null || address === void 0 ? void 0 : address.zip) || '', onChange: (event) => updateAddress({ zip: event.currentTarget.value }) })] })), _jsx(TextField, { className: classes.gcFieldMargin, label: "Business Name", value: (address === null || address === void 0 ? void 0 : address.businessName) || '', onChange: (event) => updateAddress({ businessName: event.currentTarget.value }) }), brands.length > 1 ? (_jsx(FormControl, Object.assign({ className: classes.gcFieldMargin }, { children: _jsx(Autocomplete, { value: brands.find((el) => el.ovbid === (address === null || address === void 0 ? void 0 : address.ovbid)), renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Client Segment", margin: "none" }))), options: brands, getOptionLabel: (option) => option.name, isOptionEqualToValue: (option) => option.ovbid === address.ovbid, onChange: (_, newValue) => {
                            updateAddress({
                                ovbid: newValue === null || newValue === void 0 ? void 0 : newValue.ovbid,
                            });
                        } }) }))) : null, _jsx(Autocomplete, { autoHighlight: true, value: (address === null || address === void 0 ? void 0 : address.ownerId)
                        ? ownersLookup[address === null || address === void 0 ? void 0 : address.ownerId]
                        : unassigned, isOptionEqualToValue: (option) => option.ownerId === (address === null || address === void 0 ? void 0 : address.ownerId), renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Account Manager", margin: "none" }))), options: options, renderOption: (props, option) => (_createElement(DzOwnerListOption, Object.assign({}, props, { name: option.name, key: option.ownerId }))), getOptionLabel: (option) => option.name || UNASSIGNED_TEXT, onChange: (_, value) => {
                        if (!value) {
                            updateAddress({
                                ownerId: null,
                            });
                            return;
                        }
                        updateAddress({
                            ownerId: value.ownerId,
                        });
                    } })] })));
    }, [address, brands, owners, isSubmitting]);
    const processedAddress = Object.assign(Object.assign({}, originalAddress), changes);
    const canSubmit = Boolean(Object.keys(changes).length &&
        processedAddress.address1 &&
        processedAddress.ovbid);
    return (_jsx(DzDialog, { disablePortal: true, isOpen: isOpen, caption: "Update Organization", okButtonText: "Save", okButtonDisabled: isSubmitting || !canSubmit, content: content, onClose: handleClose, cancelButtonText: "Cancel", onOk: handleSubmit, isSubmittingLoader: isSubmitting, zendesk: zendesk }));
};
