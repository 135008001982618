import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      resultHeader: {
        lineHeight: theme.px2rem(28),
        color: theme.black.black35,
        fontWeight: 600,
        padding: `0 ${theme.spacing(2.5)}`,
      },
      resultRow: {
        display: 'flex',
        alignItems: 'center',
        height: theme.px2rem(28),
        padding: `0 ${theme.spacing(2.5)}`,
        justifyContent: 'space-between',
      },
      selectedResultRow: {
        backgroundColor: theme.black.black5,
      },
      hoveredResultRow: {
        backgroundColor: theme.black.black10,
      },
      resultIconContainer: {
        display: 'flex',
        alignItems: 'center',
      },
      resultText: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(2.5),
      },
      hotkey: {
        display: 'flex',
      },
      hidden: {
        display: 'none',
      },
      resultDetails: {
        display: 'flex',
        cursor: 'pointer',
      },
      keyboardIconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: theme.px2rem(21),
        height: theme.px2rem(21),
        marginTop: theme.spacing(0.25),
        backgroundColor: theme.black.black50,
        borderRadius: 3,
      },
      icon: {
        color: theme.black.black50,
      },
      iconSelected: {
        color: theme.black.black65,
      },
      iconLight: {
        width: theme.px2rem(16),
        height: theme.px2rem(16),
        color: theme.black.black5,
      },
      hotkeyText: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 5,
        color: theme.black.black50,
        fontWeight: 500,
      },
      showMoreContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        height: theme.px2rem(27),
        padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
      },
      showMoreTextContainer: {
        display: 'flex',
      },
      selectedShowMoreContainer: {
        backgroundColor: theme.black.black5,
        color: theme.black.black65,
      },
      showMore: {
        color: theme.black.black50,
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: theme.px2rem(10),
      },
      horizontalDivider: {
        width: 2,
        height: 10,
        margin: `0 ${theme.spacing(0.625)}`,
        backgroundColor: theme.black.black35,
      },
      selectedText: {
        color: theme.palette.secondary.main,
      },
      text: {
        whiteSpace: 'pre',
      },
    };
  },
  { name: 'dz-global-search-dialog' },
);
