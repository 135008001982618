import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      toolTip: {
        zIndex: theme.zIndex.tooltip,
      },
      listButton: {
        cursor: 'pointer',
        '&:hover': {
          color: theme.dz.colors.listHover,
          '& > *': {
            color: theme.dz.colors.listHover + ' !important',
          },
        },
      },
      supportToolsPopup: {
        width: theme.px2rem(200),
      },
      popoverOffset: {
        marginTop: theme.px2rem(-100),
      },
      listItemIconRoot: {
        minWidth: '40px',
      },
      pageIconButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: `${theme.dz.appBar.width}px`,
        height: `${theme.dz.appBar.width - 10}px`,
        borderRadius: '0px',
        position: 'relative',
        minWidth: 'unset !important',
        transition: 'unset !important',
      },
      icon: {
        color: '#fff',
      },
    };
  },
  { name: 'dz-support-menu', index: 0 },
);

export type DzSupportMenuClasses = ReturnType<typeof useStyles>;
