import { UserCommunicationChannel } from 'shared-ui';
import { SortDirection } from 'types';

export const maxReduxPersistanceSize = 4500000;

export const notAvailableText = 'N/A';

export const defaultSigningDatePlaceholder = 'TBD';

export const DEFAULT_GRID_ROW_HEIGHT = 48;
export const DEFAULT_PER_PAGE = 50;
export const URL_PARAM_NAMES = {
  SELECTED: 'selected',
  CURRENT_VIEW: 'view',
};

//TO-DO, we need to make one enum with all views across the project
export enum PreSearchLists {
  proposalsPage = 'PROPOSALS_PRE_SEARCH_LIST',
  activePrjPage = 'ACTIVE_PRJ_PRE_SEARCH_LIST',
  allServiceClients = 'ALL_SERVICE_CLIENTS_PRE_SEARCH_LIST',
  activeCpp = 'ACTIVE_CPP_PRE_SEARCH_LIST',
  usersAll = 'ALL_USERS_PRE_SEARCH_LIST',
  usersPrimary = 'PRIMARY_USERS_PRE_SEARCH_LIST',
  usersNoAssociationsPrimary = 'NO_ASSOCIATIONS_USERS_PRE_SEARCH_LIST',
  organizationsAll = 'ALL_ORG_PRE_SEARCH_LIST',
  organizationsExpiredCpp = 'EXPIRED_CPP_ORG_PRE_SEARCH_LIST',
  organizationsActiveCpp = 'ACTIVE_CPP_ORG_PRE_SEARCH_LIST',
  organizationsNoPrj = 'NO_PRJ_ORG_PRE_SEARCH_LIST',
  prjProposals = 'PRJ_PROPOSALS_PRE_SEARCH_LIST',
  prjActive = 'PRJ_ACTIVE_PRE_SEARCH_LIST',
  prjCompleted = 'PRJ_COMPLETED_PRE_SEARCH_LIST',
  prjLost = 'PRJ_LOST_PRE_SEARCH_LIST',
  prjPrimary = 'PRJ_PRIMARY_PRE_SEARCH_LIST',
  prjAll = 'PRJ_ALL_PRE_SEARCH_LIST',
}

export const SHOW_MORE = 'showMore';
export const SHOW_LESS = 'showLess';
export const SHOW_MORE_DIVIDER = '-';

export enum HOTKEYS {
  ARROW_UP = 'up',
  ARROW_DOWN = 'down',
  ARROW_LEFT = 'left',
  ARROW_RIGHT = 'right',
  ENTER = 'enter',
  ESC = 'esc',
}

export const DEFAULT_SORTING_ORDER: SortDirection[] = [
  'asc',
  'desc',
  null,
];

export const DUMMY_ID = 'dummy-id';

export const signalNotificationPreferencesList = [
  { value: UserCommunicationChannel.Email, text: 'Email' },
  { value: UserCommunicationChannel.SMS, text: 'SMS' },
  { value: UserCommunicationChannel.PhoneCall, text: 'Phone Call' },
];
