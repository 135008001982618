import React from 'react';
import { CSVLink } from 'react-csv';

import { useStyles } from './dz-csv-link.styles';

export interface LabelKeyObject {
  label: string;
  key: string;
}

export interface Props<T> {
  data: T[] | string;
  headers: LabelKeyObject[];
  filename?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

export const DzCSVLinkView = <T,>({
  children,
  data,
  onClick,
  ...props
}: Props<T>): React.ReactElement => {
  const classes = useStyles();

  return (
    <CSVLink
      data={data as Record<string, unknown>[]}
      className={classes.csvLink}
      onClick={onClick}
      {...props}
    >
      {children}
    </CSVLink>
  );
};
