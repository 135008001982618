import { useState, useCallback } from 'react';

interface Output {
  searchText: string;
  onSearch: (searchText: string) => void;
}

export const useSearchField = (): Output => {
  const [searchText, setSearchText] = useState('');

  const onSearch = useCallback(
    (searchText: string) => {
      setSearchText(searchText);
    },
    [setSearchText],
  );

  return {
    searchText,
    onSearch,
  };
};
