import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        marginTop: {
            marginTop: theme.spacing(3),
        },
        chipContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            '& > *': {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
        },
        cppChip: {
            flex: '1 0 21%',
        },
        preSoldChip: {
            flex: '1 0 25%',
        },
        containerBorder: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: theme.spacing(2),
        },
        containerBorderLeft: {
            paddingLeft: `${theme.spacing(2)}`,
            borderLeft: '1px solid grey',
        },
        spinner: {
            position: 'absolute',
            width: '40px',
            height: '40px',
            left: 'calc(50% - 20px)',
            top: 'calc(50% - 20px)',
            zIndex: 10,
        },
    };
}, { name: 'dz-submit-day-one-dialog', index: 0 });
