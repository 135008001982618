var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Chip } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
export const DzChipView = (_a) => {
    var { label, onClick, checked } = _a, props = __rest(_a, ["label", "onClick", "checked"]);
    const handleClick = useCallback(() => onClick(label), [label, checked, onClick]);
    return (_jsx(Chip, Object.assign({ icon: checked ? _jsx(DoneIcon, { "data-testid": "done-icon" }) : undefined, clickable: true, label: label, onClick: handleClick, color: checked ? 'secondary' : undefined }, props)));
};
