import React, { useMemo } from 'react';
import { ClientWithMultiContacts, DzClientInfoContent } from 'shared-ui';
import { DzUsersSidebarContactsPlaceholder } from './dz-users-sidebar-contacts-placeholder';
import { API } from 'core/api';
import { useStyles } from './dz-users-sidebar-contacts.styles';

interface Props {
  client: ClientWithMultiContacts | null;
  isLoading: boolean;
}

export const DzUsersSidebarContacts: React.FC<Props> = ({
  client,
  isLoading,
}) => {
  const classes = useStyles();

  const labels = useMemo(() => {
    return {
      email:
        client?.emails.map((email) => ({
          id: email.labelId,
          label: email.label,
        })) || [],
      phone:
        client?.phones.map((phone) => ({
          id: phone.labelId,
          label: phone.label,
        })) || [],
    };
  }, [client?.emails, client?.phones]);

  if (isLoading) {
    return <DzUsersSidebarContactsPlaceholder />;
  }

  return (
    <div className={classes.contactsBlock}>
      <DzClientInfoContent
        client={client}
        isOpen={true}
        labels={labels}
        userPanel={true}
        apiClient={API}
      />
    </div>
  );
};
