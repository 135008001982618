import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        popper: {
            minWidth: theme.spacing(37.5),
        },
        inputRoot: {
            padding: `${theme.px2rem(8)} ${theme.px2rem(16)}`,
        },
    };
}, { name: 'dz-autocomplete-popper' });
