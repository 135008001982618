import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        root: {},
        ownerIcon: {
            width: theme.px2rem(18),
            height: theme.px2rem(18),
            borderRadius: '50%',
            fontSize: theme.px2rem(7),
            color: 'white',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        ownerUnassignedCircle: {
            width: '22px',
            height: '22px',
            color: theme.dz.colors.unassignedUser,
        },
    };
}, { name: 'dz-owner-icon' });
