import React, { useState } from 'react';
import {
  Box,
  FilledInput,
  FilledInputProps,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { DzDialog } from 'shared-ui';
import { DzPasswordValidationFeedback } from './dz-password-validation-feedback.view';
import { useStyles } from './dz-temporary-password-dialog.styles';
import { usePasswordValidations } from './usePasswordValidations';

interface Props {
  open: boolean;
  onSend: (temporaryPassword: string) => void;
  onClose: () => void;
}

export const DzTemporaryPasswordDialog: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [fields, setFields] = useState<FieldsState>({
    password: '',
    confirmPassword: '',
  });
  const passwordsValidation = usePasswordValidations(
    fields.password,
    fields.confirmPassword,
  );
  const [isFormDirty, setIsFormDirty] = useState(false);

  const clearInputs = () => {
    setFields({
      password: '',
      confirmPassword: '',
    });
    setIsFormDirty(false);
  };

  const handleSend = () => {
    if (passwordsValidation.hasError) {
      return;
    }

    props.onSend(fields.password);
    clearInputs();
  };

  const handleClose = () => {
    clearInputs();
    props.onClose();
  };

  const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newFields = {
      ...fields,
      [ev.target.name]: ev.target.value,
    };
    setFields(newFields);
    setIsFormDirty(true);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  return (
    <DzDialog
      caption="Set temporary password"
      isOpen={props.open}
      okButtonText="Send"
      onOk={handleSend}
      onClose={handleClose}
      content={
        <Box display="flex" flexDirection="column">
          <Typography variant="h6" className={classes.title}>
            Create a temporary password and send it to the user.
          </Typography>
          <PasswordField
            className={classes.inputMargin}
            name="password"
            label="Password"
            type="text"
            required
            value={fields.password}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
          <PasswordField
            className={classes.inputMargin}
            name="confirmPassword"
            label="Confirm Password"
            type="text"
            required
            value={fields.confirmPassword}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
          <br />
          {isFormDirty && (
            <DzPasswordValidationFeedback
              passwordsValidation={passwordsValidation}
            />
          )}
        </Box>
      }
    />
  );
};

const PasswordField: React.FC<FilledInputProps & { label: string }> = (
  props,
) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormControl>
      <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
      <FilledInput
        {...props}
        id={props.name}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDown}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

interface FieldsState {
  password: string;
  confirmPassword: string;
}
