import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles(() => {
    return {
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        fillNone: {
            fill: 'none',
        },
    };
}, { name: 'dz-icons' });
