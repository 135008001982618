export var DzProjectStage;
(function (DzProjectStage) {
    DzProjectStage[DzProjectStage["Lead"] = 1] = "Lead";
    DzProjectStage[DzProjectStage["ActiveProject"] = 2] = "ActiveProject";
    DzProjectStage[DzProjectStage["ServiceClient"] = 3] = "ServiceClient";
    DzProjectStage[DzProjectStage["LostProposal"] = 4] = "LostProposal";
})(DzProjectStage || (DzProjectStage = {}));
export const DzProjectStageName = {
    1: 'Proposal',
    2: 'Active Project',
    3: 'Service Project',
    4: 'Lost Proposal',
};
