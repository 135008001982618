import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
        borderRadius: '4px',
        marginBottom: theme.px2rem(24),
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: `${theme.px2rem(12)} ${theme.px2rem(15)} ${theme.px2rem(12)} ${theme.px2rem(12)}`,
        borderBottom: `2px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
        columnGap: theme.spacing(1),
    },
    cardHeaderZen: {
        padding: `${theme.px2rem(16)} ${theme.px2rem(12)}`,
    },
    cardContent: {
        display: 'flex',
        padding: theme.px2rem(15),
        flexDirection: 'column',
        rowGap: theme.spacing(1.5),
    },
    cardContentZen: {
        padding: `${theme.px2rem(18)} ${theme.px2rem(15)}`,
    },
    cardContentRow: {
        display: 'flex',
        columnGap: theme.spacing(1),
        alignItems: 'center',
    },
}), { name: 'dz-user-card-skeleton', index: 0 });
