import { useState, useCallback, useMemo } from 'react';
import { DzOwner } from 'shared-ui';
import { actions } from 'core/redux';

interface Input {
  updateOwner: ({
    id,
    ownerId,
    nameOwner,
  }: {
    id: string;
    ownerId: DzOwner['ownerId'] | null;
    nameOwner: DzOwner['name'] | undefined;
  }) => ReturnType<typeof actions.updateActiveProjectRecord>;
}

interface Output<RowType> {
  ownerPopupInfo: {
    anchor: null | Element;
    row: RowType | null;
  };
  setOwnerPopupInfo: React.Dispatch<
    React.SetStateAction<{ anchor: null | Element; row: null | RowType }>
  >;
  handleCloseOwnerSelection: () => void;
  handleUpdateOwner: (
    id: string,
    ownerId: DzOwner['ownerId'] | null,
    name: DzOwner['name'] | undefined,
  ) => ReturnType<Input['updateOwner']>;
}

export function useOwnerPopup<RowType>({
  updateOwner,
}: Input): Output<RowType> {
  const [ownerPopupInfo, setOwnerPopupInfo] = useState<{
    anchor: null | Element;
    row: null | RowType;
  }>({
    anchor: null,
    row: null,
  });

  const handleCloseOwnerSelection = useCallback(
    () =>
      setOwnerPopupInfo({
        ...ownerPopupInfo,
        anchor: null,
      }),
    [setOwnerPopupInfo, ownerPopupInfo],
  );

  const handleUpdateOwner = useCallback(
    (
      id: string,
      ownerId: DzOwner['ownerId'] | null,
      name: DzOwner['name'] | undefined,
    ) => {
      return updateOwner({
        id,
        ownerId,
        nameOwner: name,
      });
    },
    [updateOwner],
  );

  return useMemo(
    () => ({
      ownerPopupInfo,
      setOwnerPopupInfo,
      handleCloseOwnerSelection,
      handleUpdateOwner,
    }),
    [ownerPopupInfo, handleCloseOwnerSelection, handleUpdateOwner],
  );
}
