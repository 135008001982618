import React from 'react';
import { logRender } from '@one-vision/utils';

import { useStyles } from './dz-company-info.styles';
import { usePartnerDetails } from '../../../shared/hooks';
import { DzCompanyInfoBox } from './dz-company-infobox.view';
import { DzCompanyFormBox } from './dz-company-formbox.view';

export const DzCompanyInfoView: React.FC = () => {
  logRender(DzCompanyInfoView);

  const classes = useStyles();
  const { data: partnerDetails, partnerConfig } = usePartnerDetails();

  return (
    <div className={classes.root}>
      {partnerDetails?.ovpid && (
        <>
          <DzCompanyInfoBox
            partnerConfig={partnerConfig}
            partnerDetails={partnerDetails}
          />
          <DzCompanyFormBox partnerDetails={partnerDetails} />
        </>
      )}
    </div>
  );
};
