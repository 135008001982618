var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
import { csn } from '@one-vision/utils';
import { useStyles } from './icons.styles';
export const DzZendeskIcon = (_a) => {
    var props = __rest(_a, []);
    const classes = useStyles();
    return (_jsxs(SvgIcon, Object.assign({ className: csn(classes.root) }, props, { children: [_jsx("title", {}), _jsx("path", { d: "M11.085 21.095H0L11.085 7.712v13.383zm12.915 0H12.915c0-3.063 2.479-5.543 5.543-5.543 3.063 0 5.542 2.482 5.542 5.543zm-11.085-4.804V2.905H24L12.915 16.291zm-1.83-13.386c0 3.061-2.481 5.544-5.543 5.544C2.482 8.449 0 5.968 0 2.907h11.085v-.002z" })] })));
};
