import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState, useEffect } from 'react';
import { csn } from '@one-vision/utils';
import { Box, IconButton, Button } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DzClientInfoContent } from '../dz-client-info-content';
import { useStyles } from './dz-user-card.styles';
import { CLIENT_NOTE_HEIGHT } from '../../../const';
import { DzCardHeaderName } from '../dz-card-header-name';
export const DzUserCardView = ({ client, ovaid, labels, clientLabels, primaryClientId = null, onPrimaryUserChange, onMenuOpen, zendesk, apiClient, onCardNameClick, thunks, dispatch, }) => {
    const classes = useStyles();
    const [cardIsOpen, setCardIsOpen] = useState(false);
    const [rendered, setRendered] = useState(false);
    const [noteHeight, setNoteHeight] = useState(0);
    useEffect(() => {
        setRendered(true);
    }, []);
    const primaryUserHandler = useCallback(() => {
        if (!onPrimaryUserChange) {
            return;
        }
        onPrimaryUserChange(client);
    }, [client, primaryClientId]);
    const menuHandler = useCallback((event) => {
        if (!onMenuOpen) {
            return;
        }
        onMenuOpen(event, client);
    }, [client]);
    const clientName = useMemo(() => `${client.firstName} ${client.lastName}`, [client]);
    const cardOpenHandler = useCallback(() => setCardIsOpen((prev) => !prev), [setCardIsOpen]);
    const getPrimaryIcon = ({ primaryClientId, primaryUserHandler, classes, }) => client.ovcid === primaryClientId ? (_jsx(StarIcon, { className: csn(classes.primaryUserIcon, classes.primaryUserIconInactive) })) : (_jsx(StarBorderIcon, { className: csn(classes.primaryUserIcon, classes.primaryUserIconActive), onClick: primaryUserHandler }));
    const expandButtonHandler = (open) => rendered &&
        open &&
        (client.emails.length > 1 || client.phones.length > 1);
    const showButton = client.emails.length <= 1 &&
        client.phones.length <= 1 &&
        (!client.notes || noteHeight <= CLIENT_NOTE_HEIGHT);
    const onCardNameClickHandler = useCallback(() => onCardNameClick && onCardNameClick(client.ovcid), [onCardNameClick, client]);
    return (_jsxs(Box, Object.assign({ className: classes.userContainer }, { children: [_jsxs(Box, Object.assign({ className: csn(classes.cardHeader, [
                    classes.cardHeaderZen,
                    zendesk,
                ]) }, { children: [_jsx(Box, Object.assign({ className: classes.cardHeaderPartly }, { children: _jsx(DzCardHeaderName, { copyText: clientName.trim(), openText: 'Open User', onClick: onCardNameClickHandler, hideTooltip: !onCardNameClickHandler || zendesk }) })), _jsxs(Box, Object.assign({ className: classes.cardHeaderPartly }, { children: [getPrimaryIcon({
                                primaryClientId,
                                primaryUserHandler,
                                classes,
                            }), _jsx(IconButton, Object.assign({ className: classes.userMenuButton, size: "small", onClick: menuHandler }, { children: _jsx(MoreVertIcon, {}) }))] }))] })), labels && (_jsxs(Box, Object.assign({ position: "relative" }, { children: [_jsx(DzClientInfoContent, { isOpen: cardIsOpen, client: client || null, ovaid: ovaid, labels: labels, clientLabels: clientLabels, zendesk: zendesk, setNoteHeight: setNoteHeight, apiClient: apiClient, thunks: thunks, dispatch: dispatch }), noteHeight > CLIENT_NOTE_HEIGHT && (_jsx(Box, { className: csn([classes.gradientBlur, !cardIsOpen]) }))] }))), !showButton && (_jsxs(Button, Object.assign({ className: classes.openButton, onClick: cardOpenHandler }, { children: [cardIsOpen ? 'SHOW LESS' : 'SHOW MORE', _jsx(Box, Object.assign({ display: "flex", justifyContent: "center", className: csn([classes.arrowClosed, expandButtonHandler(cardIsOpen)], [classes.arrowOpened, expandButtonHandler(!cardIsOpen)]) }, { children: _jsx(ExpandMoreIcon, {}) }))] })))] }), client.ovcid));
};
