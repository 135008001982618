import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      paper: {
        maxWidth: theme.px2rem(500),
      },
      marginBottom: {
        marginBottom: theme.spacing(2),
      },
    };
  },
  { name: 'dz-holidays-dialog' },
);
