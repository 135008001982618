import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchOwners } from './owners.redux';
import { fetchClients } from './clients.redux';
import { fetchAddress } from './sidebars/thunks.redux';

export enum LoadingStatus {
  Initial = 'Initial',
  Loading = 'Loading',
  Success = 'Success',
  Fail = 'Fail',
}

interface LoadingState {
  owners: LoadingStatus;
  clients: LoadingStatus;
  addresses: LoadingStatus;
}

const initialState: LoadingState = {
  owners: LoadingStatus.Initial,
  clients: LoadingStatus.Initial,
  addresses: LoadingStatus.Initial,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoadingFlag: (
      state,
      action: PayloadAction<Partial<LoadingState>>,
    ): LoadingState => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder

      .addCase(
        fetchOwners.pending,
        (state, _): LoadingState => ({
          ...state,
          owners: LoadingStatus.Loading,
        }),
      )
      .addCase(
        fetchOwners.fulfilled,
        (state, _): LoadingState => ({
          ...state,
          owners: LoadingStatus.Success,
        }),
      )
      .addCase(
        fetchClients.pending,
        (state, _): LoadingState => ({
          ...state,
          clients: LoadingStatus.Loading,
        }),
      )
      .addCase(
        fetchClients.fulfilled,
        (state, _): LoadingState => ({
          ...state,
          clients: LoadingStatus.Success,
        }),
      )
      .addCase(
        fetchAddress.pending,
        (state, _): LoadingState => ({
          ...state,
          addresses: LoadingStatus.Loading,
        }),
      )
      .addCase(
        fetchAddress.fulfilled,
        (state, _): LoadingState => ({
          ...state,
          addresses: LoadingStatus.Success,
        }),
      );
  },
});
