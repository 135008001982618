import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
            maxWidth: theme.px2rem(450),
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.custom.OV.sys.surfaceContainerLowest,
            boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
            borderRadius: '4px',
        },
        rootZen: {
            width: '90%',
        },
        closeIcon: {
            position: 'absolute',
            right: 0,
            top: 0,
        },
        top: {
            padding: theme.px2rem(20),
            borderBottom: `0.5px solid ${theme.black.black35}`,
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
        },
        topText: {
            fontWeight: 500,
        },
        input: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
        },
        inputSkeleton: {
            height: theme.px2rem(37),
            width: '90%',
        },
        copyIcon: {
            height: theme.px2rem(21),
            width: theme.px2rem(21),
        },
        newTabButton: {
            marginTop: '1rem',
            color: theme.palette.custom.OV.sys.onSurface,
            textDecoration: 'underline',
            fontSize: theme.px2rem(12),
        },
        skeletonNewTab: {
            height: theme.px2rem(21),
            width: '25%',
        },
        body: {
            padding: `${theme.px2rem(25)} ${theme.px2rem(30)}`,
        },
        inputWrapper: {
            border: `1px solid ${theme.black.black35}`,
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: theme.px2rem(10),
        },
        loadingInput: {
            border: `1px solid ${theme.palette.secondary.main}`,
        },
        footer: {
            display: 'flex',
            justifyContent: 'end',
            padding: theme.px2rem(20),
        },
        closeButton: {
            fontSize: theme.px2rem(14),
            fontWeight: 500,
            color: 'orange',
        },
    };
});
