import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu, Typography, MenuItem, ListItemIcon } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/EditOutlined';
import { DzContentCopyIcon } from '../../icons/dz-content-copy-icon.view';
import { useStyles } from './dz-client-list.styles';
export const DzUserMenu = ({ userMenuInfo, handleCloseUserMenu, handleEditUser, handleDisassociateUser, handleCopyID, isTimerRunning, isFullMenu = true, }) => {
    const classes = useStyles();
    return (_jsxs(Menu, Object.assign({ open: Boolean(userMenuInfo), anchorEl: userMenuInfo === null || userMenuInfo === void 0 ? void 0 : userMenuInfo.anchor, onClose: handleCloseUserMenu }, { children: [_jsxs(MenuItem, Object.assign({ onClick: handleEditUser }, { children: [_jsx(ListItemIcon, Object.assign({ className: classes.listItemIcon }, { children: _jsx(EditIcon, { color: "primary", fontSize: "small" }) })), _jsx(Typography, Object.assign({ variant: "inherit" }, { children: "Update User" }))] })), isFullMenu && (_jsxs(MenuItem, Object.assign({ onClick: handleDisassociateUser }, { children: [_jsx(ListItemIcon, Object.assign({ className: classes.listItemIcon }, { children: _jsx(DeleteIcon, { color: "error", fontSize: "small" }) })), _jsx(Typography, Object.assign({ variant: "inherit" }, { children: "Disassociate User" }))] }))), _jsxs(MenuItem, Object.assign({ onClick: handleCopyID }, { children: [_jsx(ListItemIcon, Object.assign({ className: classes.listItemIcon }, { children: isTimerRunning ? (_jsx(CheckIcon, { fontSize: "small" })) : (_jsx(DzContentCopyIcon, { fontSize: "small" })) })), _jsx(Typography, Object.assign({ variant: "inherit" }, { children: "Copy Client ID" }))] }))] })));
};
