import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';
import { actions, useDzSelector } from 'core/redux';
import { useFormik } from 'formik';
import { DzDialog } from 'shared-ui';
import { Group } from './dz-users.users-view';
import { User } from './useUsers';
import { useUserGroups } from './useUserGroups';
import { auth } from '@one-vision/login';

interface Props {
  user: User | null;
  onClose: () => void;
  onSubmit: (groups: Group[]) => void;
}

export const DzUpdateUserGroupsDialog = ({
  user,
  onClose,
  onSubmit,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { loadingGroups, userGroupsList } = useUserGroups();

  const userConfig = useDzSelector((state) => state.settings.userConfig);

  const hasAccessToPrivateUserGroups =
    !!userConfig.user['access_to_private_user_groups'] ||
    auth.getIsAdmin();

  const yupUserGroup = yup.object({
    id: yup.string(),
    name: yup.string(),
    description: yup.string().nullable(),
  });

  const validationSchema = yup.object({
    groups: yup.array().of(yupUserGroup),
  });

  const formik = useFormik({
    initialValues: {
      groups: [] as Group[],
    },
    validationSchema,
    onSubmit: async ({ groups }) => {
      try {
        setLoading(true);
        await onSubmit(
          !hasAccessToPrivateUserGroups && user
            ? [
                ...groups,
                ...user.userGroups.filter((group) => !group.isPublic),
              ]
            : groups,
        );
        closeHandler();
      } catch {
        dispatch(
          actions.updateSnackbar({
            type: 'error',
            text: 'Can`t create relation',
          }),
        );
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (!user) {
      return;
    }

    const groups = hasAccessToPrivateUserGroups
      ? user.userGroups
      : user.userGroups.filter((group) => group.isPublic);

    formik.resetForm();
    formik.setFieldValue('groups', groups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, hasAccessToPrivateUserGroups]);

  const closeHandler = useCallback(() => {
    formik.resetForm();
    onClose();
  }, [onClose, formik]);

  const onSubmitHandler = useCallback(() => {
    formik.handleSubmit();
  }, [formik]);

  const filteredUserGroupsList = useMemo(() => {
    return hasAccessToPrivateUserGroups
      ? userGroupsList
      : userGroupsList.filter((el) => el.isPublic);
  }, [hasAccessToPrivateUserGroups, userGroupsList]);

  const content = useMemo(
    () => (
      <Autocomplete
        loading={loading || loadingGroups}
        value={formik.values.groups}
        multiple
        disableCloseOnSelect
        onChange={(_, value) => formik.setFieldValue('groups', value)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={filteredUserGroupsList}
        getOptionLabel={(option) => option.name || option.id.toString()}
        renderInput={(params) => (
          <TextField {...params} label="User Group" margin="none" />
        )}
      />
    ),
    [formik, loading, loadingGroups, filteredUserGroupsList],
  );

  return (
    <DzDialog
      isOpen={!!user}
      onClose={closeHandler}
      caption="Update user permissions"
      content={content}
      okButtonText="Save"
      okButtonDisabled={loading || loadingGroups}
      onOk={onSubmitHandler}
    />
  );
};
