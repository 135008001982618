import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Autocomplete, Box, FormControl, FormHelperText, InputAdornment, TextField, Typography, } from '@mui/material';
import { predefinedPreSoldLengths, } from '../../..';
import { useCallback, useMemo, useState, } from 'react';
import NumberFormat from 'react-number-format';
import { DzChip } from '../../shared/dz-chip';
import { useStyles } from './dz-submit-day-one-dialog.styles';
import { csn } from '@one-vision/utils';
const DzPreSoldForm = ({ offeredMemberships, handleChipChange, handleMembershipLevelChange, handlePriceChange, handleBlur, errors, values, leftBorder = true, }) => {
    const classes = useStyles();
    const [selectedChip, setSelectedChip] = useState(() => {
        var _a;
        return ((_a = predefinedPreSoldLengths.find((el) => el.value === (values === null || values === void 0 ? void 0 : values.lengthInMonths))) === null || _a === void 0 ? void 0 : _a.value) || predefinedPreSoldLengths[0].value;
    });
    const onChipChange = useCallback((item) => {
        setSelectedChip(item.value);
        handleChipChange(item.value);
    }, [setSelectedChip]);
    const membershipList = useMemo(() => {
        return offeredMemberships.map((membership) => membership.productName);
    }, [offeredMemberships]);
    return (_jsxs("div", Object.assign({ className: csn(classes.containerBorder, [
            classes.containerBorderLeft,
            leftBorder,
        ]) }, { children: [_jsxs(FormControl, Object.assign({ fullWidth: true, error: !!(errors === null || errors === void 0 ? void 0 : errors.membershipLevel) }, { children: [_jsx(Autocomplete, { id: "preSoldMembership.membershipLevel", value: values === null || values === void 0 ? void 0 : values.membershipLevel, autoHighlight: true, openOnFocus: true, disableClearable: true, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: 'Membership Level', margin: "none", error: !!(errors === null || errors === void 0 ? void 0 : errors.membershipLevel) }))), options: membershipList, isOptionEqualToValue: (option) => option === (values === null || values === void 0 ? void 0 : values.membershipLevel), onChange: (_, newValue) => {
                            handleMembershipLevelChange(newValue);
                        }, onBlur: handleBlur }), !!(errors === null || errors === void 0 ? void 0 : errors.membershipLevel) && (_jsx(FormHelperText, { children: errors === null || errors === void 0 ? void 0 : errors.membershipLevel }))] })), _jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: "Length of Pre-Sold Membership" })), _jsx(Box, Object.assign({ className: classes.chipContainer }, { children: predefinedPreSoldLengths.map((el) => (_jsx(DzChip, { label: el.text, checked: selectedChip === el.value, onClick: () => onChipChange(el), className: classes.preSoldChip }, el.value))) })), _jsxs(FormControl, Object.assign({ fullWidth: true, error: !!(errors === null || errors === void 0 ? void 0 : errors.price) }, { children: [_jsx(NumberFormat, { fullWidth: true, id: 'preSoldMembership.price', label: 'How much did the client pay?', name: "preSoldMembership.price", placeholder: "xxxx", type: "text", customInput: TextField, error: !!(errors === null || errors === void 0 ? void 0 : errors.price), InputProps: {
                            startAdornment: (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: `$` }))),
                        }, isNumericString: true, thousandSeparator: true, isAllowed: ({ value, floatValue = 0 }) => !value.includes('.') && floatValue >= 0, value: values === null || values === void 0 ? void 0 : values.price, onValueChange: (values) => {
                            handlePriceChange(values.floatValue);
                        } }), !!(errors === null || errors === void 0 ? void 0 : errors.price) && (_jsx(FormHelperText, { children: errors === null || errors === void 0 ? void 0 : errors.price }))] }))] })));
};
export default DzPreSoldForm;
