export var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["Active"] = "Active";
    SubscriptionStatus["Past Due"] = "Past Due";
    SubscriptionStatus["Unpaid"] = "Unpaid";
    SubscriptionStatus["Canceled"] = "Canceled";
    SubscriptionStatus["Incomplete"] = "Incomplete";
    SubscriptionStatus["Incomplete Expired"] = "Incomplete Expired";
    SubscriptionStatus["Trialing"] = "Trialing";
})(SubscriptionStatus || (SubscriptionStatus = {}));
