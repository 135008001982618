import React from 'react';
import { useStyles } from './dz-global-search-result.styles';
import { getHiglightedTextParts } from './dz-global-search-utils';

interface Output {
  highlightText: (
    text: string | null,
    searchValue: string,
  ) => JSX.Element | null;
}

export const useFuzzySearch = (): Output => {
  const classes = useStyles();

  const generateHighlightedString = (
    results: {
      firstPart: JSX.Element | null;
      selectedPart: JSX.Element | null;
    }[],
    text: string,
    matches: { startIndex: number; endIndex: number }[],
    actualIndex: number,
  ): {
    firstPart: JSX.Element | null;
    selectedPart: JSX.Element | null;
  }[] => {
    if (!text.length) {
      return results;
    }

    if (!matches.length) {
      results.push({
        firstPart: text ? (
          <div className={classes.text}>{text}</div>
        ) : null,
        selectedPart: null,
      });

      return results;
    }

    const { startIndex, endIndex } = matches[0];

    results.push({
      firstPart: text.slice(0, startIndex - actualIndex) ? (
        <div className={classes.text}>
          {text.slice(0, startIndex - actualIndex)}
        </div>
      ) : null,
      selectedPart: text.slice(
        startIndex - actualIndex,
        endIndex - actualIndex,
      ) ? (
        <div className={classes.selectedText}>
          {text.slice(startIndex - actualIndex, endIndex - actualIndex)}
        </div>
      ) : null,
    });

    matches.shift();

    return generateHighlightedString(
      results,
      text.slice(endIndex - actualIndex),
      matches,
      endIndex,
    );
  };

  const highlightText = (
    text: string | null,
    searchValue: string,
  ): JSX.Element | null => {
    const matches = getHiglightedTextParts(text, searchValue);

    if (!matches.length || !text) {
      return text ? <div className={classes.text}>{text}</div> : null;
    }

    matches.sort((a, b) => {
      if (a.startIndex < b.startIndex) {
        return -1;
      }

      if (a.startIndex > b.startIndex) {
        return 1;
      }

      return 0;
    });

    const elements = generateHighlightedString([], text, matches, 0);

    return (
      <>
        {elements.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item.firstPart}
              {item.selectedPart}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return { highlightText };
};
