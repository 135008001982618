import React from 'react';
import { Tabs, useTheme, useMediaQuery } from '@mui/material';
import { csn, logRender } from '@one-vision/utils';

import { useStyles } from './dz-view-tabs.styles';
import { DzViewTab } from './dz-view-tab';
import { CustomTheme } from 'core/theme';
import { useSelectAddressPanel } from 'core/redux';

interface Props {
  activeView: string;
  tabs: {
    value: string;
    label: string;
    isActive: boolean;
  }[];
  handleViewChange: (viewId: string) => void;
}

export const DzViewTabs: React.FC<Props> = ({
  activeView,
  tabs,
  handleViewChange,
}): React.ReactElement => {
  logRender(DzViewTabs);

  const classes = useStyles();

  const theme = useTheme() as CustomTheme;
  const isSmall = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.lgBiggest),
  );
  const { isOpen: isSidebarOpen } = useSelectAddressPanel();

  return (
    <Tabs
      className={csn(classes.tabsRoot, [
        classes.isSidebarOpen,
        isSidebarOpen && isSmall,
      ])}
      value={activeView}
      classes={{
        flexContainer: classes.tabsFlexContainer,
      }}
      indicatorColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      selectionFollowsFocus={true}
    >
      {tabs.map((tab) => (
        <DzViewTab
          key={tab.value}
          isActive={tab.isActive}
          classes={classes}
          value={tab.value}
          label={tab.label}
          handleTabClick={handleViewChange}
        />
      ))}
    </Tabs>
  );
};
