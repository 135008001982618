class StorageManager {
  storageKey = 'dz_redux_state';
  storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  setItem = (key: string, value: string) => {
    this.storage.setItem(key, value);
  };

  getItem = (key: string) => {
    return this.storage.getItem(key);
  };

  removeItem = (key: string) => {
    this.storage.removeItem(key);
  };

  getReduxState = (): string => {
    return this.getItem(this.storageKey) || '';
  };

  setReduxState = (state: string) => {
    this.setItem(this.storageKey, state);
  };

  removeReduxState = () => {
    this.removeItem(this.storageKey);
  };
}

export const LocalStorageManager = new StorageManager(localStorage);
export const SessionStorageManager = new StorageManager(sessionStorage);
