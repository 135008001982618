import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from '@mui/material';
import { csn } from '@one-vision/utils';
import { useStyles } from './dz-user-card-skeleton.styles';
export const DzUserCardSkeleton = () => {
    const classes = useStyles();
    return (_jsxs("div", Object.assign({ className: classes.card }, { children: [_jsxs("div", Object.assign({ className: csn(classes.cardHeader) }, { children: [_jsx(Skeleton, { animation: "wave", variant: "circular", width: 16, height: 16, component: "div" }), _jsx(Skeleton, { animation: "wave", variant: "text", width: 100, height: 26 }), _jsx(Skeleton, { animation: "wave", variant: "circular", width: 16, height: 16, component: "div", sx: {
                            marginLeft: 'auto',
                        } }), _jsx(Skeleton, { animation: "wave", variant: "circular", width: 16, height: 16, component: "div" })] })), _jsxs("div", Object.assign({ className: csn(classes.cardContent) }, { children: [_jsxs("div", Object.assign({ className: classes.cardContentRow }, { children: [_jsx(Skeleton, { animation: "wave", variant: "circular", width: 16, height: 16, component: "div" }), _jsx(Skeleton, { animation: "wave", variant: "text", width: 170, height: 26 }), _jsx(Skeleton, { animation: "wave", variant: "text", width: 75, height: 26 })] })), _jsxs("div", Object.assign({ className: classes.cardContentRow }, { children: [_jsx(Skeleton, { animation: "wave", variant: "circular", width: 16, height: 16, component: "div" }), _jsx(Skeleton, { animation: "wave", variant: "text", width: 170, height: 26 }), _jsx(Skeleton, { animation: "wave", variant: "text", width: 75, height: 26 })] }))] }))] })));
};
