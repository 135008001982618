import { isRenderingInIframe } from 'core/runtime';

export const DzIframeHiddenContent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  if (isRenderingInIframe) {
    return null;
  }

  return children;
};
