import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Phase } from './types';
import { useStyles } from './dz-bulk-users-importer-header.styles';

interface Props {
  phase: Phase;
  onClose: () => void;
}

export const Header: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.titleContainer}>
      <Typography className={classes.title} variant="h2">
        {headerText(props.phase)}
      </Typography>
      <IconButton
        className={classes.closeButton}
        size="large"
        onClick={props.onClose}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

function headerText(phase: Phase) {
  switch (phase) {
    case Phase.ChoosingSource:
    case Phase.Editing:
      return 'Bulk import users';
    case Phase.Importing:
      return 'Importing users';
    case Phase.Done:
      return 'Import complete';
  }
}
