var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, IconButton, Slide, Switch, TextField, Tooltip, Typography, } from '@mui/material';
import { csn, logError, logRender } from '@one-vision/utils';
import { Field, Form, Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';
import { format, matchFormat } from '@one-vision/date-utils';
import { ISO_DATE_FORMAT, ISO_DATE_MASK, MEMBERSHIP_NOTES_RICH_TEXT_PLACEHOLDER, ModalIDs, ORG_NOTES_RICH_TEXT_PLACEHOLDER, predefinedCppLengths, predefinedWarrantyLengths, unMutableMemberships, } from '../../../const';
import { Csm, DzProjectStage, } from '../../../types';
import { DzDateTimePicker, useDrawerStyles, useFormStyles, useGlobalStyles, usePartnerConfig, } from '../../shared';
import { useModalManager, withModalManager, } from '../../shared/dz-modal-manager';
import { useStyles } from './dz-proposal-won-dialog.styles';
import DzRichTextEditor from '../../shared/dz-rich-text-editor';
import { replaceNewLineWithParagraph } from '../../../utils/replaceNewLineWithParagraph';
import { S3Client } from '../../../utils';
import { useOfferedProducts } from '../../shared/hooks/useOfferedProducts';
import { usePreSoldMembership } from '../../shared/hooks/usePreSoldMembership';
import { DzPreSoldSwitch, } from '../dz-pre-sold-dialog';
const ERROR_TEXT = 'Field is required.';
const FORMAT_ERROR = 'Please, use yyyy-mm-dd date format.';
export const DzProposalWonDialogView = ({ isOpen, close, clear, proposal = {
    clientSelectedMembership: undefined,
    isNew: undefined,
    projectId: '',
    addressId: '',
    orgNotes: '',
    membershipSalesNotes: '',
    estimatedDay1Date: '',
    warrantyLength: 0,
    tosAccepted: 'No',
}, brandId, apiClient, zendesk, dispatch, actions, }) => {
    logRender(DzProposalWonDialogView);
    const s3Client = useMemo(() => {
        return new S3Client({ apiClient });
    }, [apiClient]);
    const classes = Object.assign(Object.assign({}, useStyles()), useGlobalStyles());
    const dialogClasses = useDrawerStyles();
    const formClasses = useFormStyles();
    const { openModal } = useModalManager();
    const partnerConfig = usePartnerConfig();
    const { preSoldMembership } = usePreSoldMembership({
        addressId: proposal.addressId,
    });
    const [preSoldAdded, setPreSoldAdded] = useState(false);
    const handleClose = useCallback(() => close(), [close]);
    const { products: offeredProducts, loading: loadingOfferedProducts } = useOfferedProducts({
        brandId,
    });
    const memberships = useMemo(() => [Csm.Undecided, Csm['Declined Membership']].concat(offeredProducts.map((el) => el.productName)), [offeredProducts]);
    const shouldShowPreSolds = !zendesk &&
        partnerConfig.accessToPreSoldMemberships &&
        !preSoldMembership;
    const handleSubmit = useCallback(async ({ address, project, optOutEducationEmail }) => {
        const addressUpdates = Object.assign({}, address);
        if (address.tosAccepted !== proposal.tosAccepted) {
            addressUpdates.tosSubmissionDate = format(Date.now(), ISO_DATE_FORMAT);
        }
        try {
            const [{ data: [updatedAddress], }, { data: [updatedProject], },] = await Promise.all([
                apiClient.updateAddress(proposal.addressId, addressUpdates),
                apiClient.updateProject(proposal.projectId, Object.assign(Object.assign({}, project), { sendEducationEmail: optOutEducationEmail })),
            ]);
            await apiClient.postToTray({
                OVPRJID: proposal.projectId,
                education_email: optOutEducationEmail,
                Action: 'won_proposal',
            }, 'proposalWon');
            if (preSoldAdded) {
                await openModal(ModalIDs.preSoldMembership, {
                    address: updatedAddress,
                    zendesk,
                    annual: false,
                    dispatch,
                    actions,
                });
            }
            close({
                updatedAddress,
                updatedProject,
            });
        }
        catch (error) {
            logError(error);
        }
    }, [handleClose, zendesk, preSoldAdded]);
    return (_jsxs(Dialog, Object.assign({ open: isOpen, onClose: handleClose, closeAfterTransition: true, transitionDuration: 400, TransitionComponent: Slide, TransitionProps: {
            direction: 'left',
            onExited: clear,
            mountOnEnter: true,
            unmountOnExit: true,
        }, classes: {
            paper: dialogClasses.paper,
            container: dialogClasses.container,
        }, disableRestoreFocus: true }, { children: [_jsxs(DialogTitle, Object.assign({ className: dialogClasses.header }, { children: [_jsx(Typography, { children: "Proposal Won" }), _jsx(IconButton, Object.assign({ onClick: handleClose, color: "inherit" }, { children: _jsx(CloseIcon, { color: "inherit" }) }))] })), _jsx(Formik, Object.assign({ initialValues: {
                    address: {
                        clientSelectedMembership: proposal.clientSelectedMembership ||
                            '',
                        cppLength: proposal.isNew &&
                            proposal.cppLength &&
                            proposal.cppLength != 0
                            ? proposal.cppLength
                            : undefined,
                        orgNotes: proposal.orgNotes || '',
                        membershipSalesNotes: proposal.membershipSalesNotes,
                        tosAccepted: proposal.tosAccepted,
                    },
                    project: {
                        warrantyLength: proposal.warrantyLength || 0,
                        estimatedDay1Date: proposal.estimatedDay1Date || '',
                        projectStageId: DzProjectStage.ActiveProject,
                        signingDate: format(new Date(), 'yyyy-MM-dd'),
                    },
                    optOutEducationEmail: true,
                }, onSubmit: handleSubmit, validationSchema: yup.object({
                    address: yup.object({
                        clientSelectedMembership: yup
                            .string()
                            .nullable(true)
                            .required(ERROR_TEXT),
                    }),
                    project: yup.object({
                        estimatedDay1Date: yup
                            .string()
                            .required(ERROR_TEXT)
                            .test({
                            name: 'inProperFormat',
                            test: (dateString) => {
                                return dateString
                                    ? matchFormat(dateString, ISO_DATE_FORMAT)
                                    : false;
                            },
                            message: FORMAT_ERROR,
                        }),
                    }),
                }) }, { children: ({ dirty, isSubmitting }) => {
                    return (_jsxs(_Fragment, { children: [_jsx(DialogContent, Object.assign({ classes: {
                                    root: csn([dialogClasses.zendeskDialogContent, zendesk]),
                                } }, { children: _jsxs(Form, Object.assign({ id: "proposal-won-form", className: formClasses.formContainer, role: "form" }, { children: [_jsx(Field, Object.assign({ name: "address.clientSelectedMembership" }, { children: ({ field, meta, form }) => {
                                                return (_jsxs(FormGroup, Object.assign({ row: true, className: classes.formRow }, { children: [_jsxs(FormControl, Object.assign({ className: csn(classes.fieldWithInfo, [
                                                                classes.zendeskFieldWithInfo,
                                                                zendesk,
                                                            ]), error: Boolean(meta.touched && meta.error), disabled: unMutableMemberships.has(proposal.clientActivatedMembership) }, { children: [_jsx(Autocomplete, { id: "address.clientSelectedMembership", loading: loadingOfferedProducts, autoHighlight: true, openOnFocus: true, value: field.value, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Desired Membership", margin: "none" }))), options: memberships, getOptionLabel: (option) => option, isOptionEqualToValue: (option) => option === field.value, onChange: (_, newValues) => {
                                                                        form.setFieldValue('address.clientSelectedMembership', newValues);
                                                                    }, onBlur: form.handleBlur }), meta.touched && meta.error && (_jsx(FormHelperText, { children: meta.error }))] })), _jsx(Tooltip, Object.assign({ arrow: true, placement: zendesk ? 'bottom' : 'left', title: "This is the membership the client committed to in the proposal process.", classes: {
                                                                tooltip: classes.tooltip,
                                                            } }, { children: _jsx(InfoOutlinedIcon, {}) }))] })));
                                            } })), (proposal === null || proposal === void 0 ? void 0 : proposal.isNew) && (_jsx(Field, Object.assign({ name: "address.cppLength" }, { children: ({ field, form }) => {
                                                return (_jsx(FormControl, Object.assign({ className: csn(classes.fieldWithInfo, [
                                                        classes.zendeskFieldWithInfo,
                                                        zendesk,
                                                    ]) }, { children: _jsx(Autocomplete, { id: "address.cppLength", autoHighlight: true, openOnFocus: true, value: predefinedCppLengths.find((el) => el.value === Number(field.value)) || null, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Complimentary Period Length", margin: "none" }))), options: predefinedCppLengths, getOptionLabel: (option) => option.text, isOptionEqualToValue: (option) => option.value === field.value, onChange: (_, newValues) => {
                                                            form.setFieldValue('address.cppLength', newValues === null || newValues === void 0 ? void 0 : newValues.value);
                                                        }, onBlur: form.handleBlur }) })));
                                            } }))), _jsx(Field, Object.assign({ name: "project.warrantyLength" }, { children: ({ field, form }) => {
                                                return (_jsx(FormControl, Object.assign({ className: csn(classes.fieldWithInfo, [
                                                        classes.zendeskFieldWithInfo,
                                                        zendesk,
                                                    ]) }, { children: _jsx(Autocomplete, { id: "project.warrantyLength", value: predefinedWarrantyLengths.find((el) => el.value === field.value) || null, autoHighlight: true, openOnFocus: true, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Warranty Length", margin: "none" }))), options: predefinedWarrantyLengths, getOptionLabel: (option) => option.text, isOptionEqualToValue: (option) => option.value === field.value, onChange: (_, newValues) => {
                                                            form.setFieldValue('project.warrantyLength', newValues === null || newValues === void 0 ? void 0 : newValues.value);
                                                        }, onBlur: form.handleBlur }) })));
                                            } })), _jsx(Field, Object.assign({ name: "project.estimatedDay1Date" }, { children: ({ meta, field: { value, onBlur, name }, form, }) => {
                                                return (_jsx(DzDateTimePicker, { onBlur: onBlur, inputFormat: ISO_DATE_FORMAT, mask: ISO_DATE_MASK, name: name, value: value, error: meta.touched && meta.error ? meta.error : '', className: csn(classes.fieldWithInfo, [
                                                        classes.zendeskFieldWithInfo,
                                                        zendesk,
                                                    ]), label: "Estimated Day1", setFieldValue: form.setFieldValue }));
                                            } })), _jsx(Field, Object.assign({ name: "optOutEducationEmail", type: "checkbox" }, { children: ({ field }) => {
                                                return (_jsx(FormControlLabel, { className: csn(classes.switchFormLabel, [
                                                        classes.zendeskSwitchFormLabel,
                                                        zendesk,
                                                    ]), control: _jsxs(Box, Object.assign({ className: formClasses.switchWrap }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", component: "div" }, { children: "Opt-in" })), _jsx(Switch, Object.assign({ id: "optOutEducationEmail", classes: {
                                                                    root: formClasses.rotatedSwitch,
                                                                } }, field, { color: "primary" })), _jsx(Typography, Object.assign({ variant: "subtitle2", component: "div" }, { children: "Opt-out" }))] })), label: _jsx(Box, Object.assign({ className: classes.toggleLabel }, { children: "Send educational emails?" })), labelPlacement: "start" }));
                                            } })), proposal.tosAccepted !== 'Yes' && (_jsx(Field, Object.assign({ name: "address.tosAccepted", type: "checkbox" }, { children: (_a) => {
                                                var _b = _a.field, { checked, onChange } = _b, field = __rest(_b, ["checked", "onChange"]), { form } = _a;
                                                return (_jsx(FormControlLabel, { className: csn(classes.switchFormLabel, [
                                                        classes.zendeskSwitchFormLabel,
                                                        zendesk,
                                                    ]), control: _jsxs(Box, Object.assign({ className: formClasses.switchWrap }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", component: "div" }, { children: "Accepted" })), _jsx(Switch, Object.assign({ id: "optOutEducationEmail", classes: {
                                                                    root: formClasses.rotatedSwitch,
                                                                }, checked: field.value.toLowerCase() === 'yes', onChange: (_, value) => {
                                                                    form.setFieldValue('address.tosAccepted', value ? 'Yes' : 'No');
                                                                } }, field, { color: "primary" })), _jsx(Typography, Object.assign({ variant: "subtitle2", component: "div" }, { children: "Not Accepted" }))] })), label: _jsx(Box, Object.assign({ className: classes.toggleLabel }, { children: "Terms of Service" })), labelPlacement: "start" }));
                                            } }))), shouldShowPreSolds && (_jsx("div", Object.assign({ className: classes.switchFormLabel }, { children: _jsx(DzPreSoldSwitch, { checked: preSoldAdded, onChange: () => setPreSoldAdded(!preSoldAdded), spaceBetween: true, showLabels: true }) }))), _jsx(Divider, { className: classes.divider }), _jsx(Typography, Object.assign({ gutterBottom: true, variant: "h6" }, { children: "Additional Details" })), _jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: "Org Notes" })), _jsx(Field, Object.assign({ name: "address.orgNotes" }, { children: ({ field, form }) => {
                                                return (_jsx(DzRichTextEditor, { content: replaceNewLineWithParagraph(field.value), onUpdate: ({ editor }) => {
                                                        const value = editor.getHTML();
                                                        form.setFieldValue(field.name, value);
                                                    }, placeholder: ORG_NOTES_RICH_TEXT_PLACEHOLDER, s3Client: s3Client }));
                                            } })), _jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: "Membership Sales Notes" })), _jsx(Field, Object.assign({ name: "address.membershipSalesNotes" }, { children: ({ field, form }) => (_jsx(DzRichTextEditor, { content: replaceNewLineWithParagraph(field.value), onUpdate: ({ editor }) => {
                                                    const value = editor.getHTML();
                                                    form.setFieldValue(field.name, value);
                                                }, placeholder: MEMBERSHIP_NOTES_RICH_TEXT_PLACEHOLDER, s3Client: s3Client })) }))] })) })), _jsx(DialogActions, Object.assign({ className: dialogClasses.actions }, { children: _jsx(Button, Object.assign({ size: "large", variant: "contained", color: "primary", type: "submit", form: "proposal-won-form", disabled: !dirty || isSubmitting }, { children: isSubmitting ? 'Submitting...' : 'Submit' })) }))] }));
                } }))] })));
};
export default withModalManager()(DzProposalWonDialogView);
