import { useDzSelector, actions } from 'core/redux';
import { fetchProjects } from 'core/redux/entities';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DzProject, Projects } from 'shared-ui';

interface Input {
  actions: typeof actions;
}

export const useOpenProjectSidebar = ({ actions }: Input) => {
  const dispatch = useDispatch();

  const projectsLookup = useDzSelector((state) =>
    Projects.selectors.selectAllEntities(state),
  );

  const openProjectSidebar = useCallback(
    async (ovprjid?: string) => {
      if (!ovprjid) {
        return;
      }

      let project = projectsLookup?.[ovprjid];

      if (!project) {
        const result = (await dispatch(
          fetchProjects({
            filter: {
              ovprjid,
            },
            include: ['address>primaryClient', 'owners'],
          }),
        )) as unknown as DzProject[] | undefined;

        if (result) {
          project = result[0];
        } else {
          return;
        }
      }
      const { ovaid } = project;

      if (!ovaid) {
        return;
      }
      dispatch(
        actions.updateOrganizationDialogState({
          isOpen: true,
          ovaid: ovaid,
          context: {
            recordId: ovaid,
            ovprjid,
          },
        }),
      );
    },
    [dispatch, projectsLookup, actions],
  );
  return openProjectSidebar;
};
