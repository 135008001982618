import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        flex: 1,
      },
      inputRoot: {
        color: 'inherit',
        height: '100%',
      },
      inputInput: {
        paddingLeft: `calc(1em + ${theme.spacing(3)})`,
        height: '100%',
      },
      inputUnderline: {
        '&:before': {
          borderColor: theme.palette.grey[300],
        },
      },
      search: {
        position: 'relative',
        width: 'auto',
        alignSelf: 'stretch',
      },
      searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.grey[500],
      },
      headerLeftGroup: {
        display: 'flex',
        alignItems: 'center',
      },
      newUserDialog: {
        width: theme.px2rem(450),
      },
      inputMargin: {
        marginBottom: theme.spacing(2),
      },
      accountIcon: {
        margin: '1.3rem 0 1.2rem',
        height: '4rem',
        width: '4rem',
        color: theme.palette.grey[400],
      },
      communication: {
        margin: theme.spacing(2, 0),
        border: `1px solid ${theme.palette.custom.OV.sys.outline}`,
        borderRadius: '4px',
        padding: theme.spacing(2),
      },
      communicationHeader: {
        fontSize: theme.px2rem(14),
        fontWeight: 500,
        color: theme.palette.custom.OV.sys.onSurface,
        marginBottom: theme.spacing(1),
      },
      communicationCaption: {
        fontSize: theme.px2rem(12),
        fontWeight: 400,
        color: theme.palette.custom.OV.sys.onSurfaceVariant,
        marginBottom: theme.spacing(3),
      },
      communicationSelect: {
        width: '50%',
        paddingTop: 0,
      },
      selectPadding: {
        padding: theme.spacing(1, 4, 1, 2),
      },
      selectLabel: {
        top: theme.px2rem(-6),
      },
    };
  },
  { name: 'dz-users' },
);
