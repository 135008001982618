import React from 'react';
import { Skeleton, TableCell, TableRow } from '@mui/material';
import { useStyles } from './dz-simple-grid.styles';

interface DzSimpleGridPlaceholderRowProps {
  columnsCount: number;
}
export const DzSimpleGridPlaceholderRow = ({
  columnsCount,
}: DzSimpleGridPlaceholderRowProps) => {
  const classes = useStyles();
  return (
    <TableRow hover tabIndex={-1} className={classes.tableRow}>
      {Array.from({ length: columnsCount }).map((_, i) => (
        <TableCell align="left" key={i}>
          <Skeleton
            variant="text"
            className={classes.skeletonCell}
            animation="wave"
          />
        </TableCell>
      ))}
    </TableRow>
  );
};
