import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      tableRoot: {
        width: 'unset',
        border: `1px solid ${theme.black.black35}`,
        borderRadius: 0,
        flex: 1,
        '--ag-font-size': theme.px2rem(14),
        '--ag-header-background-color':
          theme.palette.custom.OV.sys.surfaceContainerLowest,
        '--ag-header-cell-hover-background-color':
          theme.palette.custom.OV.sys.surfaceContainerHighest,
      },
      tableHeader: {
        color: theme.palette.grey[500],
      },
      tableRow: {
        opacity: 0,
        animation: '$row-emergence-anim .6s ease-in-out normal forwards',
        transition: theme.transitions.create('background-color'),
        '&:hover': {
          backgroundColor: '#40c5c938',
        },
      },
      publicChip: {
        backgroundColor:
          theme.palette.custom.OV.sys.surfaceContainerHighest,
        color: theme.palette.custom.OV.sys.onSurface,
      },
      privateChip: {
        backgroundColor: theme.palette.custom.OV.sys.successContainer,
        color: theme.palette.custom.OV.sys.onSuccessContainer,
      },
      groupsWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: theme.px2rem(230),
      },
      '@keyframes row-emergence-anim': {
        '100%': {
          opacity: 1,
          transform: 'unset',
        },
      },
    };
  },
  { name: 'dz-users-table' },
);
