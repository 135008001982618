import { makeStyles } from '@mui/styles';

import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      title: {
        marginBottom: theme.spacing(2),
      },
      inputMargin: {
        marginBottom: theme.spacing(2),
      },
    };
  },
  { name: 'dz-temporary-password-dialog' },
);
