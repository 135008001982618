import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider } from '@mui/material';
import { csn } from '@one-vision/utils';
import { useStyles } from '../dz-address-panel.styles';
import { DzMenuView as DzMembershipsMenu } from './dz-menu.view';
import { DzMembershipGeneralInfo, DzMembershipSubscriptionDetails, } from './dz-membership-sections';
import { DzMembershipSkeleton } from './dz-membership-skeleton';
export const DzMembershipsView = ({ address, subscriptionInfo, isLoading, actions, thunks, owner, partner, dispatch, zendesk, zdUserEmail, checkIsAnyDialogActive, apiClient, partnerDetails, }) => {
    const classes = useStyles();
    return (_jsx("div", Object.assign({ "data-testid": "memberships-container" }, { children: !address || isLoading ? (_jsx(DzMembershipSkeleton, { zendesk: zendesk })) : (_jsxs(_Fragment, { children: [_jsx(DzMembershipsMenu, { apiClient: apiClient, address: address, actions: actions, thunks: thunks, owner: owner, partner: partner, dispatch: dispatch, zendesk: zendesk, zdUserEmail: zdUserEmail, checkIsAnyDialogActive: checkIsAnyDialogActive, subscriptionDetails: subscriptionInfo }), _jsx(DzMembershipGeneralInfo, { address: address, partner: partner, zendesk: zendesk, partnerDetails: partnerDetails }), address.chargifySubId && (_jsxs(_Fragment, { children: [_jsx(Divider, { className: csn(classes.divider, [
                                classes.dividerZen,
                                zendesk,
                            ]) }), _jsx(DzMembershipSubscriptionDetails, { subscriptionInfo: subscriptionInfo, zendesk: zendesk })] }))] })) })));
};
