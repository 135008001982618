import { actions, selectSearchValue, useDzSelector } from 'core/redux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useSearchValue = () => {
  const dispatch = useDispatch();
  const searchValue = useDzSelector(selectSearchValue) || '';

  useEffect(() => {
    if (!searchValue) {
      return;
    }

    dispatch(actions.setSearchValue(null));
  }, [searchValue, dispatch]);

  return searchValue;
};
