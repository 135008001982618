import { Csm, ServiceLevel } from './types';
export const memberships = Object.keys(Csm);
export const membershipsWithOutSecurity = memberships.filter((membership) => membership !== Csm['Security Only']);
export const unMutableMemberships = new Set([
    Csm.Priority,
    Csm.Proactive,
    Csm['Essentials Plus'],
    Csm.Signature,
]);
export const CPPServiceLevel = Object.keys(ServiceLevel).filter((item) => item.includes('CPP'));
export const predefinedCppLengths = [
    { text: 'No Complimentary Period', value: 0 },
    { text: '15 Days', value: 15 },
    { text: '30 Days', value: 30 },
    { text: '60 Days', value: 60 },
    { text: '90 Days', value: 90 },
    { text: '180 Days', value: 180 },
];
export const day1PredefinedCppLengths = [
    { text: '30 Days', value: 30 },
    { text: '60 Days', value: 60 },
    { text: '90 Days', value: 90 },
    { text: '180 Days', value: 180 },
    {
        text: 'Custom Expiration Date',
        value: null,
    },
];
export const predefinedPreSoldLengths = [
    { text: '6 months', value: 6 },
    { text: '9 months', value: 9 },
    { text: '1 year', value: 12 },
    { text: '2 years', value: 24 },
    { text: '3 years', value: 36 },
];
export const MAX_CPP_EXP_DAYS = 730;
export const predefinedWarrantyLengths = [
    { text: 'No Warranty', value: 0 },
    { text: '30 Days', value: 30 },
    { text: '60 Days', value: 60 },
    { text: '90 Days', value: 90 },
    { text: '180 Days', value: 180 },
    { text: '365 Days', value: 365 },
];
export const followUpReasons = {
    'Basic - Non Member - Membership Opportunity': 'Needs Support - Non-Member',
    'Basic - Member - Upgrade Opportunity': 'Needs Support - Member',
    'Basic - Miscellaneous Task': 'Misc - Task',
    'Advanced - Non Member - Membership Opportunity': 'Partner Request - General',
    'Advanced - Member - Upgrade Opportunity': 'Partner Request - General',
    'Advanced - VIP Client': 'Target - FF/VIP Clients',
    'Advanced - Miscellaneous Task': 'Partner Request - General',
    'Client Request - General': 'Client Request - General',
    'Past Due Membership': 'Extended Dunning',
    'Membership Downgrade': 'Client Request - Downgrade',
    'Membership Cancellation': 'Client Request - Cancellation',
};
export const usStates = [
    { text: 'Alabama', value: 'AL' },
    { text: 'Alaska', value: 'AK' },
    { text: 'Arizona', value: 'AZ' },
    { text: 'Arkansas', value: 'AR' },
    { text: 'California', value: 'CA' },
    { text: 'Colorado', value: 'CO' },
    { text: 'Connecticut', value: 'CT' },
    { text: 'Delaware', value: 'DE' },
    { text: 'District of Columbia', value: 'DC' },
    { text: 'Florida', value: 'FL' },
    { text: 'Georgia', value: 'GA' },
    { text: 'Hawaii', value: 'HI' },
    { text: 'Idaho', value: 'ID' },
    { text: 'Illinois', value: 'IL' },
    { text: 'Indiana', value: 'IN' },
    { text: 'Iowa', value: 'IA' },
    { text: 'Kansas', value: 'KS' },
    { text: 'Kentucky', value: 'KY' },
    { text: 'Louisiana', value: 'LA' },
    { text: 'Maine', value: 'ME' },
    { text: 'Maryland', value: 'MD' },
    { text: 'Massachusetts', value: 'MA' },
    { text: 'Michigan', value: 'MI' },
    { text: 'Minnesota', value: 'MN' },
    { text: 'Mississippi', value: 'MS' },
    { text: 'Missouri', value: 'MO' },
    { text: 'Montana', value: 'MT' },
    { text: 'Nebraska', value: 'NE' },
    { text: 'Nevada', value: 'NV' },
    { text: 'New Hampshire', value: 'NH' },
    { text: 'New Jersey', value: 'NJ' },
    { text: 'New Mexico', value: 'NM' },
    { text: 'New York', value: 'NY' },
    { text: 'North Carolina', value: 'NC' },
    { text: 'North Dakota', value: 'ND' },
    { text: 'Ohio', value: 'OH' },
    { text: 'Oklahoma', value: 'OK' },
    { text: 'Oregon', value: 'OR' },
    { text: 'Pennsylvania', value: 'PA' },
    { text: 'Rhode Island', value: 'RI' },
    { text: 'South Carolina', value: 'SC' },
    { text: 'South Dakota', value: 'SD' },
    { text: 'Tennessee', value: 'TN' },
    { text: 'Texas', value: 'TX' },
    { text: 'Utah', value: 'UT' },
    { text: 'Vermont', value: 'VT' },
    { text: 'Virginia', value: 'VA' },
    { text: 'Washington', value: 'WA' },
    { text: 'West Virginia', value: 'WV' },
    { text: 'Wisconsin', value: 'WI' },
    { text: 'Wyoming', value: 'WY' },
];
export const canadaProvinces = [
    { text: 'Ontario', value: 'ON' },
    { text: 'Quebec', value: 'QC' },
    { text: 'Nova Scotia', value: 'NS' },
    { text: 'New Brunswick', value: 'NB' },
    { text: 'Manitoba', value: 'MB' },
    { text: 'British Columbia', value: 'BC' },
    { text: 'Prince Edward Island', value: 'PE' },
    { text: 'Saskatchewan', value: 'SK' },
    { text: 'Alberta', value: 'AB' },
    { text: 'Newfoundland and Labrador', value: 'NL' },
];
export const statesList = [...usStates, ...canadaProvinces].map((state) => ({
    value: state.value,
    text: `${state.text} (${state.value})`,
}));
export const ISO_DATE_FORMAT = 'yyyy-MM-dd';
export const ISO_DATE_MASK = '____-__-__';
export const UNASSIGNED_TEXT = 'Unassigned';
export var ModalIDs;
(function (ModalIDs) {
    ModalIDs["createProject"] = "CREATE_PROJECT_DIALOG";
    ModalIDs["createOrganization"] = "CREATE_ORGANIZATION";
    ModalIDs["createUser"] = "CREATE_USER";
    ModalIDs["confirmation"] = "CONFIRMATION";
    ModalIDs["proposalWon"] = "PROPOSAL_WON";
    ModalIDs["proposalLost"] = "PROPOSAL_LOST";
    ModalIDs["submitDayOne"] = "SUBMIT_DAY_ONE";
    ModalIDs["updateTech"] = "UPDATE_TECH";
    ModalIDs["addClient"] = "ADD_CLIENT";
    ModalIDs["addEditClient"] = "ADD_EDIT_CLIENT";
    ModalIDs["addClientToAddress"] = "ADD_CLIENT_TO_ADDRESS";
    ModalIDs["globalSearch"] = "GLOBAL_SEARCH";
    ModalIDs["transitionBanner"] = "TRANSITION_BANNER";
    ModalIDs["editLink"] = "EDIT_LINK";
    ModalIDs["selectImage"] = "SELECT_IMAGE";
    ModalIDs["addOrEditHoliday"] = "ADD_OR_EDIT_HOLIDAY";
    ModalIDs["BulkUsersImporter"] = "BULK_USERS_IMPORTER";
    ModalIDs["preSoldMembership"] = "PRE_SOLD_MEMBERSHIP";
    ModalIDs["cppExpDate"] = "CPP_EXP_DATE";
    ModalIDs["updatePartnerDetails"] = "UPDATE_PARTNER_DETAILS";
    ModalIDs["contactSupport"] = "CONTACT_SUPPORT";
    ModalIDs["oncallManagement"] = "ONCALL_MANAGEMENT";
    ModalIDs["updatePlans"] = "UPDATE_PLANS";
})(ModalIDs || (ModalIDs = {}));
export var WizardStep;
(function (WizardStep) {
    WizardStep[WizardStep["Organization"] = 0] = "Organization";
    WizardStep[WizardStep["AddPrimaryContact"] = 1] = "AddPrimaryContact";
    WizardStep[WizardStep["ProjectDetails"] = 2] = "ProjectDetails";
    WizardStep[WizardStep["MembershipInformation"] = 3] = "MembershipInformation";
})(WizardStep || (WizardStep = {}));
export const requiredField = 'Field is required';
export const CLIENT_NOTE_HEIGHT = 60;
export var ProposalLostReason;
(function (ProposalLostReason) {
    ProposalLostReason[ProposalLostReason["Budget"] = 1] = "Budget";
    ProposalLostReason[ProposalLostReason["Went With Competitor"] = 2] = "Went With Competitor";
    ProposalLostReason[ProposalLostReason["Client Cancelled The Project"] = 3] = "Client Cancelled The Project";
    ProposalLostReason[ProposalLostReason["Other"] = 4] = "Other";
})(ProposalLostReason || (ProposalLostReason = {}));
export const URL_PROTOCOL_REGEXP = /^(mailto:)|^((ht|f)tps?:\/\/)/g;
export const ORG_NOTES_RICH_TEXT_PLACEHOLDER = 'Ex:\\aJob Scope:\\aMost used rooms:\\a Any items NOT okay to reboot?';
export const MEMBERSHIP_NOTES_RICH_TEXT_PLACEHOLDER = 'What should your team know about this client?\\AWhat level of support is a match for the client?';
export const ALLOWED_S3_CONTENT_TYPE = {
    'image/bmp': true,
    'image/gif': true,
    'image/jpeg': true,
    'image/jpg': true,
    'image/png': true,
    'image/tiff': true,
};
export const cppServiceLevelList = [
    ServiceLevel['CPP Essentials Plus'],
    ServiceLevel['CPP Priority'],
    ServiceLevel['CPP Proactive'],
    ServiceLevel['CPP Signature'],
];
export const MEMBERSHIP_SERVICE_LIST = [
    ServiceLevel['Non-Member'],
    ServiceLevel['Essentials Plus'],
    ServiceLevel['Priority'],
    ServiceLevel['Proactive'],
    ServiceLevel['Signature'],
];
export const membershipList = [
    ServiceLevel['Essentials Plus'],
    ServiceLevel['Priority'],
    ServiceLevel['Proactive'],
    ServiceLevel['Signature'],
];
export var TosStatus;
(function (TosStatus) {
    TosStatus["YES"] = "Yes";
    TosStatus["NO"] = "No";
})(TosStatus || (TosStatus = {}));
