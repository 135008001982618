import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        title: {
            fontSize: theme.px2rem(14),
            marginBottom: theme.spacing(1),
            fontWeight: 500,
            paddingLeft: theme.spacing(1),
            color: theme.black.black65,
        },
        chipContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(1),
            },
            marginBottom: theme.spacing(1),
        },
        chip: {
            flex: '1 0 21%',
        },
        marginBottom: {
            marginBottom: theme.spacing(3),
        },
    };
}, { name: 'dz-cpp-exp-dialog', index: 0 });
