import { API } from 'core/api';
import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DzAsyncDispatch } from 'shared-ui';
import { actions } from 'core/redux';

const ERROR_MESSAGE = "Couldn't fetch the account's onboarding url";
export const useStripeAccountOnboardUrl = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<DzAsyncDispatch>();

  const fetchUrl = useCallback(async () => {
    try {
      setLoading(true);

      const {
        data: {
          data: { url },
        },
      } = await API.getStripeOnboardUrl();

      setLoading(false);

      return url;
    } catch {
      dispatch(
        actions.updateSnackbar({
          type: 'error',
          text: ERROR_MESSAGE,
        }),
      );
    }
  }, [dispatch]);

  return {
    fetchUrl,
    loading,
  };
};
