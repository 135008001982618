import { createSelector } from '@reduxjs/toolkit';
import { useModalManager } from 'shared-ui';
import {
  selectAddressPanel,
  selectUpdateAddressDialog,
  selectUpdateProjectInfoDialog,
  useDzSelector,
} from 'core/redux';

export const useHotkeysDisable = () => {
  const { propsDictionary } = useModalManager();

  const isReduxDialogOpen = useDzSelector(selectIsOpenReduxDialogs);
  if (!propsDictionary) {
    return false;
  }

  const isModalManagerDialogOpen = Object.keys(propsDictionary).some(
    (key) => {
      return propsDictionary[key].isOpen;
    },
  );

  return isModalManagerDialogOpen || isReduxDialogOpen;
};

const selectAreAddressDialogsOpen = createSelector(
  selectAddressPanel,
  (addressPanel) => addressPanel.areInnerDialogsOpen,
);

const selectIsOpenReduxDialogs = createSelector(
  selectUpdateAddressDialog,
  selectUpdateProjectInfoDialog,
  selectAreAddressDialogsOpen,
  (addressDialog, projectInfoDialog, areAddressDialogsOpen) => {
    return (
      areAddressDialogsOpen ||
      [addressDialog, projectInfoDialog].some((item) => item.isOpen)
    );
  },
);
