import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
        },
        textField: {
            padding: '18px 12px 19px',
        },
        list: {
            border: '1px solid #C4C4C4',
            minHeight: '308px',
            maxHeight: '308px',
            overflowY: 'auto',
            padding: '0px',
            // marginBottom: theme.spacing(1),
            color: theme.palette.primary.main,
        },
        listHeader: {
            backgroundColor: '#636466',
            color: '#fff',
        },
        dropDownRoot: {
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            backgroundColor: '#fff',
            zIndex: 5,
            boxShadow: theme.shadows[1],
        },
        listItemIcon: {
            minWidth: 'unset',
            color: theme.palette.primary.main,
        },
        selectedRow: {
            backgroundColor: '#def2f3',
        },
        // emergenceAnimation: {
        //   opacity: 0,
        //   animation: '$button-text-anim .2s ease-in-out normal forwards',
        // },
        // '@keyframes button-text-anim': {
        //   '0%': {
        //     opacity: 0,
        //   },
        //   '100%': {
        //     opacity: 1,
        //   },
        // },
    };
}, { name: 'dz-search-or-create-list', index: 0 });
