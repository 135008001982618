import React, { useCallback, useState, useMemo } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import {
  ModalManagerProps,
  withModalManager,
  DzDialog,
  DzDefaultTextField,
  addZeroToDate,
  DzDateTimePicker,
  DzTimeSelect,
} from 'shared-ui';
import { Holiday } from '../dz-holidays-manager.view';
import {
  DEFAULT_START_TIME,
  DEFAULT_END_TIME,
  DEFAULT_START_DAY,
  DEFAULT_END_DAY,
  isHolidayWholeDay,
  TO_UTC,
  getInitDay,
  getInitTime,
  getDay,
} from '../utils';
import { format } from '@one-vision/date-utils';
import { API } from 'core/api';
import { useStyles } from './dz-holidays-dialog.styles';
import dayjs from 'dayjs';

const DATE_FORMAT = 'yyyy-MM-dd hh:mm:ss';

export interface AddOrEditHolidayDialogProps {
  holiday?: Holiday;
  timeZone: string;
}

const DzHolidaysDialog: React.FC<
  ModalManagerProps & AddOrEditHolidayDialogProps
> = ({ close, isOpen, clear, holiday, timeZone }) => {
  const classes = useStyles();

  const [isAllDay, setIsAllDay] = useState<boolean>(
    holiday
      ? isHolidayWholeDay(
          holiday.holidayStart + TO_UTC,
          holiday.holidayEnd + TO_UTC,
        )
      : true,
  );

  const getTime = (
    date: string,
    compareTime: string,
    defaultTime: string,
  ) =>
    getInitTime(date, timeZone) === compareTime
      ? defaultTime
      : getInitTime(date, timeZone);

  const initValues = {
    name: holiday?.holidayName || '',
    holidayStart: holiday
      ? getInitDay(holiday.holidayStart + TO_UTC, timeZone)
      : '',
    holidayEnd: holiday
      ? getInitDay(holiday.holidayEnd + TO_UTC, timeZone)
      : '',
    timeStart: holiday
      ? getTime(
          holiday.holidayStart + TO_UTC,
          DEFAULT_START_DAY,
          DEFAULT_START_TIME,
        )
      : DEFAULT_START_TIME,
    timeEnd: holiday
      ? getTime(
          holiday.holidayEnd + TO_UTC,
          DEFAULT_END_DAY,
          DEFAULT_END_TIME,
        )
      : DEFAULT_END_TIME,
  };

  const validationSchema = yup.object({
    name: yup.string().required('Required'),
    holidayStart: yup.string().required('Required'),
    holidayEnd: yup.string().required('Required'),
    timeStart: isAllDay ? yup.string() : yup.string().required('Required'),
    timeEnd: isAllDay ? yup.string() : yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: initValues,
    validationSchema,
    onSubmit: async (formikRes) => {
      let startTime = DEFAULT_START_DAY;
      let endTime = DEFAULT_END_DAY;
      if (!isAllDay) {
        startTime = formikRes.timeStart;
        endTime = formikRes.timeEnd;
      }

      const getDate = (date: string, time: string) =>
        getDay(date.split(' ')[0] + ' ' + time, timeZone);

      const holidayStart = getDate(
        format(formikRes.holidayStart + ' 00:00:00', DATE_FORMAT),
        startTime,
      );
      const holidayEnd = getDate(
        format(formikRes.holidayEnd + ' 00:00:00', DATE_FORMAT),
        endTime,
      );

      const utcHolidayStartDate = dayjs(formikRes.holidayStart + TO_UTC)
        .toISOString()
        .split('T')[0];
      const utcHolidayEndDate = dayjs(formikRes.holidayEnd + TO_UTC)
        .toISOString()
        .split('T')[0];
      const noTimezoneHolidayStart = `${utcHolidayStartDate}T${startTime}`;
      const noTimezoneHolidayEnd = `${utcHolidayEndDate}T${endTime}`;

      let res: Holiday;
      if (holiday) {
        const { data } = await API.patchPartnerHoliday({
          partnerHolidayId: holiday.partnerHolidayId,
          holidayName: formikRes.name,
          holidayStart,
          holidayEnd,
          noTimezoneHolidayStart,
          noTimezoneHolidayEnd,
        });

        res = data.data[0].attributes;
      } else {
        const { data } = await API.postPartnerHoliday({
          holidayName: formikRes.name,
          holidayStart,
          holidayEnd,
          noTimezoneHolidayStart,
          noTimezoneHolidayEnd,
        });
        res = data;
      }
      close({ ...res });
    },
  });

  const minDate = useMemo(() => {
    const today = new Date();
    const text = `${today.getFullYear()}-${addZeroToDate(
      today.getMonth() + 1,
    )}-${addZeroToDate(today.getDate())} 00:00:00`;
    return text;
  }, []);

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const onCheckChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsAllDay(e.target.checked);
    },
    [setIsAllDay],
  );

  const submitHandler = () => {
    formik.handleSubmit();
  };

  const content = useMemo(
    () => (
      <Box>
        <DzDefaultTextField
          name="name"
          formik={formik}
          label="Holiday Name"
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="wholeDay"
                checked={isAllDay}
                onChange={onCheckChange}
              />
            }
            label="All day"
            className={classes.marginBottom}
          />
        </FormGroup>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box width="45%">
            <DzDateTimePicker
              name="holidayStart"
              value={formik.values.holidayStart}
              error={formik.errors.holidayStart}
              setFieldValue={formik.setFieldValue}
              label="From"
              className={classes.marginBottom}
              minDate={minDate}
              maxDate={formik.values.holidayEnd}
              defaultCalendarMonth={formik.values.holidayEnd}
              disablePast
            />
            <DzDateTimePicker
              name="holidayEnd"
              value={formik.values.holidayEnd}
              error={formik.errors.holidayEnd}
              setFieldValue={formik.setFieldValue}
              label="To"
              minDate={dayjs(formik.values?.holidayStart).toString()}
              defaultCalendarMonth={formik.values.holidayStart}
            />
          </Box>
          {!isAllDay && (
            <Box width="45%" display="flex" flexDirection="column">
              <DzTimeSelect
                formik={formik}
                name="timeStart"
                className={classes.marginBottom}
                label="From"
                maxTime={formik.values['timeEnd']}
              />
              <DzTimeSelect
                formik={formik}
                name="timeEnd"
                label="To"
                minTime={formik.values['timeStart']}
              />
            </Box>
          )}
        </Box>
      </Box>
    ),
    [formik, classes.marginBottom, isAllDay, onCheckChange, minDate],
  );

  return (
    <DzDialog
      isOpen={isOpen}
      onClose={handleClose}
      caption={holiday ? 'Update Holiday' : 'Add New Holiday'}
      okButtonText="Save"
      content={content}
      onOk={submitHandler}
      paperClass={classes.paper}
      clear={clear}
    />
  );
};

export default withModalManager<Holiday>()(DzHolidaysDialog);
