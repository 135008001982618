import { API } from 'core/api';
import { Routes } from 'core/routes';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DzBrand } from 'shared-ui';
import { usePrevious } from 'shared/hooks';
import { DEFAULT_PAGE_VALUE } from './dz-global-search-constants';
import {
  GlobalSearchResultType,
  GlobalSearchResultTypeKey,
} from './dz-global-search-types';
import { UniqueFieldName } from 'components/shared';
import { cleanUpSearchValue } from './dz-global-search-utils';
import { useDispatch } from 'react-redux';
import { actions } from 'core/redux';
import { useSearchParams } from 'react-router-dom';
import { URL_PARAM_NAMES } from 'shared/constants';
import { useOpenProjectSidebar } from 'components/shared';

export interface PageCounters {
  addresses: number;
  clients: number;
  projects: number;
}

interface Input {
  isOpen: boolean;
  close: (value?: unknown) => void;
}

interface Output {
  searchValue: string;
  searchResult: GlobalSearchResultType;
  brands: DzBrand[];
  showMoreLoadingKey: GlobalSearchResultTypeKey | null;
  isResultsLoading: boolean;
  handleSearch: (value: string) => void;
  handleShowMore: (key: GlobalSearchResultTypeKey) => void;
  handleShowLess: (key: GlobalSearchResultTypeKey) => void;
  goToResult: (id: string, uniqueFieldName: UniqueFieldName) => void;
  cleanUpAllSearchState: () => void;
}

const useGlobalSearchDialog = ({ isOpen, close }: Input): Output => {
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState<GlobalSearchResultType>(
    DEFAULT_SEARCH_STATE,
  );
  const [brands, setBrands] = useState<DzBrand[]>([]);
  const [pageCounters, setPageCounters] = useState<PageCounters>(
    DEFAULT_COUNTER_VALUE,
  );
  const [showMoreLoadingKey, setShowMoreLoadingKey] =
    useState<GlobalSearchResultTypeKey | null>(null);
  const [isResultsLoading, setIsResultsLoading] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleSearch = useCallback(
    (value: string) => setSearchValue(value),
    [setSearchValue],
  );

  const handleShowMore = (key: GlobalSearchResultTypeKey) => {
    setPageCounters({ ...pageCounters, [key]: pageCounters[key] + 1 });
    setShowMoreLoadingKey(key);
  };

  const handleShowLess = (key: GlobalSearchResultTypeKey) => {
    setPageCounters({ ...pageCounters, [key]: pageCounters[key] - 1 });
    setShowMoreLoadingKey(key);
  };

  const openProjectSidebar = useOpenProjectSidebar({ actions });

  const openSidebar = (uniqueFieldName: UniqueFieldName, id: string) => {
    if (uniqueFieldName === UniqueFieldName.OVCID) {
      dispatch(
        actions.updateUserSideBarState({
          isOpen: true,
        }),
      );

      return;
    }

    if (uniqueFieldName === UniqueFieldName.OVAID) {
      dispatch(
        actions.updateOrganizationDialogState({
          isOpen: true,
          ovaid: id,
        }),
      );

      return;
    }

    if (uniqueFieldName === UniqueFieldName.OVPRJID) {
      openProjectSidebar(id);

      return;
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const updateUrlParams = async (id: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(URL_PARAM_NAMES.SELECTED, id);

    setSearchParams(newSearchParams);
  };

  const goToResult = (id: string, uniqueFieldName: UniqueFieldName) => {
    close();

    const params = `?${URL_PARAM_NAMES.SELECTED}=${id}`;

    const isTheSameTypeOfEntityAsCurrentlyOpenPage =
      (pathname === Routes.Users &&
        uniqueFieldName === UniqueFieldName.OVCID) ||
      (pathname === Routes.Organizations &&
        uniqueFieldName === UniqueFieldName.OVAID) ||
      (pathname === Routes.Projects &&
        uniqueFieldName === UniqueFieldName.OVPRJID);

    dispatch(actions.setSearchValue(searchValue));

    if (isTheSameTypeOfEntityAsCurrentlyOpenPage) {
      updateUrlParams(id);
      openSidebar(uniqueFieldName, id);
      return;
    }

    if (uniqueFieldName === UniqueFieldName.OVCID) {
      navigate(`${Routes.Users}${params}`);

      return;
    }

    if (uniqueFieldName === UniqueFieldName.OVAID) {
      navigate(`${Routes.Organizations}${params}`);

      return;
    }

    if (uniqueFieldName === UniqueFieldName.OVPRJID) {
      navigate(`${Routes.Projects}${params}`);

      return;
    }
  };

  useEffect(() => {
    API.getBrands().then((result) => {
      setBrands(result.data);
    });
  }, [setBrands]);

  const previousPage = usePrevious(pageCounters);

  const prevSearchValueRef = useRef(searchValue);

  useEffect(() => {
    prevSearchValueRef.current = searchValue;
  }, [searchValue]);

  const prevSearchValue = prevSearchValueRef.current;

  const cleanUpPrevSearchValue = useCallback(() => {
    prevSearchValueRef.current = '';
  }, []);

  const cleanUpAllSearchState = useCallback(() => {
    setSearchValue('');
    cleanUpPrevSearchValue();
  }, [setSearchValue, cleanUpPrevSearchValue]);

  const [previousItemCount, setPreviousItemCount] = useState<{
    addresses: number[];
    clients: number[];
    projects: number[];
  }>({
    addresses: [],
    clients: [],
    projects: [],
  });

  const getChangedParam = (
    previousPage: PageCounters | undefined,
    currentPage: PageCounters,
  ): keyof PageCounters | null => {
    const keys = Object.keys(currentPage) as Array<
      keyof typeof currentPage
    >;
    let changedParam: keyof PageCounters | null = null;

    keys.forEach((key) => {
      const prevValue = previousPage
        ? previousPage[key]
        : DEFAULT_PAGE_VALUE;

      if (currentPage[key] !== prevValue) {
        changedParam = key;
      }
    });

    return changedParam;
  };

  // SHOW MORE / SHOW LESS LOGIC
  useEffect(() => {
    const changedParam = getChangedParam(previousPage, pageCounters);

    if (
      searchValue === '' ||
      searchValue !== prevSearchValue ||
      !changedParam
    ) {
      setShowMoreLoadingKey(null);

      return;
    }

    API.getGlobalSearch(
      searchValue,
      pageCounters[changedParam].toString(),
    ).then((response) => {
      const data = response.data as GlobalSearchResultType;

      setShowMoreLoadingKey(null);

      if (!previousPage) {
        return;
      }

      if (pageCounters[changedParam] > previousPage[changedParam]) {
        setSearchResult((previousState) => ({
          ...previousState,
          [changedParam]: {
            ...data[changedParam],
            items: [
              ...previousState[changedParam].items,
              ...data[changedParam].items,
            ],
          },
        }));

        setPreviousItemCount({
          ...previousItemCount,
          [changedParam]: [
            ...previousItemCount[changedParam],
            data[changedParam].items.length,
          ],
        });

        return;
      }
      setSearchResult((previousState) => ({
        ...previousState,
        [changedParam]: {
          ...data[changedParam],
          items: [
            ...previousState[changedParam].items.slice(
              0,
              previousState[changedParam].items.length -
                previousItemCount[changedParam][
                  previousItemCount[changedParam].length - 1
                ],
            ),
          ],
        },
      }));

      setPreviousItemCount({
        ...previousItemCount,
        [changedParam]: [
          ...previousItemCount[changedParam].slice(
            0,
            previousItemCount[changedParam].length - 1,
          ),
        ],
      });
    });
  }, [
    setSearchResult,
    pageCounters,
    // prevSearchValue,
    // previousItemCount,
    previousPage,
    searchValue,
  ]);

  // SEARCH LOGIC
  useEffect(() => {
    const cleanedUpSearchValue = cleanUpSearchValue(searchValue);

    if (searchValue === '') {
      setSearchResult(DEFAULT_SEARCH_STATE);
    }

    const changedParam = getChangedParam(previousPage, pageCounters);

    if (
      searchValue === prevSearchValue ||
      searchValue === '' ||
      cleanedUpSearchValue === ''
    ) {
      return;
    }

    setIsResultsLoading(true);

    API.getGlobalSearch(
      cleanedUpSearchValue,
      changedParam ? pageCounters[changedParam].toString() : '0',
    ).then((response) => {
      const data = response.data as GlobalSearchResultType;

      if (
        // eslint-disable-next-line
        (data.addresses as any)._errors ||
        // eslint-disable-next-line
        (data.clients as any)._errors ||
        // eslint-disable-next-line
        (data.projects as any)._errors
      ) {
        return console.warn('There is an error : ', data);
      }
      setSearchResult({
        addresses: {
          ...data.addresses,
          items: [...data.addresses.items],
        },
        clients: {
          ...data.clients,
          items: [...data.clients.items],
        },
        projects: {
          ...data.projects,
          items: [...data.projects.items],
        },
      });

      setPreviousItemCount({
        addresses: [data.addresses.items.length],
        clients: [data.clients.items.length],
        projects: [data.projects.items.length],
      });

      setIsResultsLoading(false);
    });
  }, [
    searchValue,
    prevSearchValue,
    setIsResultsLoading,
    pageCounters,
    previousPage,
  ]);

  useEffect(() => {
    if (!isOpen) {
      setSearchResult(DEFAULT_SEARCH_STATE);
      setPageCounters(DEFAULT_COUNTER_VALUE);
      setSearchValue('');
    }
  }, [isOpen]);

  return {
    searchValue,
    searchResult,
    brands,
    isResultsLoading,
    showMoreLoadingKey,
    handleSearch,
    handleShowLess,
    handleShowMore,
    goToResult,
    cleanUpAllSearchState,
  };
};

const DEFAULT_SEARCH_STATE = {
  addresses: {
    totalCount: '0',
    items: [],
  },
  clients: {
    totalCount: '0',
    items: [],
  },
  projects: {
    totalCount: '0',
    items: [],
  },
};

const DEFAULT_COUNTER_VALUE = {
  addresses: DEFAULT_PAGE_VALUE,
  clients: DEFAULT_PAGE_VALUE,
  projects: DEFAULT_PAGE_VALUE,
};

export default useGlobalSearchDialog;
