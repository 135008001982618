import { IHeaderParams } from 'ag-grid-community';
import {
  DzAscendingSort,
  DzDescendingSort,
} from 'components/shared/dz-icons';
import React, { useCallback } from 'react';
import { DEFAULT_SORTING_ORDER } from 'shared/constants';
import { SortDirection } from 'types';

interface SortableHeaderProps extends IHeaderParams {
  sortDirection?: SortDirection;
  onSortDirectionChange: (
    colId: string,
    sortDirection: SortDirection,
  ) => void;
}

const DzGridSortableHeader: React.FC<SortableHeaderProps> = ({
  column,
  displayName,
  onSortDirectionChange = () => undefined,
  sortDirection = null,
}) => {
  const colDef = column.getColDef();

  const sortingOrder: SortDirection[] =
    colDef.sortingOrder || DEFAULT_SORTING_ORDER;

  const handleClick = useCallback(() => {
    const previousDirectionIndex = sortingOrder.indexOf(sortDirection);
    const nextDirectionIndex =
      (previousDirectionIndex + 1) % sortingOrder.length;
    const nextSortDirection = sortingOrder[nextDirectionIndex];
    onSortDirectionChange(colDef.colId || displayName, nextSortDirection);
  }, [
    sortingOrder,
    sortDirection,
    onSortDirectionChange,
    colDef,
    displayName,
  ]);

  return (
    <div
      className="ag-cell-label-container"
      role="presentation"
      onClick={handleClick}
    >
      <div className="ag-header-cell-label" role="presentation">
        <span className="ag-header-cell-text">{displayName}</span>
        <span
          className="ag-header-icon ag-header-label-icon ag-filter-icon ag-hidden"
          aria-hidden="true"
        >
          <span
            className="ag-icon ag-icon-filter"
            role="presentation"
          ></span>
        </span>
        <span className="ag-sort-indicator-container">
          <span
            className="ag-sort-indicator-icon ag-sort-order ag-hidden"
            aria-hidden="true"
          ></span>
          {sortDirection === 'asc' && (
            <span className="ag-sort-indicator-icon ag-sort-ascending-icon">
              <DzAscendingSort />
            </span>
          )}
          {sortDirection === 'desc' && (
            <span className="ag-sort-indicator-icon ag-sort-descending-icon">
              <DzDescendingSort />
            </span>
          )}

          <span
            className="ag-sort-indicator-icon ag-sort-mixed-icon ag-hidden"
            aria-hidden="true"
          >
            <span
              className="ag-icon ag-icon-none"
              role="presentation"
            ></span>
          </span>
          <span
            className="ag-sort-indicator-icon ag-sort-none-icon ag-hidden"
            aria-hidden="true"
          >
            <span
              className="ag-icon ag-icon-none"
              role="presentation"
            ></span>
          </span>
        </span>
      </div>
    </div>
  );
};

export default DzGridSortableHeader;
