import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import DzRichTextEditor from '../../shared/dz-rich-text-editor';
import { S3Client, replaceNewLineWithParagraph } from '../../../utils';
import { MEMBERSHIP_NOTES_RICH_TEXT_PLACEHOLDER, } from '../../..';
import { useMemo } from 'react';
export const DzMembershipSalesNote = ({ address, value = '', handleChange, apiClient, }) => {
    const s3Client = useMemo(() => {
        return new S3Client({ apiClient });
    }, [apiClient]);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: "Membership Sales Notes" })), _jsx(DzRichTextEditor, { content: replaceNewLineWithParagraph(value), onUpdate: ({ editor }) => {
                    const value = editor.getHTML();
                    handleChange(value);
                }, editable: !!address, placeholder: MEMBERSHIP_NOTES_RICH_TEXT_PLACEHOLDER, s3Client: s3Client })] }));
};
