import React from 'react';
import { Box } from '@mui/material';
import { DzCircularProgress } from 'shared-ui';

import { DzGrid } from '../dz-grid';
import {
  UniqueFieldName,
  GridName,
  DzColDef,
} from '../dz-grid/dz-grid.types';
import { useStyles } from './dz-layout-page.styles';
import {
  CellClickedEvent,
  ColDef,
  GridOptions,
  RowClickedEvent,
} from 'ag-grid-community';

export interface Props<RowType> {
  isLoading: boolean;
  isLoadingMoreRows?: boolean;
  layoutSidebar?: JSX.Element;
  layoutHeader?: JSX.Element;
  onButtonClick: () => void;
  preparedColumns: DzColDef[];
  defaultColumnDef?: ColDef;
  preparedRows: RowType[];
  uniqueFieldName?: UniqueFieldName;
  onRowClick?: (e: RowClickedEvent) => void;
  gridName: GridName;
  ownerPopup?: JSX.Element;
  isAddButtonEnabled?: boolean;
  handleCellClick?: (e: CellClickedEvent) => void;
  searchText?: string;
  activeView: string;
  selectNextView: () => void;
  selectPrevView: () => void;
  onBodyScroll?: GridOptions['onBodyScroll'];
  openSidebar: (id: string) => void;
  closeSidebar: () => void;
}

export const DzLayoutPage = <
  RowType extends {
    ovaid: string | null;
    ownerId: string | null;
  },
>(
  props: Props<RowType>,
) => {
  const {
    onRowClick,
    layoutHeader,
    preparedColumns,
    preparedRows,
    uniqueFieldName,
    gridName,
    layoutSidebar,
    isLoading,
    ownerPopup,
    searchText = '',
    handleCellClick,
    activeView,
    selectNextView,
    selectPrevView,
    onBodyScroll,
    openSidebar,
    closeSidebar,
    defaultColumnDef,
  } = props;

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.header}>{layoutHeader}</div>

        <div className={classes.gridWrapper}>
          {isLoading ? (
            <Box>
              <DzCircularProgress />
            </Box>
          ) : (
            <DzGrid
              columns={preparedColumns}
              rows={preparedRows}
              searchText={searchText}
              uniqueFieldName={uniqueFieldName}
              gridName={gridName}
              onRowClick={onRowClick}
              ownerPopup={ownerPopup}
              handleCellClick={handleCellClick}
              activeView={activeView}
              selectNextView={selectNextView}
              selectPrevView={selectPrevView}
              onBodyScroll={onBodyScroll}
              openSidebar={openSidebar}
              closeSidebar={closeSidebar}
              defaultColumnDef={defaultColumnDef}
            />
          )}
        </div>
      </div>

      {layoutSidebar ? layoutSidebar : null}
    </Box>
  );
};
