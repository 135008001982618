import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { API } from 'core/api';
import {
  actions,
  useDzSelector,
  thunks,
  ClientOrClientToAdress,
  useSelectIdentityLabels,
} from 'core/redux';
import { usersColumns } from './dz-users-columns';
import { DzColDef, useSearchValue } from 'components/shared';
import { LoadingStatus } from 'core/redux/loading.redux';
import { useUsersViews } from './useUsersViews';
import { useSearchField } from 'components/shared/dz-layout-page';
import { selectClients } from './dz-users-selectors';
import { usePreSearchList } from 'shared/hooks';
import {
  ModalIDs,
  useModalManager,
  ClientWithMultiContacts,
  DzAddEditClientDialogProps,
  DzAsyncDispatch,
  DzAddressPanelRelatedThunks,
  ApiClient,
} from 'shared-ui';
import { URL_PARAM_NAMES, PreSearchLists } from 'shared/constants';
import { UsersViews } from 'shared/hooks/makeUseFavoriteViews';
import { CellClickedEvent } from 'ag-grid-community';

interface Output {
  rows: ClientOrClientToAdress[];
  isLoading: boolean;
  usersColumns: DzColDef<ClientOrClientToAdress>[];
  activeView: string;
  views: { id: string; text: string }[];
  searchText: string;
  favorites: string[];
  activeViewPreSearchList: string[];
  searchValue: string;
  handleHeaderBtnClick: () => void;
  handleViewChange: (viewId: string) => void;
  onSearch: (searchText: string) => void;
  handleAddFavorite: (viewId: string) => void;
  handleRemoveFavorite: (viewId: string) => void;
  handleCellClick: (e: CellClickedEvent<ClientOrClientToAdress>) => void;
  selectNextView: () => void;
  selectPrevView: () => void;
  handleCreateUser: () => void;
  openSidebar: (id: string) => void;
  closeSidebar: () => void;
  setActiveViewPreSearchListItem: () => void;
}

export const useUsersPage = (): Output => {
  const dispatch = useDispatch<DzAsyncDispatch>();

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(thunks.fetchPrimaryClientIds());
  }, [dispatch]);

  const {
    activeView,
    views,
    favorites,
    handleViewChange,
    handleAddFavorite,
    handleRemoveFavorite,
    selectNextView,
    selectPrevView,
  } = useUsersViews();

  const rows = useDzSelector((state) => selectClients(state, activeView));

  const handleCellClick = useCallback(
    (e: CellClickedEvent<ClientOrClientToAdress>) => {
      if (e.data && typeof e.data.ovcid === 'string') {
        const selectedRowId = e.data.ovcid;
        setSearchParams((oldParams) => {
          oldParams.set(URL_PARAM_NAMES.SELECTED, selectedRowId);
          return [...oldParams];
        });
        dispatch(
          actions.updateUserSideBarState({
            isOpen: true,
          }),
        );
      }
    },
    // setSearchParams is not a stable function, do not include it in deps array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const loading = useDzSelector((state) => state.loading);
  const hasData =
    !!rows.length ||
    (loading.addresses === LoadingStatus.Success &&
      loading.clients === LoadingStatus.Success &&
      loading.owners === LoadingStatus.Success);

  const isLoading = !hasData;

  const handleHeaderBtnClick = useCallback(() => {
    console.log('Header button click');
  }, []);

  const { searchText, onSearch } = useSearchField();

  const [allUsersPreSearchList, allUsersAddToPreSearchList] =
    usePreSearchList(searchText, PreSearchLists.usersAll);
  const [primaryUsersPreSearchList, primaryUsersAddToPreSearchList] =
    usePreSearchList(searchText, PreSearchLists.usersPrimary);
  const [
    noAssociationsUsersPreSearchList,
    noAssociationsAddToPreSearchList,
  ] = usePreSearchList(
    searchText,
    PreSearchLists.usersNoAssociationsPrimary,
  );

  const getPreSearchListHook: () => [string[], () => void] = () => {
    switch (activeView) {
      case UsersViews.allUsers:
        return [allUsersPreSearchList, allUsersAddToPreSearchList];
      case UsersViews.allPrimary:
        return [primaryUsersPreSearchList, primaryUsersAddToPreSearchList];
      case UsersViews.notAssociatedWithOrg:
        return [
          noAssociationsUsersPreSearchList,
          noAssociationsAddToPreSearchList,
        ];
      default:
        return [
          [],
          () => {
            return;
          },
        ];
    }
  };

  const [activeViewPreSearchList, setActiveViewPreSearchListItem] =
    getPreSearchListHook();

  const { openModal } = useModalManager();

  const labels = useSelectIdentityLabels();
  const clients = useDzSelector((state) => state.clients);

  const handleCreateUser = useCallback(async () => {
    const newClient = await openModal<
      DzAddEditClientDialogProps,
      ClientWithMultiContacts
    >(ModalIDs.addEditClient, {
      clients,
      apiClient: API as unknown as ApiClient,
      actions,
      dispatch,
      thunks: thunks as DzAddressPanelRelatedThunks,
      labels,
      create: true,
    });

    if (!newClient) {
      return;
    }

    dispatch(thunks.fetchClients({}));
  }, [openModal, dispatch, clients, labels]);

  const closeSidebar = useCallback(() => {
    dispatch(
      actions.updateUserSideBarState({
        isOpen: false,
        client: null,
      }),
    );
  }, [dispatch]);

  const openSidebar = useCallback(
    (_id: string) => {
      dispatch(
        actions.updateUserSideBarState({
          isOpen: true,
        }),
      );
    },
    [dispatch],
  );

  const searchValue = useSearchValue();

  return {
    searchValue,
    handleViewChange,
    handleHeaderBtnClick,
    handleAddFavorite,
    handleRemoveFavorite,
    favorites: favorites['user'] ? favorites['user'] : [],
    views,
    activeView,
    rows,
    isLoading,
    usersColumns,
    searchText,
    activeViewPreSearchList,
    onSearch,
    handleCellClick,
    selectNextView,
    selectPrevView,
    handleCreateUser,
    closeSidebar,
    openSidebar,
    setActiveViewPreSearchListItem,
  };
};
