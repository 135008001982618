import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { logRender } from '@one-vision/utils';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import DescriptionIcon from '@mui/icons-material/Description';
import { Box, Typography, Button, TextField } from '@mui/material';
import { DzMultiFieldInput } from './dz-multi-field-input';
import { useStyles } from './dz-add-edit-client-dialog.styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { DzDefaultTextField, DzDialog } from '../..';
import { schemaFields, schemas, } from '../../..';
import { removeKeysWithInvalidValues, tryToFormatPhoneNumber, } from '../../../utils';
import { logError } from '@one-vision/utils';
import { addPrimary, checkEmail, checkEditClientDialogChanges, findEmailIndex, sortIdentities, makeSingularEmailAndPhone, defaultClientData, } from './dz-add-edit-client-dialog-utils';
import { withModalManager } from '../../shared';
import { DzRemoveFieldModal } from './dz-remove-field-modal';
export const DzAddEditClientDialog = ({ actions, thunks, isOpen, close, clear, originalClientData, dispatch, clients, labels, zendesk, create, apiClient, }) => {
    logRender(DzAddEditClientDialog);
    const origClientDataWithPrimary = useMemo(() => {
        if (originalClientData && !create) {
            const withPrimary = addPrimary(originalClientData);
            return Object.assign(Object.assign({}, withPrimary), { emails: sortIdentities(withPrimary.emails), phones: sortIdentities(withPrimary.phones) });
        }
        return defaultClientData;
    }, [originalClientData]);
    const validationSchema = useMemo(() => schemas.clientDataMultiIdentity.shape({
        emails: yup.array().of(yup
            .object({
            email: schemaFields.email,
            labelId: schemaFields.labelId,
            primary: schemaFields.primaryContact,
            clientEmailId: schemaFields.clientEmailId,
        })
            .test('email', ({ value }) => ({
            email: `${value.email} is already being used by another user`,
        }), (value) => checkEmail(value, clients, origClientDataWithPrimary))),
    }), [clients, origClientDataWithPrimary]);
    const classes = useStyles();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [removeOnIndex, setRemoveOnIndex] = useState(null);
    const [dynamicSchema, setDynamicSchema] = useState(validationSchema);
    const handleClose = useCallback(() => {
        close();
    }, [close]);
    useEffect(() => {
        var _a, _b;
        let schemaUser = validationSchema;
        if (((_a = origClientDataWithPrimary.emails) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            schemaUser = schemaUser.omit(['emails']);
        }
        if (((_b = origClientDataWithPrimary.phones) === null || _b === void 0 ? void 0 : _b.length) === 0) {
            schemaUser = schemaUser.omit(['phones']);
        }
        setDynamicSchema(schemaUser);
    }, [origClientDataWithPrimary]);
    const formik = useFormik({
        initialValues: origClientDataWithPrimary,
        validationSchema: dynamicSchema,
        onSubmit: async () => {
            var _a;
            setIsSubmitting(true);
            const userData = removeKeysWithInvalidValues(Object.assign({}, changes.userData));
            if (create && apiClient) {
                try {
                    const { data } = await apiClient.createClient(Object.assign(Object.assign({}, userData), { identities: [...changes.identities] }));
                    if (!((_a = data[0]) === null || _a === void 0 ? void 0 : _a.emails)) {
                        data[0].emails = [];
                    }
                    if (data.length) {
                        close(data[0]);
                    }
                }
                catch (err) {
                    const error = err;
                    //The error message comes from the transaction that contains also Cause: section that we don't want to show
                    //https://gitlab.com/ov-resources/npm-packages/-/blob/master/db/src/db-connection.ts#L72
                    const text = error.response.data.message.split(' Cause')[0];
                    logError(error);
                    const [indexes, emailFound] = findEmailIndex(text, formik.values.emails.map((el) => el.email));
                    if (indexes.length) {
                        formik.setFieldError(`emails.${indexes[indexes.length - 1]}.email`, `${emailFound} is already being used by another user`);
                    }
                    dispatch(actions.updateSnackbar({
                        text: text,
                        type: 'error',
                    }));
                }
                finally {
                    setIsSubmitting(false);
                }
                return;
            }
            const payload = Object.assign(Object.assign({}, userData), { ovcid: origClientDataWithPrimary.ovcid, ovaid: origClientDataWithPrimary.ovaid, identities: [...changes.identities] });
            if (changes.userData.notes === '' &&
                changes.userData.notes !== origClientDataWithPrimary.notes) {
                payload.notes = '';
            }
            dispatch(thunks.updateClient({
                changes: payload,
            }))
                .unwrap()
                .then((clientData) => {
                dispatch(actions.updateSnackbar({
                    text: 'User was changed',
                    type: 'success',
                }));
                handleClose();
                const client = Object.assign(Object.assign({}, clientData), { phones: clientData.phones.map((phone) => {
                        return Object.assign(Object.assign({}, phone), { phone: tryToFormatPhoneNumber(phone.phone) });
                    }) });
                const singularContactsClient = makeSingularEmailAndPhone(client);
                dispatch(actions.clientUpdated(singularContactsClient));
                dispatch(actions.clientWithMultiContactsUpdated(client));
            })
                .catch((error) => {
                const [indexes, emailFound] = findEmailIndex(error.message, formik.values.emails.map((el) => el.email));
                if (indexes.length) {
                    formik.setFieldError(`emails.${indexes[indexes.length - 1]}.email`, `${emailFound} is already being used by another user`);
                }
                dispatch(actions.updateSnackbar({
                    text: error.message,
                    type: 'error',
                }));
            })
                .finally(() => {
                setIsSubmitting(false);
            });
        },
    });
    const changes = useMemo(() => {
        return checkEditClientDialogChanges(origClientDataWithPrimary, (formik === null || formik === void 0 ? void 0 : formik.values) || {});
    }, [formik, origClientDataWithPrimary]);
    const isDisabled = !changes.identities.length &&
        !changes.userData.firstName &&
        !changes.userData.lastName &&
        !changes.userData.notes &&
        changes.userData.notes !== '';
    const deleteField = (type, index) => {
        const arr = formik.values[type];
        const filteredArray = arr
            .filter((_, i) => i !== index)
            .map((el) => (Object.assign({}, el)));
        if (!filteredArray.length) {
            formik.setFieldValue(type, []);
            setDynamicSchema(dynamicSchema.omit([type]));
            return;
        }
        if (!filteredArray.find((el) => el.primary)) {
            filteredArray[0].primary = true;
        }
        formik.setFieldValue(type, filteredArray);
    };
    const addField = (type) => {
        const arr = formik.values[type];
        const hasPrimary = arr.find((el) => el.primary === true);
        const newItem = type === 'emails'
            ? { email: '', labelId: null, primary: false }
            : { phone: '', labelId: null, primary: false };
        if (!hasPrimary)
            newItem.primary = true;
        if (arr.length) {
            formik.setFieldValue(type, [...formik.values[type], newItem]);
            return;
        }
        setDynamicSchema(dynamicSchema.concat(validationSchema.pick([type])));
        formik.setFieldValue(type, [newItem]);
    };
    const addEmailFieldHandler = useCallback(() => {
        addField('emails');
    }, [formik, dynamicSchema]);
    const addPhoneFieldHandler = useCallback(() => {
        addField('phones');
    }, [formik, dynamicSchema]);
    const deleteHandler = useCallback(() => {
        if (removeOnIndex) {
            const type = Object.keys(removeOnIndex)[0];
            deleteField(type, removeOnIndex[type]);
            setRemoveOnIndex(null);
        }
    }, [removeOnIndex]);
    const cancelDeleteHandler = useCallback(() => {
        setRemoveOnIndex(null);
    }, [removeOnIndex]);
    const notesHandler = useCallback((e) => {
        formik.setFieldValue('notes', e.target.value);
    }, [formik.setFieldValue]);
    useEffect(() => {
        formik.resetForm();
        formik.setValues(origClientDataWithPrimary);
    }, [isOpen, origClientDataWithPrimary]);
    const [rendered, setRendered] = useState(false);
    useEffect(() => {
        setRendered(true);
    }, []);
    useEffect(() => {
        if (rendered && create) {
            addField('emails');
        }
    }, [rendered, create]);
    const content = useMemo(() => {
        var _a, _b;
        return (_jsxs(_Fragment, { children: [_jsxs(Box, Object.assign({ className: classes.section }, { children: [_jsxs(Box, Object.assign({ className: classes.sectionHeader }, { children: [_jsx(PersonIcon, { className: classes.sectionIcon }), _jsx(Typography, Object.assign({ className: classes.sectionLabel }, { children: "Info" }))] })), _jsx(DzDefaultTextField, { formik: formik, name: "firstName", label: "First Name" }), _jsx(DzDefaultTextField, { formik: formik, name: "lastName", label: "Last Name" })] })), _jsxs(Box, Object.assign({ className: classes.section }, { children: [_jsxs(Box, Object.assign({ className: classes.sectionHeader }, { children: [_jsx(DescriptionIcon, { className: classes.sectionIcon }), _jsx(Typography, Object.assign({ className: classes.sectionLabel }, { children: "Notes" }))] })), _jsx(TextField, { fullWidth: true, type: "text", placeholder: "What is the client\u2019s comfort with technology?\n\nIs there anything that should be known about the client when engaging in a support event?", multiline: true, minRows: 6, maxRows: 10, value: formik.values['notes'] || '', onChange: notesHandler, error: formik.touched['notes'] && Boolean(formik.errors['notes']), helperText: formik.touched['notes'] &&
                                formik.errors['notes'] })] })), _jsxs(Box, Object.assign({ className: classes.section }, { children: [_jsxs(Box, Object.assign({ className: classes.sectionHeader }, { children: [_jsx(EmailIcon, { className: classes.sectionIcon }), _jsx(Typography, Object.assign({ className: classes.sectionLabel }, { children: "Email" }))] })), (_a = formik.values) === null || _a === void 0 ? void 0 : _a.emails.map((_el, index) => (_jsx(DzMultiFieldInput, { index: index, type: "emails", formik: formik, labels: labels['email'], deleteField: () => {
                                setRemoveOnIndex({ emails: index });
                            }, zendesk: zendesk }, `email-multi-${index}`))), _jsxs(Button, Object.assign({ className: classes.addContactButton, onClick: addEmailFieldHandler }, { children: [_jsx(CancelIcon, { className: classes.addContactIcon }), _jsx(Typography, Object.assign({ className: classes.addContactText }, { children: "Add Email" }))] }))] })), _jsxs(Box, Object.assign({ className: classes.section }, { children: [_jsxs(Box, Object.assign({ className: classes.sectionHeader }, { children: [_jsx(PhoneIcon, { className: classes.sectionIcon }), _jsx(Typography, Object.assign({ className: classes.sectionLabel }, { children: "Phone" }))] })), (_b = formik.values) === null || _b === void 0 ? void 0 : _b.phones.map((_el, index) => (_jsx(DzMultiFieldInput, { index: index, type: "phones", formik: formik, labels: labels['phone'], deleteField: () => {
                                setRemoveOnIndex({ phones: index });
                            }, zendesk: zendesk }, `phone-multi-${index}`))), _jsxs(Button, Object.assign({ className: classes.addContactButton, onClick: addPhoneFieldHandler }, { children: [_jsx(CancelIcon, { className: classes.addContactIcon }), _jsx(Typography, Object.assign({ className: classes.addContactText }, { children: "Add Phone" }))] }))] }))] }));
    }, [isSubmitting, formik]);
    return (_jsxs(_Fragment, { children: [_jsx(DzDialog, { isOpen: isOpen, isSubmittingLoader: isSubmitting, caption: create ? 'Create User' : 'Update User', okButtonText: "Save", okButtonDisabled: isSubmitting || isDisabled, content: content, onClose: handleClose, cancelButtonText: "Cancel", onOk: formik.handleSubmit, clear: clear, zendesk: zendesk }), _jsx(DzRemoveFieldModal, { isOpen: !!removeOnIndex, closeDialog: cancelDeleteHandler, onDelete: deleteHandler })] }));
};
export default withModalManager()(DzAddEditClientDialog);
