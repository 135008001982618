import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import NumberFormat from 'react-number-format';
import { useStyles } from './dz-update-plans-dialog.styles';
import { generateFormikKey } from './dz-update-plans-dialog.view';
import { Box, InputAdornment, Switch, TextField, Typography, } from '@mui/material';
export const PlanCollapsible = ({ productOffered, partnerHasBuyNow, formik, }) => {
    var _a, _b;
    const classes = useStyles();
    return (_jsxs("div", Object.assign({ className: classes.productCollapsible }, { children: [_jsx(SwitchInput, { fieldName: "productOffered", label: productOffered.productName, product: productOffered, formik: formik, variant: "h6" }), ((_b = (_a = formik.values) === null || _a === void 0 ? void 0 : _a[productOffered.productId]) === null || _b === void 0 ? void 0 : _b.productOffered) && (_jsxs("div", Object.assign({ className: classes.collapsibleInputs }, { children: [_jsx(SwitchInput, { fieldName: "callForPricing", label: "Call for Pricing", product: productOffered, formik: formik }), _jsx(PriceInput, { fieldName: "monthlyPrice", label: "Monthly Pricing", product: productOffered, formik: formik }), _jsx(PriceInput, { fieldName: "annualPrice", label: "Annual Pricing", product: productOffered, formik: formik }), partnerHasBuyNow && (_jsxs(_Fragment, { children: [_jsx(PriceInput, { fieldName: "monthlyBuyNow", label: "Monthly Buy Now Pricing", product: productOffered, formik: formik }), _jsx(PriceInput, { fieldName: "annualBuyNow", label: "Annual Buy Now Pricing", product: productOffered, formik: formik })] }))] })))] }), productOffered.productId));
};
const PriceInput = ({ fieldName, label, product, formik, }) => {
    var _a, _b;
    const classes = useStyles();
    return (_jsx("div", Object.assign({ className: classes.priceFieldContainer }, { children: _jsx(NumberFormat, { fullWidth: true, id: fieldName, label: label, name: generateFormikKey(product, fieldName), placeholder: "xxxx.xx", type: "text", customInput: TextField, InputProps: {
                startAdornment: (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: `$` }))),
            }, isNumericString: true, thousandSeparator: true, fixedDecimalScale: true, decimalScale: 2, isAllowed: ({ floatValue }) => {
                return (floatValue !== undefined &&
                    floatValue !== null &&
                    floatValue >= 0 &&
                    floatValue <= 999999.99);
            }, value: ((_b = (_a = formik.values) === null || _a === void 0 ? void 0 : _a[product.productId]) === null || _b === void 0 ? void 0 : _b[fieldName]) || 0, onValueChange: (values) => {
                formik.setFieldValue(generateFormikKey(product, fieldName), values.floatValue);
            } }) })));
};
const SwitchInput = ({ fieldName, label, product, formik, variant = 'subtitle1', }) => {
    const classes = useStyles();
    return (_jsxs(Box, Object.assign({ className: classes.switchRow }, { children: [_jsx(Typography, Object.assign({ variant: variant, component: "div" }, { children: label })), _jsx(Switch, { id: fieldName, color: "primary", name: generateFormikKey(product, fieldName), defaultChecked: product[fieldName], onChange: (_, value) => {
                    formik.setFieldValue(generateFormikKey(product, fieldName), value);
                } })] })));
};
