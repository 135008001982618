import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from '@mui/material';
import { csn } from '@one-vision/utils';
import { useStyles } from './dz-tab-content-placeholder.styles';
const DzTabContentPlaceholder = ({ zendesk }) => {
    const classes = useStyles();
    return (_jsxs("div", Object.assign({ className: csn(classes.tabWrapper, classes.tabWithCardWrapper, [
            classes.tabWrapperZen,
            zendesk,
        ]) }, { children: [_jsxs("div", Object.assign({ className: csn(classes.contentHeader, [
                    classes.contentHeaderZen,
                    zendesk,
                ]) }, { children: [_jsx(Skeleton, { animation: "wave", variant: "text", width: 175, height: 26 }), _jsx(Skeleton, { animation: "wave", variant: "text", width: 100, height: 26 })] })), _jsx("div", Object.assign({ className: classes.content }, { children: new Array(3).fill(0).map((_, i) => (_jsxs("div", Object.assign({ className: classes.card }, { children: [_jsxs("div", Object.assign({ className: csn(classes.cardHeader, [
                                classes.cardHeaderZen,
                                zendesk,
                            ]) }, { children: [_jsx(Skeleton, { animation: "wave", variant: "circular", width: 16, height: 16, component: "div" }), _jsx(Skeleton, { animation: "wave", variant: "text", width: 100, height: 26 }), _jsx(Skeleton, { animation: "wave", variant: "circular", width: 16, height: 16, component: "div", sx: {
                                        marginLeft: 'auto',
                                    } }), _jsx(Skeleton, { animation: "wave", variant: "circular", width: 16, height: 16, component: "div" })] })), _jsxs("div", Object.assign({ className: csn(classes.cardContent, [
                                classes.cardContentZen,
                                zendesk,
                            ]) }, { children: [_jsxs("div", Object.assign({ className: classes.cardContentRow }, { children: [_jsx(Skeleton, { animation: "wave", variant: "circular", width: 16, height: 16, component: "div" }), _jsx(Skeleton, { animation: "wave", variant: "text", width: 170, height: 26 }), _jsx(Skeleton, { animation: "wave", variant: "text", width: 75, height: 26 })] })), _jsxs("div", Object.assign({ className: classes.cardContentRow }, { children: [_jsx(Skeleton, { animation: "wave", variant: "circular", width: 16, height: 16, component: "div" }), _jsx(Skeleton, { animation: "wave", variant: "text", width: 170, height: 26 }), _jsx(Skeleton, { animation: "wave", variant: "text", width: 75, height: 26 })] }))] }))] }), i))) }))] })));
};
export default DzTabContentPlaceholder;
