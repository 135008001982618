import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import uniqBy from 'lodash/uniqBy';

import { actions, thunks, useSelectUserSidebar } from 'core/redux';
import { DzUsersSidebarHeader } from './dz-users-sidebar-header';
import { DzUsersSidebarContent } from './dz-users-sidebar-content';
import { DzDrawer } from 'components/shared';
import { useSearchParams } from 'react-router-dom';
import { ClientWithMultiContacts, DzAsyncDispatch } from 'shared-ui';
import { URL_PARAM_NAMES } from 'shared/constants';

export const DzUsersSidebar: React.FC = () => {
  const dispatch = useDispatch<DzAsyncDispatch>();

  const [isLoading, setIsLoading] = useState(false);

  const { isOpen, client } = useSelectUserSidebar();
  const [searchParams, setSearchParams] = useSearchParams();
  const selected = searchParams.get(URL_PARAM_NAMES.SELECTED);

  const handleClose = useCallback(() => {
    searchParams.delete(URL_PARAM_NAMES.SELECTED);
    setSearchParams([...searchParams]);
    setIsLoading(false);

    dispatch(
      actions.updateUserSideBarState({
        isOpen: false,
      }),
    );
  }, [dispatch, searchParams, setSearchParams]);

  const handleOpen = useCallback(() => {
    dispatch(
      actions.updateUserSideBarState({
        isOpen: true,
        client: client,
      }),
    );
  }, [dispatch, client]);

  const getClientAndAddresses = useCallback(async () => {
    const urlClientId = searchParams.get(URL_PARAM_NAMES.SELECTED);

    if (!urlClientId || isLoading) {
      return;
    }

    setIsLoading(true);

    const result: { payload: ClientWithMultiContacts[] } = (await dispatch(
      thunks.fetchClientWithContactsById({
        ovcid: urlClientId,
      }),
    )) as unknown as { payload: ClientWithMultiContacts[] };

    if (!result.payload || !result.payload.length) {
      handleClose();
      return;
    }

    const newClient = result.payload[0];

    if (!newClient.ovcid) {
      return;
    }

    dispatch(
      thunks.fetchAddress({
        'client-id': newClient.ovcid,
      }),
    )
      .unwrap()
      .then((addresses) => {
        if (addresses) {
          dispatch(
            actions.updateUserSideBarState({
              addresses: uniqBy(
                addresses,
                (address) => address.clientToAddress_ovaid,
              ),
            }),
          );

          setIsLoading(false);
        }
      });
  }, [dispatch, searchParams]);

  useEffect(() => {
    getClientAndAddresses();
  }, [client?.ovcid, dispatch, selected, getClientAndAddresses]);

  const isNotReady = isLoading || !client;

  return (
    <DzDrawer
      showButton={true}
      isOpen={isOpen}
      caption={
        <DzUsersSidebarHeader
          isLoading={isNotReady}
          getClientAndAddresses={getClientAndAddresses}
        />
      }
      content={<DzUsersSidebarContent isLoading={isNotReady} />}
      onClose={handleClose}
      onOpen={handleOpen}
    />
  );
};
