/**
 * Utility class to create and parse composite identifiers
 * for grid fields based on their name and position in a collection.
 * Useful for uniquely identifying and retrieving fields using index notation.
 */
export class FieldId {
  static buildId(fieldKey: string, index: number) {
    return `${fieldKey}-${index}`;
  }

  static parseId(id: string): { fieldKey: string; index: number } {
    const [fieldKey, index] = id.split('-');

    return { fieldKey, index: Number(index) };
  }
}
