import { useDzSelector } from 'core/redux';
import { UserGroupRule } from 'shared-ui';
import { User } from 'components/dz-settings-page/dz-users/useUsers';

const useCommunicationPrefAvailable = (
  userToCheck: User | null | undefined,
) => {
  const userConfig = useDzSelector((state) => state.settings.userConfig);

  return (
    userToCheck?.userGroupRules?.includes(UserGroupRule.provisionSignal) &&
    (userConfig.userDetails.id === userToCheck.ownerId ||
      userConfig?.user.access_to_user_management)
  );
};

export default useCommunicationPrefAvailable;
