var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
import { useStyles } from './icons.styles';
export const DzActiveProjectIcon = (_a) => {
    var props = __rest(_a, []);
    const classes = useStyles();
    return (_jsxs(SvgIcon, Object.assign({ viewBox: "0 0 18 18", htmlColor: "#FF8080", className: classes.root }, props, { children: [_jsx("path", { d: "M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9Z" }), _jsx("path", { d: "M9 3C10.0532 3 11.0879 3.27724 12 3.80386C12.9121 4.33047 13.6696 5.0879 14.1962 6.00003C14.7228 6.91215 15 7.94677 15 9C15 10.0532 14.7227 11.0879 14.1961 12.0001C13.6695 12.9122 12.9121 13.6696 11.9999 14.1962C11.0878 14.7228 10.0531 15 8.99991 15C7.94668 15 6.91202 14.7227 5.99991 14.1961C5.0878 13.6695 4.33044 12.9121 3.80385 12L9 9V3Z" })] })));
};
