import { Box, Skeleton } from '@mui/material';
import React from 'react';
import { csn } from '@one-vision/utils';
import { useStyles } from './dz-users-sidebar-contacts.styles';

export const DzUsersSidebarContactsPlaceholder: React.FC = () => {
  const classes = useStyles();

  return (
    <div
      className={csn(classes.contactsBlock, classes.paddingPlaceholder)}
    >
      <Box display="flex" alignItems="center" columnGap={1}>
        <Skeleton
          animation="wave"
          variant="text"
          className={classes.largeTextFieldPlaceholder}
        />
        <Skeleton
          animation="wave"
          variant="text"
          className={classes.largeTextFieldPlaceholder}
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        columnGap={1}
        className={classes.marginTopPlaceholder}
      >
        <Skeleton
          animation="wave"
          variant="circular"
          className={classes.iconSize}
        />
        <Skeleton
          animation="wave"
          variant="text"
          className={classes.veryLargeTextFieldPlaceholder}
        />
        <Skeleton
          animation="wave"
          variant="text"
          className={classes.mediumTextFieldPlaceholder}
        />
      </Box>
      <Box display="flex" alignItems="center" columnGap={1}>
        <Skeleton
          animation="wave"
          variant="circular"
          className={csn(classes.iconSize, classes.hidePlaceholder)}
        />
        <Skeleton
          animation="wave"
          variant="text"
          className={classes.veryLargeTextFieldPlaceholder}
        />
        <Skeleton
          animation="wave"
          variant="text"
          className={classes.mediumTextFieldPlaceholder}
        />
      </Box>
      <Box display="flex" alignItems="center" columnGap={1}>
        <Skeleton
          animation="wave"
          variant="circular"
          className={classes.iconSize}
        />
        <Skeleton
          animation="wave"
          variant="text"
          className={classes.veryLargeTextFieldPlaceholder}
        />
        <Skeleton
          animation="wave"
          variant="text"
          className={classes.mediumTextFieldPlaceholder}
        />
      </Box>
      <Box display="flex" alignItems="center" columnGap={1}>
        <Skeleton
          animation="wave"
          variant="circular"
          className={csn(classes.iconSize, classes.hidePlaceholder)}
        />
        <Skeleton
          animation="wave"
          variant="text"
          className={classes.veryLargeTextFieldPlaceholder}
        />
        <Skeleton
          animation="wave"
          variant="text"
          className={classes.mediumTextFieldPlaceholder}
        />
      </Box>
    </div>
  );
};
