var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useEffect, useState, useCallback, } from 'react';
import { Box, TextField, Button, Autocomplete } from '@mui/material';
import { logError } from '@one-vision/utils';
import HomeIcon from '@mui/icons-material/Home';
import DomainIcon from '@mui/icons-material/Domain';
import { DzSelectedCard } from '../dz-selected-card';
import { DzCreationOption } from '../dz-creation-option';
import { isResidential, getAddressTitle } from '../../../utils';
import { useModalManager } from '../dz-modal-manager';
import { ModalIDs } from '../../../const';
import { useStyles } from './dz-org-autocomplete.styles';
import { DzSkeletonList } from '../dz-autocomplete-list-skeleton';
import { useDebounce } from '../hooks/custom-hooks';
const SEARCH_FIELDS_LIST = [
    'address1',
    'address2',
    'city',
    'state',
    'zip',
    'businessName',
];
const DEBOUNCE_TIME = 300;
const PAGE_LENGTH = 25;
function isAddress(option) {
    return option.ovaid !== undefined;
}
const stringify = (option) => {
    if (isAddress(option)) {
        const label = `${option.address1}${option.address2 && option.address2 !== 'null'
            ? `, ${option.address2}`
            : ''}, ${option.city}, ${option.state}, ${option.zip}`;
        return label;
    }
    return option.inputValue;
};
const filterAddressList = (list, filterList) => {
    if (filterList) {
        const filteredList = list.filter((el) => !filterList.map((address) => address.ovaid).includes(el.ovaid));
        return filteredList;
    }
    return list;
};
export const DzOrgAutocomplete = ({ selectedAddress, onAddressSelect, apiClient, dispatch, actions, withCreate, owners, addressToFilter, }) => {
    const classes = useStyles();
    const [addresses, setAddresses] = useState([]);
    const [brands, setBrands] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const searchValue = useDebounce(inputValue, DEBOUNCE_TIME);
    const title = useMemo(() => getAddressTitle(selectedAddress), [selectedAddress]);
    const isResidentialAddress = useMemo(() => isResidential(selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.ovbid, brands), [selectedAddress, brands]);
    const handleInputChange = useCallback((_, newValue) => {
        setInputValue(newValue);
    }, [setInputValue]);
    const getAddress = async () => {
        const params = {
            search: { fields: SEARCH_FIELDS_LIST, value: searchValue },
            page: { limit: PAGE_LENGTH, offset: 0 },
            include: ['primaryClient', 'primaryProject'],
        };
        const { data } = await apiClient.getAddress(params);
        const list = data.data.map((el) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            return (Object.assign(Object.assign({}, el.attributes), { primaryClientId: ((_d = (_c = (_b = (_a = el.relationships) === null || _a === void 0 ? void 0 : _a.primaryClient) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.id) || '', primaryProjectId: ((_h = (_g = (_f = (_e = el.relationships) === null || _e === void 0 ? void 0 : _e.primaryProject) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.id) || '', ovaid: el.id }));
        });
        return list;
    };
    useEffect(() => {
        if (!searchValue) {
            setAddresses([]);
            return;
        }
        (async () => {
            setLoading(true);
            try {
                const list = await getAddress();
                const filterList = filterAddressList(list, addressToFilter);
                setAddresses(filterList);
            }
            catch (error) {
                logError(error);
                dispatch(actions.updateSnackbar({
                    type: 'error',
                    text: 'Oops! Something went wrong...',
                }));
            }
            finally {
                setLoading(false);
            }
        })();
    }, [searchValue]);
    const { openModal } = useModalManager();
    const onCreate = useCallback(async () => {
        const newAddress = await openModal(ModalIDs.createOrganization, {
            prefilledValue: inputValue,
            apiClient,
            actions,
            dispatch,
            owners,
        });
        if (newAddress) {
            setAddresses((previous) => [...previous, newAddress]);
            onAddressSelect(newAddress);
        }
    }, [
        onAddressSelect,
        setAddresses,
        openModal,
        inputValue,
        dispatch,
        owners,
    ]);
    const closeCard = useCallback(() => {
        onAddressSelect(null);
    }, [onAddressSelect]);
    const handleChange = useCallback((_, value) => {
        if (value === null) {
            onAddressSelect(null);
            return;
        }
        if (isAddress(value)) {
            onAddressSelect(value);
            return;
        }
        onCreate();
    }, [onAddressSelect, onCreate]);
    useEffect(() => {
        (async () => {
            try {
                const brandsRequest = await apiClient.getBrands();
                setLoading(false);
                setBrands(brandsRequest.data);
            }
            catch (error) {
                logError(error);
                dispatch(actions.updateSnackbar({
                    type: 'error',
                    text: 'Oops! Something went wrong...',
                }));
            }
        })();
    }, []);
    return (_jsxs(Box, Object.assign({ className: classes.container }, { children: [selectedAddress && (_jsx(DzSelectedCard, { onClose: closeCard, title: title, Icon: isResidentialAddress ? HomeIcon : DomainIcon })), !selectedAddress && (_jsx(Autocomplete, { autoHighlight: true, clearOnBlur: false, inputValue: inputValue, onInputChange: handleInputChange, value: null, loading: isLoading, loadingText: _jsx(DzSkeletonList, {}), className: classes.input, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Find and associate an existing organization", margin: "none", autoFocus: true }))), options: addresses, filterOptions: (options) => options, onChange: handleChange, 
                // @ts-ignore TODO: Figure out renderOption typings problem.
                renderOption: (_a, option) => {
                    var { key } = _a, props = __rest(_a, ["key"]);
                    if (isAddress(option)) {
                        return (_createElement("li", Object.assign({}, props, { key: key + option.ovaid }), stringify(option)));
                    }
                    if (withCreate) {
                        return (_jsx(DzCreationOption, { description: "Can\u2019t find the organization you\u2019re looking for?", listItemProps: props }, "creationOption"));
                    }
                    return;
                }, getOptionLabel: stringify, classes: {
                    option: classes.option,
                } })), !selectedAddress && withCreate && (_jsx(Button, Object.assign({ size: "large", variant: "contained", color: "primary", onClick: onCreate }, { children: "Create New" })))] })));
};
