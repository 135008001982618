import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormHelperText, Grid } from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { followUpReasons } from '../../../../const';
import { DzDialog, useGlobalStyles } from '../../../shared';
import { schemaFields } from '../../../shared/dz-forms';
const validationSchema = yup.object({
    followUpReason: schemaFields.baseRequiredString('Follow-up reason'),
    additionalNotes: yup.string(),
});
export const DzMembershipSalesRequestView = ({ isOpen, address, onClose, actions, thunks, dispatch, owner, partner, zendesk, zdUserEmail, }) => {
    const globalClasses = useGlobalStyles();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const initialValues = { followUpReason: '', additionalNotes: '' };
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: ({ additionalNotes, followUpReason }) => {
            setIsSubmitting(true);
            dispatch(thunks.postToTray({
                payload: {
                    additional_notes: additionalNotes,
                    what_is_the_reason_for_followup: followUpReasons[followUpReason],
                    submitter_email: (owner === null || owner === void 0 ? void 0 : owner.email) || zdUserEmail,
                    org_id: address === null || address === void 0 ? void 0 : address.zdOrgId,
                    provision: true,
                    subdomain: partner === null || partner === void 0 ? void 0 : partner.zendeskDomain,
                },
                type: 'membershipSalesRequest',
            }))
                .unwrap()
                .then(() => {
                dispatch(actions.updateSnackbar({
                    text: 'Follow-up created with success',
                    type: 'success',
                }));
                formik.resetForm();
                onClose();
            })
                .finally(() => {
                setIsSubmitting(false);
            });
        },
    });
    useEffect(() => {
        formik.resetForm();
        formik.setValues(initialValues);
    }, [address]);
    const content = useMemo(() => {
        return (_jsxs(Grid, Object.assign({ container: true, direction: "column" }, { children: [_jsxs(FormControl, Object.assign({ className: globalClasses.gcFieldMargin, error: formik.touched.followUpReason &&
                        Boolean(formik.errors.followUpReason) }, { children: [_jsx(Autocomplete, { autoHighlight: true, openOnFocus: true, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Reason for follow-up", margin: "none" }))), options: Object.keys(followUpReasons), getOptionLabel: (option) => option, isOptionEqualToValue: (option) => option === formik.values.followUpReason, onChange: (_, value) => {
                                formik.setFieldValue('followUpReason', value);
                            }, onBlur: formik.handleBlur('followUpReason') }), _jsx(FormHelperText, { children: formik.errors.followUpReason })] })), _jsx(FormControl, Object.assign({ className: globalClasses.gcFieldMargin }, { children: _jsx(TextField, { fullWidth: true, label: 'Additional Notes', hiddenLabel: true, multiline: true, maxRows: 10, minRows: 10, InputLabelProps: {
                            shrink: true,
                        }, placeholder: 'Type here', value: formik.values.additionalNotes, onChange: formik.handleChange('additionalNotes'), onBlur: formik.handleBlur('additionalNotes') }) }))] })));
    }, [isSubmitting, formik]);
    return (_jsx(DzDialog, { isOpen: isOpen, caption: "Membership Sales Follow-up", okButtonText: "submit", okButtonDisabled: isSubmitting, isSubmittingLoader: isSubmitting, content: content, onClose: onClose, onOk: formik.handleSubmit, zendesk: zendesk }));
};
