import { useCallback, useEffect, useState } from 'react';
import { useApiClient } from '../dz-api-client-provider';
import { readAsList } from '@one-vision/json-api-parser';
export const useOfferedProducts = ({ brandId, addressId }) => {
    const apiClient = useApiClient();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [products, setProducts] = useState([]);
    const fetchProducts = useCallback(async () => {
        var _a, _b, _c, _d, _e;
        let brandIdToUse = brandId;
        try {
            // In some cases, we don't have a complete address with ovbid so we
            // need to first fetch it
            if (!brandIdToUse && addressId) {
                const { data } = await apiClient.getAddress({
                    filter: { ovaid: addressId },
                    include: ['brand'],
                });
                brandIdToUse =
                    ((_e = (_d = (_c = (_b = (_a = data.data[0]) === null || _a === void 0 ? void 0 : _a.relationships) === null || _b === void 0 ? void 0 : _b.brand) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.id) || '';
            }
            const params = {
                include: ['productName'],
                filter: {
                    ovbid: brandIdToUse,
                },
            };
            const { data: response } = await apiClient.getProducts(params);
            // eslint-disable-next-line
            // @ts-ignore json-api-parser types require further refinement.
            const { entities } = readAsList(response);
            const { Product, ProductName } = entities;
            const visibleMemberships = Product.filter((product) => product.productOffered).map((product) => {
                var _a, _b, _c;
                const productNameId = (_a = product.relationships.productName) === null || _a === void 0 ? void 0 : _a[0].id;
                const productName = (_c = (_b = ProductName.find((productName) => productName.id === productNameId)) === null || _b === void 0 ? void 0 : _b.productName.replace('+', ' Plus')) !== null && _c !== void 0 ? _c : '';
                return Object.assign(Object.assign({}, product), { productName, productId: productNameId });
            });
            setProducts(visibleMemberships);
            setLoading(false);
        }
        catch (e) {
            setError('Error fetching products');
        }
    }, [addressId, brandId, apiClient]);
    useEffect(() => {
        if (brandId || addressId) {
            fetchProducts();
        }
    }, [addressId, brandId, fetchProducts]);
    return {
        products,
        loading,
        error,
    };
};
