import { EmailMembershipInfo } from 'shared-ui';
import { logError } from '@one-vision/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../api';

export const sendMembershipEmail = createAsyncThunk<
  EmailMembershipInfo | null,
  {
    data: EmailMembershipInfo;
  }
>('send-membership-email/post', async (arg) => {
  try {
    const { data } = arg;
    const response = await API.sendMembershipEmail(data);

    return response.data;
  } catch (error) {
    logError(error);
    return null;
  }
});
