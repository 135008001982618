import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: theme.px2rem(220),
        },
        text: {
            fontWeight: 500,
            fontSize: theme.px2rem(14),
            lineHeight: theme.px2rem(18),
            color: theme.dz.colors.oneVisionBlack65,
        },
        icon: {
            marginBottom: theme.px2rem(12),
            color: '#8F9091',
            width: theme.px2rem(60),
            height: theme.px2rem(60),
        },
    };
}, { name: 'dz-empty' });
