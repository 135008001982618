import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { Autocomplete, Box, FormControl, FormHelperText, TextField, Typography, } from '@mui/material';
import { withTheme } from '@mui/styles';
import { Field, Form } from 'formik';
import { DzOwnerListOption, useApiClient, useFormStyles, } from '../../../components/shared';
import { canadaProvinces, ORG_NOTES_RICH_TEXT_PLACEHOLDER, usStates, } from '../../../const';
import { DzGooglePlacesAutocomplete } from '../../../components/shared';
import DzRichTextEditor from '../../shared/dz-rich-text-editor';
import { replaceNewLineWithParagraph } from '../../../utils/replaceNewLineWithParagraph';
import { S3Client } from '../../../utils';
const states = [...usStates, ...canadaProvinces].map((state) => ({
    value: state.value,
    text: `${state.text} (${state.value})`,
}));
const DzOrganizationForm = ({ theme, submitCount, brands, owners }) => {
    const [stateVal, setStateVal] = useState({ text: '', value: '' });
    const options = useMemo(() => {
        const unassigned = {
            name: null,
            ownerId: null,
        };
        return [unassigned, ...owners];
    }, [owners]);
    const formClasses = useFormStyles();
    const apiClient = useApiClient();
    const s3Client = useMemo(() => {
        return new S3Client({ apiClient });
    }, [apiClient]);
    return (_jsxs(Form, Object.assign({ id: "organization-form", className: formClasses.formContainer, role: "form" }, { children: [_jsx(Field, Object.assign({ name: "address1" }, { children: ({ meta, field, form }) => (_jsxs(FormControl, Object.assign({ error: Boolean((meta.touched || submitCount > 0) && meta.error), fullWidth: true }, { children: [_jsx(DzGooglePlacesAutocomplete, { id: "address1", label: "Address 1", initialValue: field.value, onBlur: field.onBlur, onInputValueChanged: (value) => form.setFieldValue('address1', value), onOptionSelected: ({ city, state, zip, address1 }) => {
                                form.setFieldValue('address1', address1);
                                form.setFieldValue('city', city);
                                form.setFieldValue('state', state);
                                form.setFieldValue('zip', zip);
                                setStateVal(states.find((el) => el.value === state) || null);
                            } }), (meta.touched || submitCount > 0) && meta.error && (_jsx(FormHelperText, { children: meta.error }))] }))) })), _jsx(Field, Object.assign({ name: "address2" }, { children: ({ meta, field }) => (_jsx(TextField, Object.assign({ id: "address2", label: "Address 2" }, field, { error: Boolean((meta.touched || submitCount > 0) && meta.error), helperText: (meta.touched || submitCount > 0) && meta.error
                        ? meta.error
                        : undefined }))) })), _jsxs(Box, Object.assign({ display: "flex", columnGap: theme.spacing() }, { children: [_jsx(Box, Object.assign({ flex: 1 }, { children: _jsx(Field, Object.assign({ name: "city" }, { children: ({ meta, field }) => (_jsx(TextField, Object.assign({ id: "city", label: "City" }, field, { error: Boolean((meta.touched || submitCount > 0) && meta.error), helperText: (meta.touched || submitCount > 0) && meta.error
                                    ? meta.error
                                    : undefined, fullWidth: true }))) })) })), _jsx(Box, Object.assign({ flex: 1 }, { children: _jsx(Field, Object.assign({ name: "state" }, { children: ({ meta, field, form, }) => (_jsxs(FormControl, Object.assign({ error: Boolean((meta.touched || submitCount > 0) && meta.error), fullWidth: true }, { children: [_jsx(Autocomplete, { id: "state", value: stateVal, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "State/Province", margin: "none" }))), options: states, getOptionLabel: (option) => option.text, isOptionEqualToValue: (option) => option.value === field.value, onChange: (_, newValue) => {
                                            form.setFieldValue('state', newValue === null || newValue === void 0 ? void 0 : newValue.value);
                                            setStateVal(newValue);
                                        }, onBlur: field.onBlur }), (meta.touched || submitCount > 0) && meta.error && (_jsx(FormHelperText, { children: meta.error }))] }))) })) })), _jsx(Box, Object.assign({ flex: 1 }, { children: _jsx(Field, Object.assign({ name: "zip" }, { children: ({ meta, field }) => (_jsx(TextField, Object.assign({ id: "zip", label: "Zip/Postal Code" }, field, { error: Boolean((meta.touched || submitCount > 0) && meta.error), helperText: (meta.touched || submitCount > 0) && meta.error
                                    ? meta.error
                                    : undefined, fullWidth: true }))) })) }))] })), _jsx(Field, Object.assign({ name: "businessName" }, { children: ({ field }) => (_jsx(TextField, Object.assign({ id: "businessName", label: "Business Name" }, field))) })), _jsx(Field, Object.assign({ name: "ovbid" }, { children: ({ meta, field, form }) => (_jsxs(FormControl, Object.assign({ error: Boolean((meta.touched || submitCount > 0) && meta.error), fullWidth: true }, { children: [_jsx(Autocomplete, { id: "ovbid", loading: !brands, autoHighlight: true, openOnFocus: true, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Client Segment", margin: "none" }))), options: brands || [], getOptionLabel: (option) => option.name, isOptionEqualToValue: (option) => option.ovbid === field.value, onChange: (_, newValue) => {
                                form.setFieldValue('ovbid', newValue === null || newValue === void 0 ? void 0 : newValue.ovbid);
                            }, onBlur: field.onBlur }), (meta.touched || submitCount > 0) && meta.error && (_jsx(FormHelperText, { children: meta.error }))] }))) })), _jsx(Field, Object.assign({ name: "ownerId" }, { children: ({ field, form }) => {
                    return (_jsx(Autocomplete, { autoHighlight: true, isOptionEqualToValue: (option) => option.ownerId === field.value, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Account Manager", margin: "none" }))), options: options, renderOption: (props, option) => (_createElement(DzOwnerListOption, Object.assign({}, props, { name: option.name, key: option.ownerId }))), getOptionLabel: (option) => option.name || 'Unassigned', onChange: (_, value) => {
                            if (!value) {
                                form.setFieldValue(field.name, null);
                                return;
                            }
                            form.setFieldValue(field.name, value.ownerId);
                        } }));
                } })), _jsx(Field, Object.assign({ name: "orgNotes" }, { children: ({ field, form }) => (_jsxs(_Fragment, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: "Organizational Notes" })), _jsx(DzRichTextEditor, { placeholder: ORG_NOTES_RICH_TEXT_PLACEHOLDER, content: replaceNewLineWithParagraph(field.value), onUpdate: ({ editor }) => {
                                const value = editor.getHTML();
                                form.setFieldValue(field.name, value);
                            }, s3Client: s3Client })] })) }))] })));
};
export default withTheme(DzOrganizationForm);
