import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from '@mui/material';
import { useGlobalStyles } from '..';
export const DzDefaultTextFieldView = ({ formik, name, label, disabled, customClasses, }) => {
    const classes = Object.assign(Object.assign({}, useGlobalStyles()), customClasses);
    return (_jsx(TextField, { inputProps: {
            'aria-label': name,
            'data-testid': `input-${name}`,
        }, fullWidth: true, className: classes.gcFieldMargin, name: name, label: label, value: formik.values[name], onChange: formik.handleChange, error: formik.touched[name] && Boolean(formik.errors[name]), helperText: formik.touched[name] && formik.errors[name], disabled: disabled }));
};
