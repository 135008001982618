import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    const heightOfGridPageHeader = theme.px2rem(76);

    return {
      root: {
        position: 'relative',
        width: '0px',
        transition: theme.transitions.create(['width', 'border']),
        border: `none`,
        backgroundColor:
          theme.palette.custom.OV.sys.surfaceContainerLowest,
        borderRadius: '4px',
        overflow: 'hidden',
        flexShrink: 0,
        marginRight: `-${theme.spacing(1)}`,
      },
      showOnOpened: {
        border: `1px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
        width: `${theme.dz.drawer.width}px`,
        marginRight: `0`,
      },
      header: {
        color: theme.palette.custom.OV.sys.onSurface,
        padding: `${theme.px2rem(22)} ${theme.px2rem(20)} ${theme.px2rem(
          22.5,
        )}`,
        borderBottom: `2px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
        boxSizing: 'border-box',
        height: theme.px2rem(theme.dz.subBar.height),
      },
      paper: {
        margin: 0,
        borderRadius: '4px 0px 0px 4px',
        height: '100vh',
        maxHeight: '100vh',
        width: '550px',
      },
      container: {
        justifyContent: 'flex-end',
      },
      noBackdrop: {
        backgroundColor: 'unset',
      },
      content: {
        padding: `0px`,
        overflowY: 'auto',
        position: 'absolute',
        left: 0,
        right: 0,
        top: heightOfGridPageHeader,
        bottom: 0,
      },
      dialogActions: {
        padding: theme.spacing(3),
      },
      okButton: {
        color: theme.palette.common.white,
        height: '38px',
      },
      drawerPaper: {
        width: `${theme.dz.drawer.width}px`,
        minHeight: '100%',
        maxHeight: '100%',
        position: 'relative',
      },
      menuIcon: {
        position: 'absolute',
      },
      closeButton: {
        backgroundColor: theme.palette.custom.OV.sys.surfaceContainer,
        position: 'absolute',
        top: '4px',
        left: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        width: '16px',
        height: '16px',
        cursor: 'pointer',
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: theme.palette.custom.OV.sys.surfaceContainer,
        },
      },
      closeIcon: {
        height: '12px',
        width: '12px',
        color: theme.palette.custom.OV.sys.onSurface,
      },
      rotatedIcon: {
        transform: 'rotate(180deg)',
      },
      tallHeader: {
        height: `${theme.px2rem(75)}`,
        padding: '20px 18px',
      },
    };
  },
  { name: 'dz-drawer' },
);
