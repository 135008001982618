import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Box, FormControl, Select, InputLabel, MenuItem, Input, FormHelperText, Fade, IconButton, Tooltip, } from '@mui/material';
import NumberFormat from 'react-number-format';
import { getIn } from 'formik';
import { csn } from '@one-vision/utils';
import StarIcon from '@mui/icons-material/Star';
import CancelIcon from '@mui/icons-material/Cancel';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { sortIdentities } from '../dz-add-edit-client-dialog-utils';
import { useStyles } from './dz-multi-field-input.styles';
export const DzMultiFieldInput = ({ formik, index, labels, type, deleteField, zendesk, }) => {
    var _a, _b, _c, _d;
    const classes = useStyles();
    const [focused, setFocused] = useState(false);
    const [hovered, setHovered] = useState(false);
    const emailError = !!getIn(formik.touched, `${type}.${index}.email`) &&
        getIn(formik.errors, `${type}.${index}.email`);
    const phoneError = !!getIn(formik.touched, `${type}.${index}.phone`) &&
        getIn(formik.errors, `${type}.${index}.phone`);
    const labelIdError = !!getIn(formik.touched, `${type}.${index}.labelId`) &&
        getIn(formik.errors, `${type}.${index}.labelId`);
    const isHoveredHandler = useCallback(() => {
        setHovered(true);
    }, [setHovered]);
    const unHoveredHandler = useCallback(() => {
        setHovered(false);
    }, [setHovered]);
    const focusHandler = useCallback(() => {
        setFocused(true);
    }, [setFocused]);
    const blurHandler = useCallback(() => {
        setFocused(false);
        setHovered(false);
    }, [setFocused]);
    const labelHandler = useCallback((e) => {
        formik.setFieldValue(`${type}.${index}.labelId`, e.target.value, true);
    }, [formik]);
    const identitiesLength = ((_b = (_a = formik.values) === null || _a === void 0 ? void 0 : _a.emails) === null || _b === void 0 ? void 0 : _b.length) + ((_d = (_c = formik.values) === null || _c === void 0 ? void 0 : _c.phones) === null || _d === void 0 ? void 0 : _d.length) || 0;
    return (_jsxs(Box, Object.assign({ display: "flex", flexDirection: "column" }, { children: [_jsxs(Box, Object.assign({ className: csn(classes.wrapper, [classes.focused, focused], [classes.error, emailError || phoneError || labelIdError]), onMouseEnter: isHoveredHandler, onMouseLeave: unHoveredHandler }, { children: [_jsxs(FormControl, Object.assign({ className: classes.inputWrap }, { children: [_jsx(InputLabel, { children: type === 'emails' ? 'Email' : 'Phone' }), type === 'emails' ? (_jsx(Input, { name: `emails.${index}.email`, classes: {
                                    formControl: classes.inputControl,
                                    input: csn(classes.input, [classes.inputZendesk, zendesk]),
                                }, inputProps: {
                                    'aria-label': `emails.${index}.email`,
                                    'data-testid': `input-emails.${index}.email`,
                                }, disableUnderline: true, value: formik.values.emails[index].email, onChange: formik.handleChange, error: formik.touched[`emails.${index}.email`] &&
                                    !!formik.errors[`emails.${index}.email`], onFocus: focusHandler, onBlur: blurHandler })) : (_jsx(NumberFormat, { customInput: Input, placeholder: '+1 (212) 000-0000', name: `phones.${index}.phone`, classes: {
                                    formControl: classes.inputControl,
                                    input: classes.input,
                                }, inputProps: {
                                    'aria-label': `phones.${index}.phone`,
                                    'data-testid': `input-phones.${index}.phone`,
                                }, disableUnderline: true, value: formik.values.phones[index].phone, onChange: formik.handleChange, error: formik.touched[`phones.${index}.phone`] &&
                                    !!formik.errors[`phones.${index}.phone`], onFocus: focusHandler, onBlur: blurHandler, format: "+1 (###) ###-####", mask: "_" }))] })), formik.values[type][index].primary ? (_jsx(IconButton, Object.assign({ className: csn([classes.iconStarZendesk, zendesk]) }, { children: _jsx(StarIcon, { className: csn(classes.primaryUserIcon, classes.primaryUserIconActive) }) }))) : (_jsx(Fade, Object.assign({ in: hovered }, { children: _jsx(IconButton, Object.assign({ className: csn(classes.starButton, [
                                classes.iconStarZendesk,
                                zendesk,
                            ]), onClick: () => {
                                formik.setFieldValue(type, sortIdentities(formik.values[type].map((el, i) => i === index
                                    ? Object.assign(Object.assign({}, el), { primary: true }) : Object.assign(Object.assign({}, el), { primary: false }))));
                            } }, { children: _jsx(StarBorderIcon, { className: csn(classes.primaryUserIcon, classes.primaryUserIconInactive) }) })) }))), _jsxs(FormControl, Object.assign({ variant: "filled", className: csn(classes.selectWrap, [
                            classes.selectWrapZendesk,
                            zendesk,
                        ]) }, { children: [_jsx(InputLabel, Object.assign({ id: "email-label" }, { children: "Label" })), _jsx(Select, Object.assign({ labelId: "email-label", id: "label", label: "Label", disableUnderline: true, value: formik.values[type][index].labelId || '', classes: { select: classes.selectInput }, onChange: labelHandler, onFocus: focusHandler, onBlur: blurHandler }, { children: labels.map((el) => (_jsx(MenuItem, Object.assign({ value: el.id }, { children: el.label }), el.label))) }))] })), _jsx(Fade, Object.assign({ in: hovered }, { children: identitiesLength === 1 ? (_jsx(Tooltip, Object.assign({ title: "Unable to delete. User must have one email or phone number.", classes: { popper: classes.tooltip } }, { children: _jsx(Box, Object.assign({ className: classes.cancelWrapper }, { children: _jsx(IconButton, Object.assign({ className: csn([classes.iconCancelZendesk, zendesk]), disabled: true }, { children: _jsx(CancelIcon, { className: classes.cancelDisabled }) })) })) }))) : (_jsx(Box, Object.assign({ className: classes.cancelWrapper, onClick: deleteField }, { children: _jsx(IconButton, Object.assign({ className: csn([classes.iconCancelZendesk, zendesk]) }, { children: _jsx(CancelIcon, { className: classes.cancel }) })) }))) }))] })), !!emailError && (_jsx(FormHelperText, Object.assign({ error: !!emailError }, { children: emailError }))), !!phoneError && (_jsx(FormHelperText, Object.assign({ error: !!phoneError }, { children: phoneError }))), !!labelIdError && (_jsx(FormHelperText, Object.assign({ error: !!labelIdError }, { children: labelIdError })))] })));
};
