var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { DEFAULT_DATE_FORMAT, format, } from '@one-vision/date-utils';
import dayjs from 'dayjs';
const DEFAULT_PICKER_VIEWS = [
    'year',
    'month',
    'day',
];
export const DzDateTimePicker = ({ name, value, error, inputFormat = DEFAULT_DATE_FORMAT, mask, setFieldValue, label, className, minDate, maxDate, defaultCalendarMonth, disablePast, disabled, fullWidth, onChange, onBlur, }) => {
    const onDateChange = useCallback((date, inputValue) => {
        if (onChange) {
            return onChange(date, inputValue);
        }
        try {
            const sanitizedValue = !inputValue || inputValue.length === inputFormat.length
                ? format(date, inputFormat)
                : date;
            setFieldValue === null || setFieldValue === void 0 ? void 0 : setFieldValue(name, sanitizedValue !== null && sanitizedValue !== void 0 ? sanitizedValue : '');
        }
        catch (error) {
            setFieldValue === null || setFieldValue === void 0 ? void 0 : setFieldValue(name, inputValue !== null && inputValue !== void 0 ? inputValue : '');
        }
    }, [setFieldValue, value, inputFormat, name, onChange]);
    return (_jsx(DatePicker, { renderInput: (_a) => {
            var { inputProps = {}, value } = _a, params = __rest(_a, ["inputProps", "value"]);
            return (_jsx(TextField, Object.assign({}, params, { fullWidth: fullWidth, name: name, label: label, error: Boolean(error), helperText: error, className: className, inputProps: Object.assign(Object.assign({}, inputProps), { onBlur: (...args) => {
                        if (inputProps.onBlur) {
                            inputProps.onBlur(...args);
                        }
                        onBlur === null || onBlur === void 0 ? void 0 : onBlur(...args);
                    } }) })));
        }, value: dayjs(value), inputFormat: inputFormat, mask: mask, views: DEFAULT_PICKER_VIEWS, onChange: onDateChange, minDate: minDate ? new Date(minDate) : undefined, maxDate: maxDate ? new Date(maxDate) : undefined, defaultCalendarMonth: defaultCalendarMonth ? new Date(defaultCalendarMonth) : undefined, disablePast: disablePast, disabled: disabled }));
};
