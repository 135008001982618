import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, DialogActions, DialogContent, TextField, Zoom, } from '@mui/material';
import { useCallback, useState } from 'react';
import { withModalManager } from '../../shared';
import { useStyles } from './dz-edit-link-dialog.styles';
const DzEditLinkDialog = ({ isOpen, url = '', text = '', textInput = true, close, clear }) => {
    const classes = useStyles();
    const [state, setState] = useState({
        url,
        text,
    });
    const closeHandler = useCallback(() => {
        close();
    }, [close]);
    const handleChange = useCallback((event) => {
        setState((state) => (Object.assign(Object.assign({}, state), { [event.target.name]: event.target.value })));
    }, [setState]);
    const handleSubmit = useCallback(() => {
        close(state);
    }, [state]);
    return (_jsxs(Dialog, Object.assign({ open: isOpen, onClose: closeHandler, closeAfterTransition: true, transitionDuration: 400, TransitionComponent: Zoom, TransitionProps: {
            onExited: clear,
            mountOnEnter: true,
            unmountOnExit: true,
        }, disableRestoreFocus: true, classes: {
            container: classes.dialogContainer,
            paper: classes.dialogPaper,
        } }, { children: [_jsxs(DialogContent, Object.assign({ className: classes.dialogContent }, { children: [textInput && (_jsx(TextField, { label: "Text", name: "text", margin: "none", value: state.text, onChange: handleChange, autoFocus: true })), _jsx(TextField, { label: "URL", name: "url", margin: "none", value: state.url, onChange: handleChange, autoFocus: !textInput })] })), _jsxs(DialogActions, Object.assign({ className: classes.dialogActions }, { children: [_jsx(Button, Object.assign({ variant: "outlined", size: "large", onClick: closeHandler }, { children: "Cancel" })), _jsx(Button, Object.assign({ variant: "contained", color: "primary", size: "large", onClick: handleSubmit }, { children: "Ok" }))] }))] })));
};
export default withModalManager()(DzEditLinkDialog);
