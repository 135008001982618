import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DzClient,
  DzAddress,
  DzClientToAddress,
  OrgDialogProject,
  ClientWithMultiContacts,
  DzAddressPrefixed,
} from 'shared-ui';
import { addressUpdated, addressIsUpdating } from './actions';
import {
  clientDisassociated,
  clientUpdated,
} from '../actions/clients.actions';
import { fetchOrgDialogProjects } from './organization-dialog.redux';
import { fetchClientWithContactsById } from '../clients.redux';

export interface UserSideBarSliceState {
  isOpen: boolean;
  client: ClientWithMultiContacts | null;
  addresses: DzAddressPrefixed[];
  projects: { [addressId: string]: OrgDialogProject[] };
}

export const userSideBarSlice = createSlice({
  name: 'user-dialog',
  initialState: {
    isOpen: false,
    client: null,
    addresses: [],
    projects: {},
  } as UserSideBarSliceState,
  reducers: {
    updateUserSideBarState: (
      state,
      action: PayloadAction<Partial<UserSideBarSliceState>>,
    ): UserSideBarSliceState => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchOrgDialogProjects.fulfilled,
        (
          state,
          action: ReturnType<typeof fetchOrgDialogProjects.fulfilled>,
        ): UserSideBarSliceState => {
          return {
            ...state,
            projects: {
              ...state.projects,
              [action.meta.arg.ovaid]: action.payload,
            },
          };
        },
      )
      .addCase(
        fetchClientWithContactsById.fulfilled,
        (
          state,
          action: ReturnType<typeof fetchClientWithContactsById.fulfilled>,
        ) => {
          state.client = action.payload[0];
        },
      )
      .addCase(
        clientUpdated,
        (
          state,
          action: PayloadAction<DzClientToAddress & DzClient>,
        ): UserSideBarSliceState => {
          if (state.client?.ovcid === action.payload.ovcid) {
            return {
              ...state,
              client: { ...state.client, ...action.payload },
            };
          }
          return state;
        },
      )
      .addCase(addressIsUpdating, (state, action) => {
        if (
          state.addresses.filter((el) => el.ovaid === action.payload.ovaid)
            .length
        ) {
          return {
            ...state,
            addresses: state.addresses.map((el) =>
              el.ovaid === action.payload.ovaid
                ? {
                    ...el,
                    ownerId: action.payload
                      .ownerId as DzAddress['ownerId'],
                  }
                : el,
            ),
          };
        }
        return state;
      })
      .addCase(
        addressUpdated,
        (
          state,
          action, // TODO: Check if it returns a prefixed address and not the usual one (DzAddress)
        ) => {
          if (
            state.addresses.filter(
              (el) => el.ovaid === action.payload.ovaid,
            ).length
          ) {
            return {
              ...state,
              addresses: state.addresses.map((el) =>
                el.ovaid === action.payload.ovaid
                  ? (action.payload as unknown as DzAddressPrefixed)
                  : el,
              ),
            };
          }
          return state;
        },
      )
      .addCase(
        clientDisassociated,
        (
          state,
          action: PayloadAction<{
            clientId: DzClientToAddress['ovcid'];
            addressId: DzClientToAddress['ovaid'];
          }>,
        ): UserSideBarSliceState => {
          if (
            state.client?.ovcid === action.payload.clientId &&
            state.addresses?.length
          ) {
            return {
              ...state,
              addresses: state.addresses.filter(
                (el) => el.ovaid !== action.payload.addressId,
              ),
            };
          }
          return state;
        },
      );
  },
});
