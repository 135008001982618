var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import { csn } from '@one-vision/utils';
import * as React from 'react';
import NumberFormat from 'react-number-format';
const styles = {
    noButtons: {
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '-moz-appearance': 'textfield',
    },
};
class DzNumberInputInternal extends React.Component {
    render() {
        const _a = this.props, { inputRef, decimalPartLength, className, classes, type } = _a, other = __rest(_a, ["inputRef", "decimalPartLength", "className", "classes", "type"]);
        return (_jsx(NumberFormat, Object.assign({}, other, { type: "text", className: csn(className, classes.noButtons), getInputRef: inputRef, thousandSeparator: true, decimalScale: decimalPartLength, fixedDecimalScale: true })));
    }
}
export const DzNumberInput = withStyles(styles)(DzNumberInputInternal);
export function DzNumberTextField(_a) {
    var { InputProps, onValueChange } = _a, props = __rest(_a, ["InputProps", "onValueChange"]);
    const newInputProps = Object.assign(Object.assign({}, InputProps), { inputProps: Object.assign(Object.assign({}, InputProps === null || InputProps === void 0 ? void 0 : InputProps.inputProps), { onValueChange }) });
    return (_jsx(TextField, Object.assign({}, props, { InputProps: Object.assign(Object.assign({}, newInputProps), { inputComponent: DzNumberInput }) })));
}
