import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      headerRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
      },
      headerLeftGroup: {
        display: 'flex',
        alignItems: 'center',
      },
      createButton: {
        backgroundColor: '#40C5C9',
        color: '#fff',
        lineHeight: 2,
        width: theme.px2rem(157),
        fontWeight: 600,
        '&:hover': {
          backgroundColor: '#266769',
        },
      },
      createButtonIcon: {
        marginRight: '10px',
        fontSize: '16px',
      },
    };
  },
  { name: 'dz-users-header' },
);
