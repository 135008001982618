import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      contentTitle: {
        fontWeight: 500,
        fontSize: theme.px2rem(16),
        padding: `${theme.px2rem(25)} 0`,
        color: theme.black.black65,
      },
      contactsTitle: {
        padding: `${theme.px2rem(12)} 0 0 ${theme.px2rem(14)}`,
      },
      contactsBlock: {
        borderBottom: `2px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
      },
      veryLargeTextFieldPlaceholder: {
        width: 100,
        height: 28,
      },
      largeTextFieldPlaceholder: {
        width: 75,
        height: 28,
      },
      mediumTextFieldPlaceholder: {
        width: 50,
        height: 28,
      },
      marginTopPlaceholder: {
        marginTop: 20,
      },
      paddingPlaceholder: {
        padding: 20,
      },
      iconSize: {
        width: 20,
        height: 20,
      },
      hidePlaceholder: {
        visibility: 'hidden',
      },
    };
  },
  { name: 'dz-users-sidebar-contacts', index: 0 },
);
