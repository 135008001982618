import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      button: {
        color: theme.black.black50,
        borderColor: theme.black.black50,
        '&:hover': {
          color: theme.palette.primary.main,
        },
        width: theme.px2rem(450),
        justifyContent: 'space-between',
        padding: `${theme.px2rem(9)} ${theme.px2rem(20)}`,
        borderRadius: 8,
      },
      text: {
        textTransform: 'none',
        fontWeight: 400,
        fontSize: theme.px2rem(16),
      },
      keyCombo: {
        display: 'flex',
        alignItems: 'center',
      },
    };
  },
  { name: 'dz-global-search-button', index: 0 },
);
