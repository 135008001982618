import React, { useCallback, useMemo } from 'react';
import {
  useTheme,
  useMediaQuery,
  Button,
  Fab,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  SelectChangeEvent,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { csn } from '@one-vision/utils';
import { DzViewTabs } from 'components/shared';
import StarFilled from '@mui/icons-material/StarOutlined';
import StarBorder from '@mui/icons-material/StarOutlineOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import { useStyles } from './dz-page-header.styles';
import { CustomTheme } from 'core/theme';
import { DzSearchInput, useUserConfig } from 'shared-ui';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { auth } from '@one-vision/login';

interface Props {
  views: { id: string; text: string }[];
  activeView: string;
  handleViewChange: (viewId: string) => void;
  onAddButtonClick: () => void;
  searchValue?: string;
  onSearch: (text: string) => void;
  favorites: string[] | undefined;
  handleAddFavorite: (viewId: string) => void;
  handleRemoveFavorite: (viewId: string) => void;
  buttonName: string;
  useSelectSidebarPanel: () => { isOpen: boolean };
  preSearchList?: string[];
  setItemToPreSearchList?: () => void;
  handleExportClick: () => void;
  exportButtonDisabled?: boolean;
  isExporting?: boolean;
}

export const DzPageHeader: React.FC<Props> = ({
  activeView,
  handleViewChange,
  views,
  onAddButtonClick,
  searchValue = '',
  onSearch,
  favorites = [],
  handleAddFavorite,
  handleRemoveFavorite,
  buttonName,
  useSelectSidebarPanel,
  preSearchList,
  setItemToPreSearchList,
  handleExportClick,
  exportButtonDisabled = false,
  isExporting = false,
}) => {
  const onViewChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      handleViewChange(event.target.value);
    },
    [handleViewChange],
  );

  const classes = useStyles();
  const theme = useTheme() as CustomTheme;
  const isBelowVeryLarge = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.xxl),
  );
  const isFromLg = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.lgBigger),
  );

  const isIntermediate = useMediaQuery(
    theme.breakpoints.between(
      theme.breakpoints.values.lgBigger,
      theme.breakpoints.values.xxl,
    ),
  );

  const { isOpen: isSidebarOpen } = useSelectSidebarPanel();

  const { user } = useUserConfig();

  const getFormControlRootClass = () => {
    if (!isBelowVeryLarge) {
      return undefined;
    }

    if (isSidebarOpen) {
      return isFromLg
        ? classes.openSidebarFormControlRoot
        : classes.openSidebarSmallFormControlRoot;
    }

    return undefined;
  };

  const getButtonRootClass = () => {
    if (!isBelowVeryLarge) {
      return classes.button;
    }

    if (isSidebarOpen) {
      return isFromLg
        ? classes.openSidebarButtonRoot
        : classes.openSidebarSmallButtonRoot;
    }

    return classes.button;
  };

  const getInputRootClass = () => {
    if (!isBelowVeryLarge) {
      return undefined;
    }

    if (isSidebarOpen) {
      return isFromLg
        ? classes.openSidebarInputBaseRoot
        : classes.openSidebarSmallInputRoot;
    }

    return undefined;
  };

  const currentView = useMemo(() => {
    return views.find((view) => view.id === activeView) || views[0];
  }, [views, activeView]);

  const tabs = useMemo(() => {
    return favorites.reduce<
      {
        value: string;
        label: string;
        isActive: boolean;
      }[]
    >((acc, favorite) => {
      const view = views.find((view) => view.id === favorite);

      if (!view) {
        return acc;
      }

      return [
        ...acc,
        {
          value: view.id,
          label: view.text,
          isActive: activeView === view.id,
        },
      ];
    }, []);
  }, [activeView, views, favorites]);

  return (
    <>
      <DzViewTabs
        activeView={activeView}
        handleViewChange={handleViewChange}
        tabs={tabs}
      />
      <div
        className={csn(classes.controlsWrapper, [
          classes.openSidebarControlsWrapper,
          isSidebarOpen && isBelowVeryLarge,
        ])}
      >
        <DzSearchInput
          searchValue={searchValue}
          onTextChange={onSearch}
          placeholder={isIntermediate ? 'Filter' : 'Filter results'}
          muiClassesOverrides={
            getInputRootClass()
              ? {
                  root: getInputRootClass(),
                }
              : undefined
          }
          selectList={preSearchList}
          onBlur={setItemToPreSearchList}
        />

        {buttonName &&
          (!isFromLg ? (
            <Fab
              classes={{
                root: csn(classes.smallButton, classes.smallButtonLabel),
              }}
              variant="circular"
              color="primary"
              onClick={onAddButtonClick}
              title={`Create ${buttonName}`}
            >
              <AddIcon fontSize="small" />
            </Fab>
          ) : (
            <Button
              classes={{
                root: csn(getButtonRootClass(), classes.buttonLabel),
              }}
              variant="contained"
              color="primary"
              onClick={onAddButtonClick}
            >
              <AddIcon fontSize="small" />
              {buttonName}
            </Button>
          ))}
        <FormControl
          className={classes.formControl}
          classes={{
            root: getFormControlRootClass(),
          }}
        >
          <Select
            MenuProps={{
              classes: {
                paper: classes.headerSelect,
              },
            }}
            variant="standard"
            classes={{
              select: classes.selectRoot,
              icon: classes.selectIcon,
            }}
            className={
              isSidebarOpen && isBelowVeryLarge
                ? classes.isSidebarOpenHeaderSelect
                : classes.headerSelect
            }
            value={activeView}
            label={<span>Views</span>}
            onChange={onViewChange}
            displayEmpty={true}
            renderValue={() => (
              <div className={classes.viewFilter}>
                <FilterListIcon
                  classes={{
                    root: classes.svgIconRoot,
                  }}
                />
                <span className={classes.filterText}>
                  {currentView.text}
                </span>
              </div>
            )}
            disableUnderline={true}
          >
            {views.map((view) => {
              const isFavorite = favorites.includes(view.id);

              return (
                <MenuItem
                  key={view.id}
                  value={view.id}
                  classes={{
                    root: classes.menuItem,
                  }}
                >
                  {view.text}
                  <IconButton
                    className={csn([classes.activeStar, isFavorite])}
                    onClick={(event) => {
                      event.stopPropagation();

                      isFavorite
                        ? handleRemoveFavorite(view.id)
                        : handleAddFavorite(view.id);
                    }}
                  >
                    {isFavorite ? <StarFilled /> : <StarBorder />}
                  </IconButton>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {(auth.getIsAdmin() || user['export_data']) && (
          <Tooltip title={isExporting ? 'Exporting...' : 'Export results'}>
            <IconButton
              className={classes.exportButton}
              color="inherit"
              onClick={handleExportClick}
              disabled={exportButtonDisabled}
            >
              {isExporting ? (
                <CircularProgress size="21px" />
              ) : (
                <FileDownloadOutlinedIcon fontSize="medium" />
              )}
            </IconButton>
          </Tooltip>
        )}
      </div>
    </>
  );
};
