import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';

import { DzAsyncDispatch, DzDialog } from 'shared-ui';
import { actions } from 'core/redux';
import { useFormValidation } from './usePartnerDomainFormValidation';
import { useAddPartnerDomain } from './useAddPartnerDomain';
import { useShowError } from './useShowAddDomainError';

export function DzUsersAddPartnerDomainDialog(props: Props) {
  const dispatch = useDispatch<DzAsyncDispatch>();
  const { addPartnerDomain, loading } = useAddPartnerDomain();

  const { onClose } = props;
  const showError = useShowError();
  const formik = useFormik({
    initialValues: { domain: '' },
    validate: useFormValidation(),
    onSubmit: async (values, { resetForm }) => {
      try {
        await addPartnerDomain(values.domain);
        dispatch(
          actions.updateSnackbar({
            text: 'Domain added',
            type: 'success',
          }),
        );
        resetForm();
        onClose();
      } catch (error) {
        showError(error);
      }
    },
  });

  const { resetForm } = formik;
  const closeAndReset = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  const isAddButtonDisabled = !formik.isValid || !formik.dirty || loading;

  return (
    <>
      <DzDialog
        caption="Add domin"
        isOpen={props.open}
        okButtonText="ADD"
        onOk={() => {
          formik.submitForm();
        }}
        onClose={closeAndReset}
        okButtonDisabled={isAddButtonDisabled}
        isSubmittingLoader={loading}
        content={
          <Box display="flex" flexDirection="column">
            <Typography variant="h6">
              Add a new allowed domain for sign up
            </Typography>
            <br />
            <form
              id="add-partner-domain-form"
              onSubmit={formik.handleSubmit}
            >
              <TextField
                {...formik.getFieldProps('domain')}
                placeholder="Enter domain name"
                fullWidth
                error={formik.touched.domain && !!formik.errors.domain}
                helperText={formik.touched.domain && formik.errors.domain}
              />
            </form>
          </Box>
        }
      />
    </>
  );
}

interface Props {
  open: boolean;
  onClose: () => void;
}
