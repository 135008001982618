import { useRef, useCallback, useState } from 'react';
import { SHOW_LESS, SHOW_MORE, DUMMY_ID } from 'shared/constants';

interface Output {
  setRenderedRowIds: (ids: string[]) => void;
  hoverRow: (id: string) => void;
  unhoverRow: () => void;
  lightSelectedRowId: string | undefined;
  lightSelectRow: (id: string | undefined) => void;
  lightUnselectRow: () => void;
  handleKeyUp: () => void;
  handleKeyDown: () => void;
  hoveredRowId: string | undefined;
  clearAll: () => void;
  lightSelectLastItem: () => void;
  hoveredRowIdRef: React.MutableRefObject<string | undefined>;
}

const checkIsDummyId = (id: string | undefined) => {
  if (!id) {
    return false;
  }

  return id.includes(DUMMY_ID);
};

export const useListTraversalHotkeys = (): Output => {
  const hoveredRowIdRef = useRef<string | undefined>(undefined);

  const [lightSelectedRowId, setLightSelectedRowId] = useState<
    string | undefined
  >(undefined);

  const lightUnselectRow = useCallback(() => {
    setLightSelectedRowId(undefined);
  }, [setLightSelectedRowId]);

  const renderedRowIds = useRef<string[]>([]);

  const setRenderedRowIds = useCallback(
    (ids: string[]) => {
      renderedRowIds.current = ids;
    },
    [renderedRowIds],
  );

  const lightSelectRow = useCallback(
    (id: string | undefined) => {
      if (!id) {
        return;
      }

      setLightSelectedRowId(id);
      hoveredRowIdRef.current = undefined;
    },
    [setLightSelectedRowId],
  );

  const lightSelectLastItem = useCallback(() => {
    if (!renderedRowIds.current.length) {
      return;
    }

    const lastItemId =
      renderedRowIds.current[renderedRowIds.current.length - 1];

    if (lastItemId.includes(SHOW_MORE) || lastItemId.includes(SHOW_LESS)) {
      const secondLastItemId =
        renderedRowIds.current[renderedRowIds.current.length - 2];

      if (
        secondLastItemId.includes(SHOW_MORE) ||
        secondLastItemId.includes(SHOW_LESS)
      ) {
        const thirdLastItemId =
          renderedRowIds.current[renderedRowIds.current.length - 3];

        lightSelectRow(thirdLastItemId);
      } else {
        lightSelectRow(secondLastItemId);
      }

      return;
    }

    lightSelectRow(lastItemId);
  }, [lightSelectRow]);

  const handleKeyDown = useCallback(() => {
    if (!renderedRowIds.current.length) {
      return;
    }

    const firstRowId = renderedRowIds.current[0];

    if (
      !lightSelectedRowId &&
      !hoveredRowIdRef.current &&
      !checkIsDummyId(firstRowId)
    ) {
      lightSelectRow(firstRowId);

      return;
    }

    const idToCompare =
      hoveredRowIdRef.current && !lightSelectedRowId
        ? hoveredRowIdRef.current
        : lightSelectedRowId;

    const currentRow = renderedRowIds.current.findIndex((currentRowId) => {
      return currentRowId === idToCompare;
    });

    const nextRowId = renderedRowIds.current[currentRow + 1];

    if (checkIsDummyId(nextRowId)) {
      return;
    }

    lightSelectRow(nextRowId);
  }, [lightSelectRow, lightSelectedRowId]);

  const hoverRow = useCallback(
    (id: string) => {
      hoveredRowIdRef.current = id;

      lightUnselectRow();
    },
    [lightUnselectRow],
  );

  const unhoverRow = useCallback(() => {
    hoveredRowIdRef.current = undefined;

    lightUnselectRow();
  }, [lightUnselectRow]);

  const handleKeyUp = useCallback(() => {
    if (!renderedRowIds.current.length) {
      return;
    }

    const lastId =
      renderedRowIds.current[renderedRowIds.current.length - 1];

    if (
      !lightSelectedRowId &&
      !hoveredRowIdRef.current &&
      !checkIsDummyId(lastId)
    ) {
      lightSelectRow(lastId);

      return;
    }

    const idToCompare =
      hoveredRowIdRef.current && !lightSelectedRowId
        ? hoveredRowIdRef.current
        : lightSelectedRowId;

    const currentRowId = renderedRowIds.current.findIndex(
      (currentRowId) => {
        return currentRowId === idToCompare;
      },
    );

    const prevRowId = renderedRowIds.current[currentRowId - 1];

    if (checkIsDummyId(renderedRowIds.current[currentRowId - 1])) {
      return;
    }

    lightSelectRow(prevRowId);
  }, [lightSelectedRowId, lightSelectRow]);

  const clearAll = () => {
    lightUnselectRow();
    unhoverRow();
    setRenderedRowIds([]);
  };

  return {
    lightSelectedRowId,
    lightSelectRow,
    lightUnselectRow,
    setRenderedRowIds,
    handleKeyDown,
    handleKeyUp,
    hoverRow,
    unhoverRow,
    hoveredRowId: hoveredRowIdRef.current,
    hoveredRowIdRef,
    clearAll,
    lightSelectLastItem,
  };
};
