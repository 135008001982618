import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Autocomplete, Box, FormControl, FormHelperText, TextField, Typography, } from '@mui/material';
import { DzDateTimePicker, ISO_DATE_FORMAT, ISO_DATE_MASK, day1PredefinedCppLengths, } from '../../..';
import { useCallback, useMemo, useState, } from 'react';
import { DzChip } from '../../shared/dz-chip';
import { useStyles } from './dz-submit-day-one-dialog.styles';
import { coerceToDate, format, isValid } from '@one-vision/date-utils';
import { MAX_DATE, TOMORROW_DATE, } from '../dz-cpp-exp-dialog/dz-cpp-exp-dialog.view';
import { csn } from '@one-vision/utils';
const DzCppForm = ({ values, handleChipChange, handleCustomExpDateChange, handleMembershipChange, handleBlur, errors, touched, offeredMemberships, }) => {
    var _a;
    const classes = useStyles();
    const [selectedChip, setSelectedChip] = useState(() => {
        var _a;
        return ((_a = day1PredefinedCppLengths.find((el) => el.value === values.cppLength)) === null || _a === void 0 ? void 0 : _a.value) || null;
    });
    const onChipChange = useCallback((item) => {
        setSelectedChip(item.value);
        handleChipChange(item.value);
    }, [handleChipChange]);
    const cppServiceLevelList = useMemo(() => {
        return offeredMemberships.map((membership) => `CPP ${membership.productName}`);
    }, [offeredMemberships]);
    const onDateChange = useCallback((value, inputValue) => {
        const isPartiallyEntered = inputValue && inputValue.length !== ISO_DATE_FORMAT.length;
        if (isPartiallyEntered || !isValid(value) || !value) {
            handleCustomExpDateChange(value !== null && value !== void 0 ? value : '');
            return;
        }
        let checkedDate = value;
        if (value && value < TOMORROW_DATE) {
            checkedDate = TOMORROW_DATE;
        }
        if (value && value > MAX_DATE) {
            checkedDate = MAX_DATE;
        }
        handleCustomExpDateChange(checkedDate
            ? format(coerceToDate(checkedDate), ISO_DATE_FORMAT)
            : null);
    }, [handleCustomExpDateChange]);
    return (_jsxs("div", Object.assign({ className: csn(classes.containerBorder, classes.containerBorderLeft) }, { children: [_jsxs(FormControl, Object.assign({ fullWidth: true, error: !!(errors === null || errors === void 0 ? void 0 : errors.clientActivatedMembership) }, { children: [_jsx(Autocomplete, { id: "clientActivatedMembership", value: values['clientActivatedMembership'], autoHighlight: true, openOnFocus: true, disableClearable: true, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: 'CPP Type', margin: "none", error: !!(errors === null || errors === void 0 ? void 0 : errors.clientActivatedMembership) }))), options: cppServiceLevelList, isOptionEqualToValue: (option) => option === values['clientActivatedMembership'], onChange: (_, newValue) => {
                            handleMembershipChange(newValue);
                        }, onBlur: handleBlur }), !!(errors === null || errors === void 0 ? void 0 : errors.clientActivatedMembership) && (_jsx(FormHelperText, { children: errors === null || errors === void 0 ? void 0 : errors.clientActivatedMembership }))] })), _jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: "Length" })), _jsx(Box, Object.assign({ className: classes.chipContainer }, { children: day1PredefinedCppLengths.map((el) => (_jsx(DzChip, { label: el.text, checked: selectedChip === el.value, onClick: () => onChipChange(el), className: classes.cppChip }, el.value))) })), _jsx(DzDateTimePicker, { disablePast: true, value: (_a = values.customCppExpDate) !== null && _a !== void 0 ? _a : null, onChange: onDateChange, disabled: selectedChip !== null, inputFormat: ISO_DATE_FORMAT, mask: ISO_DATE_MASK, name: "address.customCppExpDate", onBlur: handleBlur, label: "CPP Expiration Date", error: (touched === null || touched === void 0 ? void 0 : touched.customCppExpDate) ? errors === null || errors === void 0 ? void 0 : errors.customCppExpDate : '', maxDate: MAX_DATE.toISOString(), minDate: TOMORROW_DATE.toISOString() })] })));
};
export default DzCppForm;
