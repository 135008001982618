import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        root: {},
        zendeskIcon: {
            width: theme.px2rem(13),
            height: theme.px2rem(13),
            fill: 'black',
        },
    };
}, { name: 'dz-projects-page-side-panel-header', index: 0 });
