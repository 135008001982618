import { useState, useCallback } from 'react';
import { LocalStorageManager } from 'shared/utils/storageManager';

const LIST_LENGTH = 5;

const trimAndLowerCase = (text: string) =>
  text?.trim()?.toLowerCase() || text;

export const usePreSearchList: (
  searchText: string,
  localStorageName: string,
) => [string[], () => void] = (searchText, localStorageName) => {
  const trimAndLowerCaseSearch = trimAndLowerCase(searchText);

  const [preSearchList, setPreSearchList] = useState<string[]>(
    LocalStorageManager.getItem(localStorageName)
      ? JSON.parse(LocalStorageManager.getItem(localStorageName) || '')
      : [],
  );

  const addToPreSearchList = useCallback(() => {
    if (
      trimAndLowerCaseSearch &&
      !preSearchList
        .map((text) => trimAndLowerCase(text))
        .includes(trimAndLowerCaseSearch)
    ) {
      const list = [...preSearchList];
      list.unshift(searchText);
      const slicedList = list.slice(0, LIST_LENGTH);
      setPreSearchList(slicedList);
      LocalStorageManager.setItem(
        localStorageName,
        JSON.stringify(slicedList),
      );
    }
  }, [
    searchText,
    preSearchList,
    localStorageName,
    trimAndLowerCaseSearch,
  ]);

  return [preSearchList, addToPreSearchList];
};
