import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      dialog: {
        borderRadius: 8,
      },
      inputContainer: {
        padding: `${theme.px2rem(10)} ${theme.px2rem(20)} 0 ${theme.px2rem(
          20,
        )}`,
        '& > div': {
          display: 'block',
        },
      },
      footerContainer: {
        padding: `0 ${theme.px2rem(20)}`,
      },
      inputRoot: {
        width: '100%',
        '& .MuiInput-root': {
          padding: `${theme.px2rem(10)} 0`,
        },
        '& .MuiInput-root::after': {
          border: 'none',
        },
        '& .MuiInput-root::before': {
          border: 'none',
        },
        '& .MuiInput-root:hover::before': {
          border: 'none',
        },
        '& > div': {
          width: '100%',
        },
        '& fieldset': {
          border: 'none',
        },
      },
      input: {
        width: '100%',
        border: 'none',
        caretColor: theme.black.black35,
      },
      resultList: {
        maxHeight: theme.px2rem(450),
        overflow: 'auto',
        position: 'relative',
      },
      footer: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      resultNumContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: `${theme.px2rem(12)} 0`,
        color: theme.black.black50,
        fontWeight: 500,
        fontSize: theme.px2rem(12),
      },
      keysNotice: {
        display: 'flex',
        padding: `${theme.px2rem(12)} 0`,
        color: theme.black.black50,
        fontWeight: 500,
        fontSize: theme.px2rem(12),
      },
      arrowContainer: {
        display: 'flex',
        padding: `0 ${theme.px2rem(5)}`,
      },
      arrowUpward: {
        display: 'flex',
        width: theme.px2rem(21),
        height: theme.px2rem(21),

        '& svg': {
          color: theme.palette.custom.OV.sys.onSurface,
        },
      },
      arrowDownward: {
        display: 'flex',
        width: theme.px2rem(21),
        height: theme.px2rem(21),
        transform: 'rotate(180deg)',
        marginLeft: theme.px2rem(5),
        '& svg': {
          color: theme.palette.custom.OV.sys.onSurface,
        },
      },
      noticeText: {
        lineHeight: theme.px2rem(21),
      },
      hidden: {
        display: 'none',
      },
      paperOverride: {
        minWidth: theme.px2rem(650),
      },
    };
  },
  { name: 'dz-global-search-dialog' },
);
