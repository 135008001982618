import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        root: {},
        infoContainer: {
            display: 'flex',
            padding: theme.px2rem(15),
            flexDirection: 'column',
        },
        infoContainerZen: {
            padding: `${theme.px2rem(18)} ${theme.px2rem(15)}`,
        },
        contentTitle: {
            fontWeight: 500,
            fontSize: theme.px2rem(16),
            paddingBottom: theme.px2rem(10),
            color: theme.black.black65,
        },
        contentBlock: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: theme.px2rem(10),
            '&:last-of-type': {
                marginBottom: 0,
            },
        },
        hideTooWide: {
            maxWidth: theme.px2rem(250),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        hideTooWideZen: {
            maxWidth: theme.px2rem(200),
        },
        infoData: {
            fontWeight: 400,
            fontSize: theme.px2rem(12),
            alignSelf: 'center',
            alignItems: 'center',
            margin: 0,
            marginBottom: theme.px2rem(5),
        },
        infoIcon: {
            color: theme.dz.colors.proactive,
            width: theme.px2rem(17),
            height: theme.px2rem(14),
            alignSelf: 'center',
            marginRight: theme.px2rem(10),
        },
        infoIconZen: {
            marginRight: theme.px2rem(7),
        },
        label: {
            color: theme.palette.custom.OV.sys.onSurfaceVariant,
            fontSize: theme.px2rem(12),
            marginLeft: theme.px2rem(5),
        },
        notesWrapper: {
            overflow: 'hidden',
            transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        notesBlock: {
            whiteSpace: 'pre-wrap',
            overflowWrap: 'anywhere',
            fontSize: theme.px2rem(12),
        },
        gradientBlur: {
            background: 'linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff)',
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0) 0px, rgb(255, 255, 255))',
            height: '20%',
            width: '100%',
            position: 'absolute',
            bottom: 0,
        },
        clientLabelsIcon: {
            marginTop: theme.px2rem(3.5),
        },
    };
}, { name: 'dz-client-info-content', index: 0 });
