import { useEffect, useState } from 'react';

const getSizeObject = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSizeObject);

  useEffect(() => {
    const resizeHandler = () => setWindowSize(getSizeObject);

    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);
  return windowSize;
}
