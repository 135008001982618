import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { logError } from '@one-vision/utils';

import { DzBrand } from 'shared-ui';
import { API } from '../../api';

type State = DzBrand[];

export const fetchBrands = createAsyncThunk<DzBrand[]>(
  'fetchBrands/get',
  async () => {
    try {
      const response = await API.getBrands();
      return response.data;
    } catch (error) {
      logError(error);
      return [];
    }
  },
);

export const brandsSlice = createSlice({
  name: 'brands',
  initialState: [] as State,
  reducers: {
    updateBrands: (state, action: PayloadAction<State>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchBrands.fulfilled,
      (_, action: ReturnType<typeof fetchBrands.fulfilled>) => {
        return action.payload;
      },
    );
  },
});
