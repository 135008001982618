import { ActionTypes, } from './types';
export const setProps = (modalID, props) => ({
    type: ActionTypes.setProps,
    payload: { modalID, props },
});
export const clearProps = (modalID) => ({
    type: ActionTypes.clearProps,
    payload: {
        modalID,
    },
});
const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.setProps:
            const previousModalProps = state[action.payload.modalID] || {};
            return Object.assign(Object.assign({}, state), { [action.payload.modalID]: Object.assign(Object.assign({}, previousModalProps), action.payload.props) });
        case ActionTypes.clearProps:
            const newPropsDictionary = Object.assign({}, state);
            delete newPropsDictionary[action.payload.modalID];
            return newPropsDictionary;
        default:
            return state;
    }
};
export default reducer;
