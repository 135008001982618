import { makeStyles } from '@mui/styles';
export const useGlobalStyles = makeStyles((theme) => {
    return {
        gcFieldMargin: {
            margin: `0 0 ${theme.spacing(2)}`,
        },
        gcSubmissionCircularProgress: {
            position: 'absolute',
            width: '40px',
            height: '40px',
            left: 'calc(50% - 20px)',
            top: 'calc(50% - 20px)',
        },
        gcContentRootSubmitting: {
            opacity: '0.35',
        },
        gcEllipsis: {
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
        },
    };
}, { name: 'dz-global-classes' });
