import { logError } from '@one-vision/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DzProject,
  DzAddressPrefixed,
  DzClient,
  DzAddressShortList,
  FIELD_PRESETS,
  ErrorWithMessage,
} from 'shared-ui';
import { API } from 'core/api';
import { addressIsUpdating } from './actions';

export const updateAddress = createAsyncThunk<
  DzAddressPrefixed[],
  {
    ovaid: string;
    changes: Partial<DzAddressPrefixed>;
  }
>('address/patch', async (arg, thunkAPI) => {
  const { ovaid, changes } = arg;
  try {
    thunkAPI.dispatch(addressIsUpdating(changes));
    const response = await API.updateAddress(ovaid, changes);
    return response.data;
  } catch (error) {
    const err = error as ErrorWithMessage;
    thunkAPI.rejectWithValue({ ovaid });
    logError(error);
    if (err?.response?.data?.message) {
      throw err.response.data.message;
    }
    return [];
  }
});

const makeFetchAddress = <T extends { ovaid: string }>(
  preset?: FIELD_PRESETS,
) =>
  createAsyncThunk<
    T[],
    {
      ovaid?: T['ovaid'];
      'client-id'?: DzClient['ovcid'];
    }
  >('fetchAddress/get', async (arg) => {
    try {
      const { ovaid, 'client-id': clientId } = arg;
      const newParams: {
        ovaid?: T['ovaid'];
        'client-id'?: DzClient['ovcid'];
        primaryClientsOnly?: boolean;
      } = {};
      if (ovaid) {
        newParams['ovaid'] = ovaid;
      }
      if (clientId) {
        newParams['client-id'] = clientId;
      }
      const response = await API.getAddresses<T>(
        preset ? { ...newParams, ['field-preset']: preset } : newParams,
      );

      return response.data;
    } catch (error) {
      logError(error);
      return [];
    }
  });

export const fetchAddress = makeFetchAddress<DzAddressPrefixed>();
export const fetchAddressShortList = makeFetchAddress<DzAddressShortList>(
  FIELD_PRESETS.ADDRESS_SHORT_LIST,
);

export const updateProject = createAsyncThunk<
  {
    projectId: string;
  },
  {
    projectId: string;
    changes: Partial<DzProject>;
  }
>('project/patch', async (arg, thunkAPI) => {
  const { projectId, changes } = arg;
  try {
    await API.updateProject(projectId, changes);
  } catch (error) {
    thunkAPI.rejectWithValue({ projectId });
    logError(error);
  }
  return {
    projectId,
  };
});

export const fetchUpdateTechDialogData = createAsyncThunk(
  'addressData/get',
  async (addressId: string) => {
    try {
      const response = await API.getAddresses({
        ovaid: addressId,
      });
      return response.data;
    } catch (error) {
      // thunkAPI.rejectWithValue
      logError(error);
      return [];
    }
  },
);
