import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Divider, Typography } from '@mui/material';
import { csn } from '@one-vision/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { basicSystemInformation, subSystemList, tv, remoteSystemManagementFields, controlSystem, } from '../../../technology/technology-lists';
import { getSubSystemsContent } from '../../../technology/technology.utils';
import { DzCircularProgress, useApiClient } from '../../shared';
import { useStyles as useOrgDialogStyles } from '../dz-address-panel.styles';
import { useModalManager } from '../../shared';
import { ModalIDs } from '../../../const';
import { useStyles } from './dz-site-documentation.styles';
import DzRichTextEditor from '../../shared/dz-rich-text-editor';
import { replaceNewLineWithParagraph } from '../../../utils/replaceNewLineWithParagraph';
import { S3Client } from '../../../utils';
import { remoteSystemManagement as remoteSystemManagementList } from '../../../technology';
const RSM_URL = 'RsmUrl';
export var RsmUrlField;
(function (RsmUrlField) {
    RsmUrlField["siteRsmUrl"] = "siteRsmUrl";
    RsmUrlField["siteSecondaryRsmUrl"] = "siteSecondaryRsmUrl";
    RsmUrlField["siteTertiaryRsmUrl"] = "siteTertiaryRsmUrl";
})(RsmUrlField || (RsmUrlField = {}));
//Temp solution: we will have the list of rsm instead of a couple of fields
export var RsmField;
(function (RsmField) {
    RsmField["siteRemoteSystemsManagement"] = "siteRemoteSystemsManagement";
    RsmField["siteSecondaryRemoteSystemsManagement"] = "siteSecondaryRemoteSystemsManagement";
    RsmField["siteTertiaryRemoteSystemsManagement"] = "siteTertiaryRemoteSystemsManagement";
})(RsmField || (RsmField = {}));
const getRsmType = (field) => {
    if (field.includes('siteRsmUrl')) {
        return RsmField.siteRemoteSystemsManagement;
    }
    if (field.includes('Secondary')) {
        return RsmField.siteSecondaryRemoteSystemsManagement;
    }
    if (field.includes('Tertiary')) {
        return RsmField.siteTertiaryRemoteSystemsManagement;
    }
    return '';
};
const sortSubSystems = (subSystems) => subSystems.sort((el) => el === remoteSystemManagementFields[0].subHeader ||
    el === tv[0].subHeader ||
    el === controlSystem[0].subHeader
    ? -1
    : 1);
export const DzDocumentationView = ({ address, actions, dispatch, thunks, zendesk, }) => {
    var _a;
    const classes = Object.assign(Object.assign({}, useOrgDialogStyles()), useStyles());
    const [subSystems, setSubSystems] = useState([]);
    useEffect(() => {
        if (address) {
            const withValues = subSystemList.reduce((accumulator, currentValue) => {
                const hasValue = currentValue.addresses.find((item) => address[item]);
                if (hasValue)
                    accumulator.push(currentValue.label);
                return accumulator;
            }, []);
            setSubSystems(sortSubSystems(withValues));
        }
    }, [address]);
    const { openModal } = useModalManager();
    const openUpdateTechHandler = useCallback(async () => {
        await openModal(ModalIDs.updateTech, {
            ovaid: address === null || address === void 0 ? void 0 : address.ovaid,
            actions,
            dispatch,
            thunks,
            zendesk,
        });
    }, [address, dispatch, thunks]);
    const openInNewTab = (url) => {
        window.open(url, '_blank');
    };
    const getField = ({ caption, addressField, items, subHeader, }) => {
        if (!address) {
            return [null];
        }
        const isEmptyRsmField = !address[addressField] &&
            (addressField === RsmUrlField.siteRsmUrl ||
                addressField === RsmUrlField.siteSecondaryRsmUrl ||
                addressField === RsmUrlField.siteTertiaryRsmUrl ||
                RsmField.siteRemoteSystemsManagement ||
                RsmField.siteSecondaryRemoteSystemsManagement ||
                RsmField.siteTertiaryRemoteSystemsManagement);
        if (isEmptyRsmField) {
            return [null];
        }
        const getName = (list, field) => { var _a; return ((_a = list.find((el) => el.key === address[field])) === null || _a === void 0 ? void 0 : _a.name) || ''; };
        return [
            subHeader ? (_jsx(Typography, Object.assign({ className: classes.subSystemsHeader }, { children: subHeader }), `subheader-${subHeader}`)) : null,
            _jsxs(Box, Object.assign({ className: csn(classes.fieldMargin, classes.infoBlock, classes.infoLine) }, { children: [_jsx(Typography, Object.assign({ className: classes.infoCaption }, { children: caption })), _jsx(Box, Object.assign({ className: csn(classes.infoValue, [
                            classes.infoValueZen,
                            zendesk,
                        ]) }, { children: addressField.includes(RSM_URL) ? (_jsx(Button, Object.assign({ sx: { padding: 0 }, onClick: () => openInNewTab(address[addressField]) }, { children: `Open in ${getName(remoteSystemManagementList, getRsmType(addressField))}` }))) : (getName(items, addressField)) }))] }), addressField),
        ];
    };
    const apiClient = useApiClient();
    const s3Client = useMemo(() => {
        return new S3Client({ apiClient });
    }, [apiClient]);
    return (_jsx(_Fragment, { children: !address ? (_jsx("div", Object.assign({ className: classes.circularProgressWrapper }, { children: _jsx(DzCircularProgress, { timeout: 200, className: classes.circularProgress }) }))) : (_jsxs("div", Object.assign({ className: csn(classes.tabContent) }, { children: [_jsx("div", Object.assign({ className: csn(classes.tabContentHeader, [
                        classes.tabContentHeaderZen,
                        zendesk,
                    ]) }, { children: _jsx(Button, Object.assign({ color: "primary", onClick: openUpdateTechHandler }, { children: "Update" })) })), _jsx(Divider, { className: classes.headerDivider }), _jsx(Typography, Object.assign({ className: csn(classes.orgDataSectionTitle, [
                        classes.orgDataSectionTitleZen,
                        zendesk,
                    ]) }, { children: "Organization Notes" })), ((_a = address === null || address === void 0 ? void 0 : address.orgNotes) === null || _a === void 0 ? void 0 : _a.trim()) && (address === null || address === void 0 ? void 0 : address.orgNotes) !== '<p></p>' ? (_jsx(DzRichTextEditor, { content: replaceNewLineWithParagraph((address === null || address === void 0 ? void 0 : address.orgNotes) || ''), editable: false, withMenu: false, editorHookDependency: address, s3Client: s3Client })) : (_jsx(Typography, Object.assign({ className: csn(classes.notesBlock, classes.orgDataTitlePaddingLeft) }, { children: "-" }))), _jsx(Typography, Object.assign({ className: csn(classes.orgDataSectionTitle, [
                        classes.orgDataSectionTitleZen,
                        zendesk,
                    ]) }, { children: "System Infrastructure" })), basicSystemInformation.map((el) => getField(el)), !!subSystems.length && (_jsx(Typography, Object.assign({ className: csn(classes.orgDataSectionTitle, [
                        classes.orgDataSectionTitleZen,
                        zendesk,
                    ]) }, { children: "Sub Systems" }))), subSystems.map((el) => getSubSystemsContent(el, getField))] }))) }));
};
