import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        root: {},
        switchFormLabel: {
            justifyContent: 'space-between',
            marginLeft: 0,
            width: '94%',
        },
        zendeskSwitchFormLabel: {
            width: '97%',
        },
        divider: {
            marginBottom: `${theme.spacing(3)}`,
        },
        fieldWithInfo: {
            width: '94%',
        },
        zendeskFieldWithInfo: {
            width: '90%',
        },
        formRow: {
            alignItems: 'center',
            justifyContent: 'space-between',
            columnGap: `${theme.spacing(1)}`,
        },
        tooltip: {
            fontSize: theme.px2rem(14),
        },
        toggleLabel: {
            fontWeight: 500,
        },
    };
}, { name: 'dz-proposal-won-dialog' });
