import * as FullStory from '@fullstory/browser';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LocalStorageManager } from 'shared';
import {
  DzApiClientProvider,
  DzModalProvider,
  DzAuthProvider,
} from 'shared-ui';
import { API } from '../../core/api';
import { OvLogin, auth } from '@one-vision/login';
import { config } from '../../core/config';
import { DzStore, store } from '../../core/redux';
import { deepSliceStore } from '../../core/redux/local-storage.redux';
import { maxReduxPersistanceSize } from '../../shared/constants';
import { DzRoot } from '../dz-root';
import { globalStyles } from './dz-app.styles';
import { loginLogo } from 'assets';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

/**
 * This is just an example of how to connect to the RTE
 * for the developer that will work on the support of Real Time Updates
 * It will be uncommented and replaced with the actual implementation
 */
// function connectToRealTimeEngine() {
//   const socket = new WebSocket(`wss://${config.realTimeEngineApi}/v1`);
//   socket.addEventListener('open', () => {
//     socket.send(
//       JSON.stringify({
//         action: 'init',
//         key: config.apiKeys.partnerApiKey,
//         serviceName: 'Provision',
//         user: 'albert@wesker.umbrella',
//       }),
//     );
//     socket.addEventListener('message', (event) => {
//       console.log(JSON.parse(event.data));
//     });
//   });
// }

FullStory.init({
  orgId: 'V55R5',
  devMode: config.stage !== 'prod',
});

// setting email
const unSub = store.subscribe(() => {
  const owners = store.getState().owners;
  if (owners && auth.getUsername()) {
    const ownersData = Object.values(owners);
    if (!ownersData.length) {
      return;
    }
    const currentOwner = ownersData.find((ownerIter) => {
      return ownerIter.cognitoUsername === auth.getUsername();
    });
    if (currentOwner) {
      FullStory.identify(currentOwner.email, {
        displayName: currentOwner.name,
      });
      unSub();
    }
  }
});

// setup stripe
const stripePromise = loadStripe(config.stripePublicKey);

const DzAppContent: React.FC = () => {
  React.useEffect(() => {
    API.getApiKeys().then(({ data: { data } }) => {
      config.apiKeys.reportingApiKey = data.errorApiKey;
      config.apiKeys.partnerApiKey = data.partnerApiKey;
      // connectToRealTimeEngine();
    });
  }, []);

  React.useEffect(() => {
    const subscription$ = new Subject<undefined>();
    subscription$.pipe(debounceTime(500)).subscribe(() => {
      const state: DzStore = store.getState();
      const slice = deepSliceStore(state);
      const stringifiedSlice: string = JSON.stringify(slice);

      if (new Blob([stringifiedSlice]).size < maxReduxPersistanceSize) {
        LocalStorageManager.setReduxState(JSON.stringify(slice));
      }
    });
    const unsubscribe = store.subscribe(
      subscription$.next.bind(subscription$),
    );

    return unsubscribe;
  }, []);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <DzAuthProvider auth={auth}>
          <DzApiClientProvider apiClient={API}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Elements stripe={stripePromise}>
                <DzModalProvider>
                  {globalStyles}
                  <DzRoot />
                </DzModalProvider>
              </Elements>
            </LocalizationProvider>
          </DzApiClientProvider>
        </DzAuthProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default function DzAppWithLogin() {
  return <OvLogin app={DzAppContent} logo={loginLogo} withSignUp />;
}
