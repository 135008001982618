import React, { useCallback, useMemo, useRef, useState } from 'react';
import { logRender, csn } from '@one-vision/utils';
import ForumIcon from '@mui/icons-material/Forum';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SwitchIcon from '@mui/icons-material/SwitchCamera';
import DomainIcon from '@mui/icons-material/Domain';

import { DzTopBarClasses, useStyles } from './dz-top-bar.styles';
import { CustomTheme } from 'core/theme';
import { auth } from '@one-vision/login';
import { DzPartnerSelectionModal } from './dz-partner-selection-modal';
import { useDzSelector } from 'core/redux';
import { getAbbr, pickColor, DzPartner } from 'shared-ui';
import { withAdminRights } from 'shared/HOCs/withFallback';
import { DzGlobalSearchButton } from 'components/dz-global-search';
import { DzIframeHiddenContent } from 'components/shared';
import { useOwner } from 'shared/hooks';

const WithAdminRights = withAdminRights();

interface PopupItem {
  caption: string;
  action: () => void;
  icon: React.ElementType;
  disabled?: boolean;
}

const partnerNamePlaceHolder = 'ProVision';

const FrameDialogRaw: React.FC<{
  classes: DzTopBarClasses;
  title: string;
  url: string;
  isOpen: boolean;
  onClose: () => void;
}> = ({ classes, title, url, isOpen, onClose }) => {
  logRender('FrameDialog');

  const ref = useRef(null);

  return (
    <>
      <div ref={ref} className={classes.frameDialogAnchor} />
      <Popover
        open={Boolean(isOpen && ref.current)}
        anchorEl={ref.current}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.frameDialogHeader}>
          <Typography color="inherit" variant="h6">
            {title}
          </Typography>

          <IconButton color="inherit" onClick={onClose}>
            <CloseIcon color="inherit" />
          </IconButton>
        </div>
        <iframe
          title={title}
          className={classes.frameDialogFrame}
          src={url}
        ></iframe>
      </Popover>
    </>
  );
};

const FrameDialog = React.memo(FrameDialogRaw);

const SupportToolsRaw: React.FC<{
  classes: DzTopBarClasses;
  partner?: DzPartner;
}> = ({ classes, partner }) => {
  logRender('SupportTools');
  const [isOpen, setIsOpen] = useState(false);
  const supportToolsRef = useRef(null);

  const supportToolsButtonClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const supportToolsPopoverClose = useCallback(() => setIsOpen(false), []);

  const items: Array<PopupItem> = useMemo(() => {
    return [
      {
        caption: 'Zendesk',
        action: () => {
          window.open(partner?.zendeskUrl, '_blank');
          setIsOpen(false);
        },
        icon: ConfirmationNumberIcon,
      },
      {
        caption: 'Slack',
        action: () => {
          window.open(`https://${partner?.slackTeamLink}`, '_blank');
          setIsOpen(false);
        },
        icon: ForumIcon,
      },
      {
        caption: 'Metrics',
        action: () => {
          window.open('https://metrics.onevisionresources.com', '_blank');
          setIsOpen(false);
        },
        icon: AssessmentIcon,
      },
      {
        caption: 'Onboarding Resources',
        action: () => {
          window.open(partner?.docsendUrl, '_blank');
          setIsOpen(false);
        },
        icon: FolderSpecialIcon,
        disabled: !partner?.docsendUrl,
      },
    ];
  }, [setIsOpen, partner]);

  return (
    <>
      <Button
        className={classes.supportToolsButton}
        ref={supportToolsRef}
        onClick={supportToolsButtonClick}
      >
        Support Tools
        {isOpen ? (
          <ArrowDropUpIcon className={classes.supportToolsCarrotOffset} />
        ) : (
          <ArrowDropDownIcon
            className={classes.supportToolsCarrotOffset}
          />
        )}
      </Button>
      <Popover
        classes={{
          paper: csn(classes.popoverOffset, classes.supportToolsPopup),
        }}
        open={Boolean(isOpen && supportToolsRef.current)}
        anchorEl={supportToolsRef.current}
        onClose={supportToolsPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableEnforceFocus={true}
      >
        <List component="nav">
          {items
            .filter((item) => !item.disabled)
            .map((item) => (
              <ListItem
                button
                key={item.caption}
                onClick={item.action}
                className={classes.listButton}
                disabled={item.disabled}
              >
                <ListItemIcon className={classes.listItemIconRoot}>
                  <item.icon />
                </ListItemIcon>
                <ListItemText primary={item.caption} />
              </ListItem>
            ))}
        </List>
      </Popover>
    </>
  );
};

const SupportTools = React.memo(SupportToolsRaw);

const ProfileRaw: React.FC<{
  classes: DzTopBarClasses;
  partner?: DzPartner;
}> = ({ classes, partner }) => {
  logRender('Profile');
  const [isOpen, setIsOpen] = useState(false);
  const profileRef = useRef(null);
  const [frameDialog, setFrameDialog] = useState({
    isOpen: false,
    title: '',
    url: '',
  });

  const [isPartnerSelectionOpen, setIsPartnerSelectionOpen] =
    useState(false);

  const owner = useOwner();

  const profileButtonClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const profilePopoverClose = useCallback(() => setIsOpen(false), []);

  const handleCloseFrameDialog = useCallback(
    () =>
      setFrameDialog({
        ...frameDialog,
        isOpen: false,
      }),
    [frameDialog, setFrameDialog],
  );

  const handleClosePartnerSelection = useCallback(() => {
    setIsPartnerSelectionOpen(false);
  }, [setIsPartnerSelectionOpen]);

  const handleOpenPartnerSelection = useCallback(() => {
    profilePopoverClose();
    setIsPartnerSelectionOpen(true);
  }, [setIsPartnerSelectionOpen, profilePopoverClose]);

  const partnerName = partner?.name || partnerNamePlaceHolder;

  const items: Array<PopupItem> = [
    {
      caption: 'Schedule a Meeting',
      action: useCallback(() => {
        setFrameDialog({
          isOpen: true,
          title: 'Schedule a Meeting',
          url:
            partner?.dosCalendly ||
            'https://calendly.com/onevision-partner-success',
        });
        setIsOpen(!isOpen);
      }, [isOpen, partner]),
      icon: EventAvailableIcon,
    },
    {
      caption: 'Workshops & Webinars',
      action: useCallback(() => {
        setFrameDialog({
          isOpen: true,
          title: 'Workshops & Webinars',
          url: 'https://tockify.com/partnercal/agenda',
        });
        setIsOpen(!isOpen);
      }, [isOpen]),
      icon: BookmarkBorderIcon,
    },
    {
      caption: 'Marketing Assets',
      action: useCallback(() => {
        window.open(partner?.notionBoardUrl, '_blank');
        setIsOpen(!isOpen);
      }, [isOpen, partner]),
      icon: DomainIcon,
      disabled: !partner?.notionBoardUrl,
    },
  ];

  const signOut = useSignOut();

  return (
    <>
      <Button
        className={classes.profileButton}
        ref={profileRef}
        onClick={profileButtonClick}
      >
        <Box
          className={classes.profileLogo}
          bgcolor={pickColor(partnerName)}
        >
          {getAbbr(partnerName)}
        </Box>

        <Typography className={classes.profileButtonCaption} variant="h6">
          {partnerName}
        </Typography>
        {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Button>

      <FrameDialog
        classes={classes}
        title={frameDialog.title}
        isOpen={frameDialog.isOpen}
        onClose={handleCloseFrameDialog}
        url={frameDialog.url}
      />

      <Popover
        classes={{
          paper: csn(classes.popoverOffset, classes.profilePopover),
        }}
        open={Boolean(isOpen && profileRef.current)}
        anchorEl={profileRef.current}
        onClose={profilePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {owner ? (
          <div className={classes.profilePopoverInfoblock}>
            <Typography>{owner.name}</Typography>
            <Typography color="primary">{owner.email}</Typography>
          </div>
        ) : null}
        <Divider />
        <List component="nav">
          <WithAdminRights>
            <ListItem
              button
              onClick={handleOpenPartnerSelection}
              className={classes.listButton}
            >
              <ListItemIcon className={classes.listItemIconRoot}>
                <SwitchIcon />
              </ListItemIcon>
              <ListItemText primary="Switch Partner" />
            </ListItem>
            <Divider />
          </WithAdminRights>

          {items.map((item) => (
            <ListItem
              button
              className={classes.listButton}
              key={item.caption}
              onClick={item.action}
              disabled={Boolean(item.disabled)}
            >
              <ListItemIcon className={classes.listItemIconRoot}>
                <item.icon />
              </ListItemIcon>
              <ListItemText primary={item.caption} />
            </ListItem>
          ))}
          <Divider />
          <ListItem
            button
            onClick={signOut}
            className={classes.listButton}
          >
            <ListItemIcon className={classes.listItemIconRoot}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </ListItem>
        </List>
      </Popover>
      <DzPartnerSelectionModal
        isOpen={isPartnerSelectionOpen}
        onClose={handleClosePartnerSelection}
      />
    </>
  );
};

const Profile = React.memo(ProfileRaw);

export const DzTopBarView: React.FC = () => {
  logRender(DzTopBarView);

  const classes = useStyles();

  const partners = useDzSelector((state) => state.settings.partners);
  const partnerId = auth.getPartnerId();
  const partner = useMemo(
    () =>
      partners.find((partnerIter) => partnerIter.partnerId === partnerId),
    [partners, partnerId],
  );

  const theme = useTheme() as CustomTheme;

  const isMedium = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.md),
  );

  return (
    <DzIframeHiddenContent>
      <div className={classes.root}>
        {isMedium && (
          <div className={classes.searchContainer}>
            <DzGlobalSearchButton />
          </div>
        )}
        <div
          className={csn(classes.rightGroup, [
            classes.rightGroupVisible,
            partner,
          ])}
        >
          <Divider
            className={classes.vertDivider}
            orientation="vertical"
            flexItem={true}
          />
          <SupportTools classes={classes} partner={partner} />
          <Divider
            className={classes.vertDivider}
            orientation="vertical"
            flexItem={true}
          />
          <Profile classes={classes} partner={partner} />
        </div>
      </div>
    </DzIframeHiddenContent>
  );
};

function useSignOut() {
  return useCallback(() => {
    auth.signOut();
  }, []);
}
