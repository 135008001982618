import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        position: 'fixed',
        zIndex: theme.zIndex.appBar,
        backgroundColor: '#3FCBCE',
        left: `${theme.dz.appBar.width}px`,
        right: 0,
        bottom: 0,
        border: `1px solid ${theme.palette.divider}`,
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
        boxSizing: 'border-box',
        padding: '23px 200px',
        color: theme.palette.custom.OV.sys.background,
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignItems: 'end',
      },
      button: {
        marginTop: theme.spacing(1),
        marginLeft: 'auto',
      },
    };
  },
  { name: 'dz-cookie-snackbar', index: 0 },
);

export type DzCookieSnackbarClasses = ReturnType<typeof useStyles>;
