/**
 * Usage:
 * buildQuery({
 *   search: 'hello',
 *   sort: 'createdAt',
 *   include: 'address',
 * }) === '?search=hello&sort=createdAt&include=address';
 *
 * buildQuery({
 *   search: 'world',
 *   page: {
 *     offset: 40,
 *     limit: 20,
 *   },
 * }) === '?search=world&page[offset]=40&page[limit]=20';
 *
 * buildQuery({
 *   include: ['client', 'address'],
 *   filter: {
 *     'address.parternId': 5319,
 *   },
 * }) === '?include=client,address&filter[address.parternId]=5319';
 *
 * buildQuery({
 *   search: null,
 *   include: undefined,
 *   filter: {
 *     'address.parternId': 5319,
 *   },
 * }) === '?search=&filter[address.parternId]=5319';
 */
function isObject(x) {
    return typeof x === 'object';
}
function isArray(x) {
    return Array.isArray(x);
}
const flatten = (path, obj, flatArr) => Object.keys(obj).reduce((fa, property) => {
    let value = obj[property];
    if (value === undefined) {
        return flatArr;
    }
    if (value === null) {
        value = '';
    }
    const encodedProperty = encodeURIComponent(property);
    const newPath = path ? `${path}[${encodedProperty}]` : encodedProperty;
    const theType = typeof value;
    if (theType === 'function') {
        value = '';
    }
    if (isArray(value)) {
        value = value.map(encodeURIComponent).join(',');
        fa.push(`${newPath}=${value}`);
        return fa;
    }
    if (isObject(value)) {
        return flatten(newPath, value, fa);
    }
    fa.push(`${newPath}=${encodeURIComponent(value)}`);
    return fa;
}, flatArr);
const flat = (obj) => flatten('', obj, []);
const stringify = (query) => {
    const queryString = flat(query).join('&');
    return queryString ? `?${queryString}` : '';
};
export const buildQuery = (query) => {
    if (query == null || typeof query !== 'object') {
        return '';
    }
    return stringify(query);
};
