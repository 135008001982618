class S3Client {
    constructor({ apiClient }) {
        /**
         * uploadFile
         */
        this.uploadFile = async (file) => {
            const { type } = file;
            const { data: { data }, } = await this.apiClient.generatePresignedUrl({
                contentType: type,
            });
            await this.apiClient.axios.put(data.uploadURL, file, {
                headers: {
                    'Content-Type': type,
                },
            });
            return data.uploadURL.split('?')[0];
        };
        this.apiClient = apiClient;
    }
}
export default S3Client;
