import { Box, Skeleton } from '@mui/material';
import React from 'react';

export const DzUsersSidebarHeaderPlaceholder: React.FC = () => {
  return (
    <Box display="flex" alignItems="center" columnGap={1}>
      <Skeleton
        animation="wave"
        variant="circular"
        width={28}
        height={28}
      />
      <Skeleton animation="wave" variant="text" width={150} height={28} />
      <Skeleton
        animation="wave"
        variant="circular"
        sx={{
          marginLeft: 'auto',
        }}
        width={28}
        height={28}
      />
    </Box>
  );
};
