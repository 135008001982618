import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { DzDialog, DzNotificationSnackbar, useGlobalStyles, } from '../../../shared';
import { DzDefaultTextField, schemaFields, } from '../../../shared/dz-forms';
const validationSchema = yup.object({
    email: schemaFields.email,
    firstName: schemaFields.firstName,
});
export const DzSendMembershipEmailView = ({ isOpen, address, onClose, thunks, dispatch, zendesk, }) => {
    const globalClasses = useGlobalStyles();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const initialValues = useMemo(() => ({
        ovaid: (address === null || address === void 0 ? void 0 : address.ovaid) || '',
        firstName: '',
        email: '',
    }), [address]);
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (data) => {
            setIsSubmitting(true);
            dispatch(thunks.sendMembershipEmail({
                data,
            }))
                .unwrap()
                .then(() => {
                setNotificationText(`Standard Membership email sent to ${data.email}.`);
                setIsSubmitting(false);
                formik.resetForm();
                onClose();
            });
        },
    });
    useEffect(() => {
        formik.resetForm();
        formik.setValues(initialValues);
    }, [address]);
    const content = useMemo(() => {
        return (_jsxs(_Fragment, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", align: "center", className: globalClasses.gcFieldMargin }, { children: "This will send the client a standard membership education email." })), _jsx(DzDefaultTextField, { formik: formik, name: "firstName", label: "Client First Name" }), _jsx(DzDefaultTextField, { formik: formik, name: "email", label: "Client Email Address" })] }));
    }, [isSubmitting, formik]);
    const [notificationText, setNotificationText] = useState('');
    const handleCloseNotification = useCallback(() => setNotificationText(''), [setNotificationText]);
    return (_jsxs(_Fragment, { children: [_jsx(DzDialog, { isOpen: isOpen, caption: "Send Membership Email", okButtonText: "Send", okButtonDisabled: isSubmitting, isSubmittingLoader: isSubmitting, content: content, onClose: onClose, onOk: formik.handleSubmit, zendesk: zendesk }), _jsx(DzNotificationSnackbar, { onClose: handleCloseNotification, text: notificationText })] }));
};
