import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardContent, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './dz-selected-card.styles';
const DzSelectedCard = ({ title, onClose, Icon, showCloseButton = true, }) => {
    const classes = useStyles();
    return (_jsx(Card, Object.assign({ elevation: 3, className: classes.onlyChild }, { children: _jsxs(CardContent, Object.assign({ classes: {
                root: classes.cardContent,
            } }, { children: [Icon && _jsx(Icon, { className: classes.icon }), _jsx(Typography, Object.assign({ variant: "subtitle1", className: classes.title }, { children: title })), showCloseButton && (_jsx(IconButton, Object.assign({ className: classes.closeButton, onClick: onClose, "aria-label": "Cancel selection" }, { children: _jsx(CloseIcon, { fontSize: "medium", className: classes.closeIcon }) })))] })) })));
};
export default DzSelectedCard;
