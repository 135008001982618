import React from 'react';
import { useDispatch } from 'react-redux';
import { DzUpdateServiceLevelDialog } from 'shared-ui';

import {
  actions,
  thunks,
  useSelectUpdateServiceLevelDialog,
} from 'core/redux';

export const DzUpdateServiceLevelDialogView: React.FC = () => {
  const { address, isOpen, originalAddress } =
    useSelectUpdateServiceLevelDialog();
  const dispatch = useDispatch();

  return (
    <DzUpdateServiceLevelDialog
      actions={actions}
      thunks={thunks}
      address={address}
      originalAddress={originalAddress}
      dispatch={dispatch}
      isOpen={isOpen}
    />
  );
};
