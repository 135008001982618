import React, { useCallback, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import { formatAddress } from './utils';
import { formatPriceInCentsToDollars } from 'shared/utils/currency';
import {
  Order,
  getComparator,
} from '../shared/dz-simple-grid/dz-simple-grid.helpers';
import { DzTableFilters } from './dz-table-filters.view';
import { DzSimpleGrid } from '../shared/dz-simple-grid/dz-simple-grid.view';
import { ColumnDefinitions } from '../shared/dz-simple-grid';
import {
  DzCircularProgress,
  usePartnerConfig,
  useUserConfig,
} from 'shared-ui';
import useStripeReport from './useStripeReport';
import { StripeReportData } from './types';
import { useStyles } from './dz-reports-page.styles';
import { exportToCsv } from 'shared/utils/exportToCsv';
import { DzReportsFilter } from './dz-reports-filter.view';
import { API } from 'core/api';
import { auth } from '@one-vision/login';
import { isRenderingInIframe } from 'core/runtime';

const columnDefs: ColumnDefinitions<StripeReportData> = [
  {
    field: 'address1',
    headerName: 'Address',
    cellRenderer: ({ data }) =>
      formatAddress(data.address1, data.address2),
  },
  {
    field: 'businessName',
    headerName: 'Business Name',
  },
  {
    field: 'city',
    headerName: 'City',
  },
  {
    field: 'customerName',
    headerName: 'Client Name',
  },
  {
    field: 'customerEmail',
    headerName: 'Client Email',
  },
  {
    field: 'productName',
    headerName: 'Product',
  },
  {
    field: 'subscriptionId',
    headerName: 'Subscription ID',
  },
  {
    field: 'interval',
    headerName: 'Interval',
    cellRenderer: ({ data }) => `${data.intervalCount}-${data.interval}`,
  },
  {
    field: 'invoiceSubtotal',
    headerName: 'Invoice Subtotal',
    cellRenderer: ({ data }) =>
      formatPriceInCentsToDollars(Number(data.invoiceSubtotal)),
  },
  {
    field: 'latestInvoiceDiscount',
    headerName: 'Latest Invoice Discount',
    cellRenderer: ({ data }) =>
      formatPriceInCentsToDollars(Number(data.latestInvoiceDiscount)),
  },
  {
    field: 'chargeAmount',
    headerName: 'Charge Amount',
    cellRenderer: ({ data }) =>
      formatPriceInCentsToDollars(Number(data.chargeAmount)),
  },
  {
    field: 'status',
    headerName: 'Status',
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
  },
  {
    field: 'canceledAt',
    headerName: 'Ended At',
  },
  {
    field: 'currentPeriodEnd',
    headerName: 'Renews At',
  },
];

const getRowId = (row: StripeReportData) =>
  `${row.subscriptionId}${row.addressId}`;

export const DzReportsPage: React.FC = () => {
  const partnerConfig = usePartnerConfig();
  const [statusFilter, setStatusFilter] = useState('active');
  const [searchText, setSearchText] = useState('');
  const { data: rows, loading } = useStripeReport({
    status: statusFilter === 'all' ? '' : statusFilter,
  });
  const { user, loading: loadingUserConfig } = useUserConfig();
  const classes = useStyles();

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] =
    useState<keyof StripeReportData>('address1');
  const onSortChange = useCallback(
    ({
      order,
      orderBy,
    }: {
      order: Order;
      orderBy: keyof StripeReportData;
    }) => {
      setOrder(order);
      setOrderBy(orderBy);
    },
    [],
  );

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
    },
    [],
  );

  const dataList = useMemo(() => {
    if (!searchText) return rows;

    return rows.filter((row) => {
      const searchTextLowerCase = searchText.toLowerCase();
      return Object.values(row).some((value: string) =>
        value?.toString().toLowerCase().includes(searchTextLowerCase),
      );
    });
  }, [rows, searchText]);

  const sortedRows = useMemo(() => {
    return [...dataList].sort(getComparator(order, orderBy));
  }, [dataList, order, orderBy]);

  const handleExportClick = useCallback(() => {
    const headerRow = columnDefs.map((column) => column.headerName);
    const dataRows = sortedRows.map((row) => {
      return columnDefs.map<string>(({ field: id, cellRenderer }) => {
        return cellRenderer
          ? (cellRenderer({ data: row }) as string)
          : row[id];
      });
    });
    exportToCsv(`${statusFilter}_reports`, [headerRow].concat(dataRows), {
      withTimestamp: true,
    });
  }, [sortedRows, statusFilter]);

  const reportsActions = useMemo(() => {
    const viewLatestInvoice = (row: StripeReportData) => {
      window.open(row.latestInvoiceUrl, '_blank', 'noreferrer');
    };

    const goToPaymentPortal = async (row: StripeReportData) => {
      const { url } = await API.paymentPortalStaticURL({
        addressId: row.addressId,
      });
      if (!url) {
        return;
      }

      window.open(`https://${url}`, '_blank', 'noreferrer');
    };
    return [
      {
        label: 'View latest invoice',
        onClick: viewLatestInvoice,
        icon: <ReceiptOutlinedIcon />,
      },
      {
        label: 'Go to Payment Portal',
        onClick: goToPaymentPortal,
        icon: <PaymentOutlinedIcon />,
      },
    ];
  }, []);

  const isLoadingRules = partnerConfig.loading || loadingUserConfig;

  const hasAccessToReport =
    partnerConfig?.accessSubscriptionManagement &&
    (auth.getIsAdmin() || user?.reports);

  if (!hasAccessToReport && !isRenderingInIframe) {
    return (
      <Box className={classes.root}>
        <div className={classes.wrapper}>
          {'You are not authorized to access this page.'}
        </div>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <div className={classes.wrapper}>
        {isLoadingRules ? (
          <DzCircularProgress className={classes.spinner} />
        ) : (
          <>
            <Typography className={classes.headerName}>
              Subscriptions
            </Typography>
            <Box className={classes.controlWrapper}>
              <DzReportsFilter onSearchChange={onSearchChange} />
              <DzTableFilters
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                handleExportClick={handleExportClick}
              />
            </Box>
            <DzSimpleGrid
              withPagination
              rows={sortedRows}
              loading={loading}
              getRowId={getRowId}
              columnDefs={columnDefs}
              actions={reportsActions}
              order={order}
              orderBy={orderBy}
              onSortChange={onSortChange}
            />
          </>
        )}
      </div>
    </Box>
  );
};
