import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, Typography } from '@mui/material';
import cloneDeep from 'lodash.clonedeep';

import { format } from '@one-vision/date-utils';
import { useStyles } from './dz-data-import-result.styles';
import { DzCSVLink } from 'components/shared/dz-csv-link';
import { usePartner } from 'shared/hooks';

export interface Props {
  errors: Errors;
  successes: number;
  withDedicatedExportButton?: boolean;
  skippedRecordsFieldDefinitions: FieldDefinition[];
  onConfirm?: () => void;
  onExportClick?: () => void;
  onViewSkippedClick?: () => void;
}

export const DzDataImportResult: React.FC<Props> = ({
  errors,
  successes,
  skippedRecordsFieldDefinitions,
  withDedicatedExportButton,
  onConfirm,
  onExportClick,
  onViewSkippedClick,
}) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.topContainer}>
        <Typography align="center" variant="body1">
          Your data has been successfully imported.
        </Typography>

        <div className={classes.iconBox}>
          <CheckCircleOutlineIcon className={classes.icon} />
        </div>
      </div>

      <div className={classes.actionsContainer}>
        <Typography
          className={classes.amount}
          align="center"
          variant="body2"
        >
          {successes} Records Successfully Uploaded!
        </Typography>
        {withDedicatedExportButton ? (
          <DedicatedExportButton
            errors={errors}
            skippedRecordsFieldDefinitions={skippedRecordsFieldDefinitions}
            onConfirm={onConfirm}
            onExportClick={onExportClick}
          />
        ) : (
          <SeparatedExportButtons
            errors={errors}
            skippedRecordsFieldDefinitions={skippedRecordsFieldDefinitions}
            onConfirm={onConfirm}
            onViewSkippedClick={onViewSkippedClick}
            onExportClick={onExportClick}
          />
        )}
      </div>
    </div>
  );
};

interface ExportButtonsProps {
  errors: Errors;
  skippedRecordsFieldDefinitions: FieldDefinition[];
  onConfirm?: () => void;
  onExportClick?: () => void;
  onViewSkippedClick?: () => void;
}
export const DedicatedExportButton: React.FC<ExportButtonsProps> = (
  props,
) => {
  const classes = useStyles();
  const skippedRecordsFileName = useSkippedRecordsFileName();

  if (props.errors?.length) {
    return (
      <DzCSVLink
        data={copy(props.errors)}
        headers={copy(props.skippedRecordsFieldDefinitions)}
        filename={skippedRecordsFileName}
        onClick={props.onExportClick}
      >
        <Button
          className={classes.primaryButton}
          variant="contained"
          color="secondary"
        >
          {`Export skipped records (${props.errors.length})`}
        </Button>
      </DzCSVLink>
    );
  }

  return (
    <Button
      className={classes.primaryButton}
      variant="contained"
      color="secondary"
      onClick={props.onConfirm}
    >
      Confirm
    </Button>
  );
};

export const SeparatedExportButtons: React.FC<ExportButtonsProps> = (
  props: ExportButtonsProps,
) => {
  const classes = useStyles();
  const skippedRecordsFileName = useSkippedRecordsFileName();

  const handlePrimaryClick = () => {
    if (props.errors?.length) {
      props.onViewSkippedClick?.();
      return;
    }

    props.onConfirm?.();
  };

  return (
    <>
      <Button
        className={classes.primaryButton}
        variant="contained"
        color="secondary"
        onClick={handlePrimaryClick}
      >
        {props.errors?.length
          ? `View skipped records (${props.errors.length})`
          : 'Confirm'}
      </Button>
      {Boolean(props.errors?.length) && (
        <DzCSVLink
          data={copy(props.errors)}
          headers={copy(props.skippedRecordsFieldDefinitions)}
          filename={skippedRecordsFileName}
          onClick={props.onExportClick}
        >
          Or export CSV
        </DzCSVLink>
      )}
    </>
  );
};

function copy<T extends Array<unknown>>(data: T | null): T {
  return data ? cloneDeep(data) : ([] as unknown as T);
}

function useSkippedRecordsFileName(): string {
  const partner = usePartner();

  return `${partner?.name} import - ${format(new Date(), 'yyyy-MM-dd')}`;
}

type Errors = unknown[] | null;
export interface FieldDefinition {
  label: string;
  key: string;
}
