import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, AlertTitle, Box, Button, Fade, Typography, } from '@mui/material';
import { useFormStyles, useModalManager } from '../../shared';
import { useCallback, useState } from 'react';
import { ModalIDs, } from '../../..';
import { useStyles } from './dz-submit-day-one-dialog.styles';
const DzDocumentationAlert = ({ addressId, actions, thunks, dispatch, zendesk, }) => {
    const formClasses = useFormStyles();
    const classes = useStyles();
    const [openAlert, setOpenAlert] = useState(true);
    const { openModal } = useModalManager();
    const alertHandler = useCallback(() => {
        setOpenAlert(false);
    }, [setOpenAlert]);
    const openUpdateTechHandler = useCallback(async () => {
        if (!addressId) {
            return;
        }
        await openModal(ModalIDs.updateTech, {
            ovaid: addressId,
            actions,
            dispatch,
            thunks,
            zendesk,
        });
        alertHandler();
    }, [addressId, dispatch, thunks]);
    return (_jsx(Fade, Object.assign({ className: classes.marginTop, in: openAlert }, { children: _jsxs(Alert, Object.assign({ severity: "info", onClose: alertHandler, classes: {
                root: formClasses.infoRootAlert,
                icon: formClasses.infoAlertIcon,
                action: formClasses.alertActions,
            } }, { children: [_jsx(AlertTitle, Object.assign({ className: formClasses.alertTitle }, { children: "Forgot to update the site documentation for this Organization?" })), _jsx(Typography, Object.assign({ className: formClasses.alertBody }, { children: "Accurate documentation ensures the support team has the info they need to provide clients with a great experience! Please make sure the organization\u2019s technology fields are filled out by the time the client begins using their system." })), _jsx(Box, Object.assign({ className: formClasses.alertActionWrap }, { children: _jsx(Button, Object.assign({ onClick: openUpdateTechHandler }, { children: "Update Site Documentation" })) }))] })) })));
};
export default DzDocumentationAlert;
