import { DzAddress, DzClient, DzClientToAddress } from 'shared-ui';
export type {
  DzPartnerHoliday,
  PostAndPatchHolidayResponse,
} from './partner-holiday';
export * from './oncall-management.types';
export * from './api-meta';

export interface ActiveCppAPIResponse {
  address_id: string;
  client_id: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zip: string;
  first_name: string;
  last_name: string;
  email: string;
  email_2: string;
  cpp_exp_date: string;
  credit_card_provided: string;
  cc_exp_date: string;
  cc_last_4: string;
  tos_accepted: string;
  client_selected_membership: string;
  client_activated_membership: string;
  owner_id: string;
  activation_url: string;
  stage: string;
  updated_at: string;
}

export interface ReadinessProps {
  isReady: boolean;
}

export interface ClientToAddress {
  ovaid: DzAddress['ovaid'];
  ovcid: DzClient['ovcid'];
  email?: DzClientToAddress['email'];
}

export interface JsonApiError {
  title: string;
  detail: string;
}

export enum RequestParams {
  Selected = 'selected',
}

export type SortDirection = 'desc' | 'asc' | null;
