import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { useGetPartnerDomains } from './useGetPartnerDomains';
import { DzCircularProgress } from 'shared-ui';
import { useDzSelector } from 'core/redux';
import { useStyles } from './dz-users.partner-domains.styles';
import { DzUsersAddPartnerDomainDialog } from './dz-users.add-partner-domain-dialog.view';
import { PartnerDomainDetails } from './dz-users.partner-domain-details.view';

export function DzUsersPartnerDomains() {
  const { loading, error, getPartnerDomains } = useGetPartnerDomains();
  const partnerDomains = useDzSelector((state) => state.partnerDomains);
  const shouldShowContent = !loading && !error;
  const [openAddDomainDialog, setOpenAddDomainDialog] = useState(false);
  const classNames = useStyles();

  return (
    <>
      {loading && (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          marginTop={2}
        >
          <DzCircularProgress />
          <Typography marginLeft={1}>Loading domains</Typography>
        </Box>
      )}
      {error && (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          marginTop={2}
        >
          <Typography>
            There was an error loading the partner domains. Please try
            again.
          </Typography>
          <Button onClick={getPartnerDomains} variant="contained">
            Reload
          </Button>
        </Box>
      )}
      {shouldShowContent && (
        <Box className={classNames.domainsContainer}>
          <Box display="flex" flexDirection="row" justifyContent="end">
            <Button
              onClick={() => {
                setOpenAddDomainDialog(true);
              }}
            >
              ADD DOMAIN
            </Button>
          </Box>
          {partnerDomains.length > 0 && (
            <List>
              {partnerDomains.map((partnerDomain) => (
                <ListItem
                  key={partnerDomain.partnerDomainId}
                  className={classNames.listItem}
                  secondaryAction={
                    <PartnerDomainDetails
                      partnerDomain={partnerDomain}
                    ></PartnerDomainDetails>
                  }
                >
                  <ListItemText primary={partnerDomain.domain} />
                </ListItem>
              ))}
            </List>
          )}
          {!partnerDomains.length && (
            <Box marginTop={2}>
              <Typography align="center">No partner domains</Typography>
            </Box>
          )}
        </Box>
      )}
      <DzUsersAddPartnerDomainDialog
        open={openAddDomainDialog}
        onClose={() => {
          setOpenAddDomainDialog(false);
        }}
      />
    </>
  );
}
