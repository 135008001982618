import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
  () => {
    return {
      submitContainer: {
        textAlign: 'right',
      },
    };
  },
  { name: 'dz-bulk-users-importer' },
);
