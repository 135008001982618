import {
  GenerateCustomerPortal,
  GenerateCustomerPortalResponse,
} from 'shared-ui';
import { logError } from '@one-vision/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../api';

export const generateCustomerPortal = createAsyncThunk<
  GenerateCustomerPortalResponse | null,
  {
    payload: GenerateCustomerPortal;
  }
>('generate-customer-portal/post', async (arg) => {
  try {
    const { payload } = arg;
    const response = await API.generateCustomerPortal(payload);

    return response.data;
  } catch (error) {
    logError(error);
    return null;
  }
});
