import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      '@global': {
        '*': {
          boxSizing: 'inherit',
        },
        html: {
          boxSizing: 'border-box',

          // MUI typography elements use REMs, so you can scale the global
          // font size by setting the font-size on the <html> element.
          fontSize: 16,
          [theme.breakpoints.up('xs')]: {
            fontSize: 11,
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: 12,
          },
          [theme.breakpoints.up('md')]: {
            fontSize: 14,
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: 14,
          },
          // once we switch from px to rem we can make this value bigger
          [theme.breakpoints.up('xl')]: {
            fontSize: 14,
          },
        },
      },
      root: {
        position: 'relative',
      },
    };
  },
  { name: 'dz-root', index: 0 },
);
