import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        projectsRoot: {},
        cardContainer: {
            boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
            borderRadius: '4px',
            marginBottom: theme.spacing(2.5),
        },
        headerContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: `0 ${theme.px2rem(15)}`,
            height: theme.px2rem(70),
            borderBottom: `2px solid ${theme.palette.divider}`,
            marginBottom: theme.px2rem(27),
        },
        headerContainerZen: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: theme.px2rem(40),
            marginBottom: theme.px2rem(22),
        },
        innerContainer: {
            display: 'flex',
            alignItems: 'center',
            padding: `0 ${theme.px2rem(15)} ${theme.px2rem(15)} ${theme.px2rem(15)}`,
        },
        innerContainerZen: {
            paddingBottom: theme.px2rem(5),
            padding: `0 ${theme.px2rem(15)} ${theme.px2rem(5)} ${theme.px2rem(15)}`,
        },
        projectsSummaryContent: {
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: `${theme.px2rem(Number(theme.spacing(2)))} ${theme.px2rem(Number(theme.spacing(2)))}`,
        },
        newProjectButton: {
            marginRight: theme.px2rem(8),
        },
        projectDataRowTop: {
            alignSelf: 'baseline',
        },
        projectDataRowTitle: {
            fontWeight: 500,
        },
        projectDataRowTitleZen: {
            fontSize: theme.px2rem(12),
        },
        projectDataContainer: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            maxWidth: '100%',
        },
        projectDataContent: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            width: '52.5%',
        },
        projectDataContentZen: {
            width: '54%',
        },
        datePickerZen: {
            paddingLeft: 2,
        },
        projectDataLostReasonContent: {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: theme.px2rem(12),
            lineHeight: theme.px2rem(16),
            letterSpacing: theme.px2rem(0.2),
            color: '#4C4D50',
            marginTop: theme.px2rem(9),
            display: 'flex',
            width: '100%',
        },
        projectIcon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 20,
            width: 20,
            padding: `0 ${theme.px2rem(2)} 0 0`,
            marginRight: theme.spacing(2.5),
        },
        iconButton: { padding: 0 },
        projectDataTitle: {
            maxWidth: theme.px2rem(250),
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
        },
        projectDataLine: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.px2rem(17),
            justifyContent: 'space-between',
        },
        datePicker: {
            '& .MuiInputBase-input': {
                padding: 0,
                fontSize: theme.px2rem(12),
                '&::placeholder': {
                    opacity: 1,
                },
            },
        },
        datePickerColorError: {
            '& .MuiInputBase-input': {
                padding: 0,
                color: theme.dz.colors.error,
                fontSize: theme.px2rem(12),
                '&::placeholder': {
                    opacity: 1,
                    color: theme.dz.colors.error,
                },
            },
        },
        datePickerColorSuccess: {
            '& .MuiInputBase-input': {
                padding: 0,
                color: theme.dz.colors.success,
                fontSize: theme.px2rem(12),
                '&::placeholder': {
                    opacity: 1,
                    color: theme.dz.colors.success,
                },
            },
        },
        projectDataLostReasonLine: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.px2rem(17),
        },
        projectMenuButton: {
            marginRight: theme.px2rem(-12),
        },
        listItemIcon: {
            minWidth: theme.px2rem(32),
        },
        circularProgress: {
            margin: 'auto',
        },
        emergenceAnimation: {
            padding: theme.px2rem(18),
            opacity: 0,
            animation: '$emergence-anim .5s ease-in-out normal forwards',
        },
        '@keyframes emergence-anim': {
            '100%': {
                opacity: 1,
            },
        },
        orgDataValue: {
            fontSize: theme.px2rem(12),
            fontWeight: 400,
        },
        tabContentHeader: {
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: `${theme.px2rem(16)} 0`,
            margin: `0 ${theme.px2rem(15)} ${theme.px2rem(25)}`,
            borderBottom: `2px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
        },
        tabContentHeaderZen: {
            padding: `${theme.px2rem(10)} 0`,
            margin: `0 ${theme.px2rem(5)} ${theme.px2rem(25)}`,
        },
        cardHeader: {
            display: 'flex',
            flexDirection: 'row',
        },
    };
}, { name: 'dz-project-tab', index: 0 });
