import {
  DisassociateClientParams,
  ClientChangesList,
  DzAddress,
  DzClient,
  DzClientToAddress,
  ClientWithMultiContacts,
} from 'shared-ui';
import { logError } from '@one-vision/utils';
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { API } from 'core/api';
import { clientPosted, clientUpdated } from './actions/clients.actions';
import { addressUpdated } from './sidebars/actions';
import { fetchAddress } from './sidebars/thunks.redux';

export type Client = DzClient & DzClientToAddress;

export interface ClientToAddress {
  ovaid: DzAddress['ovaid'];
  ovcid: DzClient['ovcid'];
  email?: DzClientToAddress['email'];
}

export const addAddressToClient = createAsyncThunk<
  ClientToAddress | null,
  {
    data: ClientToAddress;
  }
>('client-to-address/post', async (arg) => {
  try {
    const response = await API.addAddressToClient(arg.data);
    return response.data;
  } catch (error) {
    logError(error);
    return null;
  }
});

export const fetchClientsForAddress = createAsyncThunk<
  ClientWithMultiContacts[],
  { ovaid: DzAddress['ovaid'] }
>('clients-with-contacts/get', async (arg) => {
  try {
    const { ovaid } = arg;
    if (!ovaid) {
      throw new Error('No AddressId');
    }
    const response = await API.getClientsWithContacts({
      ovaid,
      'include-labels': 'true',
    });
    return response.data;
  } catch (error) {
    logError(error);
    return [];
  }
});

export const fetchClientWithContactsById = createAsyncThunk<
  ClientWithMultiContacts[],
  { ovcid: string }
>('client-with-contacts/get', async (arg) => {
  try {
    const { ovcid } = arg;
    if (!ovcid) {
      throw new Error('No client id');
    }
    const response = await API.getClientsWithContacts({ ovcid });
    return response.data;
  } catch (error) {
    logError(error);
    return [];
  }
});

export const fetchClients = createAsyncThunk<
  (DzClientToAddress & DzClient)[],
  {
    primaryClientsOnly?: boolean;
  }
>('clients/get', async () => {
  try {
    const params = {};

    const response = await API.getClients(params);
    return response.data;
  } catch (error) {
    logError(error);
    return [];
  }
});

export const updateClient = createAsyncThunk<
  ClientWithMultiContacts[] | Partial<DzClientToAddress & DzClient>,
  {
    changes: ClientChangesList;
  }
>('client/patch', async (arg, thunkAPI) => {
  try {
    const { changes } = arg;
    const { data } = await API.updateClient(changes);
    if (changes?.ovaid) {
      thunkAPI
        .dispatch(
          fetchAddress({
            ovaid: changes.ovaid,
          }),
        )
        .unwrap()
        .then((addresses) => {
          if (addresses.length) {
            thunkAPI.dispatch(addressUpdated(addresses[0]));
          }
        });
    }

    return Array.isArray(data) ? data[0] : data;
  } catch (error) {
    logError(error);
    const err = error as AxiosError<{ message: string }>;

    throw new Error(err?.response?.data.message.split('. ')[0] || 'error');
  }
});

export const postClient = createAsyncThunk<
  (DzClientToAddress & DzClient) | null,
  {
    changes: Partial<DzClientToAddress & DzClient>;
  }
>('client/post', async (arg) => {
  try {
    const { changes } = arg;
    const response = await API.postClient(changes);
    return response.data;
  } catch (error) {
    logError(error);
    return null;
  }
});

export const disassociateClient = createAsyncThunk<
  DisassociateClientParams | null,
  DisassociateClientParams
>('client/disassociate', async (arg, thunkAPI) => {
  try {
    const { clientId, addressId } = arg;
    const response = await API.disassociateClientFromAddress(
      clientId,
      addressId || '',
    );
    thunkAPI
      .dispatch(
        fetchAddress({
          ovaid: addressId,
        }),
      )
      .unwrap()
      .then((addresses) => {
        if (addresses.length) {
          thunkAPI.dispatch(addressUpdated(addresses[0]));
        }
      });
    return response.data;
  } catch (error) {
    logError(error);
    return null;
  }
});

export const clientsSlice = createSlice<
  Client[],
  Record<string, never>,
  'clients'
>({
  name: 'clients',
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchClients.fulfilled,
        (
          _: Client[],
          action: ReturnType<typeof fetchClients.fulfilled>,
        ): Client[] => {
          const clients = action.payload;
          // TODO! It's a temporary solution. We need to create a new endpoint for clients and added an unique field.
          const uniqueClients = [
            ...new Map(
              clients.map((client) => [client.ovcid, client]),
            ).values(),
          ];

          return uniqueClients.sort(
            (a, b) =>
              new Date(b.updatedAt).valueOf() -
              new Date(a.updatedAt).valueOf(),
          );
        },
      )
      .addCase(
        clientUpdated,
        (
          state: Client[],
          action: PayloadAction<DzClient & DzClientToAddress>,
        ): Client[] => {
          const clientIndex = state.findIndex(
            (client) => client.ovcid === action.payload.ovcid,
          );

          if (clientIndex < 0) {
            return [...state, action.payload];
          }

          return [
            ...state.slice(0, clientIndex),
            {
              ...state[clientIndex],
              ...action.payload,
            },
            ...state.slice(clientIndex + 1),
          ];
        },
      )
      .addCase(
        clientPosted,
        (
          state: Client[],
          action: PayloadAction<DzClient & DzClientToAddress>,
        ): Client[] => {
          return [...state, action.payload];
        },
      )
      .addCase(
        fetchClientWithContactsById.fulfilled,
        (
          state: Client[],
          action: ReturnType<typeof fetchClientWithContactsById.fulfilled>,
        ) => {
          const changedClient = action.payload[0];

          const clientIndex = state.findIndex(
            (client) => client.ovcid === changedClient?.ovcid,
          );

          if (clientIndex < 0) {
            return;
          }

          const primaryEmail = changedClient.emails.find(
            (currentEmail) =>
              currentEmail.clientEmailId ===
              currentEmail.primaryClientEmailId,
          )?.email;

          const primaryPhone = changedClient.phones.find(
            (currentPhone) =>
              currentPhone.clientPhoneNumberId ===
              currentPhone.primaryClientPhoneNumberId,
          )?.phone;

          const clientToChange = state[clientIndex];

          state[clientIndex] = {
            ...clientToChange,
            email: primaryEmail || clientToChange.email,
            phone: primaryPhone || clientToChange.phone,
            firstName: changedClient.firstName,
            lastName: changedClient.lastName,
          };
        },
      );
  },
});
