import { MapLike } from 'typescript';

import { NewUser } from 'core/redux/owners.redux';

export enum Phase {
  ChoosingSource = 'ChoosingSource',
  Editing = 'Editing',
  Importing = 'Importing',
  Done = 'Done',
}

export type User = NewUser & MapLike<string>;
