import React, { useState } from 'react';
import { Button } from '@mui/material';

import { useStyles } from './dz-cookies-snackbar.styles';

export const lsCookiesPolicy = 'cookies_policy';

export const DzCookiesSnackbarView: React.FC = () => {
  const classes = useStyles();
  const [acceptedPolicy, setAcceptedPolicy] = useState(
    Boolean(localStorage.getItem(lsCookiesPolicy)),
  );
  const handleClick = () => {
    localStorage.setItem(lsCookiesPolicy, 'checked');
    setAcceptedPolicy(true);
  };

  if (acceptedPolicy) return null;

  return (
    <div className={classes.root}>
      <div>
        <div>
          ProVision uses cookies in order to provide the most accurate
          information.
        </div>
        <div> Please accept cookies for an optimal experience.</div>
      </div>

      <Button
        className={classes.button}
        onClick={handleClick}
        color="inherit"
        size="small"
        variant="outlined"
      >
        Accept Cookies
      </Button>
    </div>
  );
};
