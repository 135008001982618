import { useMemo, useCallback } from 'react';
import { useSearchParams, NavigateOptions } from 'react-router-dom';
import { URL_PARAM_NAMES } from 'shared/constants';

type ParamValue = () => [
  string,
  (newQuery: string, options?: NavigateOptions) => void,
];

export const makeUseUrlParams = (
  paramName: string,
  defaultValue: string,
): ParamValue => {
  return () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const paramValue = useMemo(
      () => searchParams.get(paramName) || defaultValue,
      [searchParams],
    );

    const setValue = useCallback(
      (newValue: string, options?: NavigateOptions) => {
        const newSearchParams = new URLSearchParams(searchParams);

        const stringifiedValue =
          typeof newValue === 'string'
            ? newValue
            : JSON.stringify(newValue);

        newSearchParams.set(paramName, stringifiedValue);

        setSearchParams(newSearchParams, options);
      },
      [searchParams, setSearchParams],
    );

    return [paramValue, setValue];
  };
};

export const useViewUrlParams = makeUseUrlParams(
  URL_PARAM_NAMES.CURRENT_VIEW,
  '0',
);
export const useSelectedUrlParam = makeUseUrlParams(
  URL_PARAM_NAMES.SELECTED,
  '',
);
