import React from 'react';
import { auth } from '@one-vision/login';
import { ReadinessProps } from 'types';
import { HOC } from 'types/HOC.types';

const withAdminProps: HOC<ReadinessProps> = (WrappedComponent) => {
  return React.memo(function ComponentWithAdminProps({ ...props }) {
    const isReady = auth.getIsAdmin();
    return <WrappedComponent isReady={isReady} {...props} />;
  });
};

export default withAdminProps;
