import { jsx as _jsx } from "react/jsx-runtime";
import { DzCircularProgress } from '..';
import { Stack } from '@mui/material';
export const DzLazyImportFallback = () => {
    return (_jsx(Stack, Object.assign({ sx: {
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
        } }, { children: _jsx(DzCircularProgress, {}) })));
};
