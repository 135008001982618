import React from 'react';
import { csn } from '@one-vision/utils';
import { Skeleton } from '@mui/material';
import { useStyles } from './dz-global-search-result.styles';
import { usePhStyles } from './dz-global-search-dialog-placeholder.styles';

export const DzGlobalSearchDialogPlaceholder = () => {
  const classes = useStyles();
  const phClasses = usePhStyles();

  return (
    <div className={phClasses.container}>
      <div className={classes.resultHeader}>
        <Skeleton
          animation="wave"
          variant="text"
          width={132}
          height={28}
        />
      </div>
      <div className={csn(classes.resultRow, phClasses.placholderRow)}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={20}
          height={20}
        />
        <div className={phClasses.withPaddingLeft}></div>
        <Skeleton
          animation="wave"
          variant="text"
          width={275}
          height={24}
        />
      </div>
      <div className={classes.resultHeader}>
        <Skeleton
          animation="wave"
          variant="text"
          width={132}
          height={28}
        />
      </div>
      <div className={csn(classes.resultRow, phClasses.placholderRow)}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={20}
          height={20}
        />
        <div className={phClasses.withPaddingLeft}></div>
        <Skeleton
          animation="wave"
          variant="text"
          width={275}
          height={24}
        />
      </div>
      <div className={csn(classes.resultRow, phClasses.placholderRow)}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={20}
          height={20}
        />
        <div className={phClasses.withPaddingLeft}></div>
        <Skeleton
          animation="wave"
          variant="text"
          width={275}
          height={24}
        />
      </div>
      <div className={csn(classes.resultRow, phClasses.placholderRow)}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={20}
          height={20}
        />
        <div className={phClasses.withPaddingLeft}></div>
        <Skeleton
          animation="wave"
          variant="text"
          width={275}
          height={24}
        />
      </div>
      <div className={csn(classes.resultRow, phClasses.placholderRow)}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={20}
          height={20}
        />
        <div className={phClasses.withPaddingLeft}></div>
        <Skeleton
          animation="wave"
          variant="text"
          width={275}
          height={24}
        />
      </div>
      <div className={csn(classes.resultRow, phClasses.placholderRow)}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={20}
          height={20}
        />
        <div className={phClasses.withPaddingLeft}></div>
        <Skeleton
          animation="wave"
          variant="text"
          width={275}
          height={24}
        />
      </div>
      <div className={csn(classes.resultRow, phClasses.placholderRow)}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={20}
          height={20}
        />
        <div className={phClasses.withPaddingLeft}></div>
        <Skeleton
          animation="wave"
          variant="text"
          width={275}
          height={24}
        />
      </div>
      <div className={classes.resultHeader}>
        <Skeleton
          animation="wave"
          variant="text"
          width={132}
          height={28}
        />
      </div>
      <div className={csn(classes.resultRow, phClasses.placholderRow)}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={20}
          height={20}
        />
        <div className={phClasses.withPaddingLeft}></div>
        <Skeleton
          animation="wave"
          variant="text"
          width={275}
          height={24}
        />
      </div>
      <div className={csn(classes.resultRow, phClasses.placholderRow)}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={20}
          height={20}
        />
        <div className={phClasses.withPaddingLeft}></div>
        <Skeleton
          animation="wave"
          variant="text"
          width={275}
          height={24}
        />
      </div>
      <div className={csn(classes.resultRow, phClasses.placholderRow)}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={20}
          height={20}
        />
        <div className={phClasses.withPaddingLeft}></div>
        <Skeleton
          animation="wave"
          variant="text"
          width={275}
          height={24}
        />
      </div>
      <div className={csn(classes.resultRow, phClasses.placholderRow)}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={20}
          height={20}
        />
        <div className={phClasses.withPaddingLeft}></div>
        <Skeleton
          animation="wave"
          variant="text"
          width={275}
          height={24}
        />
      </div>
      <div className={csn(classes.resultRow, phClasses.placholderRow)}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={20}
          height={20}
        />
        <div className={phClasses.withPaddingLeft}></div>
        <Skeleton
          animation="wave"
          variant="text"
          width={275}
          height={24}
        />
      </div>
    </div>
  );
};
