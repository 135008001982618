import { API } from 'core/api';
import { useState, useEffect } from 'react';

const ERROR_MESSAGE = "Couldn't fetch the payment portal URL";
const usePaymentPortalUrl = ({
  clientId,
  addressId,
  partnerId,
}: {
  clientId?: string;
  addressId?: string;
  partnerId?: number;
}) => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchPaymentPortal = async () => {
      try {
        const { url } = await API.paymentPortalStaticURL({
          clientId,
          addressId,
          partnerId,
        });

        setUrl(url);
        setLoading(false);
      } catch (error) {
        setError(ERROR_MESSAGE);
        setLoading(false);
      }
    };

    fetchPaymentPortal();
  }, [clientId, addressId, partnerId]);

  return { url, loading, error };
};

export default usePaymentPortalUrl;
