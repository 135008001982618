import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        root: {},
        userContainer: {
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '4px',
            border: `1px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
            marginBottom: theme.px2rem(24),
        },
        cardHeader: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: `${theme.px2rem(12)} ${theme.px2rem(15)} ${theme.px2rem(12)} ${theme.px2rem(12)}`,
            borderBottom: `2px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
        },
        cardHeaderZen: {
            padding: `${theme.px2rem(16)} ${theme.px2rem(12)}`,
        },
        cardHeaderPartly: {
            display: 'flex',
            alignItems: 'center',
        },
        openButton: {
            borderTop: `0.5px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
            borderRadius: 0,
            fontSize: theme.px2rem(11),
            color: theme.dz.colors.oneVisionBlack65,
        },
        arrowClosed: {
            transform: 'rotate(180deg)',
            animation: '$rotate 0.5s ease-in-out',
        },
        '@keyframes rotate': {
            '0%': {
                transform: 'rotate(0deg)',
            },
            '100%': {
                transform: 'rotate(180deg)',
            },
        },
        arrowOpened: {
            transform: 'rotate(0deg)',
            animation: '$rotate-back 0.5s ease-in-out',
        },
        '@keyframes rotate-back': {
            '0%': {
                transform: 'rotate(180deg)',
            },
            '100%': {
                transform: 'rotate(0deg)',
            },
        },
        userDataContainer: {
            display: 'grid',
            gridTemplateColumns: '2fr auto 3fr',
            padding: theme.px2rem(26),
            rowGap: theme.px2rem(15),
        },
        primaryUserIcon: {
            cursor: 'pointer',
            marginRight: theme.px2rem(5),
        },
        primaryUserIconInactive: {
            color: '#F6A609',
        },
        primaryUserIconActive: {
            color: '#828282',
        },
        userName: {
            fontWeight: 500,
            fontSize: theme.px2rem(14),
            lineHeight: theme.px2rem(18),
        },
        hideTooWide: {
            maxWidth: theme.px2rem(170),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        userMenuButton: {
            marginRight: `-${theme.px2rem(12)}`,
        },
        infoText: {
            fontWeight: 500,
            fontSize: theme.px2rem(14),
            lineHeight: theme.px2rem(18),
            alignSelf: 'center',
        },
        infoData: {
            fontWeight: 400,
            fontSize: theme.px2rem(12),
            alignSelf: 'center',
            alignItems: 'center',
            minWidth: theme.px2rem(170),
            margin: 0,
        },
        infoIcon: {
            color: theme.dz.colors.proactive,
            width: theme.px2rem(17),
            height: theme.px2rem(14),
            alignSelf: 'center',
            marginRight: theme.px2rem(10),
        },
        gradientBlur: {
            background: `linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, ${theme.palette.custom.OV.sys.surfaceContainerLowest})`,
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0) 0px, ${theme.palette.custom.OV.sys.surfaceContainerLowest})`,
            height: theme.px2rem(80),
            width: '100%',
            position: 'absolute',
            bottom: 0,
        },
    };
});
