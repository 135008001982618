import React from 'react';
import { useDispatch } from 'react-redux';
import { DzUpdateAddressDialog } from 'shared-ui';

import {
  actions,
  thunks,
  useDzSelector,
  useSelectUpdateAddressDialog,
} from 'core/redux';
import { selectOwners } from 'core/redux/owners.redux';

export const DzUpdateAddressDialogView: React.FC = () => {
  const { address, isOpen, originalAddress } =
    useSelectUpdateAddressDialog();
  const brands = useDzSelector((state) => state.brands);
  const owners = useDzSelector(selectOwners);
  const dispatch = useDispatch();

  return (
    <DzUpdateAddressDialog
      actions={actions}
      thunks={thunks}
      address={address}
      originalAddress={originalAddress}
      brands={brands}
      dispatch={dispatch}
      isOpen={isOpen}
      owners={owners}
    />
  );
};
