import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton, Box, Divider } from '@mui/material';
import { csn } from '@one-vision/utils';
import { useStyles } from './dz-membership-skeleton.styles';
const InfoLine = () => {
    const classes = useStyles();
    return (_jsxs(Box, Object.assign({ className: classes.infoLine }, { children: [_jsx(Skeleton, { className: classes.rounded, variant: "rectangular", width: "40%", height: 22 }), _jsx(Skeleton, { className: classes.infoLineMiddleItem, variant: "circular", width: 22, height: 22 }), _jsx(Skeleton, { className: classes.rounded, variant: "rectangular", width: "20%", height: 22 })] })));
};
export const DzMembershipSkeleton = ({ zendesk, }) => {
    const classes = useStyles();
    return (_jsxs(Box, { children: [_jsx(Box, Object.assign({ className: classes.menuWrapper }, { children: _jsx(Skeleton, { className: classes.rounded, variant: "rectangular", width: 48, height: 22 }) })), _jsx(Divider, { className: csn(classes.divider, [classes.dividerZen, zendesk], classes.noMarginTop) }), _jsx(Skeleton, { className: csn(classes.sectionHeader, classes.rounded), variant: "rectangular", width: "60%", height: 24 }), Array(5).fill(_jsx(InfoLine, {})), _jsxs(Box, Object.assign({ className: classes.membershipSalesWrapper }, { children: [_jsx(Skeleton, { className: csn(classes.membershipSalesNotesHeader, classes.rounded), variant: "rectangular", width: "50%", height: 22 }), _jsx(Skeleton, { className: classes.rounded, variant: "rectangular", width: "100%", height: 50 })] })), _jsx(Divider, { className: csn(classes.divider, [classes.dividerZen, zendesk]) }), _jsx(Skeleton, { className: csn(classes.sectionHeader, classes.rounded), variant: "rectangular", width: "60%", height: 24 }), Array(6).fill(_jsx(InfoLine, {}))] }));
};
