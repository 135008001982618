var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Autocomplete, ClickAwayListener, IconButton, Paper, Popper, TextField, } from '@mui/material';
import { useMemo, useRef, useCallback, useState } from 'react';
import { DzOwnerIcon, DzOwnerListOption } from '../../../shared';
import DzRelativePopper from '../../../shared/dz-relative-popper';
import { useStyles } from './dz-owner-autocomplete.styles';
import { useStyles as useProjectStyles } from '../dz-projects.styles';
import { useAutocompleteStyles } from '../../../shared/styles/autocomplete.styles';
import { UNASSIGNED_TEXT } from '../../../../const';
import { Projects } from '../../../../redux';
const DzOwnerAutocomplete = ({ dispatch, address, owner, owners, thunks, projectId, }) => {
    const autocompleteRef = useRef(null);
    const classes = useStyles();
    const autocompleteClasses = useAutocompleteStyles();
    const projectClasses = useProjectStyles();
    const ownerOptions = useMemo(() => {
        const unassigned = {
            name: null,
            ownerId: null,
        };
        return [unassigned, ...owners];
    }, [owners]);
    const [anchorElement, setAnchor] = useState(null);
    const openAutocomplete = useCallback((event) => {
        setAnchor(event.currentTarget);
    }, [setAnchor]);
    const closeAutocomplete = useCallback(() => {
        if (anchorElement) {
            anchorElement.focus();
        }
        setAnchor(null);
    }, [anchorElement, setAnchor]);
    const onAutocompleteClose = useCallback((_, reason) => {
        if (reason === 'escape') {
            closeAutocomplete();
        }
    }, [closeAutocomplete]);
    const onAutocompleteChange = useCallback((_, option) => {
        if (!option) {
            return;
        }
        dispatch(thunks.updateProject({
            changes: { ownerId: option.ownerId },
            projectId,
        }))
            .unwrap()
            .then(() => {
            if (address === null || address === void 0 ? void 0 : address.ovaid) {
                dispatch(thunks.fetchOrgDialogProjects({
                    ovaid: address.ovaid,
                }));
            }
            dispatch(Projects.actions.update({
                ovprjid: projectId,
                ownerId: option.ownerId,
            }));
        });
        closeAutocomplete();
    }, [dispatch, thunks, projectId, address]);
    const open = Boolean(anchorElement);
    const id = open ? 'autocomplete-popper' : undefined;
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: projectClasses.projectIcon }, { children: [_jsx(IconButton, Object.assign({ className: projectClasses.iconButton, size: "small", onClick: openAutocomplete, ref: autocompleteRef }, { children: _jsx(DzOwnerIcon, { name: owner === null || owner === void 0 ? void 0 : owner.name }) })), _jsx(Popper, Object.assign({ id: id, open: open, anchorEl: autocompleteRef.current, className: autocompleteClasses.popper }, { children: _jsx(ClickAwayListener, Object.assign({ onClickAway: closeAutocomplete }, { children: _jsx(Paper, { children: _jsx(Autocomplete, { autoHighlight: true, open: true, disablePortal: true, popupIcon: null, PopperComponent: DzRelativePopper, renderInput: (_a) => {
                                        var { InputProps, inputProps } = _a, params = __rest(_a, ["InputProps", "inputProps"]);
                                        return (_jsx(TextField, Object.assign({ autoFocus: true }, params, { InputProps: Object.assign(Object.assign({}, InputProps), { disableUnderline: true, classes: {
                                                    root: classes.inputRoot,
                                                } }), inputProps: Object.assign(Object.assign({}, inputProps), { 'aria-label': 'Choose an account manager' }), margin: "none", variant: "standard", placeholder: "Assign to..." })));
                                    }, options: ownerOptions, getOptionLabel: (option) => option.name || UNASSIGNED_TEXT, renderOption: (props, option) => (_createElement(DzOwnerListOption, Object.assign({}, props, { name: option.name, key: option.ownerId }))), onClose: onAutocompleteClose, onChange: onAutocompleteChange }) }) })) }))] })), _jsx("div", Object.assign({ className: classes.orgDataValue }, { children: (owner === null || owner === void 0 ? void 0 : owner.name) || UNASSIGNED_TEXT }))] }));
};
export default DzOwnerAutocomplete;
