import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        stepperRoot: {
            padding: `${theme.spacing(3)} ${theme.spacing(1.5)}`,
        },
        actions: {
            padding: theme.spacing(3),
            backgroundColor: theme.palette.custom.OV.sys.surfaceContainerLowest,
        },
    };
}, { name: 'dz-new-project-dialog', index: 0 });
