import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const DzReportsFilter = ({
  onSearchChange,
}: {
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
  <TextField
    variant="standard"
    placeholder="Filter results"
    margin="none"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
    onChange={onSearchChange}
  />
);
