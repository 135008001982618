import isEqual from 'lodash/isEqual';
import { useEffect, useState, useCallback } from 'react';

import { LocalStorageManager } from 'shared';

const storageKey = 'favoriteViews';

export interface Favorites {
  project: ProjectsViews[];
  organization: OrganizationsViews[];
  user: UsersViews[];
}

interface Output {
  favorites: Favorites;
  handleAddFavorite: (favorite: string) => void;
  handleRemoveFavorite: (favorite: string) => void;
}

export enum OrganizationsViews {
  allOrganizations = 'all',
  withProject = 'with-project',
  expiredCPPs = 'expired',
  activeCPPs = 'active',
}

export enum ProjectsViews {
  allProjects = 'all',
  proposals = 'proposals',
  activeProjects = 'active',
  completedProjects = 'completed',
  lostProjects = 'lost',
  allPrimaryProjects = 'primary',
}
export enum UsersViews {
  allUsers = 'all',
  allPrimary = 'primary',
  notAssociatedWithOrg = 'no-org',
}

const defaultFavoriteViews = {
  organization: [
    OrganizationsViews.allOrganizations,
    OrganizationsViews.expiredCPPs,
    OrganizationsViews.activeCPPs,
  ],
  project: [
    ProjectsViews.allProjects,
    ProjectsViews.proposals,
    ProjectsViews.activeProjects,
    ProjectsViews.completedProjects,
  ],
  user: [
    UsersViews.allUsers,
    UsersViews.allPrimary,
    UsersViews.notAssociatedWithOrg,
  ],
};

const updateFavoriteViews = (
  parsedViews: Favorites,
  defaultFavoriteViews: Favorites,
) => {
  const currentViewKeys = Object.keys(parsedViews);
  const defaultViewKeys = Object.keys(defaultFavoriteViews);

  const currentViewsLen = currentViewKeys.length;
  const defaultViewsLen = defaultViewKeys.length;

  const allPagesHaveViews = currentViewKeys.every((viewName) => {
    return parsedViews[viewName as keyof typeof parsedViews].length;
  });

  if (currentViewsLen === defaultViewsLen) {
    if (allPagesHaveViews) {
      return parsedViews;
    } else {
      return currentViewKeys.reduce((acc, viewName) => {
        return {
          ...acc,
          [viewName]:
            defaultFavoriteViews[
              viewName as keyof typeof defaultFavoriteViews
            ],
        };
      }, defaultFavoriteViews);
    }
  }

  if (currentViewsLen > 0 && currentViewsLen < defaultViewsLen) {
    return {
      ...defaultFavoriteViews,
      ...parsedViews,
    };
  }

  return defaultFavoriteViews;
};

export const makeUseFavoriteViews = (key: keyof Favorites) => {
  try {
    const jsonFavoriteViews = LocalStorageManager.getItem(storageKey);

    let favorites: Favorites = defaultFavoriteViews;

    if (jsonFavoriteViews) {
      const parsedViews = JSON.parse(jsonFavoriteViews);

      favorites = updateFavoriteViews(parsedViews, defaultFavoriteViews);
    }

    LocalStorageManager.setItem(storageKey, JSON.stringify(favorites));
  } catch (error) {
    LocalStorageManager.setItem(
      storageKey,
      JSON.stringify(defaultFavoriteViews),
    );
  }

  return (): Output => {
    const jsonFavoriteViews = LocalStorageManager.getItem(storageKey);

    let favoritesFromStorage;

    try {
      favoritesFromStorage = jsonFavoriteViews
        ? JSON.parse(jsonFavoriteViews)
        : {};
    } catch (e) {
      console.log('There was an error parsing views from Local Storage');
    }

    const [favorites, setFavorites] = useState<Favorites>(
      favoritesFromStorage as Favorites,
    );

    useEffect(() => {
      const jsonFavoriteViews = LocalStorageManager.getItem(storageKey);

      if (jsonFavoriteViews) {
        const favoriteViews: Favorites = JSON.parse(jsonFavoriteViews);

        if (isEqual(favoriteViews, favorites)) {
          return;
        }
      }

      LocalStorageManager.setItem(storageKey, JSON.stringify(favorites));
    }, [favorites]);

    const handleAddFavorite = useCallback(
      (viewId: string) => {
        const currentFavorites = favorites[key];

        setFavorites({
          ...favorites,
          [key]: [...currentFavorites, viewId],
        });
      },
      [setFavorites, favorites],
    );

    const handleRemoveFavorite = useCallback(
      (viewId: string) => {
        const currentFavorites = favorites[key] as string[];

        const modifiedFavorites = currentFavorites.filter(
          (favorite) => favorite !== viewId,
        );

        setFavorites({
          ...favorites,
          [key]: [...modifiedFavorites],
        });
      },
      [favorites, setFavorites],
    );

    return {
      favorites,
      handleAddFavorite,
      handleRemoveFavorite,
    };
  };
};
