import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => ({
    input: {
        flex: 1,
        '& label[data-shrink="false"]': {
            maxWidth: theme.px2rem(250),
        },
    },
    container: {
        display: 'flex',
        flexDirection: theme.isZendesk ? 'column' : 'row',
        alignItems: theme.isZendesk ? 'normal' : 'center',
        justifyContent: 'space-between',
        columnGap: theme.spacing(1.5),
        rowGap: theme.spacing(1.5),
        padding: theme.isZendesk
            ? `${theme.spacing(1.5)} 0`
            : theme.spacing(1.5),
    },
}));
