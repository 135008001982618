import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, DialogActions, DialogContent, TextField, Zoom, } from '@mui/material';
import { useCallback, useState } from 'react';
import { withModalManager } from '../../shared';
import { useStyles } from './dz-select-image-dialog.styles';
import { ALLOWED_S3_CONTENT_TYPE } from '../../..';
const DzSelectImageDialog = ({ isOpen, close, clear }) => {
    const classes = useStyles();
    const [state, setState] = useState({
        url: '',
        file: null,
    });
    const closeHandler = useCallback(() => {
        close();
    }, [close]);
    const handleChange = useCallback((event) => {
        setState((state) => (Object.assign(Object.assign({}, state), { [event.target.name]: event.target.value })));
    }, [setState]);
    const handleSubmit = useCallback(() => {
        close(state);
    }, [state, close]);
    const handleFileSelection = useCallback((event) => {
        var _a;
        const { target: fileInput } = event;
        if (!((_a = fileInput.files) === null || _a === void 0 ? void 0 : _a.length)) {
            setState((previous) => (Object.assign(Object.assign({}, previous), { file: null })));
        }
        else {
            const chosenFile = fileInput.files[0];
            setState((previous) => (Object.assign(Object.assign({}, previous), { file: chosenFile })));
        }
    }, [setState]);
    return (_jsxs(Dialog, Object.assign({ open: isOpen, onClose: closeHandler, closeAfterTransition: true, transitionDuration: 400, TransitionComponent: Zoom, TransitionProps: {
            onExited: clear,
            mountOnEnter: true,
            unmountOnExit: true,
        }, disableRestoreFocus: true, classes: {
            container: classes.dialogContainer,
            paper: classes.dialogPaper,
        } }, { children: [_jsxs(DialogContent, Object.assign({ className: classes.dialogContent }, { children: [_jsx(TextField, { label: "URL", name: "url", margin: "none", value: state.url, onChange: handleChange, autoFocus: true, disabled: !!state.file }), _jsx(TextField, { label: "File", InputLabelProps: {
                            shrink: true,
                        }, name: "file", margin: "none", InputProps: {
                            type: 'file',
                            onChange: handleFileSelection,
                            inputProps: {
                                accept: Object.keys(ALLOWED_S3_CONTENT_TYPE).join(', '),
                            },
                        } })] })), _jsxs(DialogActions, Object.assign({ className: classes.dialogActions }, { children: [_jsx(Button, Object.assign({ variant: "outlined", size: "large", onClick: closeHandler }, { children: "Cancel" })), _jsx(Button, Object.assign({ variant: "contained", color: "primary", size: "large", onClick: handleSubmit }, { children: "Ok" }))] }))] })));
};
export default withModalManager()(DzSelectImageDialog);
