import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        margin: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: '100%',
      },
      headerName: {
        fontWeight: 700,
        fontSize: theme.px2rem(32),
        color: theme.black.black100,
        marginBottom: 10,
      },
      goToPortalBox: {
        background: '#ECDEFF',
        width: '100%',
        height: theme.px2rem(90),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        padding: theme.px2rem(20),
      },
      goToPortalBoxTextContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '70%',
      },
      goToPortalBoxText: {
        marginLeft: theme.px2rem(20),
      },
      goToPortalBoxMainLine: {
        fontWeight: 'bold',
        width: '100%',
      },
      button: {
        height: theme.px2rem(45),
        backgroundColor: theme.dz.colors.proactive,
        color: '#fff',
        minWidth: theme.px2rem(195),
        '&:hover': {
          backgroundColor: '#713eb2',
        },
      },
      animatedAppearance: {
        opacity: 0,
        animation: '$row-emergence-anim .6s ease-in-out normal forwards',
        transition: theme.transitions.create('background-color'),
      },
      '@keyframes row-emergence-anim': {
        '100%': {
          opacity: 1,
          transform: 'unset',
        },
      },
      spinner: {
        position: 'absolute',
        left: 'calc(50% - 20px)',
        top: 'calc(50% - 60px)',
      },
    };
  },
  { name: 'dz-billing' },
);
