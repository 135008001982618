import { logError } from '@one-vision/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API } from '../api';
import { DzIdentityLabels } from 'shared-ui';

export const fetchIdentLabels = createAsyncThunk<DzIdentityLabels>(
  'get/identity-labels',
  async () => {
    try {
      const response = await API.getIdentityLabels();
      return response.data;
    } catch (error) {
      // thunkAPI.rejectWithValue
      logError(error);
      return { email: [], phone: [] };
    }
  },
);

export const identitySlice = createSlice<
  DzIdentityLabels,
  Record<string, never>,
  'identity'
>({
  name: 'identity',
  initialState: { email: [], phone: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchIdentLabels.fulfilled,
      (
        _,
        action: ReturnType<typeof fetchIdentLabels.fulfilled>,
      ): DzIdentityLabels => {
        return action.payload;
      },
    );
  },
});
