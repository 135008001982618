import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { thunks, useDzSelector } from 'core/redux';
import { DzAsyncDispatch } from 'shared-ui';

export function useGetPartnerDomains() {
  const dispatch = useDispatch<DzAsyncDispatch>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const thereAreRecords = useDzSelector(
    (state) => !!state.partnerDomains.length,
  );

  const execute = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      await dispatch(thunks.getPartnerDomains()).unwrap();
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (thereAreRecords) {
      return;
    }

    execute();
  }, [dispatch, execute, thereAreRecords]);

  return { getPartnerDomains: execute, loading, error };
}
