import { createAction } from '@reduxjs/toolkit';

import { DzAddress, DzAddressShortList } from 'shared-ui';

export const addressIsUpdating =
  createAction<Partial<DzAddress>>('address/updating');
export const addressUpdated = createAction<DzAddress>('address/updated');

export const fetchedAllAddresses = createAction<
  DzAddress[] | DzAddressShortList[]
>('fetchAddresses/get-all');
