import { useMemo } from 'react';

import { auth } from '@one-vision/login';
import { useDzSelector } from '../../core/redux';

export function usePartner() {
  const partners = useDzSelector((state) => state.settings.partners);
  const partnerId = auth.getPartnerId();

  return useMemo(
    () => partners.find((partner) => partner.partnerId === partnerId),
    [partners, partnerId],
  );
}
