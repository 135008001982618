import { makeStyles, CSSProperties } from '@mui/styles';
import { config } from '../../core/config';
import { ExtTheme } from '../../core/theme';

const customEnvColor = process.env.REACT_APP_ENV_COLOR || '';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    const backgroundStyle = {} as CSSProperties;
    if (customEnvColor) {
      backgroundStyle.background = `linear-gradient(90deg, white, ${customEnvColor})`;
    } else {
      let logoColor: string;
      switch (config.stage) {
        case 'local':
          logoColor = theme.palette.secondary.dark;
          break;
        case 'sb':
          logoColor = theme.palette.primary.main;
          break;
        case 'dev':
          logoColor = theme.palette.primary.main;
          break;
        case 'uat':
          logoColor = theme.dz.colors.signature;
          break;
        default:
          logoColor = '#3A3E43';
          break;
      }
      backgroundStyle.backgroundColor = logoColor;
    }
    return {
      root: {
        position: 'fixed',
        zIndex: 1000,
        /* 
         Hard-coding theme.palette.custom.OV.sys.surfaceContainerLowest here
         to keep navbar black at all time.
        */
        backgroundColor: '#1A1D21',
        top: 0,
        bottom: 0,
        left: 0,
        width: `${theme.dz.appBar.width}px`,
        display: 'flex',
        flexDirection: 'column',
        color: 'white',
      },
      logo: {
        position: 'relative',
        width: `${theme.dz.appBar.width}px`,
        height: `${theme.dz.topBar.height}px`,
        ...backgroundStyle,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      pages: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
      },
      pageIconButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: `${theme.dz.appBar.width}px`,
        height: `${theme.dz.appBar.width - 10}px`,
        borderRadius: '0px',
        position: 'relative',
        minWidth: 'unset !important',
        transition:
          // theme.transitions.create('background-color') + ' !important',
          'unset !important',
      },
      pageIconComingSoon: {
        color: '#aaa',
      },
      pageLink: {
        // borderRadius: '2px',
      },
      pageLinkSelected: {
        backgroundColor: `${theme.dz.colors.listHover} !important`,
        // transition: '300ms',
      },
      divider: {
        borderColor: 'white',
      },
      settingsLink: {
        marginTop: 'auto',
      },
      icon: {
        color: '#fff',
      },
      toolTip: {
        zIndex: 10000,
      },
    };
  },
  { name: 'dz-app-bar', index: 0 },
);
