import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        dialogContent: {
            overflowY: 'auto',
            position: 'relative',
            '&::-webkit-scrollbar': {
                width: theme.px2rem(8),
                cursor: 'pointer !important',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.grey[300],
                borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#40C5CE',
            },
        },
        linearProgress: {
            position: 'absolute',
            right: 0,
            left: 0,
        },
        hiddenElement: {
            padding: `${theme.px2rem(8)} ${theme.px2rem(16)}`,
            '& button': {
                display: 'none',
            },
        },
        orgData: {
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: '1fr auto 1fr',
            gridGap: theme.px2rem(20),
            marginBottom: theme.px2rem(20),
        },
        orgDataZen: {
            marginBottom: theme.px2rem(10),
        },
        orgDataTitle: {
            fontSize: theme.px2rem(14),
            fontWeight: 500,
        },
        orgDataTitlePaddingLeft: {
            paddingLeft: theme.px2rem(4),
        },
        orgDataTitleZen: {
            fontSize: theme.px2rem(12),
        },
        orgDataValue: {
            fontSize: theme.px2rem(12),
            paddingLeft: 4,
            fontWeight: 400,
        },
        orgDataValueRed: {
            color: theme.dz.colors.error,
        },
        orgDataValueGreen: {
            color: theme.dz.colors.success,
        },
        tabUserWrapper: {
            margin: `0 ${theme.px2rem(13)}`,
        },
        tabContentHeader: {
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: `${theme.px2rem(16)} 0`,
        },
        tabContentHeaderZen: {
            padding: `${theme.px2rem(8)} 0`,
        },
        tabUserContentHeader: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            margin: `0 ${theme.px2rem(13)} ${theme.px2rem(25)}`,
        },
        headerButton: {
            marginRight: theme.px2rem(8),
        },
        headerDivider: {
            marginBottom: theme.px2rem(28),
        },
        tabContent: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minHeight: theme.px2rem(140),
        },
        listItemIcon: {
            minWidth: theme.px2rem(32),
        },
        progressIcon: {
            width: `${theme.px2rem(17.5)} !important`,
            height: `${theme.px2rem(17.5)} !important`,
        },
        ccWrapperIcon: {
            width: theme.px2rem(16),
            height: theme.px2rem(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        ccIcon: {
            width: theme.px2rem(16),
            height: theme.px2rem(16),
        },
        circularProgress: {
            margin: 'auto',
        },
        circularProgressWrapper: {
            marginTop: theme.px2rem(16),
            textAlign: 'center',
        },
        fieldMargin: {
            margin: `0 0 ${theme.px2rem(60)}`,
            width: '100%',
        },
        membershipSalesNotesTitle: {
            fontSize: theme.px2rem(16),
            marginBottom: theme.px2rem(8),
            marginTop: theme.px2rem(8),
            fontWeight: 500,
            color: theme.dz.colors.oneVisionBlack,
            '&:first-of-type': {
                marginTop: 0,
            },
        },
        divider: { margin: `${theme.px2rem(12)} 0` },
        dividerZen: { margin: `${theme.px2rem(20)} 0` },
        projectStage: {
            paddingLeft: 0,
        },
        notesBlock: {
            whiteSpace: 'pre-wrap',
            overflowWrap: 'anywhere',
            fontSize: theme.px2rem(12),
        },
        sectionHeader: {
            fontSize: theme.px2rem(16),
            fontWeight: 500,
            color: theme.black.black50,
            marginBottom: theme.px2rem(10),
        },
        subscriptionCellMembership: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
        },
        pauseIcon: {
            height: theme.px2rem(11),
            width: theme.px2rem(11),
            marginTop: theme.px2rem(-1),
            marginRight: theme.px2rem(2),
        },
        infoIcon: {
            height: theme.px2rem(15),
            width: theme.px2rem(15),
            color: theme.black.black50,
            marginLeft: theme.px2rem(5),
        },
        iconColorBlack65: {
            color: theme.black.black65,
        },
        membershipSalesNote: {
            marginBottom: theme.px2rem(10),
        },
        subscrDetailsTooltip: {
            maxWidth: theme.px2rem(150),
        },
        calendarIcon: {
            width: theme.px2rem(17.5),
            height: theme.px2rem(17.5),
        },
    };
}, { name: 'dz-organization-dialog', index: 0 });
