import { SvgIcon } from '@mui/material';
import React from 'react';
import { useStyles } from './dz-icons.styles';

const DzDescendingSort: React.FC = () => {
  const classes = useStyles();
  return (
    <SvgIcon
      width="14"
      height="14"
      viewBox="0 0 32 32"
      className={classes.svg}
    >
      <path
        id="desc"
        d="M26.667,16L24.787,14.12L17.334,21.56L17.334,5.333L14.667,5.333L14.667,21.56L7.227,14.107L5.334,16L16.001,26.667L26.668,16L26.667,16Z"
        className={classes.path}
      ></path>
    </SvgIcon>
  );
};

export default DzDescendingSort;
