var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Autocomplete, Box, FormControl, FormControlLabel, FormHelperText, InputAdornment, Switch, TextField, Typography, } from '@mui/material';
import { Field, Form } from 'formik';
import { useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { DzDateTimePicker, DzOwnerListOption, } from '../../../../components/shared';
import { ISO_DATE_FORMAT, ISO_DATE_MASK, predefinedWarrantyLengths, } from '../../../../const';
import { DzProjectStage, } from '../../../../types';
import { useFormStyles } from '../../../shared';
const stages = [
    {
        value: DzProjectStage.Lead,
        text: 'Proposal',
        dateName: 'signingDate',
        dateLabel: 'Signing Date',
    },
    {
        value: DzProjectStage.ActiveProject,
        text: 'Active Project',
        dateName: 'estimatedDay1Date',
        dateLabel: 'Estimated Day1 Date',
    },
    {
        value: DzProjectStage.ServiceClient,
        text: 'Service Client',
        dateName: 'day1StartDate',
        dateLabel: 'Day1 Start Date',
    },
];
const MAX_PRICE = 1000000000;
const DzProjectForm = ({ submitCount, values, isPrimary, owners }) => {
    const options = useMemo(() => {
        const unassigned = {
            name: null,
            ownerId: null,
        };
        return [unassigned, ...owners];
    }, [owners]);
    const formClasses = useFormStyles();
    return (_jsxs(Form, Object.assign({ id: "project-form", className: formClasses.formContainer, role: "form" }, { children: [_jsx(Field, Object.assign({ name: "name" }, { children: ({ meta, field }) => (_jsx(TextField, Object.assign({ autoFocus: true, type: "text", id: "name", label: "Project Name" }, field, { error: Boolean((meta.touched || submitCount > 0) && meta.error), fullWidth: true, helperText: (meta.touched || submitCount > 0) && meta.error
                        ? meta.error
                        : undefined }))) })), _jsx(Field, Object.assign({ name: "projectStageId" }, { children: ({ meta, field, form }) => (_jsxs(FormControl, Object.assign({ error: Boolean((meta.touched || submitCount > 0) && meta.error), fullWidth: true }, { children: [_jsx(Autocomplete, { id: "projectStageId", autoHighlight: true, openOnFocus: true, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Project Stage", margin: "none" }))), options: stages, getOptionLabel: (option) => option.text, isOptionEqualToValue: (option) => option.value === field.value, onChange: (_, newValues) => form.setFieldValue('projectStageId', newValues === null || newValues === void 0 ? void 0 : newValues.value), onBlur: form.handleBlur }), (meta.touched || submitCount > 0) && meta.error && (_jsx(FormHelperText, { children: meta.error }))] }))) })), _jsx(Field, Object.assign({ name: "price" }, { children: (_a) => {
                    var _b = _a.field, { onChange: _ } = _b, fieldRest = __rest(_b, ["onChange"]), { form } = _a;
                    return (_jsx(NumberFormat, Object.assign({ fullWidth: true, id: "price", label: "Project Price", placeholder: "xxxx.xx", type: "text" }, fieldRest, { customInput: TextField, InputProps: {
                            startAdornment: (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: `$` }))),
                        }, isNumericString: true, thousandSeparator: true, fixedDecimalScale: true, decimalScale: 2, isAllowed: ({ floatValue }) => (floatValue || 0) <= MAX_PRICE, onValueChange: (values) => {
                            form.setFieldValue('price', values.floatValue);
                        } })));
                } })), values.projectStageId && (_jsx(Field, Object.assign({ name: stages[values.projectStageId - 1].dateName }, { children: ({ field: { value, name }, form, meta, }) => (_jsx(DzDateTimePicker, { onBlur: form.handleBlur, inputFormat: ISO_DATE_FORMAT, mask: ISO_DATE_MASK, name: name, value: value || null, error: meta.touched || submitCount > 0 ? meta.error : '', label: values.projectStageId
                        ? stages[values.projectStageId - 1].dateLabel
                        : '', setFieldValue: form.setFieldValue })) }))), _jsx(Field, Object.assign({ name: "warrantyLength" }, { children: ({ meta, field, form }) => (_jsxs(FormControl, Object.assign({ error: Boolean((meta.touched || submitCount > 0) && meta.error), fullWidth: true }, { children: [_jsx(Autocomplete, { id: "warrantyLength", autoHighlight: true, openOnFocus: true, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Warranty Length", margin: "none" }))), options: predefinedWarrantyLengths, getOptionLabel: (option) => option.text, isOptionEqualToValue: (option) => option.value === field.value, onChange: (_, newValue) => {
                                form.setFieldValue('warrantyLength', newValue === null || newValue === void 0 ? void 0 : newValue.value);
                            }, onBlur: form.handleBlur }), (meta.touched || submitCount > 0) && meta.error && (_jsx(FormHelperText, { children: meta.error }))] }))) })), _jsx(Field, Object.assign({ name: "ownerId" }, { children: ({ field, form }) => {
                    return (_jsx(Autocomplete, { autoHighlight: true, isOptionEqualToValue: (option) => option.ownerId === field.value, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Project Manager", margin: "none" }))), options: options, renderOption: (props, option) => (_createElement(DzOwnerListOption, Object.assign({}, props, { name: option.name, key: option.ownerId }))), getOptionLabel: (option) => option.name || 'Unassigned', onChange: (_, value) => {
                            if (!value) {
                                form.setFieldValue(field.name, null);
                                return;
                            }
                            form.setFieldValue(field.name, value.ownerId);
                        } }));
                } })), values.projectStageId !== DzProjectStage.ServiceClient &&
                isPrimary && (_jsx(Field, Object.assign({ name: "sendEducationEmail", type: "checkbox" }, { children: ({ field }) => (_jsx(FormControlLabel, { className: formClasses.switchFormLabel, control: _jsxs(Box, Object.assign({ className: formClasses.switchWrap }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", component: "div" }, { children: "Opt-in" })), _jsx(Switch, Object.assign({ id: "sendEducationEmail", classes: { root: formClasses.rotatedSwitch }, color: "primary" }, field)), _jsx(Typography, Object.assign({ variant: "subtitle2", component: "div" }, { children: "Opt-out" }))] })), label: _jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: "Send educational emails?" })), labelPlacement: "start" })) })))] })));
};
export default DzProjectForm;
