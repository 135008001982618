import { API } from 'core/api';
import { useState, useEffect, useCallback } from 'react';
import { usePartner } from 'shared/hooks';
import { StripeReportData } from './types';

const ERROR_MESSAGE = "Couldn't fetch the report";

const PAGE_SIZE_FOR_EAGER_FETCH = 30;

interface StripeReportRequestParams {
  status: string;
}

/**
 * The endpoint employs cursor-based pagination but we want to eagerly
 * fetch all rows for the report to offer users page navigation
 */
const useStripeReport = ({ status }: StripeReportRequestParams) => {
  const [data, setData] = useState<StripeReportData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const partner = usePartner();

  const eagerlyLoadReportData = useCallback(async () => {
    if (!partner?.partnerId) {
      return;
    }

    const reportData: StripeReportData[] = [];
    let cursor: string | undefined = undefined;
    setLoading(true);
    try {
      do {
        const { data } = await API.getStripeReportData({
          partnerId: partner.partnerId.toString(),
          status,
          pageSize: PAGE_SIZE_FOR_EAGER_FETCH,
          ...(cursor ? { nextPage: cursor } : {}),
        });

        reportData.push(...data.data.subscriptions);

        cursor = data.data.pagination?.nextPage;
      } while (cursor);

      setData(reportData);
    } catch (error) {
      setError(ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  }, [status, partner?.partnerId]);

  useEffect(() => {
    eagerlyLoadReportData();
  }, [eagerlyLoadReportData, status, partner?.partnerId]);

  return { data, loading, error };
};

export default useStripeReport;
