import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { DzSimpleGridPlaceholderRow } from './dz-simple-grid-placeholder-row.view';
import { useStyles } from './dz-simple-grid.styles';
import { DzActionMenu } from 'shared-ui';
import {
  ColumnDefinitions,
  DzSimpleGridAction,
} from './dz-simple-grid.types';
import { DEFAULT_GRID_ROW_HEIGHT } from 'shared/constants';

interface DzSimpleGridBodyProps<T> {
  isLoading?: boolean;
  visibleRows: T[];
  columnDefs: ColumnDefinitions<T>;
  getRowId?: (row: T) => string | undefined;
  actions?: DzSimpleGridAction<T>[];
}

export const DzSimpleGridBody = <T,>({
  isLoading,
  visibleRows,
  columnDefs,
  getRowId,
  actions,
}: DzSimpleGridBodyProps<T>) => {
  const classes = useStyles();

  if (isLoading) {
    return (
      <TableBody className={classes.tableBody}>
        <DzSimpleGridPlaceholderRow columnsCount={columnDefs.length} />
        <DzSimpleGridPlaceholderRow columnsCount={columnDefs.length} />
        <DzSimpleGridPlaceholderRow columnsCount={columnDefs.length} />
        <DzSimpleGridPlaceholderRow columnsCount={columnDefs.length} />
        <DzSimpleGridPlaceholderRow columnsCount={columnDefs.length} />
      </TableBody>
    );
  }

  return (
    <TableBody className={classes.tableBody}>
      {visibleRows.map((row, i) => {
        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={getRowId?.(row) || i}
            className={classes.tableRow}
          >
            {columnDefs.map((column) => {
              return (
                <TableCell
                  key={column.field}
                  align={column.align || 'left'}
                >
                  {column.cellRenderer
                    ? column.cellRenderer({ data: row })
                    : (row[column.field] as React.ReactNode)}
                </TableCell>
              );
            })}
            {actions && !isLoading && (
              <TableCell className={classes.sticky} align="center">
                <DzActionMenu
                  item={row}
                  actions={actions}
                  paperProps={{
                    style: {
                      maxHeight: DEFAULT_GRID_ROW_HEIGHT * 3,
                      width: '30ch',
                    },
                  }}
                />
              </TableCell>
            )}
          </TableRow>
        );
      })}
    </TableBody>
  );
};
