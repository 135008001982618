import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

const ROW_MARGIN = 3;

interface Input {
  lightSelectedRowId: string | undefined;
}

interface Output {
  activeElementRef: React.LegacyRef<HTMLDivElement>;
  dialogRef: React.RefObject<HTMLDivElement>;
  elementRef: React.RefObject<HTMLDivElement>;
}

export const useProgrammaticScroll = ({
  lightSelectedRowId,
}: Input): Output => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const elementRef = useRef<HTMLDivElement>(null);

  const { ref: activeElementRef, inView } = useInView({
    threshold: 1,
    initialInView: true,
    root: dialogRef.current,
  });

  useEffect(() => {
    if (!dialogRef.current || !elementRef.current) {
      return;
    }
    const { y: dialogY, height: dialogHeight } =
      dialogRef.current.getBoundingClientRect();
    const { y: elementY, height: elementHeight } =
      elementRef.current.getBoundingClientRect();

    if (inView || !dialogRef.current || !elementRef.current) {
      return;
    }

    if (dialogY > elementY) {
      dialogRef.current.scrollTop =
        elementRef.current.offsetTop - ROW_MARGIN;
    } else {
      dialogRef.current.scrollTop =
        elementRef.current.offsetTop +
        elementHeight -
        dialogHeight +
        ROW_MARGIN;
    }
  }, [inView, lightSelectedRowId]);

  return {
    activeElementRef,
    dialogRef,
    elementRef,
  };
};
