import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Skeleton } from '@mui/material';
import { useStyles } from './dz-address-content.styles';
const PlaceholderRow = () => {
    const classes = useStyles();
    return (_jsxs("div", Object.assign({ className: classes.orgData }, { children: [_jsx(Skeleton, { animation: "wave", variant: "text", width: 120, height: 26 }), _jsx(Skeleton, { animation: "wave", variant: "circular", width: 16, height: 16, component: "div" }), _jsx(Skeleton, { animation: "wave", variant: "text", width: 130, height: 26 })] })));
};
const DzAddressContentPlaceholder = () => {
    return (_jsx(_Fragment, { children: new Array(4).fill(0).map((_, i) => (_jsx(PlaceholderRow, {}, i))) }));
};
export default DzAddressContentPlaceholder;
