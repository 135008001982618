import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => ({
    onlyChild: {
        margin: theme.isZendesk ? undefined : '0 auto',
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(1),
        '&:last-child': {
            paddingBottom: theme.spacing(2),
        },
        minHeight: `${theme.px2rem(80)}`,
        minWidth: theme.isZendesk ? undefined : `${theme.px2rem(368)}`,
    },
    icon: {
        height: `${theme.px2rem(24)}`,
        width: `${theme.px2rem(24)}`,
        color: theme.black.black35,
    },
    closeIcon: {
        color: theme.black.black80,
    },
    title: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    closeButton: {
        alignSelf: 'flex-start',
        marginLeft: 'auto',
        padding: '0',
    },
}));
