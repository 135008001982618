import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { DzDialog, withModalManager, } from '../../shared';
import { useFormik } from 'formik';
import { PlanCollapsible } from './dz-plan-collapsible.view';
export const generateFormikKey = (product, fieldName) => {
    return `${product.productId}.${fieldName}`;
};
const DzUpdatePlansDialogView = ({ caption, isOpen, close, clear, productsOffered, brandId, updateMembershipPrices, partnerHasBuyNow = false, }) => {
    const [loading, setIsLoading] = useState(false);
    const handleSubmit = useCallback(async () => {
        formik.submitForm();
    }, []);
    const getInitialValues = () => {
        const initialValues = {};
        productsOffered.forEach((product) => {
            initialValues[product.productId] = {
                productOffered: product.productOffered,
                callForPricing: product.callForPricing,
                monthlyPrice: product.priceInCents / 100,
                annualPrice: product.annualPriceInCents / 100,
                monthlyBuyNow: product.buyNowPriceInCents / 100,
                annualBuyNow: product.annualBuyNowInCents / 100,
            };
        });
        return initialValues;
    };
    const formik = useFormik({
        initialValues: getInitialValues(),
        onSubmit: async (values) => {
            setIsLoading(true);
            await updateMembershipPrices({
                brandId,
                prices: Object.keys(values).map((productId) => {
                    return Object.assign({ productId: Number(productId), productOffered: values[productId].productOffered }, (values[productId].productOffered
                        ? {
                            callForPricing: values[productId].callForPricing,
                            priceInCents: Math.round(values[productId].monthlyPrice * 100),
                            annualPriceInCents: Math.round(values[productId].annualPrice * 100),
                            buyNowPriceInCents: Math.round(values[productId].monthlyBuyNow * 100),
                            annualBuyNowInCents: Math.round(values[productId].annualBuyNow * 100),
                        }
                        : {}));
                }),
            });
            setIsLoading(false);
            close();
        },
    });
    const content = useMemo(() => {
        return (_jsx(_Fragment, { children: productsOffered.map((product) => (_jsx(PlanCollapsible, { formik: formik, partnerHasBuyNow: partnerHasBuyNow, productOffered: product }))) }));
    }, [productsOffered, formik.values]);
    return (_jsx(DzDialog, { isOpen: isOpen, content: content, caption: caption, okButtonText: "Save", onClose: close, onOk: handleSubmit, okButtonDisabled: loading, clear: clear }));
};
export default withModalManager()(DzUpdatePlansDialogView);
