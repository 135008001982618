import { combineReducers } from '@reduxjs/toolkit';
import {
  fetchPartnerConfig,
  fetchPartnerDetails,
  fetchPartners,
  partnerDetailsSlice,
  partnersConfigSlice,
  partnersSlice,
  updatePartnerDetails,
} from './partners.redux';
import { fetchUserDetails, userConfigSlice } from './user.redux';

export const settings = {
  reducer: {
    settings: combineReducers({
      partners: partnersSlice.reducer,
      partnerDetails: partnerDetailsSlice.reducer,
      partnersConfig: partnersConfigSlice.reducer,
      userConfig: userConfigSlice.reducer,
    }),
  },
  actions: {
    ...partnersSlice.actions,
    ...partnerDetailsSlice.actions,
    ...partnersConfigSlice.actions,
    ...userConfigSlice.actions,
  },
  thunks: {
    fetchPartners,
    fetchPartnerDetails,
    updatePartnerDetails,
    fetchPartnerConfig,
    fetchUserDetails,
  },
};
