import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        padding: theme.spacing(1),
    },
    dialogPaper: {
        width: '100%',
        margin: 0,
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(2),
    },
    dialogActions: {
        margin: theme.spacing(2),
        marginTop: 0,
    },
}));
