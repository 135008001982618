import { makeStyles } from '@mui/styles';
export const useAutocompleteStyles = makeStyles((theme) => ({
    popper: {
        minWidth: theme.spacing(37.5),
    },
    paper: {
        margin: '-1px 0',
        border: '1px solid #C4C4C4',
        borderRadius: '0',
    },
    listbox: {
        padding: '0',
    },
    option: {
        minHeight: theme.spacing(6),
        color: theme.palette.primary.main,
        '&[data-focus="true"]': {
            backgroundColor: '#EAF9FA',
        },
        whiteSpace: 'nowrap',
        '&.create:last-child': {
            borderTop: '1px solid #C4C4C4',
            backgroundColor: theme.black.black5,
        },
        '&.create.Mui-focused:last-child': {
            backgroundColor: theme.black.black20,
        },
    },
    inputRoot: {
        padding: `${theme.px2rem(8)} ${theme.px2rem(16)}`,
    },
}));
