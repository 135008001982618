import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../shared';
import { useEffect } from 'react';
export const FETCH_CELL_TRANSCRIPTS_QUERY_KEY = 'FETCH_CELL_TRANSCRIPTS_QUERY_KEY';
const MAX_TRANSCRIPTS_TO_SHOW = 5;
export const useCallTranscripts = (params) => {
    var _a;
    const apiClient = useApiClient();
    const { addressId, onSuccess } = params;
    const { data: response, isLoading, isSuccess, refetch, } = useQuery({
        queryKey: [FETCH_CELL_TRANSCRIPTS_QUERY_KEY, addressId],
        queryFn: () => apiClient.getCallTranscriptionsByAddressId({
            page: {
                limit: MAX_TRANSCRIPTS_TO_SHOW,
                offset: 0,
            },
            filter: {
                addressId,
            },
        }),
        staleTime: 0,
        enabled: !!addressId,
    });
    const data = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data;
    useEffect(() => {
        onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(data !== null && data !== void 0 ? data : []);
    }, [isSuccess, onSuccess]);
    return { data, isLoading, refetch };
};
