import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        margin: theme.spacing(4),
        display: 'flex',
        flexDirection: 'row',
      },
      leftSection: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        border: `1px solid ${theme.black.black20}`,
        height: '80%',
        minWidth: theme.px2rem(600),
        minHeight: theme.px2rem(600),
      },
      rightSection: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(5),
      },
      noHolidays: {
        fontSize: theme.px2rem(14),
        fontWeight: 500,
        color: theme.palette.custom.OV.sys.onSurface,
        margin: `${theme.spacing(3)} 0`,
      },
      header: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      headerName: {
        fontWeight: 700,
        fontSize: theme.px2rem(32),
        color: theme.palette.custom.OV.sys.onSurface,
      },
      headerTimeZone: {
        fontWeight: 400,
        fontSize: theme.px2rem(16),
        color: theme.palette.custom.OV.sys.onSurfaceVariant,
        marginLeft: theme.spacing(1),
        marginBottom: `-${theme.spacing(1)}`,
      },
      addNewButton: {
        width: theme.px2rem(175),
        alignSelf: 'end',
        marginBottom: theme.spacing(3),
      },
      calendar: {
        border: `1px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
      },
      eventBlock: {
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      eventBlockTitle: {
        fontSize: theme.px2rem(20),
        fontWeight: 700,
        color: theme.black.black80,
      },
      eventBlockUnderTitle: {
        fontSize: theme.px2rem(14),
        fontWeight: 400,
        color: theme.palette.custom.OV.sys.onSurface,
        paddingLeft: theme.spacing(1),
      },
      threeDots: {
        color: theme.palette.custom.OV.sys.onSurfaceVariant,
      },
      editIcon: {
        color: theme.palette.custom.OV.sys.onSurfaceVariant,
        marginRight: theme.spacing(2),
      },
      deleteIcon: {
        color: theme.palette.custom.OV.sys.error,
        marginRight: theme.spacing(2),
      },
      listWrapper: {
        maxHeight: theme.px2rem(500),
        overflowY: 'auto',
      },
    };
  },
  { name: 'dz-holidays-tab' },
);
