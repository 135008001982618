import React from 'react';

import { DzUpdateAddressDialog } from './dz-update-address-dialog';
import { UpdateProjectInfoDialog } from './dz-update-project-info-dialog';
import { DzUpdateServiceLevelDialog } from './dz-update-service-level-dialog';

export const DzDialogsView: React.FC = () => (
  <>
    <DzUpdateAddressDialog />
    <DzUpdateServiceLevelDialog />
    <UpdateProjectInfoDialog />
  </>
);
