import React, { useState, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { DzSimpleGridHead } from './dz-simple-grid-head.view';
import { DzSimpleGridBody } from './dz-simple-grid-body.view';
import { DzEmptySimpleGridPlaceholder } from './dz-empty-simple-grid-placeholder.view';
import { useStyles } from './dz-simple-grid.styles';
import {
  ColumnDefinitions,
  DzSimpleGridAction,
} from './dz-simple-grid.types';
import { Order } from './dz-simple-grid.helpers';
import { csn } from '@one-vision/utils';

const PAGE_SIZES = [25, 50, 100];

interface DzSimpleGridProps<T> {
  loading?: boolean;
  rows: T[];
  getRowId?: (row: T) => string | undefined;
  columnDefs: ColumnDefinitions<T>;
  actions?: DzSimpleGridAction<T>[];
  orderBy?: keyof T;
  order?: Order;
  onSortChange?: ({
    order,
    orderBy,
  }: {
    order: Order;
    orderBy: keyof T;
  }) => void;
  unlimitedHeight?: boolean;
  withPagination?: boolean;
  classes?: ReturnType<typeof useStyles>;
  className?: string;
}

export const DzSimpleGrid = <T,>({
  rows,
  loading,
  columnDefs,
  getRowId = defaultIdGetter,
  actions,
  orderBy,
  order,
  onSortChange,
  withPagination = false,
  unlimitedHeight = false,
  classes: customClasses,
  className = '',
}: DzSimpleGridProps<T>) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZES[1]);
  const classes = { ...useStyles(), ...customClasses };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(() => {
    return rows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [rows, page, rowsPerPage]);

  return (
    <div className={className}>
      {visibleRows.length || loading ? (
        <>
          <TableContainer
            className={csn(classes.tableContainer, [
              classes.tableContainerUnlimitedHeight,
              unlimitedHeight,
            ])}
          >
            <Table size={'small'}>
              <DzSimpleGridHead
                order={order}
                orderBy={orderBy}
                onRequestSort={onSortChange}
                rowCount={rows.length}
                columnDefs={columnDefs}
                actions={actions}
              />
              <DzSimpleGridBody
                isLoading={loading}
                visibleRows={visibleRows}
                columnDefs={columnDefs}
                getRowId={getRowId}
                actions={actions}
              />
            </Table>
          </TableContainer>
          {withPagination && (
            <TablePagination
              rowsPerPageOptions={PAGE_SIZES}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      ) : (
        <DzEmptySimpleGridPlaceholder />
      )}
    </div>
  );
};

function defaultIdGetter<T>(item: T): string | undefined {
  if (
    typeof item === 'object' &&
    item &&
    'id' in item &&
    typeof item.id === 'string'
  ) {
    return item.id;
  }
  return undefined;
}
