import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './dz-simple-grid.styles';

export const DzEmptySimpleGridPlaceholder: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.noDataMessageContainer}>
      <Typography>{"There's no data to show yet."}</Typography>
    </Box>
  );
};
