import { createAction, createReducer, combineReducers, createSelector, } from '@reduxjs/toolkit';
import { batchCreate, batchingReducer } from '../reducers/batching';
import { mergePayload, lookupReducer } from '../reducers/handlers';
import { exclude, include, mergeLists } from '../reducers/helpers';
function isListAction(action) {
    var _a;
    return !!((_a = action.meta) === null || _a === void 0 ? void 0 : _a.listId);
}
const listPayload = (payload, listId) => ({
    payload,
    meta: listId ? { listId } : undefined,
});
export const createEntityReducer = ({ entityName, entityIdProperty, idSelector, }) => {
    const prefix = `DZ::${entityName.toUpperCase()}`;
    const upsertMany = createAction(`${prefix}::UPSERT-MANY`, listPayload);
    const update = createAction(`${prefix}::UPDATE`, listPayload);
    const remove = createAction(`${prefix}::REMOVE`, listPayload);
    const updateList = createAction(`${prefix}::UPDATE-LIST`, listPayload);
    const reset = createAction(`${prefix}::RESET`);
    const batch = batchCreate(`${prefix}::BATCH`);
    const initialListState = {
        page: 1,
        perPage: 20,
        lastPage: 1,
        totalCount: 0,
        ids: [],
        isReady: false,
    };
    const entityList = createReducer(initialListState, (builder) => {
        builder
            .addCase(updateList, (state, { payload }) => {
            const page = typeof payload.page === 'number' ? payload.page : state.page;
            let ids = state.ids;
            if (payload.ids) {
                ids =
                    page > 1 ? mergeLists(state.ids, payload.ids) : payload.ids;
            }
            return Object.assign(Object.assign(Object.assign(Object.assign({}, state), { isReady: true }), payload), { page,
                ids });
        })
            .addCase(update, (state, { payload }) => {
            return Object.assign(Object.assign({}, state), { ids: include(state.ids, idSelector(payload)) });
        })
            .addCase(remove, (state, { payload }) => {
            return Object.assign(Object.assign({}, state), { ids: exclude(state.ids, idSelector(payload)) });
        });
    });
    const lists = createReducer({}, (builder) => {
        builder.addMatcher(isListAction, lookupReducer(entityList, (action) => action.meta.listId));
    });
    const initialEntityState = {
        [entityIdProperty]: '',
    };
    const entity = createReducer(initialEntityState, (builder) => {
        builder.addCase(update, mergePayload);
    });
    const initialByIdState = {};
    const byId = createReducer(initialByIdState, (builder) => {
        // @ts-ignore
        builder
            .addCase(update, lookupReducer(entity, (action) => idSelector(action.payload)))
            .addCase(remove, (lookup, { payload }) => {
            delete lookup[idSelector(payload)];
        })
            .addCase(upsertMany, (lookup, { payload }) => {
            payload.forEach((entity) => {
                // eslint-disable-next-line
                // @ts-ignore
                lookup[idSelector(entity)] = Object.assign(Object.assign({}, (lookup[idSelector(entity)] || {})), entity);
            });
        });
    });
    const singleActionReducer = combineReducers({
        byId,
        lists,
    });
    const reducer = batchingReducer(batch.type, singleActionReducer);
    const domain = (_) => _[`${entityName}Entities`];
    const selectAllEntities = createSelector(domain, (_) => (_ === null || _ === void 0 ? void 0 : _.byId) || {});
    const selectAllLists = createSelector(domain, (_) => (_ === null || _ === void 0 ? void 0 : _.lists) || {});
    const selectList = createSelector(selectAllLists, (_, listId) => listId, (listsLookup, listId) => listsLookup[listId] || Object.assign({}, initialListState));
    const selectListReadiness = createSelector(selectList, (list) => list.isReady);
    const selectById = createSelector(selectAllEntities, (_, id) => id, (lookup, id) => lookup[id] || Object.assign({}, initialEntityState));
    const selectEntitiesFromList = createSelector(selectList, selectAllEntities, ({ ids }, lookup) => ids.map((id) => lookup[id] || Object.assign({}, initialEntityState)));
    return {
        reducer,
        actions: {
            update,
            remove,
            updateList,
            reset,
            batch,
            upsertMany,
        },
        selectors: {
            selectAllEntities,
            selectAllLists,
            selectList,
            selectListReadiness,
            selectById,
            selectEntitiesFromList,
        },
    };
};
