import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        maxWidth: '1200px',
        minWidth: '800px',
        display: 'flex',
        padding: theme.spacing(4),
      },
      infoBox: {
        width: theme.px2rem(425),
        height: theme.px2rem(700),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        border: `1px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
      },
      formBox: {
        padding: `${theme.px2rem(26)} 0`,
        width: theme.px2rem(500),
        height: theme.px2rem(700),
        marginLeft: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        border: `1px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
      },
      button: {
        backgroundColor: theme.dz.colors.proactive,
        color: '#fff',
        minWidth: theme.px2rem(259),
        '&:hover': {
          backgroundColor: '#713eb2',
        },
      },
      infoGridContainer: {
        width: '100%',
        padding: theme.px2rem(26),
        borderTop: `1px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
      },
      infoGridContainerHeader: {
        fontWeight: 700,
      },
      infoGridContainerBody: {
        paddingTop: theme.px2rem(14),
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridRowGap: theme.px2rem(6),
        gridColumnGap: 0,
        fontWeight: 500,
      },
      gridRowLabel: {
        fontWeight: 500,
        color: theme.palette.custom.OV.sys.onSurface,
        width: '100%',
      },
      gridRowValue: {
        fontWeight: 400,
        width: '100%',
        color: theme.palette.custom.OV.sys.onSurfaceVariant,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontSize: theme.px2rem(14),
      },
      title: {
        fontWeight: 700,
        color: theme.palette.custom.OV.sys.onSurface,
      },
      formBoxHeader: {
        width: '100%',
        padding: `0 ${theme.px2rem(26)} ${theme.px2rem(26)} ${theme.px2rem(
          26,
        )}`,
      },
      formBoxBody: {
        width: '100%',
        padding: `0 ${theme.px2rem(26)} ${theme.px2rem(26)} ${theme.px2rem(
          26,
        )}`,
      },
      updateButton: {
        color: theme.palette.common.white,
        height: '36px',
        width: theme.px2rem(150),
        marginTop: theme.px2rem(40),
        float: 'right',
      },
      inputGroup: {
        display: 'flex',
        gap: theme.px2rem(20),
      },
      field: {
        width: '100%',
        marginTop: theme.px2rem(20),
      },
      firstField: {
        width: '100%',
      },
      animatedAppearance: {
        opacity: 0,
        animation: '$row-emergence-anim .6s ease-in-out normal forwards',
        transition: theme.transitions.create('background-color'),
      },
      '@keyframes row-emergence-anim': {
        '100%': {
          opacity: 1,
          transform: 'unset',
        },
      },
    };
  },
  { name: 'dz-company-info' },
);
