import { createSlice } from '@reduxjs/toolkit';

import { PartnerDomain } from './types';
import {
  getPartnerDomains,
  GetPartnerDomainsOutput,
} from './get-partner-domains';
import {
  addPartnerDomain,
  AddPartnerDomainOutput,
} from './add-partner-domain';
import {
  deletePartnerDomain,
  DeletePartnerDomainOutput,
} from './delete-partner-domain';

const initialState: PartnerDomain[] = [];

export const partnerDomainsSlice = createSlice({
  name: 'partnerDomains',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      getPartnerDomains.fulfilled,
      (_, action: GetPartnerDomainsOutput) => {
        return action.payload.map((item) => {
          const partnerDomain: PartnerDomain = {
            partnerDomainId: item.id as string,
            partnerId: item.attributes.partnerId,
            domain: item.attributes.domain,
            createdAt: item.attributes.createdAt,
          };

          return partnerDomain;
        });
      },
    );

    builder.addCase(
      addPartnerDomain.fulfilled,
      (state, action: AddPartnerDomainOutput) => {
        const newPartnerDomain: PartnerDomain = {
          ...action.payload.attributes,
          partnerDomainId: action.payload.id as string,
        };
        state.push(newPartnerDomain);
      },
    );

    builder.addCase(
      deletePartnerDomain.fulfilled,
      (state, action: DeletePartnerDomainOutput) => {
        const deletedDomainId = action.payload;

        return state.filter(
          (item) => item.partnerDomainId !== deletedDomainId,
        );
      },
    );
  },
});

export const partnerDomains = {
  reducer: partnerDomainsSlice.reducer,
  actions: partnerDomainsSlice.actions,
  thunks: {
    getPartnerDomains,
    addPartnerDomain,
    deletePartnerDomain,
  },
};
