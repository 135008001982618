import orderBy from 'lodash/orderBy';
import { DzOwner, DzClient, DzAddressShortList } from 'shared-ui';
import { OrganizationsPreparedTableRow } from './types';

interface PrepareRowsInput {
  addresses: DzAddressShortList[];
  owners: DzOwner[];
  clients: DzClient[];
}

export function prepareOrganizationsRows({
  addresses,
  owners,
  clients,
}: PrepareRowsInput): OrganizationsPreparedTableRow[] {
  if (!clients.length || !addresses.length) {
    return [];
  }

  const rows = addresses.map((address): OrganizationsPreparedTableRow => {
    const client = clients.find(
      (client) =>
        address.primaryClientId &&
        address.primaryClientId === client.ovcid,
    );

    const owner = owners.find((currentOwner) => {
      return (
        currentOwner.ownerId && currentOwner.ownerId === address.ownerId
      );
    });

    return {
      ovaid: address.ovaid,
      projectId: address.primaryProjectId,
      ownerId: owner?.ownerId || null,
      address1: address.address1,
      address2: address.address2,
      businessName: address.businessName,
      clientActivatedMembership: address.clientActivatedMembership,
      clientSelectedMembership: address.clientSelectedMembership,
      cppExpDate: address.cppExpDate,
      tosAccepted: address.tosAccepted,
      creditCardProvided: address.creditCardProvided,
      updatedAt: address.updatedAt,
      createdAt: address.createdAt,
      ownerEmail: owner?.email || '',
      ownerName: owner?.name || '',
      firstName: client?.firstName || '',
      lastName: client?.lastName || '',
      projectName: address.primaryProject_name || '',
      projectDay1StartDate: address.primaryProject_day1StartDate || null,
      projectStageId: address.primaryProject_projectStageId || null,
    };
  });

  return orderBy(rows, ['updatedAt', 'createdAt'], ['desc', 'desc']);
}
