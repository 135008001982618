import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { useStyles } from './dz-support-menu.styles';
import { csn } from '@one-vision/utils';
import SchoolIcon from '@mui/icons-material/School';
import {
  DzAsyncDispatch,
  DzContactSupportDialogProps,
  ModalIDs,
  useModalManager,
  useUserConfig,
} from 'shared-ui';
import { actions } from 'core/redux';
import { useDispatch } from 'react-redux';

export default function DzSupportMenu() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { openModal } = useModalManager();
  const dispatch = useDispatch();
  const userConfig = useUserConfig();
  const open = Boolean(anchorEl);

  const handleClick = (event: { currentTarget: Element }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHelpCenterClick = () => {
    window.open('https://support.onevisionresources.com', '_blank');
    handleClose();
  };

  const handlePlatformPulseClick = () => {
    window.open(
      'https://portal.productboard.com/lxrla54di3wve7qmrkdfqsmq/tabs/4-under-consideration',
      '_blank',
    );
    handleClose();
  };

  const handleContactSupportDialog = async () => {
    handleClose();
    await openModal<DzContactSupportDialogProps, undefined>(
      ModalIDs.contactSupport,
      {
        userName: `${userConfig.userDetails.firstName} ${userConfig.userDetails.lastName}`,
        email: userConfig.userDetails.email,
        dispatch: dispatch as DzAsyncDispatch,
        actions,
      },
    );
  };

  return (
    <div>
      <Tooltip
        classes={{
          popper: classes.toolTip,
        }}
        placement="right-start"
        title="Support"
      >
        <Button onClick={handleClick} className={classes.pageIconButton}>
          <HelpOutlineIcon className={classes.icon} />
        </Button>
      </Tooltip>

      <Popover
        classes={{
          paper: csn(classes.popoverOffset, classes.supportToolsPopup),
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableEnforceFocus={true}
      >
        <List component="nav">
          <ListItem
            className={classes.listButton}
            onClick={handleHelpCenterClick}
          >
            <ListItemIcon className={classes.listItemIconRoot}>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary={'Help Center'} />
          </ListItem>

          <ListItem
            className={classes.listButton}
            onClick={handlePlatformPulseClick}
          >
            <ListItemIcon className={classes.listItemIconRoot}>
              <MonitorHeartIcon />
            </ListItemIcon>
            <ListItemText primary={'Platform Pulse'} />
          </ListItem>

          <ListItem
            className={classes.listButton}
            onClick={handleContactSupportDialog}
          >
            <ListItemIcon className={classes.listItemIconRoot}>
              <HelpOutlineIcon />
            </ListItemIcon>
            <ListItemText primary={'Contact Support'} />
          </ListItem>
        </List>
      </Popover>
    </div>
  );
}
