import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        stage: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            width: theme.px2rem(26),
        },
        orgData: {
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: `1fr ${theme.px2rem(26)} 1fr`,
            gridGap: theme.px2rem(20),
        },
        orgDataValue: {
            fontSize: theme.px2rem(12),
        },
    };
}, { name: 'dz-org-address-content', index: 0 });
