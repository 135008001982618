import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      noDataMessageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(3),
      },
      sticky: {
        position: 'sticky',
        right: 0,
        background: theme.palette.custom.OV.sys.surfaceContainerLowest,
        boxShadow: '5px 2px 5px grey',
        padding: 0,
      },
      tableContainer: {
        width: 'inherit',
        maxHeight: 'calc(100vh - 21rem)',
      },
      tableContainerUnlimitedHeight: {
        maxHeight: 'none',
      },
      tableBody: { marginBottom: 5 },
      tableRow: {
        borderBottom: `2px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
        whiteSpace: 'nowrap',
      },
      skeletonCell: { fontSize: '1rem' },
      actionIcon: { marginRight: 5 },
    };
  },
  { name: 'dz-simple-grid' },
);
