import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tooltip, Typography } from '@mui/material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { csn } from '@one-vision/utils';
import { useStyles } from './dz-card-header-name.styles';
import { CopyTooltip } from '../dz-copy-tooltip';
export const DzCardHeaderName = ({ copyText, openText, onClick, hideTooltip, zendesk, }) => {
    const classes = useStyles();
    return (_jsxs(Box, Object.assign({ className: classes.root }, { children: [_jsx(Tooltip, Object.assign({ title: !hideTooltip && openText }, { children: _jsx(Typography, Object.assign({ className: csn(classes.name, classes.hideTooWideName, [classes.underlinedTextOnHover, !hideTooltip], [classes.zendesk, zendesk]), onClick: onClick }, { children: copyText ? copyText : '-' })) })), copyText && (_jsx(CopyTooltip, Object.assign({ textToCopy: copyText, titleToShow: copyText }, { children: _jsx(ContentCopyRoundedIcon, { className: classes.copyIcon }) })))] })));
};
