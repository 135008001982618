export function getUserRules({ user, userGroups, userGroupRules, userGroupRuleValues, }) {
    var _a;
    const result = {};
    (_a = user.relationships.userGroup) === null || _a === void 0 ? void 0 : _a.forEach((userGroupResource) => {
        const userGroup = getEntityById(userGroups, userGroupResource.id);
        if (!userGroup) {
            return;
        }
        const currentGroupRuleValues = getUserRuleValues({
            userGroup,
            userGroupRules,
            userGroupRuleValues,
        });
        Object.assign(result, currentGroupRuleValues);
    });
    return result;
}
function getUserRuleValues({ userGroup, userGroupRules, userGroupRuleValues, }) {
    var _a;
    const result = {};
    (_a = userGroup.relationships.userGroupRuleValue) === null || _a === void 0 ? void 0 : _a.forEach((userGroupRuleValueResource) => {
        const { rule, value } = getUserRuleValue({
            userGroupRuleValueId: userGroupRuleValueResource.id,
            userGroupRules,
            userGroupRuleValues,
        });
        if (rule && value) {
            result[rule] = value === 'TRUE';
        }
    });
    return result;
}
function getUserRuleValue({ userGroupRuleValues, userGroupRules, userGroupRuleValueId, }) {
    const userGroupRuleValue = getEntityById(userGroupRuleValues, userGroupRuleValueId);
    if (!userGroupRuleValue) {
        return {};
    }
    const userGroupRuleResource = userGroupRuleValue.relationships.userGroupRule.pop();
    if (!userGroupRuleResource) {
        console.error(`Couldn't find user group rule for the value with id"${userGroupRuleValue.id}"`);
        return {};
    }
    const userGroupRule = getEntityById(userGroupRules, userGroupRuleResource.id);
    if (!userGroupRule) {
        return {};
    }
    return {
        rule: userGroupRule.id,
        value: userGroupRuleValue.value,
    };
}
export function getPartnerRules({ partner, partnerGroups, partnerGroupRules, partnerGroupRuleValues, }) {
    var _a;
    const result = {};
    (_a = partner.relationships.partnerGroup) === null || _a === void 0 ? void 0 : _a.forEach((partnerGroupResource) => {
        const partnerGroup = getEntityById(partnerGroups, partnerGroupResource.id);
        if (!partnerGroup) {
            return;
        }
        const currentGroupRuleValues = getPartnerRuleValues({
            partnerGroup,
            partnerGroupRules,
            partnerGroupRuleValues,
        });
        Object.assign(result, currentGroupRuleValues);
    });
    return result;
}
function getPartnerRuleValues({ partnerGroup, partnerGroupRules, partnerGroupRuleValues, }) {
    const result = {};
    partnerGroup.relationships.partnerGroupRuleValue.forEach((partnerGroupRuleValueResource) => {
        const { rule, value } = getPartnerRuleValue({
            partnerGroupRuleValueId: partnerGroupRuleValueResource.id,
            partnerGroupRules,
            partnerGroupRuleValues,
        });
        if (rule && value) {
            result[rule] = value;
        }
    });
    return result;
}
function getPartnerRuleValue({ partnerGroupRuleValues, partnerGroupRules, partnerGroupRuleValueId, }) {
    const partnerGroupRuleValue = getEntityById(partnerGroupRuleValues, partnerGroupRuleValueId);
    if (!partnerGroupRuleValue) {
        return {};
    }
    const partnerGroupRuleResource = partnerGroupRuleValue.relationships.partnerGroupRule.pop();
    if (!partnerGroupRuleResource) {
        console.error(`Couldn't find partner group rule for the value with id"${partnerGroupRuleValue.id}"`);
        return {};
    }
    const partnerGroupRule = getEntityById(partnerGroupRules, partnerGroupRuleResource.id);
    if (!partnerGroupRule) {
        return {};
    }
    return {
        rule: partnerGroupRule.id,
        value: partnerGroupRuleValue.value,
    };
}
function getEntityById(list, id) {
    const item = list === null || list === void 0 ? void 0 : list.find((u) => u.id === id);
    if (!item) {
        console.error(`Couldn't entity with id "${id}"`);
        return null;
    }
    return item;
}
