import {
  DzAddress,
  DzAsyncDispatch,
  DzClient,
  DzOwner,
  DzProject,
  DzProjectLostReason,
  getFieldsFromAttributes,
  ClientLabels,
  ClientLabelsListIds,
  JsonApiEntity,
  JsonApiEntityType,
  Projects,
  Addresses,
  ProjectChunk,
  AddressChunk,
  Clients,
  ProjectLostReasons,
  ProjectLostReasonsChunk,
  Owners,
  OwnerChunk,
  ListReducerState,
  helpers,
  ProjectStages,
  IDzProjectStage,
  ProjectStageChunk,
} from 'shared-ui';
import { AnyAction, createSelector } from '@reduxjs/toolkit';
import { API } from 'core/api';
import { ThunkAction } from 'redux-thunk';
import { DzStore } from '.';
import { logError } from '@one-vision/utils';
import { JsonApiError } from 'types';
import { AxiosError } from 'axios';
import { MapLike } from 'typescript';
import { OvMetaInformation } from 'types/api-meta';

export const reducers = {
  projectEntities: Projects.reducer,
  addressEntities: Addresses.reducer,
  ownerEntities: Owners.reducer,
  clientEntities: Clients.reducer,
  projectLostReasonEntities: ProjectLostReasons.reducer,
  clientLabelEntities: ClientLabels.reducer,
  projectStageEntities: ProjectStages.reducer,
};

const selectPopulatedAddress = createSelector(
  (state: DzStore) => state,
  Addresses.selectors.selectById,
  (state, address) => {
    return {
      ...address,
      primaryClient: address?.primaryClientId
        ? Clients.selectors.selectById(state, address.primaryClientId)
        : undefined,
    };
  },
);

const populateProject = createSelector(
  (state: DzStore) => state,
  (_: unknown, project: ProjectChunk) => project,
  (
    state: DzStore,
    project,
  ): ProjectChunk & {
    address?: AddressChunk;
    owner?: OwnerChunk;
    projectLostReason?: ProjectLostReasonsChunk;
    projectStage?: ProjectStageChunk;
  } => ({
    ...project,
    address: project?.ovaid
      ? selectPopulatedAddress(state, project.ovaid.trim())
      : undefined,
    owner: project?.ownerId
      ? Owners.selectors.selectById(
          state,
          project.ownerId as unknown as string,
        )
      : undefined,
    projectLostReason: project?.projectLostReasonId
      ? ProjectLostReasons.selectors.selectById(
          state,
          project.projectLostReasonId as unknown as string,
        )
      : undefined,
    projectStage: project?.projectStageId
      ? ProjectStages.selectors.selectById(
          state,
          project.projectStageId as unknown as string,
        )
      : undefined,
  }),
);

export const selectPopulatedProjectsFromList = createSelector(
  (state: DzStore) => state,
  Projects.selectors.selectEntitiesFromList,
  (state, projects) => {
    return projects.map((project) => populateProject(state, project));
  },
);

const populateProjectFields = (
  id: string,
  project: DzProject,
  relationships: MapLike<{ data: JsonApiEntityType[] }> | undefined,
): DzProject => {
  if (!relationships) {
    return {
      ...project,
      ovprjid: id,
    };
  }
  return {
    ...project,
    ovprjid: id,
    ovaid: relationships.address?.data?.[0]?.id || '',
    ownerId:
      (relationships.owners?.data?.[0]?.id as unknown as string) || null,
    projectLostReasonId: relationships.projectLostReason?.data?.[0]
      ?.id as unknown as number,
  };
};

export const fetchProjects =
  (
    options: Partial<ListReducerState> = {},
    listId?: string,
  ): ThunkAction<void, DzStore, unknown, AnyAction> =>
  async (dispatch: DzAsyncDispatch, getState) => {
    const state = getState();
    const listState = Projects.selectors.selectList(state, listId || '');
    const nextPage = helpers.getNextPage(options.page, listState);

    const limit = options.perPage || listState.perPage;
    const offset = limit * (nextPage - 1);

    delete options.perPage;

    try {
      const { data: responseData } = await API.getProjects({
        include: listState.include,
        search: listState.search,
        ...options,
        page: {
          limit,
          offset,
        },
      });
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const relationshipsDictionary: Record<string, any> = {};

      const {
        data,
        included,
        meta: { page, defaultPage, ...meta },
      } = responseData;

      const { ids, projects } = data.reduce<{
        ids: string[];
        projects: DzProject[];
      }>(
        (acc, { attributes, id, relationships }) => {
          const addressRelationship = relationships?.address;
          if (addressRelationship) {
            const relatedAddress = addressRelationship.data[0];
            const { id, relationships } =
              relatedAddress as JsonApiEntity<unknown>;
            relationshipsDictionary[id] = relationships;
          }
          acc.ids.push(id as string);
          const project = populateProjectFields(
            id as string,
            attributes,
            relationships,
          );
          acc.projects.push(project);
          return acc;
        },
        {
          ids: [],
          projects: [],
        },
      );

      dispatch(
        Projects.actions.batch([
          Projects.actions.updateList(
            {
              ...meta,
              search: meta.search,
              ids,
              page: page ? page.offset / page.limit + 1 : nextPage,
              perPage: page ? +page.limit : listState.perPage,
              lastPage: Math.ceil(
                meta.totalCount / (page ? page.limit : listState.perPage),
              ),
            },
            listId,
          ),
          Projects.actions.upsertMany(projects),
        ]),
      );

      if (!included) {
        return;
      }

      const {
        addresses,
        owners,
        clients,
        projectLostReasons,
        projectStages,
      } = included.reduce<{
        addresses: DzAddress[];
        owners: DzOwner[];
        clients: DzClient[];
        projectLostReasons: DzProjectLostReason[];
        projectStages: IDzProjectStage[];
      }>(
        (acc, include) => {
          switch (include.type) {
            case OvMetaInformation.address.type:
              return {
                ...acc,
                addresses: [
                  ...acc.addresses,
                  {
                    ...(include.attributes as DzAddress),
                    ovaid: include.id as string,
                    primaryClientId:
                      include.relationships?.primaryClient?.data?.[0].id ||
                      null,
                    primaryProjectId:
                      include.relationships?.primaryProject?.data?.[0]
                        .id || null,
                  },
                ],
              };
            case 'User':
              return {
                ...acc,
                owners: [
                  ...acc.owners,
                  {
                    ...(include.attributes as DzOwner),
                    ownerId: include.id as string,
                  },
                ],
              };
            case 'ProjectStage':
              return {
                ...acc,
                projectStages: [
                  ...acc.projectStages,
                  {
                    ...(include.attributes as IDzProjectStage),
                    projectStageId: include.id as number,
                  },
                ],
              };
            case OvMetaInformation.client.type:
              return {
                ...acc,
                clients: [
                  ...acc.clients,
                  {
                    ...(include.attributes as DzClient),
                    ovcid: include.id as string,
                  },
                ],
              };
            case OvMetaInformation.projectLostReason.type:
              return {
                ...acc,
                projectLostReasons: [
                  ...acc.projectLostReasons,
                  {
                    ...(include.attributes as DzProjectLostReason),
                    projectLostReasonId: include.id as string,
                  },
                ],
              };

            default:
              return acc;
          }
        },
        {
          addresses: [],
          owners: [],
          clients: [],
          projectLostReasons: [],
          projectStages: [],
        },
      );

      dispatch(Addresses.actions.upsertMany(addresses));

      dispatch(Owners.actions.upsertMany(owners));

      dispatch(Clients.actions.upsertMany(clients));

      dispatch(ProjectLostReasons.actions.upsertMany(projectLostReasons));
      dispatch(ProjectStages.actions.upsertMany(projectStages));

      return projects;
    } catch (error) {
      const { errors } =
        (error as AxiosError<{ errors: JsonApiError[] }>)?.response
          ?.data || {};
      logError(errors?.[0] || new Error('Project get error'));
    }
  };

export const fetchClientLabels =
  ({ listId }: { listId?: ClientLabelsListIds } = {}) =>
  async (dispatch: DzAsyncDispatch) => {
    try {
      const response = await API.getClientLabels();
      const { data } = response.data;
      const labels = getFieldsFromAttributes(data);

      dispatch(
        ClientLabels.actions.batch([
          ClientLabels.actions.updateList(
            {
              ids: labels.map((el) => el.clientLabelId),
            },
            listId,
          ),
          ...labels.map((label) => ClientLabels.actions.update(label)),
        ]),
      );
    } catch (error) {
      const { errors } =
        (error as AxiosError<{ errors: JsonApiError[] }>)?.response
          ?.data || {};

      logError(errors?.[0] || new Error('Client labels error'));
    }
  };

export const updateProjectThunk =
  (
    projectChanges: Pick<DzProject, 'ovprjid'> & Partial<DzProject>,
  ): ThunkAction<void, DzStore, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const {
        data: { data },
      } = await API.updateProjectJSONapi(projectChanges);

      if (!data.length) {
        return;
      }

      const [{ attributes }] = data;
      dispatch(Projects.actions.update(attributes));
    } catch (error) {
      logError(error);
    }
  };
