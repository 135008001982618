import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { DzAsyncDispatch } from 'shared-ui';
import { actions } from 'core/redux';

export function useShowError() {
  const dispatch = useDispatch<DzAsyncDispatch>();

  return useCallback(
    (error: unknown) => {
      let textToDisplay = 'Error adding domain. Please, try again.';
      if (isDomainAlreadyTaken(error)) {
        textToDisplay = 'Domain already taken';
      }

      dispatch(
        actions.updateSnackbar({
          text: textToDisplay,
          type: 'error',
        }),
      );
    },
    [dispatch],
  );
}
function isDomainAlreadyTaken(error: unknown) {
  const errorMessage = (error as Error)?.message;
  return errorMessage.toLowerCase().includes('domain already taken');
}
