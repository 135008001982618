import { Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './dz-error-boundary.styles';

const DzAppLevelFallback = () => {
  const classes = useStyles();
  return (
    <div className={classes.dzFallback}>
      <svg
        className={classes.dzFallbackIcon}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z"
          fill="#8F9091"
        />
      </svg>
      <Typography
        variant="h4"
        className={classes.dzFallbackMessage}
        role="fallback-message"
      >
        Oops! Something went wrong. Please, reload the page.
      </Typography>
    </div>
  );
};

export default DzAppLevelFallback;
