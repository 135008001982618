import { useCallback, useEffect, useState } from 'react';
import { Group } from './dz-users.users-view';
import { API } from 'core/api';
import { DzAsyncDispatch, JsonApiUser, UserRelations } from 'shared-ui';
import {
  UserGroupJsonApiEntity,
  UserGroupRuleJsonApiEntity,
  UserGroupRuleValueJsonApiEntity,
} from '@one-vision/json-api-parser';
import { useDispatch } from 'react-redux';
import { logError } from '@one-vision/utils';
import { getUserRulesWithIncluded } from 'core/redux/owners.redux';
import { actions } from 'core/redux';

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  ownerId: string;
  userGroups: Group[];
  userGroupRules?: string[];
}

const sortByEmail = (first: User, second: User) => {
  return first.email.localeCompare(second.email);
};

const getUser = (
  data: JsonApiUser[],
  included:
    | UserGroupJsonApiEntity[]
    | UserGroupRuleJsonApiEntity[]
    | UserGroupRuleValueJsonApiEntity[],
) => {
  const rules = getUserRulesWithIncluded(data, included);

  return data.map((el) => {
    const ids =
      el.relationships.userGroup?.data?.map((group) => group.id) || [];
    const groups = included?.filter(
      (group) =>
        ids.includes(group.id as string) && group.type === 'UserGroup',
    );
    const userRules =
      rules
        .find((items) => items.userId === el.id)
        ?.rules?.flatMap((userGroup) =>
          userGroup.map((el) => el?.id as string),
        ) || [];
    return {
      firstName: el.attributes.firstName,
      lastName: el.attributes.lastName,
      email: el.attributes.email,
      phoneNumber: el.attributes.phoneNumber,
      ownerId: el.id as string,
      userGroups:
        groups?.map((group) => ({
          id: group.id as string,
          name: group.attributes.name,
          description: group.attributes.description,
          isPublic: group.attributes.isPublic,
        })) || [],
      userGroupRules: userRules,
    };
  });
};

export const useUsers = () => {
  const [allUsers, setAllUsers] = useState<User[]>([]);

  const dispatch = useDispatch<DzAsyncDispatch>();

  const fetchUsers = useCallback(async () => {
    try {
      const {
        data: { data, included },
      } = await API.getUser({
        page: { disabled: true },
        include: [
          UserRelations.partner,
          UserRelations.userGroup,
          'userGroup.userGroupRuleValue.userGroupRule',
        ],
      });

      setAllUsers(getUser(data, included!).sort(sortByEmail));
    } catch (error) {
      logError(error);
      dispatch(
        actions.updateSnackbar({
          type: 'error',
          text: 'Can`t fetch users',
        }),
      );
    }
  }, [dispatch]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const filterUserById = useCallback(
    (userId: string) => {
      setAllUsers((prev) =>
        prev.filter((user) => user.ownerId !== userId),
      );
    },
    [setAllUsers],
  );

  return { fetchUsers, allUsers, filterUserById };
};
