import React from 'react';
import { ReadinessProps } from '../../types';
import { HOC } from '../../types/HOC.types';
import withAdminProps from './withAdminProps';
import withoutAdminProps from './withoutAdminProps';

const DefaultFallback: React.FC = () => null;

const withFallback =
  (hoc: HOC<ReadinessProps & { children?: React.ReactNode }>) =>
  (FallbackComponent: React.ComponentType = DefaultFallback) => {
    const SwitchWrapper: React.FC<
      ReadinessProps & { children?: React.ReactNode }
    > = ({ isReady, children }) => {
      return isReady ? <>{children}</> : <FallbackComponent />;
    };

    return hoc(SwitchWrapper);
  };

export const withAdminRights = withFallback(withAdminProps);
export const withoutAdminRights = withFallback(withoutAdminProps);

export default withFallback;
