import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState, useMemo, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircleOutlined';
import { csn, logRender } from '@one-vision/utils';
import { DzUserCard, useModalManager } from '../../shared';
import { DzDisassociateUserView as DzDisassociateUserDialog } from '../../shared/dz-disassociate-user/dz-disassociate-user.view';
import { DzSearchInput } from '../../shared/dz-search-input';
import { useUserMenu } from './useUserMenu';
import { DzUserMenu } from './dz-user-menu';
import { useStyles } from './dz-client-list.styles';
import { Addresses, Clients, ModalIDs } from '../../..';
import { DzUserCardSkeleton } from '../../shared/dz-user-card/dz-user-card-skeleton/dz-user-card-skeleton';
export const DzClientListView = ({ clients, address, allClients, labels, clientLabels, ovaid, primaryClientId, thunks, actions, zendesk, apiClient, dispatch, clientSyncIsLoading, clientsIsLoading, onCardNameClick, }) => {
    logRender(DzClientListView);
    const classes = useStyles();
    const { clientIdToDisassociate, closeDisassociateDialog, userMenuHandler, isTimerRunning, userMenuInfo, handleCloseUserMenu, handleEditUser, handleDisassociateUser, handleCopyID, } = useUserMenu({
        ovaid,
        clients,
        allClients,
        actions,
        labels,
        zendesk,
        thunks,
        dispatch,
    });
    const { openModal } = useModalManager();
    const [searchText, setSearchText] = useState(null);
    const primaryUserHandler = useCallback((client) => {
        dispatch(thunks.updateAddress({
            ovaid,
            changes: {
                primaryClientId: client.ovcid,
            },
        }))
            .unwrap()
            .then(([addressUpdated]) => {
            if (addressUpdated) {
                dispatch(actions.addressUpdated(Object.assign(Object.assign({}, address), addressUpdated)));
                dispatch(Clients.actions.update(client));
                dispatch(Addresses.actions.update({
                    ovaid: addressUpdated.ovaid,
                    primaryClientId: client.ovcid,
                }));
            }
            dispatch(actions.updateSnackbar({
                type: 'success',
                text: 'Primary User Updated',
            }));
        });
    }, [
        ovaid,
        primaryClientId,
        dispatch,
        thunks.updateAddress,
        actions.updateSnackbar,
        actions.addressUpdated,
    ]);
    const sortClients = (clients) => {
        const clientsCopy = [...clients];
        clientsCopy.sort((first, second) => {
            if (first.ovcid === primaryClientId) {
                return -1;
            }
            if (second.ovcid === primaryClientId) {
                return 1;
            }
            if (first.createdAt === second.createdAt) {
                return 0;
            }
            return first.createdAt > second.createdAt ? 1 : -1;
        });
        return clientsCopy;
    };
    const searchHandler = useCallback((text) => {
        setSearchText(text);
    }, [setSearchText]);
    const clientsList = useMemo(() => clients.filter((client) => {
        if (!searchText) {
            return true;
        }
        const searchTextLowerCase = searchText.toLowerCase();
        return Boolean(Object.values(client).find((value) => value === null || value === void 0 ? void 0 : value.toString().toLowerCase().includes(searchTextLowerCase)));
    }), [clients, searchText]);
    useEffect(() => {
        if (!primaryClientId && clientsList.length && ovaid) {
            primaryUserHandler(sortClients(clientsList)[0]);
        }
    }, [primaryClientId, clientsList, ovaid]);
    const handleAddClient = useCallback(async () => {
        const result = await openModal(ModalIDs.addClient, {
            ovaid,
            apiClient,
            actions,
            dispatch,
            existingClientIds: (clients || []).map((client) => client.ovcid),
            thunks,
            allClients,
            labels,
            zendesk,
        });
        if (result)
            dispatch(actions.addedClientToAddress(result));
    }, [openModal, clients, actions, dispatch]);
    return (_jsxs(_Fragment, { children: [_jsx(DzDisassociateUserDialog, { clientID: clientIdToDisassociate, ovaid: ovaid, actions: actions, thunks: thunks, dispatch: dispatch, closeDialog: closeDisassociateDialog }), _jsxs("div", Object.assign({ className: csn(classes.tabContentHeader, [
                    classes.tabContentHeaderZen,
                    zendesk,
                ]) }, { children: [_jsx(Box, Object.assign({ className: csn(classes.searchInputWrapper, [
                            classes.searchInputWrapperZen,
                            zendesk,
                        ]) }, { children: _jsx(DzSearchInput, { placeholder: "Filter results", onTextChange: searchHandler, zendesk: zendesk }) })), _jsx(Button, Object.assign({ color: "primary", onFocus: (event) => event.stopPropagation(), onClick: handleAddClient }, { children: "+ Add User" }))] })), _jsxs("div", Object.assign({ className: csn(classes.tabContent, [
                    classes.emergenceAnimation,
                    clients,
                ]) }, { children: [!clientsIsLoading &&
                        !!clientsList.length &&
                        sortClients(clientsList).map((client) => (_jsx(DzUserCard, { ovaid: ovaid, client: client, labels: labels, clientLabels: clientLabels, primaryClientId: primaryClientId, onPrimaryUserChange: primaryUserHandler, onMenuOpen: userMenuHandler, zendesk: zendesk, apiClient: apiClient, onCardNameClick: onCardNameClick, thunks: thunks, dispatch: dispatch }, client.ovcid))), !clients.length && !clientsIsLoading && (_jsxs("div", Object.assign({ className: classes.noUserWrapper }, { children: [_jsx(AccountCircle, { className: classes.noUserIcon }), _jsx(Typography, Object.assign({ className: classes.noUserText }, { children: "No Users Associated." })), _jsx(Typography, Object.assign({ className: classes.noUserText }, { children: "Please add a user." }))] }))), (clientsIsLoading || clientSyncIsLoading) && (_jsx(DzUserCardSkeleton, {}))] })), _jsx(DzUserMenu, { isTimerRunning: isTimerRunning, userMenuInfo: userMenuInfo, handleCloseUserMenu: handleCloseUserMenu, handleEditUser: handleEditUser, handleDisassociateUser: handleDisassociateUser, handleCopyID: handleCopyID })] }));
};
