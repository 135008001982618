import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
    },
    icon: {
        color: theme.dz.colors.error,
        height: '4rem',
        width: '3.5rem',
    },
    button: {
        backgroundColor: theme.dz.colors.error,
        color: theme.palette.common.white,
    },
}));
