import { combineReducers } from '@reduxjs/toolkit';
import { userSideBarSlice } from './user-side-bar.redux';
import { projectSidebarSlice } from './project-sidebar.redux';
import { updateAddressDialogSlice } from './update-address-dialog.redux';
import { updateProjectInfoDialogSlice } from './update-project-info-dialog.redux';
import {
  addressSidebarSlice,
  addressPanelMiddlewares,
} from './organization-dialog.redux';

import {
  fetchAddress,
  fetchAddressShortList,
  updateAddress,
  updateProject,
  fetchUpdateTechDialogData,
} from './thunks.redux';
import { fetchOrgDialogProjects } from './organization-dialog.redux';
import {
  fetchedAllAddresses,
  addressIsUpdating,
  addressUpdated,
} from './actions';
import { updateServiceLevelDialogSlice } from './update-service-level-dialog.redux';

export const sidebars = {
  reducer: {
    sidebars: combineReducers({
      updateAddressDialog: updateAddressDialogSlice.reducer,
      updateServiceLevelDialog: updateServiceLevelDialogSlice.reducer,
      updateProjectInfoDialog: updateProjectInfoDialogSlice.reducer,
      addressSidebar: addressSidebarSlice.reducer,
      userSidebar: userSideBarSlice.reducer,
      projectSidebar: projectSidebarSlice.reducer,
    }),
  },
  actions: {
    ...updateAddressDialogSlice.actions,
    ...updateServiceLevelDialogSlice.actions,
    ...updateProjectInfoDialogSlice.actions,
    ...addressSidebarSlice.actions,
    ...userSideBarSlice.actions,
    ...projectSidebarSlice.actions,
    fetchedAllAddresses,
    addressIsUpdating,
    addressUpdated,
  },
  thunks: {
    fetchAddress,
    fetchAddressShortList,
    fetchOrgDialogProjects,
    updateAddress,
    updateProject,
    fetchUpdateTechDialogData,
  },
};

export const sidebarsMiddlewares = [...addressPanelMiddlewares];
