import React, { CSSProperties } from 'react';
import { Typography, Chip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Close';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { csn } from '@one-vision/utils';
import ReactDOMServer from 'react-dom/server';
import { MapLike } from 'typescript';

import { applyTheme, themeObject } from '../../../core/theme';
import { useCellContentClass } from './dz-grid-hooks';
import { dzColors, DzOwnerIcon } from 'shared-ui';
import { useStyles } from './dz-grid.styles';
import { ThemeProvider as PortalTheme } from '@one-vision/design-theme';

export const DzCheckBoxCell: React.FC<{
  checked: boolean;
}> = ({ checked }) => {
  const classes = useStyles();
  return checked ? (
    <CheckIcon className={classes.signedTosTrue} />
  ) : (
    <RemoveIcon className={classes.signedTosFalse} />
  );
};

const DzCheckBoxCellBaseThemed = applyTheme(DzCheckBoxCell);

const cache: MapLike<React.ReactElement> = {};
export function getDzCheckBoxRawHtml(checked: boolean) {
  const name = checked ? 'checked' : 'unchecked';

  if (!cache[name]) {
    cache[name] = wrapInDangerouslySetInnerHTML(
      <DzCheckBoxCellBaseThemed checked={checked} />,
    );
  }
  return cache[name];
}

export const ProjectNameWithFlare: React.FC<{
  name: string;
  isPrimary: boolean;
}> = ({ name, isPrimary }) => {
  const classes = useStyles();
  return (
    <>
      {isPrimary && (
        <Chip
          className={classes.primaryChip}
          classes={{ label: classes.chipLabel }}
          label="New"
        />
      )}
      {isPrimary && ' '}
      {name}
    </>
  );
};

// ******** Hint ********

export const DzCellTooltip: React.FC<{
  contentWidth: string;
  primaryText: string;
  secondaryText?: string;
  getTooltipContentClass?: (className: string) => void;
}> = ({
  primaryText,
  secondaryText,
  contentWidth,
  getTooltipContentClass,
}) => {
  const classes = useStyles();
  if (getTooltipContentClass) {
    getTooltipContentClass(classes.tooltipContent);
  }
  return (
    <div
      style={
        {
          '--ov-owner-tooltip-content-size': contentWidth,
        } as CSSProperties
      }
      className={classes.tooltip}
    >
      <Typography>{primaryText}</Typography>
      {secondaryText ? (
        <Typography
          className={classes.toolTipSecondaryText}
          variant="button"
        >
          {secondaryText}
        </Typography>
      ) : null}
    </div>
  );
};

const DzCellTooltipThemed = applyTheme(DzCellTooltip);

export function getDzCellTooltipRawHtml(
  contentWidth: string,
  primaryText: string,
  secondaryText?: string,
) {
  let tooltipContentClass = '';
  const tooltip = (
    <DzCellTooltipThemed
      contentWidth={contentWidth}
      primaryText={primaryText}
      secondaryText={secondaryText}
      getTooltipContentClass={(className) => {
        tooltipContentClass = className;
      }}
    />
  );

  return {
    rawHtml: tooltip,
    tooltipContentClass: tooltipContentClass,
  };
}

// ******** OwnerCell ********

export const DzOwnerCell: React.FC<{
  fullName: string;
}> = ({ fullName }) => {
  const classes = useStyles();
  const contentClass = useCellContentClass();

  return (
    <div
      className={csn(
        contentClass,
        classes.ownerRoot,
        classes.tooltipContent,
      )}
    >
      <DzCellTooltip
        primaryText={fullName ? fullName : `Click to assign`}
        contentWidth="36px"
        secondaryText={fullName ? 'click to change' : undefined}
      />
      <DzOwnerIcon
        name={fullName}
        customClasses={{
          ownerIcon: classes.ownerIcon,
          ownerUnassignedCircle: classes.ownerUnassignedCircle,
        }}
      />
    </div>
  );
};

const DzOwnerCellThemed = applyTheme(DzOwnerCell);

const ownersHtmlCache: MapLike<React.ReactElement> = {};
export function getDzOwnerCellRawHtml(fullName: string) {
  if (!ownersHtmlCache[fullName]) {
    ownersHtmlCache[fullName] = wrapInDangerouslySetInnerHTML(
      <DzOwnerCellThemed fullName={fullName} />,
    );
  }
  return ownersHtmlCache[fullName];
}

// ******** ActionsCell ********

export const DzActionsCell: React.FC = () => {
  const classes = useStyles();
  const contentClass = useCellContentClass();
  return (
    <div id="ov-grid-action-cell">
      <MoreVertRoundedIcon
        className={csn(classes.actionsCell, contentClass)}
      />
    </div>
  );
};

const DzActionsCellThemed = applyTheme(DzActionsCell);

let actionsHtml: null | React.ReactElement = null;
export function getDzActionsCellRawHtml() {
  if (!actionsHtml) {
    actionsHtml = wrapInDangerouslySetInnerHTML(<DzActionsCellThemed />);
  }
  return actionsHtml;
}

// ******** AddressCell ********

export function getAddressCellValue(
  address1: string,
  address2: string | null,
): string {
  return address1 + ' ' + (address2 ? address2 : '');
}

export const DzAddressBadge: React.FC = () => {
  const classes = useStyles();
  return <span className={classes.newAddressBadge}>NEW</span>;
};

const DzAddressBadgeThemed = applyTheme(DzAddressBadge);

let AddressBadgeHtml: null | React.ReactElement = null;
export function getDzAddressBadgeRawHtml() {
  if (!AddressBadgeHtml) {
    AddressBadgeHtml = <DzAddressBadgeThemed />;
  }
  return AddressBadgeHtml;
}

const hoverIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5386 13.8333H1.82541V2.16667H7.682V0.5H1.82541C0.896723 0.5 0.1521 1.25 0.1521 2.16667V13.8333C0.1521 14.75 0.896723 15.5 1.82541 15.5H13.5386C14.4589 15.5 15.2119 14.75 15.2119 13.8333V8H13.5386V13.8333ZM9.35532 0.5V2.16667H12.3589L4.13458 10.3583L5.31427 11.5333L13.5386 3.34167V6.33333H15.2119V0.5H9.35532Z"
      fill={dzColors.listHover}
    />
  </svg>
);

export const DzAddressCell: React.FC<{
  address1: string;
  address2: string | null;
  showBadge: boolean;
}> = ({ address1, address2, showBadge }) => {
  const classes = useStyles();
  const contentClass = useCellContentClass();
  return (
    <div className={csn(classes.addressCell, contentClass)}>
      {showBadge ? (
        <span className={classes.newAddressBadge}>NEW</span>
      ) : null}
      <div
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {address1 + (address2 ? ', ' + address2 : '')}
      </div>
      <div className={classes.firstColumnHoverIcon}>{hoverIcon}</div>
    </div>
  );
};

const DzAddressCellThemed = applyTheme(DzAddressCell);

const AddressHtmlCache: MapLike<React.ReactElement> = {};
export function getDzAddressCellRawHtml(
  address1: string,
  address2: string | null,
  showBadge = false,
) {
  const key = address1 + '|' + address2 + (showBadge ? '1' : '0');
  if (!AddressHtmlCache[key]) {
    AddressHtmlCache[key] = wrapInDangerouslySetInnerHTML(
      <DzAddressCellThemed
        address1={address1}
        address2={address2}
        showBadge={showBadge}
      />,
    );
  }
  return AddressHtmlCache[key];
}

// ******** Client Cell ********

export function getClientCellValue(
  firstName: string,
  lastName: string,
  email: string,
): string {
  return firstName + ' ' + lastName + ' ' + email;
}

export const wrapInDangerouslySetInnerHTML = (content: JSX.Element) => {
  // dangerouslySetInnerHTML is an ag-grid performance optimization
  // https://www.notion.so/onevision/AG-Grid-Performance-issue-when-scrolling-6561c51bd41143d88e287b269377f278
  return (
    <PortalTheme theme={themeObject}>
      <div
        dangerouslySetInnerHTML={{
          __html: ReactDOMServer.renderToString(content),
        }}
      />
    </PortalTheme>
  );
};
