import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        flexDirection: 'row',
        columnGap: theme.spacing(1),

        maxHeight: theme.isIframe
          ? '100vh'
          : `calc(100vh - ${theme.px2rem(theme.dz.topBar.height)})`,
        minHeight: theme.isIframe
          ? '100vh'
          : `calc(100vh - ${theme.px2rem(theme.dz.topBar.height)})`,
        width: theme.isIframe
          ? '100vw'
          : `calc(100vw - ${theme.px2rem(theme.dz.appBar.width)})`,
        position: 'fixed',
        overflow: 'auto',

        backgroundColor: theme.palette.custom.OV.sys.surfaceBright,
        padding: theme.isIframe ? 0 : theme.spacing(1),
      },
      wrapper: {
        width: '100%',
        maxHeight: theme.isIframe
          ? '100vh'
          : `calc(100Vh - ${theme.px2rem(theme.dz.topBar.height)})`,
        border: `1px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
        borderRadius: '4px',
        backgroundColor:
          theme.palette.custom.OV.sys.surfaceContainerLowest,
      },
      headerName: {
        fontWeight: 700,
        fontSize: theme.px2rem(32),
        color: theme.palette.custom.OV.sys.onSurface,
        marginBottom: 10,
        padding: 20,
        width: '100%',
      },
      filtersContainer: {
        display: 'flex',
        flexDirection: 'row',
      },
      exportButton: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      filterFormControl: { minWidth: 200 },
      controlWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight: theme.spacing(8),
        columnGap: theme.spacing(2),
        padding: theme.spacing(2, 0),
      },
      spinner: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
      },
    };
  },
  { name: 'dz-reports-page' },
);
