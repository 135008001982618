import { makeStyles } from '@mui/styles';
import { DzExtTheme } from 'shared-ui';

export const useStyles = makeStyles(
  (theme: DzExtTheme) => ({
    contentHeader: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `${theme.px2rem(16)} 0`,
      margin: `0 ${theme.px2rem(0)} ${theme.px2rem(25)}`,
    },
    contentHeaderZen: {
      padding: `${theme.px2rem(10)} 0`,
      margin: `0 ${theme.px2rem(5)} ${theme.px2rem(25)}`,
    },
    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      minHeight: theme.px2rem(240),
    },
    tabWrapper: {
      margin: `0 ${theme.px2rem(28)}`,

      '&:before': {
        backgroundColor: '#FF000000',
      },
    },
    tabWrapperZen: {
      margin: `0 ${theme.px2rem(12)}`,
    },
    tabWithCardWrapper: {
      margin: `0 ${theme.px2rem(13)}`,
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
      borderRadius: '4px',
      marginBottom: theme.px2rem(24),
    },
    cardHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: `${theme.px2rem(12)} ${theme.px2rem(15)} ${theme.px2rem(
        12,
      )} ${theme.px2rem(12)}`,
      borderBottom: `2px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
      columnGap: theme.spacing(1),
    },
    cardHeaderZen: {
      padding: `${theme.px2rem(16)} ${theme.px2rem(12)}`,
    },
    cardContent: {
      display: 'flex',
      padding: theme.px2rem(15),
      flexDirection: 'column',
      rowGap: theme.spacing(1.5),
    },
    cardContentZen: {
      padding: `${theme.px2rem(18)} ${theme.px2rem(15)}`,
    },
    cardContentRow: {
      display: 'flex',
      columnGap: theme.spacing(1),
      alignItems: 'center',
    },
  }),
  { name: 'dz-users-sidebar-organizations-placeholder', index: 0 },
);
