import { makeStyles } from '@mui/styles';
import { DzExtTheme } from 'shared-ui';

export const useStyles = makeStyles(
  (theme: DzExtTheme) => {
    return {
      headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      cardHeaderPartly: {
        display: 'flex',
        alignItems: 'center',
      },
      arrowClosed: {
        transform: 'rotate(180deg)',
        animation: '$rotate 0.5s ease-in-out',
      },
      '@keyframes rotate': {
        '0%': {
          transform: 'rotate(0deg)',
        },
        '100%': {
          transform: 'rotate(180deg)',
        },
      },
      arrowOpened: {
        transform: 'rotate(0deg)',
        animation: '$rotate-back 0.5s ease-in-out',
      },
      '@keyframes rotate-back': {
        '0%': {
          transform: 'rotate(180deg)',
        },
        '100%': {
          transform: 'rotate(0deg)',
        },
      },
      userName: {
        fontWeight: 500,
        lineHeight: theme.px2rem(18),
      },
      hideTooWideName: {
        maxWidth: theme.px2rem(250),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      userMenuButton: {
        paddingRight: theme.px2rem(12),
      },
      userIcon: {
        color: theme.black.black35,
        display: 'flex',
        marginRight: 6,
      },
    };
  },
  { name: 'dz-users-sidebar-header-view', index: 0 },
);
