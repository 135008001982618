import { createEntityReducer } from '../reducers/createEntityReducer';
export var ClientLabelsListIds;
(function (ClientLabelsListIds) {
    ClientLabelsListIds["allClientLabels"] = "allClientLabels";
})(ClientLabelsListIds || (ClientLabelsListIds = {}));
export const ClientLabels = createEntityReducer({
    entityName: 'clientLabel',
    entityIdProperty: 'clientLabelId',
    idSelector: (label) => label.clientLabelId,
});
