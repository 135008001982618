import DomainIcon from '@mui/icons-material/Domain';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PanoramaIcon from '@mui/icons-material/Panorama';
import PersonIcon from '@mui/icons-material/Person';
import { Tooltip } from '@mui/material';
import { csn } from '@one-vision/utils';
import { UniqueFieldName } from 'components/shared';
import React, { useCallback, useEffect, useState } from 'react';
import { DzBrand, getBrandName, isResidential } from 'shared-ui';
import { SHOW_LESS, SHOW_MORE, SHOW_MORE_DIVIDER } from 'shared/constants';
import { useStyles } from './dz-global-search-result.styles';
import {
  GlobalSearchAPIAddress,
  GlobalSearchItems,
  GlobalSearchResultTypeKey,
} from './dz-global-search-types';
import {
  getUniqueBlockSettings,
  getRowText,
  getShowMoreParams,
} from './dz-global-search-utils';
import { useFuzzySearch } from './useFuzzySearch';

interface Props {
  results: GlobalSearchItems;
  brands: DzBrand[];
  type: GlobalSearchResultTypeKey;
  activeRowId?: string;
  uniqueFieldName: UniqueFieldName;
  totalCount: number;
  handleShowLess: (key: GlobalSearchResultTypeKey) => void;
  handleShowMore: (key: GlobalSearchResultTypeKey) => void;
  goToResult: (id: string, uniqueFieldName: UniqueFieldName) => void;
  showMoreLoadingKey: GlobalSearchResultTypeKey | null;
  searchValue: string;
  changeUniqueFieldName: (uniqueFieldName: UniqueFieldName | '') => void;
  hoverRow: (id: string) => void;
  unhoverRow: () => void;
  activeElementRef: React.LegacyRef<HTMLDivElement>;
  elementRef: React.LegacyRef<HTMLDivElement>;
  hoveredRowIdRef: { current: string | undefined };
}

export const DzGlobalSearchResult: React.FC<Props> = ({
  results,
  type,
  brands,
  activeRowId,
  uniqueFieldName,
  totalCount,
  handleShowLess,
  handleShowMore,
  goToResult,
  showMoreLoadingKey,
  searchValue,
  hoverRow,
  unhoverRow,
  changeUniqueFieldName,
  activeElementRef,
  elementRef,
  hoveredRowIdRef,
}) => {
  const classes = useStyles();

  const getResultRowIcon = (
    props: Record<string, unknown>,
    type: GlobalSearchResultTypeKey,
    ovbid: string | null | undefined,
    brands: DzBrand[],
  ) => {
    switch (type) {
      case 'clients': {
        return <PersonIcon {...props} />;
      }

      case 'projects': {
        return <PanoramaIcon {...props} />;
      }

      case 'addresses': {
        return (
          <Tooltip title={getBrandName(ovbid, brands)}>
            {isResidential(ovbid, brands) ? (
              <HomeIcon {...props} />
            ) : (
              <DomainIcon {...props} />
            )}
          </Tooltip>
        );
      }

      default: {
        return <HomeIcon {...props} />;
      }
    }
  };

  const {
    isShowMoreVisible,
    hasMoreItemsToShow,
    areMoreItemsShownThanDefault,
  } = getShowMoreParams(results, totalCount);

  const { highlightText } = useFuzzySearch();

  const [hoveredId, setHoveredId] = useState<string | null>(null);

  useEffect(() => {
    const { uniqueFieldName } = getUniqueBlockSettings(type);

    const resultIds = results.map(
      (result) => result[uniqueFieldName as keyof typeof result],
    );

    const isHoveredResultInCurrentGroup =
      (hoveredId && resultIds.includes(hoveredId)) ||
      (activeRowId && resultIds.includes(activeRowId));

    if (isHoveredResultInCurrentGroup) {
      changeUniqueFieldName(uniqueFieldName as UniqueFieldName);
    }
  }, [activeRowId, changeUniqueFieldName, type, hoveredId, results]);

  const onShowLessClick = useCallback(() => {
    handleShowLess(type);
  }, [handleShowLess, type]);

  const onShowMoreClick = useCallback(
    () => handleShowMore(type),
    [handleShowMore, type],
  );

  return (
    <>
      <div className={classes.resultHeader}>
        {getUniqueBlockSettings(type).headerText}
      </div>
      {results.map((result) => {
        const typeCoercedResult = result as GlobalSearchAPIAddress;
        const idKey = uniqueFieldName as 'ovaid';
        const id = typeCoercedResult[idKey];

        const isActive = activeRowId && id === activeRowId;

        return (
          <div
            className={csn(
              classes.resultRow,
              [classes.selectedResultRow, isActive],
              [classes.hoveredResultRow, hoveredId === id],
            )}
            ref={isActive ? activeElementRef : undefined}
            key={id}
            onClick={() => {
              const id = (result as GlobalSearchAPIAddress)[
                uniqueFieldName as 'ovaid'
              ];

              goToResult(id, uniqueFieldName);
            }}
            onMouseEnter={() => {
              setHoveredId(id);
              hoveredRowIdRef.current = id;
              hoverRow(id);
            }}
            onMouseLeave={() => {
              setHoveredId(null);
              hoveredRowIdRef.current = undefined;
              unhoverRow();
            }}
          >
            <div
              className={classes.resultDetails}
              ref={isActive ? elementRef : undefined}
            >
              <div className={classes.resultIconContainer}>
                {getResultRowIcon(
                  {
                    className: csn(classes.icon, [
                      classes.iconSelected,
                      isActive,
                    ]),
                  },
                  type,
                  (result as GlobalSearchAPIAddress).ovbid,
                  brands,
                )}
              </div>
              <div className={classes.resultText}>
                {highlightText(getRowText(type, result), searchValue)}
              </div>
            </div>
            <div
              className={csn(classes.hotkey, [classes.hidden, !isActive])}
            >
              <div className={classes.keyboardIconContainer}>
                <KeyboardReturnIcon className={classes.iconLight} />
              </div>
              <div className={classes.hotkeyText}>to select</div>
            </div>
          </div>
        );
      })}
      {showMoreLoadingKey === type ? (
        <div className={csn(classes.showMoreContainer, classes.showMore)}>
          Loading...
        </div>
      ) : (
        isShowMoreVisible && (
          <div
            className={csn(classes.showMoreContainer)}
            ref={
              activeRowId === `${SHOW_MORE}${SHOW_MORE_DIVIDER}${type}` ||
              activeRowId === `${SHOW_LESS}${SHOW_MORE_DIVIDER}${type}`
                ? elementRef
                : undefined
            }
          >
            <div className={classes.showMoreTextContainer}>
              {hasMoreItemsToShow && (
                <div
                  onClick={onShowMoreClick}
                  className={csn(classes.showMore, [
                    classes.selectedShowMoreContainer,
                    activeRowId ===
                      `${SHOW_MORE}${SHOW_MORE_DIVIDER}${type}`,
                  ])}
                  ref={
                    activeRowId ===
                    `${SHOW_MORE}${SHOW_MORE_DIVIDER}${type}`
                      ? activeElementRef
                      : undefined
                  }
                >
                  Show More ({totalCount - results.length} results)
                </div>
              )}
              {hasMoreItemsToShow && areMoreItemsShownThanDefault && (
                <div className={classes.horizontalDivider}></div>
              )}
              {areMoreItemsShownThanDefault && (
                <div
                  onClick={onShowLessClick}
                  className={csn(classes.showMore, [
                    classes.selectedShowMoreContainer,
                    activeRowId ===
                      `${SHOW_LESS}${SHOW_MORE_DIVIDER}${type}`,
                  ])}
                  ref={
                    activeRowId ===
                    `${SHOW_LESS}${SHOW_MORE_DIVIDER}${type}`
                      ? activeElementRef
                      : undefined
                  }
                >
                  Show Less
                </div>
              )}
            </div>
            <div
              className={csn(classes.hotkey, [
                classes.hidden,
                activeRowId !==
                  `${SHOW_MORE}${SHOW_MORE_DIVIDER}${type}` &&
                  activeRowId !==
                    `${SHOW_LESS}${SHOW_MORE_DIVIDER}${type}`,
              ])}
            >
              <div className={classes.keyboardIconContainer}>
                <KeyboardReturnIcon className={classes.iconLight} />
              </div>
              <div className={classes.hotkeyText}>to select</div>
            </div>
          </div>
        )
      )}
    </>
  );
};
