import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { DzPartnerHoliday } from 'types';
import { useStyles } from './dz-billing.styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import usePaymentPortalUrl from 'shared/hooks/usePaymentPortalUrl';
import { auth } from '@one-vision/login';
import { csn } from '@one-vision/utils';
import {
  DzAsyncDispatch,
  DzCircularProgress,
  usePartnerConfig,
} from 'shared-ui';
import { useDispatch } from 'react-redux';
import { actions } from 'core/redux';

export interface Holiday extends DzPartnerHoliday {
  partnerHolidayId: string;
}

const STRINGS = {
  billingDetails: 'Billing Details',
  infoFirstLine: 'Use our billing portal to manage your account.',
  infoSecondLine:
    'View invoices, update payment methods, and change your billing email.',
  goToPortal: 'Go To Portal',
};

export const DzBilling: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch<DzAsyncDispatch>();
  const partnerConfig = usePartnerConfig();
  const { url, loading, error } = usePaymentPortalUrl({
    partnerId: auth.getPartnerId(),
  });

  useEffect(() => {
    if (error) {
      dispatch(
        actions.updateSnackbar({
          type: 'error',
          text: error,
        }),
      );
    }
  }, [error, dispatch]);

  if (loading) {
    return <DzCircularProgress className={classes.spinner} />;
  }

  if (error || !partnerConfig.accessToCustomSla) {
    return <></>;
  }

  return (
    <Box className={csn(classes.root, classes.animatedAppearance)}>
      <Typography className={classes.headerName}>
        {STRINGS.billingDetails}
      </Typography>
      <Box className={classes.goToPortalBox}>
        <Box className={classes.goToPortalBoxTextContainer}>
          <InfoOutlinedIcon htmlColor="#5D398D" />
          <Box className={classes.goToPortalBoxText}>
            <Typography className={classes.goToPortalBoxMainLine}>
              {STRINGS.infoFirstLine}
            </Typography>
            <Typography>{STRINGS.infoSecondLine}</Typography>
          </Box>
        </Box>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => window.open(`https://${url}`, '_blank')}
        >
          {STRINGS.goToPortal}
        </Button>
      </Box>
    </Box>
  );
};
