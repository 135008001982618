import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      date: {
        marginRight: theme.spacing(1),
      },
    };
  },
  { name: 'dz-users-partner-domain-details' },
);
