import { Divider } from '@mui/material';
import { logError, logRender } from '@one-vision/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  actions,
  thunks,
  useDzSelector,
  useSelectAddressPanel,
  useSelectClientLabels,
} from 'core/redux';
import { useOwner, usePartner, usePartnerDetails } from 'shared/hooks';
import { DzDrawer } from 'components/shared';
import {
  DzAddressContent,
  DzProjectsPageSidePanelHeader,
  DzOrgTabs,
  DzAddress,
  DzAsyncDispatch,
  DzProject,
  useModalManager,
  DzProposalWonProps,
  DzProposalWonResult,
  ModalIDs,
  DzSubmitDayOneProps,
  DzSubmitDayOneResult,
  DzAddressPanelRelatedThunks,
  DzProposalLostProps,
  Projects,
  Addresses,
  DzPartner,
} from 'shared-ui';
import { useSearchParams } from 'react-router-dom';
import { selectOwners } from 'core/redux/owners.redux';
import { useSelectIdentityLabels } from 'core/redux';
import { API } from 'core/api';
import { URL_PARAM_NAMES } from 'shared/constants';
import { AxiosError } from 'axios';
import { JsonApiError } from 'types';
import { submitError } from 'shared';
import { useNavigate } from 'react-router';
import { Routes } from 'core/routes';
import { RequestParams } from 'types';

interface Props {
  checkIsAnyDialogActive?: (isActive: boolean) => void;
}

export const DzAddressSidebar: React.FC<Props> = ({
  checkIsAnyDialogActive,
}) => {
  logRender(DzAddressSidebar);

  const {
    isOpen,
    ovaid,
    address,
    projects,
    clients,
    subscriptionDetails,
    context,
  } = useSelectAddressPanel();
  const contextProject = useDzSelector((state) =>
    Projects.selectors.selectById(state, context?.ovprjid || ''),
  ) as DzProject;
  const owners = useDzSelector(selectOwners);
  const partners = useDzSelector((state) => state.settings.partners);
  const allClients = useDzSelector((state) => state.clients);
  const brands = useDzSelector((state) => state.brands);
  const identitiesLabels = useSelectIdentityLabels();
  const clientLabels = useSelectClientLabels();
  const products = useDzSelector(
    (state) => state.newProjectDialog.products,
  );
  const { openModal } = useModalManager();

  const [searchParams, setSearchParams] = useSearchParams();

  const owner = useOwner();
  const partner = usePartner();
  const { data: details } = usePartnerDetails();
  const memoizedClients = useMemo(() => clients || [], [clients]);

  const dispatch = useDispatch<DzAsyncDispatch>();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    searchParams.delete(URL_PARAM_NAMES.SELECTED);
    setSearchParams([...searchParams]);

    dispatch(
      actions.updateOrganizationDialogState({
        isOpen: false,
      }),
    );
  }, [dispatch, searchParams, setSearchParams]);

  const handleOpen = useCallback(() => {
    searchParams.set(URL_PARAM_NAMES.SELECTED, ovaid);
    setSearchParams([...searchParams]);
    dispatch(
      actions.updateOrganizationDialogState({
        isOpen: true,
      }),
    );
  }, [dispatch, searchParams, setSearchParams, ovaid]);

  const refetchData = useCallback(() => {
    dispatch(
      thunks.fetchAddress({
        ovaid: ovaid,
      }),
    )
      .unwrap()
      .then((addresses: DzAddress[]) => {
        if (addresses.length) {
          dispatch(
            actions.updateOrganizationDialogState({
              address: addresses[0],
            }),
          );
          return;
        }
        handleClose();
      });

    dispatch(
      thunks.fetchOrgDialogProjects({
        ovaid,
      }),
    );
  }, [ovaid, dispatch]);

  useEffect(() => {
    if (!ovaid || !isOpen) {
      return;
    }

    refetchData();
  }, [ovaid, isOpen, refetchData]);

  const [subscrIsLoading, setSubscrIsLoading] = useState(false);

  const clearSubscription = () => {
    dispatch(
      actions.updateOrganizationDialogState({
        subscriptionDetails: null,
      }),
    );
  };

  useEffect(() => {
    if (!address?.chargifySubId) {
      clearSubscription();
      return;
    }

    clearSubscription();
    setSubscrIsLoading(true);
    API.getStripeSubscriptionDetails({ addressId: address.ovaid })
      .then(({ data: { data } }) => {
        dispatch(
          actions.updateOrganizationDialogState({
            subscriptionDetails: data,
          }),
        );
      })
      .catch((errorResponse: AxiosError<{ errors: JsonApiError[] }>) => {
        const { errors } = errorResponse?.response?.data || {};

        logError(errors?.[0] || new Error('Subscription fetch error'));

        submitError({
          error: `Can't get subscription details PV`,
          level: 'minor',
          context: {
            ovaid: address.ovaid,
            chargifySubId: address.chargifySubId,
          },
        });
      })
      .finally(() => {
        setSubscrIsLoading(false);
      });
  }, [address, dispatch]);

  const handleWonProposal = useCallback(async () => {
    if (!address || !contextProject.ovprjid) {
      return;
    }
    const result = await openModal<
      DzProposalWonProps,
      DzProposalWonResult
    >(ModalIDs.proposalWon, {
      proposal: {
        addressId: address.ovaid,
        projectId: contextProject.ovprjid,
        tosAccepted: address.tosAccepted,
        clientSelectedMembership: address.clientSelectedMembership,
        isNew: contextProject.isPrimary,
        cppLength: address.cppLength,
        orgNotes: address.orgNotes || '',
        membershipSalesNotes: address.membershipSalesNotes || '',
        warrantyLength: contextProject.warrantyLength || 0,
        estimatedDay1Date: contextProject.estimatedDay1Date,
        clientActivatedMembership: address.clientActivatedMembership,
      },
      brandId: address.ovbid,
      apiClient: API,
      zendesk: false,
      dispatch,
      actions,
    });
    if (!result) {
      return;
    }
    refetchData();
    const { updatedProject } = result;
    dispatch(Projects.actions.update(updatedProject));
    dispatch(
      actions.updateSnackbar({
        type: 'success',
        text: 'Proposal has been won',
      }),
    );
  }, [contextProject, address, dispatch, refetchData, openModal]);

  const handleSubmitDay1 = useCallback(async () => {
    if (!address || !contextProject.ovprjid) {
      return;
    }
    const result = await openModal<
      DzSubmitDayOneProps,
      DzSubmitDayOneResult
    >(ModalIDs.submitDayOne, {
      addressId: address.ovaid,
      projectId: contextProject.ovprjid,
      estimatedDay1Date: contextProject.estimatedDay1Date || undefined,
      apiClient: API,
      actions,
      dispatch: dispatch as unknown as DzAsyncDispatch,
      thunks: thunks as unknown as DzAddressPanelRelatedThunks,
      partner: partner as DzPartner,
    });

    if (!result) {
      return;
    }
    refetchData();
    const { updatedProject, updatedAddress } = result;

    dispatch(
      actions.updateSnackbar({
        type: 'success',
        text: 'Day1 has been submitted',
      }),
    );

    dispatch(Projects.actions.update(updatedProject));

    if (updatedAddress) {
      dispatch(Addresses.actions.update(updatedAddress));
    }
  }, [contextProject, address, openModal, dispatch, refetchData, partner]);

  const handleLostProposal = useCallback(async () => {
    if (!address || !contextProject.ovprjid) {
      return;
    }
    const updatedProject = await openModal<DzProposalLostProps, DzProject>(
      ModalIDs.proposalLost,
      {
        projectId: contextProject.ovprjid,
        apiClient: API,
      },
    );

    if (!updatedProject) {
      return;
    }
    refetchData();

    dispatch(Projects.actions.update(updatedProject));
    dispatch(
      actions.updateSnackbar({
        type: 'success',
        text: 'Proposal has been lost',
      }),
    );
  }, [contextProject, openModal, address, refetchData, dispatch]);

  const redirectToUser = useCallback(
    (id: string) => {
      navigate(`${Routes.Users}?${RequestParams.Selected}=${id}`);
    },
    [navigate],
  );

  const redirectToProject = useCallback(
    (id: string) => {
      navigate(`${Routes.Projects}?${RequestParams.Selected}=${id}`);
    },
    [navigate],
  );

  const content = useMemo(() => {
    return (
      <>
        <DzAddressContent
          address={address}
          subscriptionDetails={subscriptionDetails}
          owners={owners}
          actions={actions}
          thunks={thunks}
          dispatch={dispatch}
          partner={partner}
          contextProjectStageId={
            contextProject.ovprjid
              ? contextProject.projectStageId
              : undefined
          }
          handleWonProposal={handleWonProposal}
          handleSubmitDay1={handleSubmitDay1}
          handleLostProposal={handleLostProposal}
          partnerDetails={details!}
        />
        <Divider />
        <DzOrgTabs
          address={address}
          ovaid={ovaid}
          subscriptionDetails={subscriptionDetails}
          isLoading={subscrIsLoading}
          clients={clients || []}
          allClients={allClients}
          projects={projects}
          owners={owners}
          labels={identitiesLabels}
          clientLabels={clientLabels}
          actions={actions}
          thunks={thunks}
          owner={owner}
          products={products}
          partner={partner}
          brands={brands}
          dispatch={dispatch}
          apiClient={API}
          checkIsAnyDialogActive={checkIsAnyDialogActive}
          onUserCardNameClick={redirectToUser}
          onProjectCardNameClick={redirectToProject}
          partnerDetails={details!}
        />
      </>
    );
  }, [
    ovaid,
    clients,
    allClients,
    clientLabels,
    address,
    owners,
    projects,
    partner,
    subscrIsLoading,
    subscriptionDetails,
    brands,
    checkIsAnyDialogActive,
    contextProject,
    dispatch,
    handleLostProposal,
    handleWonProposal,
    identitiesLabels,
    owner,
    products,
    handleSubmitDay1,
    redirectToProject,
    redirectToUser,
  ]);

  return (
    <DzDrawer
      isOpen={isOpen}
      showButton={Boolean(ovaid)}
      caption={
        <DzProjectsPageSidePanelHeader
          clients={memoizedClients}
          address={address}
          brands={brands}
          partners={partners}
          actions={actions}
          dispatch={dispatch}
          apiClient={API}
        />
      }
      content={content}
      onClose={handleClose}
      onOpen={handleOpen}
    />
  );
};
