import React, { useCallback, useMemo } from 'react';
import { DzLayoutPage, DzAddressSidebar } from 'components/shared';
import { DzOwnerSelectionPopup } from 'components/shared';
import { useOrganizationsPage } from './useOrganizationsPage';
import { DzPageHeader } from 'components/shared';
import { useSelectAddressPanel } from 'core/redux';
import { exportToCsv } from 'shared/utils/exportToCsv';

export const DzOrganizationsPage: React.FC = () => {
  const {
    views,
    activeView,
    ownerPopupInfo,
    isLoading,
    rows,
    organizationsColumns,
    searchValue,
    searchText,
    favorites,
    activeViewPreSearchList,
    handleViewChange,
    handleHeaderBtnClick,
    handleUpdateOwner,
    handleCloseOwnerSelection,
    handleAddFavorite,
    handleRemoveFavorite,
    onSearch,
    handleCellClick,
    selectNextView,
    selectPrevView,
    handleAddOrganization,
    openSidebar,
    closeSidebar,
    checkIsAnyDialogActive,
    setActiveViewPreSearchListItem,
  } = useOrganizationsPage();

  const defaultColumnDef = useMemo(
    () => ({ onCellClicked: handleCellClick }),
    [handleCellClick],
  );

  const handleExportClick = useCallback(() => {
    const headerRow = ['ID'].concat(
      organizationsColumns.map((column) => column.headerName || ''),
    );

    const dataRows = rows.map((row) => {
      return [row.ovaid].concat(
        organizationsColumns.map<string>(
          ({ toStringRepresentation, cellRenderer }) => {
            return toStringRepresentation
              ? toStringRepresentation({ data: row })
              : (cellRenderer({ data: row }) as string);
          },
        ),
      );
    });

    exportToCsv(
      `${activeView}_organizations`,
      [headerRow].concat(dataRows),
      {
        withTimestamp: true,
      },
    );
  }, [rows, organizationsColumns, activeView]);

  return (
    <DzLayoutPage
      defaultColumnDef={defaultColumnDef}
      isLoading={isLoading}
      onButtonClick={handleHeaderBtnClick}
      preparedColumns={organizationsColumns}
      preparedRows={rows}
      uniqueFieldName="ovaid"
      gridName="organizations"
      isAddButtonEnabled={false}
      searchText={searchText}
      handleCellClick={handleCellClick}
      activeView={activeView}
      selectNextView={selectNextView}
      selectPrevView={selectPrevView}
      openSidebar={openSidebar}
      closeSidebar={closeSidebar}
      layoutHeader={
        <DzPageHeader
          searchValue={searchValue}
          views={views}
          activeView={activeView}
          handleViewChange={handleViewChange}
          onSearch={onSearch}
          favorites={favorites}
          handleAddFavorite={handleAddFavorite}
          handleRemoveFavorite={handleRemoveFavorite}
          onAddButtonClick={handleAddOrganization}
          buttonName="Organization"
          useSelectSidebarPanel={useSelectAddressPanel}
          preSearchList={activeViewPreSearchList}
          setItemToPreSearchList={setActiveViewPreSearchListItem}
          handleExportClick={handleExportClick}
        />
      }
      ownerPopup={
        ownerPopupInfo.row ? (
          <DzOwnerSelectionPopup
            ownerType={'address'}
            anchor={ownerPopupInfo.anchor || undefined}
            id={ownerPopupInfo.row.ovaid}
            ownerId={ownerPopupInfo.row.ownerId || ''}
            addressId={ownerPopupInfo.row.ovaid}
            projectId={ownerPopupInfo.row.projectId || ''}
            onGetOnUpdateAction={handleUpdateOwner}
            onClose={handleCloseOwnerSelection}
          />
        ) : undefined
      }
      layoutSidebar={
        <DzAddressSidebar
          checkIsAnyDialogActive={checkIsAnyDialogActive}
        />
      }
    />
  );
};
