import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
        },
        name: {
            fontWeight: 500,
            fontSize: theme.px2rem(14),
            lineHeight: theme.px2rem(18),
        },
        copyIcon: {
            marginTop: theme.px2rem(3),
            marginLeft: theme.spacing(1),
            fontSize: theme.px2rem(14),
            height: theme.px2rem(25),
            color: theme.dz.colors.oneVisionBlack65,
        },
        underlinedTextOnHover: {
            '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
            },
        },
        hideTooWideName: {
            maxWidth: theme.px2rem(200),
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        zendesk: {
            maxWidth: theme.px2rem(160),
        },
    };
});
