export * from './ui.utils';
export * from './group.utils';
export * from './keyboard-event-helper.utils';
export * from './generateActivationUrl';
export * from './writeToClipboard';
export * from './buildQuery';
export * from './formatting';
export { default as S3Client } from './s3-client';
export { replaceNewLineWithParagraph } from './replaceNewLineWithParagraph';
export const removeKeysWithInvalidValues = (data) => {
    return Object.keys(data).reduce((acc, key) => {
        if (data[key]) {
            acc = Object.assign(Object.assign({}, acc), { [key]: data[key] });
        }
        return acc;
    }, {});
};
export const getFieldsFromAttributes = (labels) => labels.map((el) => (Object.assign({}, el.attributes)));
