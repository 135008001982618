import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {},
      searchInputContainer: {
        padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
        backgroundColor: theme.palette.custom.OV.sys.surfaceContainer,
        position: 'fixed',
        top: theme.px2rem(20),
        left: '35%',
        width: theme.px2rem(400),
        borderRadius: '5px',
      },
      searchInput: {
        padding: theme.px2rem(5),
      },
      partnerIcon: {
        borderRadius: '50%',
        width: 34,
        height: 34,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 0,
        color: 'white',
        fontSize: '11px',
      },
      listPaper: {
        boxShadow: 'none',
      },
    };
  },
  { name: 'dz-partner-selection-dialog' },
);
