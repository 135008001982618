import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DzAsyncDispatch } from 'shared-ui';
import { thunks } from 'core/redux';

export function useAddPartnerDomain() {
  const dispatch = useDispatch<DzAsyncDispatch>();
  const [loading, setLoading] = useState(false);

  const execute = useCallback(
    async (domain: string) => {
      try {
        setLoading(true);
        await dispatch(thunks.addPartnerDomain(domain)).unwrap();
      } finally {
        setLoading(false);
      }
    },
    [dispatch],
  );

  return { addPartnerDomain: execute, loading };
}
