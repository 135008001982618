import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        menuWrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: `${theme.px2rem(16)} 0`,
        },
        rounded: {
            borderRadius: '5px',
        },
        divider: { margin: `${theme.px2rem(12)} 0` },
        dividerZen: { margin: `${theme.px2rem(20)} 0` },
        noMarginTop: {
            marginTop: 0,
        },
        sectionHeader: {
            marginBottom: theme.px2rem(20),
        },
        infoLine: {
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: theme.px2rem(4),
            width: '100%',
            marginBottom: theme.px2rem(20),
            alignItems: 'center',
        },
        infoLineMiddleItem: {
            margin: `0 ${theme.px2rem(30)}`,
        },
        membershipSalesWrapper: {
            display: 'flex',
            flexDirection: 'column',
        },
        membershipSalesNotesHeader: {
            marginBottom: theme.px2rem(10),
        },
    };
}, { name: 'dz-membership-skeleton', index: 0 });
