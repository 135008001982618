import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { csn } from '@one-vision/utils';
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from './dz-circular-progress.styles';
export const DzCircularProgressView = ({ className, timeout, }) => {
    const classes = useStyles();
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        if (!timeout && timeout !== 0) {
            setVisible(true);
            return;
        }
        const timeoutObj = setTimeout(() => setVisible(true), timeout);
        return () => clearTimeout(timeoutObj);
    }, [setVisible]);
    return (_jsx(CircularProgress, { className: csn(classes.root, [classes.visible, visible], className) }));
};
