import { useCallback, useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { HOTKEYS, URL_PARAM_NAMES } from 'shared/constants';
import { useSelectedUrlParam } from 'shared/hooks';

import { bind, unbind } from 'mousetrap';
import { useHotkeysDisable } from './useHotkeysDisable';
import { useListTraversalHotkeys } from 'shared/hooks';

interface Input {
  selectNextView: () => void;
  selectPrevView: () => void;
  openSidebar?: (id: string) => void;
  closeSidebar?: () => void;
  activeView: string;
}

interface Output {
  selectedRowId: string | undefined;
  lightSelectedRowId: string | undefined;
  hoverRow: (id: string) => void;
  unhoverRow: () => void;
  selectRow: (id: string) => void;
  unselectRow: () => void;
  lightUnselectRow: () => void;
  clearAllSelection: () => void;
  setRenderedRowIds: (ids: string[]) => void;
}

export const useRowSelection = ({
  selectNextView,
  selectPrevView,
  openSidebar,
  closeSidebar,
  activeView,
}: Input): Output => {
  const {
    lightSelectedRowId,
    lightSelectRow,
    lightUnselectRow,
    setRenderedRowIds,
    handleKeyDown,
    handleKeyUp,
    hoverRow,
    unhoverRow,
    hoveredRowIdRef,
  } = useListTraversalHotkeys();

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const unselectRow = useCallback(() => {
    searchParams.delete(URL_PARAM_NAMES.SELECTED);

    setSearchParams(searchParams);

    if (closeSidebar) {
      closeSidebar();
    }
  }, [searchParams, setSearchParams, closeSidebar]);

  const selectRow = useCallback(
    (id: string) => {
      searchParams.set(URL_PARAM_NAMES.SELECTED, id);

      setSearchParams([...searchParams]);

      if (openSidebar) {
        openSidebar(id);
      }
    },
    [searchParams, setSearchParams, openSidebar],
  );

  const handleEnter = useCallback(() => {
    if (lightSelectedRowId) {
      selectRow(lightSelectedRowId);

      return;
    }

    if (!hoveredRowIdRef.current) {
      return;
    }

    selectRow(hoveredRowIdRef.current);
  }, [lightSelectedRowId, selectRow]);

  const handleEsc = useCallback(() => {
    const selectedRowId = searchParams.get(URL_PARAM_NAMES.SELECTED);

    if (selectedRowId) {
      unselectRow();
    }

    if (!lightSelectedRowId) {
      lightSelectRow(selectedRowId || undefined);
    }
  }, [searchParams, unselectRow, lightSelectRow, lightSelectedRowId]);

  const handleArrowLeft = useCallback(() => {
    selectPrevView();
  }, [selectPrevView]);

  const handleArrowRight = useCallback(() => {
    selectNextView();
  }, [selectNextView]);

  const [selectedUrlParam] = useSelectedUrlParam();

  const areHotkeysDisabled = useHotkeysDisable();

  const bindHotkeys = useCallback(() => {
    bind(HOTKEYS.ARROW_DOWN, handleKeyDown);
    bind(HOTKEYS.ARROW_UP, handleKeyUp);
    bind(HOTKEYS.ARROW_LEFT, handleArrowLeft);
    bind(HOTKEYS.ARROW_RIGHT, handleArrowRight);
    bind(HOTKEYS.ESC, handleEsc);
    bind(HOTKEYS.ENTER, handleEnter);
  }, [
    handleArrowLeft,
    handleArrowRight,
    handleEnter,
    handleEsc,
    handleKeyDown,
    handleKeyUp,
  ]);

  const unbindHotkeys = () => {
    unbind(HOTKEYS.ARROW_DOWN);
    unbind(HOTKEYS.ARROW_UP);
    unbind(HOTKEYS.ARROW_LEFT);
    unbind(HOTKEYS.ARROW_RIGHT);
    unbind(HOTKEYS.ESC);
    unbind(HOTKEYS.ENTER);
  };

  useEffect(() => {
    if (areHotkeysDisabled) {
      unbindHotkeys();
    } else {
      bindHotkeys();
    }

    return () => {
      unbindHotkeys();
    };
  }, [
    lightSelectedRowId,
    selectNextView,
    selectPrevView,
    handleArrowLeft,
    handleArrowRight,
    handleEnter,
    handleEsc,
    handleKeyDown,
    handleKeyUp,
    areHotkeysDisabled,
    bindHotkeys,
  ]);

  const clearAllSelection = useCallback(() => {
    lightUnselectRow();

    if (closeSidebar) {
      closeSidebar();
    }

    setSearchParams((previousState) => {
      previousState.delete(URL_PARAM_NAMES.SELECTED);
      return [...previousState];
    });
  }, [lightUnselectRow, closeSidebar, setSearchParams]);

  const prevActiveViewRef = useRef(activeView);

  useEffect(() => {
    if (prevActiveViewRef.current === activeView) {
      if (isFirstRender) {
        setIsFirstRender(false);

        const selectedRowId = searchParams.get(URL_PARAM_NAMES.SELECTED);

        if (selectedRowId && openSidebar) {
          openSidebar(selectedRowId);
        }
      }

      return;
    }

    clearAllSelection();
  }, [activeView, isFirstRender]);

  return {
    selectedRowId: selectedUrlParam || undefined,
    lightSelectedRowId,
    selectRow,
    unselectRow,
    hoverRow,
    unhoverRow,
    lightUnselectRow,
    setRenderedRowIds,
    clearAllSelection,
  };
};
