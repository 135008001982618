import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useCallback } from 'react';
import { useStyles } from './dz-disassociate-user.styles';
export const DzDisassociateUserView = ({ clientID, ovaid, closeDialog, actions, thunks, dispatch, }) => {
    const classes = useStyles();
    const handleDisassociate = useCallback(() => {
        if (clientID) {
            const disassociationInfo = {
                addressId: ovaid,
                clientId: clientID,
            };
            dispatch(actions.clientIsDisassociating(disassociationInfo));
            dispatch(thunks.disassociateClient(Object.assign({}, disassociationInfo)))
                .unwrap()
                .then(() => {
                dispatch(actions.clientDisassociated(disassociationInfo));
                dispatch(actions.updateSnackbar({
                    type: 'success',
                    text: 'User disassociated',
                }));
            });
        }
        closeDialog();
    }, [clientID, dispatch, ovaid]);
    return (_jsxs(Dialog, Object.assign({ open: Boolean(clientID), classes: {
            paper: classes.deleteConfirmation,
        }, onClose: closeDialog, disableRestoreFocus: true }, { children: [_jsx(DialogTitle, { children: _jsxs(Box, Object.assign({ display: "flex", flexDirection: "column", alignItems: "center" }, { children: [_jsx(DeleteForeverIcon, { className: classes.deleteIcon }), "Are you sure?"] })) }), _jsx(DialogContent, { children: _jsxs(DialogContentText, Object.assign({ align: "center" }, { children: ["Do you really want to remove this user ", _jsx("br", {}), "from the organization?"] })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ onClick: closeDialog, color: "primary", autoFocus: true }, { children: "Cancel" })), _jsx(Button, Object.assign({ onClick: handleDisassociate, className: classes.deleteButton, variant: "contained", color: "primary" }, { children: "Remove" }))] })] })));
};
