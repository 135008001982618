var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Box, Button, FormControlLabel, Typography, Switch, } from '@mui/material';
import ProjectIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { ISO_DATE_FORMAT } from '../../../../const';
import DzProjectForm from './dz-project-form.view';
import { DzProjectStage, } from '../../../../types';
import { TosStatus } from '../../../../const';
import { csn } from '@one-vision/utils';
import { useFormStyles } from '../../../shared';
import DzSelectedCard from '../../dz-selected-card';
import { useStyles } from './dz-project-step.styles';
import { format } from '@one-vision/date-utils';
const ACTIVE_PROJECT_STAGE_ID = 2;
const getInitial = () => {
    return {
        name: '',
        projectStageId: '',
        price: undefined,
        signingDate: '',
        estimatedDay1Date: global._PROJECT_DATE_ ||
            '',
        day1StartDate: '',
        ownerId: '',
        warrantyLength: undefined,
        sendEducationEmail: false,
        tosAccepted: TosStatus.NO,
    };
};
const makeDateValidation = (projectStage, message) => yup
    .string()
    .nullable()
    .when('projectStageId', {
    is: projectStage,
    then: (schema) => schema.required(message).test({
        name: 'inProperFormat',
        test: (dateString) => {
            const isValidFormat = (dateString === null || dateString === void 0 ? void 0 : dateString.length) === ISO_DATE_FORMAT.length;
            if (!isValidFormat) {
                return false;
            }
            try {
                format(dateString, ISO_DATE_FORMAT);
                return true;
            }
            catch (error) {
                return false;
            }
        },
        message: 'Please, use required date format: yyyy-mm-dd',
    }),
});
const DzProjectStep = ({ handleNext, handleBack, createProject, selectedProject, isPrimary, tosAccepted, owners, deleteProject, zendesk, }) => {
    const classes = Object.assign(Object.assign({}, useFormStyles()), useStyles());
    const validationSchema = useMemo(() => yup.object({
        name: yup.string().required('Field is required'),
        projectStageId: yup.number().required('Field is required'),
        signingDate: makeDateValidation(DzProjectStage.Lead, 'Please select signing date'),
        estimatedDay1Date: makeDateValidation(DzProjectStage.ActiveProject, 'Please select estimated Day1 date'),
        day1StartDate: makeDateValidation(DzProjectStage.ServiceClient, 'Please select Day1 start date'),
        warrantyLength: isPrimary
            ? yup.number().required('Field is required')
            : yup.number(),
    }), []);
    return (_jsxs(_Fragment, { children: [!selectedProject && (_jsx(Formik, Object.assign({ initialValues: Object.assign(Object.assign({}, getInitial()), { sendEducationEmail: isPrimary, tosAccepted: tosAccepted || 'No' }), validationSchema: validationSchema, onSubmit: createProject }, { children: (props) => (_jsxs(_Fragment, { children: [_jsx(DzProjectForm, Object.assign({}, props, { isPrimary: isPrimary, owners: owners })), props.values.projectStageId === ACTIVE_PROJECT_STAGE_ID &&
                            tosAccepted !== TosStatus.YES && (_jsx(Field, Object.assign({ name: "tosAccepted", type: "checkbox" }, { children: (_a) => {
                                var _b = _a.field, { checked, onChange } = _b, field = __rest(_b, ["checked", "onChange"]), { form } = _a;
                                return (_jsx(FormControlLabel, { className: csn(classes.switchFormLabel, [
                                        classes.zendeskSwitchFormLabel,
                                        zendesk,
                                    ]), control: _jsxs(Box, Object.assign({ className: classes.switchWrap }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", component: "div" }, { children: "Accepted" })), _jsx(Switch, Object.assign({ id: "tosAccepted", classes: {
                                                    root: classes.rotatedSwitch,
                                                }, checked: field.value.toLowerCase() ===
                                                    TosStatus.YES.toLowerCase(), onChange: (_, value) => {
                                                    form.setFieldValue('tosAccepted', value ? TosStatus.YES : TosStatus.NO);
                                                } }, field, { color: "primary" })), _jsx(Typography, Object.assign({ variant: "subtitle2", component: "div" }, { children: "Not Accepted" }))] })), label: _jsx(Box, Object.assign({ className: classes.toggleLabel }, { children: "Terms of Service" })), labelPlacement: "start" }));
                            } })))] })) }))), selectedProject && (_jsx(Box, Object.assign({ className: classes.container }, { children: _jsx(DzSelectedCard, { title: selectedProject.name, onClose: deleteProject, Icon: ProjectIcon }) }))), _jsxs(Box, Object.assign({ display: "flex", justifyContent: "space-between" }, { children: [_jsx(Button, Object.assign({ variant: "text", color: "primary", onClick: handleBack }, { children: "Previous" })), _jsx(Button, Object.assign({ variant: "text", color: "primary", type: "submit", form: "project-form", onClick: selectedProject ? handleNext : undefined }, { children: "Next" }))] }))] }));
};
export default DzProjectStep;
