var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { TextField } from '@mui/material';
import NumberFormat from 'react-number-format';
import { DEFAULT_VALID_ERROR } from '.';
import { placeholders } from '..';
export const DzPhoneInputView = (_a) => {
    var { showError, onChange, value, format = '+1 (###) ###-####', label = 'Phone', errorText = DEFAULT_VALID_ERROR, placeholder = placeholders.phone } = _a, other = __rest(_a, ["showError", "onChange", "value", "format", "label", "errorText", "placeholder"]);
    const inputChangeHandler = useCallback((e) => {
        onChange(e.target.value);
    }, [onChange]);
    return (_jsx(NumberFormat, Object.assign({ inputProps: {
            'data-testid': 'input-phone',
        }, fullWidth: true, value: value, placeholder: placeholder, label: label, format: format, mask: "_", customInput: TextField, onChange: inputChangeHandler, error: showError, helperText: showError && errorText }, other)));
};
