import { useEffect, useMemo, useState } from 'react';
import keyBy from 'lodash.keyby';

import {
  FieldDefinition,
  FieldDefinitionsMap,
  FieldErrors,
  Row,
} from '../types';
import { FieldId } from './fieldIdGenerator';

export function useFieldErrors(
  fieldDefinitions: FieldDefinition[],
  rows: Row[],
) {
  const fieldDefsByKey = useFieldDefinitionsByKey(fieldDefinitions);
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

  useEffect(() => {
    const result: FieldErrors = {};

    for (let i = 0; i < rows.length; i++) {
      for (const fieldKey in rows[i]) {
        const fieldId = FieldId.buildId(fieldKey, i);
        const fieldValue = rows[i][fieldKey];
        const fieldDefinition = fieldDefsByKey[fieldKey];

        result[fieldId] =
          fieldNotExists(fieldDefinition) ||
          valueMissing(fieldDefinition, fieldValue) ||
          valueInvalid(fieldDefinition, fieldValue);
      }
    }

    setFieldErrors(result);
  }, [rows, fieldDefsByKey]);

  return fieldErrors;
}

export function useFieldDefinitionsByKey(
  fieldDefinitions: FieldDefinition[],
) {
  return useMemo<FieldDefinitionsMap>(
    () => keyBy(fieldDefinitions, 'key'),
    [fieldDefinitions],
  );
}

function fieldNotExists(fieldDefinition: FieldDefinition) {
  if (!fieldDefinition) {
    return 'Field not found';
  }
}

function valueMissing(
  fieldDefinition: FieldDefinition,
  value: string | undefined,
) {
  if (fieldDefinition.required) {
    const hasValue = !!value?.trim?.()?.length;

    if (!hasValue) {
      return 'Field is required';
    }
  }
}

function valueInvalid(
  fieldDefinition: FieldDefinition,
  value: string | undefined,
) {
  if (fieldDefinition.validate) {
    return fieldDefinition.validate(value);
  }
}
