import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Autocomplete,
  TextField,
  InputAdornment,
  Modal,
  Box,
  Tabs,
  Tab,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { logRender } from '@one-vision/utils';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  KeyboardEvent,
} from 'react';
import { useDispatch } from 'react-redux';
import { LocalStorageManager } from 'shared';

import { auth } from '@one-vision/login';
import { thunks, useDzSelector } from '../../../core/redux';
import { getAbbr, pickColor, DzRelativePopper } from 'shared-ui';
import { useStyles } from './dz-partner-selection-modal.styles';

enum TabIds {
  all = 'all',
  current = 'current',
}

const ESC_KEY_CODE = 27;

export const DzPartnerSelectionModalView: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  logRender(DzPartnerSelectionModalView);

  const classes = useStyles();

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(TabIds.current);

  const partners = useDzSelector(
    (state) => state.settings.partners,
  ).filter((el) => {
    if (activeTab === TabIds.all) return true;

    // If the old data is cached on client side, it may not have the currentPartner field
    // so we display all partners in that case.
    return (
      !('currentPartner' in el) ||
      el.currentPartner.toLowerCase() === 'yes'
    );
  });
  const [filterText] = useState('');

  useEffect(() => {
    if (isOpen && !partners.length) {
      dispatch(thunks.fetchPartners());
    }
  }, [isOpen, dispatch, partners.length]);

  const filteredPartners = useMemo(() => {
    if (filterText) {
      const filterTextLowerCase = filterText.toLowerCase().trim();
      return partners.filter((partner) =>
        partner.name.toLowerCase().includes(filterTextLowerCase),
      );
    }
    return partners;
  }, [filterText, partners]);

  const escPressedHandler = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === ESC_KEY_CODE) {
        onClose();
      }
    },
    [onClose],
  );

  const tabs = useMemo(
    () => [
      {
        isActive: activeTab === TabIds.all,
        value: TabIds.all,
        label: 'All',
      },
      {
        isActive: activeTab === TabIds.current,
        value: TabIds.current,
        label: 'Current',
      },
    ],
    [activeTab],
  );

  const content = useMemo(() => {
    return (
      <>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue as TabIds)}
        >
          {tabs.map((el) => (
            <Tab key={el.label} value={el.value} label={el.label} />
          ))}
        </Tabs>
        <Autocomplete
          popupIcon={null}
          autoHighlight
          open
          disablePortal
          PopperComponent={DzRelativePopper}
          classes={{ paper: classes.listPaper }}
          renderInput={(params) => (
            <TextField
              {...params}
              color="secondary"
              margin="none"
              variant="standard"
              placeholder="Select Partner…"
              classes={{ root: classes.searchInput }}
              onKeyDown={escPressedHandler}
              autoFocus
              InputProps={{
                ...params.InputProps,
                disableUnderline: false,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          options={filteredPartners}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <ListItem
              {...props}
              key={option.partnerId}
              onClick={() => {
                auth.setPartnerId(option.partnerId);
                LocalStorageManager.removeReduxState();
                window.location.reload();
              }}
            >
              <ListItemIcon>
                <Typography
                  className={classes.partnerIcon}
                  style={{
                    backgroundColor: pickColor(option.name),
                  }}
                >
                  {getAbbr(option.name)}
                </Typography>
              </ListItemIcon>
              <ListItemText
                primary={option.name}
                secondary={`${option.numberOfAddresses} organizations`}
              />
            </ListItem>
          )}
          onChange={(_, newValue) => {
            if (newValue) {
              auth.setPartnerId(newValue.partnerId);
              LocalStorageManager.removeReduxState();
              window.location.reload();
            }
          }}
        />
      </>
    );
  }, [filteredPartners, classes, escPressedHandler, activeTab, tabs]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className={classes.searchInputContainer}>{content}</Box>
    </Modal>
  );
};
