import { makeStyles } from '@mui/styles';
export const useFormStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(2),
        padding: `${theme.spacing(2)} 0`,
    },
    inputInfoAdronment: {
        position: 'absolute',
        top: 'calc(50% - .5em)',
        right: theme.spacing(4),
    },
    adornmentTooltip: {
        fontSize: theme.px2rem(14),
    },
    selectInput: {
        display: 'flex',
    },
    switchFormLabel: {
        justifyContent: 'space-between',
        marginLeft: 0,
        width: '99%',
    },
    inputAvatar: {
        width: '22px',
        height: '22px',
        borderRadius: '50%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: theme.px2rem(10),
        margin: `${theme.px2rem(5)} ${theme.px2rem(10)} ${theme.px2rem(5)} ${theme.px2rem(2)}`,
    },
    rotatedSwitch: {
        transform: 'rotate(180deg)',
    },
    switchWrap: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    alertTitle: {
        fontSize: theme.px2rem(14),
        color: theme.palette.custom.OV.sys.onSecondaryFixedVariant,
    },
    alertActions: {
        color: theme.palette.custom.OV.sys.onSecondaryFixedVariant,
    },
    alertBody: {
        fontSize: theme.px2rem(14),
        color: theme.dz.colors.oneVisionBlack65,
    },
    alertActionWrap: {
        display: 'flex',
        justifyContent: 'end',
        marginTop: theme.px2rem(10),
    },
    infoAlertIcon: {
        color: `${theme.palette.secondary.main} !important`,
    },
    infoRootAlert: {
        backgroundColor: '#ECDEFF',
        borderRadius: '12px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
}));
