import { useMemo } from 'react';

import { useDzSelector } from 'core/redux';
import { auth } from '@one-vision/login';

export function useOwner() {
  const owners = useDzSelector((state) => state.owners);
  return useMemo(
    () =>
      Object.values(owners).find((owner) => {
        return owner.cognitoUsername === auth.getUsername();
      }),
    [owners],
  );
}
