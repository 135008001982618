import { useMemo } from 'react';
import { usePartnerConfig } from '..';
// According to 1V-693 requirements.
const MAX_PRJ_STAGE = 3;
export const useComplimentaryPremiumPeriod = (address) => {
    const partnerConfig = usePartnerConfig();
    return useMemo(() => {
        if (!address)
            return { isCppDialogOn: false };
        const userProjectIsAtCorrectStage = partnerConfig.accessToProvisionProjects &&
            (address === null || address === void 0 ? void 0 : address.topStageProject_projectStageId) === MAX_PRJ_STAGE;
        const isCppDialogOn = userProjectIsAtCorrectStage ||
            !partnerConfig.accessToProvisionProjects;
        return { isCppDialogOn };
    }, [address, partnerConfig]);
};
