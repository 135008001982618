import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        deleteConfirmation: {
            padding: theme.px2rem(16),
        },
        deleteIcon: {
            color: theme.dz.colors.error,
            height: theme.px2rem(56),
            width: theme.px2rem(50),
        },
        deleteButton: {
            backgroundColor: theme.dz.colors.error,
            color: theme.palette.common.white,
        },
    };
}, { name: 'dz-remove-field-modal' });
