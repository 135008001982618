import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { logError } from '@one-vision/utils';

import { NewProjectDropDowns } from 'shared-ui';
import { API } from '../../api';

export const fetchNewProjectDropDownData =
  createAsyncThunk<NewProjectDropDowns>(
    'fetchNewProjectDropDownData/get',
    async () => {
      try {
        const response = await API.getNewProjectDropDownData();
        return response.data;
      } catch (error) {
        logError(error);
        return {
          products: {},
        };
      }
    },
  );

const initialState: NewProjectDropDowns = {
  products: {},
};

export const newProjectDialog = createSlice({
  name: 'new-project-dialog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchNewProjectDropDownData.fulfilled,
      (
        state,
        action: ReturnType<typeof fetchNewProjectDropDownData.fulfilled>,
      ) => {
        const { products: productNames } = action.payload;

        return {
          ...state,
          products: productNames,
        };
      },
    );
  },
});
