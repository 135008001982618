import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { URL_PARAM_NAMES } from 'shared/constants';

import { Favorites } from 'shared/hooks/makeUseFavoriteViews';

interface View {
  id: string;
  text: string;
}

interface Output {
  activeView: string;
  views: View[];
  handleViewChange: (viewId: string) => void;
  favorites: Favorites;
  handleAddFavorite: (viewId: string) => void;
  handleRemoveFavorite: (viewId: string) => void;
  selectPrevView: () => void;
  selectNextView: () => void;
}

type GetFavoriteViews = () => {
  favorites: Favorites;
  handleAddFavorite: (viewId: string) => void;
  handleRemoveFavorite: (viewId: string) => void;
};

export const makeUseViews = <UseFavoriteViews extends GetFavoriteViews>(
  pageName: keyof Favorites,
  views: View[],
  useFavoriteViews: UseFavoriteViews,
) =>
  function (): Output {
    const [searchParams, setSearchParams] = useSearchParams();

    const [activeView, setActiveView] = useState<string>(
      searchParams.get(URL_PARAM_NAMES.CURRENT_VIEW) || views[0].id,
    );

    const { favorites, handleAddFavorite, handleRemoveFavorite } =
      useFavoriteViews();

    const handleViewChange = useCallback(
      (viewId: string) => {
        const urlView = searchParams.get(URL_PARAM_NAMES.CURRENT_VIEW);

        if (viewId === urlView) {
          return;
        }

        if (viewId === views[0].id) {
          searchParams.delete(URL_PARAM_NAMES.CURRENT_VIEW);
        } else {
          searchParams.set(URL_PARAM_NAMES.CURRENT_VIEW, viewId);
        }

        setSearchParams([...searchParams]);

        setActiveView(viewId);
      },
      [setActiveView, searchParams, setSearchParams],
    );

    const selectPrevView = useCallback(() => {
      const favoriteViews = favorites[pageName];

      if (!favoriteViews?.length) {
        return;
      }

      const viewIndex = favoriteViews.findIndex(
        (favoriteView) => favoriteView === activeView,
      );

      const previousViewIndex =
        viewIndex > 0 ? viewIndex - 1 : favoriteViews.length - 1;

      handleViewChange(favoriteViews[previousViewIndex]);
    }, [favorites, activeView, handleViewChange]);

    const selectNextView = useCallback(() => {
      const favoriteViews = favorites[pageName];

      if (!favoriteViews?.length) {
        return;
      }

      const viewIndex = favoriteViews.findIndex(
        (favoriteView) => favoriteView === activeView,
      );

      const nextViewIndex =
        viewIndex === favoriteViews.length - 1 ? 0 : viewIndex + 1;

      handleViewChange(favoriteViews[nextViewIndex]);
    }, [favorites, activeView, handleViewChange]);

    return {
      activeView,
      views,
      favorites,
      handleViewChange,
      handleAddFavorite,
      handleRemoveFavorite,
      selectPrevView,
      selectNextView,
    };
  };
