import { MapLike } from 'typescript';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import cloneDeep from 'lodash.clonedeep';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';

import { useStyles } from './dz-grid-editor.styles';
import { Row, FieldDefinition, FieldErrors } from '../types';
import { useFieldErrors } from './useFieldErrors';
import { FieldId } from './fieldIdGenerator';

interface Props {
  fieldDefinitions: FieldDefinition[];
  rows: Row[];
  onChange: (data: Row[]) => void;
  onHasError?: (hasError: boolean) => void;
}

export const DzGridEditor: React.FC<Props> = ({
  fieldDefinitions,
  rows,
  onChange,
  onHasError,
}) => {
  const classes = useStyles();

  const fieldErrors = useFieldErrors(fieldDefinitions, rows);
  useEmitIfHasError(fieldErrors, onHasError);

  const emitFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { index, fieldKey } = FieldId.parseId(event.target.name);
    const clonedData = cloneDeep(rows);
    clonedData[index][fieldKey] = event.target.value;
    onChange(clonedData);
  };

  const [deleteSelection, setDeleteSelection] = useState<MapLike<boolean>>(
    {},
  );
  const toggleDeleteSelection = useToggleDeleteSelection(
    deleteSelection,
    setDeleteSelection,
  );
  const showDeleteButton = Object.keys(deleteSelection).length > 0;
  const deleteSelected = () => {
    setDeleteSelection({});
    onChange(rows.filter((_, i) => !deleteSelection[`delete-${i}`]));
  };

  const addRow = useCallback(() => {
    const clonedData = cloneDeep(rows);
    const newRow: Row = {};
    fieldDefinitions.forEach((field) => {
      newRow[field.key] = '';
    });
    clonedData.push(newRow);
    onChange(clonedData);
  }, [fieldDefinitions, onChange, rows]);

  useInitialEmptyRow(rows, addRow);

  const colSpanForAddRowButton =
    fieldDefinitions.length +
    // +1 to span the checkbox column
    1;

  return (
    <div className={classes.root}>
      {showDeleteButton && (
        <>
          <div className={classes.deleteContainer}>
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteForeverIcon />}
              onClick={deleteSelected}
            >
              Delete selected
            </Button>
          </div>
          <br />
        </>
      )}
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        sx={{ maxHeight: 440 }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                {/* Placeholder for delete selection */}
              </TableCell>
              {fieldDefinitions.map((field, i) => (
                <TableCell key={i}>{field.gridLabel}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i}>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    name={`delete-${i}`}
                    checked={!!deleteSelection[`delete-${i}`]}
                    onChange={toggleDeleteSelection}
                  />
                </TableCell>
                {Object.keys(row).map((fieldKey) => {
                  const fieldId = FieldId.buildId(fieldKey, i);

                  return (
                    <TableCell key={fieldId}>
                      <TextField
                        className={classes.textField}
                        variant="standard"
                        value={row[fieldKey]}
                        name={FieldId.buildId(fieldKey, i)}
                        error={!!fieldErrors[fieldId]}
                        helperText={fieldErrors[fieldId]}
                        onChange={emitFieldChange}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={colSpanForAddRowButton}>
                <Button
                  variant="text"
                  startIcon={<AddIcon />}
                  onClick={addRow}
                >
                  Add
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

function useToggleDeleteSelection(
  deleteSelection: MapLike<boolean>,
  setDeleteSelection: (value: MapLike<boolean>) => void,
) {
  return useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newDeleteSelection = cloneDeep(deleteSelection);

      if (event.target.checked) {
        newDeleteSelection[event.target.name] = true;
      } else {
        delete newDeleteSelection[event.target.name];
      }

      setDeleteSelection(newDeleteSelection);
    },
    [deleteSelection, setDeleteSelection],
  );
}

function useInitialEmptyRow(rows: Row[], addRow: () => void) {
  const executed = useRef(false);

  useEffect(() => {
    if (executed.current) {
      return;
    }

    executed.current = true;
    if (rows.length === 0) {
      addRow();
    }
  }, [addRow, rows.length]);
}

function useEmitIfHasError(
  fieldErrors: FieldErrors,
  onHasError?: (hasError: boolean) => void,
) {
  useEffect(() => {
    const hasFieldWithError = Object.keys(fieldErrors).some(
      (fieldId) => !!fieldErrors[fieldId],
    );

    onHasError?.(hasFieldWithError);
  }, [fieldErrors, onHasError]);
}
