import { createAsyncThunk } from '@reduxjs/toolkit';

import { API } from '../../api';

export const addPartnerDomain = createAsyncThunk(
  'partnerDomains/add',
  async (domain: string) => {
    const { data: fetchData } = await API.createPartnerDomain({
      data: {
        type: 'PartnerDomain',
        attributes: {
          domain,
        },
      },
    });

    return fetchData.data;
  },
);
export type AddPartnerDomainOutput = ReturnType<
  typeof addPartnerDomain.fulfilled
>;
