import React from 'react';
import { csn } from '@one-vision/utils';
import { Skeleton } from '@mui/material';
import { useStyles } from './dz-global-search-dialog.styles';
import { usePhStyles } from './dz-global-search-dialog-placeholder.styles';

export const DzGlobalSearchFooterPlaceholder: React.FC = () => {
  const classes = useStyles();
  const phClasses = usePhStyles();

  return (
    <div className={classes.footerContainer}>
      <div className={classes.footer}>
        <div className={classes.resultNumContainer}>
          <Skeleton
            animation="wave"
            variant="text"
            width={125}
            height={22}
          />
        </div>
        <div className={classes.keysNotice}>
          <div className={classes.noticeText}>
            <Skeleton
              animation="wave"
              variant="text"
              width={90}
              height={22}
            />
          </div>
          <div className={classes.arrowContainer}>
            <div className={csn(classes.arrowUpward, phClasses.arrow)}>
              <Skeleton
                animation="wave"
                variant="circular"
                width={22}
                height={22}
              />
            </div>
            <div className={csn(classes.arrowDownward, phClasses.arrow)}>
              <Skeleton
                animation="wave"
                variant="circular"
                width={22}
                height={22}
              />
            </div>
          </div>

          <div className={classes.noticeText}>
            <Skeleton
              animation="wave"
              variant="text"
              width={65}
              height={22}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
