import React, { ReactElement, useMemo } from 'react';

import { useStyles } from './dz-password-criteria.styles';
import {
  PasswordsValidation,
  TextValidation,
} from './usePasswordValidations';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

export function useTextValidationStyles(
  passwordsValidation: PasswordsValidation,
): ValidationStyles {
  const classes = useStyles();
  const { password1, password2 } = passwordsValidation;

  return useMemo(() => {
    const result: Partial<ValidationStyles> = {};

    for (const key in password1) {
      if (Object.prototype.hasOwnProperty.call(password1, key)) {
        const validationName = key as keyof TextValidation;
        const isValid =
          password1[validationName] && password2[validationName];

        result[validationName] = {
          className: isValid ? classes.valid : classes.invalid,
          icon: <Icon isValid={isValid} />,
        };
      }
    }

    return result as ValidationStyles;
  }, [classes, password1, password2]);
}

export const Icon: React.FC<{ isValid: boolean }> = (props) => {
  const classes = useStyles();

  return props.isValid ? (
    <CheckIcon className={classes.icon} />
  ) : (
    <CloseIcon className={classes.icon} />
  );
};

export type ValidationStyles = Record<
  keyof TextValidation,
  { className: string; icon: ReactElement }
>;
