import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        tabsContainer: {
            padding: 0,
        },
        customTabsContainer: {},
        tab: {
            minWidth: '64px',
            padding: `${theme.spacing(1.5)} 0`,
        },
        emergenceAnimation: {
            opacity: 0,
            animation: '$emergence-anim .5s ease-in-out normal forwards',
        },
        '@keyframes emergence-anim': {
            '100%': {
                opacity: 1,
            },
        },
        tooltip: {
            marginTop: `-${theme.spacing(1)}`,
            backgroundColor: theme.dz.colors.oneVisionBlack80,
            color: theme.palette.common.white,
            fontSize: '0.8em',
            padding: `${theme.spacing(0.6)} ${theme.spacing(1.2)}`,
        },
    };
}, { name: 'dz-tabs' });
