export var PARTNER_GROUP_RULE;
(function (PARTNER_GROUP_RULE) {
    PARTNER_GROUP_RULE["ACCESS_TO_PROVISION_PROJECTS"] = "access_to_provision_projects";
    PARTNER_GROUP_RULE["ACCESS_TO_PRE_SOLD_MEMBERSHIPS"] = "access_to_pre_sold_memberships";
    // This rule is not used anywhere yet,
    // but it is a prerequisite for separation of SLA and Memberships
    // Once UI and endpoints are implemented
    // This rule will control access to the feature
    PARTNER_GROUP_RULE["ACCESS_TO_CUSTOM_SLA"] = "access_to_custom_sla";
    PARTNER_GROUP_RULE["ACCESS_TO_SLA_STRIPE_SYNC"] = "access_to_sla_stripe_sync";
    PARTNER_GROUP_RULE["ACCESS_TO_SUBSCRIPTION_MANAGEMENT"] = "access_to_subscription_management";
    PARTNER_GROUP_RULE["ACCESS_TO_PROVISION_SIGNAL"] = "provision_signal";
    PARTNER_GROUP_RULE["ACCESS_TO_BASE_PLATFORM"] = "base_platform";
})(PARTNER_GROUP_RULE || (PARTNER_GROUP_RULE = {}));
