import { useContext, useMemo } from 'react';
import { DzConfigContext } from './dz-partner-config-context';
// Added this constant for the initial use case of partner configs
// we want to have this value in the api meta info, therefore
// meta info should be available in PVZD as well
// But this will be implemented within the scope of a separate task
const ACCESS_TO_PRE_SOLD_MEMBERSHIPS = 'access_to_pre_sold_memberships';
const ACCESS_TO_CUSTOM_SLA = 'access_to_custom_sla';
const ACCESS_TO_PROVISION_PROJECTS = 'access_to_provision_projects';
const ACCESS_TO_SUBSCRIPTION_MANAGEMENT = 'access_to_subscription_management';
const ACCESS_TO_SUBSCRIPTION_MANAGEMENT_PLUS = 'access_to_subscription_management_plus';
const ACCESS_TO_PROVISION_SIGNAL = 'provision_signal';
export const usePartnerConfig = () => {
    const values = useContext(DzConfigContext);
    return useMemo(() => {
        return {
            accessToPreSoldMemberships: values[ACCESS_TO_PRE_SOLD_MEMBERSHIPS],
            accessToCustomSla: values[ACCESS_TO_CUSTOM_SLA],
            accessToProvisionProjects: values[ACCESS_TO_PROVISION_PROJECTS],
            accessSubscriptionManagement: values[ACCESS_TO_SUBSCRIPTION_MANAGEMENT],
            accessSubscriptionManagementPlus: values[ACCESS_TO_SUBSCRIPTION_MANAGEMENT_PLUS],
            accessToOncallManagement: values[ACCESS_TO_PROVISION_SIGNAL],
            loading: values === null || values === void 0 ? void 0 : values.loading,
        };
    }, [values]);
};
