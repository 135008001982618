import React, { useState } from 'react';
import { Button } from '@mui/material';

import { useStyles } from './dz-csv-import-editor.styles';
import { DzCsvFilePicker } from './dz-csv-file-picker.view';
import { DzGridEditor } from './dz-grid-editor/dz-grid-editor.view';
import { Row, FieldDefinition } from './types';
import { MapLike } from 'typescript';

interface Props<T> {
  gridFields: FieldDefinition[];
  onChange: (change: T[]) => void;
  onError: (details?: string) => void;
  onEditMode?: () => void;
  onEditorHasError?: (hasError: boolean) => void;
}

export const DzCsvImportEditor = <T extends MapLike<string>>({
  gridFields,
  onChange,
  onError,
  onEditMode,
  onEditorHasError,
}: Props<T>): React.ReactElement => {
  const classes = useStyles();
  const [data, setData] = useState<T[]>([]);

  const [shouldShowGridEditor, setShowGridEditor] = useState(false);
  const showGridEditor = () => {
    setShowGridEditor(true);
    onEditMode?.();
  };

  const handleFileData = (fileData: Partial<T>[]) => {
    const data = buildDataFromFile(fileData, gridFields);
    setData(data);
    onChange(data);
    showGridEditor();
  };
  const handleDataChange = (data: Row[]) => {
    setData(data as T[]);
    onChange(data as T[]);
    showGridEditor();
  };

  return (
    <>
      <div className={classes.root}>
        {!shouldShowGridEditor && (
          <div className={classes.buttonsContainer}>
            <DzCsvFilePicker onData={handleFileData} onError={onError} />
            <br />
            <br />
            <Button
              className={classes.manualEntry}
              variant="text"
              onClick={showGridEditor}
            >
              Manual Entry
            </Button>
          </div>
        )}
        {shouldShowGridEditor && (
          <DzGridEditor
            rows={data}
            fieldDefinitions={gridFields}
            onChange={handleDataChange}
            onHasError={onEditorHasError}
          />
        )}
      </div>
    </>
  );
};

function buildDataFromFile<T>(
  fileData: Partial<T>[],
  fieldDefinitions: FieldDefinition[],
): T[] {
  const result: T[] = [];

  for (const fileDataUnit of fileData.map(withKeysInLowerCase)) {
    const resultUnit = {} as T;

    for (const field of fieldDefinitions) {
      resultUnit[field.key as keyof T] =
        fileDataUnit[field.fileLabel as keyof T] || ('' as T[keyof T]);
    }

    result.push(resultUnit as T);
  }

  return result;
}

function withKeysInLowerCase<T>(dataUnit: T): T {
  const result = {} as T;

  for (const key in dataUnit) {
    if (Object.prototype.hasOwnProperty.call(dataUnit, key)) {
      const lowerCaseKey = key.toLowerCase() as keyof T;
      result[lowerCaseKey] = dataUnit[key];
    }
  }

  return result;
}
