import { logRender } from '@one-vision/utils';
import { DzProjectsPage } from 'components/dz-projects-page';
import { thunks } from 'core/redux';
import { Routes as RouteLookup } from 'core/routes';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { DzAppBar } from '../dz-app-bar';
import { DzCookiesSnackbar } from '../dz-cookies-snackbar';
import { DzDialogs } from '../dz-dialogs';
import { DzOrganizationsPage } from '../dz-organizations-page';
import { DzSettingsPage } from '../dz-settings-page';
import {
  DzSnackbar,
  DzAsyncDispatch,
  DzPartnerConfigProvider,
  DzUserConfigProvider,
} from 'shared-ui';
import { useDzSelector, actions } from 'core/redux';
import { DzTopBar } from '../dz-top-bar';
import { DzUsersPage } from '../dz-users-page';
import DzModals from '../dz-modals';
import { useStyles } from './dz-root.styles';
import { ClientLabelsListIds } from 'shared-ui';
import { fetchClientLabels } from 'core/redux/entities';
import BlueprintPage from './BlueprintPage.view';
import { DzReportsPage } from 'components/dz-reports-page';

export const DzRootView: React.FC = () => {
  logRender(DzRootView);

  const classes = useStyles();

  const dispatch = useDispatch();

  const { text, type } = useDzSelector((state) => state.snackbar);
  const partnersConfig = useDzSelector(
    (state) => state.settings.partnersConfig,
  );

  const userConfig = useDzSelector((state) => state.settings.userConfig);

  const navigate = useNavigate();

  const [showProjectsRoute, setShowProjectsRoute] = useState(true);

  useEffect(() => {
    dispatch(thunks.fetchPartnerConfig());
    dispatch(thunks.fetchUserDetails());
    dispatch(thunks.fetchPartners());
    dispatch(thunks.fetchBrands());
    dispatch(thunks.fetchOwners());
    dispatch(thunks.fetchClients({}));
    dispatch(thunks.fetchIdentLabels());
    dispatch(thunks.fetchNewProjectDropDownData());
    dispatch(
      fetchClientLabels({
        listId: ClientLabelsListIds.allClientLabels,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (
      !partnersConfig.loading &&
      !partnersConfig.access_to_provision_projects &&
      location.pathname.includes(RouteLookup.Projects)
    ) {
      navigate(RouteLookup.Organizations, { replace: true });
    }
  }, [navigate, partnersConfig]);

  useEffect(() => {
    if (
      showProjectsRoute &&
      !location.href.includes(`${RouteLookup.Projects}?`)
    ) {
      setShowProjectsRoute(false);
    }
  }, [showProjectsRoute]);

  const isProjectRouteShown =
    showProjectsRoute ||
    (!partnersConfig.loading &&
      partnersConfig.access_to_provision_projects);

  return (
    <DzPartnerConfigProvider
      partnerConfig={
        partnersConfig as Record<string, boolean> & { loading: boolean }
      }
    >
      <DzUserConfigProvider userConfig={userConfig}>
        <div className={classes.root}>
          <DzDialogs />
          <DzTopBar />
          <DzAppBar />

          <Routes>
            <>
              <Route path="/blueprint" element={<BlueprintPage />} />
              {isProjectRouteShown && (
                <Route
                  path={`${RouteLookup.Projects}/*`}
                  element={<Navigate to={RouteLookup.Projects} replace />}
                />
              )}
              <Route
                path={RouteLookup.Settings}
                element={<DzSettingsPage />}
              />

              <Route path={RouteLookup.Users} element={<DzUsersPage />} />

              <Route
                path={RouteLookup.Organizations}
                element={<DzOrganizationsPage />}
              />

              {isProjectRouteShown && (
                <Route
                  path={RouteLookup.Projects}
                  element={<DzProjectsPage />}
                />
              )}

              <Route
                path={RouteLookup.Reports}
                element={<DzReportsPage />}
              />

              <Route
                path="/*"
                element={
                  <Navigate
                    to={
                      !partnersConfig.loading &&
                      !partnersConfig.access_to_provision_projects
                        ? RouteLookup.Organizations
                        : RouteLookup.Projects
                    }
                    replace
                  />
                }
              />
            </>
          </Routes>

          <DzModals />
          <DzSnackbar
            text={text}
            type={type}
            actions={actions}
            dispatch={dispatch as DzAsyncDispatch}
          />
          <DzCookiesSnackbar />
        </div>
      </DzUserConfigProvider>
    </DzPartnerConfigProvider>
  );
};
