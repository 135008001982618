import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        icons: {
            marginRight: theme.px2rem(11),
            display: 'flex',
            '& svg': {
                height: theme.px2rem(25),
                width: '25px',
                fill: 'hsl(240deg 1% 70%)',
            },
        },
        title: {
            fontWeight: 500,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: theme.px2rem(250),
            display: 'flex',
            flexDirection: 'row',
        },
        titleZen: {
            maxWidth: theme.px2rem(200),
        },
        headerTitleWrap: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            maxWidth: theme.px2rem(270),
            alignItems: 'center',
        },
        rotateUrlIcon: {
            transform: 'rotate(135deg)',
        },
        headerActions: {
            display: 'flex',
            marginRight: theme.px2rem(10),
        },
        headerActionsZen: {
            marginRight: 0,
        },
        listItemIcon: {
            minWidth: theme.px2rem(32),
        },
        circularProgress: {
            margin: 'auto',
        },
        dropdownSpacing: {
            paddingLeft: theme.px2rem(14),
        },
        dropdownSecondaryText: {
            color: theme.black.black50,
        },
        tooltipFirstLine: {
            fontSize: theme.px2rem(12),
        },
        tooltipSecondLine: {
            fontSize: theme.px2rem(10),
        },
        ellipses: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: theme.px2rem(268),
        },
    };
}, { name: 'dz-side-panel-header', index: 0 });
