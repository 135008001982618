import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  ChangeEvent,
} from 'react';
import uniqBy from 'lodash/uniqBy';
import {
  withModalManager,
  ModalManagerProps,
  usePersistent,
} from 'shared-ui';
import { csn } from '@one-vision/utils';
import { Divider, Dialog, TextField } from '@mui/material';
import { useStyles } from './dz-global-search-dialog.styles';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import { DzGlobalSearchResult } from './dz-global-search-result';
import { DzGlobalSearchDialogPlaceholder } from './dz-global-search-dialog-placeholder';
import { DzGlobalSearchFooterPlaceholder } from './dz-global-search-footer-placeholder';
import {
  GlobalSearchItems,
  GlobalSearchAPIAddress,
  GlobalSearchResultType,
  GlobalSearchResultTypeKey,
} from './dz-global-search-types';
import useGlobalSearchDialog from './useGlobalSearchDialog';
import {
  getIdsFromResult,
  getUniqueBlockSettings,
} from './dz-global-search-utils';
import { useGlobalSearchHotkeys } from './dz-global-search-hotkeys';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { useProgrammaticScroll } from './useProgrammaticScroll';
import { UniqueFieldName } from 'components/shared';

export interface DzGlobalSearchDialogProps {
  id?: string;
}

const DzGlobalSearchDialog = ({
  isOpen,
  close,
}: DzGlobalSearchDialogProps & ModalManagerProps) => {
  const classes = useStyles();

  const {
    searchValue,
    searchResult,
    brands,
    isResultsLoading,
    showMoreLoadingKey,
    handleSearch,
    handleShowLess,
    handleShowMore,
    goToResult,
    cleanUpAllSearchState,
    isShowMoreOrLessTriggeredByHotkey,
  } = useGlobalSearchDialog({ isOpen, close });

  const {
    hoverRow,
    unhoverRow,
    setRenderedRowIds,
    lightSelectedRowId,
    changeUniqueFieldName,
    lightSelectLastItem,
    hoveredRowIdRef,
  } = useGlobalSearchHotkeys({
    isOpen,
    goToResult,
    handleShowMore,
    handleShowLess,
    isShowMoreOrLessTriggeredByHotkey,
  });

  const { activeElementRef, elementRef, dialogRef } =
    useProgrammaticScroll({
      lightSelectedRowId,
    });

  const areResultsEmpty = Object.keys(searchResult).every(
    (key) =>
      searchResult[key as GlobalSearchResultTypeKey].items.length === 0,
  );

  const resultNumber = Object.keys(searchResult).reduce<number>(
    (prev, currentKey) => {
      const val = parseInt(
        searchResult[currentKey as GlobalSearchResultTypeKey].totalCount,
        10,
      );

      return prev + val;
    },
    0,
  );

  const resultIds = useMemo(
    () => getIdsFromResult(searchResult),
    [searchResult],
  );

  const prevIsShowMoreOrLessTriggeredByHotkey = useRef(
    isShowMoreOrLessTriggeredByHotkey,
  );

  useEffect(() => {
    setRenderedRowIds(resultIds);

    if (
      prevIsShowMoreOrLessTriggeredByHotkey.current &&
      !isShowMoreOrLessTriggeredByHotkey
    ) {
      lightSelectLastItem();
    }

    prevIsShowMoreOrLessTriggeredByHotkey.current =
      isShowMoreOrLessTriggeredByHotkey;
  }, [
    resultIds,
    setRenderedRowIds,
    isShowMoreOrLessTriggeredByHotkey,
    lightSelectLastItem,
  ]);

  const onModalClose = useCallback(() => {
    cleanUpAllSearchState();
    close();
  }, [cleanUpAllSearchState, close]);

  const [searchText, setSearchText] = useState('');
  const inputSubject$ = usePersistent(() => new Subject<string>());

  useEffect(() => {
    const inputSub = inputSubject$
      .pipe(debounceTime(300))
      .subscribe((value) => handleSearch(value));

    return () => {
      inputSub.unsubscribe();
    };
  }, [handleSearch, inputSubject$]);

  const handleSearchTextChange = useCallback(
    (value: string | null) => {
      setSearchText(value || '');
      inputSubject$.next(value || '');
    },
    [setSearchText, inputSubject$],
  );

  useEffect(() => {
    if (!isOpen) {
      setSearchText('');
    }
  }, [isOpen]);

  const onTextChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      handleSearchTextChange(event.target.value),
    [handleSearchTextChange],
  );

  const results = (
    Object.keys(searchResult) as Array<keyof typeof searchResult>
  ).sort();

  return (
    <Dialog
      open={isOpen}
      onClose={onModalClose}
      className={classes.dialog}
      classes={{
        paper: classes.paperOverride,
      }}
    >
      <div className={classes.inputContainer}>
        <TextField
          onChange={onTextChange}
          placeholder="Search ProVision..."
          autoFocus={true}
          value={searchText}
          className={classes.input}
          classes={{
            root: classes.inputRoot,
          }}
        />
      </div>
      {(resultNumber > 0 || isResultsLoading) && <Divider />}
      {!isResultsLoading ? (
        <div
          className={csn(classes.resultList, [
            classes.hidden,
            areResultsEmpty,
          ])}
          ref={dialogRef}
        >
          {results.map((key) => {
            const { results, uniqueFieldName } = getResults(
              searchResult,
              key,
            );

            if (!results.length || !uniqueFieldName) {
              return null;
            }

            if (
              results
                .map(
                  (result) =>
                    result[uniqueFieldName as keyof typeof result],
                )
                .includes(lightSelectedRowId || null)
            ) {
              changeUniqueFieldName(uniqueFieldName as UniqueFieldName);
            }

            return (
              <DzGlobalSearchResult
                key={key}
                results={results}
                searchValue={searchValue}
                totalCount={parseInt(searchResult[key].totalCount, 10)}
                type={key}
                brands={brands}
                activeRowId={lightSelectedRowId}
                uniqueFieldName={uniqueFieldName as UniqueFieldName}
                handleShowLess={handleShowLess}
                handleShowMore={handleShowMore}
                goToResult={goToResult}
                showMoreLoadingKey={showMoreLoadingKey}
                hoverRow={hoverRow}
                unhoverRow={unhoverRow}
                changeUniqueFieldName={changeUniqueFieldName}
                activeElementRef={activeElementRef}
                elementRef={elementRef}
                hoveredRowIdRef={hoveredRowIdRef}
              />
            );
          })}
        </div>
      ) : (
        <DzGlobalSearchDialogPlaceholder />
      )}
      <Divider />

      {searchResult ? (
        <div className={classes.footerContainer}>
          <div className={classes.footer}>
            <div className={classes.resultNumContainer}>
              {`${resultNumber} Results`}
            </div>
            <div className={classes.keysNotice}>
              <div className={classes.noticeText}>Use arrow keys</div>
              <div className={classes.arrowContainer}>
                <div className={classes.arrowUpward}>
                  <ArrowUpward />
                </div>
                <div className={classes.arrowDownward}>
                  <ArrowUpward />
                </div>
              </div>

              <div className={classes.noticeText}>to navigate</div>
            </div>
          </div>
        </div>
      ) : (
        <DzGlobalSearchFooterPlaceholder />
      )}
    </Dialog>
  );
};

export default withModalManager<DzGlobalSearchDialogProps>()(
  DzGlobalSearchDialog,
);

const getResults = (
  searchResult: GlobalSearchResultType,
  key: GlobalSearchResultTypeKey,
) => {
  const uniqueFieldName = getUniqueBlockSettings(key).uniqueFieldName;
  const results = uniqBy(
    searchResult[key].items as GlobalSearchAPIAddress[],
    (item) => {
      return item[uniqueFieldName as 'ovaid'];
    },
  ) as GlobalSearchItems;

  return {
    uniqueFieldName,
    results,
  };
};
