import { useEffect, useCallback, useRef } from 'react';

import Mousetrap from 'mousetrap';
import 'mousetrap-global-bind';
import { useListTraversalHotkeys } from 'shared/hooks';
import { UniqueFieldName } from 'components/shared';
import { GlobalSearchResultTypeKey } from './dz-global-search-types';
import {
  HOTKEYS,
  SHOW_LESS,
  SHOW_MORE,
  SHOW_MORE_DIVIDER,
} from 'shared/constants';

export const enableGlobalSearchHotkeys = (
  openGlobalSearch: () => void,
) => {
  Mousetrap.bindGlobal(['mod+k'], openGlobalSearch);
};

export const disableGlobalSearchHotkeys = () => {
  Mousetrap.unbindGlobal(['mod+k']);
};

interface Input {
  goToResult: (id: string, uniqueFieldName: UniqueFieldName) => void;
  handleShowMore: (key: GlobalSearchResultTypeKey) => void;
  handleShowLess: (key: GlobalSearchResultTypeKey) => void;
  isShowMoreOrLessTriggeredByHotkey: boolean;
  isOpen: boolean;
}

interface Output {
  setRenderedRowIds: (ids: string[]) => void;
  hoverRow: (id: string) => void;
  unhoverRow: () => void;
  changeUniqueFieldName: (uniqueFieldName: UniqueFieldName | '') => void;
  lightSelectedRowId: string | undefined;
  lightSelectLastItem: () => void;
  hoveredRowIdRef: React.MutableRefObject<string | undefined>;
}

export const useGlobalSearchHotkeys = ({
  goToResult,
  handleShowMore,
  handleShowLess,
  isOpen,
}: Input): Output => {
  const {
    lightSelectedRowId,
    setRenderedRowIds,
    handleKeyDown,
    handleKeyUp,
    hoverRow,
    unhoverRow,
    clearAll,
    lightSelectLastItem,
    hoveredRowIdRef,
  } = useListTraversalHotkeys();

  const currentUniqueFieldName = useRef<UniqueFieldName | ''>();

  const changeUniqueFieldName = (
    uniqueFieldName: UniqueFieldName | '',
  ) => {
    if (currentUniqueFieldName.current === uniqueFieldName) {
      return;
    }

    currentUniqueFieldName.current = uniqueFieldName;
  };

  const handleShowMoreOrLess = ({
    lightSelectedRowId,
    isShowMore = true,
  }: {
    lightSelectedRowId: string;
    isShowMore?: boolean;
  }) => {
    const key = lightSelectedRowId.split(
      SHOW_MORE_DIVIDER,
    )[1] as GlobalSearchResultTypeKey;

    if (isShowMore) {
      handleShowMore(key);
    } else {
      handleShowLess(key);
    }

    handleKeyUp();
  };

  const handleEnter = useCallback(() => {
    if (lightSelectedRowId?.includes(SHOW_MORE)) {
      handleShowMoreOrLess({ lightSelectedRowId });

      return;
    }

    if (lightSelectedRowId?.includes(SHOW_LESS)) {
      handleShowMoreOrLess({ lightSelectedRowId, isShowMore: false });

      return;
    }

    if (!lightSelectedRowId && !hoveredRowIdRef.current) {
      return;
    }

    const id = lightSelectedRowId
      ? lightSelectedRowId
      : (hoveredRowIdRef.current as string);

    goToResult(id, currentUniqueFieldName.current as UniqueFieldName);

    clearAll();
  }, [
    lightSelectedRowId,
    clearAll,
    goToResult,
    handleKeyUp,
    handleShowLess,
    handleShowMore,
  ]);

  const bindHotkeys = () => {
    Mousetrap.bindGlobal(HOTKEYS.ARROW_DOWN, handleKeyDown);
    Mousetrap.bindGlobal(HOTKEYS.ARROW_UP, handleKeyUp);
    Mousetrap.bindGlobal(HOTKEYS.ENTER, handleEnter);
  };

  const unbindHotkeys = () => {
    Mousetrap.unbindGlobal(HOTKEYS.ARROW_DOWN);
    Mousetrap.unbindGlobal(HOTKEYS.ARROW_UP);
    Mousetrap.unbindGlobal(HOTKEYS.ENTER);
  };

  useEffect(() => {
    if (isOpen) {
      bindHotkeys();
    }

    return () => {
      if (isOpen) {
        unbindHotkeys();
      }
    };
  }, [
    lightSelectedRowId,
    handleEnter,
    handleKeyDown,
    handleKeyUp,
    lightSelectLastItem,
    isOpen,
  ]);

  return {
    setRenderedRowIds,
    hoverRow,
    unhoverRow,
    lightSelectedRowId,
    changeUniqueFieldName,
    lightSelectLastItem,
    hoveredRowIdRef,
  };
};
