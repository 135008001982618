import React, { useCallback, useMemo } from 'react';
import { DzLayoutPage } from 'components/shared';
import { useUsersPage } from './useUsersPage';
import { DzPageHeader } from 'components/shared';
import { useSelectUserSidebar } from 'core/redux';

import { DzUsersSidebar } from './dz-users-sidebar';
import { exportToCsv } from 'shared/utils/exportToCsv';

export const DzUsersPage: React.FC = () => {
  const {
    searchValue,
    views,
    activeView,
    isLoading,
    rows,
    usersColumns,
    searchText,
    favorites,
    activeViewPreSearchList,
    handleViewChange,
    handleHeaderBtnClick,
    handleAddFavorite,
    handleRemoveFavorite,
    onSearch,
    handleCellClick,
    selectNextView,
    selectPrevView,
    handleCreateUser,
    closeSidebar,
    openSidebar,
    setActiveViewPreSearchListItem,
  } = useUsersPage();

  const defaultColumnDef = useMemo(
    () => ({ onCellClicked: handleCellClick }),
    [handleCellClick],
  );

  const handleExportClick = useCallback(() => {
    const headerRow = ['ID'].concat(
      usersColumns.map((column) => column.headerName || ''),
    );

    const dataRows = rows.map((row) => {
      return [row.ovcid].concat(
        usersColumns.map<string>(({ cellRenderer }) => {
          return cellRenderer({ data: row }) as string;
        }),
      );
    });

    exportToCsv(`${activeView}_users`, [headerRow].concat(dataRows), {
      withTimestamp: true,
    });
  }, [rows, usersColumns, activeView]);

  return (
    <DzLayoutPage
      defaultColumnDef={defaultColumnDef}
      isLoading={isLoading}
      onButtonClick={handleHeaderBtnClick}
      preparedColumns={usersColumns}
      preparedRows={rows}
      uniqueFieldName="ovcid"
      gridName="users"
      isAddButtonEnabled={false}
      searchText={searchText}
      handleCellClick={handleCellClick}
      activeView={activeView}
      selectNextView={selectNextView}
      selectPrevView={selectPrevView}
      closeSidebar={closeSidebar}
      openSidebar={openSidebar}
      layoutHeader={
        <DzPageHeader
          searchValue={searchValue}
          views={views}
          activeView={activeView}
          handleViewChange={handleViewChange}
          onSearch={onSearch}
          favorites={favorites}
          handleAddFavorite={handleAddFavorite}
          handleRemoveFavorite={handleRemoveFavorite}
          onAddButtonClick={handleCreateUser}
          buttonName="User"
          useSelectSidebarPanel={useSelectUserSidebar}
          preSearchList={activeViewPreSearchList}
          setItemToPreSearchList={setActiveViewPreSearchListItem}
          handleExportClick={handleExportClick}
        />
      }
      layoutSidebar={<DzUsersSidebar />}
    />
  );
};
