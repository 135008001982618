import {
  accessControlSystemSmartLocks,
  audioSystemType,
  cameraSystemBrand,
  cameraSystemType,
  controlSystemBrand,
  hvacBrand,
  hvacIntegration,
  intercom,
  internetServiceProvider,
  lightingBrand,
  lightingSystemType,
  networkLocation,
  phoneBrand,
  phoneSystemType,
  primaryAudioInterface,
  primaryEquipmentLocation,
  remoteSystemManagement,
  securitySystemBrand,
  securitySystemIntegrated,
  shadeBrand,
  shadeInterface,
  shadeType,
  streamingPlatform,
  thirdPartyNetworkProvider,
  tvServiceProvider,
  videoDistributionBrand,
  videoDoorbells,
  videoSystemType,
  wiredNetworkBrand,
  wirelessNetworkBrand,
} from 'shared-ui';

interface NameKey {
  name: string;
  key: string;
}

export const mapSelectOptions = (values: NameKey[]) =>
  values.map(({ key, name }) => ({
    value: key,
    label: name,
  }));

export const siteTypeField = {
  label: 'Site Type',
  key: 'ovbid',
  type: 'select',
  options: [
    { value: 'Residential', label: 'Residential' },
    { value: 'Commercial', label: 'Commercial' },
  ],
  validators: [{ validate: 'required' }],
};

export const flatFileFields = [
  {
    label: 'Project ID',
    key: 'ovprjid',
  },
  {
    label: 'Address ID',
    key: 'ovaid',
  },
  {
    label: 'Client ID',
    key: 'ovcid',
  },
  {
    label: 'Project Stage',
    key: 'projectStageId',
    description: 'Client Type',
    validators: [{ validate: 'required' }],
    type: 'select',
    options: [
      { value: 1, label: 'Proposal' },
      { value: 2, label: 'Active Project' },
      { value: 3, label: 'Service Client' },
    ],
  },
  {
    label: 'First Name',
    key: 'firstName',
    description: "client's first name",
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Last Name',
    key: 'lastName',
    validators: [{ validate: 'required' }],
  },
  { label: 'User Notes', key: 'notes' },
  {
    label: 'Email',
    key: 'email',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Secondary Email',
    key: 'secondaryEmail',
  },
  {
    label: 'Primary Phone',
    key: 'phone',
  },
  { label: 'Secondary Phone', key: 'secondaryPhone' },
  { label: 'Business Name', key: 'address.name' },
  {
    label: 'Address 1 / Street Address',
    key: 'address1',
    validators: [{ validate: 'required' }],
  },
  { label: 'Address 2 / Unit Number', key: 'address2' },
  {
    label: 'City',
    key: 'city',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'State/Province',
    key: 'state',
    validators: [{ validate: 'required' }],
  },
  {
    label: 'Postal Code',
    key: 'zip',
  },
  { label: 'Organization Notes', key: 'orgNotes' },
  { label: 'Membership Sales Notes', key: 'membershipSalesNotes' },
  {
    label: 'Primary Contact for address',
    key: 'primaryContact',
    type: 'checkbox',
  },
  {
    label: 'Project Name',
    key: 'project.name',
  },
  {
    label: 'Frequent Flyer',
    key: 'frequentFlyer',
    type: 'checkbox',
  },
  {
    label: 'Leave off OneVision Marketing?',
    key: 'marketingOptOut',
    type: 'checkbox',
  },
  {
    label: 'Active Warranty Expiration Date',
    key: 'warrantyExpirationDate',
  },
  {
    label: 'Legacy Service Plan',
    key: 'clientActivatedMembership',
    type: 'select',
    options: [
      { value: 'Legacy', label: 'Yes' },
      { value: 'Inactive', label: 'No' },
    ],
  },
  {
    label: 'Legacy Plan Expiration Date',
    key: 'cppExpDate',
  },
  {
    label: 'Primary RSM Platform',
    key: 'siteRemoteSystemsManagement',
    type: 'select',
    options: mapSelectOptions(remoteSystemManagement),
  },
  {
    label: 'Primary Equipment Location',
    key: 'sitePrimaryEquipmentLocation',
    type: 'select',
    options: mapSelectOptions(primaryEquipmentLocation),
  },
  {
    label: 'Network Location',
    key: 'siteNetworkLocation',
    type: 'select',
    options: mapSelectOptions(networkLocation),
  },
  {
    label: 'Control System Brand',
    key: 'siteControlSystemBrand',
    type: 'select',
    options: mapSelectOptions(controlSystemBrand),
  },
  {
    label: 'Internet Service Provider',
    key: 'siteInternetService',
    type: 'select',
    options: mapSelectOptions(internetServiceProvider),
  },
  {
    label: 'Television Service Provider',
    key: 'siteTvServiceProvider',
    type: 'select',
    options: mapSelectOptions(tvServiceProvider),
  },
  {
    label: 'Streaming Platform',
    key: 'siteStreamingPlatform',
    type: 'select',
    options: mapSelectOptions(streamingPlatform),
  },
  {
    label: '3rd Party Network Provider',
    key: 'site3rdPartyNetworkProvider',
    type: 'select',
    options: mapSelectOptions(thirdPartyNetworkProvider),
  },
  {
    label: 'Wireless Network Brand',
    key: 'siteWirelessNetworkBrand',
    type: 'select',
    options: mapSelectOptions(wirelessNetworkBrand),
  },
  {
    label: 'Wired Network Brand',
    key: 'siteWiredNetworkBrand',
    type: 'select',
    options: mapSelectOptions(wiredNetworkBrand),
  },
  {
    label: 'Audio System Type',
    key: 'siteAudioSystemType',
    type: 'select',
    options: mapSelectOptions(audioSystemType),
  },
  {
    label: 'Primary Audio Interface',
    key: 'sitePrimaryAudioInterface',
    type: 'select',
    options: mapSelectOptions(primaryAudioInterface),
  },
  {
    label: 'Video System Type',
    key: 'siteVideoSystemType',
    type: 'select',
    options: mapSelectOptions(videoSystemType),
  },
  {
    label: 'Video Distribution Brand',
    key: 'siteVideoDistributionBrand',
    type: 'select',
    options: mapSelectOptions(videoDistributionBrand),
  },
  {
    label: 'Phones System Type',
    key: 'sitePhoneSystemType',
    type: 'select',
    options: mapSelectOptions(phoneSystemType),
  },
  {
    label: 'Phone System Brand',
    key: 'sitePhoneSystemBrand',
    type: 'select',
    options: mapSelectOptions(phoneBrand),
  },
  {
    label: 'Camera System Type',
    key: 'siteCameraSystemType',
    type: 'select',
    options: mapSelectOptions(cameraSystemType),
  },
  {
    label: 'Camera System Brand',
    key: 'siteCameraSystemBrand',
    type: 'select',
    options: mapSelectOptions(cameraSystemBrand),
  },
  {
    label: 'Lighting System Type',
    key: 'siteLightingSystemType',
    type: 'select',
    options: mapSelectOptions(lightingSystemType),
  },
  {
    label: 'Lighting System Brand',
    key: 'siteLightingSystemBrand',
    type: 'select',
    options: mapSelectOptions(lightingBrand),
  },
  {
    label: 'HVAC Integration',
    key: 'hvacIntegration',
    type: 'select',
    options: mapSelectOptions(hvacIntegration),
  },
  {
    label: 'HVAC Brand',
    key: 'siteHvacBrand',
    type: 'select',
    options: mapSelectOptions(hvacBrand),
  },
  {
    label: 'Security System Integrated',
    key: 'siteSecuritySystemIntegrated',
    type: 'select',
    options: mapSelectOptions(securitySystemIntegrated),
  },
  {
    label: 'Security System Brand',
    key: 'siteSecuritySystemBrand',
    type: 'select',
    options: mapSelectOptions(securitySystemBrand),
  },
  {
    label: 'Shade Type',
    key: 'siteShadeType',
    type: 'select',
    options: mapSelectOptions(shadeType),
  },
  {
    label: 'Shade Interface',
    key: 'siteShadeInterface',
    type: 'select',
    options: mapSelectOptions(shadeInterface),
  },
  {
    label: 'Shade Brand',
    key: 'siteShadeBrand',
    type: 'select',
    options: mapSelectOptions(shadeBrand),
  },
  {
    label: 'Intercom',
    key: 'siteIntercom',
    type: 'select',
    options: mapSelectOptions(intercom),
  },
  {
    label: 'Video Doorbells',
    key: 'siteVideoDoorbell',
    type: 'select',
    options: mapSelectOptions(videoDoorbells),
  },
  {
    label: 'Access Control System/Smart Locks',
    key: 'siteAccessControlSystemSmartLocks',
    type: 'select',
    options: mapSelectOptions(accessControlSystemSmartLocks),
  },
];
