import { useContext } from 'react';
import { DzUserConfigContext } from './dz-user-config-context';
const PARTNER_RULE_ACCESS_TO_PRE_SOLD_MEMBERSHIPS = 'access_to_pre_sold_memberships';
const PARTNER_RULE_ACCESS_TO_CUSTOM_SLA = 'access_to_custom_sla';
const PARTNER_RULE_ACCESS_TO_PROVISION_PROJECTS = 'access_to_provision_projects';
const PARTNER_RULE_ACCESS_TO_SUBSCRIPTION_MANAGEMENT = 'access_to_subscription_management';
const PARTNER_RULE_ACCESS_TO_SUBSCRIPTION_MANAGEMENT_PLUS = 'access_to_subscription_management_plus';
// this is an example of a user rule
// we can delete it once we have actual rules that we use
// const USER_RULE_HAS_ACCESS_TO_PROJECTS = 'has-access-to-projects';
const TRUE = 'true';
export const useUserConfig = () => {
    const values = useContext(DzUserConfigContext);
    return {
        partner: {
            accessToPreSoldMemberships: hasAccess(values.partner[PARTNER_RULE_ACCESS_TO_PRE_SOLD_MEMBERSHIPS]),
            accessToCustomSla: hasAccess(values.partner[PARTNER_RULE_ACCESS_TO_CUSTOM_SLA]),
            accessToProvisionProjects: hasAccess(values.partner[PARTNER_RULE_ACCESS_TO_PROVISION_PROJECTS]),
            accessSubscriptionManagement: hasAccess(values.partner[PARTNER_RULE_ACCESS_TO_SUBSCRIPTION_MANAGEMENT]),
            accessSubscriptionManagementPlus: hasAccess(values.partner[PARTNER_RULE_ACCESS_TO_SUBSCRIPTION_MANAGEMENT_PLUS]),
        },
        user: values.user,
        userDetails: values.userDetails,
        loading: values === null || values === void 0 ? void 0 : values.loading,
    };
};
function hasAccess(value) {
    return value === TRUE;
}
