import { makeStyles } from '@mui/styles';

import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      topContainer: {
        marginBottom: theme.spacing(2),
      },
      iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
      },
      icon: {
        fontSize: 123,
        color: theme.dz.colors.success,
      },
      actionsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      },
      amount: {
        marginBottom: theme.spacing(2),
        color: '#4C4D50',
      },
      primaryButton: {
        backgroundColor: theme.dz.colors.proactive,
        color: '#fff',
        minWidth: theme.px2rem(259),
        '&:hover': {
          backgroundColor: '#713eb2',
        },
      },
    };
  },
  { name: 'dz-data-import-result' },
);
