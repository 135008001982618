import { Box, Button, Divider, Tooltip, Skeleton } from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';

import { csn, logRender } from '@one-vision/utils';
import { homeWorkIcon } from 'assets';
import { Routes } from 'core/routes';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useStyles } from './dz-app-bar.styles';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { usePartnerConfig, useUserConfig } from 'shared-ui';
import DzSupportMenu from 'components/dz-support-menu/dz-support-menu.view';
import { DzIframeHiddenContent } from 'components/shared';
import { auth } from '@one-vision/login';

export const DzAppBarView: React.FC = () => {
  logRender(DzAppBarView);

  const classes = useStyles();

  const partnerConfig = usePartnerConfig();
  const { user } = useUserConfig();

  const blurActiveElement = () => {
    // remove blur after selecting the page.
    // otherwise if we press enter trying to select the row in the table
    // SELECTED param will be removed because focus
    // will remainon the page icon button
    document.activeElement?.blur();
  };

  const hasAccessToReport =
    !partnerConfig.loading &&
    partnerConfig.accessSubscriptionManagement &&
    (auth.getIsAdmin() || user.reports);

  return (
    <DzIframeHiddenContent>
      <div className={classes.root}>
        <div className={classes.logo}>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path
              d="M18 0C8.0575 0 0 8.0575 0 18C0 27.9425 8.0575 36 18 36C27.9425 36 36 27.9425 36 18C36 8.0575 27.9425 0 18 0ZM26.7967 11.7906L16.8357 26.501C16.7064 26.6858 16.5216 26.7782 16.2998 26.7782H12.5852C12.271 26.7782 12.0123 26.5195 12.0123 26.2053V14.9138C12.0123 14.5996 11.7536 14.3224 11.4209 14.3224H10.7372C10.2752 14.3224 9.99795 13.8049 10.2567 13.4353L11.846 11.0513C11.9569 10.9035 12.1232 10.8111 12.308 10.8111H14.8953C15.2094 10.8111 15.4497 11.0698 15.4497 11.3655V21.2526C15.4497 21.6407 15.9487 21.7885 16.1704 21.4743L23.193 11.0883C23.3039 10.922 23.5072 10.8111 23.7105 10.8111H26.2793C26.7782 10.8111 27.0739 11.3655 26.7967 11.7906Z"
              fill="white"
            />
          </svg>
        </div>
        <div className={classes.pages}>
          {partnerConfig.loading ? (
            <>
              <Skeleton variant="circular" width={50} height={50} />
              <Skeleton variant="circular" width={50} height={50} />
              <Skeleton variant="circular" width={50} height={50} />
            </>
          ) : (
            <>
              {!partnerConfig.loading &&
                partnerConfig.accessToProvisionProjects && (
                  <NavLink
                    to={Routes.Projects}
                    className={({ isActive }) =>
                      csn(classes.pageLink, [
                        classes.pageLinkSelected,
                        isActive,
                      ])
                    }
                  >
                    <Tooltip
                      classes={{
                        popper: classes.toolTip,
                      }}
                      placement="right"
                      title="Projects"
                    >
                      <IconButton
                        className={classes.pageIconButton}
                        onClick={() => {
                          blurActiveElement();
                        }}
                      >
                        <PermMediaIcon className={classes.icon} />
                      </IconButton>
                    </Tooltip>
                  </NavLink>
                )}

              <NavLink
                to={Routes.Organizations}
                className={({ isActive }) =>
                  csn(classes.pageLink, [
                    classes.pageLinkSelected,
                    isActive,
                  ])
                }
              >
                <Tooltip
                  classes={{
                    popper: classes.toolTip,
                  }}
                  placement="right"
                  title="Organizations"
                >
                  <Button
                    className={classes.pageIconButton}
                    onClick={() => {
                      blurActiveElement();
                    }}
                  >
                    <img
                      src={homeWorkIcon}
                      alt="Organizations page icon"
                    />
                  </Button>
                </Tooltip>
              </NavLink>

              <NavLink
                to={Routes.Users}
                className={({ isActive }) =>
                  csn(classes.pageLink, [
                    classes.pageLinkSelected,
                    isActive,
                  ])
                }
              >
                <Tooltip
                  classes={{
                    popper: classes.toolTip,
                  }}
                  placement="right"
                  title="Users"
                >
                  <Button
                    className={classes.pageIconButton}
                    onClick={() => {
                      blurActiveElement();
                    }}
                  >
                    <PeopleIcon htmlColor="#fff" />
                  </Button>
                </Tooltip>
              </NavLink>

              {hasAccessToReport && (
                <NavLink
                  to={Routes.Reports}
                  className={({ isActive }) =>
                    csn(classes.pageLink, [
                      classes.pageLinkSelected,
                      isActive,
                    ])
                  }
                >
                  <Tooltip
                    classes={{
                      popper: classes.toolTip,
                    }}
                    placement="right"
                    title="Reports"
                  >
                    <Button className={classes.pageIconButton}>
                      <AssessmentIcon htmlColor="#fff" />
                    </Button>
                  </Tooltip>
                </NavLink>
              )}
            </>
          )}

          <Box
            borderBottom="1px solid #ffffff33"
            width={'calc(100% - 24px)'}
            flex={1}
          />

          <Divider className={classes.divider} />

          <DzSupportMenu />

          <NavLink
            to={Routes.Settings}
            className={({ isActive }) =>
              csn(classes.pageLink, classes.settingsLink, [
                classes.pageLinkSelected,
                isActive,
              ])
            }
          >
            <Tooltip
              classes={{
                popper: classes.toolTip,
              }}
              placement="right-start"
              title="Settings"
            >
              <Button className={classes.pageIconButton}>
                <SettingsIcon className={classes.icon} />
              </Button>
            </Tooltip>
          </NavLink>
        </div>
      </div>
    </DzIframeHiddenContent>
  );
};
