export const dzColors = {
    listHover: '#8E54FF',
    oneVisionBlack: '#1A1D21',
    oneVisionBlackBackground: '#F4F4F4',
    oneVisionBlack65: '#6D6F71',
    oneVisionBlack80: '#4C4D50',
    signature: '#FFB402',
    priority: '#FF8080',
    proactive: '#8E54FF',
    declinedOrUndecided: '#868686',
    essentialsPlus: '#77DBB0',
    error: '#E53935',
    link: '#2AC5C9',
    brandOrange: '#FC6D26',
    nonMember: '#F2F2F2',
    success: '#00C853',
    successDarker: '#02A345',
    notAcceptedMember: '#B1B1B2',
    unassignedUser: '#C4C4C4',
    selectedRow: '#EDF7FE',
    gridHover: '#AAE8E9',
};
export const dzSharedStyles = {
    membershipColors: {
        signature: dzColors.signature,
        priority: dzColors.priority,
        proactive: dzColors.proactive,
        essentialsPlus: dzColors.essentialsPlus,
        declinedOrUndecided: dzColors.declinedOrUndecided,
        nonMember: dzColors.nonMember,
    },
    tosAcceptedColors: {
        accepted: dzColors.success,
        notAccepted: dzColors.error,
    },
    membershipColorsOrg: {
        signature: dzColors.signature,
        priority: dzColors.priority,
        proactive: dzColors.proactive,
        essentialsPlus: dzColors.essentialsPlus,
        nonMember: dzColors.notAcceptedMember,
    },
    tosAcceptedColorsOrg: {
        accepted: dzColors.success,
        notAccepted: dzColors.error,
    },
};
export { ThemeProvider } from '@one-vision/design-theme';
