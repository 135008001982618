import React from 'react';
import { Typography } from '@mui/material';

import { useStyles } from './dz-password-criteria.styles';
import { PasswordsValidation } from './usePasswordValidations';
import { useTextValidationStyles, Icon } from './useTextValidationStyles';

interface Props {
  passwordsValidation: PasswordsValidation;
}

export const DzPasswordValidationFeedback: React.FC<Props> = (props) => {
  const classes = useStyles();
  const validations = useTextValidationStyles(props.passwordsValidation);
  const passwordsMatchStyle = props.passwordsValidation.passwordsMatch
    ? classes.valid
    : classes.invalid;

  return (
    <Typography component="div">
      Password criteria:
      <ul className={classes.list}>
        <li className={validations.atLeastTenCharacters.className}>
          {validations.atLeastTenCharacters.icon} 10-character minimum
          length
        </li>
        <li className={validations.atLeastOneNumber.className}>
          {validations.atLeastOneNumber.icon} Contains at least 1 number
        </li>
        <li className={validations.atLeastOneLowerCaseLetter.className}>
          {validations.atLeastOneLowerCaseLetter.icon} Contains at least 1
          lowercase letter
        </li>
        <li className={validations.atLeastOneUpperCaseLetter.className}>
          {validations.atLeastOneUpperCaseLetter.icon} Contains at least 1
          uppercase letter
        </li>
        <li className={passwordsMatchStyle}>
          <Icon isValid={props.passwordsValidation.passwordsMatch} />{' '}
          Passwords must match
        </li>
        <li className={validations.atLeastOneSpecialCharacter.className}>
          {validations.atLeastOneSpecialCharacter.icon} Contains at least 1
          special character from the following set, or a non-leading,
          non-trailing space character.
          <br />
        </li>
      </ul>
    </Typography>
  );
};
