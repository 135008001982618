import {
  ColDef,
  GridApi,
  ColumnApi,
  GridReadyEvent,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { logRender, csn } from '@one-vision/utils';
import { usePersistent, DzCircularProgress } from 'shared-ui';
import { useStyles } from './dz-grid.styles';
import { DzGridProps, GridColumnTypes } from './dz-grid.types';
import Overlay from './dz-grid-overlay.view';
import { DEFAULT_GRID_ROW_HEIGHT } from 'shared/constants';
import { useRowSelection } from 'shared/hooks';
import { useDetectMouseMove } from 'shared/hooks/useDetectMouseMove';

const iconsList = {
  sortDescending: `<svg width="14" height="14" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns-xlink="http://www.w3.org/1999/xlink" xml-space="preserve" xmlns-serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;margin-top:2;">
  <path id="asc" d="M5.333,16L7.213,17.88L14.666,10.44L14.666,26.667L17.333,26.667L17.333,10.44L24.773,17.893L26.666,16L15.999,5.333L5.332,16L5.333,16Z" style={{fillRule:'nonzero',}}/>
  </svg>`,
  sortAscending: `<svg width="14" height="14" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns-xlink="http://www.w3.org/1999/xlink" xml-space="preserve" xmlns-serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;margin-top:2;">
    <path id="desc" d="M26.667,16L24.787,14.12L17.334,21.56L17.334,5.333L14.667,5.333L14.667,21.56L7.227,14.107L5.334,16L16.001,26.667L26.668,16L26.667,16Z" style="fill-rule:nonzero;"/>
    </svg>`,
};

const visibleRowStyle: { [CSSProperties: string]: string | number } = {
  opacity: 1,
};

export const DzGridView = <
  RowType extends { ovaid?: string; ovcid?: string; id?: string },
>(
  props: DzGridProps<RowType>,
) => {
  const {
    isLoading = false,
    columns,
    rows,
    searchText,
    // This is necessary due to the fact that not all data contains an ID field by default.
    // Therefore, we need to change the 'id' field to our custom, which is present (e.g. ovcid).
    uniqueFieldName = 'id',
    onRowClick,
    gridName,
    ownerPopup,
    selectNextView,
    selectPrevView,
    activeView,
    onBodyScroll,
    openSidebar,
    closeSidebar,
    defaultColumnDef,
  } = props;

  const {
    selectedRowId,
    lightSelectedRowId,
    hoverRow,
    unhoverRow,
    setRenderedRowIds,
  } = useRowSelection({
    selectNextView,
    selectPrevView,
    activeView,
    openSidebar,
    closeSidebar,
  });

  logRender(DzGridView);

  const [isFirstCall, setIsFirstCall] = useState(true);
  const [isFirstRendered, setIsFirstRendered] = useState(false);

  const [areHotkeysUsed, setAreHotkeysUsed] = useState(true);

  const { isMouseBeingMoved } = useDetectMouseMove();

  useEffect(() => {
    if (isMouseBeingMoved && areHotkeysUsed) {
      setAreHotkeysUsed(false);

      return;
    }
  }, [isMouseBeingMoved, areHotkeysUsed]);

  const persistent = usePersistent<{
    gridApi?: GridApi;
    columnApi?: ColumnApi;
    selectedRowId?: string | undefined;
    prevSelectedRowId?: string | undefined;
    lightSelectedRowId?: string | undefined;
    prevLightSelectedRowId?: string | undefined;
  }>({});

  if (persistent.selectedRowId !== selectedRowId) {
    persistent.prevSelectedRowId = persistent.selectedRowId;
    persistent.selectedRowId = selectedRowId;
  }

  if (persistent.lightSelectedRowId !== lightSelectedRowId) {
    persistent.prevLightSelectedRowId = persistent.lightSelectedRowId;
    persistent.lightSelectedRowId = lightSelectedRowId;
    if (!areHotkeysUsed) {
      setAreHotkeysUsed(true);
    }
  }

  const classes = useStyles();

  const defaultColDef: ColDef = useMemo(
    () => ({
      sortable: true,
      suppressHeaderKeyboardEvent: () => true,
      minWidth: 100,
      resizable: true,
      headerClass: [classes.agGridHeaderLeftAligned],
      cellClassRules: {
        [classes.agGridCellLeftAligned]: () => true,
      },
      ...(defaultColumnDef || {}),
    }),
    [classes, defaultColumnDef],
  );

  const columnTypes = useMemo(
    () => ({
      [GridColumnTypes.CENTERED]: {
        headerClass: [classes.agGridHeaderCentered],
        cellClassRules: {
          [classes.agGridCellCentered]: () => true,
          [classes.agGridCellLeftAligned]: () => false,
        },
      },
    }),
    [classes],
  );

  const handleGridReady = useCallback(
    (e: GridReadyEvent) => {
      persistent.gridApi = e.api;
      persistent.columnApi = e.columnApi;
    },
    [persistent],
  );

  const handleColumnResize = useCallback(() => {
    if (isFirstCall) {
      setIsFirstCall(false);
    }
  }, [isFirstCall, setIsFirstCall]);

  const handleFirstRender = useCallback(() => {
    const domRows = document.getElementsByClassName(classes.row);
    if (domRows.length) {
      for (let i = 0; i < domRows.length; i++) {
        if (domRows[i] && domRows[i].classList) {
          domRows[i].classList.add(classes.rowLoaded);
        }
      }
    }
    setIsFirstRendered(true);
  }, [setIsFirstRendered, classes]);

  const getRowNodeId = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (item: any) => item.data?.[uniqueFieldName],
    [uniqueFieldName],
  );

  const rowClassRules = useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [classes.rowSelected]: (e: any) =>
        e.data != undefined &&
        e.data?.[uniqueFieldName] === persistent.selectedRowId,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [classes.rowLightSelected]: (e: any) =>
        e.data != undefined &&
        e.data?.[uniqueFieldName] === persistent.lightSelectedRowId,
    }),
    [
      persistent.lightSelectedRowId,
      persistent.selectedRowId,
      classes,
      uniqueFieldName,
    ],
  );

  const updateRecords = useCallback(() => {
    if (!persistent.gridApi) {
      return;
    }

    const recordsToUpdate = rows.filter((r) => {
      const fieldname = uniqueFieldName as 'ovaid';
      return (
        r != undefined &&
        (r?.[fieldname] === persistent.selectedRowId ||
          r?.[fieldname] === persistent.prevSelectedRowId ||
          r?.[fieldname] === persistent.lightSelectedRowId ||
          r?.[fieldname] === persistent.prevLightSelectedRowId)
      );
    });

    if (recordsToUpdate.length) {
      persistent.gridApi.applyTransaction({
        update: recordsToUpdate,
      });
    }

    scrollToRow(persistent.lightSelectedRowId, persistent.gridApi);

    function scrollToRow(
      lightSelectedRowId: string | undefined,
      gridApi: GridApi<RowType> | undefined,
    ) {
      if (!lightSelectedRowId) return;

      const selectedRow = gridApi?.getRowNode(lightSelectedRowId);

      if (!selectedRow || selectedRow.rowIndex === null) return;

      gridApi?.ensureIndexVisible(selectedRow.rowIndex);
    }
  }, [
    persistent.lightSelectedRowId,
    persistent.gridApi,
    persistent.selectedRowId,
    persistent.prevSelectedRowId,
    persistent.prevLightSelectedRowId,
    rows,
    uniqueFieldName,
  ]);

  useEffect(() => {
    updateRecords();
  }, [
    persistent.lightSelectedRowId,
    persistent.gridApi,
    persistent.selectedRowId,
    persistent.prevSelectedRowId,
    updateRecords,
  ]);

  useEffect(() => {
    // Scroll grid to top when view changes.
    persistent.gridApi?.ensureIndexVisible(0);
  }, [activeView, persistent.gridApi]);

  const getOverlayComponent = useCallback(() => {
    return <Overlay gridName={gridName} />;
  }, [gridName]);

  return (
    <div className={classes.root}>
      {isFirstCall || isLoading ? (
        <DzCircularProgress className={classes.spinner} timeout={200} />
      ) : null}
      <div
        className={csn(
          classes.agGrid,
          [classes.agGridLoading, isFirstCall || isLoading],
          ['is-hover-disabled', areHotkeysUsed],
        )}
      >
        <AgGridReact
          onCellMouseOver={(event) => {
            if (!hoverRow || !isMouseBeingMoved) {
              return;
            }

            hoverRow(event.data?.[uniqueFieldName]);
          }}
          onCellMouseOut={() => {
            if (!unhoverRow || !isMouseBeingMoved) {
              return;
            }

            unhoverRow();
          }}
          rowData={rows}
          quickFilterText={searchText}
          rowBuffer={30}
          rowHeight={DEFAULT_GRID_ROW_HEIGHT}
          defaultColDef={defaultColDef}
          icons={iconsList}
          animateRows={true}
          getRowId={getRowNodeId}
          onGridReady={handleGridReady}
          onFirstDataRendered={handleFirstRender}
          onColumnResized={handleColumnResize}
          rowClass={classes.row}
          rowStyle={isFirstRendered ? visibleRowStyle : undefined}
          rowClassRules={rowClassRules}
          onRowClicked={onRowClick}
          noRowsOverlayComponent={getOverlayComponent}
          onModelUpdated={() => {
            if (!persistent.gridApi || !setRenderedRowIds) {
              return;
            }

            const nodes: string[] = [];

            persistent.gridApi.forEachNodeAfterFilterAndSort((node) => {
              nodes.push(node.data?.[uniqueFieldName]);
            });

            setRenderedRowIds(nodes);
          }}
          onBodyScroll={onBodyScroll}
          columnTypes={columnTypes}
          columnDefs={columns}
          suppressColumnVirtualisation
          suppressDragLeaveHidesColumns
        ></AgGridReact>
        {ownerPopup}
      </div>
    </div>
  );
};
