import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { Box, FormControl, TextField } from '@mui/material';
import { csn, getChanges, logRender } from '@one-vision/utils';
import { DzDialog, useGlobalStyles, useApiClient } from '../../shared';
import { MEMBERSHIP_SERVICE_LIST } from '../../..';
import { Autocomplete } from '@mui/material';
import Addresses from '../../../redux/addresses';
export const DzUpdateServiceLevelDialogView = ({ actions, dispatch, isOpen, originalAddress, thunks, address, zendesk, }) => {
    logRender(DzUpdateServiceLevelDialogView);
    const classes = useGlobalStyles();
    const apiClient = useApiClient();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const updateAddress = (changes) => {
        if (!address) {
            return;
        }
        dispatch(actions.updateUpdateServiceLevelDialogState({
            address: Object.assign(Object.assign({}, address), changes),
        }));
    };
    const handleClose = useCallback(() => dispatch(actions.updateUpdateServiceLevelDialogState({
        isOpen: false,
    })), [dispatch]);
    const changes = useMemo(() => getChanges(originalAddress, address), [address, originalAddress]);
    const handleSubmit = useCallback(() => {
        setIsSubmitting(true);
        dispatch(thunks.updateAddress({
            ovaid: originalAddress.ovaid,
            changes,
        }))
            .unwrap()
            .then(async () => {
            const { data: [updatedAddress], } = await apiClient.getAddresses({ ovaid: originalAddress.ovaid });
            if (updatedAddress) {
                dispatch(Addresses.actions.update(address));
                dispatch(actions.addressUpdated(address));
            }
            dispatch(actions.updateSnackbar({
                text: 'Organization was updated',
                type: 'success',
            }));
            handleClose();
        })
            .catch((error) => {
            dispatch(actions.updateSnackbar({
                type: 'error',
                text: error.message,
            }));
        })
            .finally(() => {
            setIsSubmitting(false);
        });
    }, [changes, originalAddress, dispatch, setIsSubmitting]);
    const content = useMemo(() => {
        return (_jsx(Box, Object.assign({ display: "flex", flexDirection: "column" }, { children: _jsx(FormControl, Object.assign({ className: csn(classes.gcFieldMargin) }, { children: _jsx(Autocomplete, { id: "clientActivatedMembership", value: MEMBERSHIP_SERVICE_LIST.find((serviceList) => {
                        var _a;
                        return (serviceList.toLowerCase() ===
                            ((_a = address === null || address === void 0 ? void 0 : address['clientActivatedMembership']) === null || _a === void 0 ? void 0 : _a.toLowerCase()));
                    }), autoHighlight: true, openOnFocus: true, disableClearable: true, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Service Level", margin: "none" }))), options: MEMBERSHIP_SERVICE_LIST, isOptionEqualToValue: (option) => option === address['clientActivatedMembership'], onChange: (_, newValue) => {
                        updateAddress({
                            clientActivatedMembership: newValue,
                        });
                    } }) })) })));
    }, [address, isSubmitting]);
    const processedAddress = Object.assign(Object.assign({}, originalAddress), changes);
    const canSubmit = Boolean(Object.keys(changes).length &&
        processedAddress.clientActivatedMembership);
    return (_jsx(DzDialog, { disablePortal: true, isOpen: isOpen, caption: "Update Service Level", okButtonText: "Save", okButtonDisabled: isSubmitting || !canSubmit, content: content, onClose: handleClose, cancelButtonText: "Cancel", onOk: handleSubmit, isSubmittingLoader: isSubmitting, zendesk: zendesk }));
};
