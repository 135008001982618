import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        root: {},
        navigationRow: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        title: {
            fontSize: theme.px2rem(18),
            marginBottom: theme.spacing(3),
            fontWeight: 'normal',
        },
        titleZen: {
            fontSize: theme.px2rem(14),
            marginBottom: theme.spacing(2),
        },
        subTitle: {
            fontSize: '14px',
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(3),
            fontWeight: 'normal',
        },
        divider: { margin: `${theme.px2rem(33)} 0` },
        dividerZen: { margin: `${theme.px2rem(20)} 0` },
        stepLabel: {
            fontSize: '1.1rem',
        },
        stepIconRoot: {
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            width: '25px',
            justifyContent: 'center',
            color: theme.palette.grey[300],
        },
        stepIconActive: {
            color: theme.palette.primary.main,
        },
        stepIconCircle: {
            width: 10,
            height: 10,
            // margin: '0 10px',
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
        stepIconCompleted: {
            color: theme.palette.primary.main,
            zIndex: 1,
            fontSize: '18px',
            cursor: 'pointer',
        },
        chipContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(1),
            },
            marginBottom: theme.spacing(2.5),
        },
        chip: {
            flex: '1 0 21%',
        },
        labelZen: {
            fontSize: theme.px2rem(12),
        },
        skeletonMargin: {
            marginBottom: theme.spacing(2),
        },
    };
}, { name: 'dz-update-tech-dialog', index: 0 });
