import { logError } from '@one-vision/utils';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { JsonApiUser, UserRelations } from 'shared-ui';

import { API } from '../api';
import { DzOwner, userToOwner } from 'shared-ui';
import { thunks } from './';
import {
  UserGroupJsonApiEntity,
  UserGroupRuleJsonApiEntity,
  UserGroupRuleValueJsonApiEntity,
} from '@one-vision/json-api-parser';

const filterRules = (
  item:
    | UserGroupJsonApiEntity
    | UserGroupRuleJsonApiEntity
    | UserGroupRuleValueJsonApiEntity,
  id: string,
) =>
  item.id === id &&
  item.type === 'UserGroupRuleValue' &&
  item.attributes.value?.toLowerCase() === 'true';

export const getUserRulesWithIncluded = (
  data: JsonApiUser[],
  included:
    | UserGroupJsonApiEntity[]
    | UserGroupRuleJsonApiEntity[]
    | UserGroupRuleValueJsonApiEntity[],
) =>
  data.map((user) => {
    const groupRules =
      user.relationships.userGroup?.data.map((group) => {
        const valuesList =
          (included?.find(
            (item) => item.id === group.id && item.type === 'UserGroup',
          )?.relationships.userGroupRuleValue?.data as {
            id: string;
            type: 'UserGroupRuleValue';
          }[]) || [];

        const rulesList = valuesList.map(
          (value) =>
            included?.find((item) => filterRules(item, value.id))
              ?.relationships.userGroupRule.data[0],
        ) as { id: string; type: 'UserGroupRule' }[];

        const rulesIncluded =
          rulesList.map((value) =>
            included?.find(
              (item) =>
                item.id === value?.id && item.type === 'UserGroupRule',
            ),
          ) || [];

        return rulesIncluded;
      }) || [];
    return {
      userId: user.id,
      rules: groupRules,
    };
  });

export const fetchOwners = createAsyncThunk<DzOwner[]>(
  'owners/get',
  async () => {
    try {
      const {
        data: { data, included },
      } = await API.getUser({
        page: { disabled: true },
        include: [
          UserRelations.partner,
          'userGroup.userGroupRuleValue.userGroupRule',
        ],
      });

      const rules = getUserRulesWithIncluded(data, included!);
      return data.map((user) => {
        const userRules =
          rules
            .find((items) => items.userId === user.id)
            ?.rules?.flatMap((userGroup) =>
              userGroup.map((el) => el?.id as string),
            ) || [];
        return {
          ...userToOwner(user),
          userGroupRules: userRules,
        };
      });
    } catch (error) {
      logError(error);
      return [];
    }
  },
);

export const postOwner = createAsyncThunk(
  'owners/post',
  async (options: PostUserOptions, thunkAPI) => {
    try {
      await API.postUser(options.owner);

      const reload =
        typeof options.reload === 'boolean' ? options.reload : true;

      if (reload) {
        thunkAPI.dispatch(reloadUsers());
      }

      return true;
    } catch (error) {
      logError(error);
      return error;
    }
  },
);
export interface PostUserOptions {
  owner: NewUser;
  /**
   * @default true
   */
  reload?: boolean;
}
export interface NewUser {
  firstName: string;
  lastName: string;
  email: string;
}

export const reloadUsers = createAsyncThunk(
  'owners/reloadDependencies',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(fetchOwners());
    thunkAPI.dispatch(thunks.fetchNewProjectDropDownData());
  },
);

export const deleteOwner = createAsyncThunk(
  'owners/delete',
  async ({ ownerId }: { ownerId: DzOwner['ownerId'] }, thunkAPI) => {
    try {
      await API.deleteUser(ownerId);
      thunkAPI.dispatch(thunks.fetchNewProjectDropDownData());
    } catch (error) {
      // thunkAPI.rejectWithValue
      logError(error);
      return [];
    }
  },
);

type TOwnersSlice = DzOwner[];

const initialState: TOwnersSlice = [];

export const ownersSlice = createSlice({
  name: 'owners',
  initialState,
  reducers: {
    setOwners: (
      _: DzOwner[],
      action: PayloadAction<DzOwner[]>,
    ): DzOwner[] => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchOwners.fulfilled,
        (
          _,
          action: ReturnType<typeof fetchOwners.fulfilled>,
        ): DzOwner[] => {
          return action.payload;
        },
      )
      .addCase(
        deleteOwner.pending,
        (
          state,
          action: ReturnType<typeof deleteOwner.pending>,
        ): DzOwner[] => {
          return state.filter(
            (owner) => owner.ownerId !== action.meta.arg.ownerId,
          );
        },
      );
  },
});

const domain = (state: { owners: TOwnersSlice }) => state.owners;
export const selectOwners = createSelector(domain, (owners) => {
  return owners.slice().sort((a, b) => (a.name >= b.name ? 1 : -1));
});
