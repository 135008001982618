import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback, useEffect } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { csn } from '@one-vision/utils';
import { useStyles } from './dz-copy-tooltip.styles';
export const CopyTooltip = ({ textToCopy, children, classNames, titleToShow, disable }) => {
    const [tooltipText, setTooltipText] = useState('Click to copy');
    useEffect(() => {
        setTooltipText(titleToShow ? titleToShow : 'Click to copy');
    }, [titleToShow]);
    const classes = useStyles();
    const handleCopyToClipboard = useCallback(() => {
        if (textToCopy) {
            navigator.clipboard.writeText(textToCopy);
            setTooltipText('Copied!');
        }
    }, [textToCopy, tooltipText]);
    const onMouseOutHandler = useCallback(() => {
        setTooltipText(titleToShow ? titleToShow : 'Click to copy');
    }, [titleToShow, setTooltipText]);
    return (_jsx(Tooltip, Object.assign({ title: _jsxs(_Fragment, { children: [_jsx(Typography, Object.assign({ align: "center", variant: "subtitle2", classes: { subtitle2: classes.tooltipFirstLine } }, { children: tooltipText })), !!titleToShow && tooltipText !== 'Copied!' && (_jsx(Typography, Object.assign({ align: "center", variant: "subtitle2", classes: { subtitle2: classes.tooltipSecondLine } }, { children: 'Click to copy' })))] }), onMouseOut: onMouseOutHandler, className: csn(classes.underlinedTextOnHover, classNames), classes: { popper: classes.copyTooltipPopper }, disableHoverListener: disable }, { children: _jsx("div", Object.assign({ onClick: handleCopyToClipboard }, { children: children })) })));
};
