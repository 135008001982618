import { useCallback, useEffect, useState } from 'react';
import { useApiClient } from '../dz-api-client-provider';
import { usePartnerConfig } from '../dz-partner-config-provider';
export const usePreSoldMembership = ({ addressId }) => {
    const apiClient = useApiClient();
    const partnerConfig = usePartnerConfig();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [preSoldMembership, setPreSoldMembership] = useState(undefined);
    const fetchPreSoldMembershipId = useCallback(async () => {
        try {
            const { data } = await apiClient.getPreSoldMembership({
                filter: { addressId },
                include: ['productName', 'address'],
            });
            if (data.data.length) {
                const relationships = data.data[0].relationships;
                setPreSoldMembership(Object.assign(Object.assign({}, data.data[0].attributes), { productNameId: Number(relationships === null || relationships === void 0 ? void 0 : relationships.productName.data[0].id) || 0, addressId: (relationships === null || relationships === void 0 ? void 0 : relationships.address.data[0].id) || '' }));
            }
            setLoading(false);
        }
        catch (e) {
            setError('Error fetching pre-sold membership');
        }
    }, [addressId]);
    useEffect(() => {
        if (addressId && partnerConfig.accessToPreSoldMemberships) {
            fetchPreSoldMembershipId();
        }
        else {
            setLoading(false);
        }
    }, [addressId, partnerConfig, fetchPreSoldMembershipId]);
    return {
        preSoldMembership,
        loading,
        error,
    };
};
