const codes = {
    Enter: 'Enter',
    ArrowDown: 'ArrowDown',
    ArrowUp: 'ArrowUp',
    Escape: 'Escape',
    Backspace: 'Backspace',
    End: 'End',
    Home: 'Home',
    Tab: 'Tab',
};
const keyCodeMap = {
    13: codes.Enter,
    27: codes.Escape,
    38: codes.ArrowUp,
    40: codes.ArrowDown,
    8: codes.Backspace,
    9: codes.Tab,
    35: codes.End,
    36: codes.Home,
};
class KeyboardEventHelper {
    constructor() {
        this.keys = codes;
        this.getKey = (event) => {
            if (event.key !== undefined) {
                return codes[event.key];
            }
            else if (event.keyCode !== undefined) {
                return keyCodeMap[event.keyCode];
            }
            else {
                return;
            }
        };
        this.isEnter = (event) => this.getKey(event) === this.keys.Enter;
    }
}
export const keyboardEventHelper = new KeyboardEventHelper();
