import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DzProject } from '@one-vision/provision-ui';

export interface ProjectSidebarSliceState {
  isOpen: boolean;
  ovprjid: DzProject['ovprjid'];
}

const initialState: ProjectSidebarSliceState = {
  isOpen: false,
  ovprjid: '',
};

export const projectSidebarSlice = createSlice({
  name: 'project-drawer',
  initialState,
  reducers: {
    updateProjectSidebarState: (
      state,
      action: PayloadAction<Partial<ProjectSidebarSliceState>>,
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
});
