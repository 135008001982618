import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

import { useStyles } from './dz-icons.styles';

export const DzUploadIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.root} viewBox="0 0 126 100" {...props}>
      <path
        d="M113 12.5H63L50.5 0H13C6.125 0 0.5625 5.625 0.5625 12.5L0.5 87.5C0.5 94.375 6.125 100 13 100H113C119.875 100 125.5 94.375 125.5 87.5V25C125.5 18.125 119.875 12.5 113 12.5ZM113 87.5H13V12.5H45.3125L57.8125 25H113V87.5ZM46.8125 65.125L56.75 55.25V81.25H69.25V55.25L79.1875 65.1875L88 56.3125L63.0625 31.25L38 56.3125L46.8125 65.125Z"
        fill="#FF8080"
      />
    </SvgIcon>
  );
};
