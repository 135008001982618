const sort = (a, b) => a.name.localeCompare(b.name);
/* cSpell:disable */
export const primaryEquipmentLocation = [
    { name: `Attic`, key: `location_attic` },
    { name: `Basement`, key: `location_basement` },
    { name: `Bathroom`, key: `location_bathroom` },
    { name: `Break Room`, key: `location_break_room` },
    { name: `Cabinet`, key: `location_cabinet` },
    { name: `Conference Room`, key: `location_conference_room` },
    { name: `Crawlspace`, key: `location_crawlspace` },
    { name: `Den`, key: `location_den` },
    { name: `Exercise Room`, key: `location_exercise_room` },
    { name: `Family`, key: `location_family` },
    { name: `Foyer`, key: `location_foyer` },
    { name: `Front Closet`, key: `location_front_closet` },
    { name: `Game Room`, key: `location_game_room` },
    { name: `Garage`, key: `location_garage` },
    { name: `Guest House`, key: `location_guest_house` },
    { name: `Gym`, key: `location_gym` },
    { name: `Hallway`, key: `location_hallway` },
    { name: `IT Room`, key: `location_it_room` },
    { name: `Kids Room`, key: `location_kids_room` },
    { name: `Kitchen`, key: `location_kitchen` },
    { name: `Laundry`, key: `location_laundry` },
    { name: `Library`, key: `location_library` },
    { name: `Living`, key: `location_living` },
    { name: `Lobby`, key: `location_lobby` },
    { name: `Man Cave`, key: `location_man_cave` },
    { name: `Master Bed`, key: `location_master_bed` },
    { name: `Mechanical Room`, key: `location_mechanical_room` },
    { name: `Office/Study`, key: `location_office/study` },
    { name: `Other - In Notes`, key: `location_other` },
    { name: `Other Bed`, key: `location_other_bed` },
    { name: `Other Closet`, key: `location_other_closet` },
    { name: `Other Conference Room`, key: `location_other_conference_room` },
    { name: `Pantry`, key: `location_pantry` },
    { name: `Play Room`, key: `location_play_room` },
    { name: `Pool House`, key: `location_pool_house` },
    { name: `Rack Room`, key: `location_rack_room` },
    { name: `Reception Area`, key: `location_reception_area` },
    { name: `Rooftop`, key: `location_rooftop` },
    { name: `Shed`, key: `location_shed` },
    { name: `Storage Closet`, key: `location_storage_closet` },
    { name: `Theater`, key: `location_theater` },
    { name: `Under Stairs`, key: `location_under_stairs` },
].sort(sort);
export const networkLocation = [
    { name: `Attic`, key: `modem_attic` },
    { name: `Basement`, key: `modem_basement` },
    { name: `Bathroom`, key: `modem_bathroom` },
    { name: `Break Room`, key: `modem_break_room` },
    { name: `Cabinet`, key: `modem_cabinet` },
    { name: `Conference Room`, key: `modem_conference_room` },
    { name: `Crawlspace`, key: `modem_crawlspace` },
    { name: `Den`, key: `modem_den` },
    { name: `Exercise Room`, key: `modem_exercise_room` },
    { name: `Family`, key: `modem_family` },
    { name: `Foyer`, key: `modem_foyer` },
    { name: `Front Closet`, key: `modem_front_closet` },
    { name: `Game Room`, key: `modem_game_room` },
    { name: `Garage`, key: `modem_garage` },
    { name: `Guest House`, key: `modem_guest_house` },
    { name: `Gym`, key: `modem_gym` },
    { name: `Hallway`, key: `modem_hallway` },
    { name: `IT Room`, key: `modem_it_room` },
    { name: `Kids Room`, key: `modem_kids_room` },
    { name: `Kitchen`, key: `modem_kitchen` },
    { name: `Library`, key: `modem_library` },
    { name: `Living`, key: `modem_living` },
    { name: `Lobby`, key: `modem_lobby` },
    { name: `Man Cave`, key: `modem_man_cave` },
    { name: `Master Bed`, key: `modem_master_bed` },
    { name: `Mechanical Room`, key: `modem_mechanical_room` },
    { name: `Office/Study`, key: `modem_office/study` },
    { name: `Other - In Notes`, key: `modem_other` },
    { name: `Other Bed`, key: `modem_other_bed` },
    { name: `Other Closet`, key: `modem_other_closet` },
    { name: `Other Conference Room`, key: `modem_other_conference_room` },
    { name: `Play Room`, key: `modem_play_room` },
    { name: `Pool House`, key: `modem_pool_house` },
    { name: `Rack Room`, key: `modem_rack_room` },
    { name: `Reception Area`, key: `modem_reception_area` },
    { name: `Rooftop`, key: `modem_rooftop` },
    { name: `Shed`, key: `modem_shed` },
    { name: `Storage Closet`, key: `modem_storage_closet` },
    { name: `Theater`, key: `modem_theater` },
    { name: `Under Stairs`, key: `modem_under_stairs` },
    { name: `With Primary Equipment`, key: `modem_with_primary_equipment` },
].sort(sort);
export const thirdPartyNetworkProvider = [
    { name: `Access Networks`, key: `3rd_party_network_access_networks` },
    { name: `WhyReboot`, key: `3rd_party_network_whyreboot` },
    { name: `Not Applicable`, key: `3rd_party_network_not_applicable` },
    { name: `Other - In Notes`, key: `3rd_party_network_other` },
].sort(sort);
export const wiredNetworkBrand = [
    {
        name: `Supported By 3rd Party`,
        key: `wiredbrand_supported_by_3rd_party`,
    },
    { name: `Aerohive`, key: `wiredbrand_aerohive` },
    { name: `Apple AirPort`, key: `wiredbrand_apple_airport` },
    { name: `Araknis (SnapAV)`, key: `wiredbrand_araknis_snapav` },
    { name: `Belkin`, key: `wiredbrand_belkin` },
    { name: `Cisco`, key: `wiredbrand_cisco` },
    { name: `D-Link`, key: `wiredbrand_d-link` },
    { name: `Eero`, key: `wiredbrand_eero` },
    { name: `EnGenius`, key: `wiredbrand_engenius` },
    { name: `GrandStream`, key: `wired_grandstream` },
    { name: `Juniper`, key: `wiredbrand_juniper` },
    { name: `Linksys`, key: `wiredbrand_linksys` },
    { name: `Luxul`, key: `wiredbrand_luxul` },
    { name: `Meraki`, key: `wiredbrand_meraki` },
    { name: `MikroTik`, key: `wiredbrand_microtik` },
    { name: `Netgear`, key: `wiredbrand_netgear` },
    { name: `Pakedge`, key: `wiredbrand_pakedge` },
    { name: `Ruckus`, key: `wiredbrand_ruckus` },
    { name: `TP-Link`, key: `wiredbrand_tp-link` },
    { name: `TrendNET`, key: `wiredbrand_trendnet` },
    { name: `Ubiquiti`, key: `wiredbrand_ubiquiti` },
    { name: `Other - In Notes`, key: `wiredbrand_other` },
    { name: `XFINITY From Comcast`, key: `wiredbrand_xfinity_from_comcast` },
    { name: `ISP Brand`, key: `wiredbrand_isp_brand` },
].sort(sort);
export const wirelessNetworkBrand = [
    {
        name: `Supported By 3rd Party`,
        key: `wirelessbrand_supported_by_3rd_party`,
    },
    { name: `Aerohive`, key: `wirelessbrand_aerohive` },
    { name: `Apple AirPort`, key: `wirelessbrand_apple_airport` },
    { name: `Araknis (SnapAV)`, key: `wirelessbrand_araknis_snapav` },
    { name: `Belkin`, key: `wirelessbrand_belkin` },
    { name: `Cisco`, key: `wirelessbrand_cisco` },
    { name: `D-Link`, key: `wirelessbrand_d-link` },
    { name: `Eero`, key: `wirelessbrand_eero` },
    { name: `EnGenius`, key: `wirelessbrand_engenius` },
    { name: `GrandStream`, key: `wireless_grandstream` },
    { name: `Juniper`, key: `wirelessbrand_juniper` },
    { name: `Linksys`, key: `wirelessbrand_linksys` },
    { name: `Luxul`, key: `wirelessbrand_luxul` },
    { name: `Meraki`, key: `wirelessbrand_meraki` },
    { name: `MikroTik`, key: `wirelessbrand_microtik` },
    { name: `Netgear`, key: `wirelessbrand_netgear` },
    { name: `Pakedge`, key: `wirelessbrand_pakedge` },
    { name: `Ruckus`, key: `wirelessbrand_ruckus` },
    { name: `TP-Link`, key: `wirelessbrand_tp-link` },
    { name: `TrendNET`, key: `wirelessbrand_trendnet` },
    { name: `Ubiquiti`, key: `wirelessbrand_ubiquiti` },
    { name: `Other - In Notes`, key: `wirelessbrand_other` },
    {
        name: `XFINITY From Comcast`,
        key: `wirelessbrand_xfinity_from_comcast`,
    },
    { name: `ISP Brand`, key: `wirelessbrand_isp_brand` },
].sort(sort);
export const controlSystemBrand = [
    { name: `AMX`, key: `control_amx` },
    { name: `Bond Bridge`, key: `control_bond_bridge` },
    { name: `Control4`, key: `control_control4` },
    { name: `Crestron`, key: `control_crestron` },
    { name: `Crestron Home`, key: `control_crestron_home` },
    { name: `Elan`, key: `control_elan` },
    { name: `Identicard`, key: `control_identicard` },
    { name: `Josh.ai`, key: `control_joshai` },
    { name: `Logitech`, key: `control_logitech` },
    { name: `Not Present`, key: `control_not_present` },
    { name: `Other - In Notes`, key: `control_other` },
    { name: `ProControls`, key: `control_procontrols` },
    { name: `RTI`, key: `control_rti` },
    { name: `Savant`, key: `control_savant` },
    {
        name: `Supported By 3rd Party`,
        key: `control_supported_by_3rd_party`,
    },
    {
        name: `Universal Remote Control`,
        key: `control_universal_remote_control`,
    },
].sort(sort);
export const internetServiceProvider = [
    { name: `Armstrong`, key: `internet_armstrong` },
    { name: `AT&T Fiber`, key: `internet_att_fiber` },
    { name: `AT&T Uverse`, key: `internet_att_uverse` },
    { name: `Allwest Communications`, key: `internet_allwest_communications` },
    { name: `Bell`, key: `internet_bell` },
    { name: `Blue Ridge Communications`, key: `internet_blue_ridge_communications` },
    { name: `C Spire Fiber`, key: `internet_c_spire_fiber` },
    { name: `Cable ONE`, key: `internet_cable_one` },
    { name: `CenturyLink`, key: `internet_centurylink` },
    { name: `Charter Spectrum`, key: `internet_charter_spectrum` },
    { name: `Cincinnati Bell`, key: `internet_cincinnati_bell` },
    { name: `Cogeco`, key: `internet_cogeco` },
    { name: `Comcast Business`, key: `internet_comcast_business` },
    { name: `Consolidated Communications`, key: `internet_consolidated_communications` },
    { name: `Cox Communications`, key: `internet_cox_communications` },
    { name: `Delta Cable`, key: `internet_delta_cable` },
    { name: `DirecTV`, key: `internet_directv` },
    { name: `Eatel`, key: `internet_eatel` },
    { name: `Fidelity`, key: `internet_fidelity` },
    { name: `Frontier Communications`, key: `internet_frontier_communications` },
    { name: `GigaMonster`, key: `internet_gigamonster` },
    { name: `Google Fiber`, key: `internet_google_fiber` },
    { name: `GVTC`, key: `internet_gvtc` },
    { name: `GVTC Fiber`, key: `internet_gvtc_fiber` },
    { name: `Hargray`, key: `internet_hargray` },
    { name: `Hawaiian Telcom`, key: `internet_hawaiian_telcom` },
    { name: `Hotwire Communications`, key: `internet_hotwire_communications` },
    { name: `iWisp`, key: `internet_iwisp` },
    { name: `LUS Fiber`, key: `internet_lus_fiber` },
    { name: `Mediacom Cable`, key: `internet_mediacom_cable` },
    { name: `Not Present`, key: `internet_not_present` },
    { name: `Optimum By Cablevision`, key: `internet_optimum_by_cablevision` },
    { name: `Other - In Notes`, key: `internet_other` },
    { name: `Ranch`, key: `internet_ranch` },
    { name: `RCN`, key: `internet_rcn` },
    { name: `Rogers`, key: `internet_rogers` },
    { name: `Shaw`, key: `internet_shaw` },
    { name: `Suddenlink Communications`, key: `internet_suddenlink_communications` },
    { name: `Supported By 3rd Party`, key: `internet_supported_by_3rd_party` },
    { name: `Telus`, key: `internet_telus` },
    { name: `Verizon Fios`, key: `internet_verizon_fios` },
    { name: `Wave Broadband`, key: `internet_wave_broadband` },
    { name: `Windstream`, key: `internet_windstream` },
    { name: `WOW!`, key: `internet_wow` },
    { name: `Southern Light`, key: `internet_southernlight` },
    { name: `Starlink`, key: `internet_starlink` },
    { name: `Summit Broadband`, key: `internet_summit_broadband` },
    { name: `Visionary Broadband`, key: `internet_visionary_broadband` },
    { name: `XFINITY From Comcast`, key: `internet_xfinity_from_comcast` },
    { name: `Xplornet`, key: `internet_xplornet` },
    { name: `T-Mobile`, key: `internet_tmobile` },
].sort(sort);
export const tvServiceProvider = [
    { name: `Armstrong`, key: `tv_armstrong` },
    { name: `AT&T Uverse`, key: `tv_att_uverse` },
    { name: `Bell`, key: `tv_bell` },
    { name: `Blue Ridge Communications`, key: `tv_blue_ridge_communications` },
    { name: `C Spire Fiber`, key: `tv_c_spire_fiber` },
    { name: `Cable ONE`, key: `tv_cable_one` },
    { name: `CenturyLink`, key: `tv_centurylink` },
    { name: `Charter Spectrum`, key: `tv_charter_spectrum` },
    { name: `Cincinnati Bell`, key: `tv_cincinnati_bell` },
    { name: `Cogeco`, key: `tv_cogeco` },
    { name: `Consolidated Communications`, key: `tv_consolidated_communications` },
    { name: `Cox Communications`, key: `tv_cox_communications` },
    { name: `Delta Cable`, key: `tv_delta_cable` },
    { name: `DirecTV`, key: `tv_directv` },
    { name: `Dish Network`, key: `tv_dish_network` },
    { name: `Frontier Communications`, key: `tv_frontier_communications` },
    { name: `Google Fiber`, key: `tv_google_fiber` },
    { name: `Hawaiian Telcom`, key: `tv_hawaiian_telcom` },
    { name: `Hotwire Communications`, key: `tv_hotwire_communications` },
    { name: `Mediacom Cable`, key: `tv_mediacom_cable` },
    { name: `Not Present`, key: `tv_not_present` },
    { name: `Optimum By Cablevision`, key: `tv_optimum_by_cablevision` },
    { name: `Other - In Notes`, key: `tv_other` },
    { name: `RCN Rogers`, key: `tv_rcn_rogers` },
    { name: `Shaw`, key: `tv_shaw` },
    { name: `Shaw Direct`, key: `tv_shaw_direct` },
    {
        name: `Suddenlink Communications`,
        key: `tv_suddenlink_communications`,
    },
    { name: `Summit Broadband`, key: `tv_summit_broadband` },
    { name: `Supported By 3rd Party`, key: `tv_supported_by_3rd_party` },
    { name: `Telus`, key: `tv_telus` },
    { name: `Tivo`, key: `tv_tivo` },
    { name: `Verizon Fios`, key: `tv_verizon_fios` },
    { name: `Wave Broadband`, key: `tv_wave_broadband` },
    { name: `Windstream`, key: `tv_windstream` },
    { name: `WOW!`, key: `tv_wow` },
    { name: `XFINITY From Comcast`, key: `tv_xfinity_from_comcast` },
    { name: `Xplornet`, key: `tv_xplornet` },
    { name: `YouTubeTV`, key: `tv_youtubetv` },
].sort(sort);
export const remoteSystemManagement = [
    { name: `Big Dog Power`, key: `rsmtool_big_dog_power` },
    { name: `BlueBolt`, key: `rsmtool_bluebolt` },
    { name: `Domotz`, key: `rsmtool_domotz` },
    { name: `Lutron Connect`, key: `rsmtool_lutron_connect` },
    { name: `OvrC`, key: `rsmtool_ovrc` },
    { name: `PolyLens`, key: `rsmtool_polylens` },
    { name: `Savant Central`, key: `rsmtool_savant_central` },
    { name: `UniFi`, key: `rsmtool_unifi` },
    { name: `Not Present`, key: `rsmtool_not_present` },
].sort(sort);
export const audioSystemType = [
    { name: `Supported By 3rd Party`, key: `audio_supported_by_3rd_party` },
    { name: `Local`, key: `audio_local` },
    { name: `Distributed`, key: `audio_distributed` },
    { name: `Not Present`, key: `audio_not_present` },
].sort(sort);
export const primaryAudioInterface = [
    { name: `AirPlay`, key: `audiosys_airplay` },
    { name: `Autonomic Controls`, key: `audiosys_autonomic_controls` },
    { name: `Control System`, key: `audiosys_control_system` },
    { name: `Control4`, key: `audiosys_control4` },
    { name: `DBX`, key: `audiosys_dbx` },
    { name: `Denon Heos`, key: `audiosys_denon_heos` },
    { name: `Mood Media`, key: `audiosys_mood_media` },
    { name: `Not Present`, key: `audiosys_not_present` },
    { name: `Other - In Notes`, key: `audiosys_other` },
    { name: `PlayNetwork`, key: `audiosys_playnetwork` },
    { name: `Russound`, key: `audiosys_russound` },
    { name: `Sonos`, key: `audiosys_sonos` },
    { name: `Soundmachine`, key: `audiosys_soundmachine` },
    { name: `Symmetrix`, key: `audiosys_symmetrix` },
    { name: `Savant Music Server`, key: `audiosys_savant_music_server` },
    {
        name: `Supported By 3rd Party`,
        key: `audiosys_supported_by_3rd_party`,
    },
    { name: `Symmetrix`, key: `audiosys_symmetrix` },
].sort(sort);
export const streamingPlatform = [
    { name: `Apple TV`, key: `stream_apple_tv` },
    { name: `BluRay Player`, key: `stream_bluray_player` },
    { name: `Fire Device`, key: `stream_fire_device` },
    { name: `Google Chromecast`, key: `stream_google_chromecast` },
    { name: `Google TV`, key: `stream_google_tv` },
    { name: `K-Scape`, key: `stream_k-scape` },
    { name: `Multiple Devices - See Org Note`, key: `stream_multiple` },
    { name: `Nvidia Shield TV`, key: `stream_nvidia_shield_tv` },
    { name: `PlayStation`, key: `stream_playstation` },
    { name: `Plex`, key: `stream_plex` },
    { name: `Roku`, key: `stream_roku` },
    { name: `Smart TV`, key: `stream_smart_tv` },
    { name: `XBox`, key: `stream_xbox` },
    { name: `Xfinity Flex`, key: `stream_xfinity_flex` },
    { name: `YouTubeTV`, key: `stream_youtubetv` },
].sort(sort);
export const videoSystemType = [
    {
        name: `Supported By 3rd Party`,
        key: `video_system_supported_by_3rd_party`,
    },
    { name: `Local`, key: `video_system_local` },
    { name: `Distributed`, key: `video_system_distributed` },
    { name: `Other - In Notes`, key: `video_system_other` },
    { name: `Not Present`, key: `video_system_not_present` },
    { name: `HDMI balun`, key: `video_hdmi-balun` },
].sort(sort);
export const videoDistributionBrand = [
    { name: `Atlona`, key: `videosys_atlona` },
    { name: `AVPro`, key: `videosys_avpro` },
    { name: `AVPro Edge`, key: `videosys_avpr_edge` },
    { name: `Control4`, key: `videosys_control4` },
    { name: `Crestron`, key: `videosys_crestron` },
    { name: `JustAddPower`, key: `videosys_justaddpower` },
    { name: `Mirage`, key: `videosys_mirage` },
    { name: `Not Present`, key: `videosys_not_present` },
    { name: `Other - In Notes`, key: `videosys_other` },
    { name: `Savant`, key: `videosys_savant` },
    { name: `Savant VOIP`, key: `videosys_savant_voip` },
    { name: `SnapAV`, key: `videosys_snapav` },
    {
        name: `Supported By 3rd Party`,
        key: `videosys_supported_by_3rd_party`,
    },
    { name: `Wyrestorm`, key: `videosys_wyrestorm` },
    { name: `Binary (Snap AV)`, key: `videosys_binary` },
    { name: `iConnects`, key: `videosys_iconnects` },
].sort(sort);
export const shadeType = [
    { name: `Wired`, key: `shadetype_wired` },
    { name: `Wireless`, key: `shadetype_wireless` },
    { name: `Not Present`, key: `shadetype_not_present` },
    { name: `Hybrid`, key: `Hybridshadetype_hybrid` },
].sort(sort);
export const shadeInterface = [
    { name: `Control System`, key: `shades_control_system` },
    { name: `Native Interface`, key: `shades_native_interface` },
    { name: `Not Present`, key: `shades_not_present` },
].sort(sort);
export const shadeBrand = [
    { name: `Acmeda Rollease`, key: `shadebrand_acmeda_rollease` },
    { name: `Conrad`, key: `shadebrand_conrad` },
    { name: `Crestron`, key: `shadebrand_crestron` },
    { name: `Draper`, key: `shadebrand_draper` },
    { name: `Geiger`, key: `shadebrand_geiger` },
    { name: `Hartmann & Forbes`, key: `shadebrand_hartmann_and_forbes` },
    { name: `Hunter Douglas`, key: `shadebrand_hunter_douglas` },
    { name: `Insolroll`, key: `shadebrand_insolroll` },
    { name: `Lutron`, key: `shadebrand_lutron` },
    { name: `Mechoshade`, key: `shadebrand_mechoshade` },
    { name: `Not Present`, key: `shadebrand_not_present` },
    { name: `Other - In Notes`, key: `shadebrand_other` },
    { name: `QMotion`, key: `shadebrand_qmotion` },
    { name: `Savant`, key: `shadebrand_savant` },
    { name: `Screen Innovations`, key: `shadebrand_screen_innovations` },
    {
        name: `Solar Shading System `,
        key: `shadebrand_solar_shading_system`,
    },
    {
        name: `Supported By 3rd Party`,
        key: `shadebrand_supported_by_3rd_party`,
    },
    { name: `Somfy`, key: `shadebrand_somfy` },
].sort(sort);
export const lightingSystemType = [
    {
        name: `Supported By 3rd Party`,
        key: `lighting_supported_by_3rd_party`,
    },
    { name: `Centralized`, key: `lighting_centralized` },
    { name: `Hybrid`, key: `lighting_hybrid` },
    { name: `Wireless`, key: `lighting_wireless` },
    { name: `Not Present`, key: `lighting_not_present` },
].sort(sort);
export const lightingBrand = [
    {
        name: `Supported By 3rd Party`,
        key: `light_brand_supported_by_3rd_party`,
    },
    { name: `Control4`, key: `light_brand_control4` },
    { name: `Crestron`, key: `light_brand_crestron` },
    { name: `Leviton - HomeKit`, key: `light_brand_leviton_homekit` },
    { name: `Leviton - Industrial`, key: `light_brand_leviton_industrial` },
    { name: `Leviton - WiFi`, key: `light_brand_leviton_wifi` },
    { name: `Leviton - ZWave`, key: `light_brand_leviton_zwave` },
    { name: `Lutron Caseta`, key: `light_brand_lutron_caseta` },
    { name: `Lutron Grafik Eye`, key: `light_brand_lutron_grafik_eye` },
    { name: `Lutron QSX`, key: `light_brand_lutron_qsx` },
    {
        name: `Lutron Homeworks Illumination`,
        key: `light_brand_lutron_homeworks_illumination`,
    },
    { name: `Lutron Homeworks QS`, key: `light_brand_lutron_homeworks_qs` },
    { name: `Lutron RadioRA 2`, key: `light_brand_lutron_radiora_2` },
    { name: 'Lutron RadioRA 3', key: `light_brand_lutron_radiora_3` },
    { name: `Phillips Hue`, key: `light_brand_phillips_hue` },
    { name: `Savant`, key: `light_brand_savant` },
    { name: `URC Vivido`, key: `light_brand_urc_vivido` },
    { name: `Vantage Infusion`, key: `light_brand_vantage_infusion` },
    { name: `Vantage Q Series`, key: `light_brand_vantage_q_series` },
    { name: `Other - In Notes`, key: `light_brand_other` },
    { name: `Not Present`, key: `light_brand_not_present` },
    { name: `Crestron Pyng`, key: `light_brand_crestron_pyng` },
    { name: `Lutron RA2 Select`, key: `light_brand_lutron_ra2_select` },
].sort(sort);
export const accessControlSystemSmartLocks = [
    { name: `August Lock`, key: `acs_august_lock` },
    { name: `Brivo`, key: `acs_brivo` },
    { name: `Danalock`, key: `acs_danalock` },
    { name: `DoorBird`, key: `acs_doorbird` },
    { name: `DoorKing`, key: `acs_doorking` },
    { name: `Electric Strikes`, key: `acs_electric_strikes` },
    { name: `Kantech`, key: `acs_kantech` },
    { name: `Kwikset`, key: `acs_kwikset` },
    { name: `Not Present`, key: `acs_not_present` },
    { name: `Other - In Notes`, key: `acs_other` },
    { name: `Ring DoorBell`, key: `acs_ring_doorbell` },
    { name: `Schlage`, key: `acs_schlage` },
    { name: `Sesame`, key: `acs_sesame` },
    { name: `Supported By 3rd Party`, key: `acs_supported_by_3rd_party` },
    { name: `Ubiquiti`, key: `acs_ubiquiti` },
    { name: `Yale`, key: `acs_yale` },
].sort(sort);
export const hvacIntegration = [
    {
        name: `Supported By 3rd Party`,
        key: `hvac_integration_supported_by_3rd_party`,
    },
    { name: `Yes`, key: `hvac_integration_yes` },
    { name: `Not Present`, key: `hvac_integration_not_present` },
].sort(sort);
export const hvacBrand = [
    { name: `2GIG`, key: `hvac_2gig` },
    { name: `Alarm.com`, key: `hvac_alarm.com` },
    { name: `Allure`, key: `hvac_allure` },
    { name: `Aprilaire`, key: `hvac_aprilaire` },
    { name: `Control4`, key: `hvac_control4` },
    { name: `Cool Automation`, key: `hvac_cool_automation` },
    { name: `Crestron`, key: `hvac_crestron` },
    { name: `Daikin`, key: `hvac_daikin` },
    { name: `Ecobee`, key: `hvac_ecobee` },
    { name: `Emerson`, key: `hvac_emerson` },
    { name: `Fujitsu`, key: `hvac_fujitsu` },
    { name: `Honeywell`, key: `hvac_honeywell` },
    { name: `Iris`, key: `hvac_iris` },
    { name: `Leviton`, key: `hvac_leviton` },
    { name: `Lutron`, key: `hvac_lutron` },
    { name: `Lux`, key: `hvac_lux` },
    { name: `Mitsubishi`, key: `hvac_mitsubishi` },
    { name: `Nest`, key: `hvac_nest` },
    { name: `Not Present`, key: `hvac_not_present` },
    { name: `Other - In Notes`, key: `hvac_other` },
    { name: `Savant`, key: `hvac_savant` },
    { name: `Supported By 3rd Party`, key: `hvac_supported_by_3rd_party` },
    { name: `Trane`, key: `hvac_trane` },
    { name: `Tekmar`, key: `hvac_tekmar` },
    { name: `Wiser`, key: `hvac_wiser` },
].sort(sort);
export const videoDoorbells = [
    { name: `2N`, key: `doorbell_2n` },
    { name: `August`, key: `doorbell_august` },
    { name: `ButterflyMX`, key: `doorbell_butterflymx` },
    { name: `Channel Vision`, key: `doorbell_channel_vision` },
    { name: `Control4`, key: `doorbell_control4` },
    { name: `Custom`, key: `doorbell_custom` },
    { name: `Doorbird`, key: `doorbell_doorbird` },
    { name: `Holovision`, key: `doorbell_holovision` },
    { name: `Lilin`, key: `doorbell_lilin` },
    { name: `Nest`, key: `doorbell_nest` },
    { name: `Not Present`, key: `doorbell_not_present` },
    { name: `Ring`, key: `doorbell_ring` },
    { name: `Savant`, key: `doorbell_savant` },
    { name: `Siedle`, key: `doorbell_siedle` },
    { name: `Skybell`, key: `doorbell_skybell` },
    { name: `Swann`, key: `doorbell_swann` },
    { name: `Vivint`, key: `doorbell_vivint` },
    { name: `Other - In Notes`, key: `doorbell_other` },
    {
        name: `Supported By 3rd Party`,
        key: `doorbell_supported_by_3rd_party`,
    },
].sort(sort);
export const phoneSystemType = [
    { name: `Supported By 3rd Party`, key: `phones_supported_by_3rd_party` },
    { name: `Basic`, key: `phones_basic` },
    { name: `Digital/PBX (with server)`, key: `phones_digitalpbx` },
    { name: `VoIP (w/o server)`, key: `phones_voip` },
    { name: `Not Present`, key: `phones_not_present` },
].sort(sort);
export const phoneBrand = [
    {
        name: `Supported By 3rd Party`,
        key: `phone_brand_supported_by_3rd_party`,
    },
    { name: `Aastra`, key: `phone_brand_aastra` },
    { name: `Ameriphone`, key: `phone_brand_ameriphone` },
    { name: `AT&T`, key: `phone_brand_at&t` },
    { name: `Avaya`, key: `phone_brand_avaya` },
    { name: `Bell Phones`, key: `phone_brand_bell_phones` },
    { name: `Cisco`, key: `phone_brand_cisco` },
    { name: `ClearOne`, key: `phone_brand_clearone` },
    { name: `GE`, key: `phone_brand_ge` },
    { name: `Motorola`, key: `phone_brand_motorola` },
    { name: `NEC`, key: `phone_brand_nec` },
    { name: `Panasonic`, key: `phone_brand_panasonic` },
    { name: `Philips`, key: `phone_brand_philips` },
    { name: `Polycom`, key: `phone_brand_polycom` },
    { name: `RCA`, key: `phone_brand_rca` },
    { name: `RingCentral`, key: `phone_brand_ringcentral` },
    { name: `Siemens`, key: `phone_brand_siemens` },
    { name: `Sony`, key: `phone_brand_sony` },
    { name: `Uniden`, key: `phone_brand_uniden` },
    { name: `Vtech`, key: `phone_brand_vtech` },
    { name: `Other - In Notes`, key: `phone_brand_other` },
    { name: `Not Present`, key: `phone_brand_not_present` },
    {
        name: `Hotwire Communications`,
        key: `phone_brand_hotwire_communications`,
    },
    { name: `Grandstream`, key: `phone_brand_grandstream` },
].sort(sort);
export const intercom = [
    {
        name: `Supported By 3rd Party`,
        key: `intercom_supported_by_3rd_party`,
    },
    { name: `2N`, key: `intercom_2n` },
    { name: `Aiphone`, key: `intercom_aiphone` },
    { name: `AT&T`, key: `intercom_att` },
    { name: `Avaya`, key: `intercom_avaya` },
    { name: `C3X`, key: `intercom_c3x` },
    { name: `Cisco`, key: `intercom_cisco` },
    { name: `Clear One`, key: `intercom_clear_one` },
    { name: `Control4`, key: `intercom_control4` },
    { name: `Crestron`, key: `intercom_crestron` },
    { name: `Digium`, key: `intercom_digium` },
    { name: `Elan`, key: `intercom_elan` },
    { name: `Holovision`, key: `intercom_holovision` },
    { name: `Intrasonic Intercoms`, key: `intercom_intrasonic_intercoms` },
    { name: `Legrand`, key: `intercom_legrand` },
    { name: `M&S`, key: `intercom_m&s` },
    { name: `Mitel`, key: `intercom_mitel` },
    { name: `NEC`, key: `intercom_nec` },
    { name: `On-Q`, key: `intercom_on-q` },
    { name: `Panasonic`, key: `intercom_panasonic` },
    { name: `Polycom`, key: `intercom_polycom` },
    { name: `RCA`, key: `intercom_rca` },
    { name: `Savant`, key: `intercom_savant` },
    { name: `Siedle`, key: `intercom_siedle` },
    { name: `Ubiquity`, key: `intercom_ubiquity` },
    { name: `Viking`, key: `intercom_viking` },
    { name: `Not Present`, key: `intercom_not_present` },
    { name: `Other - In Notes`, key: `intercom_other` },
].sort(sort);
export const securitySystemIntegrated = [
    { name: `Integrated`, key: `securityintegrated_integrated` },
    { name: `Not Integrated`, key: `securityintegrated_not_integrated` },
    { name: `Not Present`, key: `securityintegrated_not_present` },
].sort(sort);
export const securitySystemBrand = [
    {
        name: `Supported By 3rd Party`,
        key: `security_supported_by_3rd_party`,
    },
    { name: `Ademco Vista`, key: `security_ademco_vista` },
    { name: `2GIG`, key: `security_2gig` },
    { name: `ADT Pulse`, key: `security_adt_pulse` },
    { name: `DSC`, key: `security_dsc` },
    { name: `GE`, key: `security_ge` },
    { name: `HAI/Leviton`, key: `security_hai/leviton` },
    { name: `Interlogix`, key: `security_interlogix` },
    { name: `Qolsys`, key: `security_qolsys` },
    { name: `Vivint`, key: `security_vivint` },
    { name: `Other - In Notes`, key: `security_other` },
    { name: `Not Present`, key: `security_not_present` },
    { name: `Alarm.com`, key: `security_alarm.com` },
    { name: `Elk`, key: `security_elk` },
    { name: `NapCo`, key: `security_napco` },
    { name: `Clare Controls`, key: `security_clare-controls` },
    { name: `Ring`, key: `security_ring` },
].sort(sort);
export const cameraSystemType = [
    {
        name: `Supported By 3rd Party`,
        key: `camera_system_supported_by_3rd_party`,
    },
    { name: `Direct-to-Camera`, key: `camera_system_direct-to-camera` },
    { name: `On-Site NVR`, key: `camera_system_on-site_nvr` },
    { name: `Cloud-Based`, key: `camera_system_cloud-based` },
].sort(sort);
export const cameraSystemBrand = [
    { name: `Arlo`, key: `cambrand_arlo` },
    { name: `Alarm.com`, key: `cambrand_alarm.com` },
    { name: `Axis`, key: `cambrand_axis` },
    { name: `Dahua`, key: `cambrand_dahua` },
    { name: `Digital Watchdog`, key: `cambrand_digital_watchdog` },
    { name: `Flir`, key: `cambrand_flir` },
    { name: `Hikvision`, key: `cambrand_hikvision` },
    { name: `ICRealtime`, key: `cambrand_icrealtime` },
    { name: `Leviton`, key: `cambrand_leviton` },
    { name: `Lilin`, key: `cambrand_lilin` },
    { name: `Luma (SnapAV)`, key: `cambrand_luma_snapav` },
    { name: `Meraki`, key: `cambrand_meraki` },
    { name: `Nest`, key: `cambrand_nest` },
    { name: `Not Present`, key: `cambrand_not_present` },
    { name: `LTS`, key: `cambrand_lts` },
    { name: `Nuuo`, key: `cambrand_nuuo` },
    { name: `Other - In Notes`, key: `cambrand_other` },
    { name: `ProvisionISR`, key: `cambrand_provisionisr` },
    { name: `Ubiquiti CCTV`, key: `cambrand_ubiquiti_cctv` },
    { name: `Visualint`, key: `cambrand_visualint` },
    { name: `Ring`, key: `cambrand_ring` },
    { name: `Speco`, key: `cambrand_speco` },
    {
        name: `Supported By 3rd Party`,
        key: `cambrand_supported_by_3rd_party`,
    },
    { name: `Synology`, key: `cambrand_synology` },
    { name: `Visualint`, key: `cambrand_visualint` },
    { name: `Vivotek`, key: `cambrand_vivotek` },
    { name: `Wirepath (SnapAV)`, key: `cambrand_wirepath_snapav` },
].sort(sort);
