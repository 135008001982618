import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        tabContentHeader: {
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: `${theme.px2rem(16)} 0`,
            margin: `0 ${theme.px2rem(15)} ${theme.px2rem(25)}`,
            borderBottom: `2px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
        },
        tabContentHeaderZen: {
            padding: `${theme.px2rem(10)} 0`,
            margin: `0 ${theme.px2rem(5)} ${theme.px2rem(25)}`,
        },
        headerDivider: {
            marginBottom: theme.px2rem(29),
        },
        tabContent: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minHeight: theme.px2rem(240),
        },
        userContainer: {
            display: 'flex',
            alignItems: 'flex-start',
            borderBottom: `1px solid #E8E8E8`,
            marginBottom: theme.px2rem(20),
            '&:first-child': {
                borderTop: `1px solid #E8E8E8`,
                paddingTop: theme.px2rem(28),
            },
        },
        userDataContainer: {
            flex: 1,
            marginLeft: theme.px2rem(12),
            paddingBottom: theme.px2rem(18),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        userName: {
            fontWeight: 500,
            fontSize: theme.px2rem(16),
            lineHeight: 1.33,
        },
        hideTooWide: {
            maxWidth: theme.px2rem(250),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        userMenuButton: {
            marginRight: `-${theme.px2rem(12)}`,
        },
        primaryUserIcon: {
            cursor: 'pointer',
        },
        primaryUserIconInactive: {
            color: '#F6A609',
        },
        primaryUserIconActive: {
            color: '#828282',
        },
        listItemIcon: {
            minWidth: theme.px2rem(32),
        },
        circularProgress: {
            margin: 'auto',
        },
        circularProgressWrapper: {
            marginTop: theme.px2rem(16),
            textAlign: 'center',
        },
        emergenceAnimation: {
            opacity: 0,
            animation: '$emergence-anim .5s ease-in-out normal forwards',
        },
        noUserWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        noUserIcon: {
            marginBottom: theme.px2rem(12),
            color: '#8F9091',
            width: theme.px2rem(60),
            height: theme.px2rem(60),
        },
        noUserText: {
            fontWeight: 500,
            fontSize: theme.px2rem(14),
            lineHeight: theme.px2rem(18),
            color: theme.dz.colors.oneVisionBlack65,
        },
        '@keyframes emergence-anim': {
            '100%': {
                opacity: 1,
            },
        },
        searchInputWrapper: {
            width: '50%',
        },
        searchInputWrapperZen: {
            width: '60%',
        },
    };
}, { name: 'dz-org-client-list', index: 0 });
