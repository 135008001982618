import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useDzSelector, thunks, actions } from 'core/redux';
import { usePartner } from './usePartner';
import { DzPartnerDetails } from 'shared-ui';

export function usePartnerDetails() {
  const dispatch = useDispatch();
  const partner = usePartner();

  const partnerDetails = useDzSelector(
    (state) => state.settings.partnerDetails,
  );
  const partnerConfig = useDzSelector(
    (state) => state.settings.partnersConfig,
  );

  useEffect(() => {
    if (partner?.partnerId) {
      dispatch(
        thunks.fetchPartnerDetails({ ovpid: String(partner.partnerId) }),
      );
    }
  }, [partner?.partnerId, dispatch]);

  const updateDetails = useCallback(
    async (changes: Partial<DzPartnerDetails>) => {
      if (partner?.partnerId) {
        await dispatch(
          thunks.updatePartnerDetails({
            ovpid: String(partner.partnerId),
            changes,
          }),
        );
        dispatch(
          actions.updateSnackbar({
            text: 'Update successful',
            type: 'success',
          }),
        );
        return Promise.resolve();
      }

      dispatch(
        actions.updateSnackbar({
          text: 'Unable to update partner details, PARTNER_ID is missing',
          type: 'error',
        }),
      );
      return Promise.resolve();
    },
    [partner?.partnerId, dispatch],
  );

  return {
    data: partnerDetails?.ovpid ? partnerDetails : null,
    updatePartnerDetails: updateDetails,
    partnerConfig,
  };
}
