import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        message: {
            display: 'flex',
            alignItems: 'center',
        },
        filledSuccess: {
            backgroundColor: theme.dz.colors.success,
        },
        filledError: {
            backgroundColor: theme.dz.colors.error,
        },
        filledInfo: {
            backgroundColor: theme.palette.custom.OV.sys.surfaceContainer,
        },
    };
}, { name: 'dz-snackbar', index: 0 });
