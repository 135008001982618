import React, { useEffect, useCallback, useMemo } from 'react';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { actions, useDzSelector, thunks } from 'core/redux';
import { getOrganizationsColumns } from './dz-organizations-columns';
import { OrganizationsPreparedTableRow } from './types';
import { useOwnerPopup, usePartner } from 'shared/hooks';
import {
  useModalManager,
  ModalIDs,
  DzOwner,
  DzAddress,
  CreatePrjResult,
  DzAsyncDispatch,
  ApiClient,
  DzCreateOrganizationDialogProps,
  DzCreateProjectDialogProps,
  DzAddressPanelRelatedThunks,
  DzPartner,
  usePartnerConfig,
  ClientWithMultiContacts,
  DzAddClientDialogProps,
} from 'shared-ui';
import { LoadingStatus } from 'core/redux/loading.redux';
import { useOrganizationsViews } from './useOrganizationsViews';
import { API } from 'core/api';
import { makeOrganizationsSelectors } from './dz-organizations-selectors';
import { useSearchField } from 'components/shared/dz-layout-page';
import { selectOwners } from 'core/redux/owners.redux';
import { useSearchParams } from 'react-router-dom';
import { usePreSearchList } from 'shared/hooks';
import { URL_PARAM_NAMES, PreSearchLists } from 'shared/constants';
import { makeCheckIsAnyDialogActive } from 'shared';
import { OrganizationsViews } from 'shared/hooks/makeUseFavoriteViews';
import { CellClickedEvent } from 'ag-grid-community';
import { DzColDef, useSearchValue } from 'components/shared';

interface Output {
  rows: OrganizationsPreparedTableRow[];
  isLoading: boolean;
  organizationsColumns: DzColDef<OrganizationsPreparedTableRow>[];
  ownerPopupInfo: {
    anchor: null | Element;
    row: OrganizationsPreparedTableRow | null;
  };
  activeView: string;
  views: { id: string; text: string }[];
  searchText: string;
  favorites: string[];
  activeViewPreSearchList: string[];
  searchValue: string;
  handleCloseOwnerSelection: () => void;
  handleUpdateOwner: (
    id: string,
    ownerId: DzOwner['ownerId'] | null,
    name: DzOwner['name'] | undefined,
  ) => AnyAction;
  handleHeaderBtnClick: () => void;
  handleViewChange: (viewId: string) => void;
  onSearch: (searchText: string) => void;
  handleAddFavorite: (viewId: string) => void;
  handleRemoveFavorite: (viewId: string) => void;
  handleCellClick: (
    e: CellClickedEvent<OrganizationsPreparedTableRow>,
  ) => void;
  selectNextView: () => void;
  selectPrevView: () => void;
  handleAddOrganization: () => void;
  openSidebar: (id: string) => void;
  closeSidebar: () => void;
  checkIsAnyDialogActive: (isActive: boolean) => void;
  setActiveViewPreSearchListItem: () => void;
}

export const useOrganizationsPage = (): Output => {
  const dispatch = useDispatch<DzAsyncDispatch>();
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const checkIsAnyDialogActive = makeCheckIsAnyDialogActive(
    dispatch as Dispatch<any>,
  );

  const { openModal } = useModalManager();

  const [_searchParams, setSearchParams] = useSearchParams();

  const {
    ownerPopupInfo,
    setOwnerPopupInfo,
    handleCloseOwnerSelection,
    handleUpdateOwner,
  } = useOwnerPopup<OrganizationsPreparedTableRow>({
    updateOwner: actions.updateAddressItem,
  });

  const fetchAddresses = useCallback(() => {
    dispatch(thunks.fetchAddressShortList({}))
      .unwrap()
      .then((addresses) => {
        dispatch(actions.fetchedAllAddresses(addresses));
      });
  }, [dispatch]);

  useEffect(() => {
    fetchAddresses();
  }, [fetchAddresses]);

  const setOwnerPopupInfoAndAddress = useCallback(
    (
      popupInfo: React.SetStateAction<{
        anchor: Element | null;
        row: OrganizationsPreparedTableRow | null;
      }>,
      data: OrganizationsPreparedTableRow,
    ) => {
      dispatch(
        actions.addressUpdated({
          ovaid: data.ovaid,
          ownerId: data.ownerId,
          address1: data.address1,
          address2: data.address2,
          businessName: data.businessName,
          clientActivatedMembership: data.clientActivatedMembership,
          clientSelectedMembership: data.clientSelectedMembership,
          cppExpDate: data.cppExpDate,
          tosAccepted: data.tosAccepted,
          creditCardProvided: data.creditCardProvided,
        } as DzAddress),
      );

      setOwnerPopupInfo(popupInfo);
    },
    [setOwnerPopupInfo, dispatch],
  );

  const handleCellClick = useCallback(
    (e: CellClickedEvent<OrganizationsPreparedTableRow>) => {
      if (e.data && typeof e.data.ovaid === 'string') {
        const selectedRowId = e.data.ovaid;
        setSearchParams((oldParams) => {
          oldParams.set(URL_PARAM_NAMES.SELECTED, selectedRowId);
          return [...oldParams];
        });
        dispatch(
          actions.updateOrganizationDialogState({
            isOpen: true,
            ovaid: e.data?.ovaid,
            context: {
              recordId: e.data?.ovaid,
            },
          }),
        );
      }
    },
    // setSearchParams is not a stable function, do not include it in deps array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const partnerConfig = usePartnerConfig();

  const organizationsColumns: DzColDef<OrganizationsPreparedTableRow>[] =
    useMemo(() => {
      const columns = getOrganizationsColumns({
        setOwnerPopupInfo: setOwnerPopupInfoAndAddress,
      });

      if (
        !partnerConfig.loading &&
        !partnerConfig.accessToProvisionProjects
      ) {
        delete columns.primaryProject;
        delete columns.primaryDay1;
      }

      if (
        !partnerConfig.loading &&
        !partnerConfig.accessSubscriptionManagement
      ) {
        delete columns.creditCard;
        delete columns.desired;
        delete columns.tos;
      }

      return Object.values(columns);
    }, [setOwnerPopupInfoAndAddress, partnerConfig]);

  const {
    activeView,
    views,
    favorites,
    handleViewChange,
    handleAddFavorite,
    handleRemoveFavorite,
    selectNextView,
    selectPrevView,
  } = useOrganizationsViews();

  let viewsFiltered = views;
  if (!partnerConfig.loading && !partnerConfig.accessToProvisionProjects) {
    viewsFiltered = viewsFiltered.filter(
      (el) => el.id !== OrganizationsViews.withProject,
    );
  }

  const rows = useDzSelector((state) =>
    makeOrganizationsSelectors(state, activeView),
  );

  const owners = useDzSelector(selectOwners);
  const products = useDzSelector(
    (state) => state.newProjectDialog.products,
  );
  const clients = useDzSelector((state) => state.clients);
  const labels = useDzSelector((state) => state.identity);
  const partner = usePartner();

  const loading = useDzSelector((state) => state.loading);
  const hasData =
    !!rows.length ||
    (loading.addresses === LoadingStatus.Success &&
      loading.clients === LoadingStatus.Success &&
      loading.owners === LoadingStatus.Success);

  const isLoading = !hasData;

  const handleHeaderBtnClick = useCallback(() => {
    console.log('Header button click');
  }, []);

  const { searchText, onSearch } = useSearchField();

  const [allOrgPreSearchList, allOrgAddToPreSearchList] = usePreSearchList(
    searchText,
    PreSearchLists.organizationsAll,
  );
  const [activeCppOrgPreSearchList, activeCppOrgAddToPreSearchList] =
    usePreSearchList(searchText, PreSearchLists.organizationsActiveCpp);
  const [expiredCppOrgPreSearchList, expiredCppOrgAddToPreSearchList] =
    usePreSearchList(searchText, PreSearchLists.organizationsExpiredCpp);
  const [noPrjOrgPreSearchList, noPrjOrgAddToPreSearchList] =
    usePreSearchList(searchText, PreSearchLists.organizationsNoPrj);

  const getPreSearchListHook: () => [string[], () => void] = () => {
    switch (activeView) {
      case OrganizationsViews.allOrganizations:
        return [allOrgPreSearchList, allOrgAddToPreSearchList];
      case OrganizationsViews.activeCPPs:
        return [activeCppOrgPreSearchList, activeCppOrgAddToPreSearchList];
      case OrganizationsViews.expiredCPPs:
        return [
          expiredCppOrgPreSearchList,
          expiredCppOrgAddToPreSearchList,
        ];
      case OrganizationsViews.withProject:
        return [noPrjOrgPreSearchList, noPrjOrgAddToPreSearchList];
      default:
        return [
          [],
          () => {
            return;
          },
        ];
    }
  };

  const [activeViewPreSearchList, setActiveViewPreSearchListItem] =
    getPreSearchListHook();

  const handleAddOrganization = useCallback(async () => {
    const newAddress = await openModal<
      DzCreateOrganizationDialogProps,
      DzAddress
    >(ModalIDs.createOrganization, {
      apiClient: API as unknown as ApiClient,
      actions,
      dispatch,
      owners,
    });

    if (!newAddress) {
      return;
    }

    if (!partnerConfig.accessToProvisionProjects) {
      const result = await openModal<
        DzAddClientDialogProps,
        ClientWithMultiContacts
      >(ModalIDs.addClient, {
        ovaid: newAddress.ovaid,
        apiClient: API,
        actions,
        dispatch,
        thunks,
        allClients: clients,
        labels,
        zendesk: false,
      });

      if (!result) {
        return;
      }

      dispatch(
        thunks.updateAddress({
          ovaid: newAddress.ovaid as string,
          changes: { ownerId: result.ovcid },
        }),
      );
      fetchAddresses();

      return;
    }

    const result = await openModal<
      DzCreateProjectDialogProps,
      CreatePrjResult
    >(ModalIDs.createProject, {
      preselectedAddress: newAddress,
      apiClient: API as unknown as ApiClient,
      actions,
      dispatch,
      owners,
      products,
      thunks: thunks as DzAddressPanelRelatedThunks,
      allClients: clients,
      labels,
      zendesk: false,
      partner: partner as DzPartner,
    });

    if (!result) {
      return;
    }

    fetchAddresses();
  }, [
    fetchAddresses,
    owners,
    products,
    dispatch,
    clients,
    labels,
    openModal,
    partner,
    partnerConfig,
  ]);

  const closeSidebar = useCallback(() => {
    dispatch(
      actions.updateOrganizationDialogState({
        isOpen: false,
        ovaid: '',
        context: undefined,
      }),
    );
  }, [dispatch]);

  const openSidebar = useCallback(
    (ovaid: string) => {
      dispatch(
        actions.updateOrganizationDialogState({
          isOpen: true,
          ovaid: ovaid,
          context: {
            recordId: ovaid,
          },
        }),
      );
    },
    [dispatch],
  );

  const searchValue = useSearchValue();

  return {
    handleViewChange,
    handleHeaderBtnClick,
    handleCloseOwnerSelection,
    handleUpdateOwner,
    handleAddFavorite,
    handleRemoveFavorite,
    favorites: favorites['organization'],
    views: viewsFiltered,
    activeView,
    rows,
    isLoading,
    ownerPopupInfo,
    organizationsColumns,
    searchText,
    activeViewPreSearchList,
    onSearch,
    handleCellClick,
    selectNextView,
    selectPrevView,
    handleAddOrganization,
    openSidebar,
    closeSidebar,
    checkIsAnyDialogActive,
    setActiveViewPreSearchListItem,
    searchValue,
  };
};
