import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      csvLink: {
        marginTop: theme.spacing(1),
        color: theme.dz.colors.link,
      },
    };
  },
  { name: 'dz-csv-link' },
);
