import { jsx as _jsx } from "react/jsx-runtime";
import { Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { usePersistent } from './hooks/custom-hooks';
export const DzNotificationSnackbar = ({ onClose, text }) => {
    const textObj = usePersistent(() => ({ text }));
    if (text.trim() && text.trim() !== textObj.text) {
        textObj.text = text;
    }
    const handleClose = (_, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        onClose();
    };
    return (_jsx("div", { children: _jsx(Snackbar, { anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            }, open: Boolean(text), autoHideDuration: 6000, onClose: handleClose, message: text, action: _jsx(IconButton, Object.assign({ size: "small", "aria-label": "close", color: "inherit", onClick: handleClose }, { children: _jsx(CloseIcon, { fontSize: "small" }) })) }) }));
};
