import { jsx as _jsx } from "react/jsx-runtime";
import { Skeleton, Tab, Tabs } from '@mui/material';
import { useStyles } from './dz-tabs.styles';
const TabPlaceholder = () => {
    const classes = useStyles();
    return (_jsx(Tab, { className: classes.tab, icon: _jsx(Skeleton, { animation: "wave", variant: "circular", width: 24, height: 24, component: "div" }) }));
};
const DzTabsPlaceholder = () => {
    return (_jsx(Tabs, Object.assign({ value: 0 }, { children: new Array(4).fill(0).map((_, i) => (_jsx(TabPlaceholder, {}, i))) })));
};
export default DzTabsPlaceholder;
