import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
  () => {
    return {
      inputRoot: {
        padding: '8px 16px',
      },
    };
  },
  { name: 'dz-owner-selection-popup' },
);
