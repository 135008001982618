import { logError } from '@one-vision/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DzPostToTrayType } from 'shared-ui';
import { API } from '../../api';

export const postToTray = createAsyncThunk<
  [],
  {
    payload: unknown;
    type: DzPostToTrayType;
  }
>('postToTray', async (arg) => {
  const { payload, type } = arg;
  try {
    await API.postToTray(payload, type);
  } catch (error) {
    logError(error);
  }
  return [];
});
