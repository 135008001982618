export const writeToClipboard = async (data, mimeType) => {
    try {
        const blob = new Blob([data], {
            type: mimeType,
        });
        const item = new ClipboardItem({ [mimeType]: blob });
        await navigator.clipboard.write([item]);
    }
    catch (error) {
        console.error(error);
    }
};
