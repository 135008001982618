import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Slide, TextField, Autocomplete, IconButton, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { useCallback, useRef, useEffect, useState } from 'react';
import * as yup from 'yup';
import { logError } from '@one-vision/utils';
import { DzProjectStage } from '../../../types';
import { useDrawerStyles, useFormStyles, withModalManager, } from '../../shared';
import { Typography } from '@mui/material';
const DzProposalLostDialog = ({ isOpen, close, clear, apiClient, projectId }) => {
    const [lostReasonOptions, setLostReasonOptions] = useState(null);
    const selectRef = useRef(null);
    useEffect(() => {
        (async () => {
            try {
                const response = await apiClient.getProposalLostReasonDropDownData();
                setLostReasonOptions(response.data);
            }
            catch (error) {
                logError(error);
                close();
            }
        })();
    }, [setLostReasonOptions, selectRef, close]);
    const handleClose = useCallback(() => close(), [close]);
    const handleSubmit = useCallback(async (values) => {
        if (!projectId) {
            return;
        }
        try {
            const updateProjectResponse = await apiClient.updateProject(projectId, values);
            const [updatedProject] = updateProjectResponse.data;
            close(updatedProject);
        }
        catch (error) {
            logError(error);
        }
    }, [apiClient, close, lostReasonOptions, projectId]);
    const drawerClasses = useDrawerStyles();
    const formClasses = useFormStyles();
    const formik = useFormik({
        initialValues: {
            projectLostReasonId: 0,
            lostReasonNotes: '',
            projectStageId: DzProjectStage.LostProposal,
        },
        validateOnBlur: true,
        validationSchema: yup.object({
            projectLostReasonId: yup
                .number()
                .min(1, 'Please, select the reason.'),
        }),
        onSubmit: handleSubmit,
    });
    return (_jsxs(Dialog, Object.assign({ open: isOpen, onClose: handleClose, closeAfterTransition: true, transitionDuration: 400, TransitionComponent: Slide, TransitionProps: {
            direction: 'left',
            onExited: clear,
            mountOnEnter: true,
            unmountOnExit: true,
        }, classes: {
            paper: drawerClasses.paper,
            container: drawerClasses.container,
        }, disableRestoreFocus: true }, { children: [_jsxs(DialogTitle, Object.assign({ className: drawerClasses.header }, { children: [_jsx(Typography, { children: "Proposal Lost" }), _jsx(IconButton, Object.assign({ onClick: handleClose, color: "inherit" }, { children: _jsx(CloseIcon, { color: "inherit" }) }))] })), _jsx(DialogContent, { children: _jsxs("form", Object.assign({ id: "proposal-lost-form", className: formClasses.formContainer, onSubmit: formik.handleSubmit }, { children: [_jsxs(FormControl, Object.assign({ error: Boolean(formik.touched.projectLostReasonId &&
                                formik.errors.projectLostReasonId) }, { children: [_jsx(Autocomplete, { id: "projectLostReasonId", autoHighlight: true, openOnFocus: true, defaultValue: (lostReasonOptions === null || lostReasonOptions === void 0 ? void 0 : lostReasonOptions.find((el) => Number(el.id) === formik.values.projectLostReasonId)) || null, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Reason Lost", margin: "none" }))), options: lostReasonOptions || [], getOptionLabel: (option) => option.name, isOptionEqualToValue: (option) => Number(option.id) === formik.values.projectLostReasonId, onChange: (_, newValue) => {
                                        formik.setFieldValue('projectLostReasonId', newValue === null || newValue === void 0 ? void 0 : newValue.id);
                                    }, onBlur: formik.handleBlur }), formik.touched.projectLostReasonId &&
                                    formik.errors.projectLostReasonId && (_jsx(FormHelperText, { children: formik.errors.projectLostReasonId }))] })), _jsx(TextField, { id: "lostReasonNotes", label: 'Additional Notes', multiline: true, maxRows: 10, minRows: 10, InputLabelProps: {
                                shrink: true,
                            }, placeholder: `Type here`, value: formik.values.lostReasonNotes, onChange: formik.handleChange })] })) }), _jsx(DialogActions, Object.assign({ className: drawerClasses.actions }, { children: _jsx(Button, Object.assign({ size: "large", variant: "contained", color: "primary", type: "submit", form: "proposal-lost-form", disabled: !formik.dirty || formik.isSubmitting }, { children: "Submit" })) }))] })));
};
export default withModalManager()(DzProposalLostDialog);
