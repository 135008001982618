import { makeStyles } from '@mui/styles';

import { ExtTheme } from '../../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      titleContainer: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      title: {
        fontSize: '1.28rem',
        fontWeight: theme.typography.fontWeightMedium,
      },
      closeButton: {
        marginRight: theme.spacing(-2),
        marginTop: theme.spacing(-1),
      },
    };
  },
  { name: 'dz-bulk-users-importer-header' },
);
