import axios, { AxiosError, AxiosResponse } from 'axios';
import { submitError } from 'shared';
import {
  ClientWithMultiContacts,
  DzAddress,
  DzBrand,
  DzClient,
  DzClientLabel,
  DzClientToAddress,
  DzDropDownItem,
  DzIdentityLabels,
  DzOwner,
  DzPartner,
  DzPostToTrayType,
  DzProject,
  DzProjectLostReason,
  EmailMembershipInfo,
  GenerateCustomerPortal,
  GenerateCustomerPortalResponse,
  IDzProjectStage,
  JsonApiEntity,
  JsonApiGetUsersResponse,
  JsonApiMeta,
  JsonApiParams,
  NewProjectDropDowns,
  OrgDialogProject,
  PayloadAddEditClient,
  PreSoldMembershipPayload,
  PreSoldMembershipResponse,
  ProductOffered,
  SendSlackMessagePayload,
  SubscriptionDetails,
  UpcomingInvoiceData,
  UpdateTechDialogData,
  buildQuery,
  tryToFormatPhoneNumber,
  ClientEmail,
  ClientPhoneNumber,
  DzPartnerDetails,
  UpdateProductPricesRequest,
  UserCommunicationType,
  ActiveMembershipFeature,
  MembershipFeature,
  MembershipFeatureBundle,
  AiPrompt,
  CallTranscription,
} from 'shared-ui';
import { PartnerGroupRuleEntity } from 'shared-ui';
import { MapLike } from 'typescript';
import {
  ClientToAddress,
  CreateOncallScheduleResponse,
  DzPartnerHoliday,
  OncallScheduleEntity,
  OncallSchedulePayload,
  PostAndPatchHolidayResponse,
} from '../types';
import {
  CreatePartnerDomainApiInput,
  CreatePartnerDomainJsonApiResponse,
  GetPartnerDomainsJsonApiResponse,
} from 'core/redux/partner-domains';
import { auth } from '@one-vision/login';
import { config } from './config';
import { StripeReportResponse } from 'components/dz-reports-page/types';
import {
  JsonApiMessage,
  UserJsonApiEntity,
  UserGroupJsonApiEntity,
} from '@one-vision/json-api-parser';
import { convertFileToBase64 } from 'shared/utils/fileToBase64';

const isLocal = config.stage === 'local';
// "c" stands for "cognito authorizer" - only present for local API.
const SUB_ROUTE_V1 = isLocal ? 'v1/c' : `v1`;
const SUB_ROUTE_V2 = isLocal ? 'v2/c' : `v2`;

const MAX_ERROR_MESSAGE_SIZE = 2000;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getErrorMessage(error: any): string {
  let result = '';
  switch (typeof error) {
    case 'string':
      return error;

    case 'object':
      // eslint-disable-next-line no-case-declarations
      const { message } = error;
      result = message;
      break;

    default:
      result = JSON.stringify(error);
  }
  if (result.length > MAX_ERROR_MESSAGE_SIZE) {
    result = result.slice(0, MAX_ERROR_MESSAGE_SIZE);
  }
  return result;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getErrorDetails(error: any): Record<string, any> {
  return {
    responseDataMessage: error?.response?.data?.message,
    responseDataStack: error?.response?.data?.stack,
    responseStatus: error?.response?.status,
    responseStatusText: error?.response?.statusText,
  };
}
function getFirstJsonApiError(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
): Partial<{ title: string; detail: string }> | undefined {
  if (error instanceof AxiosError) {
    const errors = error.response?.data?.errors;
    if (Array.isArray(errors)) {
      return errors[0];
    }
  }
}

export class DayZeroAPI {
  private baseUrl = config.api;

  private baseGet = async <T>(
    subRoute: string,
    path: string,
    params?: MapLike<string | number | undefined>,
  ) => {
    const fullPath = `${this.baseUrl}/${subRoute}/${path}`;
    try {
      return await axios.get<T>(fullPath, {
        headers: await this.formHeaders(),
        params,
      });
    } catch (error) {
      await submitError({
        error: getErrorMessage(error),
        level: 'normal',
        context: {
          method: 'get',
          fullPath,
          params,
          ...getErrorDetails(error),
        },
      });
      throw error;
    }
  };

  private baseGetV1 = async <T>(
    path: string,
    params?: MapLike<string | number | undefined>,
  ) => this.baseGet<T>(SUB_ROUTE_V1, path, params);

  private baseGetV2 = async <T>(
    path: string,
    params?: MapLike<string | undefined>,
  ) => this.baseGet<T>(SUB_ROUTE_V2, path, params);

  private basePost = async <T>(
    subRoute: string,
    path: string,
    payload: unknown,
    params?: MapLike<string>,
  ) => {
    const fullPath = `${this.baseUrl}/${subRoute}/${path}`;
    try {
      return await axios.post<T>(fullPath, payload, {
        headers: await this.formHeaders(),
        params,
      });
    } catch (error) {
      await submitError({
        error: getErrorMessage(error),
        level: 'normal',
        context: {
          method: 'post',
          fullPath,
          params,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          responseData: (error as any)?.response?.data?.message,
        },
      });
      throw error;
    }
  };

  private basePostV1 = async <T>(
    path: string,
    payload: unknown,
    params?: MapLike<string>,
  ) => this.basePost<T>(SUB_ROUTE_V1, path, payload, params);

  private basePostV2 = async <T>(
    path: string,
    payload: unknown,
    params?: MapLike<string>,
  ) => this.basePost<T>(SUB_ROUTE_V2, path, payload, params);

  private basePatch = async <T>(
    subRoute: string,
    path: string,
    payload: unknown,
    params?: MapLike<string>,
  ) => {
    const fullPath = `${this.baseUrl}/${subRoute}/${path}`;
    try {
      return await axios.patch<T>(fullPath, payload, {
        headers: await this.formHeaders(),
        params,
      });
    } catch (error) {
      await submitError({
        error: getErrorMessage(error),
        level: 'normal',
        context: {
          method: 'patch',
          fullPath,
          params,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          responseData: (error as any)?.response?.data?.message,
        },
      });
      throw error;
    }
  };

  private basePatchV1 = async <T>(
    path: string,
    payload: unknown,
    params?: MapLike<string>,
  ) => this.basePatch<T>(SUB_ROUTE_V1, path, payload, params);

  private basePatchV2 = async <T>(
    path: string,
    payload: unknown,
    params?: MapLike<string>,
  ) => this.basePatch<T>(SUB_ROUTE_V2, path, payload, params);

  private baseDelete = async <T>(
    subRoute: string,
    path: string,
    params?: MapLike<string>,
  ) => {
    const fullPath = `${this.baseUrl}/${subRoute}/${path}`;
    try {
      return await axios.delete<T>(fullPath, {
        headers: await this.formHeaders(),
        params,
      });
    } catch (error) {
      await submitError({
        error: getErrorMessage(error),
        level: 'normal',
        context: {
          method: 'delete',
          fullPath,
          params,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          responseData: (error as any)?.response?.data?.message,
        },
      });
      throw error;
    }
  };

  private baseDeleteV1 = async <T>(
    path: string,
    params?: MapLike<string>,
  ) => this.baseDelete<T>(SUB_ROUTE_V1, path, params);

  private baseDeleteV2 = async <T>(
    path: string,
    params?: MapLike<string>,
  ) => this.baseDelete<T>(SUB_ROUTE_V2, path, params);

  private formHeaders = async () => {
    const token = await auth.getToken();
    const headers: MapLike<string | string> = {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    if (auth.getIsAdmin()) {
      headers['partner-id'] = String(auth.getPartnerId());
    }

    return headers;
  };

  public axios = axios;

  // ***** V1 *****

  public getNewProjectDropDownData = async () =>
    this.baseGetV1<NewProjectDropDowns>('new-project-dropdowns');

  public getUser = async (options?: JsonApiParams) =>
    this.baseGetV2<JsonApiGetUsersResponse>(
      `user${buildQuery({ ...options })}`,
    );

  public postUser = async (newOwner: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
  }) =>
    this.basePostV2('user', {
      data: {
        type: 'user',
        attributes: newOwner,
      },
    });

  public patchUser = async (data: {
    id: string;
    attributes: {
      firstName?: string;
      lastName?: string;
      phoneNumber?: string | null;
    };
  }) =>
    this.basePatchV2('user', {
      data: {
        type: 'User',
        ...data,
      },
    });

  public deleteUser = async (userId: DzOwner['ownerId']) =>
    this.baseDeleteV2('user', {
      'user-id': userId.toString(),
    });

  public postUserCommunicationPreference = (payload: {
    userId: string;
    data: UserCommunicationType[];
  }) => this.basePostV2('user-communication-preference', payload);

  public resendLoginInvitation = async (
    userId: DzOwner['ownerId'],
    temporaryPassword?: string,
  ) =>
    this.basePatchV2('user-temporary-password', {
      data: {
        type: 'User',
        id: userId,
        attributes: {
          temporaryPassword,
        },
      },
    });

  public getPartners = async () => this.baseGetV1<DzPartner[]>('partner');

  public getPartnerDetails = async (ovpid: string) =>
    this.baseGetV1<DzPartnerDetails>('partner-details', { ovpid });

  public getGlobalSearch = async (query: string, page: string) =>
    this.baseGetV1('global-search', {
      query,
      page,
    });

  public updatePartnerDetails = async (
    payload: Partial<DzPartnerDetails>,
  ) => this.basePatchV1<DzPartnerDetails>('partner-details', payload);

  public getProjectsData = async (
    type: 'day1-dialog-data',
    projectId: string,
  ) =>
    this.baseGetV1<UpdateTechDialogData[]>('project', {
      type,
      projectId,
    });

  public getProjects = async (options: JsonApiParams) =>
    this.baseGetV2<{
      data: JsonApiEntity<DzProject>[];
      included?: JsonApiEntity<
        | DzAddress
        | DzOwner
        | DzClient
        | DzProjectLostReason
        | IDzProjectStage
      >[];
      meta: JsonApiMeta;
    }>(`project${buildQuery({ ...options })}`);

  public updateProjectJSONapi = (
    projectChanges: Pick<DzProject, 'ovprjid'> & Partial<DzProject>,
  ) =>
    this.basePatchV2<{ data: JsonApiEntity<DzProject>[] }>(
      'project',
      projectChanges,
    );

  public getOrgDialogProjects = async (ovaid: string) =>
    this.baseGetV1<OrgDialogProject[]>('project', {
      type: 'org-dialog-data',
      ovaid,
    });

  public createAddress = (payload: Partial<DzAddress>) =>
    this.basePostV1<DzAddress[]>('address', payload);

  public updateAddress = async (
    ovaid: string,
    changes: Partial<DzAddress>,
  ) => {
    const result = await this.basePatchV1<DzAddress>('address', {
      ovaid,
      ...changes,
    });

    return {
      ...result,
      data: [result.data],
    };
  };

  public updateProject = async (
    projectId: string,
    changes: Partial<DzProject & { sendEducationEmail?: boolean }>,
  ) =>
    this.basePatchV1<DzProject[]>('project', {
      projectId,
      ...changes,
    });

  public postProject = async (
    payload: Partial<DzProject> & { ovaid: string },
  ) =>
    this.basePostV1<DzProject[]>('project', {
      ...payload,
    });

  public deleteProject = async (
    payload: Pick<DzProject, 'ovaid' | 'ovprjid'>,
  ) => this.baseDeleteV1('project', payload);

  public getApiKeys = async () =>
    this.baseGetV1<{
      data: {
        errorApiKey: string;
        partnerApiKey: string;
        logsPrivateKey: string;
      };
    }>('api-keys');

  public getProposalLostReasonDropDownData = async () =>
    this.baseGetV1<DzDropDownItem[]>('dropdown', {
      type: 'proposalLostReason',
    });

  public postToTray = async (payload: unknown, type: DzPostToTrayType) =>
    this.basePostV1<DzDropDownItem[]>('post-to-tray', payload, {
      type,
    });

  public getAddresses = async <T extends { ovaid: string }>(
    params: {
      ovaid?: T['ovaid'];
      'client-id'?: DzClient['ovcid'];
      'field-preset'?: string;
    } = {},
  ) => this.baseGetV1<DzAddress[]>('address', params);

  public getClients = async (params?: { ovaid?: DzAddress['ovaid'] }) => {
    const result = await this.baseGetV1<(DzClientToAddress & DzClient)[]>(
      'client',
      { ...params },
    );
    // TODO: move phone transformation to proper place
    // we need this fix due to the Twilio deadline
    // after that it should be taken care of
    result.data.forEach((record) => {
      record.phone = tryToFormatPhoneNumber(record.phone);
    });

    return result;
  };

  public getPrimaryClientIds = async (params: {
    primaryClientsOnly: string;
  }) => {
    return await this.baseGetV1<string[]>('client', { ...params });
  };

  public getClientsWithContacts = async (
    params?:
      | {
          ovaid: DzAddress['ovaid'];
          'include-labels'?: string;
        }
      | {
          ovcid: DzClient['ovcid'];
          'include-labels'?: string;
        },
  ) => {
    const result = await this.baseGetV1<ClientWithMultiContacts[]>(
      'client-with-contacts',
      params,
    );
    result.data.forEach((record) => {
      record.phones = record.phones.map((el) => ({
        ...el,
        phone: tryToFormatPhoneNumber(el.phone),
      }));
    });

    return result;
  };

  public updateClient = async (payload: PayloadAddEditClient) =>
    this.basePatchV1<
      ClientWithMultiContacts[] | Partial<DzClientToAddress & DzClient>
    >('client', payload);

  public postClient = async (
    payload: Partial<DzClientToAddress & DzClient>,
  ) => this.basePostV1<DzClientToAddress & DzClient>('client', payload);

  public createClient = async (payload: PayloadAddEditClient) =>
    this.basePostV1<ClientWithMultiContacts[]>(
      'client-with-contacts',
      payload,
    );

  public getIdentityLabels = async () =>
    this.baseGetV1<DzIdentityLabels>('identity-labels');

  public disassociateClientFromAddress = async (
    clientId: DzClientToAddress['ovcid'],
    addressId: DzClientToAddress['ovcid'],
  ) =>
    this.baseDeleteV1<{
      clientId: DzClientToAddress['ovcid'];
      addressId: DzClientToAddress['ovcid'];
    }>('client', { clientId, addressId });

  public sendMembershipEmail = async (payload: EmailMembershipInfo) =>
    this.basePostV1<EmailMembershipInfo>('send-membership-email', payload);

  public getImportPartnerUrl = async (payload: unknown) =>
    this.basePostV1<{ url: string; headers: MapLike<string> }>(
      'import-partner-url',
      payload,
    );

  public generateCustomerPortal = async (
    payload: GenerateCustomerPortal,
  ) =>
    this.basePostV1<GenerateCustomerPortalResponse>(
      'generate-customer-portal',
      payload,
    );

  public getBrands = async () => this.baseGetV1<DzBrand[]>('brand');

  public addAddressToClient = async (payload: ClientToAddress) =>
    this.basePatchV1<ClientToAddress>('client', payload);

  public sendSlackMessage = async (payload: SendSlackMessagePayload) =>
    this.basePostV1('send-slack-message', {
      ...payload,
      messages: (payload.messages || []).map((el) => ({
        ...el,
        partnerId: el?.partnerId?.toString(),
      })),
    });

  public updateClientActivationFeed = async (
    ovaid: string,
    payload: { comment: string },
  ) => this.basePostV1(`activity-feed?ovaid=${ovaid}`, payload);

  // ***** V2 *****

  public activationFlowURL = async ({
    addressId,
    clientId,
  }: {
    addressId: string;
    clientId: string;
  }) => {
    const {
      data: { data },
    } = await this.baseGetV2<{ data: { url: string } }>(
      'activation-flow-url',
      { 'address-id': addressId, 'client-id': clientId },
    );

    return data;
  };

  public paymentPortalStaticURL = async ({
    addressId,
    clientId,
    partnerId,
  }: {
    addressId?: string;
    clientId?: string;
    partnerId?: number;
  }) => {
    const {
      data: { data },
    } = await this.basePostV2<{ data: { url: string } }>(
      'payment-portal-generate-static-url',
      { addressId, clientId, partnerId },
    );

    return data;
  };

  public getStripeSubscriptionDetails = async ({
    addressId,
  }: {
    addressId: string;
  }) =>
    this.baseGetV2<{ data: SubscriptionDetails }>('subscription-data', {
      'address-id': addressId,
    });

  public getStripeReportData = async ({
    partnerId,
    status,
    pageSize,
    nextPage,
  }: {
    partnerId: string;
    status: string;
    pageSize: number;
    nextPage?: string;
  }) => {
    const params = {
      'partner-id': partnerId,
      ...(status ? { status } : {}),
      ...(pageSize ? { 'page-size': pageSize.toString() } : {}),
      ...(nextPage ? { 'next-page': nextPage } : {}),
    };

    return this.baseGetV2<{ data: StripeReportResponse }>(
      'stripe-report',
      params,
    );
  };

  public getClientLabels(): Promise<
    AxiosResponse<{ data: JsonApiEntity<DzClientLabel>[] }>
  >;
  public getClientLabels(params: {
    ovaid?: string;
    ovcid?: string;
  }): Promise<
    AxiosResponse<{
      data: MapLike<JsonApiEntity<DzClientLabel>[]>;
    }>
  >;
  public getClientLabels = async (params?: {
    ovaid?: string;
    ovcid?: string;
  }) =>
    this.baseGetV2<{
      data: { data: JsonApiEntity<DzClientLabel>[] };
    }>('client-label', params);

  public addLabelToClient = async (payload: {
    ovaid: string;
    ovcid: string;
    clientLabelIds: number[];
  }) => this.basePostV2('client-label-to-cta', payload);

  public deleteClientLabel = async (params: {
    ovaid: string;
    ovcid: string;
    'client-label-ids': string;
  }) => this.baseDeleteV2('client-label-to-cta', params);

  public getPartnerHoliday = async (options?: JsonApiParams) =>
    this.baseGetV2<{ data: JsonApiEntity<DzPartnerHoliday>[] }>(
      `partner-holiday${buildQuery({ ...options })}`,
    );

  public postPartnerHoliday = async (payload: {
    holidayName: string;
    holidayStart: string;
    holidayEnd: string;
    noTimezoneHolidayStart: string;
    noTimezoneHolidayEnd: string;
  }) =>
    this.basePostV2<PostAndPatchHolidayResponse>(
      'partner-holiday',
      payload,
    );

  public patchPartnerHoliday = async (payload: {
    partnerHolidayId: string;
    holidayName?: string;
    holidayStart?: string;
    holidayEnd?: string;
    noTimezoneHolidayStart?: string;
    noTimezoneHolidayEnd?: string;
  }) =>
    this.basePatchV2<{
      data: JsonApiEntity<PostAndPatchHolidayResponse>[];
    }>('partner-holiday', payload);

  public deletePartnerHoliday = async (params: {
    partnerHolidayId: string;
  }) => this.baseDeleteV2('partner-holiday', params);

  public generatePresignedUrl = ({
    contentType,
  }: {
    contentType: string;
  }) =>
    this.baseGetV2<{
      data: { key: string; uploadURL: string };
    }>('presigned-url', { contentType });

  public getAddress = (options?: JsonApiParams) =>
    this.baseGetV2<{
      data: JsonApiEntity<DzAddress>[];
      included?: JsonApiEntity<ProductOffered | { productName: string }>[];
    }>(`address${buildQuery({ ...options })}`);

  public getClient = (options?: JsonApiParams) =>
    this.baseGetV2<{
      data: JsonApiEntity<DzClient>[];
      included?: JsonApiEntity<ClientEmail | ClientPhoneNumber>[];
    }>(`client${buildQuery({ ...options })}`);

  public getUpcomingInvoice = (params: {
    ['address-id']: string;
    email: string;
    coupon?: string;
    ['product-id']: string;
    ['price-point-id']: string;
    ['interval-count']: string;
  }) =>
    this.baseGetV2<{ data: UpcomingInvoiceData }>(
      'upcoming-invoice',
      params,
    );

  public getPreSoldMembership = async (options?: JsonApiParams) =>
    this.baseGetV2<{
      data: {
        data: JsonApiEntity<PreSoldMembershipResponse>[];
      };
    }>(`pre-sold-membership${buildQuery({ ...options })}`);

  public postPreSoldMembership = async (
    payload: PreSoldMembershipPayload,
  ) =>
    this.basePostV2<{ data: { id: string } }>(
      'pre-sold-membership',
      payload,
    );

  public postContactSupport = async (payload: {
    userName: string;
    email: string;
    issueType: string;
    additionalContextText: string;
    attachments: File[];
  }) => {
    let fileAsBase64 = '';
    if (payload.attachments?.length) {
      fileAsBase64 = await convertFileToBase64(payload.attachments[0]);
    }

    const attachments = payload.attachments?.map((file) => {
      return {
        name: file.name,
        type: file.type,
        size: file.size,
        content: fileAsBase64,
      };
    });

    return this.basePostV2<{ data: { success: boolean } }>(
      'contact-support',
      {
        ...payload,
        attachments,
      },
    );
  };

  public getPartnerConfig = async (options?: JsonApiParams) =>
    this.baseGetV2<{ data: PartnerGroupRuleEntity[] }>(
      `partner-config${buildQuery({ ...options })}`,
    );

  public getPartnerDomains() {
    return this.baseGetV2<GetPartnerDomainsJsonApiResponse>(
      'partner-domain',
    );
  }
  public async createPartnerDomain(input: CreatePartnerDomainApiInput) {
    try {
      const response =
        await this.basePostV2<CreatePartnerDomainJsonApiResponse>(
          'partner-domain',
          input,
        );

      return response;
    } catch (error) {
      if (getFirstJsonApiError(error)?.detail) {
        throw new Error(getFirstJsonApiError(error)?.detail);
      }

      throw error;
    }
  }
  public deletePartnerDomain(id: string) {
    return this.baseDeleteV2('partner-domain', { partnerDomainId: id });
  }

  public getProducts = (options: JsonApiParams) =>
    this.baseGetV2<{
      data: JsonApiEntity<ProductOffered>[];
      included?: JsonApiEntity<{ productName: string }>[];
    }>(`product${buildQuery({ ...options })}`);

  public updateProductPrices = (payload: UpdateProductPricesRequest) =>
    this.basePostV2('update-product-prices', payload);

  public getUserDetails = async (options?: JsonApiParams) =>
    this.baseGetV2<JsonApiMessage<UserJsonApiEntity>>(
      `user-details${buildQuery({ ...options })}`,
    );

  public getUserGroups = async (options?: JsonApiParams) =>
    this.baseGetV2<JsonApiMessage<UserGroupJsonApiEntity>>(
      `user-groups${buildQuery({ ...options })}`,
    );

  public postUserToUserGroups = async (payload: {
    id: string;
    attributes: { userGroupId: string[] };
  }) => this.basePostV2('user-to-user-groups', payload);

  public getOncallManagementSchedule = (options: JsonApiParams) =>
    this.baseGetV2<{
      data: JsonApiEntity<OncallScheduleEntity>[];
      included?: JsonApiEntity<{
        primaryAgent: string;
        secondaryAgent: string;
      }>[];
    }>(`oncall-management-schedule${buildQuery({ ...options })}`);

  //TO-DO: move this logic to hook, keep only return for promise
  public async createOncallSchedule(input: OncallSchedulePayload) {
    try {
      const payload = {
        data: {
          type: 'OncallManagementSchedule',
          attributes: {
            startTime: input.startTime,
            endTime: input.endTime,
            recurrence: input.recurrence,
          },
          relationships: {
            primaryAgent: {
              data: {
                type: 'User',
                id: input.primaryAgent.ownerId,
              },
            },
            ...(input.secondaryAgent
              ? {
                  secondaryAgent: {
                    data: {
                      type: 'User',
                      id: input.secondaryAgent.ownerId,
                    },
                  },
                }
              : {}),
          },
        },
      };
      const response = await this.basePostV2<CreateOncallScheduleResponse>(
        'oncall-management-schedule',
        payload,
      );

      return response;
    } catch (error) {
      if (getFirstJsonApiError(error)?.detail) {
        throw new Error(getFirstJsonApiError(error)?.detail);
      }

      throw error;
    }
  }

  public async updateOncallSchedule(input: OncallSchedulePayload) {
    try {
      const payload = {
        data: {
          type: 'OncallManagementSchedule',
          attributes: {
            startTime: input.startTime,
            endTime: input.endTime,
            recurrence: input.recurrence,
          },
          relationships: {
            primaryAgent: {
              data: {
                type: 'User',
                id: input.primaryAgent.ownerId,
              },
            },
            secondaryAgent: {
              data: {
                type: 'User',
                id: input.secondaryAgent?.ownerId ?? null,
              },
            },
          },
        },
      };
      const response =
        await this.basePatchV2<CreateOncallScheduleResponse>(
          `oncall-management-schedule/${input.id}`,
          payload,
        );

      return response;
    } catch (error) {
      if (getFirstJsonApiError(error)?.detail) {
        throw new Error(getFirstJsonApiError(error)?.detail);
      }

      throw error;
    }
  }

  public async deleteOncallSchedule(scheduleId: string) {
    try {
      const payload = {
        id: scheduleId,
      };
      const response =
        await this.baseDeleteV2<CreateOncallScheduleResponse>(
          `oncall-management-schedule`,
          payload,
        );

      return response;
    } catch (error) {
      if (getFirstJsonApiError(error)?.detail) {
        throw new Error(getFirstJsonApiError(error)?.detail);
      }

      throw error;
    }
  }

  public getMembershipFeaturesForBrand = async (brandId: string) =>
    this.baseGetV2<{
      data: MembershipFeatureBundle[];
    }>(`membership-feature-bundle?brand-id=${brandId}`);

  public getMembershipFeatures = async (options?: JsonApiParams) =>
    this.baseGetV2<{
      data: JsonApiEntity<MembershipFeature>[];
    }>(`membership-feature${buildQuery({ ...options })}`);

  public postMembershipFeature = async (payload: {
    brandId: string;
    name: string;
    defaultContent: string;
    defaultTooltip: string;
  }) =>
    this.basePostV2<{
      data: { id: string; type: string };
    }>(`membership-feature`, { data: { attributes: payload } });

  public patchMembershipFeature = async (payload: {
    featureId: string;
    name?: string;
    defaultContent?: string;
    defaultTooltip?: string;
    sortOrder?: number;
  }) =>
    this.basePatchV2<{
      data: JsonApiEntity<JsonApiEntity<MembershipFeature>>;
    }>(`membership-feature`, {
      data: { id: payload.featureId, attributes: payload },
    });

  public deleteMembershipFeature = async (params: {
    'membership-feature-id': string;
  }) => this.baseDeleteV2('membership-feature', params);

  public getActiveMembershipFeatures = async (options?: JsonApiParams) =>
    this.baseGetV2<{
      data: JsonApiEntity<ActiveMembershipFeature>[];
      included?:
        | JsonApiEntity<MembershipFeature>[]
        | JsonApiEntity<{ productName: string }>[];
    }>(`active-membership-feature${buildQuery({ ...options })}`);

  public postActiveMembershipFeature = async (payload: {
    membershipFeatureId: string;
    productNameId: number;
    overrideContent?: string;
    overrideTooltip?: string;
  }) =>
    this.basePostV2<{
      data: { id: string; type: string };
    }>(`active-membership-feature`, { data: { attributes: payload } });

  public patchActiveMembershipFeature = async (payload: {
    activeFeatureId: string;
    overrideContent?: string;
    overrideTooltip?: string;
  }) =>
    this.basePatchV2(`active-membership-feature`, {
      data: { id: payload.activeFeatureId, attributes: payload },
    });

  public deleteActiveMembershipFeature = async (params: {
    'active-membership-feature-id': string;
  }) => this.baseDeleteV2('active-membership-feature', params);

  public getStripeAccountOnboardStatus = async () =>
    this.baseGetV2<{ data: { detailsSubmitted: boolean } }>(
      `stripe-account-onboard-status`,
    );

  public getStripeOnboardUrl = () =>
    this.baseGetV2<{ data: { url: string } }>(
      `stripe-onboard-url?callback-url=${window.location.href}`,
    );

  public getCallTranscriptionsByAddressId = async (
    options?: JsonApiParams,
  ) =>
    this.baseGetV2<{
      data: JsonApiEntity<CallTranscription>[];
    }>(`call-transcription${buildQuery({ ...options })}`);

  public getAiPrompts = async (options?: JsonApiParams) =>
    this.baseGetV2<{
      data: JsonApiEntity<AiPrompt>[];
    }>(`ai-prompt${buildQuery({ ...options })}`);

  public generateAiHelp = async (payload: {
    promptId: string;
    transcriptionId: string;
    additionalContext?: string;
  }) => {
    const { promptId, transcriptionId, additionalContext } = payload;

    return this.basePostV2<{
      data: JsonApiEntity<{ aiHelp: string }>;
    }>(`ai-help${buildQuery({ promptId, transcriptionId })}`, {
      additionalContext,
    });
  };
}

export const API = new DayZeroAPI();
