import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useStyles } from './dz-delete-holiday-modal.styles';

interface Props {
  id: string;
  onClose: () => void;
  onSubmit: () => void;
}

export const DzDeleteHolidayModal: React.FC<Props> = ({
  id,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={Boolean(id)}
      classes={{
        paper: classes.deleteConfirmation,
      }}
      onClose={onClose}
      disableRestoreFocus={true}
    >
      <DialogTitle>
        <Box display="flex" flexDirection="column" alignItems="center">
          <DeleteForeverIcon className={classes.deleteIcon} />
          Are you sure?
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText align="center">
          Do you really want to delete this holiday?
          <br />
          This process can`t be undone
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          className={classes.deleteButton}
          variant="contained"
          color="primary"
        >
          DELETE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
