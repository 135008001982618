import * as yup from 'yup';
import { placeholders } from './placeholders';
// Independent rules for validation.
// If you need to create your own custom scheme, then you can take fields only from here.
// If there is no field with the required validation, create it.
export const schemaFields = {
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup
        .string()
        .email(`Please enter a valid email address. Valid format: ${placeholders.email}`)
        .required('Email is required'),
    phone: yup
        .string()
        .matches(/^\+1 \(\d{1,3}\) \d{3}-\d{4}$/, `Please enter a valid phone number. Valid formats: ${placeholders.phone}`),
    address1: yup.string().required('Address 1 is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State/Prov is required'),
    zip: yup.number().required('Postal Code is required'),
    clientSegment: yup.string().required('Client Segment is required'),
    baseRequiredString: (field) => yup.string().required(`${field} is required`),
    labelId: yup.number().nullable(true),
    primaryContact: yup.boolean(),
    clientEmailId: yup.string(),
    phoneNumberId: yup.string(),
    clientNotes: yup.string().nullable(true),
};
// !Important!
// Ready-made schemes that are used in several places.
// If your schema is used in more than one place, you need to create it here and export it.
export const schemas = {
    clientData: yup.object({
        email: schemaFields.email,
        firstName: schemaFields.firstName,
        lastName: schemaFields.lastName,
        phone: schemaFields.phone,
    }),
    clientDataMultiIdentity: yup.object({
        firstName: schemaFields.firstName,
        lastName: schemaFields.lastName,
        notes: schemaFields.clientNotes,
        emails: yup.array().of(yup.object({
            email: schemaFields.email,
            labelId: schemaFields.labelId,
            primary: schemaFields.primaryContact,
            clientEmailId: schemaFields.clientEmailId,
        })),
        phones: yup.array().of(yup.object({
            phone: schemaFields.phone.required('Phone number is required'),
            labelId: schemaFields.labelId,
            primary: schemaFields.primaryContact,
            clientPhoneNumberId: schemaFields.phoneNumberId,
        })),
    }),
};
