import { logRender } from '@one-vision/utils';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  actions,
  thunks,
  useSelectUpdateProjectInfoDialog,
  useSelectAddressPanel,
} from 'core/redux';
import { UpdateProjectInfoDialog } from 'shared-ui';

export const UpdateProjectInfoDialogView: React.FC = () => {
  logRender(UpdateProjectInfoDialogView);
  const { ovaid } = useSelectAddressPanel();
  const dispatch = useDispatch();
  const { projectData, isOpen } = useSelectUpdateProjectInfoDialog();

  return (
    <UpdateProjectInfoDialog
      dispatch={dispatch}
      actions={actions}
      thunks={thunks}
      isOpen={isOpen}
      ovaid={ovaid}
      projectData={projectData}
    />
  );
};
