import { createSelector } from '@reduxjs/toolkit';

import { DzStore } from 'core/redux';
import { Client } from 'core/redux/clients.redux';
import orderBy from 'lodash/orderBy';
import { UsersViews } from 'shared/hooks/makeUseFavoriteViews';

const selectAllClients = (state: DzStore) => state.clients;
const selectPrimaryClientIds = (state: DzStore) => state.primaryClientIds;
const selectActiveView = (_: DzStore, activeView: string) => activeView;

const selectClientsNotAssociatedWithOrg = createSelector(
  selectAllClients,
  (clients) => clients.filter((client) => !client.ovaid),
);

const selectPrimaryClients = createSelector(
  selectPrimaryClientIds,
  selectAllClients,
  (clientIds, allClients) => {
    return allClients.filter((client) => clientIds.includes(client.ovcid));
  },
);

interface ClientsByViewParams {
  activeView: string;
  allClients: Client[];
  primaryClients: Client[];
  clientsNotAssociatedWithOrg: Client[];
}

const selectClientsByView = ({
  activeView,
  allClients,
  primaryClients,
  clientsNotAssociatedWithOrg,
}: ClientsByViewParams) => {
  switch (activeView) {
    case UsersViews.allUsers: {
      return allClients;
    }

    case UsersViews.allPrimary: {
      return primaryClients;
    }

    case UsersViews.notAssociatedWithOrg: {
      return clientsNotAssociatedWithOrg;
    }

    default: {
      return allClients;
    }
  }
};

export const selectClients = createSelector(
  selectAllClients,
  selectPrimaryClients,
  selectClientsNotAssociatedWithOrg,
  selectActiveView,
  (
    allClients,
    primaryClients,
    clientsNotAssociatedWithOrg,
    activeView,
  ) => {
    const clients = selectClientsByView({
      activeView,
      allClients,
      primaryClients,
      clientsNotAssociatedWithOrg,
    });

    return orderBy(clients, ['updatedAt', 'createdAt'], ['desc', 'desc']);
  },
);
