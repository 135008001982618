import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';
import { isRenderingInIframe } from 'core/runtime';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        flexDirection: 'row',
        columnGap: theme.spacing(1),

        width: isRenderingInIframe
          ? '100%'
          : `calc(100Vw - ${theme.px2rem(theme.dz.appBar.width)})`,
        position: 'fixed',
        backgroundColor: theme.palette.custom.OV.sys.surfaceBright,
        padding: isRenderingInIframe ? 0 : theme.spacing(1),
      },
      wrapper: {
        width: '100%',
        border: isRenderingInIframe
          ? 0
          : `1px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
        borderRadius: isRenderingInIframe ? 0 : '4px',
        backgroundColor:
          theme.palette.custom.OV.sys.surfaceContainerLowest,

        maxHeight: isRenderingInIframe
          ? `100vh`
          : `calc(100Vh - ${theme.px2rem(theme.dz.topBar.height)})`,
        minHeight: isRenderingInIframe
          ? `100vh`
          : `calc(100Vh - ${theme.px2rem(theme.dz.topBar.height)} - 16px)`,
        overflowY: 'hidden',
      },
      gridWrapper: {
        maxHeight: isRenderingInIframe
          ? '100vh'
          : `calc(100Vh -
        ${theme.px2rem(theme.dz.topBar.height)} -
        ${theme.px2rem(theme.dz.subBar.height)} - 16px)
        `,
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${theme.px2rem(18)} 0`,

        height: theme.px2rem(theme.dz.subBar.height),
        paddingRight: theme.px2rem(16),

        borderBottom: `2px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
        backgroundColor:
          theme.palette.custom.OV.sys.surfaceContainerLowest,
      },
    };
  },
  { name: 'dz-layout-page' },
);
