import { brandsSlice, fetchBrands } from './brands.redux';
import { generateCustomerPortal } from './generate-customer-portal.redux';
import { sendMembershipEmail } from './membership.redux';
import {
  fetchNewProjectDropDownData,
  newProjectDialog,
} from './new-project-dialog.redux';
import { postToTray } from './thunks.redux';

export const dialogs = {
  reducer: {
    newProjectDialog: newProjectDialog.reducer,
    brands: brandsSlice.reducer,
  },
  thunks: {
    fetchNewProjectDropDownData,
    fetchBrands,
    sendMembershipEmail,
    generateCustomerPortal,
    postToTray,
  },
};
