import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        title: {
            fontSize: theme.px2rem(14),
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            fontWeight: 500,
            paddingLeft: theme.spacing(1),
            color: theme.black.black65,
        },
        marginBottom: {
            marginBottom: theme.spacing(3),
        },
        additionalContextField: {
            marginTop: theme.spacing(2),
        },
        attachmentsField: {
            marginTop: theme.spacing(2),
        },
    };
}, { name: 'dz-contact-support-dialog', index: 0 });
