import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      listItem: {
        borderLeft: `1px solid ${theme.palette.divider}`,
        borderTop: `1px solid ${theme.palette.divider}`,
        borderRight: `1px solid ${theme.palette.divider}`,
        '&:last-child': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
        borderRadius: '4px',
      },
      domainsContainer: {
        marginTop: theme.spacing(-3),
      },
    };
  },
  { name: 'dz-users-partner-domains' },
);
