import React from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { csn } from '@one-vision/utils';
import { useStyles } from './dz-stripe.styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useStripeAccountOnboardUrl } from 'shared/hooks/useStripeOnboardUrl';

const SETUP_STRINGS = {
  title: 'Connected Stripe Account',
  infoFirstLine: 'Set up your Stripe Account to begin processing RMR.',
  infoSecondLine:
    'Stripe is used to collect payment methods and manage subscriptions for your clients.',
  buttonText: 'Set up Stripe Account',
};

export const DzSetupBanner = () => {
  const classes = useStyles();
  const { fetchUrl, loading } = useStripeAccountOnboardUrl();

  const handleClick = async () => {
    const url = await fetchUrl();
    window.open(url, '_blank');
  };

  return (
    <Box className={csn(classes.root, classes.animatedAppearance)}>
      <Typography className={classes.headerName}>
        {SETUP_STRINGS.title}
      </Typography>
      <Box className={csn(classes.box, classes.blueBackground)}>
        <Box className={classes.boxTextContainer}>
          <InfoOutlinedIcon className={classes.setupIcon} />
          <Box className={classes.boxText}>
            <Typography className={classes.boxMainLine}>
              {SETUP_STRINGS.infoFirstLine}
            </Typography>
            <Typography>{SETUP_STRINGS.infoSecondLine}</Typography>
          </Box>
        </Box>
        <Button
          className={classes.setupButton}
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={handleClick}
        >
          {loading ? (
            <CircularProgress size={30} />
          ) : (
            SETUP_STRINGS.buttonText
          )}
        </Button>
      </Box>
    </Box>
  );
};
