import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { csn } from '@one-vision/utils';

import { useStyles } from './dz-icons.styles';

export const DzNoOrganizationIcon: React.FC<SvgIconProps> = ({
  ...props
}) => {
  const classes = useStyles();

  return (
    <SvgIcon className={csn(classes.root, classes.fillNone)} {...props}>
      <g clipPath="url(#clip0_4976:24317)">
        <path
          d="M52.138 22.345H47.1725V27.3105H52.138V22.345Z"
          fill="#8F9091"
        />
        <path
          d="M52.138 32.2759H47.1725V37.2414H52.138V32.2759Z"
          fill="#8F9091"
        />
        <path
          d="M52.138 42.207H47.1725V47.1726H52.138V42.207Z"
          fill="#8F9091"
        />
        <path
          d="M7.44836 32.2759V57.1034H22.3449V44.6896H27.3104V57.1034H42.207V32.2759L24.8277 19.8621L7.44836 32.2759ZM37.2415 52.1379H32.276V39.7241H17.3794V52.1379H12.4139V34.7586L24.8277 26.069L37.2415 34.7586V52.1379Z"
          fill="#8F9091"
        />
        <path
          d="M29.7932 12.4138V17.3049L34.7587 20.8552V17.3793H57.1036V52.138H47.1725V57.1035H62.0691V12.4138H29.7932Z"
          fill="#8F9091"
        />
      </g>
      <circle cx="36" cy="36" r="33.5" stroke="#8F9091" strokeWidth="5" />
    </SvgIcon>
  );
};
