import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchedAllAddresses, addressUpdated } from './sidebars/actions';
import { updateProject, updateAddress } from './sidebars/thunks.redux';
import { DzOwner, DzAddress, DzAddressShortList } from 'shared-ui';

export const addressesSlice = createSlice({
  name: 'addresses',
  initialState: [] as DzAddressShortList[],
  reducers: {
    updateAddressItem: (
      state: DzAddressShortList[],
      action: PayloadAction<{
        id: string;
        ownerId: DzOwner['ownerId'] | null;
        nameOwner: DzOwner['name'] | undefined;
      }>,
    ) => {
      if (action.payload.id) {
        return state.map((record) => {
          if (record.ovaid === action.payload.id) {
            return {
              ...record,
              ...action.payload,
              owner: action.payload?.nameOwner || 'Unassigned',
            };
          }
          return record;
        });
      }

      return state;
    },
    updateAddresses: (
      state: DzAddressShortList[],
      action: PayloadAction<DzAddressShortList[]>,
    ): DzAddressShortList[] => [...action.payload, ...state],
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchedAllAddresses, (_, action) => {
        return action.payload;
      })
      .addCase(
        addressUpdated,
        (
          state: DzAddressShortList[],
          action: PayloadAction<DzAddress>,
        ) => {
          const index = state.findIndex(
            (address) => address.ovaid === action.payload.ovaid,
          );

          if (index !== -1) {
            state[index] = {
              ...state[index],
              address1: action.payload.address1,
              address2: action.payload.address2,
              businessName: action.payload.businessName,
              ownerId: action.payload.ownerId,
              cppExpDate: action.payload.cppExpDate,
              clientSelectedMembership:
                action.payload.clientSelectedMembership,
              clientActivatedMembership:
                action.payload.clientActivatedMembership,
              tosAccepted: action.payload.tosAccepted,
              creditCardProvided: action.payload.creditCardProvided,
              primaryProjectId: action.payload.primaryProjectId,
            };
          }
        },
      )
      .addCase(
        updateProject.fulfilled,
        (
          state: DzAddressShortList[],
          action: ReturnType<typeof updateProject.fulfilled>,
        ) => {
          const addressIndex = state.findIndex(
            (address) =>
              address.primaryProject_ovprjid === action.payload.projectId,
          );

          if (addressIndex !== -1) {
            state[addressIndex] = {
              ...state[addressIndex],
              primaryProject_name: action.meta.arg.changes.name || null,
            };
          }
        },
      )
      .addCase(
        updateAddress.fulfilled,
        (
          state: DzAddressShortList[],
          action: ReturnType<typeof updateAddress.fulfilled>,
        ) => {
          const changedAddress = action.payload[0];

          const addressIndex = state.findIndex((address) => {
            return (
              changedAddress && address.ovaid === changedAddress.ovaid
            );
          });

          if (addressIndex !== -1) {
            state[addressIndex] = {
              ...state[addressIndex],
              ownerId: changedAddress?.ownerId || null,
            };
          }
        },
      );
  },
});
