import React, { useMemo, useState } from 'react';

import { useStyles } from './dz-users-table.styles';
import { User } from './useUsers';
import {
  DzUserOptions,
  ResendInviteOptions as MenuResendInviteOptions,
} from './dz-user-option.view';
import { csn } from '@one-vision/utils';
import { useStyles as useGridStyles } from '../../shared/dz-grid/dz-grid.styles';

import { AgGridReact } from 'ag-grid-react';

import { DzColDef } from 'components/shared';
import { Box, Chip, Tooltip } from '@mui/material';
import { writeToClipboard } from 'shared-ui';

interface Props {
  users: User[];
  searchText: string;
  onDeleteUser: (id: string) => void;
  onResendInvite: OnResendInvite;
  onUserUpdate: (user: User) => void;
  onGroupsUpdate: (user: User) => void;
}

const columnDefs: Array<DzColDef<User>> = [
  {
    field: 'firstName',
    headerName: 'First Name',
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
  },
  {
    field: 'email',
    headerName: 'Email',
  },
];

const defaultColDef = {
  flex: 1,
  suppressMovable: true,
  suppressNavigable: true,
};

const UserGroups = ({ data }: { data: User }) => {
  const classes = useStyles();

  return (
    <>
      {data.userGroups.map((group) => (
        <Chip
          key={group.id}
          label={group.name}
          sx={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}
          className={
            group.isPublic ? classes.publicChip : classes.privateChip
          }
        />
      ))}
    </>
  );
};

export const DzUsersTable: React.FC<Props> = ({
  users,
  onDeleteUser,
  onResendInvite,
  onUserUpdate,
  onGroupsUpdate,
}) => {
  const classes = useStyles();
  const gridClasses = useGridStyles();

  const columns = useMemo(() => {
    return columnDefs.concat([
      {
        field: 'userGroups',
        headerName: 'Permissions',
        flex: 1.5,
        resizable: true,
        cellRenderer: ({ data }) => (
          <Tooltip
            title={
              <Box className={classes.groupsWrapper}>
                <UserGroups data={data} />
              </Box>
            }
          >
            <Box>
              <UserGroups data={data} />
            </Box>
          </Tooltip>
        ),
      },
      {
        headerName: '',
        field: '',
        flex: 0,
        width: 90,
        cellRenderer: ({ data }) => (
          <DzUserOptions
            onDelete={() => onDeleteUser(data.ownerId)}
            onResendInvite={(options) => {
              onResendInvite({
                ...options,
                userId: data.ownerId,
              });
            }}
            onGroupsUpdate={() => {
              onGroupsUpdate(data);
            }}
            onUserUpdate={() => {
              onUserUpdate(data);
            }}
            onUserIdCopy={async () => {
              await writeToClipboard(data.ownerId, 'text/plain');
            }}
          />
        ),
      } as DzColDef<User>,
    ]);
  }, [
    onDeleteUser,
    onResendInvite,
    onGroupsUpdate,
    classes.groupsWrapper,
    onUserUpdate,
  ]);
  const [columnWidthsAreProcessed, setColumnWidthsProcessed] =
    useState(false);

  return (
    <div
      className={csn(
        classes.tableRoot,
        'ag-theme-material',
        gridClasses.agGrid,
        gridClasses.dynamicWidthGrid,
        [gridClasses.dynamicWidthGridReady, columnWidthsAreProcessed],
      )}
    >
      <AgGridReact
        suppressCellFocus
        suppressRowHoverHighlight
        suppressRowDeselection
        rowData={users}
        columnDefs={columns}
        defaultColDef={defaultColDef}
        onFirstDataRendered={() => {
          setColumnWidthsProcessed(true);
        }}
      />
    </div>
  );
};

export type OnResendInvite = (options: ResendInviteOptions) => void;
export interface ResendInviteOptions extends MenuResendInviteOptions {
  userId: string;
}
