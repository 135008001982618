import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useStyles } from './dz-remove-field-modal.styles';
export const DzRemoveFieldModal = ({ isOpen, closeDialog, onDelete, }) => {
    const classes = useStyles();
    return (_jsxs(Dialog, Object.assign({ open: isOpen, classes: {
            paper: classes.deleteConfirmation,
        }, onClose: closeDialog, disableRestoreFocus: true }, { children: [_jsx(DialogTitle, { children: _jsxs(Box, Object.assign({ display: "flex", flexDirection: "column", alignItems: "center" }, { children: [_jsx(DeleteForeverIcon, { className: classes.deleteIcon }), "Are you sure?"] })) }), _jsx(DialogContent, { children: _jsxs(DialogContentText, Object.assign({ align: "center" }, { children: ["Do you want to remove this contact method? ", _jsx("br", {}), "This process cannot be undone."] })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ onClick: closeDialog, color: "primary", autoFocus: true }, { children: "Cancel" })), _jsx(Button, Object.assign({ onClick: onDelete, className: classes.deleteButton, variant: "contained", color: "primary" }, { children: "Remove" }))] })] })));
};
