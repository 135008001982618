import { makeStyles } from '@mui/styles';

import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      progressBox: {
        marginBottom: theme.spacing(3),
      },
      progressBar: {
        height: 12,
        borderRadius: 0,
        background: '#ECDEFF',
        '& .MuiLinearProgress-barColorPrimary': {
          backgroundColor: theme.palette.secondary.main,
        },
      },
      separator: {
        [theme.breakpoints.up('xs')]: {
          minWidth: theme.px2rem(300),
        },
        [theme.breakpoints.up('sm')]: {
          minWidth: theme.px2rem(400),
        },
        height: 0,
        border: `1px dashed #D2D3D3`,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    };
  },
  { name: 'dz-data-import-progress' },
);
