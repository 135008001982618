import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      deleteConfirmation: {
        padding: theme.spacing(2),
      },
      deleteIcon: {
        color: theme.dz.colors.error,
        height: theme.px2rem(56),
        width: theme.px2rem(50),
      },
      deleteButton: {
        backgroundColor: theme.dz.colors.error,
        color: theme.palette.common.white,
      },
    };
  },
  { name: 'dz-delete-holiday-dialog' },
);
