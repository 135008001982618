import { makeStyles } from '@mui/styles';
import { dzColors } from 'shared-ui';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      primaryChip: {
        color: '#fff',
        backgroundColor: '#FC6D26',
        display: 'inline',
        padding: `${theme.spacing(0.25)} 0`,
        marginRight: theme.spacing(0.5),
        borderRadius: '4px',
      },
      chipLabel: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
      },
      root: {
        color: theme.palette.grey[600],
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        flex: 1,
        cursor: 'auto',
        padding: 0,
        height: '100%',
      },
      agGrid: {
        width: '100%',
        height: '100%',
        transition: theme.transitions.create('opacity', {
          duration: '0.5s',
        }),
        '& > div:first-child': {
          width: '100%',
        },
        '& .ag-cell': {
          boxSizing: 'border-box',
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          letterSpacing: '0.1px',
          color: theme.palette.custom.OV.sys.onSurface,
          outline: 'unset',
        },
        '& .ag-react-container': {
          width: '100%',
        },
        '& .ag-header-cell .ag-react-container': {
          width: '100%',
          textAlign: 'center',
        },
        '& .ag-header-cell-resize': {
          position: 'absolute',
          zIndex: 1,
          display: 'block',
          left: 'calc(99% - 1px)',
          width: '1px',
          height: '50%',
          top: 'calc(40% - 15%)',
          backgroundColor: 'rgba(186, 191, 199, 0.5)',
        },
        '& .ag-header-cell-text': {
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '11px',
          lineHeight: '21px',
          letterSpacing: '0.5px',
          textTransform: 'uppercase',
          color: theme.palette.custom.OV.sys.onSurface,
        },
        '& .remove-resize .ag-header-cell-resize': {
          display: 'none',
        },
        '& .ag-row': {
          borderTop: `1px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
          backgroundColor:
            theme.palette.custom.OV.sys.surfaceContainerLowest,
          '&:hover': {
            backgroundColor: theme.palette.custom.OV.sys.tertiaryContainer,
            '& .ag-cell': {
              color: theme.palette.custom.OV.sys.onTertiaryContainer,
            },
          },
          '&:last-child': {
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
          '&::before': {
            display: 'none',
          },
        },
        '&.is-hover-disabled .ag-row:hover': {
          backgroundColor: 'transparent',
          '& .ag-cell': {
            color: theme.palette.custom.OV.sys.onSurface,
          },
        },
        '& .ag-sort-ascending-icon svg path, .ag-sort-descending-icon svg path':
          {
            fill: '#6D6F71',
          },
        '& .ag-header-cell:hover .ag-header-cell-text': {
          color: theme.dz.colors.link || 'green',
        },
        '& .ag-header-cell:hover .ag-sort-ascending-icon svg path, .ag-header-cell:hover .ag-sort-descending-icon svg path':
          {
            fill: theme.dz.colors.link || 'green',
          },
        '& .ag-header': {
          flexShrink: 0,
          height: '53px !important',
        },
        '& .ag-header-row': {
          height: '100% !important',
        },

        '& .centeredCell .ag-react-container, .centeredCell': {
          display: 'flex',
          justifyContent: 'center',
        },

        '& .show-ellipses .ag-react-container > div': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        '& .ag-body-viewport.ag-layout-normal': {
          overflowY: 'scroll !important',
          backgroundColor:
            theme.palette.custom.OV.sys.surfaceContainerLowest,
        },
        '& .ag-input-field-input': {
          height: 'auto !important',
          padding: theme.px2rem(10),
        },
        '& .ag-center-cols-container, & .ag-header-container': {
          margin: '0 auto',
        },
      },
      dynamicWidthGrid: {
        opacity: 0,
      },
      dynamicWidthGridReady: {
        opacity: 1,
      },
      dynamicRowHeightGrid: {
        '& .ag-row': {
          opacity: 0,
          transition: 'opacity 0.5s',
          transitionDelay: '0.5s',
        },
      },
      dynamicRowHeightGridReady: {
        '& .ag-row': {
          opacity: 1,
        },
      },
      agGridLoading: {
        opacity: 0,
      },
      spinner: {
        position: 'absolute',
        left: 'calc(50% - 20px)',
        top: 'calc(50% - 60px)',
      },
      row: {
        opacity: 0,
        '&:hover': {
          zIndex: 1,
        },
      },
      rowLoaded: {
        opacity: 1,
      },
      rowLightSelected: {
        /* @TODO-STYLES */
        backgroundColor: theme.utils.important(
          theme.palette.custom.OV.sys.tertiaryContainer,
        ),

        '& .ag-cell': {
          color: theme.palette.custom.OV.sys.onTertiaryContainer,
        },
      },
      rowSelected: {
        /* @TODO-STYLES */
        backgroundColor: theme.utils.important(
          theme.palette.custom.OV.sys.tertiaryContainer,
        ),
        '& .ag-cell': {
          color: theme.palette.custom.OV.sys.onTertiaryContainer,
        },
      },
      signedTosTrue: {
        color: '#3DC853',
      },
      signedTosFalse: {
        color: dzColors.error,
      },
      ownerRoot: {
        position: 'relative',
        padding: '2px',
        borderRadius: '50%',
        cursor: 'pointer',
        transition: theme.transitions.create('background-color'),
        '&:hover': {
          backgroundColor: theme.palette.grey[300],
        },
      },
      tooltipContent: {
        '&:hover': {
          '& > $tooltip': {
            display: 'flex',
          },
        },
      },
      tooltip: {
        backgroundColor: '#707070',
        color: theme.palette.common.white,
        borderRadius: '3px',
        position: 'fixed',
        pointerEvents: 'none',
        padding: '8px',
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        zIndex: 1,
        transform:
          'translate(calc(-50% + calc(var(--ov-owner-tooltip-content-size)/2)), calc(100% - 10px))',
      },

      toolTipSecondaryText: {
        fontSize: '0.6em',
        marginTop: '3px',
      },

      ownerIcon: {
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: '11px',
      },
      actionsCell: {
        borderRadius: '50%',
        boxSizing: 'content-box',
        padding: 3,
        transition: theme.transitions.create([
          'background-color',
          'color',
        ]),
        '&:hover': {
          backgroundColor: theme.palette.grey[300],
          cursor: 'pointer',
        },
        '&:active': {
          color: theme.palette.common.white,
        },
      },
      newAddressBadge: {
        alignSelf: 'start',
        backgroundColor: theme.dz.colors.brandOrange,
        color: 'rgb(255, 255, 255)',
        padding: '4px 1px',
        fontSize: '8px',
        borderRadius: 4,
        marginRight: 5,
      },
      addressCell: {
        display: 'flex',
        cursor: 'pointer',
        '&:hover': {
          color: theme.dz.colors.listHover,
          '& $firstColumnHoverIcon': {
            transform: 'unset',
          },
        },
      },
      firstColumnHoverIcon: {
        transform: 'scale(0)',
        padding: '0px 0px 4px 4px',
        borderRadius: '3px',
        transition: theme.transitions.create('transform', {
          delay: '0.04s',
        }),
        display: 'flex',
        alignItems: 'center',
      },
      agGridHeaderCentered: {
        backgroundColor:
          theme.palette.custom.OV.sys.surfaceContainerLowest,
        '& .ag-header-cell-label': {
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
        },
      },
      agGridCellCentered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      agGridHeaderLeftAligned: {
        backgroundColor:
          theme.palette.custom.OV.sys.surfaceContainerLowest,
        '& .ag-header-cell-label': {
          padding: `0 30px`,
        },
      },
      agGridCellLeftAligned: {
        paddingLeft: `30px`,
        paddingTop: '16px',
      },
      clickableText: {
        transition: theme.transitions.create('color'),
        '&:hover': {
          color: theme.dz.colors.listHover,
          cursor: 'pointer',
        },
      },
      ownerUnassignedCircle: {
        width: '36px',
        height: '36px',
        color: dzColors.unassignedUser,
      },
      noDataMessage: {
        fontSize: 16,
        color: dzColors.oneVisionBlack65,
        fontFamily: 'Roboto',
        fontWeight: 500,
      },
    };
  },
  { name: 'dz-grid' },
);

export type DzGridClasses = ReturnType<typeof useStyles>;
