import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
export const DzActionMenu = ({ item, actions, paperProps, }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (_jsxs("div", { children: [_jsx(IconButton, Object.assign({ "aria-label": "more", id: "long-button", "aria-controls": open ? 'long-menu' : undefined, "aria-expanded": open ? 'true' : undefined, "aria-haspopup": "true", onClick: handleClick }, { children: _jsx(MoreVertIcon, {}) })), _jsx(Menu, Object.assign({ id: "long-menu", MenuListProps: {
                    'aria-labelledby': 'long-button',
                }, anchorEl: anchorEl, open: open, onClose: handleClose, PaperProps: paperProps }, { children: actions.map((action) => (_jsxs(MenuItem, Object.assign({ disabled: action.disabled, onClick: action.disabled
                        ? undefined
                        : () => {
                            setAnchorEl(null);
                            action.onClick(item);
                        } }, { children: [action.icon && _jsx(ListItemIcon, { children: action.icon }), action.label] }), action.label))) }))] }));
};
