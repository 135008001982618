import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';

export const TO_UTC = ' UTC';

export const SECONDS_IN_DAY = 3600000;

export const DEFAULT_START_TIME = '08:00:00';
export const DEFAULT_END_TIME = '17:00:00';

export const DEFAULT_START_DAY = '00:00:00';
export const DEFAULT_END_DAY = '23:59:59';

export const addZero = (time: number) => (time < 10 ? `0${time}` : time);

export const isHolidayWholeDay = (startDate: string, endDate: string) => {
  const start = new Date(startDate + TO_UTC).valueOf();
  const end = new Date(endDate + TO_UTC).valueOf();

  const diff = Math.abs(end - start) / SECONDS_IN_DAY;
  const roundTwoDecimals = Math.round(diff * 100) / 100;

  return roundTwoDecimals % 24 === 0;
};

// get initial day, example '24 May 2023'
export const getInitDay = (date: string, timeZone: string) =>
  utcToZonedTime(date, timeZone).toLocaleDateString('en-GB', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

// TODO: Rewrite getInitTime and getDay with date-utils.
// get initial day, example '00:00:00'
export const getInitTime = (date: string, timeZone: string) =>
  utcToZonedTime(date, timeZone).toLocaleTimeString('en-GB');

//get UTC date, example '2023-07-24 00:00:00'
export const getDay = (date: string, timeZone: string) =>
  zonedTimeToUtc(date, timeZone).toISOString().split('.')[0];
