import React from 'react';
import {
  useSelectIdentityLabels,
  useSelectUserSidebar,
  useDzSelector,
} from 'core/redux';
import { useUserMenu, DzUserMenu } from 'shared-ui';
import { actions, thunks } from 'core/redux';
import { useDispatch } from 'react-redux';
import {
  DzUsersSidebarHeaderView,
  DzUsersSidebarHeaderPlaceholder,
} from '../dz-users-sidebar-header-view';
interface Props {
  isLoading: boolean;
  getClientAndAddresses: () => void;
}

export const DzUsersSidebarHeader: React.FC<Props> = ({
  isLoading,
  getClientAndAddresses,
}) => {
  const { client, addresses } = useSelectUserSidebar();

  const clients = useDzSelector((state) => state.clients);

  const dispatch = useDispatch();

  const labels = useSelectIdentityLabels();

  const {
    userMenuHandler,
    isTimerRunning,
    userMenuInfo,
    handleCloseUserMenu,
    handleEditUser,
    handleDisassociateUser,
    handleCopyID,
  } = useUserMenu({
    ovaid: addresses[0]?.ovaid,
    clients: client ? [client] : [],
    allClients: clients,
    actions,
    thunks,
    dispatch,
    labels,
  });

  if (isLoading || !client) {
    return <DzUsersSidebarHeaderPlaceholder />;
  }

  const handleEditUserWithExstras = async () => {
    await handleEditUser();

    getClientAndAddresses();
  };

  return (
    <>
      <DzUserMenu
        isTimerRunning={isTimerRunning}
        userMenuInfo={userMenuInfo}
        handleCloseUserMenu={handleCloseUserMenu}
        handleEditUser={handleEditUserWithExstras}
        handleDisassociateUser={handleDisassociateUser}
        handleCopyID={handleCopyID}
        isFullMenu={false}
      />
      <DzUsersSidebarHeaderView
        key={client?.ovcid}
        client={client}
        onMenuOpen={userMenuHandler}
      />
    </>
  );
};
