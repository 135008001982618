import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => ({
    input: {
        flex: 1,
        '& label[data-shrink="false"]': {
            maxWidth: theme.px2rem(250),
        },
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        columnGap: theme.spacing(1.5),
        padding: `${theme.spacing(1.5)} 0`,
    },
    option: {
        minHeight: theme.spacing(6),
        color: theme.palette.primary.main,
        '&[data-focus="true"]': {
            backgroundColor: '#EAF9FA',
        },
        whiteSpace: 'nowrap',
        '&.create:last-child': {
            borderTop: '1px solid #C4C4C4',
            backgroundColor: theme.black.black5,
        },
        '&.create.Mui-focused:last-child': {
            backgroundColor: theme.black.black20,
        },
    },
}));
