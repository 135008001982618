import { jsx as _jsx } from "react/jsx-runtime";
import Check from '@mui/icons-material/Check';
import { csn } from '@one-vision/utils';
import { useStyles } from './dz-step-icon.styles';
export const DzStepIcon = (props) => {
    const classes = useStyles();
    const { active, completed } = props;
    return (_jsx("div", Object.assign({ className: csn(classes.root, {
            [classes.active]: active,
        }) }, { children: completed ? (_jsx(Check, { className: classes.completed })) : (_jsx("div", { className: classes.circle })) })));
};
