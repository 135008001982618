var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { csn } from '@one-vision/utils';
import { IconButton } from '@mui/material';
import { TextField as TextFieldV5 } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { addZeroTime, coerceToDate, format } from '@one-vision/date-utils';
import { useCallback, useState } from 'react';
import { useStyles } from './dz-projects.styles';
import { ISO_DATE_FORMAT, ISO_DATE_MASK } from '../../../const';
export const DzCalendarPicker = ({ onChange, value, projectId, children, disabled, fieldKey, error, success, zendesk, }) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const open = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);
    const close = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);
    const handleChange = useCallback((date) => {
        const newDate = format(coerceToDate(date));
        if (value !== newDate) {
            onChange(newDate, projectId, fieldKey);
        }
    }, [onChange]);
    const getDatePickerClass = () => {
        if (success) {
            return classes.datePickerColorSuccess;
        }
        else if (error) {
            return classes.datePickerColorError;
        }
        else {
            return classes.datePicker;
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, Object.assign({ "data-testid": "calendar-button", disabled: disabled, onClick: open, className: classes.projectIcon }, { children: children })), _jsx(DatePicker, { renderInput: (_a) => {
                    var { inputProps } = _a, props = __rest(_a, ["inputProps"]);
                    return (_jsx(TextFieldV5, Object.assign({ "aria-readonly": true, fullWidth: true }, props, { inputProps: Object.assign(Object.assign({}, inputProps), { readOnly: true, placeholder: 'Not Found' }), "aria-label": "datePicker", "data-testid": "calendar-input", className: csn(getDatePickerClass(), [
                            classes.datePickerZen,
                            zendesk,
                        ]), variant: "standard" })));
                }, disableOpenPicker: true, onClose: close, value: value ? addZeroTime(value) : null, inputFormat: ISO_DATE_FORMAT, mask: ISO_DATE_MASK, InputProps: { disableUnderline: true }, open: isOpen, onChange: handleChange })] }));
};
