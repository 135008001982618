var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, useTheme } from '@mui/material';
import DateNotFoundIcon from '@mui/icons-material/CalendarToday';
import DateAvailableIcon from '@mui/icons-material/EventAvailable';
import DateBusyIcon from '@mui/icons-material/EventBusy';
import { csn } from '@one-vision/utils';
import { useEffect, useState } from 'react';
import { useStyles } from '../dz-address-panel.styles';
export var CalendarStatuses;
(function (CalendarStatuses) {
    CalendarStatuses["NOT_FOUND"] = "notFound";
    CalendarStatuses["BUSY"] = "busy";
    CalendarStatuses["AVAILABLE"] = "available";
})(CalendarStatuses || (CalendarStatuses = {}));
export const DzExpirationDateView = ({ address: { cppExpDate }, title = 'CPP Expiration', zendesk, }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [expirationInfo, setExpirationInfo] = useState({
        color: theme.dz.colors.oneVisionBlack65,
        status: CalendarStatuses.NOT_FOUND,
    });
    useEffect(() => {
        const now = new Date();
        const formattedCppExpDate = cppExpDate && new Date(cppExpDate);
        let updatedExpirationInfo;
        if (!formattedCppExpDate) {
            updatedExpirationInfo = {
                color: theme.dz.colors.oneVisionBlack65,
                status: CalendarStatuses.NOT_FOUND,
            };
        }
        else if (formattedCppExpDate < now) {
            updatedExpirationInfo = {
                color: theme.dz.colors.error,
                status: CalendarStatuses.BUSY,
            };
        }
        else if (formattedCppExpDate >= now) {
            updatedExpirationInfo = {
                color: theme.dz.colors.success,
                status: CalendarStatuses.AVAILABLE,
            };
        }
        if (updatedExpirationInfo) {
            setExpirationInfo(updatedExpirationInfo);
        }
    }, [cppExpDate]);
    const getIconComponent = (_a) => {
        var defaultProps = __rest(_a, []);
        switch (expirationInfo.status) {
            case CalendarStatuses.NOT_FOUND:
                return (_jsx(DateNotFoundIcon, Object.assign({ "data-testid": CalendarStatuses.NOT_FOUND }, defaultProps)));
            case CalendarStatuses.BUSY:
                return (_jsx(DateBusyIcon, Object.assign({ "data-testid": CalendarStatuses.BUSY }, defaultProps)));
            case CalendarStatuses.AVAILABLE:
                return (_jsx(DateAvailableIcon, Object.assign({ "data-testid": CalendarStatuses.AVAILABLE }, defaultProps)));
            default:
                return (_jsx(DateNotFoundIcon, Object.assign({ "data-testid": CalendarStatuses.NOT_FOUND }, defaultProps)));
        }
    };
    return (_jsxs(Typography, Object.assign({ "data-testid": "membership-exp-date", className: csn(classes.orgData, [classes.orgDataZen, zendesk]), component: 'span' }, { children: [_jsx("div", Object.assign({ className: csn(classes.orgDataTitle, classes.orgDataTitlePaddingLeft, [classes.orgDataTitleZen, zendesk]) }, { children: title })), getIconComponent({
                fontSize: 'small',
                htmlColor: expirationInfo.color,
            }), _jsx("div", Object.assign({ className: classes.orgDataValue, style: { color: expirationInfo.color } }, { children: cppExpDate || 'Not Found' }))] })));
};
