import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        fieldMargin: {
            margin: `0 0 ${theme.px2rem(12)}`,
        },
        orgDataSectionTitle: {
            fontSize: theme.px2rem(16),
            marginBottom: theme.px2rem(8),
            marginTop: theme.px2rem(8),
            fontWeight: 500,
            color: theme.palette.custom.OV.sys.outline,
            '&:first-of-type': {
                marginTop: 0,
            },
        },
        orgDataSectionTitleZen: {
            fontSize: theme.px2rem(14),
        },
        infoBlock: {
            padding: `0 ${theme.px2rem(15)}`,
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
        },
        infoLine: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        infoCaption: {
            fontWeight: 400,
            color: theme.palette.custom.OV.sys.onSurface,
            fontSize: theme.px2rem(14),
        },
        infoValue: {
            fontWeight: 500,
            color: theme.palette.custom.OV.sys.onSurface,
            fontSize: theme.px2rem(14),
            textAlign: 'end',
            maxWidth: theme.px2rem(200),
        },
        infoValueZen: {
            fontSize: theme.px2rem(12),
        },
        subSystemsHeader: {
            fontSize: theme.px2rem(14),
            marginBottom: theme.px2rem(4),
            paddingLeft: theme.px2rem(10),
            fontWeight: 500,
            color: '#6D6F71',
            marginTop: theme.px2rem(7),
        },
    };
}, { name: 'dz-site-documentation', index: 0 });
