import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      box: {
        padding: `${theme.px2rem(86)} 0`,
        width: '100%',
        maxWidth: `${theme.px2rem(750)}`,
        marginTop: `${theme.px2rem(86)}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:
          theme.palette.custom.OV.sys.surfaceContainerLowest,
        border: `1px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
      },
      uploadIcon: {
        width: theme.px2rem(126),
        height: theme.px2rem(100),
        marginBottom: 47,
      },
      button: {
        backgroundColor: theme.dz.colors.proactive,
        color: theme.palette.common.white,
        minWidth: theme.px2rem(259),
        '&:hover': {
          backgroundColor: '#713eb2',
        },
      },
      message: {
        width: '100%',
        maxWidth: `${theme.px2rem(220)}`,
        textAlign: 'center',
        marginBottom: `${theme.px2rem(22)}`,
      },
      title: {
        fontWeight: 700,
      },
      dialogActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      },
      dialogBox: {
        padding: `${theme.px2rem(40)} ${theme.px2rem(80)}`,
      },
      icon: {
        fontSize: 123,
        color: theme.dz.colors.success,
      },
      iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
      },
      csvLink: {
        marginTop: theme.spacing(1),
        color: theme.dz.colors.link,
      },
      progressBar: {
        height: 12,
        borderRadius: 0,
        background: '#ECDEFF',
        '& .MuiLinearProgress-barColorPrimary': {
          backgroundColor: '#A259FF',
        },
      },
      progressBox: {
        marginBottom: theme.spacing(3),
      },
      separator: {
        minWidth: theme.px2rem(600),
        height: 0,
        border: `1px dashed #D2D3D3`,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      amount: {
        marginBottom: theme.spacing(2),
        color: '#4C4D50',
      },
      animatedAppearance: {
        opacity: 0,
        animation: '$row-emergence-anim .6s ease-in-out normal forwards',
        transition: theme.transitions.create('background-color'),
      },
      '@keyframes row-emergence-anim': {
        '100%': {
          opacity: 1,
          transform: 'unset',
        },
      },
    };
  },
  { name: 'dz-data-import' },
);
