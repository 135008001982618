import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const authorizedSlice = createSlice({
  name: 'authorized',
  initialState: false as boolean,
  reducers: {
    setAuthorized: (_: boolean, action: PayloadAction<boolean>): boolean =>
      action.payload,
  },
});

export const authorized = {
  reducer: {
    [authorizedSlice.name]: authorizedSlice.reducer,
  },
  actions: {
    ...authorizedSlice.actions,
  },
};
