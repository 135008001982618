import { useMemo } from 'react';

import { FieldDefinition } from '../../../shared/dz-csv-import-editor';
import { emailRegex } from '../../../../shared/utils/email-regex';
import { useDzSelector } from 'core/redux';

export function useGridFieldDefinitions() {
  const users = useDzSelector((state) => state.owners);

  return useMemo<FieldDefinition[]>(
    () => [
      {
        gridLabel: 'First Name',
        fileLabel: 'first name',
        key: 'firstName',
        required: true,
      },
      {
        gridLabel: 'Last Name',
        fileLabel: 'last name',
        key: 'lastName',
        required: true,
      },
      {
        gridLabel: 'Email',
        fileLabel: 'email',
        key: 'email',
        validate: (value) => {
          if (!value) {
            return 'Email is required';
          }

          if (!emailRegex.test(value)) {
            return 'Email is not valid';
          }

          if (users.some((user) => user.email === value)) {
            return 'User with email already exists';
          }
        },
      },
    ],
    [users],
  );
}
