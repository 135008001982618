import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      label: {
        fontSize: theme.px2rem(16),
        fontWeight: 500,
      },
    };
  },
  { name: 'dz-add-user-to-address' },
);
