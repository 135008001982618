var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { autocompleteClasses, styled } from '@mui/material';
const StyledAutocompletePopper = styled('div')(() => ({
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}));
const DzRelativePopper = (_a) => {
    var { anchorEl, disablePortal, open } = _a, props = __rest(_a, ["anchorEl", "disablePortal", "open"]);
    return _jsx(StyledAutocompletePopper, Object.assign({}, props));
};
export default DzRelativePopper;
