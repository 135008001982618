import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography, IconButton, } from '@mui/material';
import { withModalManager } from '../../shared/dz-modal-manager';
import { useDrawerStyles } from '../../shared';
import CloseIcon from '@mui/icons-material/Close';
import { DzClientSelectionGroup } from '../dz-client-selection-group';
import { makeSingularEmailAndPhone } from '../dz-add-edit-client-dialog/dz-add-edit-client-dialog-utils';
import { logError } from '@one-vision/utils';
import { useStyles } from './dz-add-client-dialog.styles';
export const DzAddClientDialog = ({ isOpen, close, clear, apiClient, actions, dispatch, ovaid, existingClientIds, thunks, labels, allClients, zendesk, }) => {
    const classes = useStyles();
    const dialogClasses = useDrawerStyles();
    const onClose = useCallback(() => close(), [close]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isSubmitting, setSubmittingStatus] = useState(false);
    const handleSubmit = useCallback(async () => {
        if (!selectedClient) {
            return;
        }
        setSubmittingStatus(true);
        try {
            const changes = Object.assign(Object.assign({}, selectedClient), { ovaid });
            const result = await dispatch(thunks.updateClient({
                changes,
            })).unwrap();
            if (Array.isArray(result)) {
                // TODO: Check this logic, may be broken after the merge
                const singularContactsClient = makeSingularEmailAndPhone(result);
                dispatch(actions.clientUpdated(singularContactsClient));
            }
            else {
                // TODO: Check this logic, may be broken after the merge
                dispatch(actions.clientUpdated(result));
            }
            dispatch(actions.updateSnackbar({
                text: 'User was successfully added',
                type: 'success',
            }));
            close(result);
        }
        catch (error) {
            logError(error);
            dispatch(actions.updateSnackbar({
                type: 'error',
                text: 'Oops! Something went wrong...',
            }));
            setSubmittingStatus(false);
        }
    }, [
        selectedClient,
        ovaid,
        setSubmittingStatus,
        dispatch,
        actions,
        close,
        thunks,
    ]);
    return (_jsxs(Dialog, Object.assign({ open: isOpen, onClose: onClose, closeAfterTransition: true, transitionDuration: 400, TransitionComponent: Slide, TransitionProps: {
            direction: 'left',
            onExited: clear,
            mountOnEnter: true,
            unmountOnExit: true,
        }, classes: {
            paper: dialogClasses.paper,
            container: dialogClasses.container,
        }, disableRestoreFocus: true }, { children: [_jsxs(DialogTitle, Object.assign({ className: dialogClasses.header }, { children: [_jsx(Typography, { children: "Add User" }), _jsx(IconButton, Object.assign({ onClick: onClose, color: "inherit" }, { children: _jsx(CloseIcon, { color: "inherit" }) }))] })), _jsx(DialogContent, Object.assign({ className: dialogClasses.relativePosition, classes: {
                    root: classes.root,
                } }, { children: _jsx(DzClientSelectionGroup, { selectedClient: selectedClient, setSelectedClient: setSelectedClient, apiClient: apiClient, actions: actions, dispatch: dispatch, disabledClientIds: existingClientIds, thunks: thunks, labels: labels, allClients: allClients, zendesk: zendesk }) })), _jsx(DialogActions, Object.assign({ className: dialogClasses.actions }, { children: _jsx(Button, Object.assign({ size: "large", variant: "contained", color: "primary", onClick: handleSubmit, disabled: isSubmitting, "data-cy": "submit-add-user-dialog" }, { children: isSubmitting ? 'Saving...' : 'Save' })) }))] })));
};
export default withModalManager()(DzAddClientDialog);
