import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, Box, Collapse } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import DescriptionIcon from '@mui/icons-material/Description';
import LabelIcon from '@mui/icons-material/Label';
import { csn } from '@one-vision/utils';
import { useMemo, useState } from 'react';
import { CopyTooltip } from '../dz-copy-tooltip';
import { CLIENT_NOTE_HEIGHT } from '../../../const';
import { useTheme } from '@mui/styles';
import { getFieldsFromAttributes } from '../../../utils';
import { DzClientLabelsList } from '../dz-client-labels-list';
import { useStyles } from './dz-client-info-content.styles';
const ADDITIONAL_HEIGHT = 5;
export const DzClientInfoContentView = ({ isOpen, client, labels, clientLabels = [], userPanel, customClasses, zendesk, setNoteHeight, apiClient, ovaid, thunks, dispatch, }) => {
    var _a, _b;
    const classes = Object.assign(Object.assign({}, useStyles()), customClasses);
    const [ref, setRef] = useState(null);
    const theme = useTheme();
    const primaryEmail = useMemo(() => {
        var _a;
        return (_a = client === null || client === void 0 ? void 0 : client.emails) === null || _a === void 0 ? void 0 : _a.find((el) => el.clientEmailId === client.primaryClientEmailId);
    }, [client]);
    const primaryPhone = useMemo(() => {
        var _a;
        return (_a = client === null || client === void 0 ? void 0 : client.phones) === null || _a === void 0 ? void 0 : _a.find((el) => el.clientPhoneNumberId === client.primaryClientPhoneNumberId);
    }, [client]);
    const getLabel = (labelId, type) => {
        var _a, _b;
        return labelId && labels[type].length
            ? `(${(_b = (_a = labels[type].find((el) => el.id === labelId)) === null || _a === void 0 ? void 0 : _a.label) === null || _b === void 0 ? void 0 : _b.trim()})`
            : null;
    };
    const isEmailOpened = isOpen && primaryEmail && (client === null || client === void 0 ? void 0 : client.emails) && client.emails.length > 1;
    const isPhoneOpened = isOpen && primaryPhone && (client === null || client === void 0 ? void 0 : client.phones) && client.phones.length > 1;
    const refHandler = (ref) => {
        setRef(ref);
        if (setNoteHeight) {
            setNoteHeight((ref === null || ref === void 0 ? void 0 : ref.clientHeight) || 0);
        }
    };
    const defaultClientLabels = useMemo(() => (client === null || client === void 0 ? void 0 : client.clientLabels)
        ? getFieldsFromAttributes(client.clientLabels)
        : [], [client]);
    return (_jsxs(Box, Object.assign({ className: csn(classes.infoContainer, [
            classes.infoContainerZen,
            zendesk,
        ]) }, { children: [userPanel && (_jsx(Typography, Object.assign({ title: "Contact Methods", variant: "body2", className: classes.contentTitle }, { children: "Contact Methods" }))), _jsxs(Box, Object.assign({ className: classes.contentBlock }, { children: [_jsx(Box, Object.assign({ display: "flex", flexDirection: "column" }, { children: _jsx(EmailIcon, { className: csn(classes.infoIcon, [
                                classes.infoIconZen,
                                zendesk,
                            ]) }) })), _jsxs(Box, Object.assign({ display: "flex", flexDirection: "column" }, { children: [primaryEmail ? (_jsxs(Box, Object.assign({ display: "flex", flexDirection: "row" }, { children: [_jsx(CopyTooltip, Object.assign({ textToCopy: primaryEmail.email }, { children: _jsx(Typography, Object.assign({ className: csn(classes.hideTooWide, classes.infoData, [
                                                classes.hideTooWideZen,
                                                zendesk,
                                            ]) }, { children: primaryEmail.email })) })), _jsx(Typography, Object.assign({ className: classes.label }, { children: getLabel(primaryEmail.labelId, 'email') }))] }))) : (_jsx(Typography, Object.assign({ className: classes.infoData }, { children: "-" }))), _jsx(Collapse, Object.assign({ in: isEmailOpened }, { children: primaryEmail &&
                                    (client === null || client === void 0 ? void 0 : client.emails) &&
                                    ((_a = client.emails) === null || _a === void 0 ? void 0 : _a.length) > 1 &&
                                    client.emails
                                        .filter((el) => el.clientEmailId !== primaryEmail.clientEmailId)
                                        .map((el, index) => (_jsxs(Box, Object.assign({ display: "flex", flexDirection: "row" }, { children: [_jsx(CopyTooltip, Object.assign({ textToCopy: el.email }, { children: _jsx(Typography, Object.assign({ className: csn(classes.hideTooWide, classes.infoData, [classes.hideTooWideZen, zendesk]) }, { children: el.email })) })), _jsx(Typography, Object.assign({ className: classes.label }, { children: getLabel(el.labelId, 'email') }))] }), `copy-email-${index}`))) }))] }))] })), _jsxs(Box, Object.assign({ className: classes.contentBlock }, { children: [_jsx(Box, Object.assign({ display: "flex", flexDirection: "column" }, { children: _jsx(PhoneIcon, { className: csn(classes.infoIcon, [
                                classes.infoIconZen,
                                zendesk,
                            ]) }) })), _jsxs(Box, Object.assign({ display: "flex", flexDirection: "column" }, { children: [primaryPhone ? (_jsxs(Box, Object.assign({ display: "flex", flexDirection: "row" }, { children: [_jsx(CopyTooltip, Object.assign({ textToCopy: primaryPhone.phone }, { children: _jsx(Typography, Object.assign({ className: csn(classes.hideTooWide, classes.infoData, [
                                                classes.hideTooWideZen,
                                                zendesk,
                                            ]) }, { children: primaryPhone.phone })) })), _jsx(Typography, Object.assign({ className: classes.label }, { children: getLabel(primaryPhone.labelId, 'phone') }))] }))) : (_jsx(Typography, Object.assign({ className: classes.infoData }, { children: "-" }))), _jsx(Collapse, Object.assign({ in: isPhoneOpened }, { children: primaryPhone &&
                                    (client === null || client === void 0 ? void 0 : client.phones) &&
                                    client.phones.length > 1 &&
                                    client.phones
                                        .filter((el) => el.clientPhoneNumberId !==
                                        primaryPhone.clientPhoneNumberId)
                                        .map((el, index) => (_jsxs(Box, Object.assign({ display: "flex", flexDirection: "row" }, { children: [_jsx(CopyTooltip, Object.assign({ textToCopy: el.phone }, { children: _jsx(Typography, Object.assign({ className: csn(classes.hideTooWide, classes.infoData, [classes.hideTooWideZen, zendesk]) }, { children: el.phone })) })), _jsx(Typography, Object.assign({ className: classes.label }, { children: getLabel(el.labelId, 'phone') }))] }), `copy-phone-${index}`))) }))] }))] })), !userPanel && apiClient && (_jsxs(Box, Object.assign({ className: classes.contentBlock }, { children: [_jsx(Box, Object.assign({ display: "flex", flexDirection: "column" }, { children: _jsx(LabelIcon, { className: csn(classes.infoIcon, classes.clientLabelsIcon, [
                                classes.infoIconZen,
                                zendesk,
                            ]) }) })), _jsx(Box, Object.assign({ display: "flex", flexDirection: "row", flexWrap: "wrap" }, { children: _jsx(DzClientLabelsList, { defaultClientLabels: defaultClientLabels, clientLabels: clientLabels, apiClient: apiClient, ovaid: ovaid || '', ovcid: (client === null || client === void 0 ? void 0 : client.ovcid) || '', thunks: thunks, dispatch: dispatch }) }))] }))), userPanel && (_jsx(Typography, Object.assign({ title: "Contact Methods", variant: "body2", className: classes.contentTitle }, { children: "Notes" }))), _jsx(Box, Object.assign({ className: classes.contentBlock, width: "100%" }, { children: _jsxs(Box, Object.assign({ display: "flex", flexDirection: "row", width: "100%" }, { children: [_jsx(Box, Object.assign({ display: "flex", flexDirection: "column", justifyContent: "start" }, { children: _jsx(DescriptionIcon, { className: csn(classes.infoIcon, [
                                    classes.infoIconZen,
                                    zendesk,
                                ]) }) })), ((_b = client === null || client === void 0 ? void 0 : client.notes) === null || _b === void 0 ? void 0 : _b.trim()) ? (_jsx(Box, Object.assign({ width: "100%", className: classes.notesBlock }, { children: _jsx("div", Object.assign({ style: {
                                    height: isOpen && ref
                                        ? theme.px2rem((ref === null || ref === void 0 ? void 0 : ref.clientHeight) + ADDITIONAL_HEIGHT)
                                        : theme.px2rem(CLIENT_NOTE_HEIGHT),
                                }, className: classes.notesWrapper }, { children: _jsx("div", Object.assign({ ref: refHandler }, { children: client.notes })) })) }))) : (_jsx(Typography, Object.assign({ className: classes.notesBlock }, { children: "-" })))] })) }))] })));
};
