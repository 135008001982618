var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { useCallback, useEffect, useMemo, useState, } from 'react';
import { useStyles } from './dz-client-selection-group.styles';
import { useAutocompleteStyles } from '../../shared/styles/autocomplete.styles';
import DzCreationOption from '../dz-creation-option';
import { logError } from '@one-vision/utils';
import { useDebounce, useModalManager } from '../../shared';
import { ModalIDs } from '../../../const';
import DzSelectedCard from '../dz-selected-card';
import { Person } from '@mui/icons-material';
import { DzSkeletonList } from '../../shared/dz-autocomplete-list-skeleton';
const SEARCH_FIELDS_LIST = [
    'firstName',
    'lastName',
    'clientEmail',
    'clientPhoneNumber',
    'clientEmails.email',
    'clientPhoneNumbers.phone',
];
const CLIENT_INCLUDE = [
    'clientEmails',
    'primaryEmails',
    'clientPhoneNumbers',
    'primaryPhoneNumber',
];
const DEBOUNCE_TIME = 300;
const PAGE_LENGTH = 25;
function isClient(option) {
    return option.ovcid !== undefined;
}
const getRenderString = (option) => {
    var _a;
    if (isClient(option)) {
        const { firstName, lastName, primaryClientEmailId, emails } = option;
        const primaryEmail = emails === null || emails === void 0 ? void 0 : emails.find((email) => email.clientEmailId === primaryClientEmailId);
        const emailAddress = (primaryEmail === null || primaryEmail === void 0 ? void 0 : primaryEmail.email) ||
            (Array.isArray(primaryEmail) && ((_a = emails[0]) === null || _a === void 0 ? void 0 : _a.email)) ||
            'no email on file';
        return `${firstName} ${lastName} (${emailAddress})`;
    }
    return option.inputValue;
};
const getLabelString = (option) => {
    if (isClient(option)) {
        const { firstName, lastName, emails } = option;
        return `${firstName} ${lastName}${emails.map((email) => ` ${email.email}`)}`;
    }
    return option.inputValue;
};
const getClientTitle = (client) => client ? getRenderString(client) : '';
const DzClientSelectionGroup = ({ selectedClient, setSelectedClient, apiClient, actions, dispatch, immutable = false, disabledClientIds = [], thunks, labels, allClients, zendesk, }) => {
    const classes = useStyles();
    const autocompleteClasses = useAutocompleteStyles();
    const { openModal } = useModalManager();
    const [isLoading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [clients, setClients] = useState([]);
    const searchValue = useDebounce(inputValue, DEBOUNCE_TIME);
    const title = useMemo(() => getClientTitle(selectedClient), [selectedClient]);
    const disabledClientsSet = useMemo(() => new Set(disabledClientIds), [disabledClientIds]);
    const handleInputChange = useCallback((_, newValue) => {
        setInputValue(newValue);
    }, [setInputValue]);
    const onCreate = useCallback(async () => {
        const newClient = await openModal(ModalIDs.addEditClient, {
            clients: allClients,
            apiClient,
            actions,
            dispatch,
            thunks,
            labels,
            create: true,
            zendesk,
        });
        if (newClient) {
            setClients((previous) => [...previous, newClient]);
            setSelectedClient(newClient);
        }
    }, [setSelectedClient, setClients, openModal, clients]);
    const handleChange = useCallback((_, value) => {
        if (value === null) {
            setSelectedClient(null);
            return;
        }
        if (isClient(value)) {
            setSelectedClient(value);
            return;
        }
        onCreate();
    }, [setSelectedClient, onCreate]);
    const closeCard = useCallback(() => {
        setSelectedClient(null);
    }, [setSelectedClient]);
    const getClients = async () => {
        const params = {
            search: { fields: SEARCH_FIELDS_LIST, value: searchValue },
            page: { limit: PAGE_LENGTH, offset: 0 },
            include: CLIENT_INCLUDE,
        };
        const { data } = await apiClient.getClient(params);
        const list = data.data.map((client) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            const emails = ((_b = (_a = client.relationships) === null || _a === void 0 ? void 0 : _a.clientEmails) === null || _b === void 0 ? void 0 : _b.data.map((email) => {
                var _a, _b, _c;
                return ({
                    clientEmailId: email.id,
                    email: ((_c = (_b = (_a = data.included) === null || _a === void 0 ? void 0 : _a.find((item) => item.id === email.id)) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.email) || null,
                    clientId: client.id,
                });
            })) || [];
            const phones = ((_d = (_c = client.relationships) === null || _c === void 0 ? void 0 : _c.clientPhoneNumbers) === null || _d === void 0 ? void 0 : _d.data.map((el) => {
                var _a, _b, _c;
                return ({
                    clientPhoneNumberId: el.id,
                    phone: ((_c = (_b = (_a = data.included) === null || _a === void 0 ? void 0 : _a.find((item) => item.id === el.id)) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.phone) || null,
                    clientId: client.id,
                });
            })) || [];
            return Object.assign(Object.assign({}, client.attributes), { ovcid: client.id, primaryClientEmailId: ((_f = (_e = client.relationships) === null || _e === void 0 ? void 0 : _e.primaryEmails) === null || _f === void 0 ? void 0 : _f.data[0].id) || '', primaryClientPhoneNumberId: ((_h = (_g = client.relationships) === null || _g === void 0 ? void 0 : _g.primaryPhoneNumber) === null || _h === void 0 ? void 0 : _h.data[0].id) || '', emails,
                phones, zdOrgId: '', clientRoleId: 0 });
        });
        return list;
    };
    useEffect(() => {
        if (!searchValue) {
            setClients([]);
            return;
        }
        (async () => {
            setLoading(true);
            try {
                const list = await getClients();
                setClients(list);
            }
            catch (error) {
                logError(error);
                dispatch(actions.updateSnackbar({
                    type: 'error',
                    text: 'Oops! Something went wrong...',
                }));
            }
            finally {
                setLoading(false);
            }
        })();
    }, [searchValue]);
    return (_jsxs(Box, Object.assign({ className: classes.container }, { children: [selectedClient && (_jsx(DzSelectedCard, { showCloseButton: !immutable, onClose: closeCard, title: title, Icon: Person })), !selectedClient && (_jsxs(_Fragment, { children: [_jsx(Autocomplete, { autoHighlight: true, clearOnBlur: false, inputValue: inputValue, onInputChange: handleInputChange, value: null, loading: isLoading, loadingText: _jsx(DzSkeletonList, {}), className: classes.input, renderInput: (params) => (_jsx(TextField, Object.assign({ autoFocus: true }, params, { label: "Find and associate an existing user", margin: "none" }))), options: clients, onChange: handleChange, filterOptions: (options) => options, 
                        // @ts-ignore TODO: Figure out renderOption typings problem.
                        renderOption: (_a, option) => {
                            var { key } = _a, props = __rest(_a, ["key"]);
                            if (isClient(option)) {
                                return (_createElement("li", Object.assign({}, props, { key: key + option.ovcid }), getRenderString(option)));
                            }
                            return (_jsx(DzCreationOption, { description: "Can\u2019t find the user you\u2019re looking for?", listItemProps: props }, "creationOption"));
                        }, getOptionLabel: getLabelString, getOptionDisabled: (option) => isClient(option)
                            ? disabledClientsSet.has(option.ovcid)
                            : false, classes: {
                            paper: autocompleteClasses.paper,
                            listbox: autocompleteClasses.listbox,
                            option: autocompleteClasses.option,
                        } }), _jsx(Button, Object.assign({ size: "large", variant: "contained", color: "primary", onClick: onCreate, disabled: isLoading }, { children: "Create New" }))] }))] })));
};
export default DzClientSelectionGroup;
