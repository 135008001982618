import { makeUseFavoriteViews, makeUseViews } from 'shared/hooks';
import { OrganizationsViews } from 'shared/hooks/makeUseFavoriteViews';

export const views = [
  {
    id: OrganizationsViews.allOrganizations,
    text: 'All Organizations',
  },
  {
    id: OrganizationsViews.withProject,
    text: `Organizations w/ a project in service`,
  },
  {
    id: OrganizationsViews.expiredCPPs,
    text: 'Expired CPPs',
  },
  {
    id: OrganizationsViews.activeCPPs,
    text: `Active CPPs`,
  },
];

export const useOrganizationsFavoriteViews =
  makeUseFavoriteViews('organization');

export const useOrganizationsViews = makeUseViews<
  typeof useOrganizationsFavoriteViews
>('organization', views, useOrganizationsFavoriteViews);
