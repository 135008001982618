import React, { useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CircularProgress from '@mui/material/CircularProgress';

import { DzAsyncDispatch } from 'shared-ui';
import { actions } from 'core/redux';
import { fromFormat, format } from '@one-vision/date-utils';
import { PartnerDomain } from 'core/redux/partner-domains';
import { DzDeleteConfirmationDialogView } from './dz-delete-confirmation-dialog.view';
import { useDeletePartnerDomain } from './useDeletePartnerDomain';
import { useStyles } from './dz-users.partner-domain-details.styles';

export function PartnerDomainDetails(props: {
  partnerDomain: PartnerDomain;
}) {
  const classes = useStyles();
  const triggerRef = useRef(null);
  const toggleMenu = () => setMenuIsOpen((isOpen) => !isOpen);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const closeMenu = () => setMenuIsOpen(false);

  const [isConfirmDialogOpen, setOpenConfirmDialog] = useState(false);

  const { deletePartnerDomain, loading } = useDeletePartnerDomain(
    props.partnerDomain,
  );
  const dispatch = useDispatch<DzAsyncDispatch>();
  const triggerDeletion = async () => {
    try {
      await deletePartnerDomain();
      dispatch(
        actions.updateSnackbar({
          text: 'Domain removed',
          type: 'success',
        }),
      );
    } catch {
      dispatch(
        actions.updateSnackbar({
          text: 'Error trying to remove domain. Please, try again.',
          type: 'error',
        }),
      );
    }
  };

  return (
    <>
      <span className={classes.date}>
        Added on {useCreationDate(props.partnerDomain)}
      </span>
      <IconButton ref={triggerRef} onClick={toggleMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={menuIsOpen}
        anchorEl={triggerRef.current}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={() => setOpenConfirmDialog(true)}
          disabled={loading}
        >
          <ListItemIcon>
            {loading ? (
              <CircularProgress size="1.3rem" />
            ) : (
              <DeleteForeverIcon />
            )}
          </ListItemIcon>
          <Typography variant="inherit">Remove domain</Typography>
        </MenuItem>
      </Menu>
      <DzDeleteConfirmationDialogView
        type="partner-domain"
        open={isConfirmDialogOpen}
        onCancel={() => {
          setOpenConfirmDialog(false);
          closeMenu();
        }}
        onDelete={() => {
          setOpenConfirmDialog(false);
          triggerDeletion();
        }}
      />
    </>
  );
}

function useCreationDate(partnerDomain: PartnerDomain) {
  return useMemo(() => {
    const date = fromFormat(partnerDomain.createdAt, 'yyyy-MM-dd');

    return format(date, 'MMM dd, yyyy');
  }, [partnerDomain.createdAt]);
}
