import {
  GlobalSearchResultTypeKey,
  GlobalSearchAPIClient,
  GlobalSearchAPIProject,
  GlobalSearchAPIAddress,
  GlobalSearchAddress,
  GlobalSearchClient,
  GlobalSearchProject,
  GlobalSearchResultType,
  GlobalSearchItems,
} from './dz-global-search-types';
import { ITEMS_PER_PAGE } from './dz-global-search-constants';
import uniqBy from 'lodash/uniqBy';
import { SHOW_LESS, SHOW_MORE, SHOW_MORE_DIVIDER } from 'shared/constants';

type BlockSettings =
  | {
      headerText: 'Users';
      uniqueFieldName: 'ovcid';
    }
  | {
      headerText: 'Projects';
      uniqueFieldName: 'ovprjid';
    }
  | {
      headerText: 'Organizations';
      uniqueFieldName: 'ovaid';
    }
  | { headerText: ''; uniqueFieldName: '' };

export const getUniqueBlockSettings = (
  type: GlobalSearchResultTypeKey,
): BlockSettings => {
  switch (type) {
    case 'clients': {
      return {
        headerText: 'Users',
        uniqueFieldName: 'ovcid',
      };
    }

    case 'projects': {
      return {
        headerText: 'Projects',
        uniqueFieldName: 'ovprjid',
      };
    }

    case 'addresses': {
      return {
        headerText: 'Organizations',
        uniqueFieldName: 'ovaid',
      };
    }

    default: {
      return {
        headerText: '',
        uniqueFieldName: '',
      };
    }
  }
};

export const getAddressValue = ({
  address1,
  address2,
  city,
  businessName,
}: GlobalSearchAddress) => {
  return `${city ? city + ' - ' : ''}${address1}${
    address2 ? ', ' + address2 : ''
  }${businessName ? ' (' + businessName + ')' : ''}`.trim();
};

export const getClientValue = (result: GlobalSearchClient) => {
  if (!result.firstName) {
    return result.email;
  }

  return `${result.firstName} ${result.lastName ? result.lastName : ''}${
    result.email ? ' (' + result.email + ')' : ''
  }`;
};

export const getProjectValue = (result: GlobalSearchProject) => {
  return `${result.name ? result.name : ''}${
    result.address1
      ? ' | ' +
        result.address1 +
        (result.address2 ? ', ' + result.address2 : '')
      : result.address2
      ? ' | ' + result.address2
      : ''
  }${result.firstName ? ' | ' + result.firstName : ''}${
    result.lastName && result.lastName !== result.firstName
      ? (result.firstName ? ' ' : ' | ') + result.lastName
      : ''
  }`;
};

export const getRowText = (
  type: GlobalSearchResultTypeKey,
  result:
    | GlobalSearchAPIAddress
    | GlobalSearchAPIClient
    | GlobalSearchAPIProject,
) => {
  switch (type) {
    case 'addresses': {
      return getAddressValue(result as GlobalSearchAddress);
    }
    case 'clients': {
      return getClientValue(result as GlobalSearchAPIClient);
    }
    case 'projects': {
      return getProjectValue(result as GlobalSearchAPIProject);
    }
  }
};

export interface SearchHighlight {
  startIndex: number;
  endIndex: number;
}

const matchWordRecursively = (
  result: SearchHighlight[],
  text: string,
  matchedWords: string[],
): SearchHighlight[] => {
  if (!matchedWords.length) {
    return result;
  }

  let lowercaseText = text.toLowerCase();

  const lowercaseSearchValue = matchedWords[0].toLowerCase();

  let iterationCounterForSafety = 0;
  let prevEndIndex = 0;

  while (
    lowercaseText.indexOf(lowercaseSearchValue) > -1 &&
    iterationCounterForSafety < 10
  ) {
    iterationCounterForSafety += 1;
    const startIndex = lowercaseText.indexOf(lowercaseSearchValue);

    if (startIndex < 0) {
      continue;
    }

    const endIndex = startIndex + lowercaseSearchValue.length;

    const isNotIncluded = result.every((item) => {
      return (
        (prevEndIndex + startIndex < item.startIndex ||
          prevEndIndex + startIndex > item.endIndex) &&
        (prevEndIndex + endIndex < item.startIndex ||
          prevEndIndex + endIndex > item.endIndex)
      );
    });

    if (isNotIncluded) {
      result.push({
        startIndex: prevEndIndex + startIndex,
        endIndex: prevEndIndex + endIndex,
      });
    }

    lowercaseText = lowercaseText.slice(endIndex);
    prevEndIndex = prevEndIndex + endIndex;
  }

  matchedWords.shift();
  return matchWordRecursively(result, text, matchedWords);
};

export const getHiglightedTextParts = (
  text: string | null,
  searchValue: string,
): SearchHighlight[] => {
  if (!text || !searchValue) {
    return [];
  }

  const searchWords = searchValue.split(' ').filter((item) => !!item);

  const lowercaseText = text.toLowerCase();

  const matchedWords = searchWords.filter((word) =>
    lowercaseText.includes(word.toLowerCase()),
  );

  return matchWordRecursively([], text, matchedWords);
};

export const cleanUpSearchValue = (searchValue: string) => {
  if (searchValue.includes('+1')) {
    return searchValue
      .replace(/\+1/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/-/g, '')
      .replace(/ /g, '');
  }

  return searchValue;
};

export const getShowMoreParams = (
  results:
    | GlobalSearchResultType['addresses']['items']
    | GlobalSearchResultType['clients']['items']
    | GlobalSearchResultType['projects']['items'],
  totalCount: number,
) => {
  const isShowMoreVisible =
    totalCount > results.length || totalCount > ITEMS_PER_PAGE;

  const hasMoreItemsToShow = results.length !== totalCount;
  const areMoreItemsShownThanDefault = results.length > ITEMS_PER_PAGE;

  return {
    isShowMoreVisible,
    hasMoreItemsToShow,
    areMoreItemsShownThanDefault,
  };
};

export const getIdsFromResult = (
  searchResult: GlobalSearchResultType,
): string[] => {
  return (Object.keys(searchResult) as Array<keyof typeof searchResult>)
    .sort()
    .map((key) => {
      const uniqueFieldName = getUniqueBlockSettings(key).uniqueFieldName;
      const results = uniqBy<GlobalSearchAPIAddress>(
        searchResult[key]
          .items as GlobalSearchResultType['addresses']['items'],
        (item: GlobalSearchAPIAddress) => {
          return item[uniqueFieldName as 'ovaid'];
        },
      ) as GlobalSearchItems;

      const resultList = results.map(
        (result) =>
          result[uniqueFieldName as keyof typeof result] as string,
      );

      const totalCount = parseInt(searchResult[key].totalCount, 10);

      const {
        isShowMoreVisible,
        hasMoreItemsToShow,
        areMoreItemsShownThanDefault,
      } = getShowMoreParams(results, totalCount);

      if (isShowMoreVisible) {
        if (hasMoreItemsToShow) {
          resultList.push(`${SHOW_MORE}${SHOW_MORE_DIVIDER}${key}`);
        }

        if (areMoreItemsShownThanDefault) {
          resultList.push(`${SHOW_LESS}${SHOW_MORE_DIVIDER}${key}`);
        }
      }

      return resultList;
    })
    .flat();
};
