import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { DzDialog, DzRichTextEditor, useApiClient, withModalManager, } from '../../shared';
import { replaceNewLineWithParagraph, S3Client } from '../../../utils';
const DzUpdatePartnerDetails = ({ caption, fieldName, partnerDetails, dispatch, actions, isOpen, close, clear, zendesk = false, }) => {
    const [fieldValue, setFieldValue] = useState(partnerDetails[fieldName] || '');
    const [loading, setIsLoading] = useState(false);
    const apiClient = useApiClient();
    const s3Client = useMemo(() => {
        return new S3Client({ apiClient });
    }, [apiClient]);
    const handleOnChangeMembershipSalesNotes = useCallback(({ editor }) => {
        const value = editor.getHTML();
        setFieldValue(value);
    }, [setFieldValue]);
    const content = useMemo(() => (_jsx(DzRichTextEditor, { content: replaceNewLineWithParagraph(partnerDetails[fieldName] || ''), onUpdate: handleOnChangeMembershipSalesNotes, s3Client: s3Client })), [partnerDetails]);
    const handleSubmit = useCallback(async () => {
        var _a, _b;
        try {
            setIsLoading(true);
            const { data } = await apiClient.updatePartnerDetails({
                [fieldName]: fieldValue,
                ovpid: partnerDetails.ovpid,
            });
            close(data);
        }
        catch (e) {
            const err = e;
            dispatch(actions.updateSnackbar({
                text: ((_b = (_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) || 'Error',
                type: 'error',
            }));
        }
        finally {
            setIsLoading(false);
        }
    }, [fieldValue]);
    const handleClose = useCallback(() => {
        close();
    }, []);
    return (_jsx(DzDialog, { isOpen: isOpen, content: content, caption: caption, okButtonText: "Save", onClose: handleClose, onOk: handleSubmit, okButtonDisabled: loading, clear: clear, zendesk: zendesk }));
};
export default withModalManager()(DzUpdatePartnerDetails);
