import { DzStore } from '.';
import { DeepPartial } from '../../types/deep-partial';

type StoreCacheConfig<T> = {
  [K in keyof T]?: boolean | StoreCacheConfig<T[K]>;
};

const storeCacheConfig: StoreCacheConfig<DzStore> = {
  // Nested slice caching example:
  // sidebars: {
  //   addressPanel: true,
  // },
  settings: true,
  projectEntities: true,
  projectStageEntities: true,
  projectLostReasonEntities: true,
  clients: true,
  clientEntities: true,
  clientLabelEntities: true,
  ownerEntities: true,
  addresses: true,
  addressEntities: true,
  owners: true,
};

// Limit the number of cached items per slice to
// fit localStorage quota.
const MAX_ITEMS_CACHED_IN_LIST = 1000;
export function deepSlice<T, SC = StoreCacheConfig<T>>(
  config: SC | boolean,
  source: T,
): DeepPartial<T> | T | undefined {
  if (typeof source === 'object' && typeof config === 'object') {
    return Object.keys(config).reduce<DeepPartial<T>>(
      (acc: DeepPartial<T>, key) => ({
        ...acc,
        [key]: deepSlice(
          config[key as keyof typeof config],
          source[key as keyof typeof source],
        ),
      }),
      {} as DeepPartial<T>,
    );
  } else {
    if (!config) return;

    return Array.isArray(source)
      ? (source.slice(0, MAX_ITEMS_CACHED_IN_LIST) as T)
      : source;
  }
}

export const deepSliceStore = deepSlice.bind(null, storeCacheConfig);
