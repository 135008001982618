import React from 'react';
import { Frequency } from 'rrule';
import { DzOwner } from 'shared-ui';

export enum RecurrenceOptions {
  Once = 'once',
  Weekly = 'RRULE:FREQ=WEEKLY;INTERVAL=1;WKST=SU',
  Every2Weeks = 'RRULE:FREQ=WEEKLY;INTERVAL=2;WKST=SU',
  Every3Weeks = 'RRULE:FREQ=WEEKLY;INTERVAL=3;WKST=SU',
  Monthly = 'RRULE:FREQ=MONTHLY;INTERVAL=1;WKST=SU',
  Custom = 'custom',
}

export interface OncallScheduleEntity {
  id: string;
  createdAt: string;
  startTime: string;
  endTime: string;
  recurrence: string;
  updatedAt: string;
}

export type OncallScheduleAgent = DzOwner & {
  id: string;
  firstName?: string;
  lastName?: string;
};
export interface FlatOncallScheduleEventEntitiesResponse {
  entities: {
    OncallManagementSchedule: Array<
      OncallScheduleEntity & {
        relationships: {
          primaryAgent: Array<{ id: string }>;
          secondaryAgent: Array<{ id: string }>;
        };
      }
    >;
    User: OncallScheduleAgent[];
  };
}

export type NormalizedOncallSchedule = OncallScheduleEntity & {
  primaryAgent: OncallScheduleAgent;
  secondaryAgent?: OncallScheduleAgent;
};

export interface CalendarEventObject {
  id: string;
  start: Date;
  end: Date;
  recurrence: string;
  primaryAgentId: string;
  secondaryAgentId?: string;
  title: React.ReactNode;
  tooltip: string;
  isTemporary?: boolean;
}

export interface OncallManagementDialogFormData {
  startTime: string;
  endTime: string;
  recurrence: string;
  primaryAgentId: string;
  secondaryAgentId?: string;
  editType?: string;
}

export interface OncallSchedulePayload {
  id?: string;
  startTime: string;
  endTime: string;
  recurrence: string;
  primaryAgent: DzOwner;
  secondaryAgent?: DzOwner | null;
}

export interface CreateOncallScheduleResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      startTime: string;
      endTime: string;
      recurrence: string;
      createdAt: string;
      updatedAt: string;
    };
  };
}

export interface ScheduleFormModel {
  primaryAgentId: string;
  secondaryAgentId: string;
  dateStart: Date;
  timeStart: string;
  timeEnd: string;
  recurrence: string;
  recurrenceInterval?: number;
  recurrencePeriod?: Frequency;
  editType: string;
}
