import { logError } from '@one-vision/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DzPartner, PARTNER_GROUP_RULE, isTrue } from 'shared-ui';
import { PartnerGroupRuleEntity, DzPartnerDetails } from 'shared-ui';
import { API } from '../../api';

export const fetchPartners = createAsyncThunk<DzPartner[]>(
  'partners/get',
  async () => {
    try {
      const response = await API.getPartners();
      return response.data;
    } catch (error) {
      // thunkAPI.rejectWithValue
      logError(error);
      return [];
    }
  },
);

export const fetchPartnerDetails = createAsyncThunk<
  DzPartnerDetails,
  { ovpid: string }
>(
  'partners/fetchPartnerDetails',
  async (args): Promise<DzPartnerDetails> => {
    try {
      const { ovpid } = args;
      const { data } = await API.getPartnerDetails(ovpid);
      return data;
    } catch (error) {
      logError(error);
      return {} as Promise<DzPartnerDetails>;
    }
  },
);

export const fetchPartnerConfig = createAsyncThunk(
  'partners/fetchPartnerConfig',
  async (): Promise<{
    data: PartnerGroupRuleEntity[];
  }> => {
    try {
      const { data } = await API.getPartnerConfig({
        page: {
          limit: Number.MAX_SAFE_INTEGER,
          offset: 0,
        },
        include: ['partnerGroupRule'],
      });
      return data;
    } catch (error) {
      logError(error);
      return { data: [] };
    }
  },
);

export const updatePartnerDetails = createAsyncThunk<
  DzPartnerDetails,
  {
    ovpid: string;
    changes: Partial<DzPartnerDetails>;
  }
>('partners/updatePartnerDetails', async (arg, thunkAPI) => {
  const { ovpid, changes } = arg;
  try {
    const response = await API.updatePartnerDetails({
      ovpid: ovpid,
      ...changes,
    });
    thunkAPI.dispatch(fetchPartnerDetails({ ovpid }));
    return response.data;
  } catch (error) {
    thunkAPI.rejectWithValue({ ovpid });
    logError(error);
    return {} as Promise<DzPartnerDetails>;
  }
});

export const partnerDetailsSlice = createSlice({
  name: 'partnerDetails',
  initialState: {} as DzPartnerDetails,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchPartnerDetails.fulfilled,
        (_, action: ReturnType<typeof fetchPartnerDetails.fulfilled>) => {
          return action.payload;
        },
      )
      .addCase(
        updatePartnerDetails.fulfilled,
        (_, action: ReturnType<typeof updatePartnerDetails.fulfilled>) => {
          return action.payload;
        },
      );
  },
});

export const partnersSlice = createSlice({
  name: 'partners',
  initialState: [] as DzPartner[],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchPartners.fulfilled,
      (_, action: ReturnType<typeof fetchPartners.fulfilled>) => {
        return action.payload.sort((first, second) =>
          first.name.localeCompare(second.name),
        );
      },
    );
  },
});

export const partnersConfigSlice = createSlice({
  name: 'partnerConfig',
  initialState: { loading: true } as Partial<
    Record<PARTNER_GROUP_RULE, boolean>
  > & {
    loading: boolean;
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchPartnerConfig.fulfilled,
      (_, action: ReturnType<typeof fetchPartnerConfig.fulfilled>) => {
        const records = action.payload.data || [];
        if (!records.length) {
          return { loading: false };
        }
        return records.reduce((result, record) => {
          const value = record.attributes.value || '';
          const ruleId =
            record.relationships?.partnerGroupRule.data.pop()?.id;
          if (!value || !ruleId) {
            return result;
          }
          result[ruleId as PARTNER_GROUP_RULE] = isTrue(value);
          return { ...result, loading: false };
        }, {} as Partial<Record<PARTNER_GROUP_RULE, boolean>> & { loading: boolean });
      },
    );
    builder.addCase(fetchPartnerConfig.pending, (state, _) => ({
      ...state,
      loading: true,
    }));
  },
});
