var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ClickAwayListener, Popper, Paper, Autocomplete, TextField, } from '@mui/material';
import { useStyles } from './dz-autocomplete-popper.styles';
import DzRelativePopper from '../dz-relative-popper';
export const DzAutocompletePopperView = ({ isOpen, anchorEl, multiple, closeAutocomplete, placeholder, label, options, value, removeTags, onChange, renderOption, getOptionLabel, getOptionDisabled, }) => {
    const id = isOpen ? 'autocomplete-popper' : undefined;
    const classes = useStyles();
    return (_jsx(Popper, Object.assign({ id: id, open: isOpen, anchorEl: anchorEl === null || anchorEl === void 0 ? void 0 : anchorEl.current, className: classes.popper }, { children: _jsx(ClickAwayListener, Object.assign({ onClickAway: closeAutocomplete }, { children: _jsx(Paper, { children: _jsx(Autocomplete, { autoHighlight: true, open: true, multiple: multiple, disablePortal: true, popupIcon: null, renderTags: removeTags ? () => null : undefined, PopperComponent: DzRelativePopper, renderInput: (_a) => {
                        var { InputProps, inputProps } = _a, params = __rest(_a, ["InputProps", "inputProps"]);
                        return (_jsx(TextField, Object.assign({ autoFocus: true }, params, { InputProps: Object.assign(Object.assign({}, InputProps), { disableUnderline: true, classes: {
                                    root: classes.inputRoot,
                                } }), inputProps: Object.assign(Object.assign({}, inputProps), { 'aria-label': label }), margin: "none", variant: "standard", placeholder: placeholder })));
                    }, options: options, getOptionLabel: getOptionLabel, getOptionDisabled: getOptionDisabled, 
                    // @ts-ignore TODO: Figure out renderOption typings problem.
                    renderOption: renderOption, onClose: (_, reason) => {
                        if (reason === 'escape') {
                            closeAutocomplete();
                        }
                    }, value: value, onChange: (_, option) => {
                        if (typeof onChange === 'function')
                            onChange(option);
                        if (!multiple)
                            closeAutocomplete();
                    } }) }) })) })));
};
