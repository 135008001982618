import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => ({
    emptyNoticeText: {
        textAlign: 'center',
        fontSize: theme.px2rem(12),
        marginBottom: theme.px2rem(8),
        marginTop: theme.px2rem(8),
        fontWeight: 500,
        color: theme.palette.custom.OV.sys.outline,
        '&:first-of-type': {
            marginTop: 0,
        },
    },
    contentRoot: {
        paddingTop: theme.px2rem(20),
        display: 'flex',
        flexDirection: 'column',
    },
    additionalContextButton: {
        fontSize: '14px',
        fontWeight: '500',
        color: '#2AC5C9',
        width: '186px',
    },
    additionalContextFieldContainer: {
        width: '100%',
        position: 'relative',
        marginTop: theme.px2rem(10),
        marginBottom: theme.px2rem(10),
    },
    additionalContextField: {
        width: '100%',
        alignSelf: 'flex-end',
    },
    topIconRoot: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    aiHelpRoot: {
        position: 'relative',
        border: '1px solid #6D6F71',
        padding: theme.px2rem(20),
        marginTop: theme.px2rem(20),
        marginBottom: theme.px2rem(20),
    },
}), { name: 'dz-ai-assistant', index: 0 });
