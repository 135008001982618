import React, { useCallback, useRef } from 'react';
import { Button } from '@mui/material';
import Papa, { ParseError } from 'papaparse';

import { useStyles } from './dz-csv-file-picker.styles';

interface Props<T> {
  onData: (data: Partial<T>[]) => void;
  onError: (details?: string) => void;
  onLoading?: (loading: boolean) => void;
}

export const DzCsvFilePicker = <T,>({
  onData,
  onError,
  onLoading,
}: Props<T>): React.ReactElement => {
  const classes = useStyles();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const openFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleError = useCallback(
    (error: Error | ParseError) => {
      onError(error?.message);
      onLoading?.(false);
    },
    [onError, onLoading],
  );

  const handleComplete = useCallback(
    (parseResult: Papa.ParseResult<T>) => {
      if (parseResult.errors.length) {
        handleError(parseResult.errors[0]);
        return;
      }

      onData(parseResult.data);
      onLoading?.(false);
    },
    [handleError, onData, onLoading],
  );

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files?.length) {
        return;
      }

      const file = event.target.files[0];
      if (isNotCSVFile(file)) {
        onError('Not a CSV file');
        return;
      }

      onLoading?.(true);
      Papa.parse<T>(file, {
        header: true,
        skipEmptyLines: 'greedy',
        worker: true,
        complete: handleComplete,
        error: handleError,
      });
    },
    [handleComplete, handleError, onError, onLoading],
  );

  return (
    <>
      <Button
        className={classes.browseFiles}
        variant="contained"
        onClick={openFileSelector}
      >
        Browse Files (CSV)
      </Button>
      <input
        type="file"
        accept=".csv"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </>
  );
};

function isNotCSVFile(file: File): boolean {
  const fileExtension = file.name
    .slice(file.name.lastIndexOf('.'))
    .toLowerCase();

  return !['.csv'].includes(fileExtension);
}
