export const include = (set = [], item) => item == null || set.includes(item) ? set : [...set, item];
export const removeArrItem = (arr, index) => [
    ...arr.slice(0, index),
    ...arr.slice(index + 1),
];
export const exclude = (set = [], item) => {
    if (item == null) {
        return set;
    }
    const index = set.indexOf(item);
    if (index < 0)
        return set;
    return removeArrItem(set, index);
};
export const mergeLists = (list1, list2) => list2.reduce((list, item) => include(list, item), list1);
export const getNextPage = (requestedPage, { page, perPage, totalCount }) => requestedPage || (page * perPage > totalCount ? page : page + 1);
