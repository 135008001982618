import {
  JsonApiMessage,
  UserJsonApiEntity,
  readAsList,
} from '@one-vision/json-api-parser';
import { logError } from '@one-vision/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  UserConfigContextValue,
  getPartnerRules,
  getUserRules,
} from 'shared-ui';
import { API } from '../../api';

export const fetchUserDetails = createAsyncThunk(
  'user/fetchUserDetails',
  async () => {
    try {
      const { data } = await API.getUserDetails({
        page: {
          limit: 1,
          offset: 0,
        },
        include: [
          'userGroup.userGroupRuleValue.userGroupRule',
          'partner.partnerGroup.partnerGroupRuleValue.partnerGroupRule',
        ],
      });
      return data;
    } catch (error) {
      logError(error);
      return {
        data: [],
        included: [],
      } as JsonApiMessage<UserJsonApiEntity>;
    }
  },
);

export const userConfigSlice = createSlice({
  name: 'userConfig',
  initialState: {
    loading: true,
    partner: {},
    user: {},
    userDetails: {},
  } as UserConfigContextValue,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchUserDetails.fulfilled,
      (_, action: ReturnType<typeof fetchUserDetails.fulfilled>) => {
        const {
          entities: {
            Partner: [partner] = [],
            PartnerGroup: partnerGroups = [],
            PartnerGroupRule: partnerGroupRules = [],
            PartnerGroupRuleValue: partnerGroupRuleValues = [],
            User: [user] = [],
            UserGroup: userGroups = [],
            UserGroupRule: userGroupRules = [],
            UserGroupRuleValue: userGroupRuleValues = [],
          },
        } = readAsList(action.payload);

        const userRules = getUserRules({
          user,
          userGroups,
          userGroupRules,
          userGroupRuleValues,
        });

        const partnerRules = getPartnerRules({
          partner,
          partnerGroups,
          partnerGroupRules,
          partnerGroupRuleValues,
        });

        const newState: UserConfigContextValue = {
          loading: false,
          partner: partnerRules,
          user: userRules,
          userDetails: user,
        };

        return newState;
      },
    );
    builder.addCase(fetchUserDetails.pending, (state) => ({
      ...state,
      loading: true,
    }));
  },
});
