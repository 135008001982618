import {
  OrganizationDialogSliceState,
  OrgDialogProject,
  DzAddress,
  ClientWithMultiContacts,
} from 'shared-ui';
import { logError } from '@one-vision/utils';
import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  Dispatch,
  Middleware,
  PayloadAction,
} from '@reduxjs/toolkit';
import { API } from 'core/api';
import { DzStore, actions } from 'core/redux';
import {
  clientWithMultiContactsUpdated,
  addedClientToAddress,
} from '../actions/clients.actions';
import {
  disassociateClient,
  fetchClientsForAddress,
  postClient,
  updateClient,
} from '../clients.redux';
import { addressIsUpdating, addressUpdated } from './actions';
import { fetchAddress, updateProject } from './thunks.redux';

export const fetchOrgDialogProjects = createAsyncThunk<
  OrgDialogProject[],
  { ovaid: OrgDialogProject['ovaid'] }
>('fetchOrgDialogProjects/get', async (arg) => {
  try {
    const { ovaid } = arg;
    const { data } = await API.getOrgDialogProjects(ovaid);

    return data;
  } catch (error) {
    logError(error);
    return [];
  }
});

const actionsToRectTo = new Set([
  updateClient.fulfilled.type,
  disassociateClient.fulfilled.type,
  postClient.fulfilled.type,
  updateProject.fulfilled.type,
]);

export const addressPanelMiddlewares: Middleware<
  Record<string, unknown>,
  DzStore,
  Dispatch<AnyAction>
>[] = [
  (storeAPI) => (next) => (action) => {
    if (!actionsToRectTo.has(action.type)) {
      return next(action);
    }

    const addressPanelState = storeAPI.getState().sidebars.addressSidebar;

    if (!addressPanelState.ovaid) {
      return next(action);
    }

    if (action.type === updateProject.fulfilled.type) {
      const addressPanelProject = addressPanelState.projects?.find(
        (project) => project.ovprjid === action.payload.projectId,
      );

      if (addressPanelProject) {
        // the default dispatch doesn't know about thunks
        storeAPI.dispatch(
          fetchOrgDialogProjects({
            ovaid: addressPanelState.ovaid,
          }) as unknown as AnyAction,
        );

        storeAPI
          .dispatch(
            fetchAddress({
              ovaid: addressPanelState.ovaid,
            }),
          )
          .unwrap()
          .then((addresses: DzAddress[]) => {
            if (addresses.length) {
              storeAPI.dispatch(
                actions.updateOrganizationDialogState({
                  address: addresses[0],
                }),
              );
              return;
            }
          });
      }
    }

    let needRefreshClients = false;
    if (action.type === disassociateClient.fulfilled.type) {
      needRefreshClients = Boolean(
        addressPanelState.clients?.find(
          (client) => client.ovcid === action.meta?.arg?.clientId,
        ),
      );
    }
    if (action.type === updateClient.fulfilled.type) {
      needRefreshClients =
        (addressPanelState.clients || []).some(
          (client) => client.ovaid === action.payload.ovcid,
        ) || addressPanelState.ovaid === action.payload.ovaid;
    }
    if (action.type === postClient.fulfilled.type) {
      needRefreshClients =
        action?.payload?.ovaid === addressPanelState.ovaid;
    }

    if (needRefreshClients) {
      // the default dispatch doesn't know about thunks
      storeAPI.dispatch(
        fetchClientsForAddress({
          ovaid: addressPanelState.ovaid,
        }) as unknown as AnyAction,
      );
    }

    return next(action);
  },
];

export const addressSidebarSlice = createSlice({
  name: 'organization-dialog',
  initialState: {
    isOpen: false,
    ovaid: '',
    address: null,
    clients: [],
    projects: [],
    areInnerDialogsOpen: false,
    subscriptionDetails: null,
  } as OrganizationDialogSliceState,
  reducers: {
    updateOrganizationDialogState: (
      state,
      action: PayloadAction<Partial<OrganizationDialogSliceState>>,
    ) => ({
      ...state,
      ...action.payload,
    }),
    setInnerDialogsOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      areInnerDialogsOpen: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchOrgDialogProjects.fulfilled,
        (
          state,
          action: ReturnType<typeof fetchOrgDialogProjects.fulfilled>,
        ) => {
          return {
            ...state,
            projects: action.payload,
          };
        },
      )
      .addCase(
        fetchClientsForAddress.fulfilled,
        (
          state,
          action: ReturnType<typeof fetchClientsForAddress.fulfilled>,
        ) => {
          if (state.ovaid && action.meta.arg.ovaid === state.ovaid) {
            return { ...state, clients: action.payload };
          }
          return state;
        },
      )
      .addCase(addressIsUpdating, (state, action) => {
        const address = {
          ...state.address,
          ...(action.payload as DzAddress),
        };
        return {
          ...state,
          address,
        };
      })
      .addCase(
        addressUpdated,
        (state, action: PayloadAction<DzAddress>) => {
          return {
            ...state,
            address: {
              ...action.payload,
            },
          };
        },
      )
      .addCase(
        clientWithMultiContactsUpdated,
        (state, action: PayloadAction<ClientWithMultiContacts>) => {
          return {
            ...state,
            clients: state.clients.map((el) =>
              el.ovcid === action.payload.ovcid
                ? { ...el, ...action.payload }
                : el,
            ),
          };
        },
      )
      .addCase(addedClientToAddress, (state, action) => {
        const client = action.payload as ClientWithMultiContacts;
        return {
          ...state,
          clients: state.clients?.length
            ? [...state.clients, client]
            : [client],
        };
      });
  },
});
