import { jsx as _jsx } from "react/jsx-runtime";
import { Snackbar, Alert } from '@mui/material';
import { useStyles } from './dz-snackbar.styles';
export const DzSnackbar = ({ text, type, actions, dispatch, position = { vertical: 'bottom', horizontal: 'center' }, }) => {
    const classes = useStyles();
    const handleClose = (_event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(actions.closeSnackbar());
    };
    return (_jsx(Snackbar, Object.assign({ anchorOrigin: position, open: Boolean(text), autoHideDuration: 7000, onClose: handleClose }, { children: _jsx(Alert, Object.assign({ classes: {
                message: classes.message,
                filledSuccess: classes.filledSuccess,
                filledError: classes.filledError,
                filledInfo: classes.filledInfo,
            }, elevation: 6, onClose: handleClose, severity: type }, { children: text })) })));
};
