import { SubSystems } from '../types';
import { accessControlsAndSmartLocks, audio, controlSystem, hvac, intercomFields, lighting, phone, remoteSystemManagementFields, securitySystems, shades, streamingPlatformFields, tv, video, } from './technology-lists';
export const getSubSystemsContent = (contentType, getSelect) => {
    switch (contentType) {
        case SubSystems.ControlSystem:
            return controlSystem.map((item) => getSelect(item));
        case SubSystems.SecuritySystem:
            return securitySystems.map((item) => getSelect(item));
        case SubSystems.Audio:
            return audio.map((item) => getSelect(item));
        case SubSystems.HVAC:
            return hvac.map((item) => getSelect(item));
        case SubSystems.AccessControlsAndSmartLocks:
            return accessControlsAndSmartLocks.map((item) => getSelect(item));
        case SubSystems.Intercom:
            return intercomFields.map((item) => getSelect(item));
        case SubSystems.Lighting:
            return lighting.map((item) => getSelect(item));
        case SubSystems.Phone:
            return phone.map((item) => getSelect(item));
        case SubSystems.RemoteSystemsManagement:
            return remoteSystemManagementFields.map((item) => getSelect(item));
        case SubSystems.Shades:
            return shades.map((item) => getSelect(item));
        case SubSystems.StreamingPlatform:
            return streamingPlatformFields.map((item) => getSelect(item));
        case SubSystems.Tv:
            return tv.map((item) => getSelect(item));
        case SubSystems.Video:
            return video.map((item) => getSelect(item));
        default:
            return null;
    }
};
