import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: theme.px2rem(10),
            borderRadius: '4px',
            border: `1px solid ${theme.palette.custom.OV.sys.outline}`,
            '&:hover': {
                border: `1px solid ${theme.palette.custom.OV.sys.inverseSurface}`,
            },
        },
        focused: {
            border: `2px solid ${theme.palette.primary.main}`,
            '&:hover': {
                border: `2px solid ${theme.palette.primary.main}`,
            },
        },
        error: {
            border: `1px solid ${theme.palette.custom.OV.sys.error}`,
            '&:hover': {
                border: `2px solid ${theme.palette.custom.OV.sys.error}`,
            },
        },
        inputWrap: {
            width: '60%',
            '&:hover': {
                backgroundColor: 'transparent',
            },
            '& label': {
                zIndex: 10,
                backgroundColor: theme.palette.custom.OV.sys.surfaceContainerLowest,
                padding: `0 ${theme.spacing(1)}`,
            },
        },
        inputControl: {
            marginTop: '0 !important',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        input: {
            padding: `${theme.px2rem(20)} ${theme.px2rem(12)} ${theme.px2rem(10)}`,
        },
        inputZendesk: {
            padding: `${theme.px2rem(27)} ${theme.px2rem(8)} ${theme.px2rem(10)}`,
        },
        selectWrap: {
            width: '20%',
            '&:hover': {
                backgroundColor: 'transparent',
            },
            '& > div': {
                backgroundColor: 'transparent !important',
            },
            '& > div:hover': {
                backgroundColor: 'transparent !important',
            },
            '& > div:focus': {
                backgroundColor: 'transparent !important',
            },
        },
        selectWrapZendesk: {
            width: '30%',
        },
        selectInput: {
            '&:focus': {
                backgroundColor: 'transparent',
            },
        },
        primaryUserIcon: {
            cursor: 'pointer',
        },
        primaryUserIconActive: {
            color: '#F6A609',
        },
        primaryUserIconInactive: {
            color: '#828282',
        },
        cancelWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: `0 ${theme.px2rem(3)} 0 0`,
        },
        cancel: {
            color: '#6D6F71',
        },
        cancelDisabled: {
            color: '#b8bbbf',
        },
        starButton: {
            display: 'flex',
            justifyContent: 'center',
        },
        iconStarZendesk: {
            padding: `${theme.px2rem(2)}`,
        },
        iconCancelZendesk: {
            padding: 0,
        },
        tooltip: {
            maxWidth: theme.px2rem(150),
        },
    };
}, { name: 'dz-multi-field-input' });
