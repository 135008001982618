import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Autocomplete, Box, Button, FormControl, FormHelperText, TextField, Typography, } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { useFormStyles } from '../../../../components/shared/styles/form.styles';
import { MEMBERSHIP_NOTES_RICH_TEXT_PLACEHOLDER, unMutableMemberships as immutableMembershipLevels, predefinedCppLengths, } from '../../../../const';
import { Csm, DzProjectStage, } from '../../../../types';
import { S3Client } from '../../../../utils';
import { replaceNewLineWithParagraph } from '../../../../utils/replaceNewLineWithParagraph';
import { useApiClient, usePartnerConfig } from '../../../shared';
import DzRichTextEditor from '../../../shared/dz-rich-text-editor';
import { usePreSoldMembership } from '../../../shared/hooks/usePreSoldMembership';
import { DzPreSoldSwitch } from '../../dz-pre-sold-dialog';
const DzMembershipInfoStep = ({ handleBack, products, selectedAddress, selectedProject, patchAddress, isPreSell, onSwitchChange, zendesk, }) => {
    const formClasses = useFormStyles();
    const isPrimaryProject = useMemo(() => (selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.primaryProjectId) === (selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.ovprjid), [selectedAddress, selectedProject]);
    const validationSchema = useMemo(() => yup.object({
        clientSelectedMembership: isPrimaryProject
            ? yup.string().nullable().required('Field is required')
            : yup.string().nullable(),
        cppLength: (selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.projectStageId) !==
            DzProjectStage.ServiceClient || isPrimaryProject
            ? yup.number().nullable().required('Field is required')
            : yup.number().nullable(),
        membershipSalesNotes: yup.string(),
    }), [selectedProject, isPrimaryProject]);
    const membershipLevels = useMemo(() => {
        if (!selectedAddress) {
            return [];
        }
        const brandProductsDictionary = Object.assign({}, products[selectedAddress.ovbid]);
        if (brandProductsDictionary['Essentials+']) {
            brandProductsDictionary['Essentials Plus'] =
                brandProductsDictionary['Essentials+'];
            delete brandProductsDictionary['Essentials+'];
        }
        const brandProductNames = Object.keys(brandProductsDictionary);
        if ((selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.primaryProjectId) !== (selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.ovprjid) &&
            immutableMembershipLevels.has(selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.clientSelectedMembership)) {
            return [selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.clientSelectedMembership];
        }
        return [Csm.Undecided, Csm['Declined Membership']].concat(brandProductNames.sort());
    }, [selectedAddress, selectedProject, products]);
    const handleSubmit = useCallback((values) => {
        const updates = Object.assign({}, values);
        if (updates.clientSelectedMembership === 'Security Only') {
            updates.clientSelectedMembership = 'Undecided';
            updates.siteSystemsSupported = 'sys_supported_security_only';
        }
        return patchAddress(isPrimaryProject
            ? updates
            : { membershipSalesNotes: updates.membershipSalesNotes });
    }, [patchAddress, isPrimaryProject]);
    const apiClient = useApiClient();
    const s3Client = useMemo(() => {
        return new S3Client({ apiClient });
    }, [apiClient]);
    const partnerConfig = usePartnerConfig();
    const { preSoldMembership } = usePreSoldMembership({
        addressId: (selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.ovaid) || '',
    });
    const shouldShowPreSolds = !zendesk &&
        partnerConfig.accessToPreSoldMemberships &&
        !preSoldMembership;
    return (_jsxs(_Fragment, { children: [_jsx(Formik, Object.assign({ initialValues: {
                    clientSelectedMembership: !isPrimaryProject
                        ? selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.clientSelectedMembership
                        : '',
                    cppLength: !isPrimaryProject
                        ? Number(selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.cppLength)
                        : undefined,
                    membershipSalesNotes: (selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.membershipSalesNotes) || '',
                }, onSubmit: handleSubmit, validationSchema: validationSchema }, { children: ({ submitCount }) => (_jsxs(Form, Object.assign({ id: "membership-form", className: formClasses.formContainer }, { children: [isPrimaryProject && (_jsxs(_Fragment, { children: [_jsx(Field, Object.assign({ name: "clientSelectedMembership" }, { children: ({ meta, field, form }) => (_jsxs(FormControl, Object.assign({ error: Boolean((meta.touched || submitCount > 0) && meta.error), disabled: membershipLevels.length === 1, fullWidth: true }, { children: [_jsx(Autocomplete, { id: "clientSelectedMembership", autoHighlight: true, openOnFocus: true, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Desired Membership", margin: "none", error: meta.touched && !!meta.error }))), options: membershipLevels, getOptionLabel: (option) => option, isOptionEqualToValue: (option) => option === field.value, onChange: (_, newValue) => {
                                                    form.setFieldValue('clientSelectedMembership', newValue);
                                                }, onBlur: form.handleBlur, "data-testid": "membershipSelect" }), (meta.touched || submitCount > 0) && meta.error && (_jsx(FormHelperText, { children: meta.error }))] }))) })), _jsx(Field, Object.assign({ name: "cppLength" }, { children: ({ meta, field, form }) => (_jsxs(FormControl, Object.assign({ error: Boolean((meta.touched || submitCount > 0) && meta.error), fullWidth: true }, { children: [_jsx(Autocomplete, { id: "cppLength", autoHighlight: true, openOnFocus: true, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Complimentary Period", margin: "none", error: meta.touched && !!meta.error }))), options: predefinedCppLengths, getOptionLabel: (option) => option.text, isOptionEqualToValue: (option) => option.value === field.value, onChange: (_, newValue) => {
                                                    form.setFieldValue('cppLength', newValue === null || newValue === void 0 ? void 0 : newValue.value);
                                                }, onBlur: form.handleBlur }), (meta.touched || submitCount > 0) && meta.error && (_jsx(FormHelperText, { children: meta.error }))] }))) }))] })), _jsx(Field, Object.assign({ name: "membershipSalesNotes" }, { children: ({ field, form }) => (_jsxs(_Fragment, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: "Membership Sales Notes" })), _jsx(DzRichTextEditor, { content: replaceNewLineWithParagraph(field.value), onUpdate: ({ editor }) => {
                                            const value = editor.getHTML();
                                            form.setFieldValue(field.name, value);
                                        }, placeholder: MEMBERSHIP_NOTES_RICH_TEXT_PLACEHOLDER, s3Client: s3Client })] })) })), shouldShowPreSolds && (_jsx(DzPreSoldSwitch, { checked: isPreSell, onChange: onSwitchChange }))] }))) })), _jsx(Box, Object.assign({ display: "flex", justifyContent: "space-between" }, { children: _jsx(Button, Object.assign({ variant: "text", color: "primary", onClick: handleBack }, { children: "Previous" })) }))] }));
};
export default DzMembershipInfoStep;
