import uniqBy from 'lodash/uniqBy';
import { createSelector } from '@reduxjs/toolkit';

import { fromISO, isBefore } from '@one-vision/date-utils';
import { DzStore } from 'core/redux';
import { prepareOrganizationsRows } from './prepareOrganizationsRows';
import { DzAddressShortList } from 'shared-ui';
import { OrganizationsViews } from 'shared/hooks/makeUseFavoriteViews';

const selectOwners = (state: DzStore) => state.owners;
const selectClients = (state: DzStore) => state.clients;
const selectAddresses = (state: DzStore) => state.addresses;
const selectActiveView = (_: DzStore, activeView: string) => activeView;

const selectUniqueAddresses = createSelector(
  selectAddresses,
  (addresses) => uniqBy(addresses, (address) => address.ovaid),
);
const selectActiveCppAddresses = createSelector(
  selectUniqueAddresses,
  (addresses) =>
    addresses.filter((address) => {
      if (!address.cppExpDate) {
        return false;
      }

      return !isBefore(fromISO(address.cppExpDate), new Date());
    }),
);
const selectExpiredCppAddresses = createSelector(
  selectUniqueAddresses,
  (addresses) =>
    addresses.filter((address) => {
      if (!address.cppExpDate) {
        return false;
      }

      return isBefore(fromISO(address.cppExpDate), new Date());
    }),
);

const PROJECT_STAGE_IN_SERVICE = 3;

const selectAddressesWithProjectInService = createSelector(
  selectUniqueAddresses,
  (addresses) => {
    return addresses.filter((address) => {
      return (
        address.primaryProject_projectStageId &&
        address.primaryProject_projectStageId === PROJECT_STAGE_IN_SERVICE
      );
    });
  },
);

const selectAddressesByView = ({
  activeView,
  addresses,
  addressesWithProjects,
  addressesExpired,
  addressesActive,
}: {
  activeView: string;
  addresses: DzAddressShortList[];
  addressesWithProjects: DzAddressShortList[];
  addressesExpired: DzAddressShortList[];
  addressesActive: DzAddressShortList[];
}) => {
  switch (activeView) {
    case OrganizationsViews.allOrganizations: {
      return addresses;
    }

    case OrganizationsViews.withProject: {
      return addressesWithProjects;
    }

    case OrganizationsViews.activeCPPs: {
      return addressesActive;
    }

    case OrganizationsViews.expiredCPPs: {
      return addressesExpired;
    }

    default: {
      return addresses;
    }
  }
};

export const makeOrganizationsSelectors = createSelector(
  selectUniqueAddresses,
  selectClients,
  selectOwners,
  selectAddressesWithProjectInService,
  selectActiveCppAddresses,
  selectExpiredCppAddresses,
  selectActiveView,
  (
    addresses,
    clients,
    owners,
    addressesWithProjects,
    addressesActive,
    addressesExpired,
    activeView,
  ) => {
    const selectedAddresses = selectAddressesByView({
      activeView,
      addresses,
      addressesActive,
      addressesExpired,
      addressesWithProjects,
    });

    return prepareOrganizationsRows({
      addresses: selectedAddresses,
      clients,
      owners,
    });
  },
);
