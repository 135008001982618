import React, { useCallback, useMemo } from 'react';
import { Box, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import { useStyles } from './dz-users-sidebar-header-view.styles';
import { DzCardHeaderName } from 'shared-ui';
import { ClientWithMultiContacts } from 'shared-ui';

interface Props {
  client: ClientWithMultiContacts;
  onMenuOpen?: (
    event: React.MouseEvent<HTMLButtonElement>,
    client: ClientWithMultiContacts,
  ) => void;
  hasClientInfo?: boolean;
}

export const DzUsersSidebarHeaderView: React.FC<Props> = ({
  client,
  onMenuOpen,
}) => {
  const classes = useStyles();

  const menuHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!onMenuOpen) {
        return;
      }

      onMenuOpen(event, client);
    },
    [client, onMenuOpen],
  );

  const clientName = useMemo(
    () => `${client.firstName} ${client.lastName}`,
    [client],
  );

  return (
    <div className={classes.headerContainer}>
      <Box className={classes.cardHeaderPartly}>
        <div className={classes.userIcon}>
          <PersonIcon />
        </div>

        {clientName.trim() ? (
          <DzCardHeaderName copyText={clientName} hideTooltip />
        ) : (
          '-'
        )}
      </Box>

      <Box>
        <IconButton
          className={classes.userMenuButton}
          size="small"
          onClick={menuHandler}
        >
          <MoreVertIcon />
        </IconButton>
      </Box>
    </div>
  );
};
