import {
  CellClassParams,
  CellClickedEvent,
  GridOptions,
  RowClickedEvent,
} from 'ag-grid-community';
import {
  GetQuickFilterTextParams,
  ValueGetterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';
import React from 'react';
import { ColDef } from 'ag-grid-community';

export interface DzGridColumn<
  T,
  D = T extends DzRowData<infer R> ? R : any, // eslint-disable-line @typescript-eslint/no-explicit-any
> {
  sort?: 'desc' | 'asc' | null | undefined;
  caption: string;
  field?: string;
  renderer?: (data: T) => React.ReactElement | string | null;
  isNotResizable?: boolean;
  centered?: boolean;
  flex?: number;
  onCellClick?: (event: DzCellClickEvent) => void;
  onGetCellClass?: (event: CellClassParams) => string;
  onGetQuickFilterText?: (params: GetQuickFilterTextParams) => string;
  onGetValue?: (data: ValueGetterParams<D>) => string | number;
  sortable?: boolean;
  onBodyScroll?: GridOptions['onBodyScroll'];
}

export enum UniqueFieldName {
  OVAID = 'ovaid',
  OVCID = 'ovcid',
  OVPRJID = 'ovprjid',
}
export type GridName =
  | 'users'
  | 'active projects'
  | 'service clients'
  | "active CPP's"
  | 'proposals'
  | 'organizations'
  | 'projects';

export interface DzGridProps<RowType> {
  columns: DzColDef[];
  defaultColumnDef?: ColDef;
  rows: RowType[];
  searchText: string;
  canShowSpinner?: boolean;
  uniqueFieldName?: UniqueFieldName | 'id';
  onBodyScroll?: GridOptions['onBodyScroll'];
  gridName: GridName;
  onRowClick?: (e: RowClickedEvent) => void;
  isLoading?: boolean;
  ownerPopup?: JSX.Element;
  handleCellClick?: (e: { data: DzCellClickEvent['data'] }) => void;
  changeSelectedRow: (id: string) => void;
  selectNextView: () => void;
  selectPrevView: () => void;
  openSidebar: (id: string) => void;
  closeSidebar: () => void;
  activeView: string;
}

export interface DzRowData<T> {
  data: T;
}

export interface DzCellClickEvent extends CellClickedEvent {
  event: Event & {
    path: Element[];
  };
}

export interface LightSelection {
  isHovered: boolean;
  rowsLength: number;
  firstRowId: string | undefined;
  prevNodeId: undefined | string;
  nextNodeId: undefined | string;
  currentNodeId: undefined | string;
}

export interface HandleEscData {
  lightSelectedRowId: string | undefined;
  selectedRowId: string | undefined;
}

export enum GridColumnTypes {
  CENTERED = 'centered',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface DzColDef<T = any> extends ColDef<T> {
  field?: string;
  cellRenderer?: (params: {
    data: T;
  }) => React.ReactElement | string | null;
  toStringRepresentation?: (params: { data: T }) => string;
}
