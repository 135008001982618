import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            position: 'relative',
            width: 'auto',
            alignSelf: 'stretch',
        },
        searchIcon: {
            padding: theme.spacing(0, 1),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.grey[500],
        },
        searchIconZen: {
            paddingLeft: 0,
        },
        inputRoot: {
            color: 'inherit',
            height: '100%',
            width: '100%',
        },
        inputInput: {
            paddingLeft: `calc(1em + ${theme.spacing(3)})`,
            height: '100%',
        },
        inputUnderline: {
            '&:before': {
                borderColor: theme.palette.grey[300],
            },
        },
        clearIcon: {
            padding: theme.px2rem(4),
        },
        clearIconHidden: {
            visibility: 'hidden',
        },
        hideList: {
            display: 'none',
        },
        listBox: {
            paddingTop: 0,
        },
        listLabel: {
            color: theme.black.black35,
            fontSize: theme.px2rem(16),
            fontWeight: 500,
            padding: `${theme.px2rem(10)} ${theme.px2rem(10)} ${theme.px2rem(5)}`,
        },
    };
}, { name: 'dz-search-input' });
