import {
  submitItem as submitItemInternal,
  SubmitItem,
} from '@one-vision/utils';
import { config } from '../../core/config';

export const submitError = ({ error, level, context }: SubmitItem) => {
  return submitItemInternal({
    sender: 'provision-ui',
    config,
    error,
    level,
    context,
  });
};
