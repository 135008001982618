import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Dialog, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMemo } from 'react';
import { logRender, csn } from '@one-vision/utils';
import { useStyles } from './dz-dialog.styles';
import { DzCircularProgress } from '../../shared';
export const DzDialogView = ({ caption, isOpen, content, okButtonText, okButtonDisabled, cancelButtonText, paperClass, contentClass, disableBackground, isSubmittingLoader, onOk, onClose, zendesk, disablePortal, clear, direction = 'left', footer, }) => {
    logRender(DzDialogView);
    const classes = useStyles();
    const dialogClasses = useMemo(() => ({
        paper: csn(classes.paper, paperClass, (classes === null || classes === void 0 ? void 0 : classes.zendesk) || ''),
        container: classes.container,
    }), [classes, paperClass]);
    let backdropProps;
    if (disableBackground) {
        backdropProps = {
            className: classes.noBackdrop,
        };
    }
    return (_jsxs(Dialog, Object.assign({ classes: dialogClasses, BackdropProps: backdropProps, closeAfterTransition: true, open: isOpen, TransitionComponent: Slide, transitionDuration: 400, TransitionProps: {
            mountOnEnter: true,
            unmountOnExit: true,
            onExited: clear,
            direction,
        }, onClose: onClose, disableEnforceFocus: true, disablePortal: disablePortal, disableRestoreFocus: true }, { children: [_jsx(DialogTitle, Object.assign({ className: classes.header }, { children: _jsxs(Box, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: [caption, _jsx(IconButton, Object.assign({ onClick: onClose, color: "inherit" }, { children: _jsx(CloseIcon, { color: "inherit" }) }))] })) })), _jsxs(DialogContent, Object.assign({ className: csn(classes.content, contentClass, [classes.contentSubmitting, isSubmittingLoader], [classes.contentZen, zendesk]) }, { children: [isSubmittingLoader && (_jsx(DzCircularProgress, { className: classes.submissionCircularProgress, timeout: 0 })), content] })), _jsxs(DialogActions, Object.assign({ className: classes.dialogActions }, { children: [footer, cancelButtonText && (_jsx(Button, Object.assign({ onClick: onClose, color: "primary" }, { children: cancelButtonText }))), onOk && (_jsx(Button, Object.assign({ className: classes.okButton, variant: "contained", disabled: okButtonDisabled, color: "primary", onClick: onOk }, { children: okButtonText || 'OK' })))] }))] })));
};
