import { accessControlSystemSmartLocks, audioSystemType, cameraSystemBrand, cameraSystemType, controlSystemBrand, hvacBrand, hvacIntegration, intercom, internetServiceProvider, lightingBrand, lightingSystemType, networkLocation, phoneBrand, phoneSystemType, primaryAudioInterface, primaryEquipmentLocation, remoteSystemManagement, securitySystemBrand, securitySystemIntegrated, shadeInterface, shadeType, shadeBrand, streamingPlatform, thirdPartyNetworkProvider, tvServiceProvider, videoDistributionBrand, videoSystemType, wiredNetworkBrand, wirelessNetworkBrand, } from './technology.const';
export const basicSystemInformation = [
    {
        caption: 'Primary Equipment Location',
        addressField: 'sitePrimaryEquipmentLocation',
        items: primaryEquipmentLocation,
        required: false,
    },
    {
        caption: 'Network Location',
        addressField: 'siteNetworkLocation',
        items: networkLocation,
        required: false,
    },
    {
        caption: 'Wired Network Brand',
        addressField: 'siteWiredNetworkBrand',
        items: wiredNetworkBrand,
        required: false,
    },
    {
        caption: 'Wireless Network Brand',
        addressField: 'siteWirelessNetworkBrand',
        items: wirelessNetworkBrand,
        required: false,
    },
    {
        caption: '3rd Party Network Provider',
        addressField: 'site3rdPartyNetworkProvider',
        items: thirdPartyNetworkProvider,
        required: false,
    },
    {
        caption: 'Internet Service Provider',
        addressField: 'siteInternetService',
        items: internetServiceProvider,
        required: false,
    },
];
export const controlSystem = [
    {
        caption: 'Brand',
        addressField: 'siteControlSystemBrand',
        items: controlSystemBrand,
        subHeader: 'Control System',
    },
];
export const tv = [
    {
        caption: 'Service Provider',
        addressField: 'siteTvServiceProvider',
        items: tvServiceProvider,
        subHeader: 'TV',
    },
];
export const remoteSystemManagementFields = [
    {
        caption: 'Primary Brand',
        addressField: 'siteRemoteSystemsManagement',
        items: remoteSystemManagement,
        subHeader: 'Remote Systems Management',
    },
    {
        caption: 'Primary RSM URL',
        addressField: 'siteRsmUrl',
        items: [],
        required: false,
    },
    {
        caption: 'Secondary Brand',
        addressField: 'siteSecondaryRemoteSystemsManagement',
        items: remoteSystemManagement,
    },
    {
        caption: 'Secondary RSM URL',
        addressField: 'siteSecondaryRsmUrl',
        items: [],
        required: false,
    },
    {
        caption: 'Tertiary Brand',
        addressField: 'siteTertiaryRemoteSystemsManagement',
        items: remoteSystemManagement,
    },
    {
        caption: 'Tertiary RSM URL',
        addressField: 'siteTertiaryRsmUrl',
        items: [],
        required: false,
    },
];
export const streamingPlatformFields = [
    {
        caption: 'Brand',
        addressField: 'siteStreamingPlatform',
        items: streamingPlatform,
        subHeader: 'Streaming Platform',
    },
];
export const shades = [
    {
        caption: 'Type',
        addressField: 'siteShadeType',
        items: shadeType,
        subHeader: 'Shades',
    },
    {
        caption: 'Interface',
        addressField: 'siteShadeInterface',
        items: shadeInterface,
    },
    {
        caption: 'Brand',
        addressField: 'siteShadeBrand',
        items: shadeBrand,
    },
];
export const lighting = [
    {
        caption: 'Type',
        addressField: 'siteLightingSystemType',
        items: lightingSystemType,
        subHeader: 'Lighting',
    },
    {
        caption: 'Brand',
        addressField: 'siteLightingSystemBrand',
        items: lightingBrand,
    },
];
export const accessControlsAndSmartLocks = [
    {
        caption: 'Brand',
        addressField: 'siteAccessControlSystemSmartLocks',
        items: accessControlSystemSmartLocks,
        subHeader: 'Access Controls & Smart Locks',
    },
];
export const hvac = [
    {
        caption: 'Integration',
        addressField: 'hvacIntegration',
        items: hvacIntegration,
        subHeader: 'HVAC',
    },
    {
        caption: 'Brand',
        addressField: 'siteHvacBrand',
        items: hvacBrand,
    },
];
export const audio = [
    {
        caption: 'System Type',
        addressField: 'siteAudioSystemType',
        items: audioSystemType,
        subHeader: 'Audio',
    },
    {
        caption: 'Primary Interface',
        addressField: 'sitePrimaryAudioInterface',
        items: primaryAudioInterface,
    },
];
export const video = [
    {
        caption: 'System Type',
        addressField: 'siteVideoSystemType',
        items: videoSystemType,
        subHeader: 'Video',
    },
    {
        caption: 'Brand',
        addressField: 'siteVideoDistributionBrand',
        items: videoDistributionBrand,
    },
];
export const phone = [
    {
        caption: 'System Type',
        addressField: 'sitePhoneSystemType',
        items: phoneSystemType,
        subHeader: 'Phone',
    },
    {
        caption: 'Brand',
        addressField: 'sitePhoneSystemBrand',
        items: phoneBrand,
    },
];
export const intercomFields = [
    {
        caption: 'Brand',
        addressField: 'siteIntercom',
        items: intercom,
        subHeader: 'Intercom',
    },
];
export const securitySystems = [
    {
        caption: 'Security System Integrated',
        addressField: 'siteSecuritySystemIntegrated',
        items: securitySystemIntegrated,
        subHeader: 'Security System',
    },
    {
        caption: 'Security System Brand',
        addressField: 'siteSecuritySystemBrand',
        items: securitySystemBrand,
    },
    {
        caption: 'Camera Type',
        addressField: 'siteCameraSystemType',
        items: cameraSystemType,
    },
    {
        caption: 'Camera Brand',
        addressField: 'siteCameraSystemBrand',
        items: cameraSystemBrand,
    },
];
export const formatDropDownData = (dropDownData) => dropDownData.reduce((accumulator, currentValue) => {
    if (currentValue.subHeader)
        accumulator.label = currentValue.subHeader;
    accumulator.addresses.push(currentValue.addressField);
    return accumulator;
}, { label: '', addresses: [] });
export const subSystemList = [
    formatDropDownData(shades),
    formatDropDownData(securitySystems),
    formatDropDownData(controlSystem),
    formatDropDownData(tv),
    formatDropDownData(hvac),
    formatDropDownData(remoteSystemManagementFields),
    formatDropDownData(streamingPlatformFields),
    formatDropDownData(lighting),
    formatDropDownData(accessControlsAndSmartLocks),
    formatDropDownData(audio),
    formatDropDownData(video),
    formatDropDownData(phone),
    formatDropDownData(intercomFields),
];
