import {
  combineReducers,
  configureStore,
  Middleware,
  createSelector,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { LocalStorageManager } from 'shared';
import { auth } from '@one-vision/login';
import { addressesSlice } from './addresses.redux';
import { authorized } from './auth.redux';
import {
  deleteOwner,
  fetchOwners,
  ownersSlice,
  postOwner,
  reloadUsers,
} from './owners.redux';
import { dialogs } from './dialogs';
import { loadingSlice } from './loading.redux';
import { settings } from './settings';
import { snackbar } from './snackbar.redux';
import { sidebars, sidebarsMiddlewares } from './sidebars';
import {
  clientsSlice,
  Client,
  updateClient,
  addAddressToClient,
  fetchClients,
  fetchClientsForAddress,
  fetchClientWithContactsById,
  postClient,
  disassociateClient,
} from './clients.redux';
import {
  fetchPrimaryClientIds,
  primaryClientIdsSlice,
} from './primaryClients.redux';
import { identitySlice, fetchIdentLabels } from './identity.redux';
import { arbitrarySettingsSlice } from './arbitrary-settings.redux';
import { partnerDomains } from './partner-domains';
import { clientsActions, projectsActions } from './actions';
import * as Entities from './entities';
import { ClientLabels, ClientLabelsListIds } from 'shared-ui';

export const rootReducer = combineReducers({
  ...dialogs.reducer,
  ...sidebars.reducer,
  ...settings.reducer,
  ...authorized.reducer,
  ...snackbar.reducer,
  identity: identitySlice.reducer,
  loading: loadingSlice.reducer,
  owners: ownersSlice.reducer,
  addresses: addressesSlice.reducer,
  clients: clientsSlice.reducer,
  ...Entities.reducers,
  primaryClientIds: primaryClientIdsSlice.reducer,
  partnerDomains: partnerDomains.reducer,
  arbitrary: arbitrarySettingsSlice.reducer,
});

const middleware: Middleware[] = [];
middleware.push(
  (storeAPI) => (next) => (action) => {
    if (!auth.authorized) {
      if (storeAPI.getState().authorized) {
        storeAPI.dispatch(authorized.actions.setAuthorized(false));
      }
    }
    return next(action);
  },
  ...sidebarsMiddlewares,
);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(...middleware),
  preloadedState: JSON.parse(LocalStorageManager.getReduxState() || '{}'),
});

export type DzStore = ReturnType<typeof store.getState>;

export type ClientOrClientToAdress = Client;

export const actions = {
  ...dialogs.actions,
  ...sidebars.actions,
  ...settings.actions,
  ...authorized.actions,
  ...snackbar.actions,
  ...ownersSlice.actions,
  ...addressesSlice.actions,
  ...loadingSlice.actions,
  ...clientsSlice.actions,
  ...identitySlice.actions,
  ...clientsActions,
  ...projectsActions,
  ...primaryClientIdsSlice.actions,
  ...partnerDomains.actions,
  ...arbitrarySettingsSlice.actions,
};

export const thunks = {
  ...dialogs.thunks,
  ...sidebars.thunks,
  ...settings.thunks,
  fetchOwners,
  postOwner,
  deleteOwner,
  reloadUsers,
  updateClient,
  addAddressToClient,
  fetchClients,
  fetchClientsForAddress,
  fetchClientWithContactsById,
  postClient,
  disassociateClient,
  fetchIdentLabels,
  fetchPrimaryClientIds,
  ...partnerDomains.thunks,
};

export const useDzSelector: TypedUseSelectorHook<DzStore> = useSelector;

export const selectUpdateAddressDialog = (state: DzStore) =>
  state.sidebars.updateAddressDialog;
export const selectUpdateProjectInfoDialog = (state: DzStore) =>
  state.sidebars.updateProjectInfoDialog;

export const selectClientsStatus = (state: DzStore) =>
  state.loading.clients;
export const selectClients = (state: DzStore) => state.clients;
export const selectAddressPanel = (state: DzStore) =>
  state.sidebars.addressSidebar;
export const selectIdentityLabels = (state: DzStore) => state.identity;
export const selectClientLabels = (state: DzStore) =>
  ClientLabels.selectors.selectEntitiesFromList(
    state,
    ClientLabelsListIds.allClientLabels,
  );
export const selectSidebars = (state: DzStore) => state.sidebars;
export const selectSearchValue = (state: DzStore) =>
  state.arbitrary.searchValue;

export const selectUpdateServiceLevelDialog = (state: DzStore) =>
  state.sidebars.updateServiceLevelDialog;

export const useSelectClients = () => useDzSelector(selectClients);
export const useSelectClientsStatus = () =>
  useDzSelector(selectClientsStatus);
export const useSelectAddressPanel = () =>
  useDzSelector(selectAddressPanel);
export const useSelectUpdateAddressDialog = () =>
  useDzSelector(selectUpdateAddressDialog);
export const useSelectUpdateServiceLevelDialog = () =>
  useDzSelector(selectUpdateServiceLevelDialog);
export const useSelectUpdateProjectInfoDialog = () =>
  useDzSelector(selectUpdateProjectInfoDialog);
export const useSelectIdentityLabels = () =>
  useDzSelector(selectIdentityLabels);
export const useSelectClientLabels = () =>
  useDzSelector(selectClientLabels);

export const selectUserSidebar = createSelector(
  selectSidebars,
  (sidebars) => sidebars.userSidebar,
);

export const selectProjectSidebar = createSelector(
  selectSidebars,
  (_) => _.projectSidebar,
);

export const useSelectUserSidebar = () => useDzSelector(selectUserSidebar);
export const useSelectProjectSidebar = () =>
  useDzSelector(selectProjectSidebar);

export const comparisonAllowEmptyArray = (
  left: unknown[],
  right: unknown[],
) => {
  if (left && right && left.length === right.length && !left.length) {
    return true;
  }
  return left === right;
};
