import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { User, Phase } from './types';
import { Header } from './dz-bulk-users-importer-header.view';
import { DzCsvImportEditor } from '../../../shared/dz-csv-import-editor';
import { useStyles } from './dz-bulk-users-importer.styles';
import { useGridFieldDefinitions } from './useGridFieldDefinitions';
import { DzDataImportProgress } from '../../../dz-settings-page/dz-data-import';
import {
  DzDataImportResult,
  FieldDefinition as SkippedUsersFieldDefinition,
} from '../../../dz-settings-page/dz-data-import';
import { useStartImport } from './useStartImport';
import { ModalManagerProps, withModalManager } from 'shared-ui';

export interface Props {
  onFileError?: (error: string) => void;
}

const skippedUsersFieldDefinition: SkippedUsersFieldDefinition[] = [
  {
    label: 'First Name',
    key: 'firstName',
  },
  {
    label: 'Last Name',
    key: 'lastName',
  },
  {
    label: 'Email',
    key: 'email',
  },
];

const DzBulkUsersImporterDialog: React.FC<ModalManagerProps & Props> = ({
  isOpen,
  close,
  clear,
  onFileError,
}) => {
  const classes = useStyles();
  const gridFields = useGridFieldDefinitions();
  const [editorHasError, setEditorHasError] = useState(false);

  const [phase, setPhase] = useState<Phase>(Phase.ChoosingSource);
  const setEditingPhase = useCallback(() => setPhase(Phase.Editing), []);

  const [users, setUsers] = useState<User[]>([]);
  const [succeededUsers, setSucceededUsers] = useState<User[]>([]);
  const addSucceededUser = useCallback(
    (user: User) => setSucceededUsers((prevUsers) => [...prevUsers, user]),
    [],
  );
  const [failedUsers, setFailedUsers] = useState<User[]>([]);
  const addFailedUser = useCallback(
    (user: User) => setFailedUsers((prevUsers) => [...prevUsers, user]),
    [],
  );

  const startImport = useStartImport({
    addFailedUser,
    addSucceededUser,
    users,
  });

  const handleImportClick = () => {
    setPhase(Phase.Importing);
    startImport().finally(() => setPhase(Phase.Done));
  };

  const isChoosingOrEditing =
    phase === Phase.ChoosingSource || phase === Phase.Editing;

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      TransitionProps={{
        onExited: clear,
      }}
    >
      <Header onClose={close} phase={phase} />
      <DialogContent dividers>
        {isChoosingOrEditing && (
          <DzCsvImportEditor
            gridFields={gridFields}
            onChange={setUsers}
            onError={(error) => {
              onFileError?.(error || 'Unknown error');
            }}
            onEditMode={setEditingPhase}
            onEditorHasError={setEditorHasError}
          />
        )}
        {phase === Phase.Editing && (
          <>
            <br />
            <div className={classes.submitContainer}>
              <Button
                variant="contained"
                onClick={handleImportClick}
                disabled={editorHasError}
              >
                Import
              </Button>
            </div>
          </>
        )}
        {phase === Phase.Importing && (
          <DzDataImportProgress
            total={users.length}
            done={succeededUsers.length + failedUsers.length}
            success={succeededUsers.length}
            error={failedUsers.length}
          />
        )}
        {phase === Phase.Done && (
          <DzDataImportResult
            errors={failedUsers}
            successes={succeededUsers.length}
            skippedRecordsFieldDefinitions={skippedUsersFieldDefinition}
            withDedicatedExportButton
            onConfirm={close}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default withModalManager()(DzBulkUsersImporterDialog);
