import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => ({
    root: {
        height: '20px',
        display: 'flex',
        alignItems: 'center',
        width: '25px',
        justifyContent: 'center',
        color: theme.palette.grey[300],
    },
    active: {
        color: theme.palette.primary.main,
    },
    completed: {
        color: theme.palette.primary.main,
        zIndex: 1,
        fontSize: '18px',
        cursor: 'pointer',
    },
    circle: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));
