import { alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        position: 'fixed',
        zIndex: theme.zIndex.appBar,
        left: `${theme.dz.appBar.width}px`,
        right: 0,
        top: 0,
        height: `${theme.dz.topBar.height}px`,
        background: theme.palette.custom.OV.sys.surfaceContainerLowest,
        borderBottom: `1px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: ' 0 19px',
      },
      searchContainer: {
        display: 'flex',
        justifyContent: 'center',
        flex: '1 1',
      },
      rightGroup: {
        display: 'flex',
        transition: '.7s',
        opacity: 0,
      },
      rightGroupVisible: {
        opacity: 1,
      },
      search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.black, 0.08),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.black, 0.13),
        },
        width: 'auto',
        alignSelf: 'center',
      },
      searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.grey[500],
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        height: '32px',
        [theme.breakpoints.up('sm')]: {
          width: '12ch',
          '&:focus': {
            width: '30ch',
          },
        },
      },
      popoverOffset: {
        marginTop: '14px',
      },
      profilePopover: {
        paddingBottom: theme.spacing(2),
        width: '300px',
      },
      listItemIconRoot: {
        minWidth: '40px',
      },
      supportToolsButton: {
        textTransform: 'unset',
        letterSpacing: '1.55px',
        color: theme.palette.custom.OV.sys.onSurface,
      },
      supportToolsPopup: {
        width: '170px',
      },
      supportToolsCarrotOffset: {
        marginLeft: '20px',
      },
      profilePopoverInfoblock: {
        padding: '16px',
        color: theme.palette.custom.OV.sys.onSurface,
      },
      vertDivider: {
        margin: '0 22px',
      },
      listButton: {
        '&:hover': {
          color: theme.dz.colors.listHover,
          '& > *': {
            color: theme.dz.colors.listHover + ' !important',
          },
        },
      },
      profileButton: {
        color: theme.palette.custom.OV.sys.onSurface,
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
      profileButtonCaption: {
        textTransform: 'none',
        fontWeight: 500,
        marginRight: '15px',
      },
      profileLogo: {
        height: '38px',
        width: '38px',
        minWidth: '38px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: '11px',
        marginRight: '12px',
      },
      frameDialogAnchor: {
        position: 'fixed',
        right: 0,
        top: `${theme.dz.topBar.height}px`,
      },
      frameDialogHeader: {
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px',
        color: '#fff',
      },
      frameDialogTitle: {},
      frameDialogFrame: {
        height: '600px',
        width: '400px',
        margin: '0 -2px -2px',
        backgroundColor: theme.palette.common.white,
      },
    };
  },
  { name: 'dz-top-bar', index: 0 },
);

export type DzTopBarClasses = ReturnType<typeof useStyles>;
