var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useStyles } from './dz-editor.styles';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import RuleIcon from '@mui/icons-material/Rule';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import CodeIcon from '@mui/icons-material/Code';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import DzMenuButton from './dz-menu-button.view';
import { useModalManager } from '../dz-modal-manager';
import { ModalIDs, URL_PROTOCOL_REGEXP, } from '../../..';
const DzEditorMenu = (_a) => {
    var { editor, uploadImage } = _a, rest = __rest(_a, ["editor", "uploadImage"]);
    const classes = useStyles({});
    const { openModal } = useModalManager();
    const setLink = useCallback(async () => {
        if (!editor) {
            return;
        }
        const previousUrl = editor.getAttributes('link').href || '';
        // select whole link if one exists
        if (previousUrl) {
            editor.commands.extendMarkRange('link');
        }
        const { from, to } = editor.state.selection;
        const previousText = editor.state.doc.textBetween(from, to);
        const linkEditResult = await openModal(ModalIDs.editLink, {
            text: previousText,
            url: previousUrl,
        });
        // cancelled
        if (!linkEditResult) {
            editor.commands.focus();
            return;
        }
        const { url, text } = linkEditResult;
        // empty
        if (url === '') {
            editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .unsetLink()
                .setTextSelection(editor.state.selection.to)
                .run();
            return;
        }
        // update text
        if (previousText !== text) {
            if (text) {
                editor
                    .chain()
                    .focus()
                    .insertContent(text)
                    .setTextSelection({
                    from,
                    to: from + text.length,
                })
                    .run();
            }
            else {
                editor.chain().focus().deleteSelection().run();
            }
        }
        if (editor.state.selection.from === editor.state.selection.to) {
            editor
                .chain()
                .focus()
                .insertContent(url)
                .setTextSelection({
                from: editor.state.selection.from,
                to: editor.state.selection.from + url.length,
            })
                .run();
        }
        const hasProtocol = url.match(URL_PROTOCOL_REGEXP);
        editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .setLink({ href: hasProtocol ? url : `http://${url}` })
            .setTextSelection(editor.state.selection.to)
            .run();
    }, [editor, openModal]);
    const chooseImage = useCallback(async () => {
        if (!editor) {
            return;
        }
        const result = await openModal(ModalIDs.selectImage, {});
        editor.commands.focus();
        if (!result) {
            return;
        }
        const { file, url } = result;
        const src = file ? await uploadImage(file) : url;
        if (src) {
            editor.commands.setImage({ src });
        }
    }, [editor]);
    const toggle = useCallback((command) => () => {
        var _a;
        if (!editor) {
            return;
        }
        (_a = editor.chain().focus()) === null || _a === void 0 ? void 0 : _a[command]().run();
    }, [editor]);
    const toggleBold = useMemo(() => toggle('toggleBold'), [toggle]);
    const toggleItalic = useMemo(() => toggle('toggleItalic'), [toggle]);
    const toggleUnderline = useMemo(() => toggle('toggleUnderline'), [toggle]);
    const toggleStrike = useMemo(() => toggle('toggleStrike'), [toggle]);
    const toggleTaskList = useMemo(() => toggle('toggleTaskList'), [toggle]);
    const toggleCodeBlock = useMemo(() => toggle('toggleCodeBlock'), [toggle]);
    if (!editor) {
        return null;
    }
    return (_jsxs("div", Object.assign({}, rest, { className: classes.editorMenu }, { children: [_jsx(DzMenuButton, Object.assign({ onClick: toggleBold, disabled: !editor.can().chain().focus().toggleBold().run(), isActive: editor.isActive('bold') }, { children: _jsx(FormatBoldIcon, {}) })), _jsx(DzMenuButton, Object.assign({ onClick: toggleItalic, disabled: !editor.can().chain().focus().toggleItalic().run(), isActive: editor.isActive('italic') }, { children: _jsx(FormatItalicIcon, {}) })), _jsx(DzMenuButton, Object.assign({ onClick: toggleUnderline, disabled: !editor.can().chain().focus().toggleUnderline().run(), isActive: editor.isActive('underline') }, { children: _jsx(FormatUnderlinedIcon, {}) })), _jsx(DzMenuButton, Object.assign({ onClick: toggleStrike, disabled: !editor.can().chain().focus().toggleStrike().run(), isActive: editor.isActive('strike') }, { children: _jsx(FormatStrikethroughIcon, {}) })), _jsx(DzMenuButton, Object.assign({ onClick: toggleTaskList, disabled: !editor.can().chain().focus().toggleTaskList().run(), isActive: editor.isActive('taskList') }, { children: _jsx(RuleIcon, {}) })), _jsx(DzMenuButton, Object.assign({ onClick: setLink, isActive: editor.isActive('link') }, { children: _jsx(InsertLinkIcon, {}) })), _jsx(DzMenuButton, Object.assign({ onClick: chooseImage }, { children: _jsx(WallpaperIcon, {}) })), _jsx(DzMenuButton, Object.assign({ onClick: toggleCodeBlock, isActive: editor.isActive('codeBlock') }, { children: _jsx(CodeIcon, {}) }))] })));
};
export default DzEditorMenu;
