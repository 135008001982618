import { makeStyles } from '@mui/styles';
export const useDrawerStyles = makeStyles((theme) => {
    return {
        header: {
            backgroundColor: theme.palette.secondary.main,
            color: '#fff',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        paper: {
            margin: 0,
            borderRadius: `${theme.px2rem(4)} 0px 0px ${theme.px2rem(4)}`,
            height: '100vh',
            maxHeight: '100vh',
            width: `${theme.px2rem(550)}`,
        },
        container: {
            justifyContent: 'flex-end',
        },
        content: {
            padding: `0px`,
        },
        zendeskDialogContent: {
            padding: `0 ${theme.px2rem(3)} ${theme.px2rem(24)}`,
        },
        actions: {
            padding: theme.spacing(3),
            backgroundColor: theme.palette.custom.OV.sys.surfaceContainerLowest,
        },
        overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            inset: 0,
            pointerEvents: 'initial',
            background: 'rgba(255, 255, 255, 0.35)',
            zIndex: 10,
        },
        relativePosition: {
            position: 'relative',
        },
    };
}, { name: 'dz-new-project-dialog', index: 0 });
