import React from 'react';
import { useStyles } from './dz-stripe.styles';
import { DzCircularProgress } from 'shared-ui';
import { useStripeAccountOnboardStatus } from 'shared/hooks/useStripeOnboardStatus';
import { DzLoginBanner } from './dz-login-banner.view';
import { DzSetupBanner } from './dz-setup-banner.view';

export const DzStripe = () => {
  const classes = useStyles();
  const { onboardCompleted, loading, error } =
    useStripeAccountOnboardStatus();

  if (loading) {
    return <DzCircularProgress className={classes.spinner} />;
  }

  if (error) {
    return null;
  }

  return onboardCompleted ? <DzLoginBanner /> : <DzSetupBanner />;
};
