import { useMutation } from '@tanstack/react-query';
import { useApiClient } from '../../shared';
import { useEffect } from 'react';
export const useAiHelpTrigger = ({ onSuccess, onError, }) => {
    const apiClient = useApiClient();
    const mutation = useMutation({
        mutationFn: async (params) => {
            var _a;
            const response = await apiClient.generateAiHelp(params);
            return (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data;
        },
    });
    useEffect(() => {
        if (mutation.isSuccess) {
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(mutation === null || mutation === void 0 ? void 0 : mutation.data);
        }
    }, [mutation.isSuccess]);
    useEffect(() => {
        var _a, _b;
        if (mutation.isError) {
            onError === null || onError === void 0 ? void 0 : onError((_b = (_a = mutation.error) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : '');
        }
    }, [mutation.isError]);
    return mutation;
};
