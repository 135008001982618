import { makeStyles } from '@mui/styles';
import { ExtTheme } from 'core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {},
      infoContainer: {
        display: 'grid',
        gridTemplateColumns: '2fr auto 3fr',
        padding: `${theme.px2rem(23)} ${theme.px2rem(26)}`,
        rowGap: theme.px2rem(15),
      },
      contentTitle: {
        fontWeight: 500,
        fontSize: theme.px2rem(16),
        padding: `${theme.px2rem(10)} ${theme.px2rem(25)}`,
        color: theme.black.black65,
      },
      editIcon: {
        color: '#FC6D26',
      },
      divider: {
        borderTop: '2px solid #E8E8E8',
        width: '100%',
        marginBottom: theme.px2rem(15),
      },
      addOrgHeader: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.px2rem(15)} 0`,
        margin: `0 ${theme.px2rem(15)}`,
        borderBottom: `2px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
      },
      menu: {
        padding: `${theme.px2rem(6)} 0 ${theme.px2rem(6)} ${theme.px2rem(
          8,
        )}`,
        fontSize: theme.px2rem(14),
      },
      menuItem: {
        paddingTop: 2,
      },
      menuImg: {
        marginRight: 10,
      },
      contactsImage: {
        color: theme.palette.secondary.main,
        width: theme.px2rem(16),
        marginRight: 26,
      },
      contactsText: {
        fontSize: 16,
      },
      contactsLabel: {
        color: theme.black.black35,
        paddingLeft: 6,
      },
      placeholder: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.px2rem(15)} 0`,
        margin: `0 ${theme.px2rem(15)}`,
        borderBottom: `2px solid ${theme.palette.custom.OV.sys.outlineVariant}`,
      },
      titleSkeleton: {
        padding: `${theme.px2rem(10)} ${theme.px2rem(25)}`,
      },
      zendeskIcon: {
        width: theme.px2rem(13),
        height: theme.px2rem(13),
        fill: 'black',
      },
    };
  },
  { name: 'dz-users-sidebar-content', index: 0 },
);
