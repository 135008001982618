import {
  DzAddress,
  DzClient,
  DzOwner,
  DzProject,
  DzProjectLostReason,
  IDzProjectStage,
} from 'shared-ui';
import { DzGridColumn, DzRowData } from 'components/shared';

export type PopulatedProject = DzProject & {
  address?: DzAddress & {
    primaryClient?: DzClient;
  };
  owner?: DzOwner;
  projectLostReason?: DzProjectLostReason;
  projectStage?: IDzProjectStage;
};

export type ProjectsColumn = DzGridColumn<
  DzRowData<PopulatedProject | ProjectGridDummy>
>;

export type ProjectGridDummy = Pick<DzProject, 'ovprjid'> & {
  fakeid: string;
};

export function isDummy(
  row: PopulatedProject | ProjectGridDummy,
): row is ProjectGridDummy {
  return (row as ProjectGridDummy).fakeid !== undefined;
}
