import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdateProjectInfoDialogSliceState } from 'shared-ui';

export const updateProjectInfoDialogSlice = createSlice({
  name: 'update-project-info-dialog',
  initialState: {
    isOpen: false,
    projectData: {},
  } as UpdateProjectInfoDialogSliceState,
  reducers: {
    updateProjectInfoDialogState: (
      state,
      action: PayloadAction<Partial<UpdateProjectInfoDialogSliceState>>,
    ) => ({
      ...state,
      ...action.payload,
      projectData: {
        ...state.projectData,
        ...action.payload.projectData,
      },
    }),
  },
});
