import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography, IconButton, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DzOrganizationForm from './dz-organization-form.view';
import { withModalManager, useDrawerStyles, } from '../../shared';
import { logError } from '@one-vision/utils';
import CircularProgress from '@mui/material/CircularProgress';
import { requiredField } from '../../../const';
const getInitials = () => {
    return {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        businessName: '',
        ovbid: '',
        ownerId: null,
        orgNotes: '',
    };
};
const validate = (values) => {
    const errors = {};
    if (!values.address1) {
        errors.address1 = requiredField;
    }
    if (!values.city) {
        errors.city = requiredField;
    }
    if (!values.state) {
        errors.state = requiredField;
    }
    if (!values.zip) {
        errors.zip = requiredField;
    }
    if (!values.ovbid) {
        errors.ovbid = requiredField;
    }
    return errors;
};
export const DzCreateOrganizationDialog = ({ isOpen, close, clear, prefilledValue = '', apiClient, actions, dispatch, owners = [], }) => {
    const dialogClasses = useDrawerStyles();
    const [disabled, setDisabled] = useState(false);
    const [brands, setBrands] = useState(null);
    const onClose = useCallback(() => close(), [close]);
    const onSubmit = useCallback(async (values) => {
        setDisabled(true);
        const payload = Object.entries(values).reduce((acc, [key, value]) => {
            if (value == null || value === '') {
                return acc;
            }
            return Object.assign(Object.assign({}, acc), { [key]: value });
        }, {});
        try {
            const { data } = await apiClient.createAddress(payload);
            if (data.length) {
                close(data[0]);
            }
        }
        catch (err) {
            const error = err;
            logError(error);
            dispatch(actions.updateSnackbar({
                type: 'error',
                text: error.response.data.message,
            }));
            setDisabled(false);
        }
    }, [setDisabled, dispatch]);
    useEffect(() => {
        (async () => {
            try {
                const response = await apiClient.getBrands();
                setBrands(response.data.sort((a, b) => b.name.localeCompare(a.name)));
            }
            catch (error) {
                logError(error);
                dispatch(actions.updateSnackbar({
                    type: 'error',
                    text: 'Oops! Something went wrong...',
                }));
            }
        })();
    }, []);
    return (_jsxs(Dialog, Object.assign({ open: isOpen, onClose: onClose, closeAfterTransition: true, transitionDuration: 400, TransitionComponent: Slide, TransitionProps: {
            direction: 'left',
            onExited: clear,
            mountOnEnter: true,
            unmountOnExit: true,
        }, classes: {
            paper: dialogClasses.paper,
            container: dialogClasses.container,
        }, disableRestoreFocus: true }, { children: [_jsxs(DialogTitle, Object.assign({ className: dialogClasses.header }, { children: [_jsx(Typography, { children: "Create Organization" }), _jsx(IconButton, Object.assign({ onClick: onClose, color: "inherit" }, { children: _jsx(CloseIcon, { color: "inherit" }) }))] })), _jsxs(DialogContent, Object.assign({ className: dialogClasses.relativePosition }, { children: [disabled && (_jsx(Box, Object.assign({ className: dialogClasses.overlay }, { children: _jsx(CircularProgress, {}) }))), _jsx(Formik, Object.assign({ initialValues: Object.assign(Object.assign({}, getInitials()), { address1: prefilledValue }), onSubmit: onSubmit, validate: validate }, { children: ({ submitCount }) => {
                            return (_jsx(DzOrganizationForm, { submitCount: submitCount, brands: brands, owners: owners }));
                        } }))] })), _jsxs(DialogActions, Object.assign({ className: dialogClasses.actions }, { children: [_jsx(Button, Object.assign({ size: "large", variant: "text", color: "primary", onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ size: "large", variant: "contained", color: "primary", disabled: disabled, type: "submit", form: "organization-form" }, { children: "Save" }))] }))] })));
};
export default withModalManager()(DzCreateOrganizationDialog);
