import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { Autocomplete, FormControl, FormHelperText, InputAdornment, Stack, TextField, } from '@mui/material';
import { getChanges, logRender } from '@one-vision/utils';
import { DzProjectStage } from '../../../types';
import { DzDialog, useGlobalStyles } from '../../shared';
import { DzNumberTextField } from '../../shared/dz-number-input';
import Projects from '../../../redux/projects';
const stages = [
    {
        value: DzProjectStage.Lead,
        text: 'Proposal',
    },
    {
        value: DzProjectStage.ActiveProject,
        text: 'Active Project',
    },
    {
        value: DzProjectStage.ServiceClient,
        text: 'Service Client',
    },
    {
        value: DzProjectStage.LostProposal,
        text: 'Lost Proposal',
    },
];
export const UpdateProjectInfoDialogView = ({ isOpen, thunks, actions, projectData, ovaid, dispatch, zendesk, }) => {
    logRender(UpdateProjectInfoDialogView);
    const globalClasses = useGlobalStyles();
    const [initialData, setInitialData] = useState(projectData);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const updateProjectData = (projectData) => dispatch(actions.updateProjectInfoDialogState({ projectData }));
    const handlePriceChange = useCallback((value) => {
        updateProjectData({ price: value.floatValue });
    }, [updateProjectData]);
    const handleNameChange = useCallback((event) => {
        updateProjectData({ name: event.currentTarget.value });
    }, [updateProjectData]);
    const handleStageChange = (stage) => {
        updateProjectData({ projectStageId: stage });
    };
    const handleClose = useCallback(() => {
        dispatch(actions.updateProjectInfoDialogState({
            isOpen: false,
            projectData: {
                name: undefined,
                price: undefined,
                projectId: null,
                projectStageId: null,
            },
        }));
    }, [dispatch, actions]);
    const handleSubmit = useCallback(() => {
        setIsSubmitting(true);
        const { price, name, projectStageId } = getChanges(initialData, projectData);
        const { projectId } = projectData;
        if (!projectId) {
            return;
        }
        dispatch(thunks.updateProject({
            changes: {
                price: (price || 'NULL'),
                name: name,
                projectStageId: projectStageId,
            },
            projectId,
        }))
            .unwrap()
            .then(() => {
            const updates = {
                ovprjid: projectId,
            };
            if (price) {
                updates.price = price;
            }
            if (name) {
                updates.name = name;
            }
            if (projectStageId) {
                updates.projectStageId = projectStageId;
            }
            dispatch(Projects.actions.update(updates));
            dispatch(actions.updateSnackbar({
                text: 'Project Info was updated!',
                type: 'success',
            }));
            handleClose();
            setIsSubmitting(false);
            thunks.fetchOrgDialogProjects({
                ovaid,
            });
        })
            .catch(() => {
            setIsSubmitting(false);
        })
            .finally(() => {
            setInitialData({});
        });
    }, [dispatch, handleClose, projectData, initialData]);
    const content = useMemo(() => {
        return (_jsxs(Stack, { children: [_jsx(TextField, { className: globalClasses.gcFieldMargin, label: "Project Name", placeholder: "Project Name", value: projectData.name || '', onChange: handleNameChange }), _jsxs(FormControl, Object.assign({ error: !projectData.projectStageId, fullWidth: true, className: globalClasses.gcFieldMargin }, { children: [_jsx(Autocomplete, { autoHighlight: true, openOnFocus: true, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Project Stage", margin: "none", error: !projectData.projectStageId }))), options: stages, getOptionLabel: (option) => option.text, isOptionEqualToValue: (option) => option.value === projectData.projectStageId, value: stages.find((el) => el.value === projectData.projectStageId), onChange: (_, newValues) => handleStageChange((newValues === null || newValues === void 0 ? void 0 : newValues.value) || null) }), !projectData.projectStageId && (_jsx(FormHelperText, { children: "Required" }))] })), _jsx(DzNumberTextField, { label: "Project Price", placeholder: "xxxx.xx", InputProps: {
                        inputProps: {
                            decimalPartLength: 2,
                            max: 9999999,
                        },
                        startAdornment: (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: `$` }))),
                    }, value: projectData.price || '', onValueChange: handlePriceChange })] }));
    }, [
        projectData.name,
        projectData.price,
        updateProjectData,
        handlePriceChange,
        handleNameChange,
    ]);
    return (_jsx(DzDialog, { caption: 'Update Project Info', isOpen: isOpen, okButtonText: "Submit", onOk: handleSubmit, onClose: handleClose, content: content, okButtonDisabled: isSubmitting, isSubmittingLoader: isSubmitting, zendesk: zendesk }));
};
